import { createAction } from '@ngrx/store';
import { Task } from '../../models/task';

export enum TaskActionTypes {
  LoadTasks = '[Task Page] Load Tasks',
  LoadTasksError = '[Task Page] Load Tasks Error',
  LoadTasksSuccess = '[Task Page] Load Tasks Success',
}

export const loadTasks = createAction(TaskActionTypes.LoadTasks);


export const loadTasksSuccess = createAction(
  TaskActionTypes.LoadTasksSuccess,
  (data: Task[]) => ({  data })
);


export const loadTasksError = createAction(
  TaskActionTypes.LoadTasksError,
  (error: string) => ({ error })
);
