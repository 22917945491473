interface IBlueAltair
{
    id: string;
    fromCounry : string
    toCountry: string
    travelDate  :Date
    reasonOfTravel :string
    additionalInfo :string
    userId :string
}
export class BlueAltairTravel {
    public id: string;
    public fromCounry : string
    public  toCountry: string
    public travelDate  :Date
    public reasonOfTravel :string
    public additionalInfo :string
    public userId :string
    constructor(){
        this.id=undefined
        this.fromCounry = ''
        this.toCountry=''
        this.reasonOfTravel = ''
        this.additionalInfo=''
        this.travelDate=undefined
        this.id=undefined
    }
    bind(httpResponse:any){
        this.id = httpResponse && httpResponse.id || undefined
        this.fromCounry = httpResponse && httpResponse.fromCounry || undefined
        this.toCountry = httpResponse && httpResponse.toCountry || undefined
        this.userId = httpResponse && httpResponse.userId || undefined
        this.reasonOfTravel = httpResponse && httpResponse.reasonOfTravel || undefined  
     this.travelDate=httpResponse&&httpResponse.travelDate||undefined
        this.additionalInfo = httpResponse && httpResponse.additionalInfo || undefined
        return this;     
    }
}
