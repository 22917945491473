<div>
  <div class="main_header_div">
    <div>
      <h3 mat-dialog-title>Create Knowledge Transfer Plan</h3>
    </div>
    <div class="child_close_btn">
      <button class="closeButton" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <hr />

  <div>
    <form
      action=""
      [formGroup]="fianlKtForm"
      (ngSubmit)="buildDtoForSubmission()"
    >
      <div class="add-user-kt">
        <div class="sub-div">
          <form [formGroup]="userForm" (ngSubmit)="onAdd()">
            <table class="user-table">
              <tr class="header-row">
                <th class="user-title">Employee</th>
                <th class="user-title">Career Manager</th>
                <th class="user-title">Title</th>
                <th class="user-title">Capability</th>
                <th class="user-title">Base location</th>
              </tr>
              <tr>
                <td class="user-name">
                  {{ this.relievingEmployee.name | titlecase }}
                </td>
                <td class="user-name">
                  {{ this.relievingEmployee.cmName | titlecase }}
                </td>
                <td class="user-name">
                  {{ this.relievingEmployee.title | titlecase }}
                </td>
                <td class="user-name">
                  {{
                    this.relievingEmployee.capablity
                      | titlecase
                      | underScoreToSpace
                  }}
                </td>
                <td class="user-name">
                  {{ this.relievingEmployee.baseLocation | titlecase }}
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <div class="date-start">
                    <mat-form-field
                      appearance="outline"
                      class="example-full-width"
                    >
                      <mat-label
                        >KT Start Date <span class="contentRed"></span
                      ></mat-label>
                      <input
                        matInput
                        [matDatepicker]="picker1"
                        formControlName="startDate"
                        placeholder="dd/mm/yyyy"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker1"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                      <mat-error
                        *ngIf="userForm.get('startDate').hasError('required')"
                      >
                        Start date is required
                      </mat-error>
                    </mat-form-field>
                  </div>
                </td>
                <td>
                  <div class="date-start">
                    <mat-form-field
                      appearance="outline"
                      class="example-full-width"
                    >
                      <mat-label
                        >KT End Date <span class="contentRed"></span
                      ></mat-label>
                      <input
                        matInput
                        [matDatepicker]="picker2"
                        formControlName="endDate"
                        placeholder="dd/mm/yyyy"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker2"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker2></mat-datepicker>
                      <mat-error
                        *ngIf="userForm.get('endDate').hasError('required')"
                      >
                        End date is required
                      </mat-error>
                    </mat-form-field>
                  </div>
                </td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <mat-form-field
                    appearance="outline"
                    class="example-full-width"
                  >
                    <mat-label>Employee Name</mat-label>
                    <mat-select
                      formControlName="userName"
                      (selectionChange)="getUserID($event.value)"
                    >
                      <mat-option
                        *ngFor="let user of this.uList"
                        [value]="user"
                      >
                        {{ user }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="checkError">Required</mat-error>
                  </mat-form-field>
                </td>
                <td colspan="2">
                  <mat-form-field
                    appearance="outline"
                    class="example-full-width"
                  >
                    <mat-label>Select a Project</mat-label>
                    <mat-select
                      formControlName="projectName"
                      (selectionChange)="getProjectID($event.value)"
                    >
                      <mat-option
                        *ngFor="let project of this.projectList"
                        [value]="project"
                      >
                        {{ project | underScoreToSpace | titlecase }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="checkError">Required</mat-error>
                  </mat-form-field>
                </td>
                <td></td>
              </tr>
              <tr>
                <td colspan="3"></td>
                <td>
                  <button class="add_Emp_btn" type="submit">Add</button>
                </td>
                <td></td>
              </tr>
            </table>
          </form>
        </div>
      </div>

      <div class="add-user-kt" *ngFor="let detail of knowledgeTransferList">
        <div class="sub-div">
          <table class="user-table">
            <tr class="header-row">
              <th class="user-title">Employee Name</th>
              <th class="user-title">
                Employee nominated for knowledge transfer
              </th>
              <th class="user-title">Transition start date</th>
              <th class="user-title">Transition end date:</th>
              <th class="user-title">
                <button class="primary-btn" (click)="removeKtUser(detail)">
                  Remove
                </button>
              </th>
            </tr>
            <tr>
              <td class="user-name">
                {{ detail.relievingEmployee | titlecase }}
              </td>
              <td class="user-name">
                {{ detail.knowledgeTransferedTo | titlecase }}
              </td>
              <td class="user-name">{{ detail.startDate | date }}</td>
              <td class="user-name">{{ detail.endDate | date }}</td>
              <td class="user-name"></td>
            </tr>
          </table>
        </div>
      </div>

      <form [formGroup]="exitDetailsForm">
        <div
          class="add-user-kt"
          *ngFor="let field of this.knowledgeTransferFields; let i = index"
        >
          <div class="sub-div">
            <table class="user-table2">
              <thead>
                <tr class="heder-table-title">
                  <th colspan="4">
                    {{ field.title }}
                  </th>
                  <th>
                    <button class="primary-btn" (click)="addRow(field)">
                      +Add
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody *ngIf="!isTablecollapse">
                <tr>
                  <td class="user-title">Sr. No.</td>
                  <td class="user-title">Information</td>
                  <td class="user-title">
                    Comments (employee,who is giving the transition)
                  </td>
                  <td class="user-title">
                    Comments (employee,who is getting the transition)
                  </td>
                  <td class="user-title">Career manager comments</td>
                </tr>
                <tr *ngFor="let f of field.knowledgeTransferFields | keyvalue">
                  <td>
                    {{ f.key }}
                  </td>
                  <td
                    *ngIf="
                      field.knowledgeTransferFields[f.key].title != 'Others'
                    "
                  >
                    {{ field.knowledgeTransferFields[f.key].title }}
                  </td>
                  <td
                    *ngIf="
                      field.knowledgeTransferFields[f.key].title === 'Others'
                    "
                  >
                    <mat-form-field
                      appearance="outline"
                      class="example-form-field"
                    >
                      <input matInput [disabled]="false" type="text" />
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field
                      appearance="outline"
                      class="example-form-field"
                    >
                      <input matInput [disabled]="true" type="text" />
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field
                      appearance="outline"
                      class="example-form-field"
                    >
                      <input matInput [disabled]="true" type="text" />
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field
                      appearance="outline"
                      class="example-form-field"
                    >
                      <input matInput [disabled]="true" type="text" />
                    </mat-form-field>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>

      <div class="add-user-kt">
        <div class="sub-div">
          <table class="user-table2">
            <tr>
              <th colspan="4" class="heder-table-title">Other Tasks</th>
              <th>
                <button class="primary-btn" (click)="addRow1()">+Add</button>
              </th>
            </tr>
            <tr>
              <td class="user-title">Sr. No.</td>
              <td class="user-title">Information</td>
              <td class="user-title">
                Comments (employee,who is giving the transition)
              </td>
              <td class="user-title">
                Comments (employee,who is getting the transition)
              </td>
              <td class="user-title">Career manager comments</td>
            </tr>
            <tr *ngFor="let question of otherTaskList; let i = index">
              <td>
                {{ i + 1 }}
              </td>
              <td *ngIf="question != ''">
                {{ question }}
              </td>
              <td *ngIf="question === ''">
                <mat-form-field appearance="outline" class="example-form-field">
                  <input matInput [disabled]="false" type="text" />
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline" class="example-form-field">
                  <input matInput type="text" [disabled]="true" />
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline" class="example-form-field">
                  <input matInput type="text" [disabled]="true" />
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline" class="example-form-field">
                  <input matInput type="text" [disabled]="true" />
                </mat-form-field>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="add-user-kt-comments">
        <div>
          <h4 class="additional-input-header">Additional Comments</h4>
        </div>
        <textarea
          class="additional-input"
          rows="4"
          placeholder="Please provide any additional comments that you feel will be meaningful for (employee name) to sustain or improve his effectiveness."
          formControlName="additionalComments"
        ></textarea>
      </div>

      <div class="submit-div">
        <button type="submit" class="add_Emp_btn">Submit</button>
      </div>
    </form>
  </div>
</div>
