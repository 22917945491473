<div class="main-error-container">
  <app-error-header></app-error-header>
  <div class="error-container">
    <div class="error-logo-container">
      <div class="error-logo">
       <img src="assets/images/page-not-found.png"/>
      </div>
    </div>
    <div class="error-details-container">
      <div class="error-details">
        <div class="error-name">Error 404: Page Not Found</div>
        <div class="error-bar"></div>
        <div class="error-desc">
          The page you are looking for might have been moved or doesn't exist.
          Please check the URL
        </div>
        <a href="/login"><button class="error-white-btn">Back</button></a>
      </div>
    </div>
  </div>
</div>
