import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Project } from '../models/project';
import { RestAPIService } from '../services/restAPI/rest-api.service';
import { ApiService } from './api.service';
import { ClientService } from './client/client.service';
import { ProjectURLService } from './restAPI/projectURLs/project-url.service';
@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  public totalCount;
  public page: PageEvent;
  public pageCount = 1;
  public pageSize = 6;
  projects: Project[];
  multiselectProjects = [];
  public clientDto;
  resOfFeedBackAfterParse: any;
  constructor(
    private clientService: ClientService,
    private apiService: ApiService,
    private restApi: RestAPIService,
    private projectUrl: ProjectURLService
  ) {}
  getProjects() {
    this.apiService.getProjects().subscribe((res) => {
      this.setProjects(res);

    });
  }
  getProjectPagination() {
    this.restApi
      .get(this.projectUrl.getPaginationUrl(this.pageCount, this.pageSize))
      .subscribe((res) => {
        this.setProjects(res.body);
        this.totalCount = Number(res.headers.getAll('X-Total-Count'));
        
      });
  }
  getprojectfromclient(clientId) {
    return this.restApi.get(this.projectUrl.getprojectfromclient(clientId));
  }

  getProjectByLoggedInUserIdSubscribe(id) {
    return this.restApi.get(this.projectUrl.getUserByIdUrl(id));
  }
  getProjectsSubscribe() {
    return this.restApi.get(this.projectUrl.getBaseUrl());
  }

  getAllProjects() {
    return this.apiService.getProjects();
  }

  getProjectById(id) {
    return this.restApi.get(this.projectUrl.getByFieldUrl(id));
  }
  getProjectBySearch(searchText) {
    this.restApi
      .get(this.projectUrl.getSearchUrl(searchText))
      .subscribe((res) => {
        this.setProjects(res.body);
      });
  }

  setProjects(projects) {
    this.projects = [];
    this.multiselectProjects = [];
    for (let project of projects) {
      this.projects.push(new Project(project));
      this.multiselectProjects.push({
        display: project.projectName,
        value: project.id,
      });
    }
  }
  postProjectData(projectData) {
    return this.restApi.post(this.projectUrl.getBaseUrl(), projectData);
  }
  updateProject(projectData) {
    this.restApi.put(this.projectUrl.getBaseUrl(), projectData).subscribe();
  }

  updateProjectSubscribe(projectData) {
    return this.restApi.put(this.projectUrl.getBaseUrl(), projectData);
  }

  delete(id: any) {
    this.restApi.delete(this.projectUrl.getByFieldUrl(id)).subscribe((res) => {
      this.getProjectPagination();
    });
  }
  promise1
  async projectPresentOrNot()
  {
    
    this.promise1 = new Promise((resolve, reject) => {
      
      this.apiService.getProjects().subscribe((res) => {
        
             resolve(res)
         });
        })
        
        var resOfFeedBack = await this.promise1;
    var resOfFeedBackAsString = JSON.stringify(resOfFeedBack);
    this.resOfFeedBackAfterParse = JSON.parse(resOfFeedBackAsString);
  }
}
