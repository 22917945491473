import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-leave-warning',
  templateUrl: './leave-warning.html',
  styleUrls: ['./leave-warning.scss'],
})
export class LeaveWarning implements OnInit {
  leaveType: any;
  warning: string = '';

  constructor(
    public dialogRef: MatDialogRef<LeaveWarning>,
    @Inject(MAT_DIALOG_DATA) public data: any[]
  ) {}

  ngOnInit(): void {
    this.leaveType = this.data[0];
    this.warning = this.data[1];
  }

  closeDialog() {
    this.dialogRef.close();
  }

  okDialog() {
    this.dialogRef.close();
  }
}
