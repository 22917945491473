import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { WfoService } from 'src/app/services/work-from-office/wfo.service';
import { ToastrService } from 'ngx-toastr';
import { RefreshService } from 'src/app/services/refreshService/refresh.service';
import { locationOfWfo } from 'src/app/services/work-from-office/locationOfWfo-enum';
import { EventTabClosed, Status } from 'src/app/services/user/user-enum';

@Component({
  selector: 'app-apply-wfo',
  templateUrl: './apply-wfo.component.html',
  styleUrls: ['./apply-wfo.component.scss'],
})
export class ApplyWfoComponent implements OnInit {
  applyWFODetails: FormGroup;
  userid: string;
  minDate: Date;
  maxDate: Date;
  loading: boolean = false;
  errorMessage: string;
  locationOptions = Object.keys(locationOfWfo).map(key => ({ key: key, value: locationOfWfo[key] }));

  constructor(
    private dialogRef: MatDialogRef<ApplyWfoComponent>,
    private fb: FormBuilder,
    private userDetailService: UserDetailsService,
    private wfoService: WfoService,
    private toaster: ToastrService,
    private refreshService: RefreshService
  ) {
   
  }
  ngOnInit(): void {
    this.initiateComponent();
  }
 initiateComponent()
 {
  this.applyWFODetails = this.fb.group({
    startDate: [new Date(), [Validators.required]],
    location: ['', [Validators.required]],
  });
  this.maxDate = new Date(); 
  this.minDate = new Date();
  this.userid = this.userDetailService.getUserDetails().id;
 }
 
 checkLBandLAerrorFcn() {
  const endDateControl = this.applyWFODetails.get('endDate');
  const startDateControl = this.applyWFODetails.get('startDate');
  const startDate1 = this.applyWFODetails.get('startDate').value;
  const endDate1 = this.applyWFODetails.get('endDate').value;

  if (startDate1 != null && endDate1 != null && startDate1 > endDate1) {
    endDateControl.addValidators([Validators.pattern('[@]$')]);
    this.errorMessage = 'End date should be greater than Start date';
  } else {
    endDateControl.clearValidators();
    endDateControl.setValidators([Validators.required]);
  }
  endDateControl.updateValueAndValidity();
}

checkError = (controlName: string, errorName: string) => {
  return this.applyWFODetails.controls[controlName].hasError(errorName);
};
  closeDialog() {
    this.dialogRef.close({ event: EventTabClosed.Closed });
  }

  onSubmit() {
    if (this.applyWFODetails.valid && !this.loading) {
      this.loading = true;
      const formValues = this.applyWFODetails.value;
      const userTimeZoneOffset = new Date().getTimezoneOffset();
      const startDate = new Date(formValues.startDate);
      startDate.setMinutes(startDate.getMinutes() - userTimeZoneOffset);
      const endDate = new Date(formValues.endDate);
      endDate.setMinutes(endDate.getMinutes() - userTimeZoneOffset);

      const workingFromOfficeDTO = {
        appliedBy: this.userid,
        appliedOn: new Date().toISOString(),
        endDate: startDate,
        officeLocation: formValues.location,
        startingDate: startDate,
        workingFromOfficeStatus: Status.APPLIED,
      };

      const dto = {
        userId: this.userid,
        workingFromOfficeDTO,
      };

      this.wfoService
        .create(dto)
        .subscribe(
          (res) => {
            this.toaster.success('Successfully Applied', 'Saved');
            this.refreshService.triggerRefresh('wfo-detail');
            this.refreshService.triggerRefresh('taskdialog');
            this.refreshService.triggerRefresh('tasks');
            this.dialogRef.close();
          },
          (error) => {
            if (error.status === 409) {
              this.toaster.error(
                'You have already applied for Today!!',
                'Error',
                {}
              );
            } else if (error.status === 400) {
              this.toaster.error('You cannot apply on Weekend!!', 'Error', {});
            } else {
              this.toaster.error(
                'You can not apply on Mandatory Holiday!!',
                'Error',
                {}
              );
            }
            this.dialogRef.close();
          }
        )
        .add(() => {
          this.loading = false;
        });
    }
  }
}
