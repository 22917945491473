<div *ngIf="this.nodata == true">
    <div class="nothing-data">
        <img class="no-client-img" src="assets/images/no-data-project.png">
        <button class="Fetch-btn" [disabled]="syncButtonDisabled " (click)="onClickButton()"> <span class="fetch-msg">{{
                syncButtonDisabled ? 'Syncing...' : 'Sync' }}</span>
        </button>

    </div>
</div>


<div *ngIf="this.nodata == false ">
    <div class="main">
        <div class="col-md-12 pl-0  pr-0">
            <mat-card class="mat_card">
                <div class="search_main ">
                    <mat-icon class="search-icon  mt-1 mr-4">search</mat-icon>
                    <input class="search_input" type="search" placeholder="Search" [(ngModel)]="searchText" value="">
                </div>

                <div class="sync-btn hover-pointer">
                    <img class="icon" [disabled]="syncButtonDisabled " (click)="onClickButton()"
                    [matTooltip]="syncButtonDisabled ? 'Sync in progress, please wait.' : 'Click to sync'"
                    matTooltipPosition="left" src="assets/icons/retry.png"/>

                </div>
            </mat-card>
        </div>
        <div *ngIf="this.mobileview==false" class="card-container" fxLayout="row wrap" fxLayout.xs="column">


            <mat-card class="matcardprojectdetail" fxFlex="100%" fxFlex.lt-sm="0 1 calc(33.3% - 32px)"
                *ngFor="let client of this.clientList | filter:searchText | paginate: {id:'client_id', itemsPerPage: 5, currentPage: p } ">
                <mat-card-content (click)="viewClient(client)">
                    <div class="row">
                        <img *ngIf="this.client.clientImageThumbnail !== null "
                            src="data:image/png;base64,{{this.client.clientImageThumbnail}}" class="img-container">
                        <img *ngIf="this.client.clientImageThumbnail === null " src="assets/images/blue_altair_logo.png"
                            class="img-container">

                        <div class="col ml-4">
                            <div class="row">
                                <h5 class="client-name" style="text-transform: capitalize">{{client.clientName
                                    }} </h5>
                            </div>
                            <div class="row">
                                <h5 class="client-common">{{client.clientDescription
                                    }} </h5>
                            </div>
                        </div>
                        <div class="col coloums">
                            <h5 class="client-common">Client ID </h5>
                            <p class="client-name" style="text-transform:uppercase">{{client.clientId
                                }}</p>
                        </div>
                        <div class="col coloums">
                            <h5 class="client-common">Client Location </h5>
                            <p class="client-name" style="text-transform: capitalize">{{client.clientLocation
                                }}</p>
                        </div>
                        <div class="col coloums">
                            <h5 class="client-common">Project Count </h5>
                            <p class="client-name">{{this.projectcount[client.id] | number:'2.0-0'}}</p>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

        </div>
    </div>
    <div *ngIf="this.mobileview==true" class="card-container" fxLayout="row wrap" fxLayout.xs="column">
        <mat-card class="matcardprojectdetail" fxFlex="100%" fxFlex.lt-sm="0 1 calc(33.3% - 32px)"
            *ngFor="let client of this.clientList | filter:searchText | paginate: { itemsPerPage: 5, currentPage: p } ">
            <mat-card-content (click)="viewClient(client)">

                <div class="row">
                    <div class="col">
                        <img *ngIf="this.client.clientImageThumbnail !== null "
                            src="data:image/png;base64,{{this.client.clientImageThumbnail}}" class="img-container">
                        <img *ngIf="this.client.clientImageThumbnail === null " src="assets/images/blue_altair_logo.png"
                            class="img-container">
                    </div>
                </div>

                <div class="row">
                    <div class="col coloums">
                        <h5 class="client-common" style="text-transform: capitalize">{{client.clientName}} </h5>
                    </div>
                    <div class="col">
                        <p class="client-common"> {{client.clientDescription}}</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col coloums">
                        <h5 class="client-common">Client ID </h5>
                    </div>
                    <div class="col">
                        <p class="client-name" style="text-transform:uppercase">{{client.clientId
                            }}</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col coloums">
                        <h5 class="client-common">Client Location </h5>
                    </div>
                    <div class="col">
                        <p class="client-name" style="text-transform: capitalize"> {{client.clientLocation
                            }}</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col coloums">
                        <h5 class="client-common">Project Count </h5>
                    </div>
                    <div class="col">
                        <p class="client-name"> {{this.projectcount[client.id]}}</p>
                    </div>
                </div>


                <div class="row">
                    <div class="col coloums">
                        <h5 class="client-common">Onboarding Date </h5>
                    </div>
                    <div class="col">
                        <p class="client-name"> {{client.createdDate | date:
                            "dd-MM-yyyy" }}</p>
                    </div>
                </div>

            </mat-card-content>
        </mat-card>
    </div>

    <pagination-controls id='client_id' class="pagination" (pageChange)="p = $event"></pagination-controls>
</div>