import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { SkillService } from 'src/app/services/skill/skill.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/models/user';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Skill } from 'src/app/models/skill';
import { ToastrService } from 'ngx-toastr';
import { MatChipInputEvent } from '@angular/material/chips';
import { EventTabClosed } from 'src/app/services/user/user-enum';
@Component({
  selector: 'app-professional-skills',
  templateUrl: './professional-skills.component.html',
  styleUrls: ['./professional-skills.component.scss'],
})
export class ProfessionalSkillsComponent implements OnInit {
  @ViewChild('chipList1') chipList1;
  @ViewChild('chipList2') chipList2;

  editSpotlightDetails: FormGroup;
  saved: boolean = false;
  removedSkill: number[] = [];

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  constructor(
    private skillservice: SkillService,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private dialogRef: MatDialogRef<ProfessionalSkillsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: User
  ) {
  }
  ngOnInit(): void {
    this.getData();
  }
  getData() {
    this.editSpotlightDetails = this.fb.group({
      skills: this.fb.group({
        id: [null],
        userId: [''],
        primarySkills: this.fb.array([]),
        secondarySkills: this.fb.array([]),
      }),
    });
    this.skillservice.getskillByUserIdSubscribe(this.data).subscribe((res) => {
      if (res.body == null) {
      }
      this.setSkillFormArray(res.body);
    }, (e) => {
      this.toaster.error("Error fetching the details")
    });
  }
  get primarySkillsArray(): FormArray {
    return (this.editSpotlightDetails.get('skills') as FormGroup).get('primarySkills') as FormArray;
  }
  get secondarySkillsArray(): FormArray {
    return (this.editSpotlightDetails.get('skills') as FormGroup).get('secondarySkills') as FormArray;
  }
  add(event: MatChipInputEvent, isPrimary): void {
    
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      isPrimary ? this.primarySkillsArray.push(this.fb.control(value.trim(), [Validators.required,Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9+#. -]*$')])) : this.secondarySkillsArray.push(this.fb.control(value.trim(), [Validators.required,Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9+#. -]*$')]));
    }
    if (input) {
      input.value = '';
    }
    if(this.primarySkillsArray.invalid ){
      this.chipList1.errorState=true;
    }
    if(this.secondarySkillsArray.invalid){
      this.chipList2.errorState=true;
    }
  }
  remove(skillIndex: number, isPrimary): void {
    isPrimary ? this.primarySkillsArray.removeAt(skillIndex) : this.secondarySkillsArray.removeAt(skillIndex);
    if(!this.primarySkillsArray.invalid ){
      this.chipList1.errorState=false;
    }
    if(!this.secondarySkillsArray.invalid){
      this.chipList2.errorState=false;
    }
  }
  addPredefinedSkills(predefinedSkills: Skill): void {
    const primarySkillsArray = this.primarySkillsArray;
    const secondarySkillsArray = this.secondarySkillsArray;
    predefinedSkills.primarySkills.forEach(skill => {
      primarySkillsArray.push(this.fb.control(skill));
    });
    predefinedSkills.secondarySkills.forEach(skill => {
      secondarySkillsArray.push(this.fb.control(skill));
    });
  }
  setSkillFormArray(response: any): void {
    const skillList: Skill = this.skillservice.setSkillResponse(response);;
    this.editSpotlightDetails.get('skills').patchValue({ id: response.id, userId: response.userId });
    this.addPredefinedSkills(skillList);
  }
  onSubmit(): void {
    if (this.editSpotlightDetails.valid) {
      let skill = this.editSpotlightDetails.get('skills').value;
      if (skill.id === null || skill.id === '') {
        skill.userId = this.data;
        this.skillservice.create(skill).subscribe((res) => {
          this.dialogRef.close({ event: EventTabClosed.Closed });
        }, (e) => {
          this.toaster.error("Error Saving the details")
        });
      } else {
        this.skillservice.update(skill).subscribe((res) => {
          this.dialogRef.close({ event: EventTabClosed.Closed });
        }, (e) => {
          this.toaster.error("Error updating the details")
        });
      }
    }
    else {
      this.toaster.error("Invalid Skills")
    }
  }
  closeDialog(event: Event) {
    this.dialogRef.close({ event: EventTabClosed.Closed })
  }
}
