import { Injectable } from '@angular/core';
import { RestAPIService } from '../rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class UsergoalurlService {
  private readonly userGoalBaseApi = '/api/user-goals';
  private readonly search = '/search';
  private readonly user = '/user';

  constructor(private restApi: RestAPIService) {}

  getBaseUrl() {
    return this.userGoalBaseApi;
  }

  getSearchUrl(searchText: string) {
    return this.userGoalBaseApi + this.search + this.getByField(searchText);
  }

  //This will be used for get by id and delete by id
  getByFieldUrl(field) {
    return this.userGoalBaseApi + this.getByField(field);
  }

  getUserIdUrl(userId){
    return this.userGoalBaseApi +this.user+'/'+userId;
  }

  getUserIdAndYearUrl(userId,year)
{
  return this.userGoalBaseApi +this.user+'/'+userId+'/'+year;
}

  private getByField(field) {
    return '/' + field;
  }

}
