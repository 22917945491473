import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormSchemaDTO } from 'src/app/dtos/FormSchemaDTO';
import { ProjectDTO } from 'src/app/dtos/ProjectDTO';
import { FormsSchemaService } from 'src/app/services/forms/forms-schema.service';
import { EditAppComponent } from '../../edit-app/edit-app.component';

@Component({
  selector: 'app-create-forms-dialog',
  templateUrl: './create-forms-dialog.component.html',
  styleUrls: ['./create-forms-dialog.component.scss'],
})
export class CreateFormsDialogComponent implements OnInit {
  formTemplatesModel: any = [];
  public temp: any[];
  public inc: number = 0;
  public sendValue;
  public newFormSchema = new FormSchemaDTO();
  public project = new ProjectDTO();
  public formDataLength;
  public formCardFlag: boolean;
  constructor(
    private dialog: MatDialog,
    config: NgbCarouselConfig,
    public dialogRef: MatDialogRef<CreateFormsDialogComponent>,
    public editDialogRef: MatDialogRef<CreateFormsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formService: FormsSchemaService
  ) {
    config.interval = 10000;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = false;
  }

  ngOnInit(): void {
    if (this.editDialogRef.componentInstance.data != null) {
      this.project = this.editDialogRef.componentInstance.data;
      let projectId = this.project.id;
      this.formService
        .getFormByProjectIdSubscribe(projectId)
        .subscribe((res) => {
          this.formDataLength = res.body.length;
          if (this.project.id != null) {
            this.newFormSchema = res.body;
            for (let index = 0; index < this.formDataLength; index++) {
              this.formTemplatesModel.push(
                JSON.parse(res.body[index].formTemplate)
              );
            }
          }

          this.formTemplatesModel.length > 0
            ? (this.formCardFlag = true)
            : (this.formCardFlag = false);
        });
    }
  }

  onAddFormTemplate() {
    this.openDialog();

    this.temp = this.formTemplatesModel;

    this.inc++;
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '95%';
    dialogConfig.height = '90%';
    data: {
      pageValue: this.sendValue;
    }
    let dialogRef = this.dialog.open(EditAppComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      this.newFormSchema.formTemplate = result;
      if (result != undefined) {
        this.formTemplatesModel.push(result);
      }
      this.formTemplatesModel.length > 0
        ? (this.formCardFlag = true)
        : (this.formCardFlag = false);
    });
  }

  onClose() {
    this.dialogRef.close();
    this.editDialogRef.close();
  }
  onSave() {
    this.saveForm(this.formTemplatesModel);
    this.dialogRef.close();
    this.editDialogRef.close();
  }

  saveForm(feedbackFormInfo) {

    for (let index = 0; index < this.formTemplatesModel.length; index++) {
      if (index > this.formDataLength - 1) {
        feedbackFormInfo.projectId = this.project.id;
        feedbackFormInfo.formTemplate = JSON.stringify(
          this.formTemplatesModel[index]
        );
        feedbackFormInfo.formType =
          this.formTemplatesModel[index][0].description;

        this.formService.addNewFormSchema(feedbackFormInfo);
      } else {
        //put call only if form is changed
        feedbackFormInfo.id = this.newFormSchema[index].id;
        feedbackFormInfo.formTemplate = JSON.stringify(
          this.formTemplatesModel[index]
        );
        feedbackFormInfo.formType =
          this.formTemplatesModel[index][0].description;
        this.formService.updateFeedbackForm(feedbackFormInfo);
      }
    }
  }
}
