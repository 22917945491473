import { Injectable } from '@angular/core';
import { BlueAltairTravel } from 'src/app/models/bluealtairtravel';
import { BluealtairtravelUrlService } from '../restAPI/blueAltairTravelURLs/bluealtairtravel-url.service';
import { RestAPIService } from '../restAPI/rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class BluealtairtravelService {
  constructor(private restApiService: RestAPIService,
    private bluealtair : BluealtairtravelUrlService) { }
    create(payload) {
      return this.restApiService.post(
        this.bluealtair.getBaseUrl(),
        payload
      )
    }
    update(payload){
      return this.restApiService.put(
        this.bluealtair.getBaseUrl(),
        payload
      )
    }
    gettravelByUserIdSubscribe(userId) {
      return this.restApiService.get(
        this.bluealtair.getUserUrl(userId)
      )
    }
    settravelResponse(response):BlueAltairTravel[]{
      let travelList:BlueAltairTravel[] = []
      for(let data of response){
          let travel:BlueAltairTravel = new BlueAltairTravel()
          travelList.push(
            travel.bind(data)          
          )
      }
      return travelList
    }
    delete(id: any) {
      this.restApiService.delete(
        this.bluealtair.getByFieldUrl(id)
      ).subscribe(res=>{
     
      })
    }
}
