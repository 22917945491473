import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { User } from 'src/app/models/user';
import { AttendanceUrlService } from 'src/app/services/attendanceURLs/attendance-url.service';
import { AttendanceStatus } from 'src/app/services/attendance copy/attendance-enum';
import { AttendanceService } from 'src/app/services/attendance copy/attendance.service';
import { RestAPIService } from 'src/app/services/restAPI/rest-api.service';
import { UitoggleService } from 'src/app/services/uitoggle.service';
import { UserService } from 'src/app/services/user/user.service';
import { OrderPipe } from 'ngx-order-pipe';
import { isThisSecond, isToday, parse } from 'date-fns';
import { EmployeeCardComponent } from '../employee-card/employee-card.component';
import { FormControl } from '@angular/forms';
import { Departments, JobTitles } from 'src/app/services/user/user-enum';
import { WorkBook, utils, write } from 'xlsx';
import * as XLSX from 'xlsx';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-employee-unmarked',
  templateUrl: './employee-unmarked.component.html',
  styleUrls: ['./employee-unmarked.component.scss'],
})
export class EmployeeUnmarkedComponent implements OnInit {
  @Input() public index;
  @Input() data;
  date1 = new FormControl(new Date());
  serializedDate = new FormControl(new Date().toISOString());
  userDto: User[] = [];
  searchText: string;
  careerManagerIdName = {};
  p: number = 1;
  careerManagerName;
  isVisible = false;
  caseInsensitive: boolean = false;
  reverse: boolean = false;
  order: string = 'info.name';
  display: string;
  dataSource: any;

  reverseFlag = -1;
  today;

  excelArray = [];
  excelArrayOpen = [];
  excelArrayClosed = [];
  t1 = [];
  t2 = [];
  t3 = [];
  public finalExcelFile: Blob;

  jobTitles: typeof JobTitles;
  departments: typeof Departments;
  date = [
    String(new Date().getDate()).padStart(2, '0'),
    String(new Date().getMonth() + 1).padStart(2, '0'),
    new Date().getFullYear(),
  ].join('-');
  public getScreenWidth: any;
  public getScreenHeight: any;
  mobileview: boolean = false;
  constructor(
    private restApi: RestAPIService,
    private attendanceUrl: AttendanceUrlService,
    private orderPipe: OrderPipe,
    public userService: UserService,
    private uitoggle: UitoggleService,
    public attendanceService: AttendanceService
  ) {}
  FinalMonth: any;
  FinalDay: any;
  ngOnInit(): void {
    this.jobTitles = JobTitles;
    this.departments = Departments;

    this.today = new Date();
    this.function(this.date);
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth > 600) {
      this.mobileview = false;
    }
    if (this.getScreenWidth < 600) {
      this.mobileview = true;
    }
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth > 600) {
      this.mobileview = false;
    }
    if (this.getScreenWidth < 600) {
      this.mobileview = true;
    }
  }
  OnPageClick(event) {
    this.userService.pageCount = event.pageIndex;
    this.userService.pageCount += 1;
    this.userService.getUser('Asc');
  }

  updateDOB(dateObject) {
    // convert object to string then trim it to yyyy-mm-dd

    const dob = [
      String(dateObject.value.getDate()).padStart(2, '0'),
      String(new Date(dateObject.value).getMonth() + 1).padStart(2, '0'),
      new Date().getFullYear(),
    ].join('-');

    this.function(dob);
  }

  isDateToday(dateString: string): boolean {
    const inputDate = parse(dateString, 'dd-MM-yyyy', new Date());
    return isToday(inputDate);
  }

  function(date) {
    this.userDto.length = 0;
    this.attendanceService
      .getAttendance(
        this.isDateToday(date)
          ? AttendanceStatus.ABSENT
          : AttendanceStatus.UNMARKED_ATTENDANCE,
        date
      )
      .subscribe((response) => {
        response.body.forEach((data) => {
          if (data.userImageThumbnail !== '') {
            data.userImageThumbnail =
              'data:image/png;base64,' + data.userImageThumbnail;
          }
          this.userDto.push(new User(data));
        });
        if (this.userDto.length != 0) {
          this.isVisible = true;
        }
        this.reverseOrder();

        for (let user of this.userDto) {
          if (user.careerManagerId !== null) {
            this.userService
              .getUserNameByID(user.careerManagerId)
              .subscribe((res) => {
                this.careerManagerIdName[user.careerManagerId] = res;
              });
          }
        }
      });
  }
  loaddata(eve) {}
  getAttendance(attendanceStatus, date) {
    return this.restApi.get(
      this.attendanceUrl.getAttendanceUrl(attendanceStatus, date)
    );
  }
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
      5;
    }
    this.order = value;
  }

  reverseOrder() {
    this.reverseFlag = this.reverseFlag * -1;
    this.userDto = this.userDto.sort((a, b) => {
      if (a.firstName < b.firstName) {
        return -this.reverseFlag;
      }
      if (a.firstName > b.firstName) {
        return this.reverseFlag;
      }
      return 0;
    });
  }

  file() {
    const unmarkedEmployeesExcelList: any[] = [];
    const observables: Observable<any>[] = [];

    let serialNumber = 1; // Initialize the serial number

    for (const user of this.userDto) {
      const fullName = user.firstName + ' ' + user.lastName;
      const employeeId = user.employeeId;
      const jobTitle = user.jobTitle;
      const department = user.department;

      if (user.careerManagerId !== null) {
        const observable = this.userService
          .getUserNameByID(user.careerManagerId)
          .pipe(
            map((res) => {
              this.careerManagerIdName[user.careerManagerId] = res;
              const careerManagerName =
                this.careerManagerIdName[user.careerManagerId];
              return careerManagerName;
            })
          );
        observables.push(observable);

        observable.subscribe((careerManagerName) => {
          const rowData = {
            'Sr. No.': serialNumber++,
            'Full Name': fullName,
            'Employee ID': employeeId,
            Title: jobTitle,
            Capability: department,
            'Career Manager': careerManagerName,
          };

          unmarkedEmployeesExcelList.push(rowData);
        });
      }
    }

    forkJoin(observables).subscribe(() => {
      const holidaysDataForExcel: any[] = [];
      this.finalExcelFile = this.generateExcelFile(unmarkedEmployeesExcelList);
      this.saveAsExcelFile(this.finalExcelFile, 'unmarked-employee.xlsx');
    });
  }

  generateExcelFile(formArrayData: any[]): Blob {
    const worksheet = utils.json_to_sheet(formArrayData);
    const workbook: WorkBook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Template');
    const excelBuffer: any = write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    return new Blob([excelBuffer], { type: 'application/octet-stream' });
  }

  saveAsExcelFile(buffer: any, filename: string) {
    // to download excel
    const data = new Blob([buffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(url);
  }
}
