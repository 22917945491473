import { FeedbackRole } from "../services/feedback/feedback-enum";
export class NewFeedback {
    public id:string
       public feedbackFrom:string;
       public weightage:Number;
       public calibratedScore:Number
       public feedbackRole:FeedbackRole;
       public clientEmail:string;
       public score:Number
       constructor() {
        this.feedbackFrom="";
        this.weightage=0;
        this.feedbackRole;
        this.score=0;
        this.calibratedScore=0;
        this.feedbackRole=FeedbackRole.CAREER_MANAGER
    }
}