<mat-card class="main-card">
  <div class="row">
    <div class="col-md-12">
      <div class="main-header">
        <div class="svg-heading-div">
          <img class="submodule-svg" src="assets/icons/dashboard-icons/holiday-icon.svg" />
          <div class="head">Holidays</div>
        </div>
        <div class="date-color">{{ selectedMonth }}-{{ selectedYear }}</div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <!--  holidays with empty data -->
      <div class="tableFixHead-empty" *ngIf="!restrictedHolidays || restrictedHolidays.length == 0">
        <div class="headingOne">
          <div class="no-data-image">
            <img src="assets/icons/dashboard-icons/white-holiday-icon.svg" />
          </div>
          <div class="no-data-text">No Holidays this month.</div>
        </div>
      </div>

      <!--  holidays with data -->
      <div class="tableFixHead" *ngIf="restrictedHolidays && restrictedHolidays.length != 0">
        <div *ngFor="let holiday of restrictedHolidays | orderBy : 'date'">
          <div class="single-reportee-detail-container">
            <div class="col-8">
              <div class="upcoming-holidays">
                <span class="holiday_date">{{ holiday.date }}</span>
                <br />
                <span class="holiday_name">{{ holiday.desc }}</span>
              </div>
            </div>
            <div class="col-4 apply-btn-div">
              <div *ngIf="!holiday.status" class="apply apply-leave-button-dark">Applied</div>
              <button class="blue-border-button"
                *ngIf="holiday.status && holiday.calendarLeaveType == 'FLOATER_HOLIDAY'"
                (click)="applyRestrictedHoliday(holiday.desc, holiday.originalDate)">
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>