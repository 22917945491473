import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FeedbackNotesService } from 'src/app/services/feedback-notes/feedback-notes.service';
import { NewFeedbackGroupService } from 'src/app/services/new-feedback-group.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { FeedbackNotesDTO } from 'src/app/dtos/FeedbackNotesDTO';
import { PreviewFeedbackComponent } from '../../preview-feedback/preview-feedback.component';
import { ProbationFeedbackSummaryDTO } from 'src/app/dtos/ProbationFeedbackSummaryDTO';

@Component({
  selector: 'app-porbation-feedback-closure',
  templateUrl: './porbation-feedback-closure.component.html',
  styleUrls: ['./porbation-feedback-closure.component.scss'],
})
export class ProbationFeedbackClosureComponent implements OnInit {
  public Editor = ClassicEditor;
  feedbackClosure: FormGroup;
  userdto: UserDTO;
  feedbackdto = new FeedbackNotesDTO();
  feedbackNotesDto = new FeedbackNotesDTO();
  probationFeedbackSummaryDTO = new ProbationFeedbackSummaryDTO();

  data: any;
  config = {
    toolbar: [
      'undo',
      'redo',
      '|',
      'heading',
      'fontFamily',
      'fontSize',
      '|',
      'bold',
      'italic',
      'underline',
      'fontColor',
      'fontBackgroundColor',
      'highlight',
      'NumberedList',
      'BulletedList',
      'RemoveFormat',
      '|',
    ],
  };

  constructor(
    public dialogRef: MatDialogRef<ProbationFeedbackClosureComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public userIdYear: any,
    private dialog: MatDialog,
    private feedbackgroup: NewFeedbackGroupService,
    public feedbackNotesService: FeedbackNotesService,
    public toastr: ToastrService
  ) {
    this.feedbackClosure = this.fb.group({
      strengthAreas: [''],
      developmentAreas: [''],
      action: [''],
    });
  }

  ngOnInit(): void {
    this.feedbackNotesService
      .getProbationFeedbackSummary(
        this.userIdYear.directReporteeId,
        this.userIdYear.selected
      )
      .subscribe((res) => {
        this.feedbackdto = res.body;
        this.setFeedbackClosureNote();
      });
  }
  setFeedbackClosureNote() {
    this.feedbackNotesService
      .getProbationFeedbackSummary(
        this.userIdYear.directReporteeId,
        this.userIdYear.selected
      )
      .subscribe((res) => {
        this.feedbackClosure
          .get('strengthAreas')
          .setValue(res.body.feedbackSummary.strengths);
        this.feedbackClosure
          .get('developmentAreas')
          .setValue(res.body.feedbackSummary.developments);
        this.feedbackClosure
          .get('action')
          .setValue(res.body.feedbackSummary.actionItems);
      });
  }
  closeDialog() {
    this.dialogRef.close();
  }

  preview() {
    this.feedbackClosure;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '70rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      directReporteeId: this.userIdYear.directReporteeId,
      selected: this.userIdYear.selected,
      userdto: this.userIdYear.userdto,
      isClosedVisible: false,
      isProbation: true,
    };
    const dialogRef = this.dialog.open(PreviewFeedbackComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  callSaveApi(isPreview) {
    var objForPayload = {};
    var data = [];
    this.probationFeedbackSummaryDTO.actionItems =
      this.feedbackClosure.value.action;
    this.probationFeedbackSummaryDTO.developments =
      this.feedbackClosure.value.developmentAreas;
    this.probationFeedbackSummaryDTO.strengths =
      this.feedbackClosure.value.strengthAreas;
    objForPayload['feedbackSummary'] = this.probationFeedbackSummaryDTO;

    objForPayload['ccEmails'] = data;

    this.feedbackgroup
      .closeProbationFeedBackSaveNote(
        this.userIdYear.directReporteeId,
        this.userIdYear.selected,
        true,
        objForPayload
      )
      .subscribe(
        (res) => {
          if (res.body == 0) {
            this.toastr.success('Success', 'Success');
          } else if (res.body == 1) {
            this.toastr.error(
              'All Feedback Group weightage Sum should be 100%',
              'failed'
            );
            this.dialogRef.close();
          } else if (res.body == 2) {
            this.toastr.error('All feedback must be submitted', 'failed');
            this.dialogRef.close();
          } else if (res.body == 3) {
            this.toastr.error(
              'All feedback weightage sum should be 100%',
              'failed'
            );
            this.dialogRef.close();
          } else if (res.body == 4) {
            if (isPreview) {
              this.preview();
            }
            this.dialogRef.close();
          }
        },
        (error) => {
          this.toastr.error(
            'No feedback generated for user for the year',
            'failed'
          );
        }
      );
  }
}
