import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ExitDetailsDTO } from 'src/app/dtos/ExitDetailsDTO';
import { ResignationDetails } from 'src/app/models/resignationdetails';
import { SubmitResignationService } from 'src/app/services/submit-resignation/submit-resignation.service';
import { UserService } from 'src/app/services/user/user.service';
import { ResignationEmailDialogComponent } from './resignation-email-dialog/resignation-email-dialog.component';
import { emails } from 'src/app/services/email-service/emails';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-resignationemail',
  templateUrl: './resignationemail.component.html',
  styleUrls: ['./resignationemail.component.scss'],
})
export class ResignationemailComponent implements OnInit {
  public resignationform: FormGroup;
  public careerManagerEmailId: any;
  public currentDate: any;
  public userid: any;
  public userDepartment: any;
  public PnTMail: any = emails.PNT_MAIL;
  public capablityLeadMail: any;

  exitDetailsDTO: ExitDetailsDTO = new ExitDetailsDTO();
  public datePipe: DatePipe;
  emailError: string;
  constructor(
    public dialogRef: MatDialogRef<ResignationemailComponent>,
    public dialog: MatDialog,
    public userService: UserService,
    public fb: FormBuilder,
    public toaster: ToastrService,

    private userDetailService: UserDetailsService,

    public submitResignationService: SubmitResignationService,
    @Inject(MAT_DIALOG_DATA) public data: ResignationDetails
  ) {
    this.resignationform = this.fb.group({
      exitReason: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.currentDate = new Date();
    this.getCareerManagerEmailId();
    this.userid = this.userDetailService.getUserDetails().id;
    this.userService.getUserByID(this.userid).subscribe((res) => {
      this.userDepartment = res.body.department;
      this.userService
        .getCapablityLeadEmailId(this.userDepartment)
        .subscribe((data) => {
          this.capablityLeadMail = data.body.Mail;
        });
    });
  }

  checkError = (controlName: string, errorName: string) => {
    return this.resignationform.controls[controlName].hasError(errorName);
  };

  closeDialog() {
    this.dialogRef.close();
  }

  getCareerManagerEmailId() {
    this.userService
      .getCareerManagerEmailId()
      .subscribe((data) => (this.careerManagerEmailId = data.body.email));
  }

  onsubmit() {
    if (this.resignationform.valid) {
      this.exitDetailsDTO.resignationDate = this.currentDate;
      this.exitDetailsDTO.ccEmails =
        this.careerManagerEmailId +
        ',' +
        this.PnTMail +
        ',' +
        this.capablityLeadMail;
      this.exitDetailsDTO.exitReason =
        this.resignationform.get('exitReason').value;
    }
    this.postExitDetails(this.exitDetailsDTO);
  }

  postExitDetails(exitDetailsDTO) {
    this.submitResignationService.submitResignation(exitDetailsDTO).subscribe(
      (result) => {
        this.toaster.success('Resignation applied successfully', '');
        this.dialogRef.close();
      },
      (e) => {
        this.toaster.warning('Reason cannot be blank', 'warning');
      }
    );
  }

  setFormControlError(
    controlName: string,
    controlError: string,
    errorMsg: string
  ) {
    this.resignationform.get(controlName).markAsTouched({ onlySelf: true });
    this.resignationform.get(controlName).markAsDirty({ onlySelf: true });
    this.resignationform.get(controlName).setErrors({ [controlError]: true });
    this.emailError = errorMsg;
  }
}
