import { Component, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { BulkEmployeeImportService } from 'src/app/services/bulkImport/bulk-employee-import.service';
import { BulkSummaryComponent } from '../bulk-summary/bulk-summary.component';

@Component({
  selector: 'app-bulk-import-summary',
  templateUrl: './bulk-import-summary.component.html',
  styleUrls: ['./bulk-import-summary.component.scss'],
})
export class BulkImportSummaryComponent implements OnInit {
  public fileName = '';

  public xlFile: File;
  public response: any;
  public allfileContent: any;
  public badFileFormat: boolean;
  public displayStyle = 'none';
  public confirmImportFlag: boolean;
  public uploadBtnLabel = 'Select File';
  public hidebutton = true;
  public loading = false;

  constructor(
    public bulkService: BulkEmployeeImportService,
    private dialogref: MatDialogRef<BulkImportSummaryComponent>,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}
  closeDialog() {
    this.dialogref.close();
  }

  //Read selected file's name, and assign xlFile the selected file, and set fileName to file name to display on screen
  readFile(event: Event) {
    this.badFileFormat = false;

    this.xlFile = event[0];
    if (this.xlFile) {
      this.fileName = this.xlFile.name;
    }
  }

  //On clicking upload button, create form data with the selected xl file, and call the service to trigger the API
  uploadFile() {
    this.confirmImportFlag = false;
    const formData = new FormData();
    formData.append('file', this.xlFile);
    this.uploadBtnLabel = 'Importing Users...';
    this.fileName = 'Please wait we are processing your request';
    this.bulkService.uploadBulkEmployees(formData).subscribe(
      (data) => {
        let result = data.body.message;

        this.response = JSON.parse(result);

        let filecontent = {
          Successful: '',
          Failed: '',
          Already_Existing_Email_Ids: '',
          Invalid_CM_Email_Ids: '',
          Invalid_TB_Email_Ids: '',
        };
        filecontent.Successful = this.response.Successful;
        filecontent.Failed = this.response.Failed;
        filecontent.Already_Existing_Email_Ids =
          this.response.Already_Existing_Email_Ids;
        filecontent.Invalid_CM_Email_Ids = this.response.Invalid_CM_Email_Ids;
        filecontent.Invalid_TB_Email_Ids = this.response.Invalid_TB_Email_Ids;
        this.allfileContent = filecontent;
        this.openDialog();
        this.uploadBtnLabel = 'Select File';
        this.fileName = '';
      },
      (error) => {
        //To display Bad File Format message on the screen
        this.badFileFormat = true;
        this.loading = false;
        this.uploadBtnLabel = 'Select File';
        this.fileName = '';
      }
    );

    //Reset variables
    this.allfileContent = '';
    this.response = '';
    this.xlFile = null;
    this.loading = true;
  }

  openDialog(): void {
    this.closeDialog();
    //Set Mat-dialog configuration, assign data to it and display it
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {};

    dialogConfig.data = {
      Successful: this.allfileContent.Successful,
      Failed: this.allfileContent.Failed,
      Already_Existing_Email_Ids:
        this.allfileContent.Already_Existing_Email_Ids,
      Invalid_CM_Email_Ids: this.allfileContent.Invalid_CM_Email_Ids,
      Invalid_TB_Email_Ids: this.allfileContent.Invalid_TB_Email_Ids,
    };

    dialogConfig.height = '60%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'bulk-employee';
    const dialogRef = this.dialog.open(BulkSummaryComponent, dialogConfig);
  }

  openPopup() {
    this.hidebutton = false;
  }
  loadinglogo() {
    this.loading = true;
  }

  closePopup() {
    this.dialogref.close();
  }
}
