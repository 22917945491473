import { Injectable } from '@angular/core';
import { Education } from 'src/app/models/educationdetails';
import { EducationDetailsUrlService } from '../restAPI/educationDetailsURLs/educationdetails-url.service';
import { RestAPIService } from '../restAPI/rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class EducationDetailsService {
  constructor(private restApiService: RestAPIService,
    private educationurl : EducationDetailsUrlService) { }
    create(payload) {
      return this.restApiService.post(
        this.educationurl.getBaseUrl(),
        payload
      )
    }
    update(payload){
      return this.restApiService.put(
        this.educationurl.getBaseUrl(),
        payload
      )
    }
    getskillByUserIdSubscribe(userId) {
      return this.restApiService.get(
        this.educationurl.getUserUrl(userId)
      )
    }
    setEducationResponse(response):Education[]{
     
      let educationList:Education[] = []
      for(let data of response){
          let education:Education = new Education()
          educationList.push(
            education.bind(data)          
          )
      }
      return educationList
    }
    delete(id: any) {
      this.restApiService.delete(
        this.educationurl.getByFieldUrl(id)
      ).subscribe(res=>{
      })
    }
}
