import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import {
  FeedbackSubmitStatus,
  FeedbackReadStatus,
} from 'src/app/services/feedback/feedback-enum';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-feedback-tabs',
  templateUrl: './feedback-tabs.component.html',
  styleUrls: ['./feedback-tabs.component.scss'],
})
export class FeedbackTabsComponent implements OnInit {
  public pendingFeedbacksList: [];
  public submittedFeedbacksList: [];
  public disableEdit: Boolean = false;

  constructor(
    public feedbackService: FeedbackService,
    private spinnerService: SpinnerService,
    private userDetailsService: UserDetailsService
  ) {}

  ngOnInit(): void {
    this.feedbackService
      .getFeedbackByUserIdAndStatus(
        this.userDetailsService.getUserDetails().id,
        FeedbackReadStatus.UNREAD,
        FeedbackSubmitStatus.PENDING
      )
      .subscribe((res) => {
        this.feedbackService.setFeedbackPendingList(res.body);
      });

    this.feedbackService
      .getFeedbackByUserIdAndStatus(
        this.userDetailsService.getUserDetails().id,
        FeedbackReadStatus.READ,
        FeedbackSubmitStatus.SUBMITTED
      )
      .subscribe((res) => {
        this.feedbackService.setFeedbackSubmittedList(res.body);
      });
  }

  background: ThemePalette = 'primary';

  onClickSubmittedTab(event) {
    if (event.index == 1) {
      this.feedbackService
        .getFeedbackByUserIdAndStatus(
          this.userDetailsService.getUserDetails().id,
          FeedbackReadStatus.READ,
          FeedbackSubmitStatus.SUBMITTED
        )
        .subscribe((res) => {
          this.submittedFeedbacksList = res.body;
        });
    } else {
      this.feedbackService
        .getFeedbackByUserIdAndStatus(
          this.userDetailsService.getUserDetails().id,
          FeedbackReadStatus.UNREAD,
          FeedbackSubmitStatus.PENDING
        )
        .subscribe((res) => {
          this.pendingFeedbacksList = res.body;
        });
    }
  }
}
