import { Component, Inject ,OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RegularizationService } from 'src/app/services/regularization/regularization.service';

@Component({
  selector: 'app-withdraw-regularization',
  templateUrl: './withdraw-regularization.component.html',
  styleUrls: ['./withdraw-regularization.component.scss']
})
export class WithdrawRegularizationComponent implements OnInit {
  withdrawnDetails: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<WithdrawRegularizationComponent>,
    private regularizeService :RegularizationService
  ) {
    this.withdrawnDetails = new FormGroup({
      reasonForRejection: new FormControl(),
    });
   }

  ngOnInit(): void {
  }
  closeDialog(event: string) {
    this.dialogRef.close({ event });
  }
  revokeLeave() {
    const reason = this.withdrawnDetails.get('reasonForRejection').value;
    this.regularizeService.withdrawnRegularizationRequest('',this.data.leaveDetail.id)
      .subscribe(
        (res) => {
          this.closeDialog('success');
        },
        () => {
          this.closeDialog('error');
        }
      );
  }
}
