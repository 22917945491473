import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {CalendarOptions, EventInput} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import {FullCalendarComponent} from '@fullcalendar/angular';
import {UserDetailsService} from 'src/app/services/user-details.service';
import {HolidaysEventService} from 'src/app/services/leaveManagementCalendar/holidays-event.service';
import {LeaveBalanceService} from 'src/app/services/leaveBalances/leave-balance.service';
import * as moment from 'moment';
import {ApplyHolidayComponent} from './apply-holiday/apply-holiday.component';
import {RegularizeComponent} from '../attendance-calendar/attendance-details/regularize/regularize.component';
import {AbsentService} from 'src/app/services/absent/absent.service';
import {Router} from '@angular/router';
import {TabNavigationService} from 'src/app/services/tab-navigation/tab-navigation.service';
import {AttendanceStatus} from 'src/app/services/attendance-extra/attendance-enum';

@Component({
  selector: 'app-holiday-calendar',
  templateUrl: './holiday-calendar.component.html',
  styleUrls: ['./holiday-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LeaveCalendarComponent implements OnInit {
  @ViewChild('fullCalendar') fullCalendar!: FullCalendarComponent;
  months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December',];
  years: number[] = [];
  selectedMonth: string = this.months[new Date().getMonth()];
  selectedYear: number = new Date().getFullYear();
  leaveBalanceYear: number = new Date().getFullYear();
  val: any[] = [];
  selectedItems: any[] = [];
  selectAllChecked = false;
  item: any[] = [];
  loggedInUser: any;
  totalHoliday: any = [];
  eventData: EventInput[] = [];
  restrictedHolidays: { date: any; desc: any; originalDate: any }[] = [];
  generalAndRestricted: { date: any; desc: any; originalDate: any }[] = [];
  perYearLeaves: any = '-';
  availableLeaves: any = '-';
  userid: any;
  leavebalanceData: any;
  UnmarkStatus: string = 'UNMARKED_ATTENDANCE';
  LeaveStatus: string = 'LEAVE';
  restrictedStatus: string = 'FLOATER_HOLIDAY';
  generalStatus: string = 'MANDATORY_HOLIDAY';

  designation: any;
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin],
    initialDate: new Date(),
    weekends: true,
    firstDay: 0,
    fixedWeekCount: false,
    datesSet: (event) => {
      const dateObj = new Date(event.end);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth();
      this.selectedMonth = month == 0 ? 'December' : this.months[month - 1];
      this.selectedYear = month == 0 ? year - 1 : year;

      this.calendarOptions.events = this.apiCallForHolidays();
      this.genRestrictedHolidayCall();
    },
    headerToolbar: {
      start: 'prevYear prev', center: 'title', end: 'today next nextYear',
    },
  };

  constructor(private dialog: MatDialog, private eventService: HolidaysEventService, private leaveBalanceService: LeaveBalanceService, private userDetailService: UserDetailsService, private userdetails: UserDetailsService, private absentService: AbsentService, private router: Router, private tabNavigationService: TabNavigationService) {
  }

  ngOnInit(): void {
    this.userid = this.userDetailService.getUserDetails().id;
    this.loggedInUser = this.userDetailService.getUserDetails();
    const user = this.userdetails.getUserDetails();
    this.getStatus(user.id, AttendanceStatus.UNMARKED_ATTENDANCE);

    const currentYear = new Date().getFullYear();
    for (let i = currentYear + 20; i > currentYear - 20; i--) {
      this.years.push(i);
    }
    this.apiCallForHolidays();
  }

  apiCallForHolidays(): EventInput[] {
    this.eventService
      .getHolidayEvents(this.selectedYear, this.loggedInUser.geoLocation, this.loggedInUser.employmentType, this.loggedInUser.baseLocation)
      .subscribe((res) => {
        this.eventData = res;
        this.calendarOptions.events = this.eventData;
      }, () => {
        return [];
      });
    return this.eventData;
  }

  openDialog(item: any[] = []) {
    const dialogRef = this.dialog.open(RegularizeComponent, {
      height: '50%', width: '40%', data: {
        item: item,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const user = this.userdetails.getUserDetails();
        this.getStatus(user.id, AttendanceStatus.UNMARKED_ATTENDANCE);
      }
      this.item = [];
    });
  }

  genRestrictedHolidayCall() {
    this.restrictedHolidays = [];
    this.eventService
      .getRestictedHolidaysForApplyLeave(this.selectedYear, this.loggedInUser.geoLocation, this.loggedInUser.employmentType, this.loggedInUser.baseLocation)
      .subscribe((res) => {
        const response = res.body;
        for (let i = 0; i < response.length; i++) {
          const holiday = response[i];
          const dateObj = new Date(holiday.date);
          const monthName = dateObj.toLocaleString('default', {
            month: 'long',
          });

          this.eventService
            .getHolidayStatus(holiday)
            .subscribe((result) => {
              if ((holiday.calendarLeaveType == this.restrictedStatus || this.generalStatus) && monthName.toLowerCase() == this.selectedMonth.toLowerCase()) {
                const currentHoliday = {
                  date: moment(holiday.date).format('DD-MM-YYYY'),
                  desc: holiday.leaveDescription,
                  originalDate: holiday.date,
                  status: result.body,
                  calendarLeaveType: holiday.calendarLeaveType,
                };

                this.restrictedHolidays.push(currentHoliday);
              }
            });
        }
      });
  }

  onMonthYearChange() {
    if (this.fullCalendar) {
      const date = new Date(this.selectedYear, this.months.indexOf(this.selectedMonth), 1);
      this.fullCalendar.getApi().gotoDate(date);
    }
  }


  getStatus(attendanceStatus, userid) {
    this.val = [];
    const user = this.userdetails.getUserDetails();
    this.absentService.getstatusbyuser('UNMARKED_ATTENDANCE', this.userid).subscribe(res => {
      for (let i = 0; i < res.body.length; i++) {
        const newItem = res.body[i];
        newItem.selected = false;
        this.val.push(newItem);

      }
    });
    return this.val;
  }


  applyRestrictedHoliday(description, date) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '50%';
    dialogConfig.width = '40%';
    dialogConfig.panelClass = 'manage-class';
    dialogConfig.data = {holidayDescription: description, holidayDate: date};
    const dialogRef = this.dialog.open(ApplyHolidayComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      this.onMonthYearChange();
    });
  }



}
