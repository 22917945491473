<div class="main_header_div">
  <div class="child">
    <h1 class="header_name "><img class="header-icon mr-3" src="assets\icons\passport.png">Passport Details</h1>
  </div>
  <div class="pr-3 pt-3">
    <img class="close-icon" (click)="closeDialog($event)" src="assets/icons/close.png">
  </div>
</div>
<hr />

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 form_main_div">
      <div class="add_button_fix">
        <button class="add_button" (click)="addPassport()">+Add</button>
        <button class="plus_button mt-4" (click)="addPassport()">
          <span>+Add</span>
        </button>
      </div>
      <form [formGroup]="editBusinessTravel1">
        <div class="bodyScroll">
          <div class="row" formArrayName="passports"
            *ngFor="let pass of this.PassportDetails().controls; let i = index">
            <div class="col-md-3" [formGroupName]="i">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Valid Till Date<span class="contentRed"></span></mat-label>
                <input matInput [matDatepicker]="picker3" placeholder="dd/mm/yyyy" formControlName="expiryDate" />
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker #picker3></mat-datepicker>
                <mat-error *ngIf="pass.get('expiryDate').errors?.matDatepickerParse">
                  Invalid Date </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4" [formGroupName]="i">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Passport Number<span class="contentRed"></span></mat-label>
                <input matInput formControlName="passportNumber" autocomplete="off"
                  (input)="onInputToUppercase($event)" />
                <mat-error *ngIf="pass.get('passportNumber').errors?.required">
                  Required
                </mat-error>
                <mat-error *ngIf="pass.get('passportNumber').errors?.pattern">
                  Invalid Passport number 
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4" [formGroupName]="i">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Country<span class="contentRed"></span></mat-label>
                <mat-select Required formControlName="country">
                  <mat-option *ngFor="let option of countries" [value]="option">{{ option }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4 visa_properties">
              <button (click)="removePassport(pass.value.id, i)" class="remove_passport_label">
                Remove
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="submit_button_main">
              <button class="save_Emp_btn" type="submit" (click)="onSubmit()">
                <span>Save</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>