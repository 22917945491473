import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-delete-feedback',
  templateUrl: './delete-feedback.component.html',
  styleUrls: ['./delete-feedback.component.scss'],
})
export class DeleteFeedbackComponent implements OnInit {
  dialogText:any;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private dialogRef: MatDialogRef<DeleteFeedbackComponent>
  ) { }
  ngOnInit(): void {
    this.dialogText=this.data;
  }
  closeDialog(res) {
    this.dialogRef.close(res);
  }
}
