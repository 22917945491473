<div>
  <div *ngIf="isSpinner" class="spinner-div">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <label class="loading_label">Loading...</label>
  </div>
  <div class="container" *ngIf="!isSpinner">
    <div class="row">
      <div class="col-md-3 search-bar mt-3">
        <img class="col-md-2 icon" src="\assets\svg\search_bar.svg" alt="search" />
        <input type="search" class="search-input col-md-10" placeholder="Search " [(ngModel)]="searchTerm"
          (input)="applySearch()" />
      </div>
      <div class="col-md-2 from-date mt-3">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label class="common-heading">From Date <span class="contentRed"></span></mat-label>
          <input matInput [matDatepicker]="picker" placeholder="dd/mm/yyyy" autocomplete="off" [(ngModel)]="fromDate"
            (dateChange)="applyFilter()" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-2 to-date mt-3">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label class="common-heading">To Date<span class="contentRed"></span></mat-label>
          <input matInput [min]="getMinEndDate()" [matDatepicker]="picker1" placeholder="dd/mm/yyyy" autocomplete="off"
            (dateChange)="applyFilter()" [(ngModel)]="toDate" />
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-2 mat-form-field-title mt-3">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>
            <img src="\assets\svg\filter-icon.svg" />
            <span class="common-heading"> Leave Type</span>
          </mat-label>
          <mat-select [(ngModel)]="leaveType" (selectionChange)="
              applySearchForJobTitleAndDepartementAndLeaveType()
            " multiple>
            <mat-option *ngFor="let leaveType of this.leaveTypes" [value]="leaveType"
              [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
              [matTooltipDisabled]="!ifOverflow(lt)" matTooltip=" {{ leaveType }}">
              <div class="options" #lt>{{ leaveType }}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-2 mat-form-field-capabilities mt-3">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>
            <img src="\assets\svg\filter-icon.svg" />
            <span class="common-heading"> Capabilities</span>
          </mat-label>
          <mat-select [(ngModel)]="capabilitySearch" (selectionChange)="
              applySearchForJobTitleAndDepartementAndLeaveType()
            " multiple>
            <mat-option *ngFor="let capability of this.departments" [value]="capability"
              [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
              [matTooltipDisabled]="!ifOverflow(cap)" matTooltip="{{ capability }}">
              <div class="options" #cap>{{ capability }}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-2 mat-form-field-title mt-3">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>
            <img src="\assets\svg\filter-icon.svg" />
            <span class="common-heading"> Title</span>
          </mat-label>
          <mat-select [(ngModel)]="jobTitleSearch" (selectionChange)="
              applySearchForJobTitleAndDepartementAndLeaveType()
            " multiple>
            <mat-option *ngFor="let jobTitle of this.jobTitles" [value]="jobTitle" [matTooltipPosition]="position.value"
              [matTooltipClass]="position.value" [matTooltipDisabled]="!ifOverflow(t)" matTooltip=" {{ jobTitle }}">
              <div class="options" #t>{{ jobTitle }}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-.5 download-employee-bulk mt-3" (click)="exportToExcel()">
        <img src="assets/svg/download.svg" />
      </div>

      <pagination-controls class="pagination-controls mt-3" (pageChange)="page = $event" previousLabel="<"
        nextLabel=">"></pagination-controls>

    </div>
    <div class="mt-3 row">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th colspan="3" [matTooltipPosition]="position.value" [matTooltipClass]="position.value" matTooltip="Name"
                [matTooltipDisabled]="!ifOverflow(headerN)" #headerN>
                <div class="table-header-cell">
                  <img src="assets/svg/sort.svg" alt="Double Arrow" class="icon icon-name"
                    (click)="sortTable('name')" />
                  <span class="name">Name</span>
                </div>
              </th>
              <th [matTooltipPosition]="position.value" [matTooltipClass]="position.value" matTooltip="ID"
                [matTooltipDisabled]="!ifOverflow(headerId)" #headerId>
                <div class="table-header-cell">
                  <img src="assets/svg/sort.svg" alt="Double Arrow" class="icon" (click)="sortTable('employeeId')" />
                  <span class="name">ID</span>
                </div>
              </th>
              <th colspan="2" [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                matTooltip="Title" [matTooltipDisabled]="!ifOverflow(headerJT)" #headerJT>
                <div class="table-header-cell">
                  <img src="assets/svg/sort.svg" alt="Double Arrow" class="icon" (click)="sortTable('jobTitle')" />
                  <span class="name">Title</span>
                </div>
              </th>
              <th colspan="3" [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                matTooltip="Capability" [matTooltipDisabled]="!ifOverflow(headerC)" #headerC>
                <div class="table-header-cell">
                  <img src="assets/svg/sort.svg" alt="Double Arrow" class="icon" (click)="sortTable('department')" />
                  <span class="name">Capability</span>
                </div>
              </th>

              <th colspan="2" [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                matTooltip="Career Manager" [matTooltipDisabled]="!ifOverflow(headerCM)" #headerCM>
                Career Manager
              </th>
              <th colspan="3" [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                matTooltip="Official Email ID" [matTooltipDisabled]="!ifOverflow(headerE)" #headerE>
                Official Email ID
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="
              let activeUser of this.filteredUsers
                | paginate : { itemsPerPage: 10, currentPage: page }
            ">
              <td colspan="3">
                <div class="row" [matTooltipPosition]="position.value" [matTooltipClass]="position.value" matTooltip="{{ activeUser.firstName }} {{
                  activeUser.lastName
                }}" [matTooltipDisabled]="!ifOverflow(name)">
                  <div *ngIf="
                    activeUser.userImageThumbnail === null ||
                    activeUser.userImageThumbnail === ''
                  ">
                    <img class="noemployee_img" src="assets/icons/default-avatar.jpg" alt="" />
                  </div>
                  <div *ngIf="activeUser.userImageThumbnail !== ''">
                    <img class="employee_img" [(src)]="activeUser.userImageThumbnail" alt="img" />
                  </div>
                  <label class="data" #name>{{ activeUser.firstName }} {{ activeUser.lastName }}</label>
                </div>
              </td>
              <td>
                <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                  matTooltip="{{ activeUser.employeeId }}" [matTooltipDisabled]="!ifOverflow(id)" #id class="data">{{
                  activeUser.employeeId }}</label>
              </td>
              <td colspan="2">
                <label class="data" [matTooltipPosition]="position.value" [matTooltipClass]="position.value" matTooltip="{{
                  this.getEnumValueByKey(this.jobTitle, activeUser.jobTitle)
                }}" [matTooltipDisabled]="!ifOverflow(jt)" #jt>{{
                  this.getEnumValueByKey(this.jobTitle, activeUser.jobTitle)
                  }}</label>
              </td>
              <td colspan="3">
                <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value" matTooltip="{{
                  this.getEnumValueByKey(this.department, activeUser.department)
                }}" [matTooltipDisabled]="!ifOverflow(d)" #d class="data">{{
                  this.getEnumValueByKey(this.department, activeUser.department)
                  }}</label>
              </td>
              <td colspan="2">
                <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                  matTooltip="{{ activeUser.carrierManagerName }}" [matTooltipDisabled]="!ifOverflow(CM)" #CM
                  class="data">{{ activeUser.carrierManagerName }}</label>
              </td>
              <td colspan="3">
                <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                  matTooltip="{{ activeUser.email }}" [matTooltipDisabled]="!ifOverflow(email)" #email class="data">{{
                  activeUser.email }}</label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>