import { FeedbackSubmitStatus, FeedbackReadStatus, FeedbackRole } from 'src/app/services/feedback/feedback-enum'
import { Feedback } from '../models/feedback';
export class NewFeedbackDTO {
 public id:string
    public feedbackFrom:string;
    public weightage:Number;
    public calibratedScore:Number
    public feedbackRole:FeedbackRole;
    public clientEmail:string;
    public weightedScore:Number;
    public score:Number;
    constructor() {
        this.feedbackFrom="";
        this.weightage=0;
        this.score=0;
        this.feedbackRole;
        this.feedbackRole=FeedbackRole.CAREER_MANAGER
    }
    bind(httpResponse:any){
        this.id = httpResponse && httpResponse.id || undefined
        this.feedbackFrom = httpResponse && httpResponse.feedbackFrom || undefined
        this.weightage = httpResponse && httpResponse.weightage || undefined
        this.score = httpResponse && httpResponse.score || undefined
        this.feedbackRole = httpResponse && httpResponse.feedbackRole || undefined
        this.clientEmail = httpResponse && httpResponse.clientEmail || undefined  
        return this;     
    }
}