interface IPREVIOUSEMPLOYMENT {
    comapanyName: String;
    experience: number;
    startDate: Date
    endDate: Date
    jobTitle: string
    experiencemonth: string
    userId: String
    id: string
}
export class PreviousEmployment {
    public comapanyName: String;
    public experience: number
    public experiencemonth: string
    public userId: string
    public id: string
    public startDate: Date
    public endDate: Date
    public jobTitle: string
    constructor() {
        this.comapanyName = null
        this.id = undefined
        this.userId = undefined
        this.jobTitle = ""
        this.endDate = undefined
        this.startDate = undefined
        this.experiencemonth = ""
    }
    bind(httpResponse: any) {
        this.id = httpResponse && httpResponse.id || undefined
        this.comapanyName = httpResponse && httpResponse.comapanyName || undefined
        this.startDate = httpResponse && new Date(httpResponse.startDate) || undefined
        this.endDate = httpResponse && new Date(httpResponse.endDate) || undefined
        this.jobTitle = httpResponse && httpResponse.jobTitle || undefined
        this.experience = httpResponse && httpResponse.experience || undefined
        this.userId = httpResponse && httpResponse.userId || undefined
        return this;
    }
}