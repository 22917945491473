import { Component, OnInit, Inject } from '@angular/core';
import { BulkExcelSummaryComponent } from '../bulk-excel-summary/bulk-excel-summary.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { LeaveBalanceService } from 'src/app/services/leaveBalances/leave-balance.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-bulk-excel-upload',
  templateUrl: './bulk-excel-upload.component.html',
  styleUrls: ['./bulk-excel-upload.component.scss'],
})
export class BulkExcelUploadComponent implements OnInit {
  public fileName = '';

  public xlFile: File;
  public response: any;
  public allfileContent: any;
  public badFileFormat: boolean;
  public displayStyle = 'none';
  public confirmImportFlag: boolean;
  public uploadBtnLabel = 'Select File';
  public hidebutton = true;
  public loading = false;

  constructor(
    public bulkService: LeaveBalanceService,
    private dialogref: MatDialogRef<BulkExcelSummaryComponent>,
    public dialog: MatDialog,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}
  closeDialog() {
    this.dialogref.close();
  }

  //Read selected file's name, and assign xlFile the selected file, and set fileName to file name to display on screen
  readFile(event: Event) {
    this.badFileFormat = false;

    this.xlFile = event[0];
    if (this.xlFile) {
      this.fileName = this.xlFile.name;
    }
  }

  //On clicking upload button, create form data with the selected xl file, and call the service to trigger the API
  uploadFile() {
    this.confirmImportFlag = false;
    const formData = new FormData();
    formData.append('file', this.xlFile);
    this.uploadBtnLabel = 'Importing Leave Balance...';
    this.bulkService.bulkExcelUploadbyHR(this.data.year, formData).subscribe(
      (data) => {
        this.toastr.success('Data uploaded successfully!', 'Success');
        this.closeDialog();
      },
      (error) => {
        //To display Bad File Format message on the screen
        this.badFileFormat = true;
        this.loading = false;
        this.uploadBtnLabel = 'Select File';
        this.fileName = '';
        this.toastr.error(
          'Failed to upload data. Please try again!!!',
          'Error'
        );
        this.closeDialog();
      }
    );

    //Reset variables
    this.allfileContent = '';
    this.response = '';
    this.xlFile = null;
  }

  openDialog(): void {
    this.closeDialog();
    //Set Mat-dialog configuration, assign data to it and display it
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {};

    dialogConfig.data = {
      Successful: this.allfileContent.Successful,
      Failed: this.allfileContent.Failed,
    };

    dialogConfig.height = '60%';
    dialogConfig.width = '100%';
    const dialogRef = this.dialog.open(BulkExcelSummaryComponent, dialogConfig);
  }

  openPopup() {
    this.hidebutton = false;
  }
  loadinglogo() {
    this.loading = true;
  }

  closePopup() {
    this.dialogref.close();
  }
}
