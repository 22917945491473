import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarOptions, EventInput, EventSourceInput } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { AttendanceHistoryService } from 'src/app/services/attendaceHistory/attendance-history.service';
import { MatDialog } from '@angular/material/dialog';
import { AbsentService } from 'src/app/services/absent/absent.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { RegularizeComponent } from './regularize/regularize.component';
import { TabNavigationService } from 'src/app/services/tab-navigation/tab-navigation.service';
import { UserService } from 'src/app/services/user/user.service';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { AttendanceDTO } from 'src/app/dtos/AttendanceDTO';
import { ReporteeDTO } from 'src/app/dtos/ReporteeDTO';
import { AttendanceStatus } from 'src/app/services/attendance-extra/attendance-enum';
@Component({
  selector: 'app-attendance-details',
  templateUrl: './attendance-details.component.html',
  styleUrls: ['./attendance-details.component.scss'],
})
export class AttendanceDetailsComponent implements OnInit {
  @ViewChild('fullCalendar') fullCalendar!: FullCalendarComponent;
  months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December',];
  userId: number;
  employeeId: String;
  defaultUser: UserDTO;
  selected: 2023;
  val: AttendanceDTO[] = [];
  userDetail: UserDTO;
  allEmployee: UserDTO[] = [];
  filterEmployee: UserDTO[] = [];
  reportees: ReporteeDTO[] = [];
  HistoryData: EventInput[] = [];
  leaveApplyDisabled: boolean = false;
  regularizationDisabled: boolean = false;
  selectedMonth: string = this.months[new Date().getMonth()];
  monthIndex = this.months.indexOf(this.selectedMonth);
  selectedYear: number = new Date().getFullYear();
  isCM: boolean = false;
  historyTabData: { date: any; status: any }[] = [];
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin],
    initialDate: new Date(),
    weekends: true,
    firstDay: 0,
    fixedWeekCount: false,
    datesSet: (event) => {

      const dateObj = new Date(event.end);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth();
      this.selectedMonth = month == 0 ? 'December' : this.months[month - 1];
      this.selectedYear = month == 0 ? year - 1 : year;
      this.monthIndex = this.months.indexOf(this.selectedMonth);
      this.apiCallForStatus();
      this.getHistoryTabData();

      this.getStatus(AttendanceStatus.UNMARKED_ATTENDANCE, this.userDetail.id, this.monthIndex,this.selectedYear);
    },
    headerToolbar: {
      start: 'prevYear prev',
      center: 'title',
      end: 'today next nextYear',
    },
  };

  constructor(
    private attendaceHistory: AttendanceHistoryService,
    private userdetails: UserDetailsService,
    private absentService: AbsentService,
    private router: Router,
    private userService: UserService,
    private tabNavigationService: TabNavigationService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.loadUserDetails();
    this.loadDirectReportees();
  }

  loadUserDetails() {
    this.userDetail = this.userdetails.getUserDetails();
    this.defaultUser = this.userDetail;
  }

  loadDirectReportees() {
    this.userService.getDirectReportee(this.userDetail.id).subscribe(
      (res) => {
        this.processReporteeData(res.body);
      },
      (error) => {
        console.error("Error fetching direct reportees:", error);
      }
    );
  }

  processReporteeData(reportees: any[]) {
    reportees.forEach((element) => {
      if (element.userImageThumbnail !== "") {
        element.userImageThumbnail = "data:image/png;base64," + element.userImageThumbnail;
      }
    });
    this.allEmployee = [this.userDetail, ...reportees];
    this.filterEmployee = this.allEmployee;
  }


  getSafeImageUrl(url: string): string {
    return (!url || url === '') ? 'assets/icons/default-avatar.jpg' : (url.startsWith('data:image') ? url : 'data:image/png;base64,' + url);
  }


 
  apiCallForStatus(): EventInput[] {
    this.attendaceHistory.getAttendaceHistory(this.selectedYear, this.userDetail.id)
      .subscribe(
        (res) => {
          this.HistoryData = this.processAttendaceHistory(res.body);
          this.calendarOptions.events = this.HistoryData;
        },
        (error) => {
          return this.HistoryData;
        }
      );

    return this.HistoryData;
  }

  private processAttendaceHistory(attendanceHistory: any[]): EventInput[] {
    const processedData: EventInput[] = [];

    attendanceHistory.forEach((item) => {
      const status = item.attendanceStatus;
      if (this.isValidAttendanceStatus(status)) {
        const formattedDate = moment(item.attendanceDateTime).format('YYYY-MM-DD');
        const bgColor = this.getBackgroundColor(status);
        const holidayInitial = this.getHolidayInitial(status);

        const currentEvent: EventInput = {
          title: holidayInitial,
          start: formattedDate,
          end: formattedDate,
          backgroundColor: bgColor,
          display: 'background',
        };

        processedData.push(currentEvent);
      }
    });

    return processedData;
  }

  private isValidAttendanceStatus(status: AttendanceStatus): boolean {
    return [
      AttendanceStatus.PRESENT,
      AttendanceStatus.ABSENT,
      AttendanceStatus.REGULARIZED_PRESENT,
      AttendanceStatus.LEAVE,
      AttendanceStatus.UNMARKED_ATTENDANCE,
      AttendanceStatus.HOLIDAY,
    ].includes(status);
  }

  private getBackgroundColor(status: string): string {
    switch (status) {
      case AttendanceStatus.PRESENT:
        return '#0AB4AC';
      case AttendanceStatus.REGULARIZED_PRESENT:
        return '#FC8034';
      case AttendanceStatus.ABSENT:
      case AttendanceStatus.UNMARKED_ATTENDANCE:
        return '#FF8396';
      case AttendanceStatus.LEAVE:
        return '#0036AC';
      default:
        return '#c0f592';
    }
  }

  private getHolidayInitial(status: string): string {
    switch (status) {
      case AttendanceStatus.PRESENT:
        return 'P';
      case AttendanceStatus.REGULARIZED_PRESENT:
        return 'R';
      case AttendanceStatus.ABSENT:
      case AttendanceStatus.UNMARKED_ATTENDANCE:
        return 'A';
      case AttendanceStatus.LEAVE:
        return 'L';
      default:
        return '';
    }
  }

  getHistoryTabData() {
    this.attendaceHistory.getAttendaceHistory(this.selectedYear, this.userDetail.id)
      .subscribe(
        (res) => {
          this.historyTabData = this.processAttendanceHistory(res.body);
        },
        (error) => {
          return this.HistoryData;
        }
      );
  }

  private processAttendanceHistory(attendanceHistory: any[]): any[] {
    const processedData: any[] = [];

    attendanceHistory.forEach((item) => {
      const status = item.attendanceStatus;
      const dateObj = new Date(item.attendanceDateTime);
      const monthName = dateObj.toLocaleString('default', {
        month: 'long',
      });

      if (this.isCorrectMonth(monthName) && this.shouldIncludeStatus(status, item.appliedForRegularization)) {
        const formattedDate = moment(item.attendanceDateTime).format('ddd, D MMM,');
        const currStatus = this.getStatusLabel(status);

        const currentData = {
          date: formattedDate,
          status: currStatus,
        };

        processedData.push(currentData);
      }
    });

    return processedData;
  }

  private isCorrectMonth(monthName: string): boolean {
    return monthName.toLowerCase() === this.selectedMonth.toLowerCase();
  }

  private shouldIncludeStatus(status: string, appliedForRegularization: boolean): boolean {
    return status === AttendanceStatus.REGULARIZED_PRESENT || (status === AttendanceStatus.UNMARKED_ATTENDANCE && appliedForRegularization);
  }

  private getStatusLabel(status: string): string {
    return status === AttendanceStatus.REGULARIZED_PRESENT ? 'Approved' : 'Pending';
  }


  redirectToLeaveApply(date) {
    this.router.navigate(['./leaveManagement/leave-apply'] ,{ queryParams: { date: date} });
  }
  
  getStatus(attendanceStatus, userId, month, year) {
    this.val = [];
    this.absentService.getstatusbyuser(AttendanceStatus.UNMARKED_ATTENDANCE, userId).subscribe(res => {
      for (let i = 0; i < res.body.length; i++) {
        const newItem = res.body[i];
        const attendanceDateTime = new Date(newItem.attendanceDateTime);
        if (attendanceDateTime.getMonth() === month && attendanceDateTime.getFullYear() === year) {
          this.val.push(newItem);
        }
      }
      return this.val;
    });

    return this.val;
  }

  searchEmployee(employee) {
    this.filterEmployee = this.allEmployee.filter(emp => (emp.firstName.toLowerCase().includes(employee.toLowerCase()) || emp.lastName.toLowerCase().includes(employee.toLowerCase())));
  }

  selectEmployee(employee) {
    this.userDetail = employee.value;
    if (this.userDetail === this.defaultUser) {
      this.leaveApplyDisabled = false; 
      this.regularizationDisabled = false; 
    } else {
      this.leaveApplyDisabled = true;
      this.regularizationDisabled = true; 
    }
    this.getStatus(AttendanceStatus.UNMARKED_ATTENDANCE, this.userDetail.id, this.monthIndex, this.selectedYear);
    this.attendaceHistory.getAttendaceHistory(this.selectedYear, this.userDetail.id)
      .subscribe((res) => {
        this.HistoryData = [];
        const attendanceHistory = res.body;

        for (let i = 0; i < attendanceHistory.length; i++) {
          const status = attendanceHistory[i].attendanceStatus;
          if (status == AttendanceStatus.PRESENT || status == AttendanceStatus.ABSENT || status == AttendanceStatus.REGULARIZED_PRESENT || status == AttendanceStatus.LEAVE || status == AttendanceStatus.UNMARKED_ATTENDANCE || status == AttendanceStatus.HOLIDAY) {
            const formattedDate = moment(attendanceHistory[i].attendanceDateTime).format('YYYY-MM-DD');
            let bgColor = ''
            let holidayInitial = '';

            if (status == AttendanceStatus.PRESENT) {
              bgColor = '#28A745';
              holidayInitial = 'P';
            }
            else if (status == AttendanceStatus.REGULARIZED_PRESENT) {
              bgColor = '#FFA900';
              holidayInitial = 'R';
            }
            else if (status == AttendanceStatus.ABSENT || status == AttendanceStatus.UNMARKED_ATTENDANCE) {
              bgColor = '#EB0000';
              holidayInitial = 'A';
            }
            else if (status == AttendanceStatus.LEAVE) {
              bgColor = '#0045B9';
              holidayInitial = 'L';
              holidayInitial = 'L';
            } else {
              bgColor = '#FAA8A9';
            }

            const currentEvent: EventInput = {
              title: holidayInitial,
              start: formattedDate,
              end: formattedDate,
              backgroundColor: bgColor,
              display: 'background',
            };
            this.HistoryData.push(currentEvent);
          }
        }
        this.calendarOptions.events = this.HistoryData;

        return this.HistoryData;
      },
        (e) => {
          return this.HistoryData;
        })
    return this.HistoryData;

  }

  openRegularizeDialog(item: any[] = []) {
    const dialogRef = this.dialog.open(RegularizeComponent, {
      height: '50%',
      width: '40%',
      data: {
        item: item
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const user = this.userdetails.getUserDetails();
        this.getStatus(AttendanceStatus.UNMARKED_ATTENDANCE, this.userDetail.id, this.monthIndex, this.selectedYear);
      }
    });
  }

}
