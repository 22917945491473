import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { FeedbackNotesService } from 'src/app/services/feedback-notes/feedback-notes.service';
import { NewFeedbackGroupService } from 'src/app/services/new-feedback-group.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { PreviewFeedbackComponent } from '../preview-feedback/preview-feedback.component';

@Component({
  selector: 'app-user-summary',
  templateUrl: './user-summary.component.html',
  styleUrls: ['./user-summary.component.scss'],
})
export class UserSummaryComponent implements OnInit {
  userDto: UserDTO;
  temp: boolean;
  scoreSum: any;
  feedbackdto: any;
  feedbackClosureNotes: any;
  feedbackAvailable = false;
  feedback = [];
  year: any;
  isclosed: any;
  selected: number = new Date().getFullYear();

  constructor(
    public userDetailService: UserDetailsService,
    private feedbackgroup: NewFeedbackGroupService,
    private dialog: MatDialog,
    public feedbackNotesService: FeedbackNotesService
  ) {}

  ngOnInit(): void {
    this.userDto = this.userDetailService.getUserDetails();
    this.setFormData();
  }
  setFormData() {
    this.feedbackNotesService
      .getAllYearsFeedbackSummary(this.userDto.id)
      .subscribe(
        (res) => {
          for (let i of res.body) {
            this.feedbackClosureNotes = i.feedbackSummary;
            this.year = i.year;
            this.feedback.push(this.feedbackClosureNotes);

            this.feedbackgroup
              .getFeedbackOverallstatus(this.userDto.id, this.year)
              .subscribe((res) => {
                this.isclosed = res.body;
              });
          }
        },
        (error) => {
          this.temp = true;
        }
      );
  }
}
