import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserDetailsService } from '../user-details.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService {
  routingPath: string = '';
  hasResourceAccessRights: boolean;
  hasEmployeeManagementRights: boolean;
  hasResourceAccessRightsHr: boolean;
  hasFormGenerationAccessRights: boolean;
  hasCMAccessRights: boolean;

  constructor(private router: Router,
    private userDetailService: UserDetailsService,) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    this.routingPath = route.routeConfig.path;
    this.hasResourceAccessRights = this.userDetailService.hasRole(['ROLE_SUPER_ADMIN', 'ROLE_PROJECT_ADMIN', 'ROLE_ASSET_ADMIN', 'ROLE_CLIENT_ADMIN']);
    this.hasEmployeeManagementRights = this.userDetailService.hasRole(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_EMPLOYEE_ADMIN']);
    this.hasResourceAccessRightsHr = this.userDetailService.hasRole(['ROLE_SUPER_ADMIN', 'ROLE_HUMAN_RESOURCE']);
    this.hasCMAccessRights = this.userDetailService.hasRole(['ROLE_CAREER_MANAGER']);
    
    if (this.hasEmployeeManagementRights && this.routingPath === 'employee') {
      return true;
    } else if (this.hasResourceAccessRights && (
      this.routingPath === 'project' ||
      this.routingPath === 'project/projectDetailView/:id' ||
      this.routingPath === 'assetManagement' ||
      this.routingPath === 'viewAsset/:id' ||
      this.routingPath === 'client_details/:id'
    )) {
      return true;
    } else if (this.hasResourceAccessRightsHr && (
      this.routingPath === 'configure' ||
      this.routingPath === 'configure-menu' ||
      this.routingPath === 'formGeneration' ||
      this.routingPath === 'feedback-forms/:id' ||
      this.routingPath === 'cm-reportees/:id'
    )) {
      return true;
    } else if (this.hasCMAccessRights && (
      this.routingPath === 'directreportees' ||
      this.routingPath === 'profiledetail/:id'
    )) {
      return true;
    } else {
      this.router.navigate(['/access-denied']);
    }
    return false;
  }
}
