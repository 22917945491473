import { Injectable } from '@angular/core';
import { Certificate } from 'src/app/models/certificate';
import { CertificationUrlService } from '../restAPI/certificationURLs/certification-url.service';
import { RestAPIService } from '../restAPI/rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class CertificationService {
  constructor(private restApiService: RestAPIService,
    private certificationurl : CertificationUrlService
  ) { }
  create(payload) {
    return this.restApiService.post(
      this.certificationurl.getBaseUrl(),
      payload
    )
  }
  update(payload){
    return this.restApiService.put(
      this.certificationurl.getBaseUrl(),
      payload
    )
  }
  setCertResponse(response):Certificate[]{
    let certList:Certificate[] = []
    for(let data of response){
        let cert:Certificate = new Certificate()
        certList.push(
          cert.bind(data)          
        )
    }
    return certList
  }
  getCertByUserIdSubscribe(userId) {
      return this.restApiService.get(
      this.certificationurl.getUserUrl(userId)
    )
  }
  delete(id: any) {
    this.restApiService.delete(
      this.certificationurl.getByFieldUrl(id)
    ).subscribe(res=>{
      
    })
  }
}
