



export interface Resource {
    displayText: string;
    resourcePath: string
}


export interface ExitDetailsDTO {
    "fields": {
        comment: String
        completionDate: Date
        expectedDate: Date
        id: String
        resource: Resource
    },
    title: string
}

export interface exitChecklistStages {
    "stages": {
        "USER_STAGE": {
            owner:string
        },
        "HR_STAGE": {
            owner:string
        },
        "OPERATIONS_STAGE": {
            owner:string
        },
        "CARRER_MANAGER_STAGE": {
            owner:string
        },
        "FINANCE_STAGE": {
            owner:string
        }
    }

}

export class ExitChecklistDTO {
    public stages: exitChecklistStages

    constructor() {
    
    }
}