import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AddressDetailsService } from 'src/app/services/address-details/address-details.service';
import { AddressDetailsDTO } from 'src/app/dtos/AddressDetailsDTO';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { AddressType, EventTabClosed } from 'src/app/services/user/user-enum';

@Component({
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  addressForm: FormGroup;
  currentAddress: AddressDetailsDTO = new AddressDetailsDTO();
  permanentAddress: AddressDetailsDTO = new AddressDetailsDTO();
  userDto = new UserDTO();

  constructor(
    private fb: FormBuilder,
    private addressService: AddressDetailsService,
    private dialogRef: MatDialogRef<AddressComponent>,
    private toaster: ToastrService,
    @Inject(MAT_DIALOG_DATA) private data
  ) { }

  ngOnInit(): void {
   this.initiateComponent();
  }
  initiateComponent()
  {
    this.addressForm = this.fb.group({
      currentAddress: this.fb.group({
        addressLineOne: ['', [Validators.required,Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9+#. ,()-]*$')]],
        addressLineTwo: ['',[Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9+#. ,()-]*$')]],
        country: ['', [Validators.required, Validators.pattern('^[a-zA-Z\\s]*$')]],
        city: ['', [Validators.required, Validators.pattern('^[a-zA-Z\\s]*$')]],
        state: ['', [Validators.required, Validators.pattern('^[a-zA-Z\\s]*$')]],
        pincode: [
          '',
          [
            Validators.required,
            Validators.pattern('^[0-9a-zA-Z- ]{3,10}$'),
          ],
        ],
      }),
      permanentAddress: this.fb.group({
        addressLineOne: ['', [Validators.required,Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9+#. ,()-]*$')]],
        addressLineTwo: ['',[Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9+#. ,()-]*$')]],
        country: ['', [Validators.required, Validators.pattern('^[a-zA-Z\\s]*$')]],
        city: ['', [Validators.required, Validators.pattern('^[a-zA-Z\\s]*$')]],
        state: ['', [Validators.required, Validators.pattern('^[a-zA-Z\\s]*$')]],
        pincode: [
          '',
          [
            Validators.required,
            Validators.pattern('^[0-9a-zA-Z- ]{3,10}$'),
          ],
        ],
      }),
      prefillPermanent: [false]

    });

    this.addressService.getaddressbyuserId(AddressType.CURRENT, this.data).subscribe((res) => {
      this.currentAddress = res.body;
      this.addressForm.get('currentAddress').patchValue(this.currentAddress);
    });

    this.addressService.getaddressbyuserId(AddressType.PERMANENT, this.data).subscribe((res) => {
      this.permanentAddress = res.body;
      this.addressForm.get('permanentAddress').patchValue(this.permanentAddress);
    });
  }
  onPrefillChange(): void {
    if (this.addressForm.get('prefillPermanent').value) {
      // Copy current address to permanent address fields
      const perAddress = this.addressForm.get('permanentAddress').value;
      this.addressForm.get('currentAddress').patchValue(perAddress);
    } else {
      // Clear permanent address fields
      this.addressForm.get('currentAddress').reset();
    }
  }
  closeDialog(): void {
    this.dialogRef.close({ event: EventTabClosed.Closed });
  }

  checkError(controlName: string, parent ,errorName: string): boolean {
    const address = this.addressForm.get(parent).get(controlName);
    return address ? address.hasError(errorName) : false;
  }

  onSubmit(): void {
    if (this.addressForm.valid) {

      const currentAddressDto = getDtoValue(this.currentAddress, this.addressForm.value.currentAddress);
      const permanentAddressDto = getDtoValue(this.permanentAddress, this.addressForm.value.permanentAddress);

      if (currentAddressDto.id != null && permanentAddressDto.id != null) {
        // Update current address
        this.addressService.putdocument(currentAddressDto).subscribe(() => {
        }, () => {
          this.toaster.error('Error updated current address');
        });
        // Update permanent address
        this.addressService.putdocument(permanentAddressDto).subscribe(() => {
          this.toaster.success('Successfully Saved');
          this.dialogRef.close({ event: EventTabClosed.Closed});
        }, () => {
          this.toaster.error('Error updating permanent address');
        });
      }
      else {
        permanentAddressDto.userId=currentAddressDto.userId=this.data;
        permanentAddressDto.addressCategory=AddressType.PERMANENT;
        currentAddressDto.addressCategory=AddressType.CURRENT;
        // Save current address
        this.addressService.postdocument(currentAddressDto).subscribe(() => {
        }, () => {
          this.toaster.error('Error saving current address');
        });
        // Save permanent address
        this.addressService.postdocument(permanentAddressDto).subscribe(() => {
          this.toaster.success('Successfully Saved');
          this.dialogRef.close({ event: EventTabClosed.Closed });
        }, () => {
          this.toaster.error('Error saving permanent address');
        });
      }

    } else {
      this.toaster.error("Invalid details");
    }
  }


}
function getDtoValue(Address, form): AddressDetailsDTO {
  Address.addressLineOne = form.addressLineOne;
  Address.addressLineTwo = form.addressLineTwo;
  Address.city = form.city;
  Address.pincode = form.pincode;
  Address.state = form.state;
  Address.country = form.country;
  return Address;
}

