import { Component, Inject, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FormControl,
  FormGroup,
} from '@angular/forms';
import { ApprovalStatus } from 'src/app/services/leaveManagment/leave-enum';
import { LeaveApprovalService } from 'src/app/services/leave-approval-cm/leave-approval.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ToastrService } from 'ngx-toastr';
import { EventTabClosed, Status } from 'src/app/services/user/user-enum';

@Component({
  selector: 'app-cm-leave-reject',
  templateUrl: './cm-leave-reject.component.html',
  styleUrls: ['./cm-leave-reject.component.scss'],
})
export class CmLeaveRejectComponent implements OnInit {
  rejectionDetails: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CmLeaveRejectComponent>,
    private leaveApprovalservice: LeaveApprovalService,
    public toaster: ToastrService
  ) {
    this.rejectionDetails = new FormGroup({
      reasonForRejection: new FormControl(),
    });
  }

  ngOnInit(): void {}

  rejectLeave() {
    const reason = this.rejectionDetails.get('reasonForRejection').value;
    const leaveReject = {
      leaveApplicationId: this.data.leave.leaveId,
      approvalStatus:
        this.data.leave.approvalStatus == Status.PENDING
          ? ApprovalStatus.REJECTED
          : 'REVOKE_REQUEST_REJECTED',
      reasonForRejection: reason,
    };
    this.leaveApprovalservice.update(leaveReject).subscribe(
      (res) => {
        this.toaster.success('Leave Rejected', 'Saved', {});
        this.closeDialog();
      },
      () => {
        this.toaster.success('Cannot Reject Try Again', '', {});
        this.closeDialog();
      }
    );
  }

  closeDialog() {
    this.dialogRef.close({ event: EventTabClosed.Closed });
  }
}
