import { Injectable } from '@angular/core';
import { RestAPIService } from '../rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class SkillUrlService {
  private readonly skillBaseApi = '/api/skills';
  private readonly user = '/user';
  private readonly userId_param = 'userId'
  constructor(private restApi:RestAPIService) { }


  getBaseUrl(){
    return this.skillBaseApi;
  }

  getUserUrl(userId) {
    return this.skillBaseApi + this.user
      + '?' + this.userId_param + '=' + userId
  }

  getByFieldUrl(field){
    return this.skillBaseApi+ this.getByField(field)
  }

  private getByField(field){
    return '/'+field;
  }













}


