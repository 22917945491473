import { Pipe, PipeTransform } from '@angular/core';

@Pipe({

  name: 'underScoreToSpace'

})

export class PipeUnderScoreToSpace implements PipeTransform {

  nvalue: string

  transform(value: any): any {  
    if (value === null || value === undefined || typeof value !== 'string') {
      return "";
    }
    return value.replace(/_/g, ' ').replace(/\band\b/gi, '&');

  }

}