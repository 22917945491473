import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
    
})
export class EducationDetailsUrlService
{
    private readonly educationDetailsBaseApi = '/api/education-details';
    private readonly user = '/user';
    private readonly userId_param = 'userId';
   constructor()
   {}
   getBaseUrl()
   {
       return this.educationDetailsBaseApi;
   }
 getUserUrl(userId)
{
    return this.educationDetailsBaseApi + this.user + '?' + this.userId_param + '=' + userId
  }  
getByFieldUrl(field)
   {
       return this.educationDetailsBaseApi + this.getByField(field);
   }
   
   private getByField(field){
       return '/'+field;
     }
     getByParamUrl(params){
        let queryParamStr = '?'
        let i = 0
        Object.keys(params).forEach(function(key) {
          
          queryParamStr += (key + "=" +params[key])
          
          i++
          if (Object.keys(params).length > i)
            queryParamStr+='&'
    
         
         
        })
        
        return this.educationDetailsBaseApi+this.user + queryParamStr
      } 
   
}