import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { onMainContentChange } from './animations/animation';
import { LoadingService } from './services/loading.service';
import { LoginService } from './services/login.service';
import { SidenavService } from './services/sidenav.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthGuardService } from './services/auth-guard.service';
import { debounce } from 'lodash';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [onMainContentChange],
})
export class AppComponent implements OnInit, AfterViewInit {
  isExpanded: boolean = false;
  opened: boolean = false;
  loader: boolean = false;
  public onSideNavChange: boolean;
  userActivity;

  userInactive: Subject<any> = new Subject();
  @ViewChild('leftSidenav') public sidenav: MatSidenav;

  constructor(
    private _sidenavService: SidenavService,
    private loadingService: LoadingService,
    private changeDetectorRef: ChangeDetectorRef,
    private loginService: LoginService,
    private sidenavService: SidenavService,
    private authguardService: AuthGuardService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this._sidenavService.sideNavState$.subscribe((res) => {
      this.onSideNavChange = res;
    });
    this.setSessionTimeOut();
    this.userInactive.subscribe(() =>
      this.logOut()
    );
  }
  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  ngOnInit() {
    if (localStorage.length == 1 || localStorage.length == 0) {
      this.router.navigate(['/login']);
    }
    else {
      this.loginService.updateLoggedinUserInfo();
      this.loadingService.onLoaderChange().subscribe((res) => {
        this.loader = res;
        this.changeDetectorRef.detectChanges();
      });
    }
    this.debouncedRefreshUserState = this.debouncedRefreshUserState.bind(this);
  }

  // This will check if user is idle for 30 minutes
  setSessionTimeOut() {
    this.userActivity = setTimeout(
      () => this.userInactive.next(undefined),
      1800000
    );
  }

  // This will check if the user is inactive on Pulse window(500ms delay)
  @HostListener('window:mousemove')
  debouncedRefreshUserState = debounce(() => {
    clearTimeout(this.userActivity);
    this.setSessionTimeOut();
  }, 500);

  logOut() {
    this.dialog.closeAll();
    this.authguardService.signOut();
    this.router.navigate([`/session-timeout`]);
  }
}
