interface ICertificate {
    docBase: string;
    dateOfIssue : Date
    userId: string
    validUpto :Date
    documentName: string
    id:string
    certificateType: string
}
export class Certificate {
    public docBase: String;
    public documentName : string
    public  userId: String
    public dateOfIssue  :Date
    public validUpto:Date
    public id :string
    public certificateType: String;

    constructor(){
        this.documentName = ''
        this.certificateType = ''
        this.docBase=''
        this.dateOfIssue=undefined
        this.validUpto=undefined
    }
    bind(httpResponse:any){
        this.id = httpResponse && httpResponse.id || undefined
        this.certificateType = httpResponse && httpResponse.certificateType || undefined
        this.docBase = httpResponse && httpResponse.docBase || undefined
        this.documentName = httpResponse && httpResponse.documentName || undefined
        this.userId = httpResponse && httpResponse.userId || undefined
        this.dateOfIssue = httpResponse && httpResponse.dateOfIssue || undefined  
        this.validUpto = httpResponse && httpResponse.validUpto || undefined  
        return this;     
    }
}