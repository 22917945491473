<div class="container-fluid">
  <div class="main_header_div">
    <div class="child">
      <div class="header-div">
        <div class="flex">
          <div class="coloums">
            <img src="assets/icons/default-avatar.jpg" class="img-container" />
          </div>
          <div class="col">
            <label class="header_name"
              >{{ this.userData.userdto.firstName }}
              {{ this.userData.userdto.lastName }}</label
            >
            <p class="count">ID {{ this.userData.userdto.employeeId }}</p>
          </div>
          <div *ngIf="!this.userData.isProbation">
            <div class="col">
              <label class="asset-common">Score </label>
              <p class="reportee-score">
                {{ this.userData.userdto.totalScore | number : "1.2-2" }}/5
              </p>
            </div>
            <div class="col">
              <label class="asset-common">Feedback Group </label>
              <p class="reportee-score">
                {{ this.userData.userdto.feedbackForms }}
              </p>
            </div>
          </div>
        </div>
        <div class="child_close_btn">
          <button class="closeButton" (click)="closeDialog()">Close</button>
        </div>
      </div>
      <hr />
      <div *ngIf="this.userData.isProbation">
        <div class="label-element">
          <label for="">Areas of strength</label>
        </div>
        <div
          class="notes-data-element"
          [innerHTML]="feedbackClosureNotes.strengths"
        ></div>
        <div class="label-element">
          <label for="">Areas of development</label>
        </div>
        <div
          class="notes-data-element"
          [innerHTML]="feedbackClosureNotes.developments"
        ></div>
        <div class="label-element">
          <label for="">Action item if any</label>
        </div>
        <div
          class="notes-data-element"
          [innerHTML]="feedbackClosureNotes.actionItems"
        ></div>
      </div>
      <div *ngIf="!this.userData.isProbation">
        <div class="label-element">
          <label for="">Feedback</label>
        </div>
        <div
          class="notes-data-element"
          [innerHTML]="feedbackClosureNotes"
        ></div>
      </div>
      <div class="save-btn-div">
        <button mat-raised-button class="save-btn button" (click)="send()">
          Close feedback
        </button>
      </div>
    </div>
  </div>
</div>
