<!-- <div class="main_header_div col-md-12">
  <div class="w-head">
    <img src="assets/icons/dashboard-icons/wfo-icon.svg" />
    Working from Office
  </div>
  <img class="close-icon-svg" (click)="closeDialog()" src="assets/icons/dashboard-icons/close-pink-icon.svg" />
</div> -->

<mat-card class="main-card">

  <div class="row">
    <div class="col-md-12">
      <div class="main-header">
        <div class="svg-heading-div">
          <img class="submodule-svg" src="assets/icons/dashboard-icons/wfo-icon.svg" />
          <div class="wfo-heading">Working from Office</div>
        </div>
        <div>
          <img class="close-icon-svg" (click)="closeDialog()" src="assets/icons/dashboard-icons/close-pink-icon.svg" />
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="main-body">
        <form [formGroup]="applyWFODetails">
          <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Date <span class="contentRed"></span></mat-label>
            <input matInput [matDatepicker]="startDatePicker" formControlName="startDate" placeholder="dd/mm/yyyy"
              [min]="minDate" [max]="maxDate" (dateChange)="checkLBandLAerrorFcn()" [disabled]="true" />
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
            <mat-error *ngIf="checkError('startDate', 'required')">Required</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Location <span class="contentRed"></span></mat-label>
            <mat-select formControlName="location">
              <mat-option *ngFor="let option of locationOptions" [value]="option.value">
                {{ option.key | titlecase | underScoreToSpace}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="checkError('location', 'required')">Required</mat-error>
          </mat-form-field>

          <button class="add_Emp_btn" type="submit" (click)="onSubmit()" [disabled]="loading">
            <span>Apply</span>
          </button>
        
        </form>
      </div>
    </div>
  </div>
  
</mat-card>

