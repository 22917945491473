import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import de from 'date-fns/esm/locale/de/index.js';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { FormsSchemaService } from 'src/app/services/forms/forms-schema.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit, OnChanges {
  @Input() public parentData;
  @Input() public isDisable;
  @Input() public disableEdit;
  @Output() someEvent = new EventEmitter<any>();

  totalScore = 0;
  arrayOfAns: any;
  isDisabledSubmit: Boolean;
  constructor(
    public formSchemaService: FormsSchemaService,
    private feedBackService: FeedbackService
  ) {}
  ngOnChanges() {
    this.formSchemaService.setFormCategory(this.parentData.name);
  }
  ngOnInit() {
    this.feedBackService.getCurrentStatusOfIsDisable().subscribe((res) => {
      this.isDisabledSubmit = res;
    });
  }

  toggleValue(index, score, item) {
    let event = {
      item: index,
      score: score,
    };
    item.value = score;
    this.someEvent.emit(event);
  }
  submit() {
    let valid = true;
    if (!valid) {
      return false;
    }
    let input = new FormData();
    input.append('formId', this.parentData._id);
    input.append('attributes', JSON.stringify(this.parentData));
  }
}
