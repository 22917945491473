import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WfoUrlService {
  private readonly wfoURL = "/api/work-from-office-for-user/apply";
  private readonly wfoDirectReporteeHistoryURL = '/api/work-from-office-for-user/fetchWorkFromOfficeForDirectReportees';
  private readonly wfoHistoryForUser = '/api/work-from-office-for-user/fetchWorkFromOfficeForUser';
  private readonly wfoApproveByCM = '/api/work-from-office/approve';
  private readonly wfoWithdrawByUser = '/api/work-from-office/withdraw';
  private readonly fetchRecentApprovedUser = '/api/work-from-office-for-user/fetchRecentApprovedWorkFromOfficeForUser';

  constructor() { }

  getBaseUrl() {
    return this.wfoURL;
  }

  getAllHistoryOfReporteeWFO(userId) {
    return this.wfoDirectReporteeHistoryURL + '/' + userId;
  }

  approveWfoByCM() {
    return this.wfoApproveByCM;
  }

  withdrawWfobyUser() {
    return this.wfoWithdrawByUser;
  }

  getLastApprovedUserDetails(userId) {
    return this.fetchRecentApprovedUser + '/' + userId;
  }

  getWfoHistorybyMonthAndYear(userId, month, year) {
    return this.wfoHistoryForUser + '/' + userId + '/' + month + '/' + year;
  }
}
