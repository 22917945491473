import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { AddHolidayService } from 'src/app/services/addHoliday/add-holiday.service';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client/client.service';
import { RefreshService } from 'src/app/services/refreshService/refresh.service';
import { UserService } from 'src/app/services/user/user.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

import {
  AuthoritiesConstants,
  Departments,
  JobTitles,
  ProbationPeriod,
  Gender,
  GeoLocation,
  EmployementType,
  HolidayType,
  RelationshipStatus,
} from 'src/app/services/user/user-enum';



@Component({
  selector: 'app-addholidays',
  templateUrl: './addholidays.component.html',
  styleUrls: ['./addholidays.component.scss']
})
export class AddholidaysComponent implements OnInit {
  years: number[] = [];
  selectedYear: number = new Date().getFullYear();
  LeaveType = [];
  tableData = [];
  rowAdded: boolean = false;
  clientProject = [];
  selectedClient: any;
  loggedInUser: any;
  newLeaveDetails: FormGroup;
  clientDetails = [];
  employmentTypes: any = [];
  geoLocations: any = [];
  locations: string[];
  holidayType: any = [];


  constructor(public dialogRef: MatDialogRef<AddholidaysComponent>,
    public addHolidayService: AddHolidayService,
    private refreshService: RefreshService,
    public clientService: ClientService,
    public userService: UserService,
    public userDetails: UserDetailsService,
    public toaster: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder) {
    this.newLeaveDetails = this.fb.group({
      geoLocation: ['', Validators.required],
      holidaytype: ['', Validators.required],
      year: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.processEnumValuesToArray();
    this.loggedInUser = this.userDetails.getUserDetails();
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year <= this.selectedYear + 10; year++) {
      this.years.push(year);
    }
    this.newLeaveDetails.patchValue({
      year: this.selectedYear,
    });

  }


  closeDialog() {
    this.dialogRef.close();
  }


  removeRow(index: number): void {
    this.tableData.splice(index, 1);
    this.updateFormDisabledState();
  }

  updateFormDisabledState(): void {
    if (this.tableData.length === 0 ) {
      this.newLeaveDetails.enable();
      this.rowAdded = false;
    } else {
      this.rowAdded = true;
    }
  }

  isInvalidControl(controlName: string): boolean {
    const control = this.newLeaveDetails.get(controlName);
    return control.invalid && control.touched;
  }
  addRow(): void {
    if (this.newLeaveDetails.valid) {
      const newRow = {
        geoLocation: this.newLeaveDetails.value.geoLocation,
        calendarLeaveType: this.newLeaveDetails.value.holidaytype,
        date: '',
        holidayName: '',
        employmentType: '',
        baseLocation: ''

      };
      this.tableData.push(newRow);
      this.rowAdded = true;
    }
  }

  saveData(): void {
    const requestBody = {
      geoLocation: this.newLeaveDetails.value.geoLocation,
      calendarLeaveType: this.newLeaveDetails.value.holidaytype,
      leaveDates: this.tableData.map(row => ({
        date: new Date(row.date).toISOString(),
        leaveDescription: row.holidayName,
        baseLocation: row.baseLocation,
        employmentType: row.employmentType
      })),
      year: new Date().getFullYear().toString()
    };

    this.addHolidayService.addHolidays(requestBody).subscribe(response => {
      this.refreshService.triggerRefresh('blue-altair-holiday');
      this.clearFormData();
    });
  }



  clearFormData(): void {
    this.tableData = [];
    this.dialogRef.close();
  }
  isFieldInvalid(field: any): boolean {
    return field.invalid && (field.dirty || field.touched);
  }

  processEnumValuesToArray() {
    this.holidayType = Object.keys(HolidayType).filter(key => isNaN(Number(HolidayType[key])))
    this.geoLocations = Object.keys(GeoLocation).filter(key => isNaN(Number(GeoLocation[key])));
    this.employmentTypes = Object.keys(EmployementType).filter(key => isNaN(Number(EmployementType[key])));
  }

  onGeoLocationChange(geoLocation: string) {

    this.userService.fetchBaseLocations(geoLocation.toLocaleUpperCase()).subscribe(res => {

      this.locations = res.body;
    });
  }


}
