<mat-card
  class="container-sm w-3"
  style="
    margin-top: 20px;
    background-color: #93e5f3;
    border-color: #82be41;
    border-radius: 20px;
    box-shadow: 0 6px 15px 2px rgba(0, 0, 0, 0.1);
  "
>
  <mat-card-header
    style="
      text-align: center;
      font-size: 25px;
      padding-left: 10px;
      padding-bottom: 10px;
      color: white;
      font-weight: bold;
    "
  >
    My Projects
  </mat-card-header>
  <app-project-accordian
    *ngFor="let project of this.projects"
    [project]="project"
  ></app-project-accordian>
</mat-card>
