import { Component, Inject, OnInit } from '@angular/core';
import { RefreshService } from 'src/app/services/refreshService/refresh.service';
import { WfoService } from 'src/app/services/work-from-office/wfo.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-is-withdraw-box',
  templateUrl: './is-withdraw-box.component.html',
  styleUrls: ['./is-withdraw-box.component.scss'],
})
export class IsWithdrawBoxComponent implements OnInit {
  wfoDetail: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private refreshService: RefreshService,
    public dialogRef: MatDialogRef<IsWithdrawBoxComponent>,
    private wfoService: WfoService
  ) {}

  ngOnInit(): void {
    this.wfoDetail = this.data.wfoDetail;
  }

  withdrawWfoByUser() {
    const id = this.wfoDetail.id;

    if (id) {
      this.wfoService.withdrawWfoByUser(id).subscribe(
        (res) => {
          this.ngOnInit();
          this.refreshService.triggerRefresh('wfo-detail');
          this.refreshService.triggerRefresh('taskdialog');
          this.refreshService.triggerRefresh('tasks');
          this.dialogRef.close();
        },
        (e) => {
        }
      );
    } else {
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Closed' });
  }
}
