<div class="main_header_div">
  <h3 mat-dialog-title>Reason for Decline</h3>
  <div class="child_close_btn">
    <button class="closeButton" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<form [formGroup]="rejectionDetails">
  <div class="col">
    <mat-form-field appearance="outline" class="example">
      <textarea matInput formControlName="reasonForRejection" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="4" cdkAutosizeMaxRows="7" placeholder="Reason in words"></textarea>
    </mat-form-field>
  </div>
</form>

<div class="submit_button_main">
  <button class="add_Emp_btn" type="submit" (click)="rejectRegularization()">
    <span>Submit</span>
  </button>
</div>