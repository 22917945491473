
import { Client } from './client';

interface IProject {
    id?: string;
    clientId?: Client;
    startDate?:Date;
    endDate?:Date;
    managerId?:string;
    projectName?:string;
    projectDescription?:string;
    projectCategory?:string;
    projectCode?:string;
    department?:string;
    talentId?:string;
    skill?:  string [];
}

export class Project {
    id: string;
    clientId: Client;
    startDate:Date;
    endDate:Date;
    managerId:string;
    projectName: string;
    projectDescription:string;
    projectCategory:string;
    projectCode:string;
    department:string;
    talentId:string;
    skill:string[];
    constructor(project: IProject) {
        if (project && project.id) {
            this.id = project.id;
        }
        this.clientId = project && project.clientId || null;
        this.managerId = project && project.managerId || null;
        this.projectName = project && project.projectName || null;
        this.startDate = project && project.startDate || null;
        this.endDate = project && project.endDate || null;
        this.projectDescription = project && project.projectDescription || null;
        this.projectCategory = project && project.projectCategory || null;
        this.projectCode = project && project.projectCode || null;
        this.department =  project && project.department || null;
        this.talentId=project && project.talentId || null;
        this.skill=project && project.skill || null;
    }
}