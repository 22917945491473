import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ClientService } from 'src/app/services/client/client.service';
import { ProjectService } from 'src/app/services/project.service';
import { EmployeeSelectionComponent } from './employee-selection/employee-selection.component';
@Component({
  selector: 'app-add-client-holiday',
  templateUrl: './add-client-holiday.component.html',
  styleUrls: ['./add-client-holiday.component.scss']
})
export class AddClientHolidayComponent implements OnInit {
  clientDetails: FormGroup;
  currentClient: string;
  rowAdded: boolean = false;
  projects=[];
  years: number[] = [];
  selectedYear: number = new Date().getFullYear();
  clientList=[];
  projectList=[];
  clientData=[];
  constructor(
    public dialogRef: MatDialogRef<AddClientHolidayComponent>,
    private fb: FormBuilder,
    public clientservice: ClientService,
    public projectService:ProjectService,
    private dialog: MatDialog,
  ) { 
    this.clientDetails = this.fb.group({  	
      clientName: ['', Validators.required],
      projectName: ['', Validators.required],
      year: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year <= this.selectedYear + 10; year++) {
      this.years.push(year);
    }
    this.getClients();
  }
  closeDialog() {
    this.dialogRef.close();
  }

  isInvalidControl(controlName: string): boolean {
    const control = this.clientDetails.get(controlName);
    return control.invalid && control.touched;
  }

  getClients() {
    this.clientservice.getClientsSubscribe().subscribe((res) => {
        for (let r of res.body) {
            this.clientList.push(r.clientName);
            this.projectService.getprojectfromclient(r.id).subscribe(response => {
                const projectsWithMembers = response.body.map(project => {
                    return {
                        projectId: project.projectId,
                        projectName: project.projectName,
                        projectMembers: project.projectMembers // Assuming this is how project members are stored in the response
                    };
                });
                this.projectList.push({ clientId: r.id, clientName: r.clientName, projects: projectsWithMembers }); 
            });
        }
    });
}


onClientChange(clientName: string): void {
  const selectedClient = this.projectList.find(client => client.clientName === clientName);
  if (selectedClient) {
      const clientId = selectedClient.clientId;
      this.projectService.getprojectfromclient(clientId).subscribe(response => {
          const projectsWithMembers = response.body.map(project => {
              return {
                  projectName: project.projectName,
                  projectMembers: project.projectMembers,
                  clientId:project.clientId,
              };
          });
          this.projects = projectsWithMembers;
         
      });
  } 
}


addRow(): void {
  if (this.clientDetails.valid) {
    const clients = {
      projectName: this.clientDetails.value.projectName,
      clientName: this.clientDetails.value.clientName,
      date: '',
      holidayName: '',
    };
    this.clientData.push(clients);
    this.rowAdded = true;
  }
}

removeRow(index: number): void {
  this.clientData.splice(index, 1);
  this.updateFormDisabledState();
}

updateFormDisabledState(): void {
  if (this.clientData.length === 0) {
    this.clientDetails.enable();
  } else {
    this.clientDetails.disable();
  }
}
openEmployeeDialog(){
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.width = '80%';
  dialogConfig.height = '70%';
  dialogConfig.data = {  projects: this.projects , clientData: this.clientData};
  const dialogRef = this.dialog.open(EmployeeSelectionComponent, dialogConfig);
  dialogRef.afterClosed().subscribe((result) => {
      dialogRef.close();
  });
}


}
