import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProbationFeebackUrlsService {
  private readonly postProbationFeebackUrl='/api/probation/feedbacks';
  private readonly getProbationFeebackUrl='/api/probation/feedbacks/get';
  private readonly updateProbationFeebackUrl='/api/probation/feedbacks/manageFeedbacks';
  private readonly deleteProbationFeebackUrl='/api/probation/feedbacks/deleteFeedbackGroup';

  private readonly getProbationFeedBackById='/api/probation/feedback';
  private readonly getProbationFeedbackGroupById='/api/probation/feedbacks';
  private readonly declineProbationFeedback='/api/probation/feedback/declineFeedback';


  constructor() { }

  probationFeebackBaseUrl() { 
    return this.postProbationFeebackUrl;
  }

  getProbationFeebackBaseUrl(feedbackFor) { 
    return this.getProbationFeebackUrl+'/'+feedbackFor;
  }

  updateProbationFeeback(groupId){
    return this.updateProbationFeebackUrl+'/'+groupId;
  }

  deleteProbationFeeback(id){
    return this.deleteProbationFeebackUrl+'/'+id;
  }

  getProbationFeedBackByIdUrl(feedbackId){
    return this.getProbationFeedBackById+'/'+feedbackId;
  }

  getProbationFeedbackGroupByIdUrl(feedbackGroupId){
    return this.getProbationFeedbackGroupById+'/'+feedbackGroupId;
  }

  declineProbationFeedbackUrl(id){
    return this.declineProbationFeedback+'/'+id;

  }

}
