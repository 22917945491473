import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CmLeaveRejectComponent } from './cm-leave-reject/cm-leave-reject.component';
import { LeaveApplicationsService } from 'src/app/services/cm-leave-applications/leave-applications.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { LeaveApprovalDTO } from 'src/app/dtos/leaveApprovalDTO';
import { UserService } from 'src/app/services/user/user.service';
import { LeaveApprovalService } from 'src/app/services/leave-approval-cm/leave-approval.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LeaveApplicationService } from 'src/app/services/leave-application/leave-application.service';
import { OrderByApprovalStatusPipe } from 'src/app/order-by-approval-status.pipe';
import { Status } from 'src/app/services/user/user-enum';

@Component({
  selector: 'app-leave-approval',
  templateUrl: './leave-approval.component.html',
  styleUrls: ['./leave-approval.component.scss'],

})
export class LeaveApprovalComponent implements OnInit {
  isSpinner: boolean = true;
  searchText: string;
  cmId: String;
  leaveApplicationsList: any = [];
  leaveApplications = new LeaveApprovalDTO();
  page: number = 1;
  filteredLeaveApplications: any = [];
  constructor(
    private dialog: MatDialog,
    private modalService: NgbModal,
    private leaveApplicationsService: LeaveApplicationsService,
    private leaveapplicationervice: LeaveApplicationService,
    private userDetailService: UserDetailsService,
    private userservice: UserService,
    private leaveApprovalservice: LeaveApprovalService,
    private toaster: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getLeaveApplicationForCM();
  }

  getLeaveApplicationForCM() {
    this.isSpinner = true;
    this.leaveApplicationsList = [];
    this.cmId = this.userDetailService.getUserDetails().id;
    this.leaveApplicationsService.getCMLeaveApplications().subscribe(
      (res) => {
        let curr = res.body;
        for (let i = 0; i < curr.length; i++) {
          if (curr[i].approvalStatus == Status.PENDING || curr[i].approvalStatus == Status.APPROVED || curr[i].approvalStatus == Status.REJECTED) {
            if (curr[i].approvalStatus == Status.PENDING || curr[i].approvalStatus == Status.APPROVED || curr[i].approvalStatus == Status.REJECTED) {
              this.userservice.getUserByID(curr[i].userId).subscribe((result) => {
                let days = 'calculating';
                this.leaveapplicationervice
                  .getLeaveTotalCount(curr[i])
                  .subscribe((res) => {
                    days = res.body;
                    const currLeave = {
                      name: result.body.firstName + ' ' + result.body.lastName,
                      empId: result.body.employeeId,
                      userId: curr[i].userId,
                      leaveType: curr[i].typeOfLeave,
                      cmId: this.cmId,
                      startDate: curr[i].startDate,
                      endDate: curr[i].endDate,
                      noOfDays: days,
                      transactionDateTime: curr[i].transactionDateTime,
                      reason: curr[i].reasonForLeave,
                      leaveId: curr[i].id,
                      leaveDesc: curr[i].floaterDescription,
                      attachment: curr[i].doc_base
                        ? atob(curr[i].doc_base).toString()
                        : null,
                      attachmentType: curr[i].doc_base
                        ? this.getDocumentType(atob(curr[i].doc_base).toString())
                        : null,
                      approvalStatus: curr[i].approvalStatus,
                      dataUrl: null,
                    };

                    this.leaveApplicationsList.push(currLeave);

                  });
              });
            }

          }

        }
        this.isSpinner = false;
      }
      ,(error)=>{
        this.toaster.error('Something went wrong', error);
        this.isSpinner = false;
      }
    );
  }
  getDocumentType(temp): string {
    const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];
    for (let type of allowed_types) {
      if (temp.indexOf(type) != -1) {
        return type;
      }
    }
  }
  open(content10, temp) {
    this.modalService
      .open(content10, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => { },
        (reason) => { }
      );
  }
  revokeBox(leave) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    dialogConfig.data = { leave };
    const dialogRef = this.dialog.open(CmLeaveRejectComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }
  approveLeave(leave) {
    this.leaveApplications.approvalStatus = Status.APPROVED;
    this.leaveApplications.leaveApplId = leave.leaveId;
    const leaveApprove = {
      leaveApplicationId: leave.leaveId,
      approvalStatus:
        leave.approvalStatus == Status.PENDING
          ? Status.APPROVED
          : Status.REVOKE_REQUEST_APPROVED,
      reasonForRejection: '',
    };
    this.leaveApprovalservice.update(leaveApprove).subscribe(
      (res) => {
        this.toaster.success('Leave Approved', 'Saved', {});
        this.ngOnInit();
      },
      (e) => {
        this.toaster.error('Leave Cannot be Approved', '', {});
      }
    );
  }
}
