import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-attendance-calendar',
  templateUrl: './attendance-calendar.component.html',
  styleUrls: ['./attendance-calendar.component.scss'],
})
export class AttendanceCalendarComponent implements OnInit {
  activeTab: string = 'APPLY';
  constructor() {}

  ngOnInit(): void {}
  onTabClick(tabName) {
    this.activeTab = tabName;
  }
}
