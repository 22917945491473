import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { BlueAltairTravel } from 'src/app/models/bluealtairtravel';
import { BluealtairtravelService } from 'src/app/services/blue-altair-travel/bluealtairtravel.service';
import { Countries, } from 'src/app/services/country/country-enum';
import { EventTabClosed } from 'src/app/services/user/user-enum';
@Component({
  selector: 'app-travel',
  templateUrl: './travel.component.html',
  styleUrls: ['./travel.component.scss'],
})
export class TravelComponent implements OnInit {
  editBusinesstravel2: FormGroup;
  travelBlueAltairArray: FormArray;
  removebluealtairtravel: String[] = [];
  today: Date;
  countries = [];
  constructor(
    private dialogRef: MatDialogRef<TravelComponent>,
    private bluealtairtravelservice: BluealtairtravelService,
    private fb: FormBuilder,
    private toaster: ToastrService,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
  }
  ngOnInit(): void {
    this.initializeFormArray();
    this.fetchExistingTravelDetails();
  }
  initializeFormArray() {
    this.editBusinesstravel2 = this.fb.group({
      bluealtairarray: this.fb.array([], [Validators.required]),
    });
  }
  fetchExistingTravelDetails() {
    this.today = new Date();
    this.countries = Object.values(Countries);
    this.bluealtairtravelservice
      .gettravelByUserIdSubscribe(this.data)
      .subscribe((res) => {
        if (res.body[0] == null) {
          this.addbluealtairtravel();
        }
        this.setBluealtairtravelFormArray(res.body);
      });
  }
  createbluealtairtravel() {
    const formGroup = this.fb.group(new BlueAltairTravel());
    formGroup.controls.toCountry.setValidators([Validators.required]);
    formGroup.controls.fromCounry.setValidators([Validators.required]);
    formGroup.controls.travelDate.setValidators([Validators.required]);
    formGroup.controls.reasonOfTravel.setValidators([Validators.required,Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9+#. ()-]*$')]);
    return formGroup;
  }
  setBluealtairtravelFormArray(response) {
    this.travelBlueAltairArray = this.editBusinesstravel2.get(
      'bluealtairarray'
    ) as FormArray;
    const travelList: BlueAltairTravel[] =
      this.bluealtairtravelservice.settravelResponse(response);
    for (const s of travelList) {
      const v: BlueAltairTravel = new BlueAltairTravel().bind(s);
      const travelGroup = this.createbluealtairtravel();
      travelGroup.controls.toCountry.setValue(Countries[v.toCountry]);
      travelGroup.controls.fromCounry.setValue(Countries[v.fromCounry]);
      travelGroup.controls.additionalInfo.setValue(v.additionalInfo);
      travelGroup.controls.travelDate.setValue(v.travelDate);
      travelGroup.controls.reasonOfTravel.setValue(v.reasonOfTravel);
      travelGroup.controls.id.setValue(v.id);
      this.travelBlueAltairArray.insert(0, travelGroup);
    }
  }
  BlueAltairTravel(): FormArray {
    return this.editBusinesstravel2.get('bluealtairarray') as FormArray;
  }
  addbluealtairtravel() {
    this.travelBlueAltairArray = this.editBusinesstravel2.get(
      'bluealtairarray'
    ) as FormArray;
    this.travelBlueAltairArray.insert(0, this.createbluealtairtravel());
  }
  removebluealtair(id, i: number) {
    this.removebluealtairtravel.push(id);
    this.BlueAltairTravel().removeAt(i);
  }
  getEnumKeyByEnumValue(myEnum, enumValue) {
    const keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }
  closeDialog(event: Event) {
    this.dialogRef.close({ event: EventTabClosed.Closed });
  }
  checkError = (controlName: string, errorName: string) => {
    return true;
  };
  onSubmit() {
    let isPopUp = false;
    if (this.editBusinesstravel2.valid) {
      for (const travel of this.editBusinesstravel2.value.bluealtairarray) {
        travel.toCountry = this.getEnumKeyByEnumValue(
          Countries,
          travel.toCountry
        );
        travel.fromCounry = this.getEnumKeyByEnumValue(
          Countries,
          travel.fromCounry
        );
        if (
          travel &&
          !travel.id &&
          travel.fromCounry != '' &&
          travel.toCountry != '' &&
          travel.reasonOfTravel != '' &&
          travel.travelDate != undefined
        ) {
          travel.userId = this.data;
          this.bluealtairtravelservice.create(travel).subscribe((res) => {
            if (!isPopUp) {
              this.toaster.success('Successfully Saved');
              isPopUp = true;
            } this.dialogRef.close({ event: EventTabClosed.Closed });
          });
        } else if (
          travel &&
          travel.id &&
          travel.fromCounry != '' &&
          travel.toCountry != '' &&
          travel.reasonOfTravel != '' &&
          travel.travelDate != undefined
        ) {
          travel.userId = this.data;
        }
        this.bluealtairtravelservice.update(travel).subscribe((res) => {
          if (!isPopUp) {
            this.toaster.success('Successfully Saved');
            isPopUp = true;
          } this.dialogRef.close({ event: EventTabClosed.Closed });
        });
      }
    }
    for (const i in this.removebluealtairtravel) {
      this.bluealtairtravelservice.delete(this.removebluealtairtravel[i]);
    }
  }
}
