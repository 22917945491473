<div class="main_header_div">
  <div class="child">
    <h1 class="header_name "><img class="header-icon mr-3" src="assets\icons\qualification.png">Educational
      Qualifications</h1>
  </div>
  <div class="pr-3 pt-3">
    <img class="close-icon" (click)="closeDialog($event)" src="assets/icons/close.png">
  </div>
</div>
<hr />
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 form_main_div">
      <div class="add_button_fix">
        <button class="add_button" type="button"(click)="addEducation()">+Add</button>
        <button class="plus_button mt-4"type="button" (click)="addEducation()">
          <span>+Add</span>
        </button>
      </div>
      <form [formGroup]="editSpotlightDetails">
        <div class="bodyScroll">
          <div class="row" formArrayName="education" *ngFor="let educate of this.Education().controls; let i = index">
            <div class="col-md-10" [formGroupName]="i">
              <div class="row">
                <div class="col-md-3">
                  <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Start Date <span class="contentRed"></span></mat-label>
                    <input matInput [max]="today" [matDatepicker]="picker1" formControlName="yearOfBeginning"
                      placeholder="dd/mm/yyyy" />
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                    <mat-error *ngIf="
                        editSpotlightDetails
                          .get(['education', i, 'yearOfBeginning'])
                          .hasError('required') &&  !editSpotlightDetails
                          .get(['education', i, 'yearOfBeginning'])
                          .hasError('matDatepickerParse')
                      ">
                      Required
                    </mat-error>
                    <mat-error *ngIf="
                        editSpotlightDetails
                          .get(['education', i, 'yearOfBeginning'])
                          .hasError('matDatepickerParse')
                      ">
                      Invalid Date
                    </mat-error>
                    
                  </mat-form-field>
                </div>

                <div class="col-md-3">
                  <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>End Date <span class="contentRed"></span></mat-label>
                    <input matInput [min]="getMinEndDate(i)" [max]="today" [matDatepicker]="picker2"
                      formControlName="yearofCompletion" placeholder="dd/mm/yyyy" />
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                    <mat-error *ngIf="
                        editSpotlightDetails
                          .get(['education', i, 'yearofCompletion'])
                          .hasError('required')&&  !editSpotlightDetails
                          .get(['education', i, 'yearofCompletion'])
                          .hasError('matDatepickerParse')
                      ">
                      Required
                    </mat-error>
                    <mat-error *ngIf="
                        editSpotlightDetails
                          .get(['education', i, 'yearofCompletion'])
                          .hasError('matDatepickerParse')
                      ">
                      Invalid Date
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-md-3">
                  <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Course Name</mat-label>
                    <input matInput formControlName="courseName" autocomplete="off" />
                    <mat-error *ngIf="
                        editSpotlightDetails
                          .get(['education', i, 'courseName'])
                          .hasError('required')
                      ">
                      Required
                    </mat-error>
                    <mat-error *ngIf="
                        editSpotlightDetails
                          .get(['education', i, 'courseName'])
                          .hasError('pattern')
                      ">
                      Invalid Pattern
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>University/Institute Name</mat-label>
                    <input matInput formControlName="universityName" autocomplete="off" />
                    <mat-error *ngIf="
                        editSpotlightDetails
                          .get(['education', i, 'universityName'])
                          .hasError('required')
                      ">
                      Required
                    </mat-error>
                    <mat-error *ngIf="
                    editSpotlightDetails
                    .get(['education', i, 'universityName'])
                          .hasError('pattern')
                      ">
                      Invalid Pattern
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="col-md-2 mt-2">
              <button class="remove_label"type="button" (click)="removeeducation(educate.value.id, i)">
                Remove
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="submit_button_main">
              <button class="save_Emp_btn" type="button"(click)="onSubmit()">
                <span>Save</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>