import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PolicyConfigurationService } from 'src/app/services/leave-policy-configuration/policy-configuration.service';
import { RefreshService } from 'src/app/services/refreshService/refresh.service';

@Component({
  selector: 'app-delete-policy',
  templateUrl: './delete-policy.component.html',
  styleUrls: ['./delete-policy.component.scss']
})
export class DeletePolicyComponent implements OnInit {
  policyDetail: any;

  constructor(
    public dialogRef: MatDialogRef<DeletePolicyComponent>,
    private policyConfigurationService: PolicyConfigurationService,
    private refreshService: RefreshService,
    public toaster: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.policyDetail = this.data.policyDetail;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  deletePolicy() {
    this.policyConfigurationService.deletePolicy(this.policyDetail.id).subscribe((res) => {
      this.toaster.success('Successful', 'Policy Deleted', {});
      this.refreshService.triggerRefresh('leave-policy-configuration');
      this.dialogRef.close();
    },
      (e) => {
        this.toaster.error('Something went wrong', 'Error', {});
      });

  }

}
