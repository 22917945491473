import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FeedbackNotesDTO } from 'src/app/dtos/FeedbackNotesDTO';
import { FeedbackNotesService } from 'src/app/services/feedback-notes/feedback-notes.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { MainContentProjectComponent } from '../../project/main-content-project/main-content-project.component';
@Component({
  selector: 'app-feedback-notes',
  templateUrl: './feedback-notes.component.html',
  styleUrls: ['./feedback-notes.component.scss'],
})
export class FeedbackNotesComponent implements OnInit {
  feedbackNotes: FormGroup;
  feedbackNotesDto = new FeedbackNotesDTO();
  data: any;
  feedbackdto: any;
  profileViewer: any;
  constructor(
    public dialogRef: MatDialogRef<MainContentProjectComponent>,
    public userDetailService: UserDetailsService,
    public fb: FormBuilder,
    public feedbackNotesService: FeedbackNotesService,
    @Inject(MAT_DIALOG_DATA) public notes: any
  ) {
    this.feedbackNotes = this.fb.group({
      note: ['', [Validators.required]],
    });
  }
  ngOnInit(): void {
    this.profileViewer = this.notes.viewer;
    if (this.notes.switchedTab == 'PROBATION') {
      this.feedbackNotesService
        .getProbationnotesbyuserandyear(
          this.notes.directReporteeId,
          this.notes.selected
        )
        .subscribe((res) => {
          this.feedbackdto = res.body;
        });
    } else {
      this.feedbackNotesService
        .getnotesbyuserandyear(this.notes.directReporteeId, this.notes.selected)
        .subscribe((res) => {
          this.feedbackdto = res.body;
        });
    }

    this.setFormData();
  }
  closeDialog() {
    this.dialogRef.close();
  }
  setFormData() {
    if (this.notes.switchedTab == 'PROBATION') {
      this.feedbackNotesService
        .getProbationnotesbyuserandyear(
          this.notes.directReporteeId,
          this.notes.selected
        )
        .subscribe((res) => {
          this.feedbackdto = res.body;
          if (this.profileViewer == 'CM') this.feedbackNotes.get('note').setValue(this.feedbackdto.cmNote);
          if (this.profileViewer == 'HR') this.feedbackNotes.get('note').setValue(this.feedbackdto.hrNote);
        });
    } else {
      this.feedbackNotesService
        .getnotesbyuserandyear(this.notes.directReporteeId, this.notes.selected)
        .subscribe((res) => {
          this.feedbackdto = res.body;
          if (this.profileViewer == 'CM') this.feedbackNotes.get('note').setValue(this.feedbackdto.cmNote);
          if (this.profileViewer == 'HR') this.feedbackNotes.get('note').setValue(this.feedbackdto.hrNote);
        });
    }
  }
  save() {
    this.feedbackNotesDto.userId = this.notes.directReporteeId;
    this.feedbackNotesDto.year = this.notes.selected;
    if (this.profileViewer == 'CM') {
      this.feedbackNotesDto.cmNote = this.feedbackNotes.get('note').value;
      if (this.feedbackdto) this.feedbackNotesDto.hrNote = this.feedbackdto?.hrNote;
    } else if (this.profileViewer == 'HR') {
      this.feedbackNotesDto.hrNote = this.feedbackNotes.get('note').value;
      if (this.feedbackdto) this.feedbackNotesDto.cmNote = this.feedbackdto?.cmNote;
    }
    if (this.feedbackdto) {
      this.feedbackNotesDto.id = this.feedbackdto.id;
    }

    let payload: any = this.feedbackNotesDto;
    if (this.feedbackdto != null) {
      if (this.notes.switchedTab == 'PROBATION') {
        this.feedbackNotesService
          .updateProbationNotes(payload)
          .subscribe(() => { });
      } else {
        this.feedbackNotesService
          .update(payload)
          .subscribe(() => { });
      }
    } else {
      if (this.notes.switchedTab == 'PROBATION') {
        this.feedbackNotesService
          .createProbationNotes(payload)
          .subscribe(() => { });
      } else {
        this.feedbackNotesService
          .create(payload)
          .subscribe(() => { });
      }
    }
    this.dialogRef.close();
  }
}

