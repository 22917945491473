<div class="main">
  <div class="header">
    <div class="mt-2 div-left">
      <span>Exit Checklist</span>
    </div>
    <div class="mt-2 div-right">
      <a (click)="closeDialog()">close</a>
    </div>
  </div>
  <div>
    <div>
      <mat-stepper
        labelPosition="bottom"
        #stepper
        selectedIndex="{{ this.currentStepIndex }}"
        (selectionChange)="setStage($event)"
        [ngClass]="{ 'block-step': updateChecklist }"
      >
        <ng-template matStepperIcon="number" let-index="index">
          {{ index + 1 }}/5
        </ng-template>
        <ng-template matStepperIcon="edit" let-index="index">
          {{ index + 1 }}/5
        </ng-template>
        <mat-step>
          <ng-template matStepLabel
            ><span class="txt">Employee</span></ng-template
          >
          <form>
            <div class="user">
              <div
                class="first-user"
                *ngIf="this.trnxData.typeOfTask !== 'UPDATE_CHECKLIST'"
              >
                <mat-icon class="personicon">person</mat-icon>
                <div class="userdiv">
                  <mat-form-field appearance="outline">
                    <mat-label> Employee </mat-label>
                    <input
                      matInput
                      type="text"
                      [value]="this.userName"
                      readonly
                    />
                  </mat-form-field>
                </div>
                <div class="editicon">
                  <mat-icon (click)="changeEditable()">edit</mat-icon>
                </div>
              </div>
              <div class="second-user">
                <form [formGroup]="userStageFormGroup">
                  <table class="table">
                    <thead>
                      <div class="flex">
                        <th>Sr.no</th>
                        <th class="action">Action</th>
                      </div>
                      <th>Must Completed by</th>
                      <th>Action Completion Date</th>
                      <th>Comments</th>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let rowFormGroup of userStageQueFormArray.controls;
                          let rowIndex = index
                        "
                        [formGroup]="rowFormGroup"
                      >
                        <span class="flex">
                          <td class="first">
                            {{ rowIndex + 1 }}
                          </td>
                          <td class="mid" data-label="Action">
                            <div
                              id="divedit"
                              class="txtbox1 head"
                              contenteditable="false"
                            >
                              {{ rowFormGroup.value.title }}
                              <span
                                *ngIf="rowFormGroup.value.resource !== null"
                              >
                                -
                                <a
                                  [href]="
                                    rowFormGroup.value.resource.resourcePath
                                  "
                                  class="link"
                                  target="_blank"
                                  >{{
                                    rowFormGroup.value.resource.displayText
                                  }}</a
                                >
                              </span>
                            </div>
                          </td>
                        </span>
                        <td class="colu" data-label="Must completed by">
                          <mat-form-field
                            class="date-picker"
                            appearance="outline"
                          >
                            <input
                              matInput
                              [matDatepicker]="picker1"
                              formControlName="expectedDate"
                              [disabled]="
                                this.trnxData.typeOfTask === 'CREATE_CHECKLIST'
                                  ? true
                                  : false
                              "
                            />
                            <mat-datepicker-toggle matSuffix [for]="picker1">
                              <mat-icon matDatepickerToggleIcon>
                                calendar_month
                              </mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                          </mat-form-field>
                        </td>
                        <td class="colu" data-label="Actual Completion Date">
                          <mat-form-field
                            class="date-picker"
                            appearance="outline"
                          >
                            <input
                              matInput
                              [matDatepicker]="picker"
                              formControlName="completionDate"
                              [disabled]="
                                this.trnxData.typeOfTask === 'CREATE_CHECKLIST'
                                  ? true
                                  : false
                              "
                            />
                            <mat-datepicker-toggle matSuffix [for]="picker">
                              <mat-icon matDatepickerToggleIcon>
                                calendar_month
                              </mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field>
                        </td>
                        <td class="colu" data-label="Comments">
                          <div>
                            <input
                              type="text"
                              formControlName="comment"
                              class="txtbox txtlbl"
                              [readonly]="
                                this.trnxData.typeOfTask === 'CREATE_CHECKLIST'
                                  ? true
                                  : false
                              "
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
                <div class="btn">
                  <button
                    class="assignbtn"
                    (click)="assignUser()"
                    *ngIf="this.trnxData.typeOfTask !== 'UPDATE_CHECKLIST'"
                  >
                    Assign Employee
                  </button>
                  <button
                    class="assignbtn"
                    (click)="submitUserStage()"
                    *ngIf="this.trnxData.typeOfTask === 'UPDATE_CHECKLIST'"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>PnT</ng-template>
          <form [formGroup]="hrFormGroup">
            <div class="user">
              <div class="first-user">
                <mat-icon class="personicon">person</mat-icon>
                <div>
                  <mat-form-field appearance="outline">
                    <mat-label> PnT </mat-label>
                    <input
                      formControlName="hr"
                      matInput
                      type="text"
                      [matAutocomplete]="auto"
                      [readonly]="
                        this.trnxData.typeOfTask === 'UPDATE_CHECKLIST'
                      "
                    />
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option
                        *ngFor="let item of filteredOptions"
                        [value]="item"
                      >
                        {{ item }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <div class="editicon">
                  <mat-icon>edit</mat-icon>
                </div>
              </div>
              <div class="second-user">
                <form [formGroup]="hrStageFormGroup">
                  <table class="table">
                    <thead>
                      <div class="flex">
                        <th>Sr.no</th>
                        <th class="action">Action</th>
                      </div>
                      <th>Must Completed by</th>
                      <th>Action Completion Date</th>
                      <th>Comments</th>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let rowFormGroup of hrStageQueFormArray.controls;
                          let rowIndex = index
                        "
                        [formGroup]="rowFormGroup"
                      >
                        <span class="flex">
                          <td class="first">
                            {{ rowIndex + 1 }}
                          </td>
                          <td class="mid" data-label="Action">
                            <div
                              id="divedit"
                              class="txtbox1 head"
                              contenteditable="false"
                            >
                              {{ rowFormGroup.value.title }}
                              <span
                                *ngIf="rowFormGroup.value.resource !== null"
                              >
                                -
                                <a
                                  [href]="
                                    rowFormGroup.value.resource.resourcePath
                                  "
                                  class="link"
                                  target="_blank"
                                  >{{
                                    rowFormGroup.value.resource.displayText
                                  }}</a
                                >
                              </span>
                            </div>
                          </td>
                        </span>
                        <td class="colu" data-label="Must completed by">
                          <mat-form-field
                            class="date-picker"
                            appearance="outline"
                          >
                            <input
                              matInput
                              [matDatepicker]="picker2"
                              formControlName="expectedDate"
                              [disabled]="
                                this.trnxData.typeOfTask === 'CREATE_CHECKLIST'
                                  ? true
                                  : false
                              "
                            />
                            <mat-datepicker-toggle matSuffix [for]="picker2">
                              <mat-icon matDatepickerToggleIcon>
                                calendar_month
                              </mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                          </mat-form-field>
                        </td>
                        <td class="colu" data-label="Actual Completion Date">
                          <mat-form-field
                            class="date-picker"
                            appearance="outline"
                          >
                            <input
                              matInput
                              [matDatepicker]="picker3"
                              formControlName="completionDate"
                              [disabled]="
                                this.trnxData.typeOfTask === 'CREATE_CHECKLIST'
                                  ? true
                                  : false
                              "
                            />
                            <mat-datepicker-toggle matSuffix [for]="picker3">
                              <mat-icon matDatepickerToggleIcon>
                                calendar_month
                              </mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker3></mat-datepicker>
                          </mat-form-field>
                        </td>
                        <td class="colu" data-label="Comments">
                          <div>
                            <input
                              type="text"
                              formControlName="comment"
                              class="txtbox txtlbl"
                              [readonly]="
                                this.trnxData.typeOfTask === 'CREATE_CHECKLIST'
                                  ? true
                                  : false
                              "
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
            <div class="btn">
              <button
                class="assignbtn"
                (click)="assignHr()"
                *ngIf="this.trnxData.typeOfTask !== 'UPDATE_CHECKLIST'"
              >
                Assign HR Owner
              </button>
              <button
                class="assignbtn"
                (click)="submitHrStage()"
                *ngIf="this.trnxData.typeOfTask === 'UPDATE_CHECKLIST'"
              >
                Submit
              </button>
            </div>
          </form>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Admin</ng-template>
          <div class="tab">
            <mat-tab-group
              class="operation-owner-tab"
              mat-align-tabs="center"
              [selectedIndex]="this.mattabCurrentStepIndex"
            >
              <mat-tab
                [disabled]="
                  this.trnxData.subTask !== 'ADMIN_STAGE' &&
                  this.trnxData.typeOfTask === 'UPDATE_CHECKLIST'
                "
              >
                <ng-template mat-tab-label>
                  <div class="oo-label">
                    <div class="part">1/2</div>
                    <div class="part-label">Admin</div>
                  </div>
                </ng-template>
                <form [formGroup]="adminFormGroup">
                  <div class="user">
                    <div class="first-user">
                      <mat-icon class="personicon">person</mat-icon>
                      <div>
                        <mat-form-field appearance="outline">
                          <mat-label> Operation Owner </mat-label>
                          <input
                            formControlName="admin"
                            matInput
                            type="text"
                            [matAutocomplete]="auto"
                            [readonly]="
                              this.trnxData.typeOfTask === 'UPDATE_CHECKLIST'
                            "
                          />
                          <mat-autocomplete #auto="matAutocomplete">
                            <mat-option
                              *ngFor="let item of filteredOptions"
                              [value]="item"
                            >
                              {{ item }}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                      <div class="editicon">
                        <mat-icon>edit</mat-icon>
                      </div>
                    </div>
                    <div class="second-user">
                      <form [formGroup]="adminStageFormGroup">
                        <table class="table">
                          <thead>
                            <div class="flex">
                              <th>Sr.no</th>
                              <th class="action">Action</th>
                            </div>
                            <th>Must Completed by</th>
                            <th>Action Completion Date</th>
                            <th>Comments</th>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let rowFormGroup of adminStageQueFormArray.controls;
                                let rowIndex = index
                              "
                              [formGroup]="rowFormGroup"
                            >
                              <span class="flex">
                                <td class="first">
                                  {{ rowIndex + 1 }}
                                </td>
                                <td class="mid" data-label="Action">
                                  <div
                                    id="divedit"
                                    class="txtbox1 head"
                                    contenteditable="false"
                                  >
                                    {{ rowFormGroup.value.title }}
                                    <span
                                      *ngIf="
                                        rowFormGroup.value.resource !== null
                                      "
                                    >
                                      -
                                      <a
                                        [href]="
                                          rowFormGroup.value.resource
                                            .resourcePath
                                        "
                                        class="link"
                                        target="_blank"
                                        >{{
                                          rowFormGroup.value.resource
                                            .displayText
                                        }}</a
                                      >
                                    </span>
                                  </div>
                                </td>
                              </span>
                              <td class="colu" data-label="Must completed by">
                                <mat-form-field
                                  class="date-picker"
                                  appearance="outline"
                                >
                                  <input
                                    matInput
                                    [matDatepicker]="picker2"
                                    formControlName="expectedDate"
                                    [disabled]="
                                      this.trnxData.typeOfTask ===
                                      'CREATE_CHECKLIST'
                                        ? true
                                        : false
                                    "
                                  />
                                  <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker2"
                                  >
                                    <mat-icon matDatepickerToggleIcon>
                                      calendar_month
                                    </mat-icon>
                                  </mat-datepicker-toggle>
                                  <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                              </td>
                              <td
                                class="colu"
                                data-label="Actual Completion Date"
                              >
                                <mat-form-field
                                  class="date-picker"
                                  appearance="outline"
                                >
                                  <input
                                    matInput
                                    [matDatepicker]="picker3"
                                    formControlName="completionDate"
                                    [disabled]="
                                      this.trnxData.typeOfTask ===
                                      'CREATE_CHECKLIST'
                                        ? true
                                        : false
                                    "
                                  />
                                  <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker3"
                                  >
                                    <mat-icon matDatepickerToggleIcon>
                                      calendar_month
                                    </mat-icon>
                                  </mat-datepicker-toggle>
                                  <mat-datepicker #picker3></mat-datepicker>
                                </mat-form-field>
                              </td>
                              <td class="colu" data-label="Comments">
                                <div>
                                  <input
                                    type="text"
                                    formControlName="comment"
                                    class="txtbox txtlbl"
                                    [readonly]="
                                      this.trnxData.typeOfTask ===
                                      'CREATE_CHECKLIST'
                                        ? true
                                        : false
                                    "
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </form>
                    </div>
                  </div>
                  <div class="btn">
                    <button
                      class="assignbtn"
                      (click)="assignAdmin()"
                      *ngIf="this.trnxData.typeOfTask !== 'UPDATE_CHECKLIST'"
                    >
                      Assign Operation Owner
                    </button>
                    <button
                      class="assignbtn"
                      (click)="submitHrStage()"
                      *ngIf="this.trnxData.typeOfTask === 'UPDATE_CHECKLIST'"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </mat-tab>
              <mat-tab
                [disabled]="
                  this.trnxData.subTask !== 'OPERATIOMS_STAGE' &&
                  this.trnxData.typeOfTask === 'UPDATE_CHECKLIST'
                "
              >
                <ng-template mat-tab-label>
                  <div class="oo-label">
                    <div class="part">2/2</div>
                    <div class="part-label">Operation Owner</div>
                  </div>
                </ng-template>
                <form [formGroup]="operationFormGroup">
                  <div class="user">
                    <div class="first-user">
                      <mat-icon class="personicon">person</mat-icon>
                      <div>
                        <mat-form-field appearance="outline">
                          <mat-label> Operation Owner </mat-label>
                          <input
                            formControlName="operation_owner"
                            matInput
                            type="text"
                            [matAutocomplete]="auto"
                            [readonly]="
                              this.trnxData.typeOfTask === 'UPDATE_CHECKLIST'
                            "
                          />
                          <mat-autocomplete #auto="matAutocomplete">
                            <mat-option
                              *ngFor="let item of filteredOptions"
                              [value]="item"
                            >
                              {{ item }}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                      <div class="editicon">
                        <mat-icon>edit</mat-icon>
                      </div>
                    </div>
                    <div class="second-user">
                      <form [formGroup]="operationStageFormGroup">
                        <table class="table">
                          <thead>
                            <div class="flex">
                              <th>Sr.no</th>
                              <th class="action">Action</th>
                            </div>
                            <th>Must Completed by</th>
                            <th>Action Completion Date</th>
                            <th>Comments</th>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let rowFormGroup of operationStageQueFormArray.controls;
                                let rowIndex = index
                              "
                              [formGroup]="rowFormGroup"
                            >
                              <span class="flex">
                                <td class="first">
                                  {{ rowIndex + 1 }}
                                </td>
                                <td class="mid" data-label="Action">
                                  <div
                                    id="divedit"
                                    class="txtbox1 head"
                                    contenteditable="false"
                                  >
                                    {{ rowFormGroup.value.title }}
                                    <span
                                      *ngIf="
                                        rowFormGroup.value.resource !== null
                                      "
                                    >
                                      -
                                      <a
                                        [href]="
                                          rowFormGroup.value.resource
                                            .resourcePath
                                        "
                                        class="link"
                                        target="_blank"
                                        >{{
                                          rowFormGroup.value.resource
                                            .displayText
                                        }}</a
                                      >
                                    </span>
                                  </div>
                                </td>
                              </span>
                              <td class="colu" data-label="Must completed by">
                                <mat-form-field
                                  class="date-picker"
                                  appearance="outline"
                                >
                                  <input
                                    matInput
                                    [matDatepicker]="picker2"
                                    formControlName="expectedDate"
                                    [disabled]="
                                      this.trnxData.typeOfTask ===
                                      'CREATE_CHECKLIST'
                                        ? true
                                        : false
                                    "
                                  />
                                  <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker2"
                                  >
                                    <mat-icon matDatepickerToggleIcon>
                                      calendar_month
                                    </mat-icon>
                                  </mat-datepicker-toggle>
                                  <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                              </td>
                              <td
                                class="colu"
                                data-label="Actual Completion Date"
                              >
                                <mat-form-field
                                  class="date-picker"
                                  appearance="outline"
                                >
                                  <input
                                    matInput
                                    [matDatepicker]="picker3"
                                    formControlName="completionDate"
                                    [disabled]="
                                      this.trnxData.typeOfTask ===
                                      'CREATE_CHECKLIST'
                                        ? true
                                        : false
                                    "
                                  />
                                  <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker3"
                                  >
                                    <mat-icon matDatepickerToggleIcon>
                                      calendar_month
                                    </mat-icon>
                                  </mat-datepicker-toggle>
                                  <mat-datepicker #picker3></mat-datepicker>
                                </mat-form-field>
                              </td>
                              <td class="colu" data-label="Comments">
                                <div>
                                  <input
                                    type="text"
                                    formControlName="comment"
                                    class="txtbox txtlbl"
                                    [readonly]="
                                      this.trnxData.typeOfTask ===
                                      'CREATE_CHECKLIST'
                                        ? true
                                        : false
                                    "
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </form>
                    </div>
                  </div>
                  <div class="btn">
                    <button
                      class="assignbtn"
                      (click)="assignOperationOwner()"
                      *ngIf="this.trnxData.typeOfTask !== 'UPDATE_CHECKLIST'"
                    >
                      Assign Operation Owner
                    </button>
                    <button
                      class="assignbtn"
                      (click)="submitHrStage()"
                      *ngIf="this.trnxData.typeOfTask === 'UPDATE_CHECKLIST'"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </mat-tab>
            </mat-tab-group>
          </div>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Account</ng-template>
          <form [formGroup]="financeFormGroup">
            <div class="user">
              <div class="first-user">
                <mat-icon class="personicon">person</mat-icon>
                <div>
                  <mat-form-field appearance="outline">
                    <mat-label> Account </mat-label>
                    <input
                      formControlName="finance_owner"
                      matInput
                      type="text"
                      [matAutocomplete]="auto"
                      [readonly]="
                        this.trnxData.typeOfTask === 'UPDATE_CHECKLIST'
                      "
                    />
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option
                        *ngFor="let item of filteredOptions"
                        [value]="item"
                      >
                        {{ item }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <div class="editicon">
                  <mat-icon>edit</mat-icon>
                </div>
              </div>
              <div class="second-user">
                <form [formGroup]="financeStageFormGroup">
                  <table class="table">
                    <thead>
                      <div class="flex">
                        <th>Sr.no</th>
                        <th class="action">Action</th>
                      </div>
                      <th>Must Completed by</th>
                      <th>Action Completion Date</th>
                      <th>Comments</th>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let rowFormGroup of financeStageQueFormArray.controls;
                          let rowIndex = index
                        "
                        [formGroup]="rowFormGroup"
                      >
                        <span class="flex">
                          <td class="first">
                            {{ rowIndex + 1 }}
                          </td>
                          <td class="mid" data-label="Action">
                            <div
                              id="divedit"
                              class="txtbox1 head"
                              contenteditable="false"
                            >
                              {{ rowFormGroup.value.title }}
                              <span
                                *ngIf="rowFormGroup.value.resource !== null"
                              >
                                -
                                <a
                                  [href]="
                                    rowFormGroup.value.resource.resourcePath
                                  "
                                  class="link"
                                  target="_blank"
                                  >{{
                                    rowFormGroup.value.resource.displayText
                                  }}</a
                                >
                              </span>
                            </div>
                          </td>
                        </span>
                        <td class="colu" data-label="Must completed by">
                          <mat-form-field
                            class="date-picker"
                            appearance="outline"
                          >
                            <input
                              matInput
                              [matDatepicker]="picker2"
                              formControlName="expectedDate"
                              [disabled]="
                                this.trnxData.typeOfTask === 'CREATE_CHECKLIST'
                                  ? true
                                  : false
                              "
                            />
                            <mat-datepicker-toggle matSuffix [for]="picker2">
                              <mat-icon matDatepickerToggleIcon>
                                calendar_month
                              </mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                          </mat-form-field>
                        </td>
                        <td class="colu" data-label="Actual Completion Date">
                          <mat-form-field
                            class="date-picker"
                            appearance="outline"
                          >
                            <input
                              matInput
                              [matDatepicker]="picker3"
                              formControlName="completionDate"
                              [disabled]="
                                this.trnxData.typeOfTask === 'CREATE_CHECKLIST'
                                  ? true
                                  : false
                              "
                            />
                            <mat-datepicker-toggle matSuffix [for]="picker3">
                              <mat-icon matDatepickerToggleIcon>
                                calendar_month
                              </mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker3></mat-datepicker>
                          </mat-form-field>
                        </td>
                        <td class="colu" data-label="Comments">
                          <div>
                            <input
                              type="text"
                              formControlName="comment"
                              class="txtbox txtlbl"
                              [readonly]="
                                this.trnxData.typeOfTask === 'CREATE_CHECKLIST'
                                  ? true
                                  : false
                              "
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
            <div class="btn">
              <button
                class="assignbtn"
                (click)="assignFinanceOwner()"
                *ngIf="this.trnxData.typeOfTask !== 'UPDATE_CHECKLIST'"
              >
                Assign Finance Owner
              </button>
              <button
                class="assignbtn"
                (click)="submitFinanceStage()"
                *ngIf="this.trnxData.typeOfTask === 'UPDATE_CHECKLIST'"
              >
                Submit
              </button>
            </div>
          </form>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Career Manager</ng-template>
          <form [formGroup]="cmFormGroup">
            <div class="user">
              <div class="first-user">
                <mat-icon class="personicon">person</mat-icon>
                <div>
                  <mat-form-field appearance="outline">
                    <mat-label> Career Manager </mat-label>
                    <input
                      formControlName="cm"
                      matInput
                      type="text"
                      [matAutocomplete]="auto"
                      [readonly]="
                        this.trnxData.typeOfTask === 'UPDATE_CHECKLIST'
                      "
                    />
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option
                        *ngFor="let item of filteredOptions"
                        [value]="item"
                      >
                        {{ item }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <div class="editicon">
                  <mat-icon>edit</mat-icon>
                </div>
              </div>
              <div class="second-user">
                <form [formGroup]="cmStageFormGroup">
                  <table class="table">
                    <thead>
                      <div class="flex">
                        <th>Sr.no</th>
                        <th class="action">Action</th>
                      </div>
                      <th>Must Completed by</th>
                      <th>Action Completion Date</th>
                      <th>Comments</th>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let rowFormGroup of cmStageQueFormArray.controls;
                          let rowIndex = index
                        "
                        [formGroup]="rowFormGroup"
                      >
                        <span class="flex">
                          <td class="first">
                            {{ rowIndex + 1 }}
                          </td>
                          <td class="mid" data-label="Action">
                            <div
                              id="divedit"
                              class="txtbox1 head"
                              contenteditable="false"
                            >
                              {{ rowFormGroup.value.title }}
                              <span
                                *ngIf="rowFormGroup.value.resource !== null"
                              >
                                -
                                <a
                                  [href]="
                                    rowFormGroup.value.resource.resourcePath
                                  "
                                  class="link"
                                  target="_blank"
                                  >{{
                                    rowFormGroup.value.resource.displayText
                                  }}</a
                                >
                              </span>
                            </div>
                          </td>
                        </span>
                        <td class="colu" data-label="Must completed by">
                          <mat-form-field
                            class="date-picker"
                            appearance="outline"
                          >
                            <input
                              matInput
                              [matDatepicker]="picker2"
                              formControlName="expectedDate"
                              [disabled]="
                                this.trnxData.typeOfTask === 'CREATE_CHECKLIST'
                                  ? true
                                  : false
                              "
                            />
                            <mat-datepicker-toggle matSuffix [for]="picker2">
                              <mat-icon matDatepickerToggleIcon>
                                calendar_month
                              </mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                          </mat-form-field>
                        </td>
                        <td class="colu" data-label="Actual Completion Date">
                          <mat-form-field
                            class="date-picker"
                            appearance="outline"
                          >
                            <input
                              matInput
                              [matDatepicker]="picker3"
                              formControlName="completionDate"
                              [disabled]="
                                this.trnxData.typeOfTask === 'CREATE_CHECKLIST'
                                  ? true
                                  : false
                              "
                            />
                            <mat-datepicker-toggle matSuffix [for]="picker3">
                              <mat-icon matDatepickerToggleIcon>
                                calendar_month
                              </mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker3></mat-datepicker>
                          </mat-form-field>
                        </td>
                        <td class="colu" data-label="Comments">
                          <div>
                            <input
                              type="text"
                              formControlName="comment"
                              class="txtbox txtlbl"
                              [readonly]="
                                this.trnxData.typeOfTask === 'CREATE_CHECKLIST'
                                  ? true
                                  : false
                              "
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
            <div class="btn">
              <button
                class="assignbtn"
                (click)="assignCarreerManager()"
                *ngIf="this.trnxData.typeOfTask !== 'UPDATE_CHECKLIST'"
              >
                Assign Career Manager
              </button>
              <button
                class="assignbtn"
                (click)="submitCarreerManagerStage()"
                *ngIf="this.trnxData.typeOfTask === 'UPDATE_CHECKLIST'"
              >
                Submit
              </button>
            </div>
          </form>
        </mat-step>
      </mat-stepper>
    </div>
  </div>
</div>
