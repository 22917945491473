<div class="main-div">
  <div>
    <div class="container">
      <h1 class="header_name"><img class="header-icon mr-3" src="assets/icons/rtf.png"> Real-time Feedback</h1>
      <div class="pt-3">
        <img class="close-icon" (click)="closeDialogue()" src="assets/icons/close.png">
      </div>
    </div>
    <hr>
    <div>
      <form [formGroup]="form" (ngSubmit)="submitForm()">
        <div class="form-element">
          <div class="fb-title">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Feedback Title</mat-label>
              <input matInput type="text" autocomplete="off" formControlName="feedbackTitle" pattern="[a-zA-Z\s]+"
                maxlength="32" required>

              <mat-error *ngIf="form.get('feedbackTitle').hasError('required')">
                Required
              </mat-error>
              <mat-error *ngIf="
                  form.get('feedbackTitle').hasError('pattern') &&
                  form.get('feedbackTitle').touched
                ">
                Only alphabets and spaces are allowed
              </mat-error>
              <mat-error *ngIf="
                  form.get('feedbackTitle').hasError('maxlength') &&
                  form.get('feedbackTitle').touched
                ">
                Feedback Title should not exceed 32 characters
              </mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Year</mat-label>
              <mat-select formControlName="selectedYear" required>
                <mat-option *ngFor="let year of years" [value]="year">
                  {{ year }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
                  form.get('selectedYear').hasError('required') &&
                  form.get('selectedYear').touched
                ">
                Required
              </mat-error>
            </mat-form-field>
          </div>

          <div class="margin-right">
            <mat-form-field class="feedbackTitle" appearance="outline" class="example-full-width">
              <mat-label>Giving feedback as</mat-label>
              <mat-select formControlName="selectedRole" name="selectedRole" required>
                <mat-option *ngFor="let role of feedbackRole" [value]="role">
                  {{ role | underScoreToSpace | titlecase }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
                  form.get('selectedRole').hasError('required') &&
                  form.get('selectedRole').touched
                ">
                Required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="button-container">
          <button class="btn-sub" type="submit" [disabled]="submitBtnDisabled">
            {{ submitBtnText }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>