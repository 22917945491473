import { Component, Inject, NgModule, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PassportDetailsDTO } from 'src/app/dtos/PassportDetailsDTO';
import { PassportDetails } from 'src/app/models/passportdetails';
import { PassportDetailsService } from 'src/app/services/passport-details/passport-details.service';
import { Countries, } from 'src/app/services/country/country-enum';
import { EventTabClosed } from 'src/app/services/user/user-enum';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
@Component({
  selector: 'app-passport-details',
  templateUrl: './passport-details.component.html',
  styleUrls: ['./passport-details.component.scss'],
})
export class PassportDetailsComponent implements OnInit {
  editBusinessTravel1: FormGroup;
  passportBase64Array = [];
  passport = new PassportDetailsDTO();
  passports1: FormArray;
  isPassportSaved: Boolean;
  passportName: string;
  removedPassport = [];
  imageError: string;
  bytes: string;
  countries = [];
  dataSubscription
  userid: string
  constructor(private fb: FormBuilder,
    private modalService: NgbModal, private toaster: ToastrService,
    private feedbackService: FeedbackService,
    private passportservice: PassportDetailsService,
    private dialogRef: MatDialogRef<PassportDetailsComponent>, @Inject(MAT_DIALOG_DATA) private data: any
  ) {
  }
  ngOnInit(): void {
    this.fetchExistingPassport();
  }
  fetchExistingPassport() {
    this.editBusinessTravel1 = this.fb.group({
      passports: this.fb.array([], [Validators.required]),
    });
    this.countries = Object.values(Countries);
    this.passportservice
      .getpassByUserIdSubscribe(this.data)
      .subscribe((res) => {
        if (res.body[0] == null)
          this.addPassport();
        this.setPassportFormArray(res.body);
      });
  }
  onInputToUppercase(event: any) {
    const newValue = event.target.value.toUpperCase();
    event.target.value = newValue;
  }
  PassportDetails(): FormArray {
    return this.editBusinessTravel1.get('passports') as FormArray;
  }
  addPassport() {
    this.passports1 = this.editBusinessTravel1.get('passports') as FormArray;
    this.passports1.insert(0, this.createPassport());
  }
  createPassport() {
    const formGroup = this.fb.group(new PassportDetails());
    formGroup.controls.country.setValidators([Validators.required]);
    formGroup.controls.expiryDate.setValidators([Validators.required]);
    formGroup.controls.passportNumber.setValidators([
      Validators.required,
      Validators.pattern('^(?!.*(?:1234567|2345678|3456789|4567890|abcdefg|bcdefgh|cdefghij|defghijk|efghijkl|fghijklm|ghijklmn|hijklmno|ijklmnop|jklmnopq|klmnopqr|lmnopqrs|mnopqrstu|nopqrstuv|opqrstuvw|pqrstuvwx|qrstuvwxy|rstuvwxyz))[A-Za-z0-9]{8,9}$'),
    ]);
    formGroup.controls.passportb;
    return formGroup;
  }
  setPassportFormArray(response) {
    this.passports1 = this.editBusinessTravel1.get('passports') as FormArray;
    const passportList: PassportDetails[] =
      this.passportservice.setPassportResponse(response);
    for (const s of passportList) {
      const v: PassportDetails = new PassportDetails().bind(s);
      const passportdetails = this.createPassport();
      passportdetails.controls.id.setValue(v.id);
      passportdetails.controls.country.setValue(Countries[v.country]);
      passportdetails.controls.expiryDate.setValue(v.expiryDate);
      passportdetails.controls.passportNumber.setValue(v.passportNumber);
      passportdetails.controls.passportb.setValue(v.passportNumber);
      this.passports1.insert(0, passportdetails);
    }
  }
  getEnumKeyByEnumValue(myEnum, enumValue) {
    const keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }
  removePassport(id, i: number) {
    this.removedPassport.push(id);
    this.PassportDetails().removeAt(i);
  }
  closeDialog(event: Event) {
    this.dialogRef.close({ event: EventTabClosed.Closed });
  }
  onSubmit() {
    let isPopUp = false;
    if (this.editBusinessTravel1.valid) {
      for (const passport of this.editBusinessTravel1.value.passports) {
        passport.country = this.getEnumKeyByEnumValue(
          Countries,
          passport.country
        );
        if (
          passport &&
          !passport.id &&
          passport.country != '' &&
          passport.expiryDate != '' &&
          passport.passportNumber != undefined
        ) {
          passport.userId = this.data;
          this.passportservice.create(passport).subscribe((res) => {
            if (!isPopUp) {
              this.toaster.success('Successfully Saved');
              isPopUp = true;
            }
            this.dialogRef.close({ event: EventTabClosed.Closed });
          });
        } else if (
          passport &&
          passport.id &&
          passport.country != '' &&
          passport.expiryDate != '' &&
          passport.passportNumber != undefined
        ) {
          passport.userId = this.data;
          this.passportservice.update(passport).subscribe((res) => {
            if (!isPopUp) {
              this.toaster.success('Successfully Saved');
              isPopUp = true;
            }
            this.dialogRef.close({ event: EventTabClosed.Closed });
          });
        }
      }
      for (const i in this.removedPassport) {
        this.passportservice.delete(this.removedPassport[i]);
      }
    }
  }
}
