import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Usergoal } from 'src/app/models/usergoal';
import { RestAPIService } from '../restAPI/rest-api.service';
import { UsergoalurlService } from '../restAPI/usergoalURLs/usergoalurl.service';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class UserGoalService {
  goals = [];
  public yearSource = new BehaviorSubject(new Date().getFullYear().toString());
  public year = this.yearSource.asObservable();
  constructor(private restApi: RestAPIService,
    private usergoalUrl: UsergoalurlService,
    private toast: ToastrService
  ) { }


  changeYear(year: string) {
    this.yearSource.next(year)
    this.year.subscribe(res => {
    })
  }

  getGoals() {
    this.restApi
      .get(this.usergoalUrl.getBaseUrl())
      .subscribe((res) => {
        this.setGoals(res.body);
      });
  }
  setGoals(goals) {

    this.goals = [];
    for (let goal of goals) {
      this.goals.push(new Usergoal(goal));
    }
  }
  getGoalByID(id) {
    return this.restApi.get(this.usergoalUrl.getByFieldUrl(id))
  }

  getGoalByUserAndYear(userid, year) {
    return this.restApi.get(this.usergoalUrl.getUserIdAndYearUrl(userid, year))
  }

  create(payload) {

    return this.restApi.post(
      this.usergoalUrl.getBaseUrl(),
      payload
    )
  }
  update(payload) {
    return this.restApi.put(
      this.usergoalUrl.getBaseUrl(),
      payload
    )
  }
  delete(id: any) {
    this.restApi.delete(
      this.usergoalUrl.getByFieldUrl(id)
    ).subscribe(res => {
      this.toast.warning('Deleted Goal Success', 'Success');
    })
  }
  private _listners = new Subject<any>();
  listen(): Observable<any> {
    return this._listners.asObservable();
  }
  filter(filterBy: string) {
    this._listners.next(filterBy);
  }

  selectedYear = new Date().getFullYear();
  private _subject = new BehaviorSubject<Number>(this.selectedYear);


  setCurrentYear(year: Number) {
    return this._subject.next(year);
  }

  getCurrentYear(): Observable<Number> {
    return this._subject.asObservable();
  }
}
