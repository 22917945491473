import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InitialAvatarService {
  backfroundColor = ["#330e47",
    "#470e45",
    "#0e1347",
    "#0e4728",
    "#473a0e",
  ]

  public avatar;
  constructor() { }

  getInitialImage(strOne, strTwo) {
    if (strOne || strTwo) {

      let name: string = strOne[0] + strTwo[0]

      const canvas = document.createElement("canvas")
      const context = canvas.getContext("2d")

      canvas.height = 200
      canvas.width = 200

      context.fillStyle = this.backfroundColor[
        Math.floor(Math.random() * this.backfroundColor.length)
      ];
      context.fillRect(0, 0, canvas.width, canvas.height);

      // Draw text
      context.font = "bold 100px Roboto";
      context.fillStyle = "white";
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillText(name.toUpperCase(), canvas.width / 2, canvas.height / 2);

      return canvas.toDataURL("image/png");


    } else {
      return "assets/icons/download.png"
    }
  }

  setAvatar(firstName,LastName){
    this.avatar=  this.getInitialImage(firstName,LastName)
  }

  getAvatar()
  {
    return this.avatar
  }
}
