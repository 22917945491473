import { Component, OnInit } from '@angular/core';
import { AttendanceDTO } from 'src/app/dtos/AttendanceDTO';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { AbsentService } from 'src/app/services/absent/absent.service';
import { AttendanceStatus } from 'src/app/services/attendance-extra/attendance-enum';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
  selector: 'app-approved-regularizations',
  templateUrl: './approved-regularizations.component.html',
  styleUrls: ['./approved-regularizations.component.scss']
})
export class ApprovedRegularizationsComponent implements OnInit {
  regularizedDates: AttendanceDTO[] = [];
  userDetail: UserDTO;
  constructor(
    private userdetails: UserDetailsService,
    private absentService: AbsentService,
  ) { 

  }

  ngOnInit(): void {
    this.userDetail = this.userdetails.getUserDetails();
    this.getStatus(AttendanceStatus.REGULARIZED_PRESENT, this.userDetail.id)
  }

  getStatus( attendanceStatus,userId) {
    this.userDetail = this.userdetails.getUserDetails();
    this.regularizedDates = [];
    const user = this.userdetails.getUserDetails();
    this.absentService.getstatusbyuser(AttendanceStatus.REGULARIZED_PRESENT,userId).subscribe(res => {
      for (let i = 0; i < res.body.length; i++) {
        const newItem = res.body[i];
        this.regularizedDates.push(newItem);
      }
    }
    )

    return this.regularizedDates;
  }

}
