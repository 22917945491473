import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfigureLeaveDialogComponent } from '../configure-leave-dialog/configure-leave-dialog.component';
import { UserService } from 'src/app/services/user/user.service';
import { Observable, forkJoin } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { LeaveBalanceService } from 'src/app/services/leaveBalances/leave-balance.service';
import { DetailLeaveComponent } from '../detail-leave/detail-leave.component';
import { TypeOfLeave } from 'src/app/services/leaveManagment/leave-enum';
import { ConfigureEmployeeLeaveDialogComponent } from '../configure-employee-leave-dialog/configure-employee-leave-dialog.component';
import { BulkConfigureLeaveComponent } from '../bulk-configure-leave/bulk-configure-leave.component';
import { WorkBook, utils, write } from 'xlsx';
import { BulkExcelUploadComponent } from '../bulk-excel-upload/bulk-excel-upload.component';

@Component({
  selector: 'app-leave-policy-configuration',
  templateUrl: './leave-policy-configuration.component.html',
  styleUrls: ['./leave-policy-configuration.component.scss'],
})
export class LeavePolicyConfigurationComponent implements OnInit {
  selectedYear: number;
  years: number[] = [];
  searchValue: string;
  allUsers: User[];
  filteredUsers: Observable<User[]>;
  userControl: FormControl = new FormControl();
  selectedUser: any;
  leavebalanceData: any;
  isEnabled: any;
  typeOfLeaveList: string[] = Object.keys(TypeOfLeave);
  public finalExcelFile: Blob;
  careerManagerIdName = {};

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private leaveBalanceService: LeaveBalanceService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year >= this.selectedYear-10; year--) {
      this.years.push(year);
    }

    this.userService.getAllUserForSearchEmployee().subscribe((res) => {
      this.allUsers = res.body;
      this.filteredUsers = this.userControl.valueChanges.pipe(
        startWith(''),
        map((value) => this.filterUsers(value))
      );
    });
  }

  filterUsers(value: string): User[] {
    const filterValue = value.toLowerCase().trim();
    if (this.selectedUser) {
      return this.allUsers.filter(
        (user) =>
          user.firstName.toLowerCase().includes(filterValue) &&
          user !== this.selectedUser
      );
    } else {
      return this.allUsers;
    }
  }

  selectUser(user: any) {
    if (user) {
      this.selectedUser = user;

      this.getLeaveBalance();
      this.userControl.setValue('');
    } else {
      this.selectedUser = null;
      this.userControl.setValue('');
      this.leavebalanceData = {};
      this.isEnabled = {};
      return;
    }
  }

  getLeaveBalance() {
    this.leavebalanceData = {};
    this.isEnabled = {};
    for (const leaveType in this.typeOfLeaveList) {
      if (this.typeOfLeaveList.hasOwnProperty(leaveType)) {
        this.isEnabled[leaveType] = false;
      }
    }

    if (this.selectedUser && this.selectedYear) {
      this.leaveBalanceService
        .getLeaveBalanceByIdAndYear(this.selectedUser.userID, this.selectedYear)
        .subscribe(
          (res) => {
            if (res && res.body && res.body.balanceLeave) {
              this.leavebalanceData = res.body.balanceLeave;
              this.isEnabled = res.body.isEnabled;
            }
            this.changeDetectorRef.detectChanges();
          },
          (error) => {}
        );
    } else {
      this.leavebalanceData = {};
      this.isEnabled = {};
      this.changeDetectorRef.detectChanges();
    }
  }

  onYearChange(year) {
    this.selectedYear = year;
    this.getLeaveBalance();
  }

  bulkConfigureLeave() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '50%';
    dialogConfig.data = {
      year: this.selectedYear,
    };
    const dialogRef = this.dialog.open(
      BulkConfigureLeaveComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      this.getLeaveBalance();
    });
  }

  configureEmployeeLeave() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '50%';
    dialogConfig.data = {
      userId: this.selectedUser.userID, //////will change as
      year: this.selectedYear,
    };
    const dialogRef = this.dialog.open(
      ConfigureEmployeeLeaveDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      this.getLeaveBalance();
    });
  }

  enableLeave(leaveType, id) {
    this.leaveBalanceService
      .isLeaveBalanceEnableOrDisable(leaveType, id, true, this.selectedYear)
      .subscribe(
        (res) => {
          this.getLeaveBalance();
        },
        (e) => {
          this.getLeaveBalance();
        }
      );
  }

  disableLeave(leaveType, id) {
    this.leaveBalanceService
      .isLeaveBalanceEnableOrDisable(leaveType, id, false, this.selectedYear)
      .subscribe(
        (res) => {
          this.getLeaveBalance();
        },
        (e) => {
          this.getLeaveBalance();
        }
      );
  }

  detailLeave(leaveType, id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '70%';
    dialogConfig.data = {
      leaveType: leaveType,
      id: id,
    };
    const dialogRef = this.dialog.open(DetailLeaveComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {});
  }

  file() {
    const LeaveBalanceExcelList: any[] = [];
    const observables: Observable<any>[] = [];

    let serialNumber = 1; // Initialize the serial number
    const fullName =
      this.selectedUser.firstName + ' ' + this.selectedUser.lastName;
    const employeeId = this.selectedUser.employeeId;
    const jobTitle = this.selectedUser.jobTitle;
    const department = this.selectedUser.department;
    const paidLeave = this.leavebalanceData.PAID_LEAVE;
    const paternityLeave = this.leavebalanceData.PATERNITY_LEAVE;
    const unplannedLeave = this.leavebalanceData.UNPLANNED_LEAVE;
    const compOff = this.leavebalanceData.COMP_OFF;
    const contigencyLeave = this.leavebalanceData.CONTINGENCY_LEAVE;
    const restrictedHolidaysLeaves = this.leavebalanceData.RESTRICTED_HOLIDAY;
    const maternityLeave = this.leavebalanceData.MATERNITY_LEAVE;
    const specialLeave = this.leavebalanceData.SPECIAL_LEAVE;

    if (this.selectedUser.careerManagerId !== null) {
      const observable = this.userService
        .getUserNameByID(this.selectedUser.careerManagerId)
        .pipe(
          map((res) => {
            this.careerManagerIdName[this.selectedUser.careerManagerId] = res;
            const careerManagerName =
              this.careerManagerIdName[this.selectedUser.careerManagerId];
            return careerManagerName;
          })
        );
      observables.push(observable);

      observable.subscribe((careerManagerName) => {
        const rowData = {
          'Sr. No.': serialNumber++,
          'Full Name': fullName,
          'Employee ID': employeeId,
          Title: jobTitle,
          Capability: department,
          'Career Manager': careerManagerName,
          'Paid Leave': paidLeave | 0,
          'Unplanned Leave': unplannedLeave | 0,
          'Comp-Off Leave': compOff | 0,
          'Contingency Leave': contigencyLeave | 0,
          'Restricted Holiday': restrictedHolidaysLeaves | 0,
          'Maternity Leave': maternityLeave | 0,
          Paternity: paternityLeave | 0,
        };

        LeaveBalanceExcelList.push(rowData);
      });
    }

    forkJoin(observables).subscribe(() => {
      const holidaysDataForExcel: any[] = [];
      this.finalExcelFile = this.generateExcelFile(LeaveBalanceExcelList);
      this.saveAsExcelFile(
        this.finalExcelFile,
        `${this.selectedUser.firstName}_${this.selectedUser.lastName}_Leave_Balance.xlsx`
      );
    });
  }

  generateExcelFile(formArrayData: any[]): Blob {
    const worksheet = utils.json_to_sheet(formArrayData);
    const workbook: WorkBook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Template');
    const excelBuffer: any = write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    return new Blob([excelBuffer], { type: 'application/octet-stream' });
  }

  saveAsExcelFile(buffer: any, filename: string) {
    // to download excel
    const data = new Blob([buffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(url);
  }

  bulkExcelUpload() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    dialogConfig.height = '70%';
    dialogConfig.data = {
      year: this.selectedYear,
    };
    const dialogRef = this.dialog.open(BulkExcelUploadComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {});
  }

  exportEmployeeReport() {}
}
