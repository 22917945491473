import { Component, OnInit } from '@angular/core';
import { ClientHolidayService } from 'src/app/services/clientHoliday/client-holiday.service';
import { ClientService } from 'src/app/services/client/client.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RefreshService } from 'src/app/services/refreshService/refresh.service';
import { AddHolidayService } from 'src/app/services/addHoliday/add-holiday.service';
import { AddholidaysComponent } from '../addholidays/addholidays.component';
import { UserDetailsService } from 'src/app/services/user-details.service';
import {
  AuthoritiesConstants,
  Departments,
  JobTitles,
  ProbationPeriod,
  Gender,
  GeoLocation,
  EmployementType,
  RelationshipStatus,
  HolidayType,
} from 'src/app/services/user/user-enum';
import { UserService } from 'src/app/services/user/user.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-bluealtair-holidays',
  templateUrl: './bluealtair-holidays.component.html',
  styleUrls: ['./bluealtair-holidays.component.scss']
})
export class BluealtairHolidaysComponent implements OnInit {

  years: number[] = [];
  LeaveType = [];
  allHolidays = [];
  page: number = 1;
  selectedYear: number = new Date().getFullYear();
  selectedLeaveType: string = "ALL";
  totalHoliday: any = [];
  employmentTypes: any = [];
  geoLocations: any = [];
  loggedInUser: any
  holidayType = [];
  locations: string[];
  searchTerm: string = '';
  newholidayDetails:FormGroup

  constructor(public clientHolidaysService: ClientHolidayService,
    public client: ClientService,
    public userService: UserService,
    private userDetails: UserDetailsService,
    private holidayService: AddHolidayService,
    private refreshService: RefreshService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    public addHoliday: AddHolidayService) {
    this.refreshService.refresh$.subscribe((componentName) => {
      if (componentName === 'blue-altair-holiday') {
        this.getAllHolidays();
      }
    });
  }

  ngOnInit(): void {
    this.processEnumValuesToArray();
    this.loggedInUser = this.userDetails.getUserDetails();
    this.getAllHolidays();
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year >= this.selectedYear - 10; year--) {
      this.years.push(year);
    }
    this.userService.fetchBaseLocations(this.loggedInUser.geoLocation.toLocaleUpperCase()).subscribe(res => {

      this.locations = res.body;
    })
    
    this.initializeForm();
  }

  initializeForm(): void {
    this.newholidayDetails = this.fb.group({
      geoLocation: [this.loggedInUser.geoLocation],
      employmentType: [this.loggedInUser.employmentType],
      baseLocation: [this.loggedInUser.baseLocation],
      year: [this.selectedYear],
    });
  }

  processEnumValuesToArray() {
    this.geoLocations = Object.keys(GeoLocation).filter(key => isNaN(Number(GeoLocation[key])));
    this.holidayType = Object.keys(HolidayType).filter(key => isNaN(Number(HolidayType[key])));
    this.employmentTypes = Object.keys(EmployementType).filter(key => isNaN(Number(EmployementType[key])));
  }


  addNewHoliday() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '100%';
    dialogConfig.height = '70%';
    const dialogRef = this.dialog.open(AddholidaysComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {

    });
  }

  getDayName(dayNumber: number): string {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return daysOfWeek[dayNumber];
  }


  getAllHolidays() {
    this.allHolidays = [];
    this.holidayService.getHoliday(this.selectedYear, this.loggedInUser.geoLocation, this.loggedInUser.employmentType, this.loggedInUser.baseLocation).subscribe((res) => {
      for (let i = 0; i < res.body.length; i++) {
        const holidayDate = new Date(res.body[i].date);
      const dayOfWeek = holidayDate.getDay(); 
      const dayName = this.getDayName(dayOfWeek);
        const holidayDetails = {
          date: res.body[i].date,
          leaveDescription: res.body[i].leaveDescription,
          calendarLeaveType: res.body[i].calendarLeaveType,
          employmentType: res.body[i].employmentType,
          geoLocation: res.body[i].geoLocation,
          baseLocation: res.body[i].baseLocation,
          dayName:dayName
        };
        this.allHolidays.push(holidayDetails);
      }

    });
  }

  onGeoLocationTypeChange(event) {
    const selectedGeoLocation = event.value;
    this.loggedInUser.geoLocation = selectedGeoLocation;
    this.getAllHolidays();
    this.userService.fetchBaseLocations(selectedGeoLocation.toLocaleUpperCase()).subscribe(res => {

      this.locations = res.body;
    });

  }
  onHolidayTypeChange(event): void {
    const selectedHolidayType = event.value;
    if (selectedHolidayType) {
      this.holidayService.getHoliday(this.selectedYear, this.loggedInUser.geoLocation, this.loggedInUser.employmentType, this.loggedInUser.baseLocation)
        .subscribe((res) => {
          this.allHolidays = res.body.filter(row => row.calendarLeaveType === selectedHolidayType);
        });
    }
  }

  filterHolidays() {
    if (!this.searchTerm.trim()) {
      this.getAllHolidays();
      return;
    }
    this.allHolidays = this.allHolidays.filter(holiday =>
      holiday.leaveDescription.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  onEmploymentTypeChange(event) {
    const selectedEmploymentType = event.value;
    this.loggedInUser.employmentType = selectedEmploymentType;
    this.getAllHolidays();
  }
  onBaseLocationTypeChange(event) {
    const selectedBaseLocation = event.value;
    this.loggedInUser.baseLocation = selectedBaseLocation;
    this.getAllHolidays();
  }

  onYearChange(event) {
    const selectedYear = event.value;
    this.selectedYear = selectedYear;
    this.getAllHolidays();
  }





}
