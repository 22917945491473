<div class="main-error-container">
  <app-error-header></app-error-header>
  <div class="error-container">
    <div class="error-logo-container">
      <div class="error-logo">
        <img src="assets/images/access-denied.png"/>
      </div>
    </div>
    <div class="error-details-container">
      <div class="error-details">
        <div class="error-name">Access Denied: Error 403: Forbidden</div>
        <div class="error-bar"></div>
        <div class="error-desc">
          Access to this resource is forbidden. You may not have the necessary
          permissions to view this page.
        </div>
        <a href="/login"><button class="error-white-btn">Back to Log in</button></a>
      </div>
    </div>
  </div>
</div>
