import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user/user.service';
import { AddNewEmployeeCardComponent } from '../add-new-employee-card/add-new-employee-card.component';
import { Router } from '@angular/router';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { ToastrService } from 'ngx-toastr';
import { Departments, JobTitles } from 'src/app/services/user/user-enum';
import { DeactivateEmployeeComponent } from '../../employee/employee-activation/deactivate-employee/deactivate-employee.component';
import { TooltipPosition } from '@angular/material/tooltip';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-new-employees',
  templateUrl: './new-employees.component.html',
  styleUrls: ['./new-employees.component.scss']
})
export class NewEmployeesComponent implements OnInit {
  page: number = 1;
  newUsers: UserDTO[] = [];
  selectedUsers: UserDTO[] = [];
  searchTerm: any;
  department = Departments;
  jobTitle = JobTitles;
  isChecked: boolean = false;
  filteredUsers: UserDTO[];
  departments = [];
  jobTitles = [];
  isSpinner = true;
  capabilitySearch: Array<string> = [];;
  jobTitleSearch: Array<string> = [];;
  currentSortName: { column: string, direction: 'asc' | 'desc' } = { column: 'name', direction: 'asc' };
  currentSortEmployeeId: { column: string, direction: 'asc' | 'desc' } = { column: 'employeeId', direction: 'asc' };
  currentSortJobTitle: { column: string, direction: 'asc' | 'desc' } = { column: 'jobTitle', direction: 'asc' };
  currentSortDepartment: { column: string, direction: 'asc' | 'desc' } = { column: 'department', direction: 'asc' };
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);

  constructor(private userService: UserService,
    private dialog: MatDialog,
    private router: Router,
    private toaster: ToastrService

  ) { }

  ngOnInit(): void {
    this.processEnumValuesToArray()
    this.getNewEmployees()
  }

  getNewEmployees() {
    this.isSpinner = true;
    this.userService.getNewEmmployee().subscribe(res => {
      this.filteredUsers = res;
      this.newUsers = res;
      this.isSpinner = false;
      this.filteredUsers.forEach(element => {
        if (element.userImageThumbnail !== '') {
          element.userImageThumbnail =
            'data:image/png;base64,' + element.userImageThumbnail;
        }
      });
    }, error => {
      this.isSpinner = false;
    })
  }
  addNewEmployee() {
    let empData: UserDTO = null;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = 'auto';
    dialogConfig.height = '75%';
    dialogConfig.data = [empData, 'new-user'];
    const dialogRef = this.dialog.open(AddNewEmployeeCardComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.clearSelection();
      this.getNewEmployees();
      this.searchTerm = '';
    });
  }
  editEmployeeDialog(empData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = 'auto';
    dialogConfig.height = '75%';
    dialogConfig.data = [empData, 'new-user'];

    const dialogRef = this.dialog.open(AddNewEmployeeCardComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.searchTerm = '';
      this.clearSelection();
      this.getNewEmployees();
    });
  }
  activateEmployee(empData) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '450px';
    dialogConfig.height = '200px';
    dialogConfig.data = [empData, "Activate"];
    dialogConfig.panelClass = 'activate-employee';
    const dialogRef = this.dialog.open(
      DeactivateEmployeeComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      this.getNewEmployees();
    });
  }
  viewEmployee(empDetail) {
    this.router.navigate([`/employee/employee-profile/${empDetail.id}`]);
  }
  applySearch() {
    this.capabilitySearch = [];
    this.jobTitleSearch = [];
    this.filteredUsers = this.newUsers;
    let filteredUsers = this.newUsers.filter(user => {
      const nameMatch = user.name.toLowerCase().includes(this.searchTerm.toLowerCase());
      return nameMatch
    });

    filteredUsers.sort((a, b) => {
      const aNameMatch = a.name.toLowerCase().includes(this.searchTerm.toLowerCase());
      const bNameMatch = b.name.toLowerCase().includes(this.searchTerm.toLowerCase());

      if (aNameMatch && !bNameMatch) {
        return -1;
      } else if (!aNameMatch && bNameMatch) {
        return 1;
      } else {
        return 0;
      }
    });
    this.filteredUsers = filteredUsers;
  }

  deleteNewUser(empData) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '450px';
    dialogConfig.height = '200px';
    dialogConfig.data = [empData, "Delete"];
    dialogConfig.panelClass = 'activate-employee';
    const dialogRef = this.dialog.open(
      DeactivateEmployeeComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      this.getNewEmployees()
    });
  }
  processEnumValuesToArray() {
    for (const jobTitle of Object.values(JobTitles)) {
      if (isNaN(Number(jobTitle))) {
        this.jobTitles.push(jobTitle);
      }
    }

    for (const department of Object.values(Departments)) {
      if (isNaN(Number(department))) {
        this.departments.push(department);
      }
    }
  }
  getDepartmentKeyByValue(value: string): string | undefined {
    return Object.keys(Departments).find(key => Departments[key] === value);
  }
  getJobTitleKeyByValue(value: string): string | undefined {
    return Object.keys(JobTitles).find(key => JobTitles[key] === value);
  }
  applySearchForJobTitleAndDepartement() {
    let filteredUsers = this.newUsers.filter(user => {
      const departmentKeys = this.capabilitySearch.map(capability => this.getDepartmentKeyByValue(capability));
      const jobTitleKeys = this.jobTitleSearch.map(jobTitle => this.getJobTitleKeyByValue(jobTitle));
      const departmentMatch = departmentKeys.length === 0 || departmentKeys.includes(user.department);
      const jobTitleMatch = jobTitleKeys.length === 0 || jobTitleKeys.includes(user.jobTitle);
      return departmentMatch && jobTitleMatch;
    });
    this.filteredUsers = filteredUsers;
  }
  sortTable(column: string): void {
    switch (column) {
      case 'name':
      case 'employeeId':
      case 'jobTitle':
      case 'department':
      case 'carrierManagerName':
      case 'email':
        this.sortByColumn(column);
        break;
      // Add more cases for other columns if needed
    }
  }

  private sortByColumn(column: string): void {
    const currentSort = this.getCurrentSortForColumn(column);

    if (currentSort.column === column) {
      currentSort.direction = currentSort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      currentSort.column = column;
      currentSort.direction = 'asc';
    }

    this.updateCurrentSortForColumn(column, currentSort);

    this.filteredUsers.sort((a, b) => {
      const aValue = a[column] ? a[column].toLowerCase() : null;
      const bValue = b[column] ? b[column].toLowerCase() : null;

      if (aValue === null && bValue === null) {
        return 0; // Both values are null, maintain current order
      } else if (aValue === null) {
        return 1; // Null should be considered greater, place at the end
      } else if (bValue === null) {
        return -1; // Null should be considered greater, place at the end
      } else {
        return currentSort.direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
    });
  }

  // The rest of the methods remain the same...

  private getCurrentSortForColumn(column: string): { column: string, direction: 'asc' | 'desc' } {
    switch (column) {
      case 'name':
        return this.currentSortName;
      case 'employeeId':
        return this.currentSortEmployeeId;
      case 'jobTitle':
        return this.currentSortJobTitle;
      case 'department':
        return this.currentSortDepartment;
      // Add more cases for other columns if needed
      default:
        return { column: '', direction: 'asc' }; // Default case
    }
  }

  private updateCurrentSortForColumn(column: string, currentSort: { column: string, direction: 'asc' | 'desc' }): void {
    switch (column) {
      case 'name':
        this.currentSortName = currentSort;
        break;
      case 'employeeId':
        this.currentSortEmployeeId = currentSort;
        break;
      case 'jobTitle':
        this.currentSortJobTitle = currentSort;
        break;
      case 'department':
        this.currentSortDepartment = currentSort;
        break;
    }
  }

  toggleSelectUser(user: UserDTO): void {
    const index = this.selectedUsers.indexOf(user);
    if (index === -1) {
      this.selectedUsers.push(user);
    } else {
      this.selectedUsers.splice(index, 1);
    }
    if (this.selectedUsers.length == this.filteredUsers.length) {
      this.isChecked = true;
    }
    else if (this.selectedUsers.length == 0) {
      this.isChecked = false;
    }
  }

  isSelected(user: UserDTO): boolean {
    return this.selectedUsers.includes(user);
  }

  toggleSelectAll(): void {
    if (this.isChecked) {
      this.selectedUsers = [];
      this.isChecked = false;
    }
    else {
      this.selectedUsers = [...this.filteredUsers];
      this.isChecked = true;
    }
  }

  activateSelectedUsers() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '450px';
    dialogConfig.height = '200px';
    const selectedIds = this.selectedUsers.map(user => user.id);
    dialogConfig.data = [selectedIds, 'BulkActivate'];
    dialogConfig.panelClass = 'deactivate-employee';
    if (selectedIds.length > 0) {
      const dialogRef = this.dialog.open(
        DeactivateEmployeeComponent,
        dialogConfig
      );
      dialogRef.afterClosed().subscribe((result) => {
        this.clearSelection();
        this.getNewEmployees();
      });
    }
    else {
      this.toaster.warning("No user selected");
    }
  }

  deleteSelectedUsers() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '450px';
    dialogConfig.height = '200px';
    const selectedIds = this.selectedUsers.map(user => user.id);
    dialogConfig.data = [selectedIds, 'BulkDelete'];
    dialogConfig.panelClass = 'deactivate-employee';
    if (selectedIds.length > 0) {
      const dialogRef = this.dialog.open(
        DeactivateEmployeeComponent,
        dialogConfig
      );
      dialogRef.afterClosed().subscribe((result) => {
        this.clearSelection();
        this.getNewEmployees();
      });
    }
    else {
      this.toaster.warning("No user selected");
    }

  }
  ifOverflow(e){
    return e.scrollWidth > e.clientWidth;
  }
  clearSelection(): void {
    this.selectedUsers = [];
    this.capabilitySearch = [];
    this.jobTitleSearch = [];
    this.isChecked = false;
  }
  getEnumValueByKey(type, key: string): any {
    return type[key];

  }
}
