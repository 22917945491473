import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { TaskService } from '../../services/task.service';
import * as TaskActions from '../actions/task.action';

@Injectable()
export class TaskEffects {
  loadTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.TaskActionTypes.LoadTasks),
      mergeMap(() =>
        
        this.taskService.getAllByUserId(this.userDetailService.getUserDetails().id).pipe(
          map((tasks) => {
            return TaskActions.loadTasksSuccess(tasks);
          }),
          catchError((err) => {
            if (err && err.error && err.error.message) {
              return of(
                TaskActions.loadTasksError(`Error: ${err.error.message}`)
              );
            } else if (err && err.message) {
              return of(TaskActions.loadTasksError(`Error: ${err.message}`));
            } else {
              return of(
                TaskActions.loadTasksError(`Error: ${JSON.stringify(err)}`)
              );
            }
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private taskService: TaskService,private  userDetailService: UserDetailsService) {
    
  }
}
