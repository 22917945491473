import { Component, HostListener, OnInit } from '@angular/core';
import { EmployeeReviewService } from 'src/app/services/employeeReview/employee-review.service';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { OneOnOneMeetingService } from 'src/app/services/oneOnOneMeeting/one-on-one-meeting.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { Router } from '@angular/router';
import { NewFeedbackGroupService } from 'src/app/services/new-feedback-group.service';
import { UserGoalService } from 'src/app/services/usergoal/user-goal.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProfileDetailComponent } from '../profile-detail/profile-detail.component';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-direct-reportees-cm',
  templateUrl: './direct-reportees-cm.component.html',
  styleUrls: ['./direct-reportees-cm.component.scss'],
  animations: [
    trigger('slideUp', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('600ms ease-out', style({ transform: 'translateY(0)', opacity: 1 }))
      ]),
    ])
  ]
})
export class DirectReporteesCMComponent implements OnInit {
  public employeeReviewCompleted: boolean = false;
  userdto;
  abc = '64eefa0d235ce86a984431aa';
  isMyrepoteeVisible = true;
  probationStatus: boolean = false;
  p: number = 1;
  searchText: string;
  public getScreenWidth: any;
  public getScreenHeight: any;
  selectedYear: number;
  nodata: Boolean = true;
  selected;
  userFeedbackDTO: any;
  excelArray = [];
  reportingManager: any;
  reviewerName: any;
  employee: any;
  hide: boolean = true;
  years: number[] = [];
  mobileview: boolean = false;
  constructor(
    public userService: UserService,
    public userDetailService: UserDetailsService,
    public initialAvatarService: InitialAvatarService,
    public employeeReviewService: EmployeeReviewService,
    public oneOnOneMeetingService: OneOnOneMeetingService,
    public feedbackService: FeedbackService,
    public userDetailsService: UserDetailsService,
    public feedbackgroupservice: NewFeedbackGroupService,
    public userGoalService: UserGoalService,
    private router: Router,
    private feedbackgroup: NewFeedbackGroupService,
    public dialogue: MatDialog
  ) {
    this.feedbackService.listen().subscribe((m: any) => {
      if (m == 'Reopened-feedback') {
        this.getMyReportee();
      }
    });
    this.userService.myReporteeTabVisibility.subscribe((res) => {
      this.isMyrepoteeVisible = res;
    });
  }
  ngOnInit(): void {
    this.selected = new Date().getFullYear();
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year >= 2022; year--) {
      this.years.push(year);
    }

    this.getMyReportee();
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth > 800) {
      this.mobileview = false;
    }
    if (this.getScreenWidth < 800) {
      this.mobileview = true;
    }
  }
  getMyReportee() {
    this.feedbackgroupservice
      .getdirectreporteebyyear(this.selected)
      .subscribe(async (res) => {
        if (res.body.length != 0) {
          this.nodata = false;
        }
        this.userdto = res.body;
        for (let i = 0; i < res.body.length; i++) {
          let a: any = await this.getNameFromID(res.body[i].userId);
          res.body[i].jobTitle = a.jobTitle;
        }
      });
  }
  onViewReportee(id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '100%';
    dialogConfig.height = '95%';
    dialogConfig.maxWidth = '95%';
    dialogConfig.data = {
      userId: id,
      viewer: 'CM'
    };
    const dialogRef = this.dialogue.open(ProfileDetailComponent, dialogConfig);
  }
  processDesignation(user) {
    let designation;
    if (user && user.firstName) return (designation = user.jobTitle);
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth > 800) {
      this.mobileview = false;
    }
    if (this.getScreenWidth < 800) {
      this.mobileview = true;
    }
  }
  loadForm(event) {
    this.userGoalService.setCurrentYear(event.value);
    this.selectedYear = event.value;
    this.feedbackgroupservice
      .getdirectreporteebyyear(event.value)
      .subscribe((res) => {
        if (res.body.length != 0) {
          this.nodata = false;
        }
        this.userdto = null;
        this.userdto = res.body;
      });
  }
  getQuestionsRatingAdhoc(directReporteeId, event) {
    event.stopPropagation();
    this.excelArray = [];
    this.feedbackgroup
      .getfeedbackbyuserandyear(directReporteeId, this.selected)
      .subscribe(async (res) => {
        this.userFeedbackDTO = res.body;
        let first_row_of_excel = [];
        first_row_of_excel.push('Sr.No.');
        first_row_of_excel.push('Employee ID');
        first_row_of_excel.push('Employee Name');
        first_row_of_excel.push('Job Title');
        first_row_of_excel.push('Career Manager');
        first_row_of_excel.push('Capability');
        first_row_of_excel.push('Reviewer Name');
        first_row_of_excel.push('Reviewer Type');
        first_row_of_excel.push('Feedback Title');
        first_row_of_excel.push('Questions');
        first_row_of_excel.push('Rating');
        first_row_of_excel.push('Comments');
        first_row_of_excel.push('Additional Feedback');
        this.excelArray.push(first_row_of_excel);
        let reportingManager = 1;

        for (let i of res.body) {
          if (i.feedbackType == 'ADHOC' || i.feedbackType == 'PROJECT') {
            this.employee = await this.getNameFromID(i.feedbackFor);
            this.reportingManager = await this.getNameFromID(
              this.employee.careerManagerId
            );

            for (let temp1 of i.feedbackFrom) {
              this.reviewerName = await this.getNameFromID(temp1.feedbackFrom);
              var obj = JSON.parse(temp1.submission);
              for (let x of obj) {
                if (x.hasOwnProperty('attributes')) {
                  for (let y of x.attributes) {
                    let excelArrayTemp = [];
                    excelArrayTemp.push(reportingManager++);
                    this.getemailAdhoc(excelArrayTemp, y, temp1);
                  }
                }
              }
            }
          }

          var csvString = '';
          var temp;
          const yearRT = this.selected;
          const fileName = `Questions_Rating_Report_${yearRT}.csv`;

          temp = this.excelArray;

          for (let i = 1; i < temp.length; i++) {
            temp[i][0] = i;
          }
          temp.forEach((RowItem: any, RowIndex: any) => {
            RowItem.forEach((colItem: any, colIndex: any) => {
              csvString += '"' + colItem + '"' + ',';
            });
            csvString += '\r\n';
          });
          csvString = 'data:application/csv, ' + encodeURIComponent(csvString);
          var x = document.createElement('A');
          x.setAttribute('href', csvString);
          x.setAttribute('download', fileName);
          document.body.appendChild(x);
          x.click();
        }
      });
  }
  async getNameFromID(userId) {
    const myPromise = new Promise((resolve, rejects) => {
      this.userService.getUserByID(userId).subscribe((res) => {
        resolve(res.body);
      });
    });

    var abc = await myPromise;
    return abc;
  }
  async getemailAdhoc(excelArrayTemp, y, temp1) {
    let response;
    excelArrayTemp.push(this.employee.employeeId);
    excelArrayTemp.push(this.employee.firstName + ' ' + this.employee.lastName);
    excelArrayTemp.push(this.employee.jobTitle);
    excelArrayTemp.push(this.employee.department);
    excelArrayTemp.push(
      this.reportingManager.firstName + ' ' + this.reportingManager.lastName
    );
    excelArrayTemp.push(
      this.reviewerName.firstName + ' ' + this.reviewerName.lastName
    );
    excelArrayTemp.push(temp1.feedbackRole);
    excelArrayTemp.push(y.label);
    excelArrayTemp.push(y.value);
    excelArrayTemp.push(y.description);
    this.excelArray.push(excelArrayTemp);
  }
  fileAdhoc(id) {
    var csvString = '';
    var temp;
    const yearRT = this.selected;
    const fileName = `Questions_Rating_Report_${yearRT}.csv`;

    temp = this.excelArray;

    for (let i = 1; i < temp.length; i++) {
      temp[i][0] = i;
    }
    temp.forEach((RowItem: any, RowIndex: any) => {
      RowItem.forEach((colItem: any, colIndex: any) => {
        csvString += '"' + colItem + '"' + ',';
      });
      csvString += '\r\n';
    });
    csvString = 'data:application/csv, ' + encodeURIComponent(csvString);
    var x = document.createElement('A');
    x.setAttribute('href', csvString);
    x.setAttribute('download', fileName);
    document.body.appendChild(x);
    x.click();
  }
}