<div class="container">
  <div class="main_header_div">
    <!-- <div class="head">
        <img src="assets/icons/dashboard-icons/wfo-icon.svg" />
        This Feature is Under Construction!
    </div>   -->

    <div class="main-error-container">
      <app-error-header>
        <header>
          <img src="assets/images/under.png" alt="Blue pulse" />
          <div class="logo-name">Blue Pulse</div>
        </header>
      </app-error-header>

      <div class="error-container">
        <div class="error-logo-container">
          <div class="error-logo"><img src="assets/images/under.png" /></div>
        </div>

        <div class="error-details-container">
          <div class="error-details">
            <div class="error-desc">This feature is under construction.</div>

            <button (click)="closeDialog()" class="error-white-btn">
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
