import { FeedbackType } from "../services/user/user-enum";
import { ProbationFeedbackDTO } from "./ProbationFeedbackDTO";
export class ProbationFeedbackGroupDTO {
    id: string;
    createdBy: string;
    isClosed: boolean;
    overallScore: number;
    year: string;
    feedbackFor: string;
    feedbackType: FeedbackType.PROBATION;
    feedbackFrom: Array<ProbationFeedbackDTO>;
    constructor() {
        this.feedbackFrom=[]
    }
  }
  