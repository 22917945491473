<div class="container-fluid">
<div class="main_header_div">
    <div class="head">
      <img src="assets/icons/holidays-icons/holiday_configuration.svg" />
    Select Employee
    </div>
    <img class="close-icon-svg" (click)="closeDialog()" src="assets/icons/dashboard-icons/close-pink-icon.svg" />
  </div>
  <div class="row mt-3">
    <div class="col-md-12 table-1">
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th>
          Employee Name
            </th>
            <th>
         Employee Id
            </th>
            <th>
           Project Name
              </th>
          </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let project of projects">
                <tr *ngFor="let member of project.projectMembers; let i = index">
                    <td>
                        <input type="checkbox" [checked]="member.selected" (change)="toggleSelection(project.projectName, member ,project.clientId, member.projectId)">
                      </td>
                  <td>
                    {{ member.talentName }}
                </td>
                  <td>{{ member.userId }}</td>
                  <td>{{ project.projectName }}</td>
                </tr>
              </ng-container>
        </tbody>
    </table>
    <div class="button-group">
        <button class="blue-button">Back</button>
        <button class="blue-button" (click)=" openPreview()">Next</button>
      </div>
    </div>
  </div>
  </div>
