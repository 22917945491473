<div class="main_header_div">
  <h3 mat-dialog-title>Accept Resignation</h3>
  <div class="child_close_btn">
    <button class="closeButton" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<form [formGroup]="noticePeriodDetails">
  <div class="col">
    <mat-form-field appearance="outline" class="example">
      <textarea
        matInput
        formControlName="noticePeriod"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="7"
        placeholder="Notice Period in days"
      ></textarea>
    </mat-form-field>
  </div>
</form>

<div class="submit_button_main">
  <button class="add_Emp_btn" type="submit" (click)="acceptResignation()">
    <span>Submit</span>
  </button>
</div>
