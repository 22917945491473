<div class="container-fluid mt-2 main" id="content">
  <div class="row">
    <div class="col-md-12 mt-2" class="container-padding">
      <mat-tab-group (selectedTabChange)="onTabChanged($event)">
        <mat-tab>
          <ng-template mat-tab-label> My Goal Sheet </ng-template>
          <div>
            <app-cm-goal-sheet [userid]="userid"></app-cm-goal-sheet>
          </div>
        </mat-tab>
        <mat-tab *ngIf="this.isCareerManager">
          <ng-template mat-tab-label> My Reportees </ng-template>
          <div>
            <app-direct-reportees-cm></app-direct-reportees-cm>
          </div>
        </mat-tab>
        <mat-tab class="mat-tab-label-active" *ngIf="this.hasHrRole">
          <ng-template mat-tab-label class="tab-2"> Firm Wide </ng-template>
          <div class="mat-background">
            <app-calibrated-report></app-calibrated-report>
          </div>
        </mat-tab>
        <mat-tab class="mat-tab-label-active" >
          <ng-template mat-tab-label class="tab-2"> My Summary </ng-template>
          <div class="mat-background">
            <app-user-summary></app-user-summary>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
