import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TooltipPosition } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { PassportDetailsDTO } from 'src/app/dtos/PassportDetailsDTO';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { BlueAltairTravel } from 'src/app/models/bluealtairtravel';
import { PassportDetails } from 'src/app/models/passportdetails';
import { Visa } from 'src/app/models/visa';
import { BluealtairtravelService } from 'src/app/services/blue-altair-travel/bluealtairtravel.service';
import { PassportDetailsService } from 'src/app/services/passport-details/passport-details.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { VisaService } from 'src/app/services/visa/visa.service';
import { PassportDetailsComponent } from './passport-details/passport-details.component';
import { TravelComponent } from './travel/travel.component';
import { Countries } from 'src/app/services/country/country-enum';
import { VisaDetailsComponent } from './visa-details/visa-details.component';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { EventTabClosed, Status } from 'src/app/services/user/user-enum';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-business-travel',
  templateUrl: './business-travel.component.html',
  styleUrls: ['./business-travel.component.scss'],
  animations: [
    trigger('slideUp', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('600ms ease-out', style({ transform: 'translateY(0)', opacity: 1 }))
      ]),
    ])
  ]
})
export class BusinessTravelComponent implements OnInit {
  travelhistorydto: BlueAltairTravel[] = [];
  visadto: Visa[] = [];
  visastatus: Object = {};
  passport = new PassportDetails();
  passportdto: PassportDetailsDTO[] = [];
  passportstatus = {};
  userdto = new UserDTO();
  dataSubscription
  userid: string;
  @Output() dummyfunc = new EventEmitter();
  flag = false;
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  countriesEnum = Countries;
  constructor(
    private userDetailService: UserDetailsService,
    private feedbackService: FeedbackService,
    private dialog: MatDialog,
    private passportservice: PassportDetailsService,
    private visadetailservice: VisaService,
    private travelhistoryservice: BluealtairtravelService,
    private route: ActivatedRoute
  ) {
  }
  ngOnInit(): void {
    this.refreshOnClickOfEmployee();
  }
  refreshOnClickOfEmployee() {
    this.dataSubscription = this.feedbackService.data$.subscribe((data) => {
      this.userid = data;
      this.getUserId();
      this.getExistingPassportDetails();
      this.getExistingVisaDetails();
      this.getExistingTravelHistroy();
    });
  }
  ifOverflow(e) {
    return e.scrollWidth > e.clientWidth;
  }
  getUserId() {
    if (this.userid == null) {
      this.userid = this.route.snapshot.paramMap.get('id');
    }
    if (this.userid == null) {
      this.userid = this.userDetailService.getUserDetails().id;
    }
  }
  getExistingPassportDetails() {
    this.passportservice
      .getpassByUserIdSubscribe(this.userid)
      .subscribe((res) => {
        this.passportdto = this.passportservice.setPassportResponse(res.body);
        for (const r of res.body) {
          if (this.flag == false && res.body.length > 0) {
            this.dummyfunc.emit(25);
            this.flag = true;
          }
          this.passportstatus[r.id] = this.processDate('expiryDate', r);
        }
      });
  }
  getExistingVisaDetails() {
    this.visadetailservice
      .getvisaByUserIdSubscribe(this.userid)
      .subscribe((res) => {
        this.visadto = this.visadetailservice.setVisaResponse(res.body);
        for (const r of res.body) {
          this.visastatus[r.id] = this.processDate('validTo', r);
        }
      });
  }
  getExistingTravelHistroy() {
    this.travelhistoryservice
      .gettravelByUserIdSubscribe(this.userid)
      .subscribe((res) => {
        this.travelhistorydto = this.travelhistoryservice.settravelResponse(
          res.body
        );
      });
  }
  editPassportDetailsDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.height = 'auto';
    dialogConfig.maxHeight = '75vh';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'passport-class';
    dialogConfig.data = this.userid;
    const dialogRef = this.dialog
      .open(PassportDetailsComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res.event == EventTabClosed.Closed) {
          this.getExistingPassportDetails();
        }
      });
  }
  editVisaDetails() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.height = 'auto';
    dialogConfig.maxHeight = '75vh';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'visadetails-class';
    dialogConfig.data = this.userid;
    const dialogRef = this.dialog
      .open(VisaDetailsComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res.event == EventTabClosed.Closed) {
          this.getExistingVisaDetails();
        }
      });
  }
  editBAtravelDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.height = 'auto';
    dialogConfig.maxHeight = '75vh';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'travelba-class';
    dialogConfig.data = this.userid;
    const dialogRef = this.dialog
      .open(TravelComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res.event == EventTabClosed.Closed) {
          this.getExistingTravelHistroy();
        }
      });
  }
  processDate(dateProperty: string, i: any): string {
    const dateValue = new Date(i[dateProperty]);
    const todayDate = new Date();
    return i[dateProperty] && todayDate >= dateValue ? Status.Inactive : Status.Active;
  }
}
