import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AuthoritiesConstants } from './user/user-enum';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {

  public userDetails : any;
  private imageSource = new BehaviorSubject<string>(null);
  image$ = this.imageSource.asObservable();
  constructor() { }

  getUserDetails() {
    return this.userDetails;

  }
  setImage(imageUrl:string ){
    
    if(imageUrl && imageUrl.length>0){
      this.imageSource.next('data:image/png;base64,' + imageUrl);
    }else{
      this.imageSource.next("empty");
    }
  }

  setUserDetails(userDetails) {
    localStorage.setItem(StorageService.USER_INFO, JSON.stringify(userDetails));
    if (userDetails !== undefined && userDetails !== null  && userDetails.userImageThumbnail !== null &&userDetails.userImageThumbnail != ''){
    userDetails.userImageThumbnail =  'data:image/png;base64,' + userDetails.userImageThumbnail

  }
    this.userDetails = userDetails;
  }

  hasRole(roles: string[]) {
    if (this.userDetails != undefined) {
      let authorities: string[] = this.userDetails.authorities;
      for (let index = 0; index < roles.length; index++) {
        if (authorities.includes(roles[index])) {
          return true;
        }
      }
    }
    return false;
  }
  setUserAbout(about) {
    this.userDetails.about = about;
    localStorage.setItem(StorageService.USER_INFO, JSON.stringify(this.userDetails));
  }

  setUserProfile(userDto){
    this.userDetails.firstName = userDto.firstName;
    this.userDetails.lastName = userDto.lastName;
    this.userDetails.mobileNumber = userDto.mobileNumber;
    localStorage.setItem(StorageService.USER_INFO, JSON.stringify(this.userDetails));
  }
}
