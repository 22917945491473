<div class="container-fluid">
  <div class="main_header_div">
    <div class="child">
      <h1 class="header_name">Feedback Review Meeting Notes</h1>
    </div>
    <div class="child_close_btn">
      <button class="closeButton" (click)="closeDialog()">
        <img class="close-icon-svg" 
            src="assets/icons/dashboard-icons/close-pink-icon.svg" />
      </button>
    </div>
  </div>
  <hr />
  <div [innerHTML]="feedbackClosureNotes"></div>
</div>
