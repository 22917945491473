<div class="row ml-4 topRow">
  <!-- ##commented_BackButton -->
  <figure (click)="onBackButtonClick()">
    <mat-icon><img class="upload_img" src="assets/back.png" alt="" /></mat-icon>
    <label class="upload_text">Back</label>
  </figure>
</div>
<div class="col-md-12" *ngIf="this.mobileview == false">
  <div class="content">
    <div class="mainContent">
      <div class="row"></div>
      <div class="row" fxLayout="row wrap" fxLayout.xs="column">
        <mat-card
          class="matcardprojectdetail"
          fxFlex.lt-sm="0 1 calc(33.3% - 32px)"
        >
          <mat-card-content>
            <div class="row">
              <div class="col coloums">
                <h5 class="client-common">Project Name</h5>
                <h5 class="client-name">{{ projectData.projectName }}</h5>
              </div>
              <div class="col coloums">
                <h5 class="client-common">Client Name</h5>
                <p class="client-name">{{ this.clientName }}</p>
              </div>
              <div class="col coloums">
                <h5 class="client-common">Start Date</h5>
                <p class="client-name">
                  {{ projectData.startDate | date : "dd-MM-yyyy" }}
                </p>
              </div>
              <div class="col coloums">
                <h5 class="client-common">End Date</h5>
                <p class="client-name">
                  {{ projectData.endDate | date : "dd-MM-yyyy" }}
                </p>
              </div>
              <div class="col coloums">
                <h5 class="client-common">Project Category</h5>
                <p class="client-name">
                  {{
                    projectData.projectCategory | underScoreToSpace | titlecase
                  }}
                </p>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="row">
        <mat-card
          class="matcardprojectdetail"
          fxFlex="100%"
          fxFlex.lt-sm="0 1 calc(33.3% - 32px)"
          *ngFor="let talent of this.talentData; let i = index"
        >
          <mat-card-content>
            <div class="row">
              <img
                *ngIf="this.talentImages[talent.userId] !== ''"
                [(src)]="this.talentImages[talent.userId]"
                class="img-container"
              />
              <img
                *ngIf="this.talentImages[talent.userId] == ''"
                src="assets/icons/default-avatar.jpg"
                class="img-container"
              />
              <div class="col ml-4">
                <div class="row">
                  <h5 class="client-name">{{ talent.talentName }}</h5>
                </div>
                <div class="row">
                  <h5 class="client-common">
                    {{ this.userIdName[talent.userId] }}
                  </h5>
                </div>
              </div>
              <div class="col">
                <p class="client-name">
                  {{ talent.role | underScoreToSpace | titlecase }}
                </p>
              </div>
              <div class="col"></div>
              <div class="col coloums">
                <h5 class="client-common">Allocation</h5>
                <p class="allocation">{{ talent.allocationTime }} %</p>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="this.mobileview == true">
  <div class="col-11 content">
    <div class="mainContent">
      <div class="row">
        <div class="col">
          <button
            type="button "
            class="btn btn-default mt-4"
            (click)="addTalent()"
          >
            Add New Talent
          </button>
        </div>
      </div>
      <div class="row" fxLayout="row wrap" fxLayout.xs="column">
        <mat-card
          class="matcardprojectdetail"
          fxFlex="100%"
          fxFlex.lt-md="0 1 calc(50% - 32px)"
          fxFlex.lt-sm="0 1 calc(33.3% - 32px)"
        >
          <mat-card-content>
            <div class="row">
              <div class="col coloums">
                <h5 class="client-common">Project Name</h5>
              </div>
              <div class="col">
                <p>{{ projectData.projectName }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col coloums">
                <h5 class="client-common">Client Name</h5>
              </div>
              <div class="col">
                <p>{{ this.clientName }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col coloums">
                <h5 class="client-common">Start Date</h5>
              </div>
              <div class="col">
                <p>{{ projectData.startDate | date : "dd-MM-yyyy" }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col coloums">
                <h5 class="client-common">End Date</h5>
              </div>
              <div class="col">
                <p>{{ projectData.endDate | date : "dd-MM-yyyy" }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col coloums">
                <h5 class="client-common">Project Category</h5>
              </div>
              <div class="col">
                <p>
                  {{
                    projectData.projectCategory | underScoreToSpace | titlecase
                  }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label
                  class="count mt-4"
                  (click)="EditProjectDialog(projectData)"
                  >Edit</label
                >
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="row" fxLayout="row wrap" fxLayout.xs="column">
        <mat-card
          class="matcardprojectdetail"
          fxFlex="100%"
          fxFlex.lt-md="0 1 calc(50% - 32px)"
          fxFlex.lt-sm="0 1 calc(33.3% - 32px)"
          *ngFor="let talent of this.talentData"
        >
          <mat-card-content>
            <div class="row">
              <div class="col">
                <img
                  *ngIf="this.talentImages[talent.userId] == ''"
                  src="assets/icons/default-avatar.jpg"
                  class="img-container"
                />

                <img
                  *ngIf="this.talentImages[talent.userId] !== ''"
                  [(src)]="this.talentImages[talent.userId]"
                  class="img-container"
                />
              </div>
            </div>
            <div class="row">
              <div class="col coloums">
                <h5 class="client-name">{{ talent.talentName }}</h5>
              </div>
              <div class="col">
                <h5 class="client-common">
                  {{ this.userIdName[talent.userId] }}
                </h5>
              </div>
            </div>
            <div class="row">
              <div class="col coloums">
                <h5 class="client-common">Role</h5>
              </div>
              <div class="col">
                <p>{{ talent.role | underScoreToSpace | titlecase }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col coloums">
                <h5 class="client-common">Allocation</h5>
              </div>
              <div class="col">
                <p>{{ talent.allocationTime }}</p>
              </div>
            </div>
            <div class="row count-col">
              <div class="col">
                <p class="count mt-4" (click)="onEdit(talent)">Edit</p>
              </div>
              <div class="col" *ngIf="flag">
                <label
                  class="release"
                  (click)="release(talent, projectData.endDate)"
                  >Release</label
                >
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
