<div class="main-div">
  <div class="goalType">
    <label>{{parentData.name}}</label>
  </div>
  <div *ngFor="let item of parentData.attributes;let i=index" class="form-group">
    <div *ngIf="item.type=='text'">
      <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
      <input type="text" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}"
        [disabled]="disableEdit" [(ngModel)]="item.value" [value]="item.value" [required]="item.required"
        [ngModelOptions]="{standalone: true}" autocomplete="off" pattern="item.regex">
        
      </div>
     
      <div *ngIf="item.type=='textarea'">
        <label>{{item.label}}</label> <label *ngIf="item.Required" class="text-danger">*</label>
        <textarea class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" [(ngModel)]="item.value"
          [ngModelOptions]="{standalone: true}" autocomplete="off" [disabled]="disableEdit" pattern="item.regex" required>
        </textarea>
      </div>
      
    <div *ngIf="item.type=='paragraph'">
      <label class="form-text">{{item.placeholder}}</label>
    </div>
    <div *ngIf="item.type=='autocomplete'">
      <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
      <select class="form-control" id="{{item.name}}" [(ngModel)]="item.value" [ngModelOptions]="{standalone: true}"
        [disabled]="disableEdit">
        <option *ngFor="let v of item.values" [value]="v.value">{{v.label}}</option>
      </select>
    </div>
    <div *ngIf="item.type=='checkbox'">
      <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
      <div *ngFor="let v of item.values" class="inline-form-group">
        <label class="cust-check-bx">
          <input type="checkbox" [checked]="v.selected" [value]="v.value" [disabled]="disableEdit" name="{{item.name}}"
            (click)="toggleValue(v)"> {{v.label}}
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
    <div *ngIf="item.type=='radio'" class="question-col-div">
      <div class="row question-div">
        <div class="question col-md-12">
          <label class="question-label" [ngClass]="{'sort_focus' : item.value==''&&isDisabledSubmit}">{{item.label}}</label>
        </div>
        <div class="radio col-md-7">
          <mat-radio-group class="example-radio-group" [(ngModel)]="item.value">
            <mat-radio-button class="radio-button" [disabled]="!isDisable" *ngFor="let ans of item.values"
              [value]="ans.value" (click)="toggleValue(i,ans.value, item)">
                <label class="label-radio" for="ans.label">{{ans.label}}</label><br>
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="col-md-5"> <textarea input class="comment" name="descrip" [disabled]="!isDisable" placeholder="Comments"
          [(ngModel)]="item.description"></textarea>
          <label class="change-color" *ngIf="(item.value==1||item.value==5)&&(item.description=='')">Comment field is mandatory for the selected option.</label>
        </div>
      </div>
      

      <!-- <div class="example-selected-value">Comment</div> -->

    </div>
    <div *ngIf="item.type=='button'" class="btn-cont">
      <input type="{{item.subtype}}" (click)="submit()" value="{{item.label}}" class="btn btn-primary"
        id="{{item.name}}">
    </div>
  </div>
  <div *ngIf="!parentData.hasOwnProperty('attributes')">
    <div  *ngFor="let temp of parentData.question">
      <label class="question-answer mt-2" [disabled]="!isDisable">{{temp.que}}</label><br>
      <textarea class="textarea" name="{{temp.que}}" [(ngModel)]="temp.ans" [disabled]="!isDisable"></textarea>
    </div>
  </div>
</div>