import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TooltipPosition } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { AddressDetailsDTO } from 'src/app/dtos/AddressDetailsDTO';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { EmergencyContact } from 'src/app/models/emergencycontact';
import { Family } from 'src/app/models/familydetails';
import { AddressDetailsService } from 'src/app/services/address-details/address-details.service';
import { EmergencycontactService } from 'src/app/services/emergency-contact/emergencycontact.service';
import { FamilyDetailsService } from 'src/app/services/familyDetails/familydetails.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AddressType, AuthoritiesConstants, BloodGroup, ContactType, EventTabClosed } from 'src/app/services/user/user-enum';
import { UserService } from 'src/app/services/user/user.service';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { EmergencyContactComponent } from './emergency-contact/emergency-contact.component';
import { AddressComponent } from './address/address.component';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-correspondence',
  templateUrl: './correspondence.component.html',
  styleUrls: ['./correspondence.component.scss'],
  animations: [
    trigger('slideUp', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('600ms ease-out', style({ transform: 'translateY(0)', opacity: 1 }))
      ]),
    ])
  ]
})
export class CorrespondenceComponent implements OnInit {
  userDto: UserDTO;
  bloodGroup = BloodGroup
  familydetailsdto: Family[] = [];
  address: AddressDetailsDTO[] = [];
  addresscurr: AddressDetailsDTO[] = [];
  emergencycontactone: EmergencyContact[] = [];
  emergencycontacttwo: EmergencyContact[] = [];
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  personalInformationPresentCheck = true;
  dataSubscription;
  userid: String;
  flag = [false, false, false, false, false, false];
  allowEditToOthers = false;
  @Output() dummyfunc = new EventEmitter();
  @Input() data: UserDTO;
  loggedInUserId: String;
  constructor(
    private feedbackService: FeedbackService,
    private dialog: MatDialog,
    private userDetailService: UserDetailsService,
    private userService: UserService,
    private addressdetailservice: AddressDetailsService,
    private emergencycontactservice: EmergencycontactService,
    private route: ActivatedRoute
  ) {
  }
  ngOnInit(): void {
    this.refreshOnEmployeeSearch();
  }
  refreshOnEmployeeSearch() {
    this.dataSubscription = this.feedbackService.data$.subscribe((data) => {
      
      this.userid = data;
      this.initiateComponent();
      this.getPersonalInformation();
      this.getPermanentAddress();
      this.getCurrentAddress();
      this.getEmergencyContactOneDetails();
      this.getEmergencyContactTwoDetails();
    });
  }
  initiateComponent() {
    this.bloodGroup = BloodGroup;
    this.loggedInUserId = this.userDetailService.getUserDetails().id;
    this.getUserID();
    this.allowEditToOthers =
      this.userid == this.loggedInUserId ||
      this.userDetailService.hasRole([
        this.getKeyByValue(AuthoritiesConstants.ROLE_HUMAN_RESOURCE),
        this.getKeyByValue(AuthoritiesConstants.ROLE_SUPER_ADMIN).toString(),
        this.getKeyByValue(AuthoritiesConstants.ROLE_ADMIN).toString(),
      ]);
  }
  getUserID() {
    if (this.userid == null) {
      this.userid = this.route.snapshot.paramMap.get('id')
        ? this.route.snapshot.paramMap.get('id')
        : this.userDetailService.getUserDetails().id;
      }
  }
  getKeyByValue(value: string): string | undefined {
    return Object.keys(AuthoritiesConstants).find(key => AuthoritiesConstants[key] === value);
  }
  getCurrentAddress() {
    this.addresscurr = [];
    this.addressdetailservice
      .getaddressbyuserId(AddressType.CURRENT, this.userid)
      .subscribe((res) => {
        if (res.body.id != '' && !this.flag[2]) {
          this.dummyfunc.emit(4);
          this.flag[2] = true;
        }
        this.addresscurr = res.body;
      }, (e) => { });
  }
  getPermanentAddress() {
    this.address = [];
    this.addressdetailservice
      .getaddressbyuserId(AddressType.PERMANENT, this.userid)
      .subscribe((res) => {
        if (res.body.id != '' && !this.flag[1]) {
          this.dummyfunc.emit(4);
          this.flag[1] = true;
        }
        this.address = res.body;
      }, (e) => { });
  }
  getPersonalInformation() {
    this.userDto = null;
    this.userService.getUserPersonalInformationDetails(this.userid).subscribe(
      (res) => {
        if (res.body.id && !this.flag[0]) {
          this.dummyfunc.emit(4);
          this.flag[0] = true;
        }
        this.userDto = res.body;
        if (
          (this.userDto.bloodGroup == null ||
            this.userDto.bloodGroup == undefined) &&
          (this.userDto.dateOfBirth == null ||
            this.userDto.dateOfBirth == undefined) &&
          (this.userDto.placeOfBirth == '' ||
            this.userDto.placeOfBirth == undefined) &&
          (this.userDto.relationshipStatus == null ||
            this.userDto.relationshipStatus == undefined)
        ) {
          this.personalInformationPresentCheck = false;
        } else {
          this.personalInformationPresentCheck = true;
        }
      }, (e) => {
        this.personalInformationPresentCheck = false;
      }
    );
  }
  getEmergencyContactOneDetails() {
    this.emergencycontactone = [];
    this.emergencycontactservice
      .getemerbyuserId(ContactType.Emergencycontactone, this.userid)
      .subscribe((res) => {
        if (res.body.id && !this.flag[4]) {
          this.dummyfunc.emit(4);
          this.flag[4] = true;
        }
        this.emergencycontactone = res.body;
      }, (e) => { });
  }
  getEmergencyContactTwoDetails() {
    this.emergencycontacttwo = [];
    this.emergencycontactservice
      .getemerbyuserId(ContactType.Emergencycontacttwo, this.userid)
      .subscribe((res) => {
        if (res.body.id && !this.flag[5]) {
          this.dummyfunc.emit(4);
          this.flag[5] = true;
        }
        this.emergencycontacttwo = res.body;
      }, (e) => { });
  }
  editAddressDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '66%';
    dialogConfig.width = '60%';
    dialogConfig.panelClass = 'currentaddress-class';
    dialogConfig.data = this.userid;
    const dialogRef = this.dialog.open(AddressComponent, dialogConfig).afterClosed()
      .subscribe((res) => {
        if (res.event == EventTabClosed.Closed) {
          this.getCurrentAddress();
          this.getPermanentAddress();
        }
      });
  }
  editPersonalInformationDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '66%';
    dialogConfig.width = '35%';
    dialogConfig.panelClass = 'personalinformation-class';
    dialogConfig.data = this.userid;
    const dialogRef = this.dialog
      .open(PersonalInformationComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res.event == EventTabClosed.Closed) {
          this.getPersonalInformation();
        }
      });
  }
  editEmergencyContact() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '66%';
    dialogConfig.width = '60%';
    dialogConfig.panelClass = 'emergencycontact-class';
    dialogConfig.data = this.userid;
    const dialogRef = this.dialog.open(
      EmergencyContactComponent,
      dialogConfig
    ).afterClosed()
      .subscribe((res) => {
        if (res.event == EventTabClosed.Closed) {
          this.getEmergencyContactOneDetails();
          this.getEmergencyContactTwoDetails();
        }
      });
  }
}
