<div class="container">
    <div class="main_header_div">
        <div class="head">
            <img src="assets/icons/dashboard-icons/calendar.svg" />
            Leave Balance
        </div>
        <img class="close-icon-svg" (click)="closeDialog()" src="assets/icons/dashboard-icons/close-pink-icon.svg" />
    </div>
</div>

<div class="container">
    <div class="employee-year-main">

        <div class="employee-image-container">
            <div *ngIf="userDetail.userImageThumbnail === null || userDetail.userImageThumbnail === ''">
                <img class="noemployee_img" src="assets/icons/default-avatar.jpg" alt="" />
            </div>
            <div *ngIf="userDetail.userImageThumbnail !== ''">
                <img class="employee_img" [(src)]="userDetail.userImageThumbnail" alt="img" />
            </div>
            <span>{{ (userDetail.firstName | titlecase) + " " + (userDetail.lastName | titlecase)}}</span>
        </div>
    
        <div class="month-container-main">
            <mat-form-field class="month-container" appearance="outline">
                <mat-label>Year</mat-label>
                <mat-select [(value)]="selectedYear" (selectionChange)="onYearChange(selectedYear)">
                  <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                </mat-select>
              </mat-form-field>
        </div>

    </div>
</div>

<div class="container main-div">
    <div class="row">
        <div *ngFor="let leaveType of leaveTypes" class="leave col-lg-4 col-md-6 col-sm-6 col-xs-12">
            <div class="leave-type">
                <div class="leave-heading">
                    <div class="heading-name">
                        <img [src]="leaveType.iconSrc" alt="calendar">
                        <label class="heading-lbl blue-clr">{{ leaveType.name | underScoreToSpace | titlecase }}</label>
                    </div>
                    <div class="opening-bal">
                        Opening<span *ngIf="leaveType.accruedBalance !== undefined">/Accrued</span> Balance :
                        <label
                            [ngClass]="{'blue-clr':leaveType.openingBalance!==0, 'black-clr':leaveType.openingBalance===0}">
                            <span *ngIf="leaveType.accruedBalance !== undefined"> {{ leaveType.openingBalance }}</span>
                            <span *ngIf="leaveType.accruedBalance === undefined"> {{ leaveType.openingBalance}}</span>
                            <span *ngIf="leaveType.accruedBalance !== undefined">/{{leaveType.accruedBalance}}</span>
                        </label>
                    </div>
                </div>
                <div class="leave-detail" [ngClass]="{'grey-clr': leaveType.availableBalance === 0,
                                'light-blue-clr': leaveType.availableBalance !== 0 &&  leaveType.name !== 'FLOATER_LEAVE', 
                                'orange-clr':  leaveType.name === 'FLOATER_LEAVE'}">
                    <div class="center avail-bal">Available Balance</div>
                    <div class="center"
                        [ngClass]="{'blue-clr':leaveType.availableBalance!==0, 'black-clr':leaveType.availableBalance===0}">
                        {{ leaveType.availableBalance | number:'2.0'}}</div>
                    <div class="center"><span *ngIf="leaveType.length !== 0">
                            <button class="view-detail" (click)="leaveHistoryDialog(leaveType)">View Details</button>
                        </span></div>
                    <div class="center availed">Availed: {{ leaveType.availed }}</div>
                    <div class="center lapse-date"
                        *ngIf="( leaveType.name === 'FLOATER_LEAVE' && leaveType.availed===0 && leaveType.availableBalance !== 0)">
                        Lapse on {{ getCurrentLapseDate() }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>