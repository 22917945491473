<div class="container">
  <div class="row">
    <div class="col-md-3 search-bar  set-margin">
      <img src="\assets\svg\search_bar.svg" alt="search" />
      <input type="search" class="search-input" placeholder="Search " [(ngModel)]="searchTerm"
        (input)="applySearch()" />
    </div>

    <button class="col-md-2 blue-button set-margin" (click)="policyConfigure()">
      <div><img class="img-class-bulk" src="assets/icons/upload-white.svg" /></div>
      <div>Policy Configure</div>
    </button>

    <button class="col-md-2 blue-button set-margin" (click)="bulkConfigure()">
      <div><img class="img-class-bulk" src="assets/icons/upload-white.svg" /></div>
      <div>Bulk Configure</div>
    </button>

    <div class="col-md-2 mat-form-field-title  set-margin">
      <mat-form-field appearance="outline" class="example-full-width">
        <mat-label>
          <img src="\assets\svg\filter-icon.svg" />
          <span class="common-heading"> Geo Location</span>
        </mat-label>
        <mat-select [(ngModel)] = "geoLocationSearch" (selectionChange)="applySearchForGeoLocation()" multiple>
          <mat-option *ngFor="let geoLocation of this.geoLocations" [value]="geoLocation">
            {{ geoLocation}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="container policy-table-heading-main">
      <div class="heading-content">Geo Location</div>
      <div class="heading-content">Employment Type</div>
      <div class="heading-content"></div>
      <div class="heading-content-2"></div>
      <div class="heading-content-2"></div>
    </div>
    <table class="policy-table">
      <tbody>
        <tr *ngFor = "let policy of filteredPolicyList">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <td class="policy-table-content">{{policy.geoLocation }}</td>
                  <td class="policy-table-content">{{policy.employmentType | titlecase}}</td>
                  <td class="policy-table-content"></td>
                  <td class="policy-table-content-2">
                    <button class="grey-border-button">Edit</button>
                  </td>
                  <td class="policy-table-content-2">
                    <button class="pink-button" (click)="deletePolicy(policy); $event.stopPropagation()">Delete</button>
                  </td>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <hr />
              <div>
                <table class="leaves-table">
                  <thead>
                    <tr>
                      <th class="leave-type-colmn">Leave Type</th>
                      <th>Number of Days</th>
                      <th>Carry Forward</th>
                      <th>Encash</th>
                    </tr>
                  </thead>
                  <!-- Here you can dynamically generate the rows based on your data -->
                  <tbody>
                    <tr *ngFor="let leave of policy.leaveConfigurations">
                      <td class="leave-type-colmn">{{leave.leaveType | underScoreToSpace | titlecase}}</td>
                      <td>{{leave.numberOfDays}}</td>
                      <td>{{leave.carryForwardLimit}}</td>
                      <td>{{leave.encashLimit}}</td>
                    </tr>
                  </tbody>
                  
                  <!-- Add more rows as needed -->
                </table>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </tr>
      </tbody>
    </table>
  </div>
</div>