import { Injectable } from '@angular/core';
import { RestAPIService } from '../restAPI/rest-api.service';
import { ResignationUrlsService } from '../restAPI/resignationURLs/resignation-urls.service';


@Injectable({
  providedIn: 'root'
})
export class ResignationServiceService {

  constructor(
    private restApiService: RestAPIService,
    private resignationUrlsService: ResignationUrlsService
  ) { }

  AcceptRejectResignation(isAccepted, id, payload) {
    return this.restApiService.put(
      this.resignationUrlsService.rejectResignation(isAccepted, id),
      payload

    )
  }

  getExitDetails(id) {
    return this.restApiService.get(
      this.resignationUrlsService.getExitDetailsBaseUrl(id)
    )
  }

  addKtUser(id, payload) {
    return this.restApiService.post(
      this.resignationUrlsService.postKtUserBaseUrl(id),
      payload
    )
  }

  deleteKtUser(id, ktId) {
    return this.restApiService.delete(
      this.resignationUrlsService.deleteKtUserBaseUrl(id, ktId)
    )
  }
  submitExitDetails(id,dto){
    return this.restApiService.post(
      this.resignationUrlsService.postKtUserBaseUrl(id),
      dto
    )
  }
}
