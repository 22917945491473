import { Injectable } from '@angular/core';
import { RestAPIService } from '../restAPI/rest-api.service';
import { PolicyConfigurationURLsService } from '../restAPI/leavePolicyConfigurationURLs/policy-configuration-urls.service';

@Injectable({
  providedIn: 'root'
})
export class PolicyConfigurationService {

  constructor(
    private restApiService: RestAPIService,
    private leavePolicyUrlService: PolicyConfigurationURLsService,
  ) { }

  createPolicy(payload){
    return this.restApiService.post(
      this.leavePolicyUrlService.createPolicyUrl(),payload
    )
  }

  getAllPolicy(){
    return this.restApiService.get(
      this.leavePolicyUrlService.getAllPolicyUrl()
    )
  }

  deletePolicy(id){
    return this.restApiService.delete(
      this.leavePolicyUrlService.deletePolicyUrl(id)
    )
  }

}
