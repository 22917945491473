import { JobTitles } from "../services/user/user-enum";

export class DirectReporteeFBDetaitDTO {
    public userId: String
    public firstName: String
    public lastName: String
    public employeeId: String
    public geoLocation: String
    public jobTitle: JobTitles
    public feedbackGroupCount: number
    public feedbackForms: number
    public submittedFeedbackCount: number
    public totalScore: number
    public isClosed: boolean
    public realTimeFeedbackCount: number

    constructor() {
        this.userId = "";
        this.firstName = "";
        this.lastName = "";
        this.employeeId = "";
        this.geoLocation = "";
        this.jobTitle=null;
        this.feedbackGroupCount=0;
        this.feedbackForms=0;
        this.submittedFeedbackCount=0;
        this.totalScore=0;
        this.realTimeFeedbackCount=0;
        this.isClosed=false;
    }
}