import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { Education } from 'src/app/models/educationdetails';
import { User } from 'src/app/models/user';
import { EducationDetailsService } from 'src/app/services/education-details/education-details.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { EventTabClosed } from 'src/app/services/user/user-enum';
import { UserService } from 'src/app/services/user/user.service';
@Component({
  selector: 'app-educational-qualifications',
  templateUrl: './educational-qualifications.component.html',
  styleUrls: ['./educational-qualifications.component.scss'],
})
export class EducationalQualificationsComponent implements OnInit {
  @ViewChild('fileInput') fileInput: File;
  Educationgroup: FormArray;
  userdto = new UserDTO();
  today = new Date();
  removedEducation: String[] = [];
  editSpotlightDetails: FormGroup;
  constructor(
    private fb: FormBuilder,
    private educationservice: EducationDetailsService,
    private toaster: ToastrService,
    private dialogref: MatDialogRef<EducationalQualificationsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: User
  ) {
  }
  ngOnInit(): void {
    this.initiateEducationalCertification();
  }
  initiateEducationalCertification() {
    this.editSpotlightDetails = this.fb.group({
      education: this.fb.array([]),
    });
    this.educationservice
      .getskillByUserIdSubscribe(this.data)
      .subscribe((res) => {
        if (res.body[0] == null) {
          this.addEducation();
        }
        this.setEducationFormArray(res.body);
      });
  }
  createeducation() {
    let formGroup = this.fb.group(new Education());
    formGroup.controls.courseName.setValidators([Validators.required,Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9+#. -]*$')]);
    formGroup.controls.universityName.setValidators([Validators.required,Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9+#. -]*$')]);
    formGroup.controls.yearofCompletion.setValidators([Validators.required]);
    formGroup.controls.yearOfBeginning.setValidators([Validators.required]);
    return formGroup;
  }
  setEducationFormArray(response) {
    this.Educationgroup = this.editSpotlightDetails.get(
      'education'
    ) as FormArray;
    let eduList: Education[] =
      this.educationservice.setEducationResponse(response);
    for (let s of eduList) {
      let v: Education = new Education().bind(s);
      let educationGroup = this.createeducation();
      educationGroup.controls.courseName.setValue(v.courseName);
      educationGroup.controls.universityName.setValue(v.universityName);
      educationGroup.controls.yearofCompletion.setValue(v.yearofCompletion);
      educationGroup.controls.yearOfBeginning.setValue(v.yearOfBeginning);
      educationGroup.controls.id.setValue(v.id);
      this.Educationgroup.insert(0, educationGroup);
    }
  }
  Education(): FormArray {
    return this.editSpotlightDetails.get('education') as FormArray;
  }
  addEducation() {
    this.Educationgroup = this.editSpotlightDetails.get(
      'education'
    ) as FormArray;
    this.Educationgroup.insert(0, this.createeducation());
  }
  removeeducation(id, i: number) {
    this.removedEducation.push(id);
    this.Education().removeAt(i);
  }
  getMinEndDate(i) {
    var endDate = this.editSpotlightDetails
      .get('education')
    ['controls'][i].get('yearOfBeginning').value;
    return endDate;
  }
  closeDialog(event: Event) {
    this.dialogref.close({ event: EventTabClosed.Closed });
  }
  onSubmit() {
    let isPopUp = false;
    if (this.editSpotlightDetails.valid) {
      for (let edu of this.editSpotlightDetails.value.education) {
        if (
          edu &&
          !edu.id &&
          edu.courseName != '' &&
          edu.universityName != '' &&
          edu.yearofCompletion != undefined &&
          edu.yearOfBeginning != undefined
        ) {
          edu.userId = this.data;
          this.educationservice.create(edu).subscribe((res) => {
            this.dialogref.close({ event: EventTabClosed.Closed });
            if (!isPopUp) {
              this.toaster.success('Successfully Saved');
              isPopUp = true;
            }
          });
        } else if (
          edu &&
          edu.id &&
          edu.courseName != '' &&
          edu.universityName != '' &&
          edu.yearofCompletion != undefined &&
          edu.yearOfBeginning != undefined
        ) {
          edu.userId = this.data;
          this.educationservice.update(edu).subscribe((res) => {
            this.dialogref.close({ event: EventTabClosed.Closed });
            if (!isPopUp) {
              this.toaster.success('Successfully Saved');
              isPopUp = true;
            }
          });
        }
      }
      for (let i in this.removedEducation) {
        this.educationservice.delete(this.removedEducation[i]);
        this.dialogref.close({ event: EventTabClosed.Closed });
      }
    } else {
      this.toaster.error('Invalid Details');
    }
  }
}
