<table class="content-table">
  <thead>
    <tr class="table-head">
      <th class="head-3">Team Members</th>
      <th class="head-1">LeaveType/Holiday</th>
      <th class="head-2">No. of Days</th>
      <th class="head-3">Status</th>
    </tr>
  </thead>
  <tbody>
    <div *ngIf="isSpinner" class="spinner-div">
      <mat-spinner strokeWidth="7" [diameter]="150"></mat-spinner>
      <label class="loading_label">Loading...</label>
    </div>
  </tbody>
  <tbody *ngIf="!isSpinner">
    <div *ngIf="leaveApplicationsList.length == 0">
      <label class="no-data-message">No data available</label>
    </div>
    <ng-container *ngIf="leaveApplicationsList.length > 0">
      <ng-container *ngFor="
        let leave of this.leaveApplicationsList | orderBy : 'startDate' : 'desc' | orderByApprovalStatus
      ">
        <tr>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <td class="content-3">{{ leave.name }} ({{ leave.empId }})</td>
                  <td class="content-1">
                    {{
                    leave.leaveType !== "FLOATER_LEAVE"
                    ? leave.leaveType == "PLANNED_LEAVE"
                    ? "Planned Leave"
                    : (leave.leaveType | underScoreToSpace | titlecase)
                    : "Floater"
                    }}
                  </td>
                  <td class="content-2">{{ leave.noOfDays }}</td>
                  <td class="content-3">{{ leave.approvalStatus | underScoreToSpace | titlecase}}</td>
                  <td>
                    <button *ngIf="leave.approvalStatus=='PENDING'" class="reject-btn" (click)="revokeBox(leave)">
                      Reject
                    </button>
                  </td>
                  <td>
                    <button *ngIf="leave.approvalStatus=='PENDING'" class="approve-btn" (click)="approveLeave(leave)">
                      Approve
                    </button>
                  </td>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <hr />
              <div class="expansion">
                <div>Applied on : {{ leave.transactionDateTime | date }}</div>
                <div *ngIf="leave.reason" class="reason-mat">Reason : {{ leave.reason }}</div>
              </div>
              <div>
                Duration : {{ leave.startDate | date }} to
                {{ leave.endDate | date }}
              </div>
              <div>
                <ng-container *ngIf="leave.attachment">
                  <div class="leave-prescription" (click)="open(content10, leave.attachment)">
                    <img class="img_size" class="attachment-img" src="assets/icons/Certificate.png">
                    <label class="attachment-label" >View Attachment</label>
                  </div>
                  <ng-template #content10 let-modal>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-body">
                      <div *ngIf="leave.attachmentType == 'application/pdf'">
                        <pdf-viewer [src]="leave.attachment" [rotation]="0" [fit-to-page]="false"
                          [original-size]="false" [show-all]="true" class="pdf-attachment"></pdf-viewer>

                      </div>
                      <div *ngIf="leave.attachmentType === 'image/png' || leave.attachmentType === 'image/jpeg'">
                        <img class="preview-img" [src]="leave.attachment">
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </tr>
      </ng-container>
    </ng-container>


  </tbody>
</table>