import { Injectable } from '@angular/core';
import { Visa } from 'src/app/models/visa';
import { RestAPIService } from '../restAPI/rest-api.service';
import { VisaUrlService } from '../restAPI/visaURLs/visa-url.service';
@Injectable({
  providedIn: 'root'
})
export class VisaService {
  constructor(private restApiService: RestAPIService,
    private visaurl : VisaUrlService) { }
    create(payload) {
      return this.restApiService.post(
        this.visaurl.getBaseUrl(),
        payload
      )
    }
    getvisaByUserIdSubscribe(userId) {
      return this.restApiService.get(
          this.visaurl.getUserUrl(userId))
  }
    update(payload){
      return this.restApiService.put(
        this.visaurl.getBaseUrl(),
        payload
      )
    }
    getskillByUserIdSubscribe(userId) {
      return this.restApiService.get(
        this.visaurl.getUserUrl(userId)
      )
    }
    setVisaResponse(response):Visa[]{
      let visaList:Visa[] = []
      for(let data of response){
          let visa:Visa = new Visa()
          visaList.push(
            visa.bind(data)          
          )
      }
      return visaList
    }
    delete(id: any) {
      this.restApiService.delete(
        this.visaurl.getByFieldUrl(id)
      ).subscribe(res=>{
       
      })
    }
}
