interface IEmergencyContact {
    id: string;
    name: string;
    relationship: string;
    location: string;
    phoneNumber: string;
    emergencyContactType: string;
    userId:string
}
export class EmergencyContact {
    public id: String;
    public name : string
    public  relationship: string
    public emergencyContactType :string
    public location :string
    public phoneNumber :string
    public userId:string
    constructor(){
        this.name = ''
        this.id=undefined
        this.emergencyContactType = ''
        this.relationship=''
        this.location = ''
        this.phoneNumber=''
    }
}