<div class="container-fluid">
  <div class="main_header_div">
    <div class="child">
      <h1 class="header_name">Apply For Resignation</h1>
    </div>
    <div class="child_close_btn">
      <button class="closeButton" (click)="closeDialog()">Close</button>
    </div>
  </div>

  <form [formGroup]="resignationform" (ngSubmit)="onsubmit()">
    <div class="row main-resignation-box">
      <div class="side-by-side">
        <div class="left">
          <label class="noticeperiod_warning"
            >Official notice period is 2 months from the date of your
            resignation.</label
          >
        </div>

        <div class="right">
          <label class="resignation_label">Date of Resignation: </label><br />
          <span class="resignation_date">
            <b>{{ currentDate | date : "dd-MMMM-yyyy" }}</b></span
          >
        </div>
      </div>

      <div class="mail-box">
        <div class="date-box">
          <div class="cm_header">
            <label class="cm_label">Career Manager</label>
            <span class="cm_email">{{ careerManagerEmailId }}</span>
          </div>
        </div>
        <div class="date-box">
          <div class="cm_header">
            <label class="cm_label">PnT Team</label>
            <span class="cm_email">{{ this.PnTMail }}</span>
          </div>
        </div>
        <div class="date-box">
          <div class="cm_header">
            <label class="cm_label">Capablity Lead </label>
            <span class="cm_email">{{ this.capablityLeadMail }} </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <label class="resignation_reason_label">Reason For Separation</label>
        <div>
          <textarea
            matInput
            type="text"
            formControlName="exitReason"
            class="resignation_reason_input"
            required
          >
          </textarea>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="submit_button_main">
          <button class="add_Emp_btn" mat-raised-button>
            <span class="add_em_name">Apply For Resignation</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
