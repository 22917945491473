import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseUrl = environment.BASE_API_URL_BACKEND+'/'
  constructor(private http: HttpClient,private storageService: StorageService) { }

  public getEmployees(): Observable<any> {
    return this.http.get<any>(this.baseUrl+'api/employees');
  }

  public addEmployee(payload): Observable<any> {
    return this.http.post<any>(this.baseUrl+'api/employees', payload);
  }

  public updateEmployee(payload): Observable<any> {
    return this.http.put<any>(this.baseUrl+'api/employees', payload);
  }

  public deleteEmployee(id): Observable<any> {
    return this.http.delete<any>(this.baseUrl+'api/employees/', id);
  }

  public getEmployeeByEmail(email): Observable<any> {
    return this.http.get<any>(this.baseUrl+'api/employees/email/'+email);
  }

  public getEmployeeByCmId(id): Observable<any> {
    return this.http.get<any>(this.baseUrl+'api/employees/career-manager/'+id);
  }

  public getProjects(): Observable<any> {
    return this.http.get<any>(this.baseUrl+'api/projects');
  }

  public addProject(payload): Observable<any> {
    return this.http.post<any>(this.baseUrl+'api/projects', payload);
  }

  public updateProject(payload): Observable<any> {
    return this.http.put<any>(this.baseUrl+'api/projects', payload);
  }

  public deleteProject(id): Observable<any> {
    return this.http.delete<any>(this.baseUrl+'api/projects/', id);
  }

  public getFeedbacks(): Observable<any> {
    return this.http.get<any>(this.baseUrl+'api/feedbacks');
  }

  public addFeedback(payload): Observable<any> {
    return this.http.post<any>(this.baseUrl+'api/feedbacks', payload);
  }

  public updateFeedback(payload): Observable<any> {
    return this.http.put<any>(this.baseUrl+'api/feedbacks', payload);
  }

  public deleteFeedback(id): Observable<any> {
    return this.http.delete<any>(this.baseUrl+'api/feedbacks/', id);
  }

  public filterFeedbacks(payload): Observable<any> {
    return this.http.post<any>(this.baseUrl+'api/feedbacks/filter', payload, {observe: 'response'});
  }

  public getAuthenticationToken(loginPass): Observable<any> {
    return this.http.post<any>(this.baseUrl+'api/authenticate', {
      "password": loginPass.password,
      "rememberMe": true,
      "username": loginPass.email
    }, {observe: 'response'});
  }

  public getAllProjects(token): Observable<any> {
    return this.http.get<any>(this.baseUrl+'api/projects');
  }
  
  public getAllUsers(token): Observable<any> {
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<any>(this.baseUrl+'api/users');
  }

  public getUserAccount(): Observable<any> {
    return this.http.get<any>(this.baseUrl+'api/account');
  }

  public getUserById(id): Observable<any> {
    return this.http.get<any>(this.baseUrl+'api/users/'+id);
  }

  public postProjectData(projectInfo): Observable<any> {
    const header = new HttpHeaders().set('Authorization', 'Bearer ' +this.storageService.getItem(StorageService.JWT_TOKEN));
  
    return this.http.post<any>(this.baseUrl+'api/projects', projectInfo, {observe: 'response'});
  }

  // added in careermanager REST API service(tested)
  public postCareerManagerData(careerMangerInfo): Observable<any> {
    return this.http.post<any>(this.baseUrl+'api/career-manager-teams', careerMangerInfo, {observe: 'response'});
  }

  // added in careermanager REST API service(not used)
  public getCareerManagerData(): Observable<any> {
    return this.http.get<any>(this.baseUrl+'api/career-manager-teams');
  }

  // added in careermanager REST API service(tested)
  public getCareerManagerDataById(id): Observable<any> {
    return this.http.get<any>(this.baseUrl+'api/career-manager-teams/'+id);
  }

  public putProjectData(projectInfo): Observable<any> {
    const header = new HttpHeaders().set('Authorization', 'Bearer ' +this.storageService.getItem(StorageService.JWT_TOKEN));
    return this.http.put<any>(this.baseUrl+'api/projects', projectInfo, {observe: 'response'});
  }

  public putFormData(formInfo): Observable<any> {
    const header = new HttpHeaders().set('Authorization', 'Bearer ' +this.storageService.getItem(StorageService.JWT_TOKEN));
    return this.http.put<any>(this.baseUrl+'api/form-schemas',formInfo, {observe: 'response'});
  }
  
  public postProjectFormData(formInfo): Observable<any> {
    const header = new HttpHeaders().set('Authorization', 'Bearer ' +this.storageService.getItem(StorageService.JWT_TOKEN));
    return this.http.post<any>(this.baseUrl+'api/form-schemas', {
      "formTemplate": formInfo.formTemplate,
      "projectId": formInfo.projectId,
      "formType" : formInfo.formType,
      "careerManagerTeamId" : formInfo.careerManagerTeamId,
    }, {observe: 'response','headers':header});
  }

  // added in careermanager REST API service(tested)
  public postCareerManagerFormData(formInfo): Observable<any> {
    return this.http.post<any>(this.baseUrl+'api/form-schemas', {
      "formTemplate": formInfo.formTemplate,
      "careerManagerTeamId": formInfo.careerManagerTeamId,
      "formType" : formInfo.formType,
    }, {observe: 'response'});
  }

  // added in careermanager REST API service(tested)
  public putCareerManagerTeamData(careerManagerTeamInfo): Observable<any> {
    return this.http.put<any>(this.baseUrl+'api/career-manager-teams', careerManagerTeamInfo, {observe: 'response'});
  }
   
  // added in careermanager REST API service(tested)
  public getFeedbackFormByCareerManagerTeamId(id): Observable<any> {
    return this.http.get<any>(this.baseUrl+'api/form-schemas-by-career-manager-team-id/'+id);
  }

  public getAuthority(token): Observable<any> {
    return this.http.get<any>(this.baseUrl+'api/users/authorities');
  }

  public addClient(payload): Observable<any> {
    return this.http.post<any>(this.baseUrl+'api/clients', payload);
  }

  public getClients(): Observable<any> {
    return this.http.get<any>(this.baseUrl+'api/clients');
  }

  public searchClientByText(searchText): Observable<any> {
    return this.http.get<any>(this.baseUrl+'api/clients/search/'+searchText);
  }
  public searchProjectByText(searchText): Observable<any> {
    return this.http.get<any>(this.baseUrl+'api/projects/search/'+searchText);
  }
  
  public getClientsPagination(pageCount,pageSize): Observable<any> {
    pageCount-=1;
    let url = this.baseUrl+'api/clients'+'?'+'page='+pageCount+'&size='+pageSize;
    return this.http.get<any>(url,{observe:'response'});
  }
  public getCareerManagerTeamPagination(pageCount,pageSize): Observable<any> {
    pageCount-=1
    let url = this.baseUrl+'api/career-manager-teams'+'?'+'page='+pageCount+'&size='+pageSize;
    return this.http.get<any>(url,{observe:'response'});
  }
   public getProjectPagination(pageCount,pageSize): Observable<any> {
    pageCount-=1
    let url = this.baseUrl+'api/projects'+'?eagerload=false&'+'page='+pageCount+'&size='+pageSize;
    return this.http.get<any>(url,{observe:'response'});
  }

  public updateClient(payload): Observable<any> {
    return this.http.put<any>(this.baseUrl+'api/clients', payload);
  }

  public deleteClient(id): Observable<any> {
    return this.http.delete<any>(this.baseUrl+'api/clients/', id);
  }

  public getProjectById(id,token): Observable<any> {
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<any>(this.baseUrl+'api/projects/'+id);
  }

  public getFeedbackFormtById(id,token): Observable<any> {
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<any>(this.baseUrl+'api/form-schemas/'+id);
  }

  public getFeedbackFormtByProjectId(id,token): Observable<any> {
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<any>(this.baseUrl+'api/form-schemas-by-project/'+id);
  }

  public msLogin(token): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'api/msLogin', {
      "accessToken": token
    }, { observe: 'response' }).pipe();
  }

  public addImage(payload): Observable<any> {
    return this.http.post<any>(this.baseUrl+'api/images', payload);
  }

  public getImages(): Observable<any> {
    return this.http.get<any>(this.baseUrl+'api/images');
  }

  public getImageById(id): Observable<any> {
    return this.http.get<any>(this.baseUrl+'api/images/'+id);
  }

  public getImageContentByImageId(id) {
    return this.http.get(this.baseUrl+'api/images/'+id + '/_imageContent');
  }

}
