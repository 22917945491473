export enum AttendanceStatus {
    PRESENT='PRESENT',
    ABSENT='ABSENT',
    REGULARIZED_PRESENT='REGULARIZED_PRESENT',
    PENDING_FOR_APPROVAL='PENDING_FOR_APPROVAL',
    UNMARKED_ATTENDANCE="UNMARKED_ATTENDANCE",
    LEAVE="LEAVE",
    HOLIDAY="HOLIDAY"
}

