import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { PrievewdialogComponent } from '../prievewdialog/prievewdialog.component';

@Component({
  selector: 'app-employee-selection',
  templateUrl: './employee-selection.component.html',
  styleUrls: ['./employee-selection.component.scss']
})
export class EmployeeSelectionComponent implements OnInit {
  projects: any[];
  clientDetails:any;
  selectedEntries=[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EmployeeSelectionComponent>,
    private dialog: MatDialog,
  ) {
    this.projects = data.projects;
    this.clientDetails=data.clientData
     }

  ngOnInit(): void {
  }
  
  closeDialog() {
    this.dialogRef.close();
  }

  selectAllEmployees(checked: boolean) {
    this.projects.forEach(project => {
      project.projectMembers.forEach(member => {
        member.selected = checked;
      });
    });
  }
  openPreview(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '70%';
    dialogConfig.data = {  projects: this.selectedEntries , clientData: this.clientDetails};
    const dialogRef = this.dialog.open(PrievewdialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.dialogRef.close();
    })
}


toggleSelection(projectName: string, member: any,clientId,projectId): void {
  member.selected = !member.selected; 

  if (member.selected) {
    const selectedEntry = {
      projectName: projectName,
      talentName: member.talentName,
      userId: member.userId,
      clientId:clientId,
      projectId:projectId
    };
    this.selectedEntries.push(selectedEntry);
  } else {
    const index = this.selectedEntries.findIndex(entry =>
      entry.projectName === projectName && entry.talentName === member.talentName && entry.userId === member.userId
    );
    if (index !== -1) {
      this.selectedEntries.splice(index, 1);
    }
  }
}

}
