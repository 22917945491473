<div class="main-container">
    <div class="header mt-3">
      <div class="warnig-with-icon">
        <img src="\assets\icons\warning.png" />
        <label class="warning">Warning</label>
      </div>
      <div class="close-icon">
        <img src="assets/icons/dashboard-icons/close-pink-icon.svg" (click)="closeDialog()" />
      </div>
    </div>
    <div class="line"></div>
  
    <div class="body-text">
      <label>
        Are you sure you want to delete this Policy?
      </label>
      
    </div>
    <div class="footer">
      <button class="no" (click)="closeDialog()">No</button>
      <button class="yes" (click)="deletePolicy()">Yes</button>
    </div>
  </div>
  