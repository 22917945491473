import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor() { }

  getImage(imageId){
    return environment.BASE_API_URL_BACKEND+"/api/images/"+imageId+"/_imageContent"
  }
}
