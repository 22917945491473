import { Injectable } from '@angular/core';
import { RestAPIService } from '../restAPI/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class TransferDetailsService {
  details=[]

  constructor(private restApi: RestAPIService) { }

  saveIds(getDetails:any){
    this.details = getDetails;
   
    
}
retrieveIDs(){
  
    return this.details
}
}
