import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LeaveBalancesServiceUrl {
  private readonly leaveBalanceBaseUrl = '/api/getLeaveBalanceByIdAndYear';
  private readonly leaveBalanceEnableOrDisable = '/api/leave_balances/enableOrDisableLeave';
  private readonly leaveBalanceBaseHRUrl = '/api/leave_balances/addLeaveBalanceByHR';
  private readonly bulkUploadUrl= '/api/leave_balance/upload-bulk';
  
  constructor() { }

  getBaseUrl() {
    return this.leaveBalanceBaseUrl;
  }

  getLeaveBalances(userId, year) {
    return this.leaveBalanceBaseUrl + '/' + userId + '/' + year;
  }

  getByField(field) {
    return '/' + field;
  }
  
  isleaveBalanceEnableOrDisable(leaveType : string,userid : string,isEnabled : boolean, year){
    return this.leaveBalanceEnableOrDisable + '/' + leaveType + '/' + userid + '/' + isEnabled + '/' + year;
  }
  addLeaveBalanceByHRUrl(leaveType,count,userId,year){
    return this.leaveBalanceBaseHRUrl + '/' + leaveType + '/' + count + '/' + userId + '/' + year;
  }

  bulkConfigureByHRUrl(leaveType,leaveCount,year,geoLocation, employmentType){
    return this.leaveBalanceBaseHRUrl + '/' + 'bulkConfigure' + '/' + leaveType + '/' + leaveCount + '/' + year + '/' + geoLocation + '/' + employmentType;
  }
  
  bulkExcelUploadByHRUrl(year){
    return this.bulkUploadUrl + '/' + year;
  }
}
