<div *ngIf="this.temp == true">
  <div class="card-container1">
    <div class="matcardprojectdetail1">
      <div class="section">
        <img src="assets/icons/perf-summary.png"/>
        <div class="section-header">
          Annual Performance Review Summary
          <div class="perf-year">Year: {{ this.selected }}</div>
        </div>
      </div>

      <div class="notes-data">No feedback available</div>
    </div>
  </div>
</div>
<div class="card-container1" *ngFor="let summary of this.feedback">
  <div class="matcardprojectdetail1">
    <div class="section">
      <img src="assets/icons/perf-summary.png"/>
      <div class="section-header">
        Annual Performance Review Summary
        <div class="perf-year">Year: {{ this.year }}</div>
      </div>
    </div>

    <div *ngIf="this.isclosed" class="notes-data" [innerHTML]="summary"></div>
    <div *ngIf="!this.isclosed" class="notes-data">No feedback available</div>
  </div>
</div>
