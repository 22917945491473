import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { Departments, GeoLocation, JobTitles } from 'src/app/services/user/user-enum';
import { UserService } from 'src/app/services/user/user.service';
import { SingleEmployeeConfigureComponent } from '../single-employee-configure/single-employee-configure.component';
import { ViewLeavebalanceDialogComponent } from '../view-leavebalance-dialog/view-leavebalance-dialog.component';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'app-employee-leave-config',
  templateUrl: './employee-leave-config.component.html',
  styleUrls: ['./employee-leave-config.component.scss']
})

export class EmployeeLeaveConfigComponent implements OnInit {
  selectedYear: number;
  years: number[] = [];
  activeUser: UserDTO[]
  departments = [];
  jobTitles = [];
  department =Departments;
  jobTitle=JobTitles;
  page: number = 1;
  searchTerm: string = '';
  filteredUsers: UserDTO[];
  searchForm: FormGroup;
  capabilitySearch: Array<string> = [];
  jobTitleSearch: Array<string> = [];
  currentSortName: { column: string, direction: 'asc' | 'desc' } = { column: 'name', direction: 'asc' };
  currentSortEmployeeId: { column: string, direction: 'asc' | 'desc' } = { column: 'employeeId', direction: 'asc' };
  currentSortJobTitle: { column: string, direction: 'asc' | 'desc' } = { column: 'jobTitle', direction: 'asc' };
  currentSortDepartment: { column: string, direction: 'asc' | 'desc' } = { column: 'department', direction: 'asc' };
  // Add this property to your component class
  currentSort: { column: string, direction: 'asc' | 'desc' } = { column: 'name', direction: 'asc' };
  hasHrRights: boolean = false;
  hasSuperAdminRights: boolean = false;
  loggedInGeoLocation: string;
  geoLocations: any = [];
  geoLocationSearch: Array<string> = [];
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private router: Router,
    private userDetailService: UserDetailsService
  ) { }

  ngOnInit(): void {
    this.selectedYear = new Date().getFullYear();
    this.processEnumValuesToArray();
    this.getActiveUser();
  }

  getActiveUser() {
    this.hasHrRights = this.userDetailService.hasRole(['ROLE_HUMAN_RESOURCE']);
    this.hasSuperAdminRights = this.userDetailService.hasRole(['ROLE_SUPER_ADMIN']);
    this.loggedInGeoLocation = this.userDetailService.getUserDetails().geoLocation;
    this.userService.getActiveEmployee().subscribe(res => {
      if(this.hasSuperAdminRights){
        this.filteredUsers = res;
        this.activeUser = res;
      }else if(this.hasHrRights && !this.hasSuperAdminRights){
        this.filteredUsers = res.filter(user => user.geoLocation === this.loggedInGeoLocation);
        this.activeUser = this.filteredUsers;
      }

      this.filteredUsers.forEach(element => {
        if (element.userImageThumbnail !== '') {
          element.userImageThumbnail =
            'data:image/png;base64,' + element.userImageThumbnail;
        }
        this.userService.getEmployeeById(element.careerManagerId).subscribe(res => {
          element.carrierManagerName = res.body.firstName + " " + res.body.lastName;
        })
      });
    });
  }

  applySearch() {
    this.capabilitySearch=[];
    this.jobTitleSearch=[];
    this.geoLocationSearch=[];
    let filteredUsers = this.activeUser.filter(user => {
      const nameMatch = user.name.toLowerCase().includes(this.searchTerm.toLowerCase());

      return nameMatch;
    });

    filteredUsers.sort((a, b) => {
      const aNameMatch = a.name.toLowerCase().includes(this.searchTerm.toLowerCase());
      const bNameMatch = b.name.toLowerCase().includes(this.searchTerm.toLowerCase());

      if (aNameMatch && !bNameMatch) {
        return -1;
      } else if (!aNameMatch && bNameMatch) {
        return 1;
      } else {
        return 0;
      }
    });
    this.filteredUsers = filteredUsers;
  }

  applySearchForJobTitleAndDepartement() {
    let filteredUsers = this.activeUser.filter(user => {
      const departmentKeys = this.capabilitySearch.map(capability => this.getDepartmentKeyByValue(capability));
      const jobTitleKeys = this.jobTitleSearch.map(jobTitle => this.getJobTitleKeyByValue(jobTitle));
      const geoLocationKeys = this.geoLocationSearch.map(geoLocation => this.getGeoLocationKeyByValue(geoLocation));
      const departmentMatch = departmentKeys.length === 0 || departmentKeys.includes(user.department);
      const jobTitleMatch = jobTitleKeys.length === 0 || jobTitleKeys.includes(user.jobTitle);
      const geoLocationMatch = geoLocationKeys.length === 0 || geoLocationKeys.includes(user.geoLocation);
      return departmentMatch && jobTitleMatch && geoLocationMatch;
    });
    this.filteredUsers = filteredUsers;
  }

  processEnumValuesToArray() {
    for (const jobTitle of Object.values(JobTitles)) {
      if (isNaN(Number(jobTitle))) {
        this.jobTitles.push(jobTitle);
      }
    }

    for (const department of Object.values(Departments)) {
      if (isNaN(Number(department))) {
        this.departments.push(department);
      }
    }

    for (const geoLocation of Object.values(GeoLocation)) {
      if (isNaN(Number(geoLocation))) {
        this.geoLocations.push(geoLocation);
      }
    }
  }

  getDepartmentKeyByValue(value: string): string | undefined {
    return Object.keys(Departments).find(key => Departments[key] === value);
  }

  getJobTitleKeyByValue(value: string): string | undefined {
    return Object.keys(JobTitles).find(key => JobTitles[key] === value);
  }

  getGeoLocationKeyByValue(value: string): string | undefined{
    return Object.keys(GeoLocation).find(key => GeoLocation[key] === value);
  }

  sortTable(column: string): void {
    switch (column) {
      case 'name':
      case 'employeeId':
      case 'jobTitle':
      case 'department':
      case 'carrierManagerName':
      case 'email':
        this.sortByColumn(column);
        break;
    }
  }

  private sortByColumn(column: string): void {
    const currentSort = this.getCurrentSortForColumn(column);

    if (currentSort.column === column) {
      currentSort.direction = currentSort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      currentSort.column = column;
      currentSort.direction = 'asc';
    }

    this.updateCurrentSortForColumn(column, currentSort);

    this.filteredUsers.sort((a, b) => {
      const aValue = a[column] ? a[column].toLowerCase() : null;
      const bValue = b[column] ? b[column].toLowerCase() : null;

      if (aValue === null && bValue === null) {
        return 0;
      } else if (aValue === null) {
        return 1;
      } else if (bValue === null) {
        return -1;
      } else {
        return currentSort.direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
    });
  }

  private getCurrentSortForColumn(column: string): { column: string, direction: 'asc' | 'desc' } {
    switch (column) {
      case 'name':
        return this.currentSortName;
      case 'employeeId':
        return this.currentSortEmployeeId;
      case 'jobTitle':
        return this.currentSortJobTitle;
      case 'department':
        return this.currentSortDepartment;
      default:
        return { column: '', direction: 'asc' };
    }
  }

  private updateCurrentSortForColumn(column: string, currentSort: { column: string, direction: 'asc' | 'desc' }): void {
    switch (column) {
      case 'name':
        this.currentSortName = currentSort;
        break;
      case 'employeeId':
        this.currentSortEmployeeId = currentSort;
        break;
      case 'jobTitle':
        this.currentSortJobTitle = currentSort;
        break;
      case 'department':
        this.currentSortDepartment = currentSort;
        break;
    }
  }

  configureEmployeeLeave(user) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '55%';
    dialogConfig.height = '38%';
    dialogConfig.data = {
      userDetail: user,
      year: this.selectedYear,
    };
    const dialogRef = this.dialog.open(
      SingleEmployeeConfigureComponent,
      dialogConfig
    );
  }

  viewEmployeeBalance(user) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '100vw';
    dialogConfig.height = '90vh';
    dialogConfig.data = {
      userDetail: user,
    };
    const dialogRef = this.dialog.open(
      ViewLeavebalanceDialogComponent,
      dialogConfig
    );

  }

  getEnumValueByKey(type, key: string): any {
    return type[key];
  }

}
