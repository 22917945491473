import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterLink, RouterStateSnapshot } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Client } from '@microsoft/microsoft-graph-client';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { OAuthSettings } from 'src/oauth';
import { UserDTO } from '../dtos/UserDTO';
import { AlertsService } from './alerts.service';
import { ApiService } from './api.service';
import { BadgeService } from './leftMenuBar/badge.service';
import { SidenavService } from './sidenav.service';
import { SpinnerService } from './spinner.service';
import { StorageService } from './storage.service';
import { UserDetailsService } from './user-details.service';
import { User } from './user/user';
import { LoginType } from './login/login-enum';
import { LoginService } from './login.service';
import { UserService } from './user/user.service';
import { Toast, ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  
  public static authenticated: boolean = false;
  public user: User;
  public msLoginBtnLabel = "Login";
  public msLoginBtnDisabled = false;
  isLoggedIn = false;
  loggedInUser;
  userDetails = new UserDTO();
  badCredentials: boolean;
  logInProcess: boolean;
  constructor(
    private badgeService:BadgeService,
    private router: Router,
    private storageService: StorageService,
    private loginService: LoginService,
    private msalService: MsalService,
    private alertsService: AlertsService,
    private apiService: ApiService,
    private spinnerService: SpinnerService,
    private userDetailsService: UserDetailsService,
    private sidenavService:SidenavService,
    private userService: UserService,
    private toaster: ToastrService) {
  }
  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    if (this.userDetailsService.getUserDetails() == undefined){
      this.userDetailsService.setUserDetails( 
        JSON.parse(this.storageService.getItem(StorageService.USER_INFO)));

    }

    if (AuthGuardService.authenticated == false) {
      if(this.storageService.getItem(StorageService.LOGIN_TYPE) == LoginType[LoginType.MicrosoftSSOLogin]){
      AuthGuardService.authenticated = this.msalService.getAccount() != null;
      this.getUser().then((user) => { this.user = user });
      return AuthGuardService.authenticated ? true : false;
      }else if(this.storageService.getItem(StorageService.LOGIN_TYPE) == LoginType[LoginType.NormalLogin]){
        this.loginService.updateLoggedinUserInfo();
        AuthGuardService.authenticated = true;
        return AuthGuardService.authenticated ? true : false;

      }
    }
    else {
      let loggedinUserType = this.storageService.getItem(
        StorageService.LOGGED_IN_USER_TYPE);
      let loggedinUserInfo = JSON.parse(this.storageService.getItem(StorageService.LOGGED_IN_USER_INFO));
      if (loggedinUserInfo == null) {
        this.loginService.isLoggedIn = false;
        this.router.navigate(['/login']);
        return false;
      } else {
        this.loginService.isLoggedIn = true;
        this.loginService.loggedInUser = loggedinUserInfo;
        return true;
      }
    }
  }
  async signIn(): Promise<void> {
    this.msLoginBtnLabel = "Logging In..."
    this.msLoginBtnDisabled = true;
    let result = await this.msalService.loginPopup(OAuthSettings)
      .catch((reason) => {
        this.alertsService.addError('Login failed', JSON.stringify(reason, null, 2));
        this.toaster.error("Retry after reloading the page", "Internal Server Error");
        this.toaster.info(reason, "Login Failed" );
        localStorage.clear();
        this.msLoginBtnLabel = "Login"
        this.msLoginBtnDisabled = false;
        
      });
    if (result) {
      if (!AuthGuardService.authenticated) {
        this.user = await this.getUser();
      }
    }
  }
  async getAccessToken(): Promise<string> {
    let result = await this.msalService.acquireTokenSilent(OAuthSettings)
      .catch((reason) => {
        this.alertsService.addError('Get token failed', JSON.stringify(reason, null, 2));
        this.toaster.error("Retry after reloading the page", "Internal Server Error")
        this.toaster.info(reason, "Login Failed" )
        localStorage.clear();
        this.msLoginBtnLabel = "Login"
        this.msLoginBtnDisabled = false;
        
      });
    if (result) {
      AuthGuardService.authenticated = true;
      this.spinnerService.requestStarted();
      this.apiService.msLogin(result.accessToken).subscribe(res => {
        this.loginSuccess(res,LoginType[LoginType.MicrosoftSSOLogin])
        this.msLoginBtnLabel = "Login"
        this.msLoginBtnDisabled = false;
       
      }, error => {
        this.router.navigate(['/service-unavailable']);
      });
      return result.accessToken
    }
    AuthGuardService.authenticated = false;
    this.router.navigate(['/']);
    return null;
  }
  signOut() {
    localStorage.clear();
    this.logoutSuccess();
  }

  async msSignOut(): Promise<void>{
    await this.msalService.logout();
  }
  private async getUser(): Promise<User> {
    if (AuthGuardService.authenticated) {
      this.loginService.updateLoggedinUserInfo();
      return null
    };
    let graphClient = Client.init({
      authProvider: async (done) => {
        let token = await this.getAccessToken()
          .catch((reason) => {
            done(reason, null);
          });
        if (token) {
          done(null, token);
        } else {
          done("Could not get an access token", null);
        }
      }
    });
    let graphUser: MicrosoftGraph.User = await graphClient
      .api('/me')
      .select('displayName,mail,mailboxSettings,userPrincipalName')
      .get();
    let user = new User();
    user.displayName = graphUser.displayName;
    user.email = graphUser.mail || graphUser.userPrincipalName;
    user.timeZone = graphUser.mailboxSettings.timeZone;
    user.avatar = 'assets/no-profile-photo.png';
    return user;
  }

  async loginSuccess(res,loginType){
    this.loggedInUser = res;
    this.storageService.setItem(StorageService.JWT_TOKEN, res.body.id_token);
    this.storageService.setItem(StorageService.REFRESH_TOKEN, res.body.refresh_token);
    this.storageService.setItem(StorageService.LOGGED_IN_USER_INFO, JSON.stringify(res.body.username));
    this.storageService.setItem(StorageService.LOGIN_TYPE, loginType);
    
    this.loginService.updateLoggedinUserInfo();
    // Note : change this apiservice to RestApiService. By creating Account service and manage all call there.
    // We don't need to send token in param this is old code. Now we have implemented intercepter which will automatically inject token into api header.
    await this.apiService.getUserAccount().subscribe(res => {
      this.userDetails = res;
      this.storageService.setItem(StorageService.USER_INFO,JSON.stringify(res));
      this.userDetailsService.setUserDetails(res);
      this.loginService.updateLoggedinUserInfo();
      this.spinnerService.requestEnded();
      this.router.navigate(['home']);
    })

    await this.userService.fetchEmployeesCount().subscribe(res => {
      this.userService.setEmployeesCount(res.body);
    })

  }

  logoutSuccess(){
    this.user = null;
    AuthGuardService.authenticated = false;
    this.isLoggedIn = false;
    this.loggedInUser = null;
    this.userDetailsService.setUserDetails(undefined);
    this.storageService.setItem(StorageService.IS_LOGGED_OUT, "true");
    this.storageService.removeItem(StorageService.ACCESS_TOKEN);
    this.storageService.removeItem(StorageService.LOGGED_IN_USER_INFO);
    this.storageService.removeItem(StorageService.JWT_TOKEN);
    this.storageService.removeItem(StorageService.REFRESH_TOKEN);
    this.storageService.removeItem(StorageService.USER_INFO);
    this.storageService.removeItem(StorageService.LOGIN_TYPE)
    this.toaster.success("You have been successfully logged out.", "Logout Successful")
    this.router.navigate(['/login']);
  }
  async loginGetToken(loginPass: any) {
    this.logInProcess = true;
    this.badCredentials = false;
      this.apiService.getAuthenticationToken(loginPass).subscribe(res => {  //add error handling logic too
      this.spinnerService.requestStarted()
      AuthGuardService.authenticated = true;
      this.badCredentials = false;
      this.logInProcess = false;
      this.loginSuccess(res,LoginType[LoginType.NormalLogin])
      this.spinnerService.requestEnded()
    },
    error => {
      if (error.status == 401){
        this.badCredentials = true;
        this.logInProcess = false;
      }
    })
  }
}
