import { Injectable } from '@angular/core';
import { RestAPIService } from '../rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class TalentUrlService {

  private readonly talentBaseApi = '/api/project-members';
  private readonly user = '/user';
  private readonly users = '/users';
  private readonly userId_param = 'userId'
  private readonly projectId_param = "projects";
  private readonly projectsId_param = "projectId"
  private readonly validateEmployeeId = '/validateUserId';
  private readonly projectManagerEmails = '/api/project-member-emails';
  constructor(private restApi:RestAPIService) { }


  getBaseUrl(){
    return this.talentBaseApi;
  }

  getUserUrl(userId) {
    return this.talentBaseApi + this.user
      + '?' + this.userId_param + '=' + userId
  }
  getProjectUrl(projectId){
    return this.talentBaseApi +'/'+this.projectId_param+'/'+projectId;
  }
  getUserIdUrl(userId){
    return this.talentBaseApi +this.users+'/'+userId;
  }
  getValidateEmployeeIdUrl(UserId,projectId) {
    return this.talentBaseApi + this.validateEmployeeId + this.getByField(UserId)+this.getByField(projectId)
  }

  getByFieldUrl(field){
    return this.talentBaseApi+ this.getByField(field)
  }

  private getByField(field){
    return '/'+field;
  }
  gettalentUrl(projectId,userId) {
    return this.talentBaseApi + this.user
      + '?' + this.projectsId_param + '=' + projectId+'&'+ this.userId_param + '=' + userId
  }

  getProjectManagerEmailsUrl(){
    return this.projectManagerEmails
  }





}