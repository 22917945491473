import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoalURLsService {

  private readonly goalBaseApi = '/api/goals';
  private readonly user = '/user';

  constructor() { }

  getBaseUrl(){
    return this.goalBaseApi;
  }

  //This will be used for get by id and delete by id
  getByFieldUrl(field){
    return this.goalBaseApi+ this.getByField(field)
  }

  private getByField(field){
    return '/'+field;
  }

  getByParamUrl(params){
    let queryParamStr = '?'
    let i = 0
    Object.keys(params).forEach(function(key) {
      
      queryParamStr += (key + "=" +params[key])
      
      i++
      if (Object.keys(params).length > i)
        queryParamStr+='&'

     
     
    })
    
    return this.goalBaseApi+this.user + queryParamStr
  }
}
