import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FeedbackNotesDTO } from 'src/app/dtos/FeedbackNotesDTO';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { FeedbackNotesService } from 'src/app/services/feedback-notes/feedback-notes.service';
import { NewFeedbackGroupService } from 'src/app/services/new-feedback-group.service';
import { UserService } from 'src/app/services/user/user.service';
import { MainContentProjectComponent } from '../../project/main-content-project/main-content-project.component';
import { CloseFeedbackComponent } from '../profile-detail/close-feedback/close-feedback.component';

@Component({
  selector: 'app-preview-feedback',
  templateUrl: './preview-feedback.component.html',
  styleUrls: ['./preview-feedback.component.scss'],
})
export class PreviewFeedbackComponent implements OnInit {
  feedbackNotes: FormGroup;
  feedbackNotesDto = new FeedbackNotesDTO();
  data: any;
  feedbackClosureNotes;
  feedbackdto: any;
  userDetails = new UserDTO();
  username: string;
  userDto: UserDTO;
  careermanager: string;
  directid: any;
  emailid: string;
  cmid: any;
  cmemail: any;
  constructor(
    public dialogRef: MatDialogRef<MainContentProjectComponent>,
    public fb: FormBuilder,
    public toastr: ToastrService,
    public feedbackNotesService: FeedbackNotesService,
    @Inject(MAT_DIALOG_DATA) public userData: any,
    private dialog: MatDialog,
    private feedbackgroup: NewFeedbackGroupService,
    public userService: UserService
  ) {
    this.feedbackNotes = this.fb.group({
      note: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.setFormData();
    this.getemail();
  }

  getemail() {
    this.userService
      .getUserByID(this.userData.directReporteeId)
      .subscribe((res) => {
        this.emailid = res.body.email;
        this.cmid = res.body.careerManagerId;
        this.userService.getUserByID(this.cmid).subscribe((res) => {
          this.cmemail = res.body.email;
        });
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  setFormData() {
    if (this.userData.isProbation) {
      this.feedbackNotesService
        .getProbationFeedbackSummary(
          this.userData.directReporteeId,
          this.userData.selected
        )
        .subscribe((res) => {
          this.feedbackdto = res.body;
          this.feedbackClosureNotes = this.feedbackdto.feedbackSummary;
        });
    } else {
      this.feedbackNotesService
        .getFeedbackSummary(
          this.userData.directReporteeId,
          this.userData.selected
        )
        .subscribe((res) => {
          this.feedbackdto = res.body;
          this.feedbackClosureNotes = this.feedbackdto.feedbackSummary;
        });
    }
  }

  send() {
    this.dialog.closeAll();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.height = '30em';
    dialogConfig.maxWidth = '100%';
    dialogConfig.panelClass = 'closefeedback';
    dialogConfig.data = {
      directReporteeId: this.userData.directReporteeId,
      selected: this.userData.selected,
      saveAsDraft: false,
      note: this.feedbackClosureNotes,
      isProbation: this.userData.isProbation,
    };

    const dialogRef = this.dialog.open(CloseFeedbackComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.closeDialog();
    });
  }
}
