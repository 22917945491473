import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestAPIService } from '../restAPI/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class AssetManagementService {

  constructor(private restApiService: RestAPIService) { }

  getAssetViewScreenDetails(id) {
    return this.restApiService.get("/api/getAssetViewScreenDetails/" + id);
  }

  getAllAssetListing() {
    return this.restApiService.get("/api/getAllAssetsListing");
  }
  getAssetDetailsForEmployee(employeeId)
  {
    return this.restApiService.get("/api/getAssetDetailsForEmployee/" + employeeId);
    
  }
  getAllAssetIDs() {
    return this.restApiService.get("/api/getAllAssetIDs");
  }

  addAsset(assetDto) {
   
    return this.restApiService.post('/api/addAsset', assetDto);
  }

  updateAsset(assetDto): Observable<any> {
   
    return this.restApiService.put('/api/updateAsset', assetDto);
  }

  revokeAsset(id, comment) {
    let payload = {
      "id": id,
      "comment": comment
    };
   
    return this.restApiService.put('/api/revokeAsset', payload);
  }

  assignAsset(assetDto) {
    return this.restApiService.put('/api/assignAsset', assetDto);
  }

  getAssetDetailsForEmployeeFromUserId(id)
  {
    return this.restApiService.get("/api/getAssetDetailsForEmployeeFromUserId/" + id);
    
  }
  

}
