import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormSchemaDTO } from 'src/app/dtos/FormSchemaDTO';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';
import { EditAppComponent } from '../../edit-app/edit-app.component';

@Component({
  selector: 'app-test-parent',
  templateUrl: './test-parent.component.html',
  styleUrls: ['./test-parent.component.scss'],
})
export class TestParentComponent implements OnInit, OnChanges {
  @Input() public parentDataTest;
  public dialogValue;
  public sendValue;
  public data;
  public source;
  public editData;
  public isPresent: boolean = false;
  id: String;
  newFormSchema = new FormSchemaDTO();

  images = [700, 533, 807, 124].map(
    (n) => `https://picsum.photos/id/${n}/900/500`
  );

  constructor(
    private dialog: MatDialog,
    config: NgbCarouselConfig,
    private _Activatedroute: ActivatedRoute,
    private apiService: ApiService,
    public storageService: StorageService,
    private route: Router
  ) {
    config.interval = 10000;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = false;
  }
  ngOnChanges() {
    this.data = this.parentDataTest;
    this.data = this.parentDataTest;
  }

  ngOnInit(): void {}

  previousState() {
    this.route.navigate(['all-projects']);
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = '90%';
    dialogConfig.height = '80%';
    data: {
      pageValue: this.sendValue;
    }
    let dialogRef = this.dialog.open(EditAppComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.data = result;
        this.isPresent = true;
      }
    });
  }

  editDialog() {
    const editDialogConfig = new MatDialogConfig();
    editDialogConfig.autoFocus = true;
    editDialogConfig.width = '90%';
    editDialogConfig.height = '80%';
    editDialogConfig.data = this.data;
    data: {
      pageValue: this.sendValue;
    }
    let editDialogRef = this.dialog.open(EditAppComponent, editDialogConfig);
    this.editData = editDialogConfig.data;
    this.isPresent = false;

    editDialogRef.afterClosed().subscribe((editResult) => {
      this.isPresent = true;
    });
  }
}
