<div class="container-fluid">
  <div class="main_header_div">
    <div class="child" *ngIf="!isSaveOrSubmit">
      <label class="header_name">Add New Client</label>
    </div>
    <div class="child" *ngIf="isSaveOrSubmit">
      <label class="header_name">Edit Client</label>
    </div>
    <div class="child_close_btn">
      <button class="closeButton" (click)="closeDialog()">Close</button>
    </div>
  </div>
  <hr>

  <div class="row">
    <div class="col-md-12">
      <div class="upload_img_maindiv">
        <figure>
          <div class="upload_img_cation_alignment" (click)="fileInput.click()">
            <img class="upload_img" [(src)]="binaryImgString" *ngIf="photo" />
            <img class="upload_img" src="assets/images/blue_altair_logo.png" alt="" *ngIf="!photo">
            <label class="remove_img_text" *ngIf="this.imageError && !this.photo">{{this.imageError}}</label>
            <figcaption class="upload_text" *ngIf="!photo">Upload Client Logo</figcaption>
            <input type="file" id="my_file" style="display: none;" accept=".jpg,.png"
              (change)="readURL($event.target.files)" #fileInput />
          </div>
          <figcaption class="remove_img_text" *ngIf="photo" (click)="setDefaultImg()">Remove File</figcaption>
        </figure>
      </div>
    </div>
  </div>

  <div class="row form_main_div">
    <div class="col-md-12">
      <form [formGroup]="newClientDetails">
        <div class="row mat-form-field-inputs">
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Client ID</mat-label>
              <input matInput formControlName="clientId" autocomplete="off" style="text-transform:uppercase" />
              <mat-error *ngIf="newClientDetails.get('clientId').hasError('noOnlySpecialCharacters')">Invalid
                characters</mat-error>
              <mat-error *ngIf="checkError('clientId', 'required')">Required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Client Name</mat-label>
              <input matInput formControlName="clientName" autocomplete="off" style="text-transform: capitalize" />
              <mat-error *ngIf="newClientDetails.get('clientName').hasError('noOnlySpecialCharacters')">Invalid
                characters</mat-error>
              <mat-error *ngIf="checkError('clientName', 'required')">Required</mat-error>

            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Client Location</mat-label>
              <input matInput formControlName="clientLocation" autocomplete="off" style="text-transform: capitalize" />
              <mat-error *ngIf="newClientDetails.get('clientLocation').hasError('noOnlySpecialCharacters')">Invalid
                characters</mat-error>

              <mat-error *ngIf="checkError('clientLocation', 'required')">Required</mat-error>

            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Client Start Date<span class="contentRed"></span></mat-label>
              <input matInput [matDatepicker]="picker" placeholder="dd/mm/yyyy" formControlName="clientStartDate" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="checkError('clientStartDate', 'required')">Required</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="outline" class="client_desc_input">
              <mat-label>Client Description</mat-label>
              <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"
                cdkAutosizeMaxRows="15" formControlName="clientDesc"></textarea>
              <mat-error *ngIf="newClientDetails.get('clientDesc').hasError('noOnlySpecialCharacters')">Invalid
                characters</mat-error>
              <mat-error *ngIf="checkError('clientDesc', 'required')">Required</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12" *ngIf="!isSaveOrSubmit">
            <div class="submit_button_main">
              <button (click)="onSubmit()" class="add_client_btn">
                Save
              </button>
            </div>
          </div>
          <div class="col-md-12" *ngIf="isSaveOrSubmit">
            <div class="submit_button_main">
              <button (click)="onSubmit()" class="add_client_btn">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>