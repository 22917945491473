import { Component, Inject, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { LeaveApprovalService } from 'src/app/services/leave-approval-cm/leave-approval.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reject-leave',
  templateUrl: './reject-leave.component.html',
  styleUrls: ['./reject-leave.component.scss'],
})
export class RejectLeaveComponent implements OnInit {
  rejectionDetails: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RejectLeaveComponent>,
    private notificationService: NotificationService,
    public tosterService: ToastrService,
    private leaveApprovalservice: LeaveApprovalService
  ) {
    this.rejectionDetails = new FormGroup({
      reasonForRejection: new FormControl(),
    });
  }

  ngOnInit(): void {
    
  }

  rejectLeave() {
    const reason = this.rejectionDetails.get('reasonForRejection').value;
    const leaveReject = {
      leaveApplicationId: this.data.taskTransactionId,
      approvalStatus:
        this.data.typeOfTask == 'LEAVE_APPROVAL'
          ? 'REJECTED'
          : 'REVOKE_REQUEST_REJECTED',
      reasonForRejection: reason,
    };
    this.leaveApprovalservice.update(leaveReject).subscribe(
      (res) => {
        this.closeDialog();
        this.tosterService.success('Leave Rejected');
      },
      (e) => {
        this.tosterService.error(e.messsage);
        console.log(e.message)
      }
     
    );
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Closed' });
  }
}
