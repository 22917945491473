import { Injectable } from '@angular/core';
import { RestAPIService } from '../restAPI/rest-api.service';
import { WfoUrlService } from '../restAPI/workFromOfficeURLs/wfo-url.service';

@Injectable({
  providedIn: 'root'
})
export class WfoService {

  constructor(
    private restApiService: RestAPIService,
    private wfoUrlService: WfoUrlService
  ) { }

  create(payload) {
    return this.restApiService.post(
      this.wfoUrlService.getBaseUrl(), payload
    )
  }

  getAllHistoryDirectReportee(userId) {
    return this.restApiService.get(
      this.wfoUrlService.getAllHistoryOfReporteeWFO(userId)
    )
  }

  approveWfoByCM(wfoId) {
    const url = `${this.wfoUrlService.approveWfoByCM()}/?id=${wfoId}`;
    return this.restApiService.put(url, null);
  }

  withdrawWfoByUser(id) {
    const url = `${this.wfoUrlService.withdrawWfobyUser()}/?id=${id}`;
    return this.restApiService.delete(url);
  }

  getLastAprrovedUserDetails(userId) {
    return this.restApiService.get(
      this.wfoUrlService.getLastApprovedUserDetails(userId)
    );
  }

  getWFOHistorybyMonthAndYear(userId, month, year) {
    return this.restApiService.get(
      this.wfoUrlService.getWfoHistorybyMonthAndYear(userId, month, year)
    );
  }
}
