<div class="container-fluid">
  <div class="row mt-3">
    <div class="col-md-12">
      <button class="close-button" (click)="matDialogClose()">Close</button>
    </div>
  </div>
  <div class="row main_container">
    <div class="col-md-12">
      <img class="main-card-destop" src="\assets\icons\Image close.png" />
      <h1 class="confirmation_text">
        Are you sure you want to activate this employee?
      </h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="main_button_div">
        <button mat-button class="yes_button" (click)="activateEmployee()">
          Yes
        </button>
        <button mat-button class="no_button ml-3" (click)="matDialogClose()">
          No
        </button>
      </div>
    </div>
  </div>
</div>
