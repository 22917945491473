export class UserCertificateDTO {
    public id : String
     public documentName: string;
     public docBase :string
     public dateOfIssue:Date
     public dateOfValid:Date
     public userId :string
     constructor() {
         this.userId="";
     }
 }     
 