import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user/user.service';
import { LeaveBalanceService } from 'src/app/services/leaveBalances/leave-balance.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { LeaveApplicationDTO } from 'src/app/dtos/LeaveApplicationDTO';
import {
  ApprovalStatus,
  TypeOfLeave,
} from 'src/app/services/leaveManagment/leave-enum';
import { LeaveApplicationService } from 'src/app/services/leave-application/leave-application.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-apply-holiday',
  templateUrl: './apply-holiday.component.html',
  styleUrls: ['./apply-holiday.component.scss'],
})
export class ApplyHolidayComponent implements OnInit {
  public careerManagerEmailId: any;
  public userId: any;
  public currentYear: any;
  leavebalanceData: any;
  restrictedHolidays: number = 0;
  myRemark: any;
  leaveApplicationDTO: LeaveApplicationDTO;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ApplyHolidayComponent>,
    private toaster: ToastrService,
    private userService: UserService,
    private leaveBalanceService: LeaveBalanceService,
    private userDetailsService: UserDetailsService,
    private leaveApplicationService: LeaveApplicationService
  ) { }

  ngOnInit() {
    this.currentYear = new Date(this.data.holidayDate).getFullYear();

    this.userId = this.userDetailsService.getUserDetails().id;
    this.userService
      .getCareerManagerEmailId()
      .subscribe((data) => (this.careerManagerEmailId = data.body.email));

    this.getLeaveBalance();
  }

  onSubmit() {
    this.leaveApplicationDTO = new LeaveApplicationDTO();
    this.leaveApplicationDTO.userId = this.userId;
    this.leaveApplicationDTO.typeOfLeave = "FLOATER_LEAVE"
    this.leaveApplicationDTO.careerManagerId = this.userDetailsService.getUserDetails().careerManagerId
    this.leaveApplicationDTO.approvalStatus = ApprovalStatus.PENDING
    this.leaveApplicationDTO.contactNumber = this.userDetailsService.getUserDetails().contactNumber
    this.leaveApplicationDTO.startDate = this.setFormattedDate(this.data.holidayDate)
    this.leaveApplicationDTO.endDate = this.setFormattedDate(this.data.holidayDate)
    this.leaveApplicationDTO.startSession = 1
    this.leaveApplicationDTO.endSession = 2
    this.leaveApplicationDTO.floaterDescription = this.data.holidayDescription
    this.leaveApplicationDTO.transactionDateTime = new Date().getTime();
    this.leaveApplicationService
      .createRestricted(this.leaveApplicationDTO)
      .subscribe(
        (res) => {
          this.toaster.success('Successfully Applied', 'Saved', {});
          this.dialogRef.close();
        },
        (e) => {
          this.toaster.success(e.error.detail, '', {});
        }
      );
  }

  setFormattedDate(dateString: string): Date {
    const date = new Date(dateString);
    date.setHours(0, 0, 0, 0);
    const timezoneOffset = date.getTimezoneOffset(); // correct time zone
    date.setMinutes(date.getMinutes() - timezoneOffset);
    return date;
  }

  getLeaveBalance() {
    this.leaveBalanceService.getLeaveBalanceByIdAndYear(this.userId, this.currentYear)
      .subscribe(
        (res) => {
          this.leavebalanceData = res.body.balanceLeave;

          this.restrictedHolidays = this.leavebalanceData.FLOATER_LEAVE;
        })
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Closed' });
  }
}
