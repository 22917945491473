export enum AssetLocations {
    PUNE = "Pune",
    BANGALORE = "Bangalore",
}

export enum AssetCategories {
    LAPTOP="Laptop",
    MACBOOK="Macbook",
    IMAC="iMac",
    IPAD="iPad",
    IPHONE="iPhone",
    ANDROID_PHONE="Android Phone",
    WIRELESS_MOUSE="Wireless mouse",
    WIRED_MOUSE="Wired Mouse",
    MONITOR="Monitor",
    WIRELESS_KEYBOARD="Wireless Keyboard",
    WIRED_KEYBOARD="Wired Keboard",
    PRINTER="Printer",
    SHREDDER="Shredder",
    WEB_CAMERA="Web Camera",
    WIRELESS_SPEAKER="Wireless Speaker",
    SIM_CARD="Sim Card",
    TV="TV",
    PROJECTOR="Projector",
    ACCESS_POINT="Access Point",
    ROUTER="Router",
    ACCESS_CONTROL="Access Control",
    CCTV_CAMERA="CCTV Camera",
}

export enum AssetCondition {
    GOOD = "Good",
    AVERAGE = "Average",
    DEAD = "Dead"
}

export enum AssetStatus {
    AVAILABLE = "Available",
    ISSUED = "Issued",
    NOT_AVAILABLE = "Not Available"
}