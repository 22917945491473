import { Injectable } from '@angular/core';
import { RestAPIService } from '../restAPI/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class AddEmployeeProbationService {

  constructor(private restApi: RestAPIService) {}

    getProbationPeriodData(){
      
         return this.restApi.get('/api/getProbationPeriod');
    }
}