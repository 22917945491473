<div class="main-error-container">
  <div class="error-container">
    <div class="error-logo-container">
      <div class="error-logo">
        <img src="assets/images/under.png"/>
      </div>
    </div>
    <div class="error-details-container">
      <div class="error-details">
        <div class="error-name">Under Construction</div>
        <div class="error-bar"></div>
        <div class="error-desc">
          To make things right, we need some time to rebuild. Thank you!
        </div>
      </div>
    </div>
  </div>
</div>
