import { Injectable } from '@angular/core';
import { RestAPIService } from '../rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class LeaveApplicationUrlService {
  private readonly leaveApplicationBaseApi = '/api/leave_application';
  private readonly user = '/user';
  private readonly revoke = '/revoke';
  private readonly create = '/create';
  private readonly userId_param = 'userId';
  private readonly deleteLeave = '/api/leave_application/delete-leave'
  private readonly editLeave = '/api/leave_application/editLeaveApplication';
  private readonly detailLeave='/api/leave_application/getSingleLeaveDetail';
  private readonly leaveStatus = '/api/leave_application/leave-by-status'
  constructor(private restApi:RestAPIService) { }
  
  getApplyLeaveUrl(){
    return this.leaveApplicationBaseApi + this.create;
  }
  updateLeaveApplication(id){
    return this.deleteLeave + '/' + id;
  }
  getAllLeaveByStatus(status){
    return this.leaveStatus + '/' + status;
  }

  getDetailLeave(typeOfLeave,year,userId){
    return this.detailLeave + "/" + typeOfLeave + "/" + year + "/" + userId;
  }

  getApplyLeaveFromTaskUrl(taskId){
    return this.getApplyLeaveUrl() + "/" + taskId;
  }

  getBaseUrl(){
    return this.leaveApplicationBaseApi;
  }
  getLeaveApplicationUrl(){
    return this.leaveApplicationBaseApi+'/'+'create';
  }

  getByWhomUrl(byWhom){
    return this.leaveApplicationBaseApi + "/" + byWhom;
  }

  getAcceptedUrl(leaveApplicationID,approvalStatus,reasonForRejection){
    return this.leaveApplicationBaseApi + "/" + leaveApplicationID + "/" + approvalStatus+ + "/" + reasonForRejection
  }
  getLeaveApplicationByIdUrl(leaveApplicationID){
    return this.leaveApplicationBaseApi + "/" + leaveApplicationID 
  }
  getAllLeaveApplicationByUserId(){
    return this.leaveApplicationBaseApi
  }

  revokeLeaveApplication(){
    return this.leaveApplicationBaseApi + this.revoke;
  }

  editLeaveApplication(leaveApplicationId){
    return this.editLeave + "/" +leaveApplicationId;
  }


}
