<mat-card class="container main-card-task-list">
  <div class="month-year-row">
    <div class="svg-heading-div">
      <div *ngIf="this.moduleName == 'WFO'" class="svg-container wfo-icon-bg">
        <img src="assets/icons/dashboard-icons/wfo-task-icon.svg">
      </div>
      <div *ngIf="this.moduleName == 'Leave Management'" class="svg-container lms-icon-bg">
        <img src="assets/icons/dashboard-icons/blue-calendar.svg">
      </div>

      <div *ngIf="this.moduleName == 'Performance'" class="svg-container performance-icon-bg">
        <img src="assets/icons/dashboard-icons/performance-icon.svg">
      </div>

      <div *ngIf="this.moduleName == 'Profile'" class="svg-container profile-icon-bg">
        <img src="assets/icons/dashboard-icons/pink-profile-icon.svg">

      </div>

      <div class="head ml-3">{{ moduleName }}</div>
    </div>
    <img class="close-icon-svg" (click)="closeDialog()" src="assets/icons/dashboard-icons/close-pink-icon.svg">

  </div>

  <mat-card-content class="task-content" *ngIf="moduleName == 'WFO'">
    <div class="headingOne" *ngIf="(wfoTasksCount$ | async) === 0">
      <div class="no-data-image">
        <img src="assets/images/notask-leave.png">
      </div>
      <div class="no-data-text">No WFO Task.</div>
    </div>
    <div *ngFor="let t of wfoTasks$ | async | orderBy : 't.created_on' : 'asc'">
      <app-task [taskData]="t" [taskStatus]="'pending'"></app-task>
    </div>
  </mat-card-content>

  <mat-card-content class="task-content" *ngIf="moduleName == 'Leave Management'">
    <div class="headingOne" *ngIf="(lmsTasksCount$ | async) === 0">
      <div class="no-data-image">
        <img src="assets/images/notask-leave.png">
      </div>
      <div class="no-data-text">No Leave Management Task.</div>
    </div>
    <div *ngFor="let t of lmsTasks$ | async | orderBy : 't.created_on' : 'asc'">
      <app-task [taskData]="t" [taskStatus]="'pending'" (closeDialogEvent)="closeDialog()"></app-task>
    </div>
  </mat-card-content>

  <mat-card-content class="task-content" *ngIf="moduleName == 'Performance'">
    <div class="headingOne" *ngIf="(performanceTasksCount$ | async) === 0">
      <div class="no-data-image">
        <img src="assets/images/notask-leave.png">
      </div>
      <div class="no-data-text">No Performance Task.</div>
    </div>
    <div *ngFor="
        let t of performanceTasks$ | async | orderBy : 't.created_on' : 'asc'
      ">
      <app-task [taskData]="t" [taskStatus]="'pending'"></app-task>
    </div>
  </mat-card-content>

  <mat-card-content class="task-content" *ngIf="moduleName == 'Profile'">
    <div class="headingOne" *ngIf="(profileTasksCount$ | async) === 0">
      <div class="no-data-image">
        <img src="assets/images/notask-leave.png">
      </div>
      <div class="no-data-text">No Profile Task.</div>
    </div>
    <div *ngFor="let t of profileTasks$ | async | orderBy : 't.created_on' : 'asc'">
      <app-task [taskData]="t" [taskStatus]="'pending'"></app-task>
    </div>
  </mat-card-content>
</mat-card>