import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { parse } from 'path';
import { Activity } from 'src/app/services/activity/activity-enum';
import { ClientService } from 'src/app/services/client/client.service';
import { ImageService } from 'src/app/services/Images/image.service';
import { ProjectService } from 'src/app/services/project.service';
import { TalentService } from 'src/app/services/talent/talent.service';
import { TransferDetailsService } from 'src/app/services/transferDetails/transfer-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { AddProjectCardComponent } from '../add-project-card/add-project-card.component';
import { AddTalentComponent } from './add-talent/add-talent/add-talent.component';
import { PipeUnderScoreToSpace } from 'src/app/services/pipes/pipe-underscore-to-space';
import { of } from 'rxjs';
import { TabNavigationService } from 'src/app/services/tab-navigation/tab-navigation.service';
@Component({
  selector: 'app-project-detail-view',
  templateUrl: './project-detail-view.component.html',
  styleUrls: ['./project-detail-view.component.scss'],
  providers: [PipeUnderScoreToSpace],
})
export class ProjectDetailViewComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private userService: UserService,
    private imageService: ImageService,
    private clientService: ClientService,
    private router: Router,
    private dialog: MatDialog,
    public transferDetails: TransferDetailsService,
    private tabNavigationService: TabNavigationService,
    public talentservice: TalentService
  ) {}
  public projectId;
  public getName = [];
  public flag: any = [];
  public flag1 = true;
  public endDate;
  public projectData;
  public talentData = [];
  public clientData;
  userIdName = {};
  getClient: any = [];
  public managerData;
  public supervisorData;
  public peerData;
  public projectLogo;
  clientName;
  public getScreenWidth: any;
  public getScreenHeight: any;
  mobileview: boolean = false;
  talentImages = {};
  ngOnInit(): void {
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.getproject();
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth > 600) {
      this.mobileview = false;
    }
    if (this.getScreenWidth < 600) {
      this.mobileview = true;
    }
  }
  async getFeedBackByIdAsync(id) {}

  getproject() {
    this.projectService.getProjectById(this.projectId).subscribe((res) => {
      this.projectData = res.body;
      this.supervisorData = res.body.supervisors;
      this.peerData = res.body.users;
      this.clientService
        .getClientByIdSubscribe(res.body.clientId)
        .subscribe((resClient) => {
          this.clientName = resClient.body.clientName;
        });
      this.getTalent();
    });
  }

  ngOnChanges() {
    this.getproject();
    this.getTalent();
  }

  getTalent() {
    this.flag.length = 0;
    let talentlocal = [];
    this.talentservice
      .getTalentByProjectIdSubscribe(this.projectId)
      .subscribe((resptalent) => {
        let j = 0;
        for (let i of resptalent.body) {
          talentlocal.push(i);
          let toDaysDate = new Date();
          let endDateInZoneFormat = new Date(i.endDate);
          if (endDateInZoneFormat < toDaysDate) {
            this.flag.push(false);
          } else {
            this.flag.push(true);
          }

          this.userService.getUserByID(i.userId).subscribe((response) => {
            this.userIdName[i.userId] = response.body.employeeId;
          });
        }
        this.talentData = talentlocal;

        this.getTalentImages(this.talentData);
      });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth > 600) {
      this.mobileview = false;
    }
    if (this.getScreenWidth < 600) {
      this.mobileview = true;
    }
  }

  setProjectLogo(imageId) {
    this.projectLogo = this.imageService.getImage(imageId);
  }

  backToProject() {
    this.router.navigate(['project']);
  }

  onBackButtonClick() {
    // Set the active tab to 'projects'
    this.tabNavigationService.setActiveTab('projects');

    // Navigate back to the main-project-content component with the fragment identifier 'projects'
    this.router.navigate(['/project'], { fragment: 'projects' });
  }

  addTalent(endDate) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = 'auto';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      projectId: this.projectId,
      activityType: Activity.ADD,
      endDate: this.projectData.endDate,
    };
    const dialogRef = this.dialog.open(AddTalentComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.getTalent();
    });
  }

  onEdit(talent) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = 'auto';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      talent: talent,
      projectId: this.projectId,
    };
    const dialogRef = this.dialog.open(AddTalentComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.getTalent();
      this.ngOnInit();
    });
  }

  EditProjectDialog(projectData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = 'auto';
    dialogConfig.height = 'auto';
    dialogConfig.data = projectData;
    const dialogRef = this.dialog.open(AddProjectCardComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.projectService.getProjectPagination();
      this.projectService.getProjects();
    });
  }

  release(talent, i) {
    let date: Date = new Date();
    talent.endDate = date.toISOString();
    let temp = this.projectData.endDate;

    if (talent.endDate < temp) {
      this.flag[i] = false;
      this.talentservice.update(talent).subscribe((res) => {
        this.getTalent();
      });
    }
  }

  getTalentImages(talentData) {
    for (let talent of talentData) {
      this.userService
        .getUserThumbnailFromUserId(talent.userId)
        .subscribe((res) => {
          if (res.body.userImageThumbnail !== '') {
            this.talentImages[talent.userId] =
              'data:image/png;base64,' + res.body.userImageThumbnail;
          } else {
            this.talentImages[talent.userId] = '';
          }
        });
    }
  }
}
