export enum formTypeProject {
    ManagerToSupervisor = 'Manager To Supervisor',
    SupervisorToManager = 'Supervisor To Manager',
    ManagerToPeer = 'Manager To Peer',
    PeerToManager = 'Peer To Manager',
    SupervisorToPeer = 'Supervisor To Peer',
    PeerToSupervisor = 'Peer To Supervisor',
    ClientFeedback = 'Client Feedback',
}

export enum formTypeCareerManager {
    CareerManagerToPeer = "Career Manager To Peer",
    PeerToCareerManager = "Peer To Career Manager"
}

export enum FeedbackTypeEnum {
    CAREER_MANAGER = "Career Manager",
    CLIENT = "Client",
    MANAGER = "Manager",
    SUPERVISOR = "Supervisor",
    PEER = "Peer"
}


// Consider adding new form type
// project feedback form might have form type adding facility