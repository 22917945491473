<div class="container-fluid" id="content">
  <div class="row">
    <div class="col-md-12 add-emo-container">
      <button class="add_Emp_btn" (click)="addEmployeeDialog()">
        <span class="add_em_name">Add New Employee</span>
      </button>
    </div>
    <div class="col-md-12">
      <mat-tab-group
        class="tab-group"
        (selectedTabChange)="onTabChanged($event)"
      >
        <mat-tab class="mat-tab-label-active">
          <ng-template mat-tab-label>
            <mat-icon class="people">people</mat-icon>
            Employees
          </ng-template>
          <div>
            <app-employee-card> </app-employee-card>
          </div>
        </mat-tab>
        <mat-tab class="mat-tab-label-active" *ngIf="hasRoleBased">
          <ng-template mat-tab-label class="tab-2">
            <mat-icon class="unmarked">people</mat-icon>
            Unmarked Employees
          </ng-template>
          <div class="unmarked-employee">
            <app-employee-unmarked></app-employee-unmarked>
          </div>
        </mat-tab>
        <mat-tab class="mat-tab-label-active" *ngIf="hasRoleBased">
          <ng-template mat-tab-label class="tab-3">
            <mat-icon class="onleave">people</mat-icon>
            On Leave
          </ng-template>

          <div>
            <app-employee-onleave></app-employee-onleave>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
