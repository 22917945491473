import { Injectable } from '@angular/core';
import { RestAPIService } from '../restAPI/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class SubmitResignationService {

  constructor(private restApi: RestAPIService) {}

  submitResignation(exitDetailsDTO){
    return this.restApi.post("/api/exit-details", exitDetailsDTO);
  }
}
