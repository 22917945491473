import { Project } from "../models/project";
import { BloodGroup, CustodianType, RelationshipStatus } from "../services/user/user-enum";

export class UserDTO {
    public firstName: String;
    public name:String
    public lastName: String;
    public login: String;
    public email: String;
    public createdDateTime: Date;
    public imageUrl: String;
    public activated: Boolean = false;
    public langKey: String;
    public createdBy: String;
    public createdDate: Date;
    public lastModifiedBy: String;
    public lastModifiedDate: String;
    public authorities: string[];
    public jobTitle: string;
    public roles: any;
    public mobileNumber: String;
    public officeLocation: String;
    public microsoftId: String;
    public project: Project[];
    public id: string;
    public spouseName: string
    public guardianName: string
    public careerManagerId: string;
    public department: string;
    public employeeId: string;
    public joiningDate: Date;
    public fgJoiningDate:Date;
    public about: string;
    public dateOfBirth: Date;
    public bloodGroup: string;
    public relationshipStatus: string;
    public placeOfBirth: string;
    public probationPeriod: number;
    public confirmationDate: Date;
    public level:string;
    public rate:number;
    public fileSource : string;
    public image: String;
    public imageBase : string;
    public gender : string
    public custodianType:string
    public talentBuddyId: string
    public isRelieved: Boolean
    public userImage: string;
    public userImageThumbnail: string;
    public geoLocation: string;
    public baseLocation: string;
    public carrierManagerName:string;
    public employmentType :string;
    public alternateMobileNumber:string;
    public middleName:string;
    constructor(){
        this.employmentType="",
        this.carrierManagerName="";
        this.createdDateTime=null;
        this.firstName = "";
        this.lastName = "";
        this.login = "";
        this.email = "";
        this.imageUrl = "";
        this.name="";
        this.activated = false;
        this.langKey = "";
        this.createdBy = "";
        this.lastModifiedBy = "";
        this.lastModifiedDate = "";
        this.authorities = [];
        this.jobTitle = "";
        this.roles = [];
        this.mobileNumber = "";
        this.microsoftId = "";
        this.careerManagerId = "";
        this.department = "";
        this.joiningDate = new Date();
        this.about = "";
        this.dateOfBirth = undefined
        this.placeOfBirth = "";
        this.guardianName = "";
        this.spouseName ="";
        this.probationPeriod = 0;
        this.confirmationDate = null;
        this.joiningDate = null;
        this.fgJoiningDate = null
        this.rate=0;
        this.confirmationDate = null;
        this.imageBase = null;
        this.about="";
        this.placeOfBirth="";
        this.spouseName=""
        this.gender="";
        this.talentBuddyId="";
        this.isRelieved = false;
        this.userImage = "";
        this.userImageThumbnail= "";
        this.geoLocation ="";
        this.baseLocation ="";
        this.officeLocation ="";
        this.alternateMobileNumber ="";
        this.middleName ="";
    
    }
}