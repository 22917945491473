<div class="row">
  <div class="col-lg-12">
    <mat-card class="main-card1" *ngIf="this.passportdto.length !== 0" [@slideUp]>
      <div class="header main_header_div">
        <div class="ml-3">
          <img class="header-icon" src="assets\icons\passport.png">
          <span class="head ml-3">Passport Details</span>
        </div>
        <div class="mr-3">
          <mat-icon (click)="editPassportDetailsDialog()"><img class="edit_logo" src="assets/icons/edit.png">
          </mat-icon>
        </div>
      </div>

      <table class="table">
        <thead>
          <tr>
            <th class="passport-header">Passport Number</th>
            <th class="passport-header">Valid Till Date</th>
            <th class="passport-header">Country</th>
            <th class="passport-header">Passport Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let p of passportdto" class="address_mat_body" class="row-table">
            <td>
              <img src="assets/images/passimg.png">
              <label class="passport-no-label">{{p.passportNumber}}</label>
            </td>
            <td class="mr-2">
              <label class="mt-1">{{p.expiryDate | date: 'dd-MM-yyyy'}}</label>
            </td>
            <td>
              <label class="mt-1">{{countriesEnum[p.country] }}</label>
            </td>
            <td>
              <label *ngIf="passportstatus[p.id] === 'Active'"
                class="status_color mt-1">{{passportstatus[p.id]}}</label>
              <label *ngIf="passportstatus[p.id] === 'Inactive'"
                class="content-color mt-1">{{passportstatus[p.id]}}</label>
            </td>
          </tr>
        </tbody>
      </table>

    </mat-card>
    <mat-card class="no_data-card" *ngIf="this.passportdto.length == 0" [@slideUp]>
      <div class="main_header_div">
        <div class="ml-3">
          <img class="header-icon" src="assets\icons\passport.png">
          <span class="head ml-3">Passport Details</span>
        </div>
        <div class="mr-3">
          <mat-icon (click)="editPassportDetailsDialog()"><img class="edit_logo" src="assets/icons/edit.png">
          </mat-icon>
        </div>
      </div>
      <div class="no_data_div">
        <img class="img" src="assets/images/no-data-available.png">
        <label class="no_data_label mt-2">No Data Available</label>
      </div>
    </mat-card>
  </div>
  <div class="col-md-12">
    <mat-card class="main-card1 " *ngIf="this.visadto.length !== 0" [@slideUp]>
      <div class="main_header_div">
        <div class="ml-3">
          <img class="header-icon" src="assets\icons\visa.png">
          <span class="head ml-3">Visas Available</span>
        </div>
        <div class="mr-3">
          <mat-icon (click)="editVisaDetails()"><img class="edit_logo" src="assets/icons/edit.png"></mat-icon>
        </div>
      </div>
      <table class="table">
        <thead>
          <th>Visa Type</th>
          <th>Country</th>
          <th>Issue Date</th>
          <th>Valid Till Date</th>
          <th>Visa Status</th>
          <th>Additional Info</th>
        </thead>

        <tbody>
          <tr *ngFor="let v of visadto" class="visa-row">
            <td data-label="VisaType">
              <label class="content_color1">{{ v.visaType }}</label>
            </td>
            <td data-label="Country">
              <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                matTooltip="{{ countriesEnum[v.country]}}" class="content_color2" [matTooltipDisabled]="!ifOverflow(country)" #country>{{
                countriesEnum[v.country]}}</label>
            </td>
            <td data-label="Issue Date">
              <label class="content_color">{{ v.validFrom | date : 'dd-MM-yyyy'}}</label>
            </td>
            <td data-label="Expiration Date">
              <label class="content_color">{{ v.validTo | date : 'dd-MM-yyyy'}}</label>
            </td>
            <td data-label="Visa status">
              <label *ngIf="visastatus[v.id] === 'Active'" class="status_color1 ">{{this.visastatus[v.id]}}</label>
              <label *ngIf="visastatus[v.id] === 'Inactive'" class="content-color">{{this.visastatus[v.id]}}</label>

            </td>
            <td data-label="VisaInfo">
              <label class="content_color1" [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                matTooltip="{{v.additionalInformation}}" class="content_color2" [matTooltipDisabled]="!ifOverflow(info)" #info>

                {{ v.additionalInformation }}</label>
            </td>
          </tr>
        </tbody>
      </table>
    </mat-card>
    <mat-card class="no_data-card" *ngIf="this.visadto.length == 0" [@slideUp]>
      <div class="main_header_div">
        <div class="ml-3">
          <img class="header-icon" src="assets\icons\visa.png">
          <span class="head ml-3">Visas Available</span>
        </div>
        <div class="mr-3">
          <mat-icon (click)="editVisaDetails()"><img class="edit_logo" src="assets/icons/edit.png"></mat-icon>
        </div>
      </div>
      <div class="no_data_div">
        <img class="img" src="assets/images/no-data-available.png">
        <label class="no_data_label mt-2">No Data Available</label>
      </div>
    </mat-card>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <mat-card class="main-card1" *ngIf="this.travelhistorydto.length !== 0" [@slideUp]>
      <div class="main_header_div">
        <div class="ml-3">
          <img class="header-icon" src="assets\icons\travel.png">
          <span class="head ml-3">Travel history with Blue Altair</span>
        </div>
        <div class="mr-3">
          <mat-icon (click)="editBAtravelDialog()"><img class="edit_logo" src="assets/icons/edit.png"></mat-icon>
        </div>
      </div>
      <table class="table">
        <thead>
          <th>From Country</th>
          <th>To Country</th>
          <th>Travel Date</th>
          <th>Reason For Travel</th>
          <th>Additional Info</th>
        </thead>

        <tbody>
          <tr *ngFor="let t of travelhistorydto">
            <td data-label="From Country">

              <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                matTooltip="{{ countriesEnum[t.fromCounry]}}" class="content_color3" [matTooltipDisabled]="!ifOverflow(info1)" #info1>{{
                countriesEnum[t.fromCounry]}}</label>
            </td>
            <td data-label="To Country">
              <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                matTooltip="{{ countriesEnum[t.toCountry]}}" class="content_color4" [matTooltipDisabled]="!ifOverflow(info2)" #info2>{{
                countriesEnum[t.toCountry]}}</label>
            </td>
            <td data-label="Travel Date">
              <label class="content_color">{{ t.travelDate | date : 'dd-MM-yyyy'}}</label>
            </td>
            <td data-label="Reason Of Travel">
              <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                matTooltip="{{ t.reasonOfTravel}}" class="content_color5" [matTooltipDisabled]="!ifOverflow(info3)" #info3>{{ t.reasonOfTravel}}</label>
            </td>
            <td data-label="Additional Info">
              <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                matTooltip="{{t.additionalInfo}}" class="content_color6"[matTooltipDisabled]="!ifOverflow(info4)" #info4>{{t.additionalInfo}}</label>
            </td>
          </tr>
        </tbody>
      </table>
    </mat-card>
    <mat-card class="no_data-card" *ngIf="this.travelhistorydto.length == 0" [@slideUp]>
      <div class="main_header_div">
        <div class="ml-3">
          <img class="header-icon" src="assets\icons\travel.png">
          <span class="head ml-3">Travel history with Blue Altair</span>
        </div>
        <div class="mr-3">
          <mat-icon (click)="editBAtravelDialog()"><img class="edit_logo" src="assets/icons/edit.png"></mat-icon>
        </div>
      </div>
      <div class="no_data_div">
        <img class="img" src="assets/images/no-data-available.png">
        <label class="no_data_label mt-2">No Data Available</label>
      </div>
    </mat-card>
  </div>
</div>