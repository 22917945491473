import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AngularSpinnerService {

  public isLoading:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  constructor() { }

  start(){
    this.isLoading.next(true);
  }

  stop(){
    this.isLoading.next(false);
  }
}
