import { FeedbackSubmitStatus, FeedbackReadStatus, FeedbackRole } from 'src/app/services/feedback/feedback-enum'
import { Feedback } from '../models/feedback';

export class ProbationFeedbackDTO {
    public id:string
    public feedbackFrom:string;
    public formTemplateId:string;
    public feedbackRole:FeedbackRole;
    public feedbackSubmissionStatus:string;
   
    public createdAt: Date;
    public updatedAt: Date;
    public remindedAt: Date;
    public reminderCount: Number;
    constructor() {
        this.feedbackFrom="";
        this.feedbackRole;
        this.feedbackRole=FeedbackRole.CAREER_MANAGER;
       
        this.feedbackSubmissionStatus='CREATED';
       
        this.updatedAt=new Date();
    }
    bind(httpResponse:any){
        this.id = httpResponse && httpResponse.id || undefined;
        this.formTemplateId = httpResponse && httpResponse.formTemplateId || undefined;
        this.feedbackFrom = httpResponse && httpResponse.feedbackFrom || undefined;
       
        this.feedbackSubmissionStatus = httpResponse && httpResponse.status || undefined;
        this.feedbackRole = httpResponse && httpResponse.feedback_role || undefined;
        this.createdAt = httpResponse && new Date(httpResponse.createdAt) || undefined;
        this.updatedAt = httpResponse && new Date(httpResponse.updatedAt) || undefined;
        this.remindedAt = httpResponse && new Date(httpResponse.reminded_at) || undefined;
        this.reminderCount = httpResponse && httpResponse.reminder_count || undefined;
        return this;    
    }
}