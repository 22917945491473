import { Project } from "./project";

export interface ISpecifiInfoUser {
  userID?: any;
  firstName?: string;
  lastName?: string;
  employeeId?:string;
}

export class SpecifiInfoUser {
  about(about: any) {
    throw new Error('Method not implemented.');
  }
  userID: any;
  firstName: string;
  lastName: string;
  employeeId:string;
  constructor(user: ISpecifiInfoUser) {
    if (user && user.userID) {
      this.userID = user.userID;
    }
    this.firstName = user && user.firstName || null;
    this.lastName = user && user.lastName || null;
    this.employeeId = user && user.employeeId || null
    }
  }
