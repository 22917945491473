import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PolicyConfigurationService } from 'src/app/services/leave-policy-configuration/policy-configuration.service';
import { TypeOfLeave } from 'src/app/services/leaveManagment/leave-enum';
import { RefreshService } from 'src/app/services/refreshService/refresh.service';
import { EmployementType, GeoLocation, LeaveType } from 'src/app/services/user/user-enum';

@Component({
  selector: 'app-policy-configure-box',
  templateUrl: './policy-configure-box.component.html',
  styleUrls: ['./policy-configure-box.component.scss']
})
export class PolicyConfigureBoxComponent implements OnInit {
  policyDetails: FormGroup;
  currentClient: string;
  rowAdded: boolean = false;
  projects = [];
  years: number[] = [];
  selectedYear: number = new Date().getFullYear();
  employmentTypes: any = [];
  geoLocations: any = [];
  policyData = [];
  leaveBalanceData = [];
  leaveTypes: any = [];

  constructor(
    public dialogRef: MatDialogRef<PolicyConfigureBoxComponent>,
    public fb: FormBuilder,
    public policyConfigurationService: PolicyConfigurationService,
    public toaster: ToastrService,
    private refreshService: RefreshService
  ) {
    this.policyDetails = this.fb.group({
      geoLocation: ['', Validators.required],
      employmentType: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.processEnumValuesToArray();
  }

  processEnumValuesToArray() {
    this.geoLocations = Object.keys(GeoLocation).filter(key => isNaN(Number(GeoLocation[key])));
    this.employmentTypes = Object.keys(EmployementType).filter(key => isNaN(Number(EmployementType[key])));
    this.leaveTypes = Object.keys(LeaveType).filter(key => isNaN(Number(LeaveType[key])));
  }

  closeDialog() {
    this.dialogRef.close();
  }

  isInvalidControl(controlName: string): boolean {
    const control = this.policyDetails.get(controlName);
    return control.invalid && control.touched;
  }

  addRow(): void {
    if (this.policyDetails.valid) {
      const newRow = {
        leaveType: '',
        numberOfdays: '',
        carryForwardLimit: '',
        encashLimit: ''
      };
      this.leaveBalanceData.push(newRow);
      this.rowAdded = true;
    }
  }

  saveData() {
    const transformedData = {};
    this.leaveBalanceData.forEach(row => {
      const { leaveType, numberOfDays, carryForwardLimit, encashLimit } = row;

      const leaveData = {
        numberOfDays: parseInt(numberOfDays),
        carryForwardLimit: parseInt(carryForwardLimit),
        encashLimit: parseInt(encashLimit),
      };

      transformedData[leaveType] = leaveData;
    });
    if (this.policyDetails.valid) {
      const newPolicy = {
        geoLocation: this.policyDetails.value.geoLocation,
        employmentType: this.policyDetails.value.employmentType,
        leaveConfigurations: transformedData
      };

      this.policyConfigurationService.createPolicy(newPolicy).subscribe((res) => {
        this.toaster.success('Successful', 'New Policy Created', {});
        this.refreshService.triggerRefresh('leave-policy-configuration')
        this.dialogRef.close();
      }, (error) => {
        this.toaster.error('Something went wrong', 'Error', {});
      });
    }
  }

  removeRow(index: number): void {
    this.policyData.splice(index, 1);
    this.updateFormDisabledState();
  }

  updateFormDisabledState(): void {
    if (this.policyData.length === 0) {
      this.policyDetails.enable();
    } else {
      this.policyDetails.disable();
    }
  }

}
