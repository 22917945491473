import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { from } from 'rxjs';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';

import { DynamicFormComponent } from 'src/app/components/dynamic-form/dynamic-form.component';
@Component({
  selector: 'app-probation-json-form',
  templateUrl: './probation-json-form.component.html',
  styleUrls: ['./probation-json-form.component.scss'],
})
export class ProbationJsonFormComponent implements OnInit {
  images = [
    'assets/images/BA-logo.png',
    'assets/images/BA-logo.png',
    'assets/images/BA-logo.png',
  ];
  inc;
  @ViewChild('formAlignment') elementView: ElementRef;
  @ViewChild(DynamicFormComponent, { read: ElementRef })
  elementView1: ElementRef;
  showScrollText = false;
  flag = [];
  answers = new Map<any, any>();
  jsonFormData: any;
  jsonObjectData: any;
  temp: string;
  favoriteSeason: string;
  seasons: string[] = ['Winter', 'Spring', 'Summer', 'Autumn'];
  questionCount = 0;

  @HostListener('scroll', ['$event'])
  scrollMe(event) {}

  public myForm: FormGroup = this.fb.group({});
  len: any;
  isDisabled = true;
  isProbation = false;
  arrayOfAnswer = [];
  probationAnswersData: any = [];
  totalScore = 0;
  numberOfQuestion = 0;
  probationScore = 0;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<any>,
    public feedbackService: FeedbackService,
    private toast: ToastrService,
    private cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public dataActual: any
  ) {
    this.probationScore = this.dataActual[dataActual.length - 1];
    this.jsonObjectData = JSON.parse(this.dataActual[0]);
    let totalData = this.jsonObjectData[0].attributes;
    for (let i = 0; i < totalData.length; i++) {
      if (totalData[i].type == 'radio') {
        let checkValue = totalData[i].values[totalData[i].value - 1].label;
        let currAttribute = {
          label: totalData[i].label,
          answervalue: checkValue,
          description: totalData[i].description,
        };
        this.probationAnswersData.push(currAttribute);
      } else {
        let currAttribute = {
          label: totalData[i].label,
          answervalue: totalData[i].value,
          description: totalData[i].description,
        };
        this.probationAnswersData.push(currAttribute);
      }
    }
    this.inc = 0;
    this.len = this.jsonObjectData.length;
    let i = 0;
    if (this.dataActual[1] != false && this.dataActual[3] != true) {
      for (let temp of this.jsonObjectData) {
        if (temp.hasOwnProperty('attributes')) {
          this.flag.push(true);
          for (let t of temp.attributes) {
            t['value'] = '';
            t['description'] = '';
          }
        }
      }
    }
    this.jsonFormData = this.jsonObjectData;
  }
  onEditPrevious() {
    this.inc--;
  }
  onEditNext() {
    let v = true;
    for (let i of this.jsonFormData[this.inc].attributes) {
      if ((i.value == 1 || i.value == 5) && i.description == '') {
        v = false;
      }
    }
    if (v) {
      let temp = true;
      if (this.inc < this.jsonFormData.length - 1) {
        this.isDisabled = true;
        for (let j of this.jsonFormData[this.inc].attributes) {
          if (j.value === '') {
            temp = false;
          }
        }
        this.flag[this.inc] = temp;
      }
      this.inc++;

      if (this.inc == this.jsonFormData.length - 1) {
        for (let i of this.flag) {
          this.isDisabled = this.isDisabled && i;
        }
      }
    } else {
      this.toast.warning(
        'Some information is missing. Make sure you have filled all the required fields before saving the feedback',
        'warning'
      );
    }
  }

  ngOnInit(): void {
    this.probationAnswersData;
    this.dataActual[6] ? (this.isProbation = true) : (this.isProbation = false);

    this.feedbackService.setCurrentStatusOfIsDisable(false);
    for (let temp of this.jsonFormData) {
      if (temp.hasOwnProperty('attributes')) {
        let temp_array = [];
        this.flag.push(true);
        for (let t of temp.attributes) {
          this.numberOfQuestion++;
          if (t.value != '') {
            temp_array.push(parseInt(t.value));
            if (t.type == 'radio') {
              if (!isNaN(t.value)) {
                this.totalScore += parseInt(t.value);
                this.questionCount++;
              }
            }
          } else {
            temp_array.push(0);
          }
        }
        this.arrayOfAnswer.push(temp_array);
      }
    }
  }

  ngAfterViewChecked(): void {
    this.showScrollText = this.showScroll();
    this.cdRef.detectChanges();
  }

  sendProbationFeedback() {
    this.jsonFormData;
    this.feedbackService
      .submitProbationFeedback(this.dataActual[1], this.jsonFormData)
      .subscribe(
        (res) => {
          this.toast.success('Successfully Submitted', 'Saved');
          this.feedbackService.filter('');
        },
        (error) => {
          this.toast.error(
            'Please ensure all the checkboxes are filled',
            'Error'
          );
        }
      );
    this.dialogRef.close();
  }

  send() {
    this.jsonFormData;
    this.feedbackService
      .submitFeedback(this.dataActual[1], this.jsonFormData)
      .subscribe(
        (res) => {
          this.toast.success('Successfully Submitted', 'Saved');
          this.feedbackService.filter('');
        },
        (error) => {
          this.toast.error(
            'Please ensure all the checkboxes are filled',
            'Error'
          );
        }
      );
    this.dialogRef.close();
  }
  save() {
    this.feedbackService
      .saveFeedback(this.dataActual[1], this.jsonFormData)
      .subscribe((res) => {});
    this.dialogRef.close();
  }

  save1() {
    let v = true;
    for (let i of this.jsonFormData[this.inc].attributes) {
      if ((i.value == 1 || i.value == 5) && i.description == '') {
        v = false;
      }
    }
    if (v) {
      let temp = true;
      if (this.inc < this.jsonFormData.length - 1) {
        this.isDisabled = true;
        for (let j of this.jsonFormData[this.inc].attributes) {
          if (j.value === '') {
            temp = false;
          }
        }
        this.flag[this.inc] = temp;
      }
      this.inc++;

      if (this.inc == this.jsonFormData.length - 1) {
        for (let i of this.flag) {
          this.isDisabled = this.isDisabled && i;
        }
      }
      this.feedbackService
        .saveFeedback(this.dataActual[1], this.jsonFormData)
        .subscribe((res) => {});
    } else {
      this.toast.warning(
        'Some information is missing. Make sure you have filled all the required fields before saving the feedback',
        'warning'
      );
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
  checkError = (controlName: string, errorName: string) => {
    return this.myForm.controls[controlName].hasError(errorName);
  };
  recalculateScore(event) {
    this.arrayOfAnswer[this.inc][event.item] = parseInt(event.score);
    this.totalScore = 0;
    this.arrayOfAnswer.forEach((p) => {
      p.forEach((s) => {
        this.totalScore += s;
      });
    });
  }

  showScroll = () => {
    if (
      this.elementView1 &&
      this.elementView &&
      this.elementView1.nativeElement &&
      this.elementView.nativeElement
    ) {
      let nativeElement = this.elementView.nativeElement,
        nativeElement1 = this.elementView1.nativeElement;
      return (
        nativeElement1.offsetHeight > nativeElement.offsetHeight &&
        nativeElement.offsetHeight + nativeElement.scrollTop <
          nativeElement1.offsetHeight - 10
      );
    }
    return false;
  };

  send1() {
    this.toast.warning(
      'Make sure you have answered all the feedback questions before submitting the form.',
      'warning'
    );
    this.feedbackService.setCurrentStatusOfIsDisable(true);
  }
}
