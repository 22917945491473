import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { ToastrService } from 'ngx-toastr';
import { ResignationServiceService } from 'src/app/services/resignationService/resignation-service.service';

@Component({
  selector: 'app-resignation-accept',
  templateUrl: './resignation-accept.component.html',
  styleUrls: ['./resignation-accept.component.scss'],
})
export class ResignationAcceptComponent implements OnInit {
  noticePeriodDetails: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ResignationAcceptComponent>,
    private resignationService: ResignationServiceService,
    public toastr: ToastrService
  ) {
    this.noticePeriodDetails = new FormGroup({
      noticePeriod: new FormControl(),
    });
  }

  ngOnInit(): void {
  }

  acceptResignation() {
    const noticePeriod = this.noticePeriodDetails.get('noticePeriod').value
      ? this.noticePeriodDetails.get('noticePeriod').value
      : null;

    if (!isNaN(parseInt(noticePeriod, 10))) {
      this.resignationService
        .AcceptRejectResignation(
          true,
          this.data.taskTransactionId,
          parseInt(noticePeriod, 10)
        )
        .subscribe(
          (res) => {
            this.toastr.success('Resignation Accepted', '', {});
            this.closeDialog();
          },
          () => {
            this.toastr.success('Resignation cannot Accept', '', {});
          }
        );
    } else {
      this.toastr.error('Enter valid notice period', '', {});
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Closed' });
  }
}
