import { Component, Inject, Input, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AttendanceRegularizationService } from 'src/app/services/attendance-regularization.service';
import { Task } from 'src/app/models/task';

@Component({
  selector: 'app-rejectregularize',
  templateUrl: './rejectregularize.component.html',
  styleUrls: ['./rejectregularize.component.scss'],
})
export class RejectregularizeComponent implements OnInit {
  @Input() taskData: Task;
  rejectionDetails: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RejectregularizeComponent>,
    private notificationService: NotificationService,
    private attendanceRegularizationService: AttendanceRegularizationService
  ) {
    this.rejectionDetails = new FormGroup({
      reasonForRejection: new FormControl(),
    });
  }

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close({ event: 'Closed' });
  }

  rejectRegularization() {
    const reason = this.rejectionDetails.get('reasonForRejection').value;
    const rejectAttendanceRegDTO = {
      attendanceRegularizationId: this.data.taskTransactionId,
      isAccepted: false,
      reasonForAttendanceRegularizationRejection: reason,
    };
    this.attendanceRegularizationService
      .getAcceptance(rejectAttendanceRegDTO)
      .subscribe(
        (res) => {
          this.closeDialog();
        },
        () => {
          this.closeDialog();
        }
      );
  }
}
