<mat-card class="main-card">
  <div class="row">
    <div class="col-md-12">
      <div class="main-header">
        <div class="svg-heading-div">
          <img
            class="submodule-svg"
            src="assets/icons/dashboard-icons/wfo-icon.svg"
          />
          <div class="wfo-heading">Withdraw</div>
        </div>
        <div>
          <img
            class="close-icon-svg"
            (click)="closeDialog()"
            src="assets/icons/dashboard-icons/close-pink-icon.svg"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="main-body">
        <div class="main-body-content">
          <label>
            Are you sure you want to withdraw this WFO request
          </label>

          <label>
            on
            <span class="blue-text">{{
              this.wfoDetail.startingDate | date : "dd-MM-yyyy"
            }}</span
            >?
          </label>
        </div>

        <div class="button-flex">
          <button class="cancel-btn ml-2" (click)="closeDialog()">
            Cancel
          </button>
          <button class="withdraw-btn mr-2" (click)="withdrawWfoByUser()">
            Withdraw
          </button>
        </div>

      </div>
    </div>
  </div>
</mat-card>
