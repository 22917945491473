import { Injectable } from '@angular/core';
import { FeedbacknotesUrlService } from '../restAPI/feedbackNotesURLs/feedbacknotes-url.service';
import { RestAPIService } from '../restAPI/rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class FeedbackNotesService {
  constructor(private restApiService: RestAPIService,
    private feedbacknotesurl : FeedbacknotesUrlService) { }
    create(payload) {
      return this.restApiService.post(
        this.feedbacknotesurl.getBaseUrl(),
        payload
      )
    }
    createProbationNotes(payload) {
      return this.restApiService.post(
        this.feedbacknotesurl.getProbationBaseUrl(),
        payload
      )
    }
    update(payload){
      return this.restApiService.put(
        this.feedbacknotesurl.updatenote(),
        payload
      )
    }
    updateProbationNotes(payload){
      return this.restApiService.put(
        this.feedbacknotesurl.updateProbationNote(),
        payload
      )
    }
    getnotesbyuserandyear(userid,year){
      return this.restApiService.get(
        this.feedbacknotesurl.getnotesbyuserandyear(userid,year)
      )
    }
    getProbationnotesbyuserandyear(userid,year){
      return this.restApiService.get(
        this.feedbacknotesurl.getProbationnotesbyuserandyear(userid,year)
      )
    }

    getFeedbackSummary(userid, year)
    {
      
      return this.restApiService.get(
        this.feedbacknotesurl.getFeedbackSummary(userid,year)
      )
    }
    getProbationFeedbackSummary(userid, year)
    {
      
      return this.restApiService.get(
        this.feedbacknotesurl.getProbationFeedbackSummary(userid,year)
      )
    }
    
    getProbationNotesByUserandYear(userid,year){
      return this.restApiService.get(
        this.feedbacknotesurl.getProbationnotesbyuserandyear(userid,year)
      )
    }
    getAllYearsFeedbackSummary(userid){
      return this.restApiService.get(
        this.feedbacknotesurl.getAllYearsFeedbackSummary(userid)
      )
    }
  }