import { Injectable } from '@angular/core';
import { LeaveApplicationUrlService } from '../restAPI/CMleaveApplicationsURLs/leave-application-url.service';
import { RestAPIService } from '../restAPI/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class LeaveApplicationsService {

  constructor(
    private restApiService: RestAPIService,
    private leaveApplicationsUrl: LeaveApplicationUrlService
  ) { }

  getCMLeaveApplications(){
    return this.restApiService.get(
      this.leaveApplicationsUrl.getLeaveApplications()
    )
  }
}
