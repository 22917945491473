<div class="reliving-div">
  <div class="main-div" *ngIf="this.status.length == 0">
    <div class="text-div">
      <p>
        Go through our company's exit policy (<a
          href="https://bluealtair.sharepoint.com/:b:/r/sites/BluePediaPnT/People%20and%20Talent%20%20All%20Altairians/India%20-%20All/PnT%20-%20Policies%20-%20All/IBA%20-%20P%26T%20-%20Exit.pdf?csf=1&web=1&e=iHePcm"
          target="_blank"
          >Here</a
        >) before you apply for the resignation
      </p>
    </div>
    <div class="check-div">
      <mat-checkbox (change)="relivingEnable($event)">
        I agree to the company's exit policy
      </mat-checkbox>
    </div>
    <div class="reliving-btn-div">
      <button
        class="reliving-btn"
        [disabled]="disableAgreement"
        (click)="open()"
      >
        <span class="reliving-lbl">Apply For Resignation</span>
      </button>
    </div>
  </div>

  <div *ngIf="status">
    <div *ngFor="let s of status">
      <div class="col-lg-14 col-md-14 col-sm-14">
        <div class="task_card" [ngClass]="taskColor">
          <mat-card>
            <div class="bar"></div>
            <div class="display-flex">
              <div class="main-div-status">
                <label class="exit_checklist_lbl">
                  Employee Knowledge Transfer Plan
                </label>
                <label class="txt"> We need you to do this and stuff </label>
              </div>
              <div class="col-lg-1 col-sm-6">
                <div class="exit-main-btn">
                  {{ s | titlecase }}
                </div>
              </div>
            </div>
          </mat-card>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</div>
