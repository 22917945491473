import { Injectable } from '@angular/core';
import { RestAPIService } from '../rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class CertificationUrlService {
  private readonly certificationBaseApi = '/api/cercertifications';
  private readonly user = '/user';
  private readonly userId_param = 'userId'
  constructor(private restApi:RestAPIService) { }
  getBaseUrl(){
    return this.certificationBaseApi;
  }
  getUserUrl(userId) {
    return this.certificationBaseApi + this.user
      + '?' + this.userId_param + '=' + userId
  }
  getByFieldUrl(field){
    return this.certificationBaseApi+ this.getByField(field)
  }
  private getByField(field){
    return '/'+field;
  }
}
