<div class="main_header_div">
    <div class="child">
        <h1 class="header_name"><img class="header-icon mr-3" src="assets\icons\certification.png">Certifications</h1>
    </div>
    <div class="pr-3 pt-3">
        <img  class="close-icon" (click)="closeDialog($event)" src="assets/icons/close.png">
    </div>
</div>
<hr />
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="add_button_fix">
        <button class="add_button" type="button" (click)="addCertificate()">+Add</button>
        <button class="plus_button mt-4" type="button"(click)="addCertificate()">
          <span>+Add</span>
        </button>
      </div>
      <form [formGroup]="editSpotlightDetails">
        <div class="bodyScroll">
          <div class="row" formArrayName="uploadCertificates"
            *ngFor="let cert of this.Certificate.controls; let i = index">
            <div class="col-md-4" [formGroupName]="i">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Certificate Name</mat-label>
                <input required type="text" matInput formControlName="documentName" autocomplete="off" />
                <mat-error *ngIf="
                    editSpotlightDetails
                      .get('uploadCertificates')
                      ['controls'][i].get('documentName')
                      .hasError('required')
                  ">
                  Required
                </mat-error>
                <mat-error *ngIf="
                    editSpotlightDetails
                      .get('uploadCertificates')
                      ['controls'][i].get('documentName')
                      .hasError('pattern')
                  ">
                  Invalid pattern
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4" [formGroupName]="i">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Issue Date <span class="contentRed"></span></mat-label>
                <input required [max]="today" matInput [matDatepicker]="picker1" placeholder="dd/mm/yyyy"
                  formControlName="dateOfIssue" />
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <mat-error *ngIf="
                    editSpotlightDetails
                      .get('uploadCertificates')
                      ['controls'][i].get('dateOfIssue')
                      .hasError('required') && !editSpotlightDetails
                      .get('uploadCertificates')
                      ['controls'][i].get('dateOfIssue')
                      .hasError('matDatepickerParse')
                  ">
                  Required
                </mat-error>
                <mat-error *ngIf="
                    editSpotlightDetails
                      .get('uploadCertificates')
                      ['controls'][i].get('dateOfIssue')
                      .hasError('matDatepickerParse')
                  ">
                  Invalid Date
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4" [formGroupName]="i">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Valid Upto <span class="contentRed"></span></mat-label>
                <input [min]="getMinEndDate(i)" matInput [matDatepicker]="picker2" placeholder="dd/mm/yyyy"
                  formControlName="validUpto" />
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <mat-error *ngIf="
                    editSpotlightDetails
                      .get('uploadCertificates')
                      ['controls'][i].get('validUpto')
                      .hasError('matDatepickerParse')
                  ">
                  Invalid Date
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12 certificate" [formGroupName]="i">
              <img class="img_size" src="assets/icons/Certificate.png" />
          
              <!-- File input for uploading certificate copy -->
              <label *ngIf="this.multiCertificates[i] == ''" class="certificate_label" for="my_file{{i}}">
                  Attach Certificate Copy
              </label>
              <input type="file" id="my_file{{i}}" style="display: none" [multiple]="true" formControlName="docBase"
                  accept=".png,.jpg,.pdf" (change)="uploadCertificate(i, $event)" />
          
              <!-- Conditional rendering for existing certificate copy -->
              <ng-container *ngIf="multiCertificates[i]!=null && this.multiCertificates[i] != '' && multiCertificates[i] != 'undefined'">
                  <!-- Display if certificate copy exists -->
                  <label class="certificate_label" (click)="open(content10, i)">
                      Preview Certificate Copy
                  </label>
                  <span class="remove-icon" (click)="remove(i)">
                      <img src="assets/svg/delete.svg" />
                  </span>
              </ng-container>
          
              <!-- Display if no certificate copy exists -->
              <ng-container *ngIf="multiCertificates[i]!=null && this.multiCertificates[i] != '' && multiCertificates[i] === 'undefined'">
                  <label class="certificate_label" for="my_file{{i}}">No Certificate Attached</label>
                  <input type="file" id="my_file2{{i}}" style="display: none" [multiple]="true" formControlName="docBase"
                  accept=".png,.jpg,.pdf" (change)="uploadCertificate(i, $event)" />
              </ng-container>
          
              <!-- Modal for previewing certificate copy -->
              <ng-template #content10 let-modal>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                  </button>
                  <div class="modal-body">
                      <pdf-viewer *ngIf="certificateType[i] === 'application/pdf'" [src]="multiCertificates[i]" [rotation]="0"
                          [fit-to-page]="false" [original-size]="false" [show-all]="true">
                      </pdf-viewer>
                      <img *ngIf="certificateType[i] === 'image/png' || certificateType[i] === 'image/jpeg'" class="preview-img"
                          [src]="multiCertificates[i]" />
                  </div>
              </ng-template>
          </div>
          
          
            <div class="col-md-3 mt-2">
              <button class="remove_label" type="button" (click)="removecertificate(cert.value.id, i)">
                Remove
              </button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="submit_button_main">
              <button class="save_Emp_btn" type="button" (click)="onSubmit()">
                <span>Save</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
