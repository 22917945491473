<div class="container mt-2" style="padding: 15px">
  <div style="display: flex; justify-content: space-between">
    <div class="title">
      <h1 *ngIf="this.projectsid.talent == null" class="header_name">
        Add New Talent
      </h1>
      <h1 *ngIf="this.projectsid.talent" class="header_name">Edit Talent</h1>
    </div>
    <div>
      <button (click)="closeDialog()" class="close-button">Close</button>
    </div>
  </div>
  <hr />
  <form [formGroup]="newTalentDetails">
    <div class="row outer-row">
      <div *ngIf="!flag" class="col-md-3">
        <mat-form-field
          appearance="outline"
          class="example-full-width"
          style="width: 100%"
        >
          <mat-label>Talent Name</mat-label>
          <mat-select
            (selectionChange)="getUserID($event.value)"
            formControlName="talentName"
          >
            <input
              class="myInput"
              #myInput
              matInput
              focused="'true'"
              type="text"
              (keyup)="search($event.target.value)"
              autocomplete="off"
              placeholder="Search Name"
            />
            <mat-option *ngFor="let user of this.selectedAreas" [value]="user">
              {{ user }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              this.newTalentDetails.get('talentName').errors?.InvalidEmployeeId
            "
          >
            {{ this.Error }}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="flag" class="col-md-3">
        <mat-form-field
          appearance="outline"
          class="example-full-width"
          style="width: 100%"
        >
          <mat-label>Talent Name</mat-label>
          <mat-select
            formControlName="talentName"
            (selectionChange)="getUserID($event.value)"
          >
            <mat-option *ngFor="let user of this.userList1" [value]="user">
              {{ this.userNameIDMapping1[user] }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              this.newTalentDetails.get('talentName').errors?.InvalidEmployeeId
            "
          >
            {{ this.Error }}
          </mat-error>
          <mat-error *ngIf="checkError">Required</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field
          appearance="outline"
          class="example-full-width"
          style="width: 100%"
        >
          <mat-label>Talent ID<span class="contentRed"></span></mat-label>
          <input
            matInput
            formControlName="employeeId"
            autocomplete="off"
            readonly
          />
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field
          appearance="outline"
          class="example-full-width"
          style="width: 100%"
        >
          <mat-label>Role</mat-label>
          <mat-select formControlName="role">
            <mat-option
              *ngFor="let category of this.projectRoleList"
              [value]="category"
              >{{ category | underScoreToSpace | titlecase }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="checkError">Required</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field
          appearance="outline"
          class="example-full-width"
          style="width: 100%"
        >
          <mat-label>Allocation</mat-label>
          <input matInput formControlName="allocationTime" autocomplete="off" />
          <mat-error *ngIf="checkError">Required</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <mat-form-field
          appearance="outline"
          class="example-full-width"
          style="width: 100%"
        >
          <mat-label>Start Date </mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            placeholder="dd/mm/yyyy"
            formControlName="startDate"
            [max]="this.maxdate"
            [min]="this.mindate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="checkError">Required</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field
          appearance="outline"
          class="example-full-width"
          style="width: 100%"
        >
          <mat-label>End Date</mat-label>
          <input
            matInput
            [matDatepicker]="picker1"
            placeholder="dd/mm/yyyy"
            formControlName="endDate"
            [min]="this.mindate"
            [max]="this.maxdate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <mat-error *ngIf="checkError">Required</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div style="display: flex; justify-content: flex-end">
      <button mat-raised-button class="submit-btn" (click)="save()">
        Save
      </button>
    </div>
  </form>
</div>
