import { KeyValuePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AngularSpinnerComponent } from 'src/app/components/angular-spinner/angular-spinner.component';
import { Goal } from 'src/app/models/goal';
import { AngularSpinnerService } from '../angular-spinner.service';
import { GoalStatus, GoalType, GoalTypeLabel } from '../goalSheet/goal-sheet-enum';
import { GoalURLsService } from '../restAPI/goal/goal-urls.service';
import { RestAPIService } from '../restAPI/rest-api.service';
import { SpinnerService } from '../spinner.service';



@Injectable({
  providedIn: 'root'
})
export class GoalService {
  


  public categoryGoalData = new Map()

  public overallProgressData = {
    label: GoalTypeLabel.OverallProgress,
    goalStatusStateCount: new Map(),
    year: "",
    lastUpdated: "",
    totalGoalProgress: 0,
    totalSubgoalCount: 0,
    progress: 0
  }


  public goalTypeProgess = new Map()

  private totalSubgoalCount = 0;
  private totalSubgoalProgress = 0;
  public subGoalsData = {
    "BuildingTheBusinessIP": this.getSubGoalDataObject(GoalTypeLabel.BuildingTheBusinessIp),
    "ClientRelations": this.getSubGoalDataObject(GoalTypeLabel.ClientRelations),
    "Communication": this.getSubGoalDataObject(GoalTypeLabel.Communication),
    "CoreValues": this.getSubGoalDataObject(GoalTypeLabel.CoreValues),
    "TeamWorkAndCollaboration": this.getSubGoalDataObject(GoalTypeLabel.TeamWorkAndCollaboration),
    "TechnicalCapability": this.getSubGoalDataObject(GoalTypeLabel.TechnicalCapability),
    "TimeManagement": this.getSubGoalDataObject(GoalTypeLabel.TimeManagement),
    "Compliance": this.getSubGoalDataObject(GoalTypeLabel.Compliance),
  }
  public goalCategoryCount = 7
  public goalList = new Map()
  public buildingTheBusinessIpSubgoal: Goal[];
  public goalsList: Goal[]
  public goalStatusState = new Map()

  constructor(private restApiService: RestAPIService,
    private angularSpinnerService:AngularSpinnerService,
    private goalsUrlService: GoalURLsService) {

    for (let item in GoalType) {
      if (isNaN(Number(item))) {
        if (this.goalTypeProgess.get(item) == undefined) {
          this.goalTypeProgess.set(item, [0, 0])
        }
      }
    }

    for (let item in GoalStatus) {
      if (isNaN(Number(item))) {
        if (this.overallProgressData.goalStatusStateCount.get(item) == undefined) {
          this.overallProgressData.goalStatusStateCount.set(item, 0)
        }
      }
    }
  }


  setGoals(goals) {
    //List of all the subgoal for a user
    for (let goal of goals) {

      //add subgoal to goalList for frontend Rendering
      this.subGoalsData[goal.goalType].subGoalList.push(new Goal(goal))
      
      let lastUpdated = this.subGoalsData[goal.goalType].lastUpdated
      
      this.subGoalsData[goal.goalType].lastUpdated = this.setLastUpdated(goal.lastUpdated,this.subGoalsData[goal.goalType].lastUpdated) 
      lastUpdated = this.subGoalsData[goal.goalType].lastUpdated
      this.subGoalsData[goal.goalType].totalSubgoalProgress += goal.currentValue
      this.subGoalsData[goal.goalType].progress = this.calculateGoalTypeProgress(goal.goalType)

      this.overallProgressData.totalSubgoalCount += 1
      this.overallProgressData.totalGoalProgress += this.subGoalsData[goal.goalType].progress 
      this.overallProgressData.goalStatusStateCount.set(goal.goalStatus, 1 + this.overallProgressData.goalStatusStateCount.get(goal.goalStatus))


    }
    this.calculateOverGoalProgress()
  }

  private setLastUpdated(dateTime,systemStoredLastUpdated){

    if(systemStoredLastUpdated == '')
      return dateTime

    let dateTimeMilli = new Date(dateTime).getTime()
    let systemStoredLastUpdatedMilli = new Date(systemStoredLastUpdated).getTime()
    if( new Date(dateTime).getTime() > new Date(systemStoredLastUpdated).getTime() ){
      return dateTime
    }
    else 
      return systemStoredLastUpdated


  }

  calculateOverGoalProgress() {
    this.overallProgressData.progress = 0
    for(let goal in this.subGoalsData){
      this.overallProgressData.progress += this.subGoalsData[goal].progress
    }
    this.overallProgressData.progress = Math.round(this.overallProgressData.progress / Object.keys(this.subGoalsData).length)
  }

  calculateGoalTypeProgress(goalType) {
    let progress = this.subGoalsData[goalType].totalSubgoalProgress
    let count = this.subGoalsData[goalType].subGoalList.length
    return Math.round(progress / count)

  }

  setGoalServiceSelf(goalType,userId,year) {
    
    this.getGoalByGoalTypeUserIdAndYearSubscribe(goalType, userId, year).subscribe(res => {
      this.setGoals(res.body);
      this.angularSpinnerService.stop()
    });
  }

  getGoalsByCategory(category: string) {
  }


  getGoalByGoalTypeUserIdAndYearSubscribe(goalType, userId, year) {
    this.angularSpinnerService.start()
    return this.restApiService.get(
      this.goalsUrlService.getByParamUrl(
        {
          "goalType": goalType,
          "userId": userId
        }
      )
    )
  }


  getAllSubGoals() {
    this.restApiService.get(
      this.goalsUrlService.getBaseUrl()
    ).subscribe(res => {
      this.setGoals(res.body)
    })
  }
  getAllSubGoalsSubscribe() {
    return this.restApiService.get(
      this.goalsUrlService.getBaseUrl()
    )
  }

  postSubGoal(payload) {
    return this.restApiService.post(
      this.goalsUrlService.getBaseUrl(),
      payload
    )
  }

  updateSubGoal(payload) {
    return this.restApiService.put(
      this.goalsUrlService.getBaseUrl(),
      payload
    )
  }

  resetGoalTypeProgrees() {
    [...this.goalTypeProgess.keys()].forEach((key) => {
      this.goalTypeProgess.set(key, [0, 0])
    })
  }

  resetGoalStatusState() {
    [...this.goalStatusState.keys()].forEach((key) => {
      this.goalStatusState.set(key, 0)
    })
  }

  private getSubGoalDataObject(goalLabel = "") {

    return {
      label: goalLabel,
      progress: 0,
      totalSubgoalProgress: 0,
      lastUpdated: "",
      subGoalList: []
    }
  }

  resetSubGoalData(goalType) {
   this.subGoalsData[goalType].progress = 0
   this.subGoalsData[goalType].totalSubgoalProgress = 0
   this.subGoalsData[goalType].subGoalList = []
   

  }

  //reset overAllProgress by goal Type
  resetOverAllProgress(goalType) {
    for(let goal of this.subGoalsData[goalType].subGoalList){
      if(this.overallProgressData.goalStatusStateCount.get(goal.goalStatus)>0){
        let updatedCount = this.overallProgressData.goalStatusStateCount.get(goal.goalStatus) - 1
        this.overallProgressData.goalStatusStateCount.set(goal.goalStatus,updatedCount)
      }
    }
    this.overallProgressData.progress = 0
    this.overallProgressData.totalSubgoalCount = 0
    this.overallProgressData.totalGoalProgress = 0
  }


  //reset overAllProgress Completely
  resetGoalService() {
  
    //reset Over All progress
    this.resetOverallProgressGoalStatusStateCount()
    this.overallProgressData.progress = 0
    this.overallProgressData.totalSubgoalCount = 0
    this.overallProgressData.totalGoalProgress = 0
    this.overallProgressData.year = ""
    this.overallProgressData.lastUpdated = ""
  
    //reset sub goal Data
    for(let subGoal in this.subGoalsData){
      this.subGoalsData[subGoal] = this.getSubGoalDataObject(this.subGoalsData[subGoal].label)
    }
  }

  private resetOverallProgressGoalStatusStateCount() {
    for (let item in GoalStatus) {
      if (isNaN(Number(item))) {
        this.overallProgressData.goalStatusStateCount.set(item, 0)
      }
    }
  }

  
}

