import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LeaveBalanceService } from 'src/app/services/leaveBalances/leave-balance.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { LeaveType, TransactionType } from 'src/app/services/user/user-enum';
import { LeaveBalanceHistoryComponent } from './leave-balance-history/leave-balance-history.component';
import { Transaction } from 'src/app/models/transaction';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { UserService } from 'src/app/services/user/user.service';
import { LeaveTypeDTO } from 'src/app/dtos/LeaveTypeDTO ';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-leave-balance', templateUrl: './leave-balance.component.html', styleUrls: ['./leave-balance.component.scss']
})

export class LeaveBalanceComponent implements OnInit {
  years: number[] = [];
  selectedYear = new Date().getFullYear();
  currentDate: Date = new Date();
  leaveTypes: LeaveTypeDTO[] = [];
  carryForwardLeave = 0;
  userDetail: UserDTO;
  allEmployee: UserDTO[] = [];
  filterEmployee: UserDTO[] = [];
  isCM = false;

  constructor(private userDetailService: UserDetailsService, private leaveBalanceService: LeaveBalanceService, private dialog: MatDialog, private userService: UserService, private toaster: ToastrService) {

  }

  ngOnInit(): void {
    this.initiateComponent();
  }

  initiateComponent() {
    const startYear = new Date().getFullYear() - 2;
    for (let year = this.selectedYear; year >= startYear; year--) {
      this.years.push(year);
    }
    this.userDetail = this.userDetailService.getUserDetails();
    this.createLeaveTypeArray();
    this.getLeaveBalance(this.userDetail.id, new Date().getFullYear());
    this.isCM = this.userDetailService.hasRole(['ROLE_CAREER_MANAGER']);
    if (this.isCM) {
      this.getReporteesData();
    }
  }

  getReporteesData() {
    this.userService.getDirectReportee(this.userDetail.id).subscribe(res => {
      res.body.forEach(element => {
        if (element.userImageThumbnail !== '') {
          element.userImageThumbnail = 'data:image/png;base64,' + element.userImageThumbnail;
        }
      });
      this.allEmployee = [this.userDetail, ...res.body];
      this.filterEmployee = this.allEmployee;
    });
  }

  createLeaveTypeArray(): void {
    this.carryForwardLeave = 0;
    this.leaveTypes = Object.values(LeaveType)
      .filter(type => type !== LeaveType.ACCRUED_LEAVE)
      .map(type => {
        const leaveType: any = {
          name: type,
          totalBalance: 0,
          openingBalance: 0,
          availableBalance: 0,
          availed: 0,
          history: [],
          iconSrc: `/assets/calendar-icon/${type.toLowerCase().replace(/ /g, '-').replace(/_/g, '-')}-calendar.jpg`
        };
        if (type === LeaveType.PLANNED_LEAVE) {
          leaveType.accruedBalance = 0;
        }
        return leaveType;
      });
  }

  getLeaveBalance(userId, year) {
    this.leaveBalanceService.getLeaveBalanceByIdAndYear(userId, year).subscribe(res => {
      const LeaveHistory = this.segrigateLeaveHistory(res.body.transactionHistory);
      this.carryForwardLeave = res.body.carryForwardLeaves;
      this.setBalanceAndHistory(res.body.balanceLeave, LeaveHistory);
    }, err => {
      this.toaster.error(err);
      this.createLeaveTypeArray();
    });
  }

  segrigateLeaveHistory (leavesHistory) {
    const leaveTypes = Object.values(LeaveType);
    const segregatedHistory: { [key: string]: any[] } = {};

    for (const leaveType of leaveTypes) {
        segregatedHistory[leaveType] = [];
    }

    for (const transaction of leavesHistory) {
        const leaveType = transaction.leaveType;
        segregatedHistory[leaveType].push(transaction);
    }

    return segregatedHistory;
}



  setBalanceAndHistory(leaveBalanceData, leaveHistory) {
    for (const key in leaveBalanceData) {
      const leaveTypeIndex = this.leaveTypes.findIndex(leave => leave.name === key);
      if (leaveTypeIndex !== -1) {
        this.leaveTypes[leaveTypeIndex].availableBalance = leaveBalanceData[key];
        this.leaveTypes[leaveTypeIndex].history = leaveHistory[key];
        this.leaveTypes[leaveTypeIndex].totalBalance = this.getTotalBalance(leaveHistory[key]);
        this.leaveTypes[leaveTypeIndex].openingBalance = this.getOpeningBalance(leaveHistory[key]);
        if (key === LeaveType.PLANNED_LEAVE) {
          this.leaveTypes[leaveTypeIndex].openingBalance += this.carryForwardLeave;
          this.leaveTypes[leaveTypeIndex].totalBalance += this.carryForwardLeave;
        }
        this.leaveTypes[leaveTypeIndex].availed = this.calculateAvailedBalance(this.leaveTypes[leaveTypeIndex].totalBalance, this.leaveTypes[leaveTypeIndex].availableBalance);
      } else {
        if (key === LeaveType.ACCRUED_LEAVE) {
          const plannedLeaveIndex = this.leaveTypes.findIndex(leave => leave.name === LeaveType.PLANNED_LEAVE);
          if (plannedLeaveIndex !== -1) {
            this.leaveTypes[plannedLeaveIndex].accruedBalance = leaveBalanceData[key];
            this.leaveTypes[plannedLeaveIndex].availed -= leaveBalanceData[key];
          }
        }
      }
    }
  }

  getTotalBalance(leaveHistory?: Transaction[]): number {
    const filteredTransactions: Transaction[] = (leaveHistory ?? []).filter((transaction: Transaction) => {
      return (transaction.transactionPersonType === TransactionType.SYSTEM || transaction.transactionPersonType === TransactionType.HR) && (transaction.transactionType === TransactionType.CREDIT || transaction.transactionType === TransactionType.DEBIT || transaction.transactionType === TransactionType.HR_CREDIT);
    });

    const totalBalance = filteredTransactions.reduce((acc: number, transaction: Transaction) => {
      return acc + transaction.transactionCount;
    }, 0);

    return totalBalance;
  }


  getOpeningBalance(leaveHistory?: Transaction[]): number {
    let openingBalance = 0;
    for (const transaction of leaveHistory) {
      if ((transaction.transactionPersonType === TransactionType.SYSTEM) &&
        (transaction.transactionType === TransactionType.CREDIT)) {
        openingBalance += transaction.transactionCount;
      }
    }

    return openingBalance;
  }


  calculateAvailedBalance(totalBalance, AvailableBal) {
    return Math.abs(totalBalance - AvailableBal);
  }

  leaveHistoryDialog(leaveDetail) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '100%';
    dialogConfig.height = '50%';
    dialogConfig.data = leaveDetail;
    const dialogRef = this.dialog.open(LeaveBalanceHistoryComponent, dialogConfig);
  }

  onYearChange() {
    this.getLeaveBalance(this.userDetail.id, this.selectedYear);
  }

  getCurrentLapseDate(): string {
    return this.currentDate.getMonth() <= 5 ? 'June-30' : 'December-31';
  }

  searchEmployee(employee) {
    this.filterEmployee = this.allEmployee.filter(emp => (emp.firstName.toLowerCase().includes(employee.toLowerCase()) || emp.lastName.toLowerCase().includes(employee.toLowerCase())));
  }

  selectEmployee(employee) {
    this.userDetail = employee.value;
    this.createLeaveTypeArray(); //to clear leave type array
    this.getLeaveBalance(this.userDetail.id, this.selectedYear);
  }
}
