import { AttendanceStatus } from "../services/attendance-extra/attendance-enum";
// Define interface attendance
export interface IAttendance{
    attendanceDateTime?:Date;
    attendanceMarkedByUserDateTime?:Date;
    attendanceStatus?:string;
    createdDateTime?:Date;
    id?:string;
    latestAttendanceSessionId?:string;
    regularizedByUserId?:string;
    userId?:String;
}

//Define Class for attendace
export class Attendance{
    attendanceDateTime:Date;
    attendanceMarkedByUserDateTime:Date;
    attendanceStatus:string;
    createdDateTime:Date;
    id:string;
    latestAttendanceSessionId:string;
    regularizedByUserId:string;
    userId:String;
    constructor(attendance?:IAttendance){
        if(attendance && attendance.id){
            this.id= attendance.id;
        }
        this.attendanceDateTime= attendance && attendance.attendanceDateTime || null;
        this.attendanceMarkedByUserDateTime = attendance && attendance.attendanceMarkedByUserDateTime || null;
        this.attendanceStatus = attendance && attendance.attendanceStatus || null;
        this.createdDateTime = attendance && attendance.createdDateTime || null;
        this.latestAttendanceSessionId = attendance && attendance.latestAttendanceSessionId || null;
        this.regularizedByUserId = attendance && attendance.regularizedByUserId || null;
        this.userId = attendance && attendance.userId || null;
    }
 createPayload(attendaceDate:Date,attendaceStatus:AttendanceStatus,userId:String=''){
    
    this.attendanceDateTime= attendaceDate
    this.attendanceMarkedByUserDateTime = attendaceDate
    this.attendanceStatus = attendaceStatus;
    this.createdDateTime = new Date()
    this.latestAttendanceSessionId =  null;
    this.regularizedByUserId =null;
    this.userId = userId

    return this;
 }

}
