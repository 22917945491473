import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user/user.service';
import { LeaveApplicationDTO } from 'src/app/dtos/LeaveApplicationDTO';
import { LeaveApplicationService } from 'src/app/services/leave-application/leave-application.service';
import { FirstnSecondHalf, TypeOfLeave } from 'src/app/services/leaveManagment/leave-enum';
import { ToastrService } from 'ngx-toastr';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { MatSelectChange } from '@angular/material/select';
import { LeaveBalanceService } from 'src/app/services/leaveBalances/leave-balance.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TalentService } from 'src/app/services/talent/talent.service';
import { LeaveWarning } from './leave-warning/leave-warning';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { TabNavigationService } from 'src/app/services/tab-navigation/tab-navigation.service';

@Component({
  selector: 'app-apply-newleave',
  templateUrl: './apply-newleave.component.html',
  styleUrls: ['./apply-newleave.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplyNewleaveComponent implements OnInit {
  newLeaveDetails: FormGroup;
  userId: any;
  public careerManagerEmailId: any;
  leavetypes = [];
  firstsecondhalf = [];
  allEmails: any = [];
  filteredAllEmails: string[];
  selectedEmailOptions: string[] = [];
  filteredUsers: Observable<User[]>;
  allUsers: any[] = [];
  selectedUser: any;
  userdata: User;
  searchText = '';
  userControl: FormControl = new FormControl();
  userImageThumbnail: string;
  allProjectManagerEmails: string[] = [];
  filteredPMEmails: string[];
  leaveapplication = new LeaveApplicationDTO;
  leaveBalance = new Number;
  typedLADays = new Number;
  accuredBalance: any;
  maxDateOfYear: Date;
  minDateofYear: Date;
  currentYear: number;
  dayAString: string;
  dayBString: string;
  dayCString: string;
  checkEndDate: boolean;
  checkLBandLAerror: boolean;
  showAccuredBalance: boolean = false;
  errorMessage: string;
  submitButtonDisabled: boolean = false;
  loadingflag: number = 4; // 4 async functions are running in ngoninit and 1 for all other process
  public isAttachmentSaved: boolean;
  attachmentBase64: string;
  imageError: string;
  attachmentname: string;
  attachmentnameExtension: string;
  binary;
  existingLeave = new LeaveApplicationDTO();
  carrerManagerName: any;
  carrerManagerImage: any;
  isExistingLeave: any;
  isDisableInput: boolean = false;
  attachmentType: string;
  leavebalanceData: any;
  carrerManagerId: any;
  result: string[] = [];
  userNameIDMapping = {};
  userIdNameMapping: { [email: string]: any } = {};
  applyLeaveLabel = 'Apply';

  data: any = null;

  constructor(public leaveapplicationservice: LeaveApplicationService, public userService: UserService, public fb: FormBuilder, public toaster: ToastrService, public userDetailService: UserDetailsService, private leaveBalanceService: LeaveBalanceService, private talentService: TalentService, private modalService: NgbModal, private cd: ChangeDetectorRef, private dialog: MatDialog, private route: ActivatedRoute, private tabNavigationService: TabNavigationService) {
    this.newLeaveDetails = this.fb.group({
      typeOfLeave: ['', [Validators.required]],
      careerManagerId: ['', [Validators.required]],
      projectManagerId: ['', [Validators.required]],
      startDate: [, [Validators.required]],
      endDate: [, [Validators.required]],
      startSession: ['', [Validators.required]],
      endSession: ['', [Validators.required]],
      ccMentions: [[]],
      reasonForLeave: [''],
    });
  }

  ngOnInit() {
    const typeOfLeave = this.route.snapshot.queryParams['typeOfLeave'];
    if (typeOfLeave === 'PLANNED_LEAVE') {
      this.newLeaveDetails.get('typeOfLeave').setValue('Planned Leave');
      this.showAccuredBalance = true;
      this.newLeaveDetails.get('typeOfLeave').setValue('Planned Leave');
      this.showAccuredBalance = true;
    } else if (typeOfLeave === 'UNPLANNED_LEAVE') {
      this.newLeaveDetails.get('typeOfLeave').setValue('Unplanned Leave');
      this.maxDateOfYear = new Date();
    }
    this.allUsers = [];
    this.leaveBalance = null;
    this.isAttachmentSaved = false;
    this.typedLADays = null;
    this.userId = this.userDetailService.getUserDetails().id;
    this.currentYear = new Date().getFullYear();
    this.minDateofYear = new Date(this.currentYear, 0, 1);
    this.maxDateOfYear = new Date(this.currentYear, 11, 31);
    this.checkLBandLAerror = null;
    this.errorMessage = "";
    this.getCareerManagerEmailId();
    this.getAllEmpEmail();
    this.getAllPMEmail();
    this.getusers();
    this.getAllLeaveBalances(this.currentYear);
    const sdate = this.setFormattedDate(this.route.snapshot.queryParams['date']);
    const edate = this.setFormattedDate(this.route.snapshot.queryParams['date']);
    this.newLeaveDetails.get('startDate').setValue(sdate);
    this.newLeaveDetails.get('endDate').setValue(edate);
    this.newLeaveDetails.get('startSession').setValue('First Half');
    this.newLeaveDetails.get('endSession').setValue('Second Half');
    this.setApplyingDays();

  }

  setFormattedDate(dateString: string): Date {
    const date = new Date(dateString);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  getusers() {
    this.userService.getAllUserForSearchEmployee().subscribe((res) => {
      for (let user of res.body) {
        let userImageThumbnail = '';
        if (user?.userImageThumbnail !== '') {
          userImageThumbnail = 'data:image/png;base64,' + user.userImageThumbnail;
        }
        let userDetail = {
          employeeId: user?.employeeId,
          firstName: user?.firstName,
          lastName: user?.lastName,
          userID: user?.userID,
          userImageThumbnail: userImageThumbnail
        }
        this.allUsers.push(userDetail);
        this.userIdNameMapping[user.mailId] = userDetail;
        this.filteredUsers = this.userControl.valueChanges.pipe(
          startWith(''),
          map((value) => this.filterUsers(value))
        );
      }
    });

  }

  filterUsers(value: string): User[] {
    const filterValue = value.toLowerCase().trim();
    if (this.selectedUser) {
      return this.allUsers.filter((user) => user.firstName.toLowerCase().includes(filterValue) && user !== this.selectedUser);
    } else {
      return this.allUsers;
    }
  }

  getDocumentType(temp): string {
    const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];
    for (let type of allowed_types) {
      if (temp.indexOf(type) != -1) {
        return type;
      }
    }
  }

  getEnumValueByEnumKey(myEnum, enumKey: string) {
    return myEnum[enumKey];
  }

  getCareerManagerEmailId() {
    this.userService.getCareerManagerEmailId().subscribe((data) => {
      this.careerManagerEmailId = data.body.email;
      this.carrerManagerName = data.body.firstName + ' ' + data.body.lastName;
      this.carrerManagerId = data.body.employeeId;
      this.carrerManagerImage = data.body.userImageThumbnail ? 'data:image/png;base64,' + data.body.userImageThumbnail : 'assets/icons/default-avatar.jpg';
      this.loadingflag--;
      this.cd.detectChanges();
    }, (e) => {
      this.toaster.warning('Career Manager details are not available for user! please contact Admin');
    });
  }

  processEnumValuesToArray() {
    this.leavetypes.splice(0, this.leavetypes.length);
    for (let leaveType of Object.values(TypeOfLeave)) {

      if ((leaveType === "Floater Leave" || leaveType === "Accured leave")) {
        // don't push in drop down as it will not applied from here
      }
      else {
        if (isNaN(Number(leaveType))) this.leavetypes.push(leaveType);
      }
    }
    for (let firstnsecondhalf of Object.values(FirstnSecondHalf)) {
      if (isNaN(Number(firstnsecondhalf))) {
        this.firstsecondhalf.push(firstnsecondhalf);
      }
    }
  }

  onAddCCSelectionChange(event: MatSelectChange) {
    this.selectedEmailOptions = [...event.value]; // Only keep newly selected emails
    this.updateFilteredEmails();
  }

  searchAllEmails(query: string) {
    const searchResults = this.allEmails.filter(email => email.toLowerCase().includes(query.toLowerCase()));

    // Combine search results and previously selected emails, ensuring uniqueness
    const allSelectedEmails = [...new Set([...this.selectedEmailOptions, ...searchResults]),];
    this.filteredAllEmails = allSelectedEmails;

    // Maintain the order with selected emails at the top
    this.filteredAllEmails.sort((a, b) => {
      const isSelectedA = this.selectedEmailOptions.includes(a);
      const isSelectedB = this.selectedEmailOptions.includes(b);

      if (isSelectedA && !isSelectedB) {
        return -1; // Move selected email 'a' to the beginning
      } else if (!isSelectedA && isSelectedB) {
        return 1; // Move selected email 'b' to the beginning
      } else {
        return 0; // Maintain the order for unselected emails
      }
    });
  }

  updateFilteredEmails() {
    this.searchAllEmails(''); // Reapply filtering and sorting
  }

  searchAllPMEmails(query: string) {
    let result: string[] = [];
    for (let a of this.allProjectManagerEmails) {
      if (a.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        result.push(a);
      }
    }
    this.filteredPMEmails = result;
  }

  getEnumKeyByEnumValue(myEnum, enumValue) {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }

  getAllEmpEmail() {
    this.userService.getAllUsersMailId().subscribe((res) => {
      this.allEmails = res.body;
      this.filteredAllEmails = this.allEmails;
      this.loadingflag--;
      this.cd.detectChanges();
    });

  }

  getAllPMEmail() {
    this.talentService.getProjectManagerEmails().subscribe((res) => {
      this.allProjectManagerEmails = res.body;
      this.allProjectManagerEmails.unshift('NA');
      this.allProjectManagerEmails = this.allProjectManagerEmails.filter((value, index, self) => self.indexOf(value) === index);

      this.filteredPMEmails = this.allProjectManagerEmails;
      this.loadingflag--;
      this.cd.detectChanges();
    });
  }

  onStartDateChange(date: Date) {
    this.setLeaveBalance();
    if (this.newLeaveDetails.get('endDate').value) {
      this.setApplyingDays();
    }
  }

  checkLBandLAerrorFcn() {
    const endSessionControl = this.newLeaveDetails.get('endSession');
    const endDateControl = this.newLeaveDetails.get('endDate');
    const startDate1 = this.newLeaveDetails.get('startDate').value;
    const endDate1 = this.newLeaveDetails.get('endDate').value;
    const startSession1 = (this.newLeaveDetails.get('startSession').value == 'First Half') ? 1 : 0.5;
    const endSession1 = (this.newLeaveDetails.get('endSession').value == 'First Half') ? 0.5 : 1;
    const leaveType1 = (this.newLeaveDetails.get('typeOfLeave').value);
    if (leaveType1 == "Planned Leave" && this.typedLADays != null && this.accuredBalance != null &&
      this.accuredBalance < this.typedLADays) {
      this.checkLBandLAerror = true;
      endDateControl.addValidators([Validators.pattern('[@]$')]);
      this.errorMessage = 'Not enough Leave Balance';
      this.submitButtonDisabled = true;
    }
    else if (this.leaveBalance != null &&
      this.typedLADays != null &&
      this.leaveBalance < this.typedLADays && leaveType1 !== "Loss Of Pay"
    ) {
      this.checkLBandLAerror = true;
      endDateControl.addValidators([Validators.pattern('[@]$')]);
      this.errorMessage = 'Not enough Leave Balance';
      this.submitButtonDisabled = true;
    } else if (startDate1 != null && endDate1 != null && (startDate1 > endDate1 || (new Date(startDate1).toDateString() === new Date(endDate1).toDateString() && startSession1 == 0.5 && endSession1 == 0.5))) {
      endDateControl.addValidators([Validators.pattern('[@]$')]);
      this.errorMessage = 'Start date should be less than end date';
      this.submitButtonDisabled = true;
    } else if (this.typedLADays == 0) {
      endDateControl.addValidators([Validators.pattern('[@]$')]);
      this.errorMessage = 'Applying leave for zero days';
      this.submitButtonDisabled = true;
    } else if (this.typedLADays == 50) {
      endDateControl.addValidators([Validators.pattern('[@]$')]);
      this.errorMessage = 'Leave applying days limit exceeds';
      this.submitButtonDisabled = true;
      this.typedLADays = null;
      this.dayAString = null;
      this.cd.detectChanges();
    } else {
      this.checkLBandLAerror = false;
      endSessionControl.clearValidators();
      endSessionControl.setValidators([Validators.required]);
      endDateControl.clearValidators();
      endDateControl.setValidators([Validators.required]);
      this.submitButtonDisabled = false;
    }

    endSessionControl.updateValueAndValidity();
    endDateControl.updateValueAndValidity();
  }

  getAllLeaveBalances(year) {
    this.leaveBalanceService
      .getLeaveBalanceByIdAndYear(this.userId, year)
      .subscribe(
        (res) => {
          this.leavebalanceData = res.body;
          this.processEnumValuesToArray();
          this.loadingflag--;
          this.cd.detectChanges();
        },
        (err) => {
          this.leaveBalance = null;
        }
      );
  }

  onLeaveTypeChange() {
    this.setLeaveBalance();
    const selectedLeaveType = this.newLeaveDetails.get('typeOfLeave').value;
    if (selectedLeaveType == 'Planned Leave') {
      this.showAccuredBalance = true;
    } else {
      this.showAccuredBalance = false;
    }

    if ((selectedLeaveType === 'Contingency Leave') && (this.leavebalanceData.balanceLeave.CONTINGENCY_LEAVE != 0) && (this.leavebalanceData.balanceLeave.PLANNED_LEAVE != 0 || this.leavebalanceData.balanceLeave.UNPLANNED_LEAVE != 0)) {
      this.openPnTDialog(selectedLeaveType, 'Please avail your Planned/Unplanned leaves first.');
    }
    else if ((selectedLeaveType === 'Contingency Leave' || selectedLeaveType === 'Comp Off' || selectedLeaveType === 'Special Leave' || selectedLeaveType === 'Maternity Leave' || selectedLeaveType === 'Paternity Leave') && this.leaveBalance == 0) {
      this.openPnTDialog(selectedLeaveType, 'Please reach out to the PnT Team to avail this kind of leave.');
    } else {
      if (selectedLeaveType === 'Unplanned Leave') {
        this.maxDateOfYear = new Date();
      } else {
        this.maxDateOfYear = new Date(this.currentYear, 11, 31);
      }
      this.setLeaveBalance();
    }
  }

  openPnTDialog(selectedLeaveType, warning) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '40%';
    dialogConfig.width = '50%';
    dialogConfig.data = [selectedLeaveType, warning];
    dialogConfig.panelClass = 'manage-class';
    const dialogRef = this.dialog
      .open(LeaveWarning, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        this.newLeaveDetails.patchValue({
          typeOfLeave: '',
        });
      });
  }



  setLeaveBalance() {
    let typeOfLeaveValue, typeOfLeaveKey;
    typeOfLeaveValue = this.newLeaveDetails.get('typeOfLeave').value;
    if (typeOfLeaveValue) {
      typeOfLeaveKey = this.getEnumKeyByEnumValue(TypeOfLeave, typeOfLeaveValue);
      if (this.leavebalanceData.balanceLeave[typeOfLeaveKey] == null) {
        this.leaveBalance = 0;
        this.cd.detectChanges();
      }
      else if (typeOfLeaveValue == "Planned Leave") {
        this.leaveBalance = this.leavebalanceData.balanceLeave[typeOfLeaveKey];
        this.accuredBalance = this.leavebalanceData.balanceLeave.ACCRUED_LEAVE;
        this.dayCString = this.leaveBalance == 1 ? 'Day' : 'Days';
        this.cd.detectChanges();
      } else {
        this.leaveBalance = this.leavebalanceData.balanceLeave[typeOfLeaveKey];
        this.cd.detectChanges();


      }
      this.dayBString = this.leaveBalance == 1 ? 'Day' : 'Days';
      this.checkLBandLAerrorFcn();
    }
  }

  onSessionChange() {

    if (this.newLeaveDetails.get('endDate').value && this.newLeaveDetails.get('startDate').value) {
      this.setApplyingDays();
    }
  }

  setApplyingDays() {
    let startDate1 = new Date(this.newLeaveDetails.get('startDate').value); // as date zone issue : previous date was saved in backend
    let endDate1 = new Date(this.newLeaveDetails.get('endDate').value);
    let startSession1 = this.newLeaveDetails.get('startSession').value == 'First Half' ? 1 : 0.5; // for calculation
    let endSession1 = this.newLeaveDetails.get('endSession').value == 'First Half' ? 0.5 : 1;
    let startSession = this.newLeaveDetails.get('startSession').value == 'First Half' ? 1 : 2; //to send to backend
    let endSession = this.newLeaveDetails.get('endSession').value == 'First Half' ? 1 : 2;

    // add condition is sd is more than ed
    if (startDate1 && endDate1) {
      // by default startSession and endSession is set so not added condition
      if (startDate1 > endDate1 || (new Date(startDate1).toDateString() === new Date(endDate1).toDateString() && startSession1 === 0.5 && endSession1 === 0.5)) {
        this.typedLADays = null;
        this.dayAString = "0 Days";
        this.checkLBandLAerrorFcn();
        this.cd.detectChanges();
      } else {
        let tempLeaveDto = new LeaveApplicationDTO();
        tempLeaveDto.startDate = this.correctZoneOffset(startDate1);
        tempLeaveDto.endDate = this.correctZoneOffset(endDate1);
        tempLeaveDto.startSession = startSession;
        tempLeaveDto.endSession = endSession;
        tempLeaveDto.userId = this.userDetailService.getUserDetails().id;
        this.leaveapplicationservice.getLeaveTotalCount(tempLeaveDto).subscribe((res) => {
          this.typedLADays = res.body;
          this.cd.detectChanges();
          if (this.typedLADays == 50) { //hard coded days if apply for more than a month in backend

            this.checkLBandLAerrorFcn();
          } else {
            // to remove error
            this.dayAString = this.typedLADays == 1 ? 'Day' : 'Days';
            this.checkLBandLAerrorFcn();
          }
        },
          (error: any) => { }
        );
      }
    }
  }

  //leave balance

  uploadAttachment(fileInput: any) {
    this.imageError = null;
    const maxFileSize = 2 * 1024 * 1024;
    const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf'];
    if (fileInput.target.files && fileInput.target.files[0]) {
      const file = fileInput.target.files[0];
      if (file.size > maxFileSize) {
        this.imageError = 'File size exceeds the limit of 2MB';
        return;
      }
      if (!allowedTypes.includes(file.type)) {
        this.imageError = 'Invalid file type';
        return;
      }


      this.attachmentname = fileInput.target.files[0].name;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const imgBase64Path: String = e.target.result;
        let imageContent = imgBase64Path.split(',')[1];
        let imageMetaData = imgBase64Path.split(',')[0];
        this.isAttachmentSaved = true;
        this.cd.detectChanges();
        this.attachmentBase64 = imageMetaData + ',' + imageContent;
        this.binary = btoa(this.attachmentBase64);
      };
      // to read extension
      this.attachmentnameExtension = this.attachmentname.split('.').pop();
      if (this.attachmentnameExtension == 'pdf') {
        this.attachmentType = 'application/pdf';
      } else {
        this.attachmentType = this.attachmentnameExtension;
      }
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  removeAttachment() {
    this.isAttachmentSaved = false;
    this.attachmentBase64 = null;
    this.binary = null;
    this.cd.detectChanges();
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => { },
        (reason) => { }
      );
  }

  correctZoneOffset(date: Date) {
    const timezoneOffset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() - timezoneOffset);
    return date;
  }

  onSubmit() {
    Object.values(this.newLeaveDetails.controls).forEach((control) => {
      control.markAsTouched();
    });
    const sDate = this.newLeaveDetails.get('startDate').value;
    const eDate = this.newLeaveDetails.get('endDate').value;
    const leaveType = this.newLeaveDetails.get('typeOfLeave').value;

    if (!leaveType) {
      this.toaster.error('Please select leave type', 'Error', {});
      return;
    }

    if (!sDate || !eDate) {
      this.toaster.error('Please select both start date and end date', 'Error', {});
      return;
    }
    this.applyLeaveLabel = 'Please Wait...';
    this.submitButtonDisabled = true;
    let currentLeaveDto = new LeaveApplicationDTO();
    currentLeaveDto.userId = this.userDetailService.getUserDetails().id;
    currentLeaveDto.typeOfLeave = this.getEnumKeyByEnumValue(TypeOfLeave, this.newLeaveDetails.get('typeOfLeave').value);
    currentLeaveDto.careerManagerId = this.userDetailService.getUserDetails().careerManagerId;
    var startDate = new Date(this.newLeaveDetails.get('startDate').value);
    var endDate = new Date(this.newLeaveDetails.get('endDate').value);
    currentLeaveDto.startDate = this.correctZoneOffset(startDate);
    currentLeaveDto.endDate = this.correctZoneOffset(endDate);
    currentLeaveDto.startSession = this.newLeaveDetails.get('startSession').value == 'First Half' ? 1 : 2;
    currentLeaveDto.endSession = this.newLeaveDetails.get('endSession').value == 'First Half' ? 1 : 2;
    currentLeaveDto.ccMentions = this.newLeaveDetails.get('ccMentions').value;
    currentLeaveDto.reasonForLeave = this.newLeaveDetails.get('reasonForLeave').value;
    currentLeaveDto.contactNumber = this.userDetailService.getUserDetails().mobileNumber;
    currentLeaveDto.transactionDateTime = new Date().getTime();
    currentLeaveDto.doc_base = this.binary;
    if (
      currentLeaveDto.userId != undefined &&
      currentLeaveDto.typeOfLeave != undefined &&
      currentLeaveDto.careerManagerId != undefined &&
      currentLeaveDto.projectManagerId != '' &&
      currentLeaveDto.startDate != undefined &&
      currentLeaveDto.endDate != undefined &&
      currentLeaveDto.startSession != undefined &&
      currentLeaveDto.endSession != undefined &&
      currentLeaveDto.transactionDateTime != undefined

    ) {
      {
        this.leaveapplicationservice
          .create(
            currentLeaveDto,
            this.data && this.data.taskId ? this.data.taskId : null
          )
          .subscribe(
            (res) => {
              this.leaveBalance = null;
              this.accuredBalance = null;
              this.typedLADays = null;
              this.toaster.success('Successfully Applied', 'Saved', {});
              this.newLeaveDetails.reset();
              this.setButtonState();
              Object.keys(this.newLeaveDetails.controls).forEach(
                (controlName) => {
                  this.newLeaveDetails.get(controlName).setErrors(null);
                  this.newLeaveDetails.get(controlName).clearValidators();
                  this.removeAttachment();
                }
              );
            },
            (error) => {
              this.setButtonState();
              if (error.status == 409) {
                this.toaster.error(
                  'Leave already applied for this date',
                  'Error',
                  {}
                );
              } else {
                this.toaster.error(
                  error.message,
                  'Error',
                  {}
                );
              }
            }
          );
      }
    }

  }
  setButtonState() {
    this.submitButtonDisabled = false;
    this.applyLeaveLabel = 'Apply';
    this.cd.detectChanges();
  }
}
