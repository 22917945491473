import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AddressDetails } from 'src/app/models/addressdetails';
import { AddressDetailsUrlService } from '../restAPI/addressDetailsURLs/addressdetails-url.services';
import { RestAPIService } from '../restAPI/rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class AddressDetailsService {
addressdetails:AddressDetails[]=[];
shareDataSubject = new BehaviorSubject(0);
shareData1=this.shareDataSubject.asObservable(); //Decalring new RxJs Subject
   getaddressByUserIdSubscribe(userId){
    return this.restApiService.get(
    this.addressurl.getUserUrll(userId))
    }
    setaddressResponse(response):AddressDetails[]{
        let addressList:AddressDetails[] = []
        for(let data of response){
            let address:AddressDetails = new AddressDetails()
            addressList.push(
             address.bind(data)          
            )
        }
        return addressList
      }
  constructor(private restApiService: RestAPIService,
   private addressurl: AddressDetailsUrlService
    ) { }
  postdocument(payload) {
    return this.restApiService.post(
      this.addressurl.getBaseUrl(),
      payload
    )
  }
  putdocument(payload) {
    return this.restApiService.put(
      this.addressurl.getBaseUrl(),
      payload
    )
  }
  getaddressbyuserId(category,userId){
    return this.restApiService.get(
      this.addressurl.getUserUrl(category,userId)
    )
  }
  getIndex(index){
 this.shareDataSubject.next(index);
}
}
