import { Injectable } from "@angular/core";
import {AttandanceRegularisationUrlService} from "src/app/services/restAPI/regularize/attandance-regularisation-url.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { RestAPIService } from "./restAPI/rest-api.service";



@Injectable({
    providedIn: 'root'
  })

  export class AttendanceRegularizationService{

    constructor(public restApiService: RestAPIService,public attendenceRegularisation:AttandanceRegularisationUrlService,private http:HttpClient){}

    getAcceptance(payload){
       
        return this.restApiService.put(this.attendenceRegularisation.getAcceptRejectUrl(),payload)
      
    }

    updateAttendanceForRegularization(taskId){
      return this.restApiService.put(this.attendenceRegularisation.getRegularizaDatesUrl(),taskId);
    }

  }