import { Injectable } from '@angular/core';
import { merge, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Feedback } from '../models/feedback';
import { ApiService } from './api.service';
import { FeedbackgroupUrlService } from './restAPI/feedbackGroupURLs/feedbackgroup-url.service';
import { RestAPIService } from './restAPI/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  feedbacks = [];

  constructor(private restApiservice: RestAPIService, private apiService: ApiService, private feedbackgroupUrlService: FeedbackgroupUrlService) { }
  questions = [
    "He/She actively participates in team discussions and meetings.",
    "He/She is open to receiving constructive feedback.",
    "He/She communicates in a clear, effective, timely, concise, and organized manner, both verbally and written.",
    "He/She is an active listener.",
    "He/She exhibits our core values of passion, integrity, openness, respect, diligence, empowerment, and adaptability.",
    "He/She builds effective working / personal relationships.",
    "He/She promotes synergy within the team.",
    "He/She follows and abides by the company culture and inspires the same amongst others.",
    "He/She manages the expectations of team members appropriately and proactively communicates any potential issues.",
    "He/She is willing to help out when needed and offers assistance to others.",
  ];

  getFeedbacks() {
    this.apiService.getFeedbacks().subscribe(res => {
      this.setFeedbacks(res);
    })
  }

  setFeedbacks(feedbacks) {
    this.feedbacks = [];
    for (let feedback of feedbacks) {
      this.feedbacks.push(new Feedback(feedback));
    }
  }
  submitFeedback(id, payload) {
    return this.restApiservice.put(
      this.feedbackgroupUrlService.submitFeedback(id), payload)
  }
  saveFeedback(id, payload) {
    return this.restApiservice.put(
      this.feedbackgroupUrlService.saveFeedback(id), payload)
  }
  getFeedbackById(id): Observable<any> {
    
    return this.restApiservice.get(
      this.feedbackgroupUrlService.getFeedbackUrl(id)
    )
  }
  getFeedbackSummery(id){
    return this.restApiservice.get(
      this.feedbackgroupUrlService.getFeedbackSummeryUrl(id)
    )
  }
  getFeedbackGroupById(id): Observable<any> {
    return this.restApiservice.get(
      this.feedbackgroupUrlService.getFeedbackGroupById(id)
    )
  }
  feedbackSummerySubmit(feedbackId,payload) {
    return this.restApiservice.put(
      this.feedbackgroupUrlService.feedbackSummerySubmit(feedbackId),
      payload
    )
  }

}