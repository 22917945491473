interface ISkill {
    primarySkills: string[];
    secondarySkills: string[];
    userId: string
    id:string
}
export class Skill {
    public primarySkills: string[];
    public secondarySkills: string[];
    public  userId: String
    public id :string
    
    constructor(){
        this.id = undefined
        this.primarySkills = undefined
        this.secondarySkills = undefined
        this.userId = undefined
    }
    bind(httpResponse:any){
        this.id = httpResponse && httpResponse.id || undefined
        this.primarySkills = httpResponse && httpResponse.primarySkills || undefined
        this.secondarySkills = httpResponse && httpResponse.secondarySkills || undefined
        this.userId = httpResponse && httpResponse.userId || undefined
        return this;     
    }
}