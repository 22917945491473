<div class="main-error-container">
  <app-error-header></app-error-header>
  <div class="error-container">
    <div class="error-logo-container">
      <div class="error-logo">
        <img src="assets/images/service.png"/>
      </div>
    </div>
    <div class="error-details-container">
      <div class="error-details">
        <div class="error-name">Error 503: Service Temporarily Unavailable</div>
        <div class="error-bar"></div>
        <div class="error-desc">
          We're currently in our maintenance window to improve your experience.
          Our site will be back shortly. Thank you for your patience.
        </div>
        <a href="/login"><button class="error-white-btn">Back to Log in</button></a>
      </div>
    </div>
  </div>
</div>
