<mat-card class="main-card">
  <div class="row">
    <div class="col-md-12">
      <div class="main-header">
        <div class="svg-heading-div">
          <img class="submodule-svg" src="assets/icons/dashboard-icons/anniversary-icon.svg" />
          <div class="head">Work Anniversaries</div>
        </div>
        <div class="date-color">{{ selectedMonth }}-{{ selectedYear }}</div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <!--  anniversary with empty data -->
      <div class="table-fix-head-empty" *ngIf="!Anniversarylist || Anniversarylist.length == 0">
        <div class="heading-one">
          <div class="no-data-image">
            <img src="assets/icons/dashboard-icons/white-anniversary-icon.svg" />
          </div>
          <div class="no-data-text">No Anniversary this month.</div>
        </div>
      </div>

      <!--  anniversary with data -->
      <div class="table-fixed" *ngIf="Anniversarylist && Anniversarylist.length != 0">
        <div *ngFor="let user of Anniversarylist">
          <div class="single-reportee-detail-container">
              <div class="employee-name">{{ user.firstName | titlecase}}&nbsp;{{ user.lastName | titlecase}}</div>
              <div [style.color]="user.isToday ? '#0036AC' : 'black'" class="anniversary-date">
                {{user.message}}
              </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</mat-card>