<div class="main_header_div">
    <div class="child">
        <h1 class="header_name "><img class="header-icon mr-3" src="assets\icons\visa.png">Visa Details</h1>
    </div>
    <div class="pr-3 pt-3">
        <img class="close-icon" (click)="closeDialog($event)" src="assets/icons/close.png">   
    </div>
</div>
<hr />
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 form_main_div">
      <div class="add_button_fix">
        <button class="add_button" (click)="addVisa()">+Add</button>
        <button class="plus_button mt-4" (click)="addVisa()">
          <span>+Add</span>
        </button>
      </div>
      <form [formGroup]="editBusinesstravel">
        <div class="bodyScroll">
          <div formArrayName="visa" *ngFor="let visass of this.Visa().controls; let i = index">
            <div class="row">
              <div class="col-md-3" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>Visa Type <span class="contentRed"></span></mat-label>
                  <input matInput formControlName="visaType" autocomplete="off" />
                  <mat-error *ngIf="
                      editBusinesstravel
                        .get(['visa', i, 'visaType'])
                        .hasError('noOnlySpecialCharacters')
                    ">Invalid characters</mat-error>
                  <mat-error *ngIf="visass.get('visaType').errors?.required">Required</mat-error>
                  <mat-error *ngIf="visass.get('visaType').errors?.pattern">Invalid Visa type</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>Country<span class="contentRed"></span></mat-label>
                  <mat-select Required formControlName="country">
                    <mat-option *ngFor="let option of countries" [value]="option">{{ option }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="visass.get('country').errors?.required">Required</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>Issue Date <span class="contentRed"></span></mat-label>
                  <input matInput [matDatepicker]="picker" placeholder="dd/mm/yyyy" formControlName="validFrom" />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error *ngIf="visass.get('validFrom').errors?.required && !visass.get('validFrom').errors?.matDatepickerParse">Required</mat-error>
                  <mat-error *ngIf="visass.get('validFrom').errors?.matDatepickerParse">Invalid Date</mat-error>

                </mat-form-field>
              </div>
            </div>
            <div class="row margin-bottom">
              <div class="col-md-3" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>Valid Till Date <span class="contentRed"></span></mat-label>
                  <input matInput [min]="
                      editBusinesstravel.controls.visa.controls[i].controls
                        .validFrom.value
                    " [matDatepicker]="picker2" placeholder="dd/mm/yyyy" formControlName="validTo" [disabled]="
                      !editBusinesstravel.controls.visa.controls[i].controls
                        .validFrom.value
                    " />
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                  <mat-error *ngIf="visass.get('validTo').errors?.required && !visass.get('validTo').errors?.matDatepickerParse">Required</mat-error>
                  <mat-error *ngIf="visass.get('validTo').errors?.matDatepickerParse">Invalid Date</mat-error>

                </mat-form-field>
              </div>
              <div class="col-md-4 additional-info-input" [formGroupName]="i">
                <mat-form-field appearance="outline">
                  <mat-label>Additional Info</mat-label>
                  <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" class="additionalInfo"
                    cdkAutosizeMinRows="3" cdkAutosizeMaxRows="15" placeholder="Additional Info" maxlength="250"
                    formControlName="additionalInformation"></textarea>
                  <mat-hint>
                    <label *ngIf="!visass.get('additionalInformation').value">
                      Remaining characters: 250 / 250
                    </label>
                    <label *ngIf="visass.get('additionalInformation').value">
                      Remaining characters:
                      {{
                      250 - visass.get("additionalInformation").value.length
                      }}
                      / 250
                    </label>
                  </mat-hint>
                  <mat-error *ngIf="
                      editBusinesstravel
                        .get(['visa', i, 'additionalInformation'])
                        .hasError('noOnlySpecialCharacters')
                    ">Invalid characters</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <button (click)="removevisa(visass.value.id, i)" class="visa_remove_label">
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="submit_button_main">
              <button class="save_Emp_btn" type="submit" (click)="onSubmit()">
                <span>Save</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>