import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GeoLocation } from 'src/app/services/user/user-enum';
import { BulkLeaveConfigureComponent } from '../bulk-leave-configure/bulk-leave-configure.component';
import { PolicyConfigureBoxComponent } from '../policy-configure-box/policy-configure-box.component';
import { PolicyConfigurationService } from 'src/app/services/leave-policy-configuration/policy-configuration.service';
import { RefreshService } from 'src/app/services/refreshService/refresh.service';
import { DeletePolicyComponent } from './delete-policy/delete-policy.component';

@Component({
  selector: 'app-leave-policy-config',
  templateUrl: './leave-policy-config.component.html',
  styleUrls: ['./leave-policy-config.component.scss']
})

export class LeavePolicyConfigComponent implements OnInit {
  selectedYear: number;
  years: number[] = [];
  page: number = 1;
  searchTerm: string = '';
  geoLocations: any = [];
  policyList: any = [];
  filteredPolicyList: any = [];
  geoLocationSearch: Array<string> = [];

  constructor(
    private dialog: MatDialog,
    public policyConfigurationService: PolicyConfigurationService,
    private refreshService: RefreshService
  ) { }

  ngOnInit(): void {
    this.processEnumValuesToArray();
    this.getYearList();
    this.refreshComponent();
    this.initiateComponent();
  }

  refreshComponent() {
    this.refreshService.refresh$.subscribe((componentName) => {
      if (componentName === 'leave-policy-configuration') {
        this.initiateComponent();
      }
    });
  }

  initiateComponent() {
    this.policyConfigurationService.getAllPolicy().subscribe((res) => {
      this.policyList = res.body;
      this.policyList.forEach(policy => {
        policy.leaveConfigurations = Object.keys(policy.leaveConfigurations).map(key => {
          return {
            leaveType: key,
            numberOfDays: policy.leaveConfigurations[key].numberOfDays,
            carryForwardLimit: policy.leaveConfigurations[key].carryForwardLimit,
            encashLimit: policy.leaveConfigurations[key].encashLimit
          };
        });
      })
      this.filteredPolicyList = this.policyList;
    })
  }

  getYearList() {
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year >= this.selectedYear - 10; year--) {
      this.years.push(year);
    }
  }

  onYearChange(year) {
    this.selectedYear = year;
  }

  processEnumValuesToArray() {
    for (const geoLocation of Object.values(GeoLocation)) {
      if (isNaN(Number(geoLocation))) {
        this.geoLocations.push(geoLocation);
      }
    }
  }

  applySearchForGeoLocation() {
    let filteredPolicy = this.policyList.filter(policy => {
      const geoLocationKeys = this.geoLocationSearch.map(geoLocation => this.getGeoLocationKeyByValue(geoLocation));
      const geoLocationMatch = geoLocationKeys.length === 0 || geoLocationKeys.includes(policy.geoLocation);
      return geoLocationMatch;
    })

    this.filteredPolicyList = filteredPolicy;
  }

  getGeoLocationKeyByValue(value: string): string | undefined {
    return Object.keys(GeoLocation).find(key => GeoLocation[key] === value);
  }

  applySearch() {
    this.geoLocationSearch = [];
    let filteredPolicy = this.policyList.filter(policy => {
      const nameMatch = policy.geoLocation.toLowerCase().includes(this.searchTerm.toLowerCase());
      return nameMatch;
    });

    filteredPolicy.sort((a, b) => {
      const aNameMatch = a.geoLocation.toLowerCase().includes(this.searchTerm.toLowerCase());
      const bNameMatch = b.geoLocation.toLowerCase().includes(this.searchTerm.toLowerCase());

      if (aNameMatch && !bNameMatch) {
        return -1;
      } else if (!aNameMatch && bNameMatch) {
        return 1;
      } else {
        return 0;
      }
    });
    this.filteredPolicyList = filteredPolicy;
  }

  bulkConfigure() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    dialogConfig.height = '38%';
    this.dialog.open(BulkLeaveConfigureComponent, dialogConfig);
  }

  policyConfigure() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '75%';
    dialogConfig.height = '60%';
    this.dialog.open(PolicyConfigureBoxComponent, dialogConfig);
  }

  deletePolicy(policy){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '450px';
    dialogConfig.height = '200px';
    dialogConfig.data = {
      policyDetail: policy
    };
    const dialogRef = this.dialog.open(
      DeletePolicyComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      this.initiateComponent();
    });
  }
}
