
export class ktUserDTO{
    additionalComments: String;
  endDate: String;
  knowledgeTransferedTo: String;
  projectId: String;
  startDate: String;
  taskType: String;
}

export class RelievingEmployeeDTO{
  name:String='';
  mail:String='';
  cmUserId:String='';
  cmName:String='';
  title:String='';
  capablity:String='';
  baseLocation:String='';
}