<div class="container">
    <div class="main_header_div">
        <div class="head">
            <img src="assets/icons/holidays-icons/holiday_configuration.svg" />
            Leave Policy Configuration
        </div>
        <img class="close-icon-svg" (click)="closeDialog()" src="assets/icons/dashboard-icons/close-pink-icon.svg" />
    </div>

    <form [formGroup]="policyDetails">
        <div class="row first-row">
            <div class="col-md-3">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Geo Location</mat-label>
                    <mat-select formControlName="geoLocation" [disabled]="rowAdded">
                        <mat-option *ngFor="let geoLocation of geoLocations" [value]="geoLocation">
                            {{ geoLocation}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="policyDetails.get('geoLocation').errors?.required">
                        Required
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Employment Type</mat-label>
                    <mat-select formControlName="employmentType" [disabled]="rowAdded">
                        <mat-option *ngFor="let employmentType of employmentTypes" [value]="employmentType">
                            {{ employmentType | underScoreToSpace | titlecase }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="policyDetails.get('employmentType').errors?.required">Required
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-4"></div>
            <div class="col-md-2">
                <button (click)="addRow()" class="grey-border-button">Add row</button>
            </div>
        </div>
    </form>

    <table class="table">
        <thead>
            <tr>
                <th colspan="1">
                    Leave Type
                </th>
                <th colspan="1">
                    Number of Days
                </th>
                <th colspan="1"> Carry Forward</th>
                <th colspan="1"> Encash</th>
                <th colspan="2"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of leaveBalanceData; let i = index">
                <td colspan="1">
                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Leave Type</mat-label>
                        <mat-select [(ngModel)]="row.leaveType">
                            <mat-option *ngFor="let type of leaveTypes" [value]="type">
                                {{ type | underScoreToSpace | titlecase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
                <td colspan="1" > <input [(ngModel)]="row.numberOfDays" required></td>
                <td colspan="1" > <input [(ngModel)]="row.carryForwardLimit" required></td>
                <td colspan="1" > <input [(ngModel)]="row.encashLimit" required></td>
                <td colspan="2" ><button class="pink-text-only-btn" (click)="removeRow(i)">Remove</button></td>
            </tr>
        </tbody>
    </table>
    
    <div class="dialog-container">
        <button class="blue-button" (click)="saveData()">Save</button>
    </div>

</div>