import { Component, OnInit, ViewChild } from '@angular/core';
import { LeaveApplicationService } from 'src/app/services/leave-application/leave-application.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WithdrawLeaveApplicationComponent } from './withdraw-leave-application/withdraw-leave-application.component';
import { Status } from 'src/app/services/user/user-enum';

@Component({
  selector: 'app-leave-details',
  templateUrl: './leave-details.component.html',
  styleUrls: ['./leave-details.component.scss'],
})
export class LeaveDetailsComponent implements OnInit {
  p: number;
  LeaveDetailsList = [];
  totalLeaveDaysList: string[] = [];
  searchText: string;
  userid: string;
  years: number[] = [];
  panelOpenState: Boolean = false;
  isSpinner: boolean = true;

  constructor(
    private leaveApplicationService: LeaveApplicationService,
    private userDetailService: UserDetailsService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getAllLeaves();
  }

  getAllLeaves() {
    this.userid = this.userDetailService.getUserDetails().id;
    this.isSpinner = true;
    this.LeaveDetailsList = [];
    this.leaveApplicationService.getAllLeaveApplication().subscribe(
      (res) => {
        for (let r of res.body) {
        if(r.approvalStatus==Status.PENDING){
          let days = 'calculating';
          this.leaveApplicationService
            .getLeaveTotalCount(r)
            .subscribe((res) => {
              days = res.body;
              const currentLeave={
                id: r.id,
                typeofLeave:r.typeOfLeave,
                approvalStatus:r.approvalStatus,
                days:res.body,
                startDate:r.startDate,
                endDate:r.endDate,
                transactionDateTime:r.transactionDateTime,
                reasonForLeave:r.reasonForLeave

              }
                this.LeaveDetailsList.push(currentLeave);
              
            });
        }
      }
        this.isSpinner = false;
      },
      (e) => {
        this.isSpinner = false;
      }
    );
  }

  revokeBox(leaveDetail) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    dialogConfig.data = { leaveDetail };
    const dialogRef = this.dialog.open(
      WithdrawLeaveApplicationComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result.event === 'success') {
          this.getAllLeaves();
        }
      },
      () => {}
    );
  }

  noPendingLeaves(): boolean {
    return (
      this.LeaveDetailsList.length === 0 ||
      !this.LeaveDetailsList.some(
        (detail) => detail.approvalStatus === Status.PENDING
      )
    );
  }
}
