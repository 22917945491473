import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {
shareDataSubject = new BehaviorSubject(0);
shareData1=this.shareDataSubject.asObservable(); //Decalring new RxJs Subject

  constructor() { }
  getIndex(index){
    this.shareDataSubject.next(index);
   }
}
