import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { StorageService } from './storage.service';
import { HttpClient} from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class HandleErrorService {
  constructor(private storageService:StorageService,
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService) {}
  baseUrl = environment.BASE_API_URL_BACKEND+'/'

  // Handling HTTP Errors 
  public handleError(err: HttpErrorResponse) {
    let errorMessage: string;
    
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      switch (err.status) {
        case 400:
          errorMessage = "Bad Request.";
          break;
        case 401:
          return this.refreshJwt();
          break;
        case 403:
          errorMessage = "You don't have permission to access the requested resource.";
          break;
        case 404:
          errorMessage = "The requested resource does not exist.";
          break;
        case 412:
          errorMessage = "Precondition Failed.";
          break;
        case 500:
          errorMessage = "Internal Server Error.";
          break;
        case 503:
          errorMessage = "The requested service is not available.";
          break;
        case 422:
          errorMessage = "Validation Error!";
          break;
        default:
          errorMessage = "Something went wrong!";
      }
    }
    if (errorMessage) {
    }
}

private refreshJwt(){
  return this.http.post<any>(this.baseUrl+'api/refreshJwt', {
    "refreshToken":this.storageService.getItem(StorageService.REFRESH_TOKEN)      
  }, {observe: 'response'})
  .pipe(
    catchError(error =>{
     
      localStorage.clear();
      this.router.navigate(['/login']);
      this.toastr.info("Please re-login", "Session Expired");
      return throwError(error);
    })
  )
}

}

