<div class="container-fluid">
<div class="main_header_div">
    <div class="head">
      <img src="assets/icons/holidays-icons/holiday_configuration.svg" />
      Add Client Holiday
    </div>
    <img class="close-icon-svg" (click)="closeDialog()" src="assets/icons/dashboard-icons/close-pink-icon.svg" />
  </div>
  <div class="row mt-3">
    <div class="col-md-12">
      <form [formGroup]="clientDetails">
        <div class="row">
          <div class="col-md-2 mat-form-field-capabilities">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Year <span class="contentRed"></span></mat-label>
              <mat-select formControlName="year" [disabled]="rowAdded">
                <mat-option *ngFor="let year of years" [value]="year">
                  {{ year }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="isInvalidControl('year')"> year is required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2 mat-form-field-capabilities">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Client Name <span class="contentRed"></span></mat-label>
              <mat-select formControlName="clientName" [disabled]="rowAdded" (selectionChange)="onClientChange($event.value)">
                <mat-option *ngFor="let client of clientList" [value]="client">
                  {{ client }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="isInvalidControl('clientName')"> Client Name is required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2 mat-form-field-capabilities">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label> Project Name <span class="contentRed"></span></mat-label>
              <mat-select formControlName="projectName" [disabled]="rowAdded">
                <mat-option *ngFor="let project of projects" [value]="project.projectName">
                  {{ project.projectName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="isInvalidControl('projectName')"> Project Name is required</mat-error>
            </mat-form-field>
          </div>          
          <div class="col-md-2">
            <button (click)="addRow()"class="add-holiday">Add row</button>
          </div>
        </div>
        
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 table-1">
      <table class="table">
        <thead>
          <tr>
            <th>
            Client Name
            </th>
            <th>
             Project Name
            </th>
            <th>
              Date
              </th>
              <th>
                Holiday Name
              </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of clientData; let i = index">
            
            <td>{{row.clientName}}</td>
              <td>{{row.projectName}}</td>
            <td>
              <input type="date" [(ngModel)]="row.date" required>
            </td>
            <td> <input type="text" [(ngModel)]="row.holidayName" required>
            <td><button class="remove-btn" (click)="removeRow(i)">Remove</button></td>
          </tr>
        </tbody>
      </table>
      <div class="dialog-container">
      <button class="blue-button" (click)="openEmployeeDialog()">Next</button>
      </div>
    </div>
  </div>
  </div>
