import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LeaveApplicationUrlService {
  private readonly leaveApplicationsBaseUrl = '/api/leave_application/careermanager';

  constructor() { }

  getLeaveApplications(){
    return this.leaveApplicationsBaseUrl;
  }
}
