import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatDialogConfig,
} from '@angular/material/dialog';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { ApplyWfoComponent } from '../apply-wfo/apply-wfo.component';
import { WfoService } from 'src/app/services/work-from-office/wfo.service';
import { RefreshService } from 'src/app/services/refreshService/refresh.service';
import { IsWithdrawBoxComponent } from './is-withdraw-box/is-withdraw-box.component';
import * as ExcelJS from 'exceljs';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { WorkFromOfficeDto } from 'src/app/dtos/WorkFromOfficeDto';
import { EventTabClosed, Status } from 'src/app/services/user/user-enum';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-wfo-detail',
  templateUrl: './wfo-detail.component.html',
  styleUrls: ['./wfo-detail.component.scss'],
})

export class WfoDetailComponent implements OnInit {
  userDetails: UserDTO;
  loggedInUserId: any;
  wfoDetailsList: WorkFromOfficeDto[] = [];
  totalWfoDays: number = 0;
  wfoDetailExcel: string[][] = [];
  allEmployee: any[] = [];
  filterEmployee: any[] = [];
  monthNames: string[] = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  selectedYear: number = new Date().getFullYear();
  selectedMonth: number = new Date().getMonth() + 1; // Adding 1 to get the 1-based month index
  months = Array.from({ length: 12 }, (_, i) => i + 1);
  years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i);
  @Output() monthSelected = new EventEmitter<number>();
  @Output() yearSelected = new EventEmitter<number>();

  constructor(
    private dialogRef: MatDialogRef<WfoDetailComponent>,
    private userDetailService: UserDetailsService,
    private dialog: MatDialog,
    private wfoService: WfoService,
    private refreshService: RefreshService,
    private userService: UserService
  ) { }

  selectMonth(month: number): void {
    this.selectedMonth = month;
    this.monthSelected.emit(month);
    this.getWfoList(this.userDetails.id, this.selectedMonth, this.selectedYear);
  }

  selectYear(year: number): void {
    this.selectedYear = year;
    this.yearSelected.emit(year);
    this.getWfoList(this.userDetails.id, this.selectedMonth, this.selectedYear);
  }

  selectEmployee(employee) {
    this.userDetails = employee.value;
    this.getWfoList(this.userDetails.id, this.selectedMonth, this.selectedYear);
  }

  ngOnInit(): void {
    this.loggedInUserId = this.userDetailService.getUserDetails().id;
    this.getReporteesData()
    this.refreshComponent();
  }

  getReporteesData() {
    this.userDetails = this.userDetailService.getUserDetails();
    this.userService.getDirectReportee(this.userDetails.id).subscribe(res => {
      this.allEmployee = [this.userDetails, ...res.body];
      this.filterEmployee = this.allEmployee;
    });
  }

  searchEmployee(employee) {
    this.filterEmployee = this.allEmployee.filter(emp => (emp.firstName.toLowerCase().includes(employee.toLowerCase()) || emp.lastName.toLowerCase().includes(employee.toLowerCase())));
  }

  refreshComponent() {
    this.refreshService.refresh$.subscribe((componentName) => {
      if (componentName === 'wfo-detail') {
        this.getWfoList(this.userDetails.id, this.selectedMonth, this.selectedYear);
      }
    });
    this.initiateWfoDetails()
  }

  initiateWfoDetails() {
    this.totalWfoDays = 0;
    this.userDetails = this.userDetailService.getUserDetails();
    this.getWfoList(this.userDetails.id, this.selectedMonth, this.selectedYear);
  }

  getWfoList(id, month, year) {
    this.wfoDetailsList = [];
    this.totalWfoDays = 0;
    this.wfoService.getWFOHistorybyMonthAndYear(id, month, year).subscribe((res) => {
      this.wfoDetailsList = res.body;
      if (res && res.body) {
        let totalWfoDays = 0;
        res.body.forEach((item) => {
          if (item.workingFromOfficeStatus === Status.APPROVED) {
            totalWfoDays++;
          }
        });
        this.totalWfoDays = totalWfoDays;
      }
    });
  }

  getSafeImageUrl(url: string) {
    if (!url || url === '') {
      return 'assets/icons/default-avatar.jpg';
    } else if (url.startsWith('data:image')) {
      // If it's a base64 encoded image, return it as is
      return url;
    } else {
      return 'data:image/png;base64,' + url;
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: EventTabClosed.Closed });
  }

  applyWFO() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '24%';
    dialogConfig.height = '50%';
    const dialogRef = this.dialog.open(ApplyWfoComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      (result) => {
        this.ngOnInit();
      },
      () => { }
    );
  }

  openIsWithdrawDetail(singleWfoDetail) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '40%';
    dialogConfig.height = '45%';
    dialogConfig.data = { wfoDetail: singleWfoDetail };
    const dialogRef = this.dialog.open(IsWithdrawBoxComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => { });
  }

  formatStringDate(input: string): string {
    const date = new Date(input);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  formatString(input: string): string {
    return input
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/\b(Sr)\b/gi, 'Sr.')
      .replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      );
  }

  downloadWFODetail() {
    this.wfoDetailExcel = [];
    this.wfoService.getWFOHistorybyMonthAndYear(this.userDetails.id, this.selectedMonth, this.selectedYear).subscribe((res) => {
      const sortedData = res.body.sort((a, b) =>
        new Date(b.startingDate).getTime() - new Date(a.startingDate).getTime()
      );
      const headerRow: string[] = ['Sr.No.', 'Date', 'Location', 'Status'];
      this.wfoDetailExcel.push(headerRow);

      let index = 1;
      for (const data of sortedData) {
        if (data.workingFromOfficeStatus == "APPROVED") {
          const excelArrayTemp: string[] = [
            (index++).toString(),
            this.formatStringDate(data.startingDate),
            this.formatString(data.officeLocation),
            this.formatString(data.workingFromOfficeStatus),
          ];
          this.wfoDetailExcel.push(excelArrayTemp);
        }
      }
      this.downloadXLSX();
    });
  }

  downloadXLSX() {
    const fileName = `My_WFO_Details.xlsx`;
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('WFO-Detail');

    // Add header row and apply styles
    const headerRow = worksheet.addRow(this.wfoDetailExcel[0]); // Assuming the first element contains header data
    headerRow.eachCell((cell) => this.applyHeaderCellStyle(cell));

    // Add rows and apply styles
    for (let i = 1; i < this.wfoDetailExcel.length; i++) {
      const rowData = this.wfoDetailExcel[i];
      const rowCells = worksheet.addRow(rowData);
      rowCells.eachCell((cell) => this.applyDataCellStyle(cell));
    }

    // Set column widths (optional)
    worksheet.columns.forEach((column) => {
      column.width = 25; // Adjust the width as needed
    });

    // Generate the XLSX file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }

  applyHeaderCellStyle(cell: ExcelJS.Cell) {
    // Styles for header cells
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '051151' },
    };
    cell.font = {
      color: { argb: 'ffffff' },
      name: 'Nunito',
      size: 11,
    };
    cell.border = {
      top: { style: 'thin', color: { argb: 'D3D3D3' } },
      right: { style: 'thin', color: { argb: 'D3D3D3' } },
      bottom: { style: 'thin', color: { argb: 'D3D3D3' } },
      left: { style: 'thin', color: { argb: 'D3D3D3' } },
    };
  }

  applyDataCellStyle(cell: ExcelJS.Cell) {
    // Styles for data cells
    cell.font = {
      color: { argb: '000000' },
      name: 'Nunito',
      size: 11,
    };
    cell.border = {
      top: { style: 'thin', color: { argb: 'D3D3D3' } },
      right: { style: 'thin', color: { argb: 'D3D3D3' } },
      bottom: { style: 'thin', color: { argb: 'D3D3D3' } },
      left: { style: 'thin', color: { argb: 'D3D3D3' } },
    };
  }

}
