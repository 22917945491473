<div class="config-container" *ngIf="!selectedUser">
  <button class="button blue_btn">
    <span class="blue_btn_name">Export Report</span>
  </button>
  <button class="button blue_btn" (click)="bulkConfigureLeave()">
    <span class="blue_btn_name">Bulk Configure</span>
  </button>
  <button class="button blue_btn" (click)="bulkExcelUpload()">
    <span class="blue_btn_name">Bulk Upload</span>
  </button>
</div>
<div class="mat_card_main">
  <div class="search_main">
    <input class="search_input" [matAutocomplete]="userAuto" type="search" placeholder="Search"
      [formControl]="userControl" />
    <mat-autocomplete #userAuto="matAutocomplete" (optionSelected)="selectUser($event.option.value)">
      <mat-option *ngFor="let user of filteredUsers | async | filter : userControl.value" [value]="user">
        <span style="margin-left: 10px">{{
          user.firstName + " " + user.lastName + " (" + user.employeeId + ")"
          }}</span>
      </mat-option>
    </mat-autocomplete>
  </div>

  <div class="year-div">
    <mat-select class="" [(value)]="selectedYear" (selectionChange)="onYearChange(selectedYear)">
      <mat-option *ngFor="let year of years" [value]="year">{{
        year
        }}</mat-option>
    </mat-select>
  </div>
</div>

<div *ngIf="selectedUser">
  <div class="employee_profile">
    <div class="emp-id-detail">
      <div>
        <img class="noemployee_img" src="assets/icons/default-avatar.jpg" alt="" />
      </div>
      <div class="firstnameblock">
        <label class="firstAndLastname">{{
          selectedUser.firstName + " " + selectedUser.lastName
          }}</label>
        <br />
        <label class="idemp">ID {{ selectedUser.employeeId }}</label>
      </div>
    </div>
    <div>
      <button class="button export-import_btn" (click)="file()">
        <span class="export-import_name">Export Report</span>
      </button>
    </div>
    <div>
      <button class="button leave-config_btn" (click)="configureEmployeeLeave()">
        <span class="leave-config_name">Leave Configure</span>
      </button>
    </div>
  </div>

  <div *ngFor="let leavetype of typeOfLeaveList; let i = index">
    <div class="leave-type-container" *ngIf="i % 2 === 0">
      <!-- Left Leave Type -->
      <div class="single-row leave-type-container-inner-left">
        <div>
          <img class="leave-svg-icon" src="assets/icons/calendar.png" />
        </div>
        <div class="leave-type-name-left" [ngStyle]="{ color: isEnabled[leavetype] ? '#0045B9' : '#666666' }">
          {{ leavetype | underScoreToSpace | titlecase }}
        </div>
        <div class="leave-num">
          <div class="name">Number of Leaves</div>
          <div class="days">
            {{ (leavebalanceData[leavetype] | number : "1.2-2") || "00" }}
          </div>
        </div>
        <div class="three-dot">
          <img src="assets/icons/3dots.png" class="threedots" [matMenuTriggerFor]="beforeMenu" />
          <mat-menu class="matmenu" #beforeMenu="matMenu" xPosition="before">
            <button mat-menu-item (click)="enableLeave(leavetype, selectedUser.id)" *ngIf="!isEnabled[leavetype]">
              <span class="bold">Enable</span>
            </button>
            <button mat-menu-item (click)="disableLeave(leavetype, selectedUser.id)" *ngIf="isEnabled[leavetype]">
              <span class="bold">Disable</span>
            </button>
            <button mat-menu-item (click)="detailLeave(leavetype, selectedUser.id)">
              <span class="bold">Detail View</span>
            </button>
          </mat-menu>
        </div>
      </div>

      <!-- Right Leave Type -->
      <div class="single-row leave-type-container-inner-right" *ngIf="i + 1 < typeOfLeaveList.length">
        <div>
          <img class="leave-svg-icon" src="assets/icons/calendar.png" />

        </div>
        <div class="leave-type-name-left" [ngStyle]="{
            color: isEnabled[typeOfLeaveList[i + 1]] ? '#0045B9' : '#666666'
          }">
          {{ typeOfLeaveList[i + 1] | underScoreToSpace | titlecase }}
        </div>
        <div class="leave-num">
          <div class="name">Number of Leaves</div>
          <div class="days">
            {{
            (leavebalanceData[typeOfLeaveList[i + 1]] | number : "1.2-2") ||
            "00"
            }}
          </div>
        </div>
        <div class="three-dot">
          <img src="assets/icons/3dots.png" class="threedots" [matMenuTriggerFor]="beforeMenu" />
          <mat-menu class="matmenu" #beforeMenu="matMenu" xPosition="before">
            <button mat-menu-item (click)="enableLeave(typeOfLeaveList[i + 1], selectedUser.id)"
              *ngIf="!isEnabled[typeOfLeaveList[i + 1]]">
              <span class="bold">Enable</span>
            </button>
            <button mat-menu-item (click)="disableLeave(typeOfLeaveList[i + 1], selectedUser.id)"
              *ngIf="isEnabled[typeOfLeaveList[i + 1]]">
              <span class="bold">Disable</span>
            </button>
            <button mat-menu-item (click)="detailLeave(typeOfLeaveList[i + 1], selectedUser.id)">
              <span class="bold">Detail View</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!selectedUser">
  <div class="no-leave no-leave-container">
    <img class="no-leave-img" src="\assets\no-leave.png" alt="" />
  </div>
</div>