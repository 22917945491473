import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { HolidaysEventService } from 'src/app/services/leaveManagementCalendar/holidays-event.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { ApplyHolidayComponent } from '../../leave-management/holiday-calendar/apply-holiday/apply-holiday.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { RefreshService } from 'src/app/services/refreshService/refresh.service';
import { HolidayType } from 'src/app/services/user/user-enum';

@Component({
  selector: 'app-upcoming-holidays',
  templateUrl: './upcoming-holidays.component.html',
  styleUrls: ['./upcoming-holidays.component.scss'],
})
export class UpcomingHolidaysComponent implements OnInit {

  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  years: number[] = [];
  selectedMonth: string = this.months[new Date().getMonth()];
  selectedYear: number = new Date().getFullYear();
  userid: string;
  restrictedStatus: string = HolidayType.FLOATER_HOLIDAY;
  generalStatus: string = HolidayType.MANDATORY_HOLIDAY;
  loggedInUser: any
  restrictedHolidays: { date: string; desc: string; originalDate: string }[] = [];

  constructor(
    private eventService: HolidaysEventService,
    private userDetailService: UserDetailsService,
    public dialog: MatDialog,
    private refreshService: RefreshService
  ) { }

  ngOnInit(): void {
    this.userid = this.userDetailService.getUserDetails().id;
    this.loggedInUser = this.userDetailService.getUserDetails();
    this.genRestrictedHolidayCall();
  }

  applyRestrictedHoliday(description, date) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '50%';
    dialogConfig.width = '40%';
    dialogConfig.panelClass = 'manage-class';
    dialogConfig.data = { holidayDescription: description, holidayDate: date };
    const dialogRef = this.dialog.open(ApplyHolidayComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      this.genRestrictedHolidayCall();
      this.refreshService.triggerRefresh('tasks');
    });
  }

  genRestrictedHolidayCall() {
    this.restrictedHolidays = [];
    this.eventService
      .getRestictedHolidaysForApplyLeave(this.selectedYear, this.loggedInUser.geoLocation, this.loggedInUser.employmentType, this.loggedInUser.baseLocation)
      .subscribe(
        (res) => {
          const response = res.body;
          for (let i = 0; i < response.length; i++) {
            const holiday = response[i];
            const dateObj = new Date(holiday.date);
            const monthName = dateObj.toLocaleString('default', {
              month: 'long',
            });

            this.eventService
              .getHolidayStatus(holiday)
              .subscribe((result) => {
                if (
                  (holiday.calendarLeaveType == this.restrictedStatus ||
                    this.generalStatus) &&
                  monthName.toLowerCase() == this.selectedMonth.toLowerCase()
                ) {
                  const currentHoliday = {
                    date: moment(holiday.date).format('DD-MM-YYYY'),
                    desc: holiday.leaveDescription,
                    originalDate: holiday.date,
                    status: result.body,
                    calendarLeaveType: holiday.calendarLeaveType,
                  };

                  this.restrictedHolidays.push(currentHoliday);
                }
              });
          }
        }

      );
  }

}
