import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MainContentProjectComponent } from 'src/app/components/project/main-content-project/main-content-project.component';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { TypeOfTask } from 'src/app/services/user/user-enum';
@Component({
  selector: 'app-decline-feedback',
  templateUrl: './decline-feedback.component.html',
  styleUrls: ['./decline-feedback.component.scss'],
})
export class DeclineFeedbackComponent implements OnInit {
  feedbackDecline: FormGroup;
  isOrphanFeedback: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<MainContentProjectComponent>,
    public fb: FormBuilder,
    private feedbackService: FeedbackService,
    public tosterService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public feedbackdata: any
  ) {
    this.feedbackDecline = this.fb.group({
      description: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.feedbackService
      .getFeedbackById(this.feedbackdata.taskTransactionId)
      .subscribe((res) => {
        this.feedbackService
          .getFeedbackGroupById(res.body.groupId)
          .subscribe((res) => {
            this.isOrphanFeedback =
              res.body.feedbackType == 'ORPHAN' ? true : false;
          });
      });
  }
  closeDialog() {
    this.dialogRef.close();
  }

  save() {
    if (this.feedbackDecline.controls.description.value != '') {
      if (
        this.feedbackdata.typeOfTask == TypeOfTask.PROBATION_FEEDBACK ||
        this.feedbackdata.typeOfTask == TypeOfTask.REVERSE_PROBATION_FEEDBACK
      ) {
        this.feedbackService
          .declineProbationFeedback(
            this.feedbackdata.taskTransactionId,
            this.feedbackDecline.controls.description.value
          )
          .subscribe(
            (res) => {
              this.feedbackService.filter('');
              this.tosterService.success('Feedback declined!');

              this.dialogRef.close();
            },
            (e) => {
              this.tosterService.error('Saved feedback cannot be declined!');
            }
          );
      } else if (
        this.feedbackdata.typeOfTask == 'SUBMIT_REAL_TIME_FEEDBACK' ||
        this.isOrphanFeedback
      ) {
        this.feedbackService
          .delete(this.feedbackdata.taskTransactionId)
          .subscribe(
            (res) => {
              this.feedbackService.filter('');
              this.tosterService.success('Feedback canceled!');

              this.dialogRef.close();
            },
            (e) => {
              this.tosterService.error('Saved feedback cannot be declined!');
            }
          );
      } else {
        this.feedbackService
          .declineFeedback(
            this.feedbackdata.taskTransactionId,
            this.feedbackDecline.controls.description.value
          )
          .subscribe(
            (res) => {
              this.feedbackService.filter('');
              this.tosterService.success('Feedback declined!');

              this.dialogRef.close();
            },
            (e) => {
              this.tosterService.error('Saved feedback cannot be declined!');
            }
          );
      }
    }
  }
}
