import { Injectable } from '@angular/core';
import { RestAPIService } from '../rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class SubgoalURLService {

  private readonly subGoalBaseApi = '/api/subgoals';
  private readonly usergoalApi = '/user-goals'
  private readonly user = '/user';

  constructor(private restApi: RestAPIService) { }

  getBaseUrl() {
    return this.subGoalBaseApi;
  }

  getByFieldUrl(field) {
    return this.subGoalBaseApi + this.getByField(field);
  }

  getUserGoalIdUrl(usergoalId){
    return this.subGoalBaseApi +this.usergoalApi+'/'+usergoalId;
  }

  private getByField(field) {
    return '/' + field;
  }

}
