<div class="main_header_div">
  <h3 mat-dialog-title>Reason for Regularization</h3>
  <div class="child_close_btn">
    <img class="close-icon-svg" (click)="closeDialog()" src="assets/icons/dashboard-icons/close-pink-icon.svg" />
  </div>
</div>
<form [formGroup]="regularizationDetails">
  <div class="col">
    <mat-form-field appearance="outline" class="example">
      <textarea
        matInput
        formControlName="reasonForRegularization"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="4"
        cdkAutosizeMaxRows="7"
        maxlength="100"
        placeholder="Reason in words"
      >
      </textarea>
      <mat-hint>
        <label>
          Remaining characters:
          {{
            100 -
              regularizationDetails.get("reasonForRegularization").value.length
          }}
          / 100
        </label>
      </mat-hint>
    </mat-form-field>
  </div>
</form>
<div class="submit_button_main">
  <button class="add_Emp_btn" type="submit" (click)="applyRegularization()">
    <span>Submit</span>
  </button>
</div>
