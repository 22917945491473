import { Injectable } from '@angular/core';
import { RestAPIService } from '../rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class HolidayEventUrlService {
  private readonly HolidayEventBaseUrl = '/api/holiday';
  private readonly HolidayStatusUrl = '/api/floater-leave-status';
  private readonly userHolidaysUrl = '/api/get_leave_by_year_for_user';
  private readonly generalAndRestricted ='/api/get-ba-general-restricted-leaves'



  constructor() { }

  getBaseUrl() {
    return this.HolidayEventBaseUrl;
  }

  // gen+ res
  getHolidayEvents(year , geo , employment , baselocation) {
    return this.HolidayEventBaseUrl + '/' + year + '/' + geo + '/' + employment + '/' + baselocation;
  }

  // not exist
  getHolidaysByUserandYear(userId,year){
    return this.userHolidaysUrl+'/'+userId+'/'+year;
  }
  getHolidayStatus() {
    return this.HolidayStatusUrl;
  }
  getGeneralAndRestrictedHolidays(year){
    return this.generalAndRestricted +'/' + year;
  }




}
