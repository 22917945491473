export class SubGoalDTO{
    public id:String
    public subgoalName:String
    public currentValue:number
    public description:String
    public userGoalId:String

    constructor(){
        this.id=undefined
        this.subgoalName=null
        this.currentValue=0
        this.description=null
        this.userGoalId=null
    }

}