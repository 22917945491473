import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-search-employee',
  templateUrl: './search-employee.component.html',
  styleUrls: ['./search-employee.component.scss'],
})
export class SearchEmployeeComponent implements OnInit {
  filteredUsers: Observable<User[]>;
  allUsers: any[] = [];
  selectedUser: any;
  userdata: User;
  searchText = '';
  userControl: FormControl = new FormControl();
  userImageThumbnail: string;

  constructor(
    private userservice: UserService,
    public userDetailService: UserDetailsService,
    private feedbackService: FeedbackService,
    private router: Router,
    public dialogRef: MatDialogRef<SearchEmployeeComponent>
  ) {}

  ngOnInit(): void {
    this.allUsers = [];
    this.userservice.getAllUserForSearchEmployee().subscribe((res) => {
      for( let user of res.body){
        if(!user?.activated){
          continue;
        }
        let userImageThumbnail = '';
        if(user?.userImageThumbnail !== ''){
          userImageThumbnail = 'data:image/png;base64,' + user.userImageThumbnail;
        }
        let userDetail = {
          employeeId: user?.employeeId?.toUpperCase(),
          firstName: user?.firstName,
          lastName: user?.lastName,
          userID: user?.userID,
          userImageThumbnail: userImageThumbnail,
        };
    
        this.allUsers.push(userDetail);
        
      }
      this.filteredUsers = this.userControl.valueChanges.pipe(
        startWith(''),
        map((value) => this.filterUsers(value))
      );
    });
  }

  filterUsers(value: string): User[] {
    const filterValue = value.toLowerCase().trim();
    if (this.selectedUser) {
      return this.allUsers.filter(
        (user) =>
          user.firstName.toLowerCase().includes(filterValue) &&
          user !== this.selectedUser
      );
    } else {
      return this.allUsers;
    }
  }

  selectUser(user: any) {
    if (user) {
      this.selectedUser = user;
      this.navigateToEmployee();
      this.userControl.setValue('');
    } else {
      this.selectedUser = null;
      this.userControl.setValue('');
      return;
    }
  }

  navigateToEmployee() {
    this.feedbackService.sendData(this.selectedUser.userID);
    this.router.navigate([
      `/employee/employee-profile/${this.selectedUser.userID}`,
    ]);
    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Closed' });
  }
}
