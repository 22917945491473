<div class="container mt-2" style="padding: 15px">
  <div style="display: flex; justify-content: space-between">
    <div class="title">
      <h1 *ngIf="this.projects == null" class="header_name">Add New Project</h1>
      <h1 *ngIf="this.projects" class="header_name">Edit Project</h1>
    </div>
    <div>
      <button type="button" (click)="closeDialog()" class="close-button">
        Close
      </button>
    </div>
  </div>
  <hr />
  <form [formGroup]="newProjectDetails">
    <div class="row outer-row">
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Project Type </mat-label>
          <mat-select
            formControlName="projectType"
            [ngClass]="{
              'required-field-error':
                isSaveClicked &&
                newProjectDetails.get('projectType').errors?.required
            }"
          >
            <mat-option *ngFor="let type of projectType" [value]="type"
              >{{ type }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="checkError">Required</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Project Code </mat-label>
          <input
            matInput
            formControlName="projectCode"
            autocomplete="off"
            [ngClass]="{
              'required-field-error':
                isSaveClicked &&
                newProjectDetails.get('projectType').errors?.required
            }"
            (ngModelChange)="onProjectCodeTextTyped($event)"
            style="text-transform: uppercase"
          />

          <mat-error
            *ngIf="
              this.newProjectDetails.get('projectCode').errors
                ?.InvalidProjectCode
            "
          >
            {{ emailError }}
          </mat-error>
          <mat-error
            *ngIf="
              newProjectDetails
                .get('projectCode')
                .hasError('noOnlySpecialCharacters')
            "
            >Invalid characters</mat-error
          >
          <mat-error
            *ngIf="newProjectDetails.get('projectCode').errors?.required"
            >Required</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Project Name</mat-label>
          <input
            matInput
            formControlName="projectName"
            autocomplete="off"
            style="text-transform: capitalize"
            [ngClass]="{
              'required-field-error':
                isSaveClicked &&
                newProjectDetails.get('projectType').errors?.required
            }"
          />
          <mat-error
            *ngIf="
              newProjectDetails
                .get('projectName')
                .hasError('noOnlySpecialCharacters')
            "
            >Invalid characters</mat-error
          >

          <mat-error
            *ngIf="newProjectDetails.get('projectName').errors?.required"
            >Required</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Project Category</mat-label>
          <mat-select
            formControlName="projectCategory"
            [ngClass]="{
              'required-field-error':
                isSaveClicked &&
                newProjectDetails.get('projectType').errors?.required
            }"
          >
            <mat-option
              *ngFor="let category of projectCategory"
              [value]="category"
              >{{ category | underScoreToSpace | titlecase }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="newProjectDetails.get('projectCategory').errors?.required"
            >Required</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Capability</mat-label>
          <mat-select
            formControlName="capability"
            [ngClass]="{
              'required-field-error':
                isSaveClicked &&
                newProjectDetails.get('projectType').errors?.required
            }"
          >
            <mat-option
              *ngFor="let department of this.departments"
              [value]="department"
            >
              {{ department }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="newProjectDetails.get('capability').errors?.required"
            >Required</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Client</mat-label>
          <mat-select
            formControlName="client"
            [ngClass]="{
              'required-field-error':
                isSaveClicked &&
                newProjectDetails.get('projectType').errors?.required
            }"
          >
            <mat-option *ngFor="let cli of this.clientList" [value]="cli.id">
              {{ cli.clientName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="newProjectDetails.get('client').errors?.required"
            >Required</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Start Date</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            placeholder="dd/mm/yyyy"
            formControlName="startDate"
            (dateChange)="checkLBandLAerrorFcn()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="checkError('startDate', 'required')"
            >Required</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>End Date</mat-label>
          <input
            matInput
            [matDatepicker]="picker1"
            placeholder="dd/mm/yyyy"
            formControlName="endDate"
            [min]="newProjectDetails.get('startDate').value"
            (dateChange)="checkLBandLAerrorFcn()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <mat-error
            *ngIf="newProjectDetails.controls.endDate.hasError('pattern')"
            >{{ errorMessage }}</mat-error
          >
          <mat-error *ngIf="newProjectDetails.get('endDate').errors?.required"
            >Required</mat-error
          >
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="example-full-width"
          style="width: 100%"
        >
          <mat-label>Project Manager</mat-label>
          <input
            matInput
            [matAutocomplete]="projectManagerAuto"
            formControlName="projectManager"
            required
            style="text-transform: capitalize"
          />
          <mat-autocomplete
            #projectManagerAuto="matAutocomplete"
            [displayWith]="displayFnCareerManager"
          >
            <mat-option
              *ngFor="let manager of filteredCareerManager | async"
              [value]="manager"
            >
              <span style="margin-left: 10px">{{
                manager.firstName +
                  " " +
                  manager.lastName +
                  " " +
                  " (" +
                  manager.employeeId +
                  ")"
              }}</span>
            </mat-option>
          </mat-autocomplete>
          <mat-error
            *ngIf="
              newProjectDetails
                .get('projectManager')
                .hasError('noOnlySpecialCharacters')
            "
            >Invalid characters</mat-error
          >
          <mat-error *ngIf="checkError('projectManager', 'required')"
            >Required</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="example-full-width"
          style="width: 100%"
        >
          <mat-label>Project Description </mat-label>
          <textarea
            formControlName="projectDescription"
            matInput
            autocom
            rows="1"
          ></textarea>
          <mat-error
            *ngIf="
              newProjectDetails
                .get('projectDescription')
                .hasError('noOnlySpecialCharacters')
            "
            >Invalid characters</mat-error
          >
          <mat-error
            *ngIf="newProjectDetails.get('projectDescription').errors?.required"
            >Required</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <div style="display: flex; justify-content: flex-end">
      <button
        mat-raised-button
        type="button"
        class="submit-btn"
        (click)="save()"
      >
        Save
      </button>
    </div>
  </form>
</div>
