<div class="main_header_div">
    <div class="child">
        <h1 class="header_name "><img class="header-icon mr-3" src="assets/icons/currAddress.png"> Address</h1>
    </div>
    <div class="pr-3 pt-3">
        <img class="close-icon" (click)="closeDialog()" src="assets/icons/close.png">
    </div>
</div>
<hr>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 form_main_div">
            <form [formGroup]="addressForm">
                <div class="content-scroll">
                <fieldset formGroupName="permanentAddress">
                    <label>Permanent Address</label>
                    <div class="row mt-3">
                        <div class="col-md-6">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Address Line 1 <span class="contentRed"></span></mat-label>
                                <input matInput formControlName="addressLineOne" autocomplete="off">
                                <mat-error *ngIf="checkError('addressLineOne','permanentAddress','required')">Required</mat-error>
                                <mat-error *ngIf="checkError('addressLineOne','permanentAddress','pattern')">Invalid pattern</mat-error>

                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Pincode<span class="contentRed"></span></mat-label>
                                <input matInput formControlName="pincode" autocomplete="off">
                                <mat-error *ngIf="checkError('pincode','permanentAddress', 'required')">Required</mat-error>
                                <mat-error *ngIf="checkError('pincode','permanentAddress', 'pattern')">Invalid Pincode</mat-error>

                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>City<span class="contentRed"></span></mat-label>
                                <input matInput formControlName="city" autocomplete="off" required>
                                <mat-error *ngIf="checkError('city','permanentAddress', 'required')">Required</mat-error>
                                <mat-error *ngIf="checkError('city','permanentAddress', 'pattern')">Invalid City Name</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Address Line 2 (Optional)</mat-label>
                                <input matInput formControlName="addressLineTwo" autocomplete="off">
                                <mat-error *ngIf="checkError('addressLineTwo','permanentAddress','pattern')">Invalid pattern</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>State<span class="contentRed"></span></mat-label>
                                <input matInput formControlName="state" autocomplete="off" required>
                                <mat-error *ngIf="checkError('state','permanentAddress', 'required')">Required</mat-error>
                                <mat-error *ngIf="checkError('state','permanentAddress', 'pattern')">Invalid State Name</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Country<span class="contentRed"></span></mat-label>
                                <input matInput formControlName="country" autocomplete="off">
                                <mat-error *ngIf="checkError('country','permanentAddress', 'required')">Required</mat-error>
                                <mat-error *ngIf="checkError('country','permanentAddress', 'pattern')">Invalid Country Name</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </fieldset>

                <fieldset formGroupName="currentAddress">
                    <label>Current Address</label>
                    <div class="row mt-3">
                        <div class="col-md-6">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Address Line 1 <span class="contentRed"></span></mat-label>
                                <input matInput formControlName="addressLineOne" autocomplete="off">
                                <mat-error *ngIf="checkError('addressLineOne','currentAddress', 'required')">Required</mat-error>
                                <mat-error *ngIf="checkError('addressLineOne','currentAddress','pattern')">Invalid pattern</mat-error>

                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Pincode <span class="contentRed"></span></mat-label>
                                <input matInput formControlName="pincode" autocomplete="off">
                                <mat-error *ngIf="checkError('pincode','currentAddress', 'required')">Required</mat-error>
                                <mat-error *ngIf="checkError('pincode','currentAddress', 'pattern')">Invalid Pincode</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>City <span class="contentRed"></span></mat-label>
                                <input matInput formControlName="city" autocomplete="off">
                                <mat-error *ngIf="checkError('city','currentAddress', 'required')">Required</mat-error>
                                <mat-error *ngIf="checkError('city','currentAddress', 'pattern')">Invalid City Name</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Address Line 2 <span class="contentRed">(Optional)</span></mat-label>
                                <input matInput formControlName="addressLineTwo" autocomplete="off">
                                <mat-error *ngIf="checkError('addressLineTwo','currentAddress','pattern')">Invalid pattern</mat-error>

                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>State <span class="contentRed"></span></mat-label>
                                <input matInput matInput formControlName="state" autocomplete="off">
                                <mat-error *ngIf="checkError('state','currentAddress', 'required')">Required</mat-error>
                                <mat-error *ngIf="checkError('state','currentAddress', 'pattern')">Invalid State Name</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Country <span class="contentRed"></span></mat-label>
                                <input matInput formControlName="country" autocomplete="off">
                                <mat-error *ngIf="checkError('country','currentAddress', 'required')">Required</mat-error>
                                <mat-error *ngIf="checkError('state','currentAddress', 'pattern')">Invalid Country Name</mat-error>
                            </mat-form-field>
                        </div>

                    </div>
                </fieldset>
                
               
                <div class="row mt-3">
                    <div class="col-md-12">
                        <mat-checkbox formControlName="prefillPermanent" (change)="onPrefillChange()">Same as Permanent
                            Address</mat-checkbox>
                    </div>
                </div>
            </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="submit_button_main">
                            <button class="save_Emp_btn" type="submit" (click)="onSubmit()"> <span>Save</span>
                            </button>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>