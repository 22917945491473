import { Component, OnInit } from '@angular/core';
import { LeaveApprovalService } from 'src/app/services/leave-approval-cm/leave-approval.service';
import { UserService } from 'src/app/services/user/user.service';
import { isToday } from 'date-fns';

@Component({
  selector: 'app-reportees-leave',
  templateUrl: './reportees-leave.component.html',
  styleUrls: ['./reportees-leave.component.scss'],
})
export class ReporteesLeaveComponent implements OnInit {
  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  years: number[] = [];
  selectedMonth: string = this.months[new Date().getMonth()];
  selectedYear: number = new Date().getFullYear();
  monthIndex: number;
  monthReporteeLeaveData: any = [];
  todayDate: Date;

  constructor(
    private leaveApprovalService: LeaveApprovalService,
    private userservice: UserService
  ) {}

  ngOnInit(): void {
   this.initiateComponent()
  }
initiateComponent()
{
  this.todayDate = new Date();
  this.monthIndex = this.months.indexOf(this.selectedMonth) + 1;
  this.leaveApprovalService
    .getOnLeaveReportees(this.monthIndex, this.selectedYear)
    .subscribe(
      (res) => {
        let data = res.body;
        this.monthReporteeLeaveData = [];
        for (let key of Object.keys(data)) {
          let userArrayForToday = data[key];
          let date = this.formatDateIntoDateMonth(key);
          let name = '';
          let userId = '';
          for (let i = 0; i < userArrayForToday.length; i++) {
            this.userservice
              .getUserByID(userArrayForToday[i])
              .subscribe((result) => {
                name = result.body.firstName + ' ' + result.body.lastName;
                userId = result.body.employeeId;
                if (isToday(new Date(date))) {
                  let todayEmp = {
                    name: name,
                    id: userId,
                    date: date,
                    status: 'LEAVE',
                    userId: userArrayForToday[i],
                  };
                  this.monthReporteeLeaveData.push(todayEmp);
                }
              });
          }
        }
      },
      (e) => {}
    );
}
  formatDateIntoDateMonth(date: any): string {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const parts = date.split('-');
    const day = parseInt(parts[2], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[0], 10);

    return `${day} ${months[month]} ${year}`;
  }
}
