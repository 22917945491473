import { Injectable } from '@angular/core';
import { EventInput } from '@fullcalendar/core';
import { RestAPIService } from '../restAPI/rest-api.service';
import { HolidayEventUrlService } from '../restAPI/holidayEventURLs/holiday-event-url.service';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { AsyncAction } from 'rxjs/internal/scheduler/AsyncAction';
import { catchError, map } from 'rxjs/operators';
import { UnmarkedAttendanceService } from '../UnmarkedAttendance/unmarked-attendance.service';
import { UserDetailsService } from '../user-details.service';




@Injectable({
  providedIn: 'root'
})
export class HolidaysEventService {


  constructor(private restApiService: RestAPIService,
    private holidayUrlService: HolidayEventUrlService,
    private userDetailService: UserDetailsService,

  ) { }

  status: string = 'UNMARKED_ATTENDANCE';
  userId = this.userDetailService.getUserDetails().id;


  getHolidayEvents(year, geo, employment, baselocation): Observable<EventInput[]> {
    return this.restApiService.get(
      this.holidayUrlService.getHolidayEvents(year, geo, employment, baselocation)
    ).pipe(
      map(res => {
        const newEvents = res.body;
        const events: EventInput[] = []

        for (let i = 0; i < newEvents.length; i++) {
          const event = newEvents[i];
          const formattedDate = moment(event.date).format('YYYY-MM-DD');
          let bgColor = 'black';
          let holidayInitial = '';

          if (event.calendarLeaveType == 'MANDATORY_HOLIDAY') {
            bgColor = '#0AB4AC';
            holidayInitial = 'M';
          }
          else if (event.calendarLeaveType == 'FLOATER_HOLIDAY') {
            bgColor = '#F9B32C';
            holidayInitial = 'F';
          }
          else if (event.calendarLeaveType == 'CLIENT_HOLIDAY') {
            bgColor = '#FF8396';
            holidayInitial = 'C';
          }

          const currentEvent: EventInput = {
            title: holidayInitial,
            start: formattedDate,
            end: formattedDate,
            backgroundColor: bgColor,
            display: 'background'
          };
          events.push(currentEvent);
        }
        return events;
      })
    );
  }
  restrictedHolidays: { date: any, desc: any }[] = [];
  getRestictedHolidaysForApplyLeave(year, geo, employment, baselocation): any {
    return this.restApiService.get(
      this.holidayUrlService.getHolidayEvents(year, geo, employment, baselocation)
    )
  }
  getGeneralAndRestrictedForApply(year): any {
    return this.restApiService.get(
      this.holidayUrlService.getGeneralAndRestrictedHolidays(year)
    )
  }
  getHolidayStatus(payload) {
    return this.restApiService.post(
      this.holidayUrlService.getHolidayStatus(),
      payload
    )
  }

}
