import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-asset-main',
  templateUrl: './asset-main.component.html',
  styleUrls: ['./asset-main.component.scss'],
})
export class AssetMainComponent implements OnInit {
  matTabsClickedIndex = 0;

  constructor() {}

  ngOnInit(): void {}

  onTabChanged($event) {
    this.matTabsClickedIndex = $event.index;
  }
}
