import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ApplyNewleaveComponent } from './leave-attendance/apply-newleave/apply-newleave.component';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { LeaveDetailsComponent } from './leave-attendance/leave-details/leave-details.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TabNavigationService } from 'src/app/services/tab-navigation/tab-navigation.service';
import {  Roles } from 'src/app/services/user/user-enum';

@Component({
  selector: 'app-leave-management',
  templateUrl: './leave-management.component.html',
  styleUrls: ['./leave-management.component.scss'],
})
export class LeaveManagementComponent implements OnInit {
  @ViewChild('leaveDetail') leaveDetailComponent: LeaveDetailsComponent;
  selectedTab: string = '';
  rolesList: string[] = [];
  activeTab: string;
  isAdmin=false;
  isCareerManager=false;

  constructor(
    private dialog: MatDialog,
    private userDetailService: UserDetailsService,
  ) {}

  ngOnInit(): void {
    this.assignRoles();
  }

  assignRoles() {
    this.rolesList = this.userDetailService.getUserDetails().authorities;
    this.isCareerManager = this.rolesList.includes(Roles.ROLE_CAREER_MANAGER.toString());
    this.isAdmin = this.rolesList.includes(Roles.ROLE_ADMIN.toString());
  }

   applyNewLeaveDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '100%';
    dialogConfig.height = '90%';
    dialogConfig.panelClass = 'apply-leave';
    const dialogRef = this.dialog.open(ApplyNewleaveComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.leaveDetailComponent.getAllLeaves();
    });
  }
}
