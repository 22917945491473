<div class="container mt-4">
  <div class="row">
    <div class="col-4">
      <div class="card cardContent" style="width: 22rem">
        <div class="card-header">
          <p class="h3 bold">{{ processName(this.data) }}</p>
          <p class="h5">
            <small class="text-muted">Evaluation Configuration setting (% value)</small>
          </p>
        </div>

        <div class="card-content">
          <div class="card-body">
            <form [formGroup]="evaluationConfigForm" (ngSubmit)="onSubmit()" #form="ngForm">
              <div class="row">
                <div class="col form-group">
                  <mat-form-field appearance="outline">
                    <mat-label>Career Manager</mat-label>
                    <input matInput formControlName="careerManagerWeightage" autocomplete="off" />
                    <mat-error *ngIf="careerManagerWeightage.errors?.Required">
                      Percentage Distribution is Required.
                    </mat-error>
                    <mat-error *ngIf="careerManagerWeightage.errors?.invalidDistribution">
                      Percentage Distribution is invalid.
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col form-group">
                  <mat-form-field appearance="outline">
                    <mat-label>Client</mat-label>
                    <input matInput formControlName="clientWeightage" autocomplete="off" />
                    <mat-error *ngIf="clientWeightage.errors?.Required">
                      Percentage Distribution is Required.
                    </mat-error>

                    <mat-error *ngIf="clientWeightage.errors?.invalidDistribution">
                      Percentage Distribution is invalid.
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col form-group">
                  <mat-form-field appearance="outline">
                    <mat-label>Manager</mat-label>
                    <input matInput formControlName="managerWeightage" autocomplete="off" />
                    <mat-error *ngIf="managerWeightage.errors?.Required">
                      Percentage Distribution is Required.
                    </mat-error>

                    <mat-error *ngIf="managerWeightage.errors?.invalidDistribution">
                      Percentage Distribution is invalid.
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col form-group">
                  <mat-form-field appearance="outline">
                    <mat-label>Supervisor</mat-label>
                    <input matInput formControlName="supervisorWeightage" autocomplete="off" />
                    <mat-error *ngIf="supervisorWeightage.errors?.Required">
                      Percentage Distribution is Required.
                    </mat-error>

                    <mat-error *ngIf="supervisorWeightage.errors?.invalidDistribution">
                      Percentage Distribution is invalid.
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col form-group">
                  <mat-form-field appearance="outline">
                    <mat-label>Peer</mat-label>
                    <input matInput formControlName="peerWeightage" autocomplete="off" />
                    <mat-error *ngIf="peerWeightage.errors?.Required">
                      Percentage Distribution is Required.
                    </mat-error>

                    <mat-error *ngIf="peerWeightage.errors?.invalidDistribution">
                      Percentage Distribution is invalid.
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <button mat-raised-button style="
              background-color: #3e3e3e;
              color: white;
              text-align: center;
            " type="submit">
            Save
          </button>
          <button mat-raised-button style="margin-left: 10px" type="button" (click)="closeDialog()">
            Close
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
</div>
</div>
