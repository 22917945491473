import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ClientHolidayService } from 'src/app/services/clientHoliday/client-holiday.service';

@Component({
  selector: 'app-prievewdialog',
  templateUrl: './prievewdialog.component.html',
  styleUrls: ['./prievewdialog.component.scss']
})
export class PrievewdialogComponent implements OnInit {
  clientDetails:any =[];
  projectDetails=[];
  projectId:any;
  projectMember=[];
  leaveDates:any;
  clientId:any;
  constructor( public dialogRef: MatDialogRef<PrievewdialogComponent>,
    public clientHolidayService: ClientHolidayService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.clientDetails=data.clientData;
      this.projectDetails=data.projects;
      this.clientId=this.projectDetails[0].clientId;
      this.projectId=this.projectDetails[0].projectId;
    }

  ngOnInit(): void {
  }
  closeDialog() {
    this.dialogRef.close();
  }

  

  getProjectMembers(): void {
    this.projectMember = [];
    this.projectDetails.forEach(project => {
      this.projectMember.push(project.talentName);
    });
  }
  configureData(): void {
    this.getProjectMembers(); 
    const requestBody = {
      clientId:this.clientId,
      projectId:this.projectId,
      leaveDates: this.clientDetails.map(client => ({
        dateTime: new Date(client.date).toISOString(),
        description: client.holidayName,
      })),
      projectMembers:this.projectMember,
      year: new Date().getFullYear().toString()
    };
    this.clientHolidayService.addClientHolidays(requestBody).subscribe(response => {
      this.dialogRef.close();
    });
  }


}
