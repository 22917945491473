<mat-card-content>
  <div
    class="container-sm w-1"
    style="padding-left: 20px; padding-top: 20px; padding-right: 20px"
  >
    <form>
      <mat-accordion>
        <mat-expansion-panel
          hideToggle
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title style="color: #1bbcd3">
              {{ project.projectName }}
            </mat-panel-title>
            <mat-panel-description style="color: #5a6263">
              {{ project.projectDescription }}
            </mat-panel-description>
            <mat-icon style="color: #82be41">feed</mat-icon>
          </mat-expansion-panel-header>
          <div class="form-group">
            <div class="center">
              <button class="btn btn-warning" (click)="openCreateDialog()">
                Create Feedback Forms
              </button>
              <button
                class="btn btn-success"
                (click)="openGenerateDialog()"
                style="margin: 35px"
              >
                Generate Feedbacks
              </button>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </form>
  </div>
</mat-card-content>
