import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { LeaveBalanceService } from 'src/app/services/leaveBalances/leave-balance.service';
import { TypeOfLeave } from 'src/app/services/leaveManagment/leave-enum';
import { EmployementType, GeoLocation } from 'src/app/services/user/user-enum';

@Component({
  selector: 'app-bulk-leave-configure',
  templateUrl: './bulk-leave-configure.component.html',
  styleUrls: ['./bulk-leave-configure.component.scss']
})
export class BulkLeaveConfigureComponent implements OnInit {
  bulkConfigureFormGroup: FormGroup;
  leaveTypes: string[] = Object.keys(TypeOfLeave).filter(type => type !== 'LOSS_OF_PAY');
  geoLocations: string[] = Object.keys(GeoLocation);
  employmentTypes: string[] = Object.keys(EmployementType);
  years: number[] = [];
  selectedYear: number;

  constructor(
    public dialogRef: MatDialogRef<BulkLeaveConfigureComponent>,
    public fb: FormBuilder,
    private leaveBalanceService: LeaveBalanceService,
    public toaster: ToastrService
  ) {
    this.bulkConfigureFormGroup = this.fb.group({
      geoLocation: ['', [Validators.required]],
      employmentType: ['', [Validators.required]],
      year: ['', [Validators.required]],
      leaveType: ['', [Validators.required]],
      creditOrDebit: ['', [Validators.required]],
      numberOfDays: [, [Validators.required, this.multipleOfValidator(0.25)]],
    });
  }

  ngOnInit(): void {
    this.getYearList();
  }

  getYearList() {
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year >= this.selectedYear - 10; year--) {
      this.years.push(year);
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  multipleOfValidator(divisor: number): ValidatorFn {
    return (control: FormControl) => {
      const value = control.value;
      if (value % divisor !== 0) {
        return {
          multipleOf: {
            valid: false,
            divisor: divisor,
          },
        };
      }
      return null;
    };
  }

  submit() {
    if (this.bulkConfigureFormGroup && this.bulkConfigureFormGroup.valid) {
      const leaveType = this.bulkConfigureFormGroup.get('leaveType').value;
      const creditOrDebit = this.bulkConfigureFormGroup.get('creditOrDebit').value;
      const numberOfDays = this.bulkConfigureFormGroup.get('numberOfDays').value;
      const count = creditOrDebit == "credit" ? numberOfDays : numberOfDays * (-1);
      const year = this.bulkConfigureFormGroup.get('year').value;
      const geoLocation = this.bulkConfigureFormGroup.get('geoLocation').value;
      const employmentType = this.bulkConfigureFormGroup.get('employmentType').value;
      this.leaveBalanceService.bulkConfigureByHR(leaveType, count, year, geoLocation, employmentType).subscribe((res) => {
        this.toaster.success('Successful', 'Configured', {
        });
        this.dialogRef.close();
      }, (error) => {
        this.toaster.error('Something went wrong', 'Error', {});
      })
    }

  }

}
