import { UserDTO } from "./UserDTO";
import { FormSchemaDTO } from "./FormSchemaDTO"
import { ClientDTO } from "./ClientDTO";

export class CareerManagerDTO {
    public id: String;
    public careerManagerId: String;
    public peers: UserDTO[];
    public capability: String;
    public careerManagerName: String;

    constructor() {
        this.id = null;
        this.careerManagerId = null;
        this.peers = [];
        this.capability = "";
        this.careerManagerName = "";
    }
}