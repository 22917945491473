<div class="main-error-container">
  <app-error-header></app-error-header>
  <div class="error-container">
    <div class="error-logo-container">
      <div class="error-logo">
        <img src="assets/images/session.png"/>
      </div>
    </div>
    <div class="error-details-container">
      <div class="error-details">
        <div class="error-name">Expired Session Error 419: Authentication Timeout</div>
        <div class="error-bar"></div>
        <div class="error-desc">Your session has expired. Please log in again to continue.</div>
        <a href="/login"><button class="error-white-btn">Back to Login</button></a>
      </div>
    </div>
  </div>
</div>