import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


//Primeng Material Components
import {ToastModule} from 'primeng/toast';
import {RatingModule} from 'primeng/rating';

@NgModule({
  declarations: [
  ],
  imports: [
    BrowserModule,
    ToastModule,
    RatingModule
  ],
  exports: [
    ToastModule,
    RatingModule
  ],
  providers: [],
  bootstrap: []
})
export class PrimengModule { }