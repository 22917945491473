<div class="container">
    <div class="main_header_div">
        <div class="head">
            <img src="assets/icons/dashboard-icons/calendar.svg" />
            Leave Configuration : {{ (userDetail.firstName | titlecase) + " " + (userDetail.lastName | titlecase)}} ({{userDetail.employeeId}})
        </div>
        <img class="close-icon-svg" (click)="closeDialog()" src="assets/icons/dashboard-icons/close-pink-icon.svg" />
    </div>
</div>

<div class="container">
    <form [formGroup]="leaveConfigureFormGroup">
        <div class="row first-row">
            <div class="col-md-4">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Leave Type</mat-label>
                    <mat-select formControlName="leaveType">
                        <mat-option *ngFor="let type of leaveTypes" [value]="type">
                            {{ type | underScoreToSpace | titlecase }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="
                  leaveConfigureFormGroup.get('leaveType').errors?.required
                ">
                        Required
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Credit or Debit</mat-label>
                    <mat-select formControlName="creditOrDebit">
                        <mat-option value="credit">Credit</mat-option>
                        <mat-option value="debit">Debit</mat-option>
                    </mat-select>
                    <mat-error *ngIf="
                  leaveConfigureFormGroup.get('creditOrDebit').errors?.required
                ">
                        Required
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Number of Days</mat-label>
                    <input matInput type="number" formControlName="numberOfDays" min="0" autocomplete="off" />

                    <mat-error *ngIf="
                    leaveConfigureFormGroup.get('numberOfDays').hasError('multipleOf')
                  ">
                        Days must be a multiple of 0.25
                    </mat-error>
                    <mat-error *ngIf="leaveConfigureFormGroup.get('numberOfDays').errors?.required">
                        Required
                    </mat-error>
                    <mat-error *ngIf="leaveConfigureFormGroup.get('numberOfDays').hasError('min')">
                        Days must be a Positive Integer
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row second-row">
            <div class="col-md-4">
                <div class="heading-main">
                    Leave Type: <span class="blue-heading">{{ selectedLeaveType | titlecase | underScoreToSpace}}</span>
                </div>
            </div>

            <div class="col-md-4">
                <div class="heading-main">
                    Credit or Debit: <span class="blue-heading">{{ selectedCreditOrDebit }}</span>
                </div>

            </div>

            <div class="col-md-4">
                <div class="heading-main">
                    Number of Days: <span class="blue-heading">{{ selectedNumberOfDays }}</span>
                </div>

            </div>
        </div>

        <div class="third-row">
            <button class="blue-button" (click)="submit()"> Configure</button>
        </div>

    </form>

</div>