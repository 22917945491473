
<div class="table-main-body">

    <table class="content-table">
        <thead>
            <tr class="table-head">
                <th class="heading-1">LeaveType/Holiday</th>
                <th class="heading-2">No. of Days</th>
                <th class="heading-3">Status</th>
                <th></th>
            </tr>
        </thead>
        <tbody *ngIf="isSpinner">
            <div class="spinner-div">
                <mat-spinner strokeWidth="7" [diameter]="150"></mat-spinner>
                <label class="loading_label">Loading...</label>
            </div>
        </tbody>
        <tbody *ngIf="!isSpinner">
            <div *ngIf="LeaveDetailsList.length == 0">
                <tr>
                    <td colspan="5" class="no-data-message">No data available</td>
                </tr>
            </div>
            <ng-container *ngIf="LeaveDetailsList.length > 0">
                <ng-container *ngFor="
              let detail of this.LeaveDetailsList | orderBy : 'startDate' : 'desc' | orderByApprovalStatus
            ">
                    <tr>
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <td class="content-1">
                                            {{
                                            detail.typeofLeave !== "FLOATER_LEAVE"
                                            ? detail.typeofLeave == "PLANNED_LEAVE"
                                            ? "Planned Leave"
                                            : (detail.typeofLeave | underScoreToSpace | titlecase)
                                            : "Floater"
                                            }}
                                        </td>
                                        <td class="content-2">{{ detail.days }}</td>
                                        <td class="content-1">{{detail.approvalStatus==='REVOKED_BY_USER'? 'Withdrawn ':
                                            detail.approvalStatus| underScoreToSpace | titlecase}}</td>
                                            <td class="rewoke-container-leave">
                                                <button class="rewoke-btn-leave" (click)="revokeBox(detail)">Withdraw</button>
                                            </td>
    
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr />
                                <div class="expansion">
                                    <div>Applied on : {{ detail.transactionDateTime | date }}</div>
                                    <div *ngIf="detail.reasonForLeave" class="reason-mat">Reason : {{ detail.reasonForLeave }}</div>
                                </div>
                                <div>
                                    Duration : {{ detail.startDate | date }} to
                                    {{ detail.endDate | date }}
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </tr>
                </ng-container>
            </ng-container>
    
    
        </tbody>
    </table>

</div>
