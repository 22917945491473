<div class="row functionalArea">
  <div class="col-2 blank"></div>

  <div class="col-8 align-item-center">
    <div class="wrapper">
      <div class="searchBar">
        <input
          id="searchQueryInput"
          type="text"
          name="searchQueryInput"
          (ngModelChange)="onSearchTextTyped($event)"
          [ngModel]="searchText"
          placeholder="Search Feedback Form"
          value=""
        />
        <button id="searchQuerySubmit" type="submit" name="searchQuerySubmit">
         <img src="assests/icons/search.png"/>
        </button>
      </div>
    </div>
  </div>

  <div class="col-2 blank text-center addButtonCol">
    <button mat-flat-button class="addButton" (click)="onAddFormTemplate()">
      <mat-icon
        class="alignExpand whiteIcon"
        aria-hidden="false"
        aria-label="Example home icon"
        >person_add</mat-icon
      >
    </button>
    <p class="h6" style="margin-top: 5px">Create Feedback Form</p>
  </div>
</div>
<div class="row displayArea">
  <div class="col cardContent">
    <app-feedback-form-card-shared
      class="col-3"
    ></app-feedback-form-card-shared>
  </div>
</div>
<div class="row">
  <div
    class="row justify-content-center fixed-bottom"
    style="margin-bottom: 10px"
  >
    <mat-paginator
      [length]="this.formService.totalCount"
      [pageSize]="this.formService.pageSize"
      (page)="this.formService.page = $event; OnPageClick($event)"
    >
    </mat-paginator>
  </div>
</div>
