<div class="main_header_div">
  <div class="child">
    <h1 class="header_name"><img class="header-icon mr-3" src="assets\icons\skills.png">Professional Skills</h1>
  </div>
  <div class="pr-3 pt-3">
    <img class="close-icon" (click)="closeDialog($event)" src="assets/icons/close.png">
  </div>
</div>
<hr />
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 form_main_div">
      <div class="add_button_fix">
      </div>
      <form [formGroup]="editSpotlightDetails">
        <div class="bodyScroll">
          <div class="row" formArrayName="skills">
            <div class="col-md-12">
              <mat-form-field class="example-chip-list">
                <mat-chip-list #chipList1>
                  <mat-chip *ngFor="let skill of primarySkillsArray.controls; let i = index" [selectable]="true"
                    [removable]="true" (removed)="remove(i,true)">
                    {{ skill.value }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                  <input placeholder="New Primary skill..." [matChipInputFor]="chipList1"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="add($event,true)">
                </mat-chip-list>
                <mat-error *ngIf="chipList1.errorState">Invalid Skill</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-12">
              <mat-form-field class="example-chip-list">
                <mat-chip-list #chipList2>
                  <mat-chip *ngFor="let skill of secondarySkillsArray.controls; let i = index" [selectable]="true"
                    [removable]="true" (removed)="remove(i,false)">
                    {{ skill.value }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                  <input placeholder="New Secondary skill..." [matChipInputFor]="chipList2"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="add($event,false)">
                </mat-chip-list>
                <mat-error *ngIf="chipList2.errorState">Invalid Skill</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="submit_button_main">
              <button class="save_Emp_btn" type="submit" (click)="onSubmit()">
                <span>Save</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>