<div
  class="notification-main-div"
  *ngIf="isNotificationVisible"
  (click)="closeDialog()"
>
  <div>
   <img class="not-icon" src="assets/icons/notification-icons/active-bell-icon.svg">
  </div>

  <div class="triangle-icon-div">
    <img class="triangle-icon" src="assets/icons/triangle.png">
  </div>
</div>

<div *ngIf="isLoading" class="spinner-div">
  <mat-spinner strokeWidth="7" [diameter]="150"></mat-spinner>
</div>

<div
  class="clr-button-div"
  *ngIf="!isLoading && notificationList && notificationList.length != 0"
>
  <button class="clr-button" (click)="clearAllNotifications()">
    Clear all
  </button>
</div>

<div
  class="no-goal"
  *ngIf="
    !notificationList || (notificationList && notificationList.length == 0)
  "
>
  <img class="no-goal-img" src="assets\images\no-goal.png" alt="" />
  <div class="no-goal-assign">Hurray! You are all caught up!</div>
</div>

<table
  class="custom-table"
  *ngIf="!isLoading && notificationList && notificationList.length != 0"
>
  <tbody *ngFor="let notification of notificationList">
    <tr class="">
      <td>
        <div class="blue-color">{{notification.approvalStatus | titlecase}}</div>
        <div class="desc-text">{{(notification.typeOfLeave == 'PLANNED_LEAVE' ? 'PLANNED_LEAVE' : notification.typeOfLeave) | underScoreToSpace | titlecase}} {{notification.description}}</div>
      </td>
      <td>
        <div class="svg-container">
          <img class="close-icon"
            (click)="clearSingleNotification(notification.id)"
            src="assets/icons/dashboard-icons/close-pink-icon.svg"
          />
        </div>
      </td>
    </tr>
  </tbody>
</table>
