import { NewFeedbackDTO } from "../dtos/newFeedbackDTO";
import { FeedbackRole, FeedbackTemplateCategory } from "../services/feedback/feedback-enum";
import { Feedback } from "./feedback";
interface IFeedbackGroup {
    id?: string;
    title?:string;
    projectId?:string;
    createdBy?:string;
    isClosed?:Boolean;
    groupWeightage?:Number;
    overallScore?:Number;
    year?:string;
    feedbackFor?:string;
    feedbackType?:FeedbackTemplateCategory;
    feedbackFrom?:Array<NewFeedbackDTO>;
}
export class FeedbackGroup {
    title:string;
    projectId:string;
    createdBy:string;
    isClosed:Boolean;
    groupWeightage:number;
    overallScore:Number;
    year:string;
    feedbackFor:string;
    feedbackType:FeedbackTemplateCategory;
    feedbackFrom:Array<NewFeedbackDTO>;
    constructor() {
        this.feedbackFrom=[]
    }
}