<div class="main_header_div">
  <h3 mat-dialog-title>Reason for Withdrawal</h3>
  <div class="child_close_btn">
    <img class="close-icon-svg" (click)="closeDialog('error')" src="assets/icons/dashboard-icons/close-pink-icon.svg" />
  </div>
</div>

<form [formGroup]="rejectionDetails">
  <div class="col">
    <mat-form-field appearance="outline" class="example">
      <textarea
        matInput
        formControlName="reasonForRejection"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="4"
        cdkAutosizeMaxRows="7"
        placeholder="Reason in words"
      ></textarea>
    </mat-form-field>
  </div>
</form>

<div class="submit_button_main">
  <button class="add_Emp_btn" type="submit" (click)="revokeLeave()">
    <span>Submit</span>
  </button>
</div>
