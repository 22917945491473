import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { LeaveBalanceService } from 'src/app/services/leaveBalances/leave-balance.service';
import { TypeOfLeave } from 'src/app/services/leaveManagment/leave-enum';

@Component({
  selector: 'app-single-employee-configure',
  templateUrl: './single-employee-configure.component.html',
  styleUrls: ['./single-employee-configure.component.scss']
})

export class SingleEmployeeConfigureComponent implements OnInit {
  leaveConfigureFormGroup: FormGroup;
  leaveTypes: string[] = Object.keys(TypeOfLeave).filter(type => type !== 'LOSS_OF_PAY');
  selectedLeaveType: string;
  selectedCreditOrDebit: string;
  selectedNumberOfDays: number;
  userDetail: UserDTO;

  constructor(
    public dialogRef: MatDialogRef<SingleEmployeeConfigureComponent>,
    public fb: FormBuilder,
    private leaveBalanceService: LeaveBalanceService,
    public toaster: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.leaveConfigureFormGroup = this.fb.group({
      leaveType: ['', [Validators.required]],
      creditOrDebit: ['', [Validators.required]],
      numberOfDays: [, [Validators.required, this.multipleOfValidator(0.25)]],
    });
  }

  ngOnInit(): void {
    this.userDetail = this.data.userDetail;
    this.leaveConfigureFormGroup.valueChanges.subscribe(values => {
      this.selectedLeaveType = values.leaveType;
      this.selectedCreditOrDebit = values.creditOrDebit;
      this.selectedNumberOfDays = values.numberOfDays;
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  multipleOfValidator(divisor: number): ValidatorFn {
    return (control: FormControl) => {
      const value = control.value;
      if (value % divisor !== 0) {
        return {
          multipleOf: {
            valid: false,
            divisor: divisor,
          },
        };
      }
      return null;
    };
  }

  submit() {
    if(this.leaveConfigureFormGroup && this.leaveConfigureFormGroup.valid){
      const leaveType = this.leaveConfigureFormGroup.get('leaveType').value;
    // Check if the selected leave type is accrued and credit operation is chosen
    if (leaveType === "ACCRUED_LEAVE" && this.selectedCreditOrDebit === 'credit') {
      this.leaveBalanceService.getLeaveBalanceByIdAndYear(this.userDetail.id, this.data.year).subscribe(res => {
        const plannedLeaveBalance = res.body.balanceLeave.PLANNED_LEAVE;
        const numberOfDays = this.leaveConfigureFormGroup.get('numberOfDays').value;
        if (numberOfDays > plannedLeaveBalance) {
          this.toaster.error('Accrued leave credit cannot exceed planned leave balance.', 'Error', {});
          return;
        } else {
          this.completeLeaveBalanceConfiguration(leaveType);
        }
      });
    } else {
      this.completeLeaveBalanceConfiguration(leaveType);
    }
    }
    
  }

  completeLeaveBalanceConfiguration(leaveType: string) {
    const creditOrDebit = this.leaveConfigureFormGroup.get('creditOrDebit').value;
    const numberOfDays = this.leaveConfigureFormGroup.get('numberOfDays').value;
    const count = creditOrDebit == 'credit' ? numberOfDays : numberOfDays * -1;
    this.leaveBalanceService
      .addLeaveBalanceByHR(leaveType, count, this.userDetail.id, this.data.year)
      .subscribe(
        (res) => {
          this.toaster.success('Successful', 'Configured', {});
          this.dialogRef.close();
        },
        (error) => {
          this.toaster.error('Something went wrong', 'Error', {});
        }
      );
  }

}
