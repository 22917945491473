<div class="container">
  <div class="row">
    <div class="col-md-3 search-bar  mt-3">
      <img src="\assets\svg\search_bar.svg" alt="search" />
      <input type="search" class="search-input" placeholder="Search "
        />
    </div>
    <div class="btn col-md-2 mt-3">
      <div class="centered-content">
        <img class="img-class-bulk" src="assets/icons/AddNewEmployee.png" />
        <button (click)="addClientHoliday()" class="btn-common">
          <span class="font-size">Add Holiday </span>
        </button>
      </div>
    </div>
    <div class="col-md-2 mat-form-field-common mt-3">
      <mat-form-field appearance="outline" mat-form-field-common class="example-full-width">
        <mat-label>Year <span class="contentRed"></span></mat-label>
        <mat-select formControlName="year">
          <mat-option *ngFor="let year of years" [value]="year">
            {{ year }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 mat-form-field-common mt-3">
      <mat-form-field appearance="outline" mat-form-field-common class="example-full-width">
        <mat-label>Client Name <span class="contentRed"></span></mat-label>
        <mat-select formControlName="clientName" (selectionChange)="onClientChange($event)">
          <mat-option *ngFor="let  client of clientList" [value]="client">
            {{ client.clientName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 mat-form-field-common mt-3">
      <mat-form-field appearance="outline" mat-form-field-common class="example-full-width">
        <mat-label>Project Name<span class="contentRed"></span></mat-label>
        <mat-select formControlName="projectName" (selectionChange)="onprojectSelectionChange($event)">
          <mat-option *ngFor="let project of projects" [value]="project">
            {{ project.projectName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  
  </div>
  <div class="mt-3 row">
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Holiday Date</th>
            <th>Holiday Name</th>
            <th>
              <div class="table-header-cell">

                <span class="name">Client Name</span>
              </div>
            </th>
            <th>
              <div class="table-header-cell">

                <span class="name">Project Name</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let client of clientsHolidays">
            <td>{{ client.date | date }}</td>
            <td>{{ client.description }}</td>
            <td>{{client.clientName}}</td>
            <td>{{client.projectName}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>