<div class="main-div">
    <div class="leave-heading-div">
        <img [src]="leaveData.iconSrc" alt="calendar" class="leave-calendar-icon">
        <label class="leave-type-lbl">{{ leaveData.name | underScoreToSpace | titlecase }}</label>
        <img src="\assets\icons\close.png" alt="close" class="close-btn" (click)="closeDialog()">
    </div>
    <div class="hr-bar"></div>
    <div class="leave-history-div">
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Transaction</th>
                        <th>Posted on</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Days</th>
                        <th>Reason</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let transaction of transactions">
                        <td>{{ transaction.transaction | titlecase | underScoreToSpace}}</td>
                        <td>{{ transaction.transactionTime | date: 'dd-MM-yyyy'}}</td>
                        <td>{{ transaction.from | date: 'dd-MM-yyyy'}}</td>
                        <td>{{ transaction.to | date: 'dd-MM-yyyy' }}</td>
                        <td>{{ transaction.transactionCount | absolute }}</td>
                        <td>{{ transaction.reason }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>