<div class="row holiday">
  <div class="col-md-7 col-sm-12">
    <mat-card class="calendar-card-1">
      <full-calendar
        class="full-calendar-1"
        #fullCalendar
        [options]="calendarOptions"
      ></full-calendar>
    </mat-card>
    <div class="color-leg">
      <div class="colour-main">
        <div class="Leave colours-div"></div>

        <div class="colour-label">
          <label>On Leave</label>
        </div>
      </div>

      <div class="colour-main">
        <div class="general colours-div"></div>

        <div class="colour-label">
          <label>Present</label>
        </div>
      </div>

      <div class="colour-main">
        <div class="Restricted colours-div"></div>

        <div class="colour-label">
          <label>Regularized</label>
        </div>
      </div>

      <div class="colour-main">
        <div class="Unmarked colours-div"></div>

        <div class="colour-label">
          <label>Absent</label>
        </div>
      </div>

      <div class="colour-main">
        <div class="Client colours-div"></div>

        <div class="colour-label">
          <label>Holiday</label>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-5 col-sm-12">
    <div class="row">
      <div class="col-md-12">
        <div class="user-select-main-div">
          <div class="user-select-child-div ">
            <mat-form-field appearance="none">
              <div class="user-detail-div">
                <div>
                  <img class="user-img" src="assets/icons/default-avatar.jpg" alt=""
                    *ngIf="!userDetail.userImageThumbnail || userDetail.userImageThumbnail === ''">
                  <img class="user-img" [src]="getSafeImageUrl(userDetail.userImageThumbnail)" alt=""
                    *ngIf="userDetail.userImageThumbnail && userDetail.userImageThumbnail !== ''">
                </div>
                <div class="user-name-id-div">
                  <div>{{ userDetail?.firstName }} {{ userDetail?.lastName }}</div>
                  <div>
                    {{ userDetail?.employeeId }}
                    <ng-container *ngIf="userDetail?.baseLocation">
                      ({{ userDetail?.baseLocation }})
                    </ng-container>
                  </div>
                </div>
              </div>
              <mat-select #select (selectionChange)="selectEmployee($event)" [(value)]="userDetail">

                <div class="lms-search-main ">
                  <img class="lms-search-img" src="assets/icons/dashboard-icons/search-icon.svg" />
                  <input class="lms-search-input" type="text" placeholder="Search"
                    (keyup)="searchEmployee($event.target.value)" autocomplete="off">
                </div>

                <mat-option *ngFor="let user of filterEmployee" [value]="user">
                  <div class="user-detail-div">
                    <div>
                      <img class="user-img" src="assets/icons/default-avatar.jpg" alt=""
                        *ngIf="!user.userImageThumbnail || user.userImageThumbnail === ''">
                      <img class="user-img" [src]="getSafeImageUrl(user.userImageThumbnail)" alt=""
                        *ngIf="user.userImageThumbnail && user.userImageThumbnail !== ''">
                    </div>
                    <div class="user-name-id-div">
                      <div class="user-name-div">{{user?.firstName}} {{user?.lastName}}</div>
                      <div>
                        {{user?.employeeId}}
                        <ng-container *ngIf="user?.baseLocation">
                          ({{ user?.baseLocation }})
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card-2">
          <div class="holiday-align">
            <div class="icon-holiday">
              <img src="assets/icons/calendar-icons/missing-attendance.svg"/>
              <div class="holiday-heading">Missing Attendance</div>
            </div>
            <div class="holidays">{{selectedMonth}}-{{selectedYear}}</div>
          </div>
          <div class="card-3">
            <div  *ngIf="this.val.length > 0; else noItems">
            <div  *ngFor="let item of this.val | orderBy : 'attendanceDateTime'" >
              <div *ngIf="item.appliedForRegularization == false" >
                <div class="unmarked">
                {{ item.attendanceDateTime | date }}
              <div
                class="action"
                *ngIf="item.appliedForRegularization == false"
              >
              <div >
                <button class="btn-c"  [disabled]="leaveApplyDisabled" (click)="redirectToLeaveApply( item.attendanceDateTime)">
                  Apply Leave
                </button>
                <button class="btn-c" [disabled]="regularizationDisabled" (click)="openRegularizeDialog(item)">
                  Regularization
                </button>
                </div>
              </div>
              </div>
              </div>
            </div>
            </div>
            <ng-template #noItems>
              <div class="no-leave no-leave-container">
                  <img class="no-leave-img" src="\assets\no-regularization.png" alt="">
                  <div class="no-leave-assign">
                      <div class="no-leave-assign">No data</div>
                  </div>
              </div>
          </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
