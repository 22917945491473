import { Component, OnInit } from '@angular/core';
import { ClientHolidayService } from 'src/app/services/clientHoliday/client-holiday.service';
import { ClientService } from 'src/app/services/client/client.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditHolidayComponent } from '../edit-holiday/edit-holiday.component';
import { ProjectService } from 'src/app/services/project.service';
import { AddClientHolidayComponent } from './add-client-holiday/add-client-holiday.component';

@Component({
  selector: 'app-client-holidays',
  templateUrl: './client-holidays.component.html',
  styleUrls: ['./client-holidays.component.scss'],
})
export class ClientHolidaysComponent implements OnInit {
  years: number[] = [];
  selectedYear: number;
  clientList=[];
  projectList=[];
  projects:any[]=[];
  clientsHolidays=[];
  clientId:any;
  projectId:any;
  clientName:any;
  projectName:any;

  constructor(
    public clientHolidayService: ClientHolidayService,
    public clientservice: ClientService,
    public projectService:ProjectService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year >= 2015; year--) {
      this.years.push(year);
    }
    this.getClients();

  }
  addClientHoliday(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '70%';
    const dialogRef = this.dialog.open(AddClientHolidayComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {

    });
  }
  getClients() {
    this.clientservice.getClientsSubscribe().subscribe((res) => {
        for (let r of res.body) {
            this.clientList.push(r);
            this.projectService.getprojectfromclient(r.id).subscribe(response => {
                const projectsWithMembers = response.body.map(project => {
                    return {
                        projectId: project.projectId,
                        projectName: project.projectName,
                        projectMembers: project.projectMembers 
                    };
                });
                this.projectList.push({ clientId: r.id, clientName: r.clientName, projects: projectsWithMembers }); 
            });
        }
    });
}


onClientChange(event): void {
      const clientId = event.value.id ;
      this.clientName=event.value.clientName;
      this.projectService.getprojectfromclient(clientId).subscribe(response => {
          const projectsWithMembers = response.body.map(project => {
              return {
                  projectName: project.projectName,
                  projectMembers: project.projectMembers,
                  clientId:clientId,
              };
          });
          this.projects = projectsWithMembers;
      });
      this.clientId= clientId;
}

onprojectSelectionChange(event) {
  this.clientId= event.value.clientId;
  const projectId = event.value.projectMembers[0].projectId;
  this.projectName=event.value.projectName;
  this.projectId= projectId;
  this.getClientHolidays();
}

getClientHolidays(){
  this.clientHolidayService.getClientHoliday(this.selectedYear,this.clientId,this.projectId).subscribe((res)=>{
    for (let r of res.body){
     const clientDetails={
      date:r.dateTime,
      description:r.description,
      clientName:this.clientName,
      projectName:this.projectName
    }
    this.clientsHolidays.push(clientDetails);
    }
  });
}

 
 
}
