import { ProjectRole } from "../services/user/user-enum";

interface PTalent {
    talentName:String;
    role:  string;
    skill: string;
    projectId:string;
    startDate : Date;
    endDate :Date;
    allocationTime:String;
    userId:String;
    id:string;
}
export class Talent{
    public talentName: String;
    public role: string;
    public skill : String
    public projectId: String;
    public startDate: Date
    public endDate :Date
    public isReleased:Boolean
    public allocationTime:String;
    public userId :string;
    public id:String;
    constructor(){
        this.talentName=''
        this.isReleased=false
        this.skill = ''
        this.id = undefined
        this.projectId=''
        this.userId = ''
        this.startDate=undefined
        this.endDate=undefined
        this.allocationTime=''
    }
    bind(httpResponse:any){
        this.id = httpResponse && httpResponse.id || undefined
        this.role = httpResponse && httpResponse.role || undefined
        this.skill = httpResponse && httpResponse.skill || undefined
        this.isReleased = httpResponse && httpResponse.isReleased || undefined
        this.projectId = httpResponse && httpResponse.projectId || undefined
        this.startDate = httpResponse && new Date(httpResponse.startDate) || undefined
        this.endDate = httpResponse && new Date(httpResponse.endDate) || undefined 
        this.allocationTime=httpResponse && httpResponse.allocationTime || undefined
        this.userId = httpResponse && httpResponse.userId || undefined 
        this.talentName = httpResponse && httpResponse.talentName  || undefined
        return this;     
    }
}
