import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ButtonStateService {
  private syncButtonDisabledSubject = new BehaviorSubject<boolean>(false);

  getSyncButtonDisabledState(): Observable<boolean> {
    return this.syncButtonDisabledSubject.asObservable();
  }

  setSyncButtonDisabledState(disabled: boolean): void {
    this.syncButtonDisabledSubject.next(disabled);
  }
}
