import { Injectable } from '@angular/core';
import { EmergencyContact } from 'src/app/models/emergencycontact';
import { EmergencycontactUrlService } from '../restAPI/emergencyContactURLs/emergencycontact-url.service';
import { RestAPIService } from '../restAPI/rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class EmergencycontactService {
  constructor(private restApiService: RestAPIService,
    private emergencyurl: EmergencycontactUrlService
     ) { }
   postdocument(payload) {
     return this.restApiService.post(
       this.emergencyurl.getBaseUrl(),
       payload
     )
   }
   putdocument(payload) {
     return this.restApiService.put(
       this.emergencyurl.getBaseUrl(),
       payload
     )
   }
   getemerbyuserId(emer,user){
     return this.restApiService.get(
       this.emergencyurl.getUserUrl(emer,user)
     )
   }
 }
