import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GoalService } from 'src/app/services/goal/goal.service';
import { GoalType } from 'src/app/services/goalSheet/goal-sheet-enum';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { SubgoalService } from 'src/app/services/subgoal/subgoal.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { UserGoalService } from 'src/app/services/usergoal/user-goal.service';
import { AssignGoalComponent } from './goal-sheet/assign-goal/assign-goal.component';
import { CmGoalSheetComponent } from './goal-sheet/cm-goal-sheet/cm-goal-sheet.component';

@Component({
  selector: 'app-career-manager',
  templateUrl: './career-manager.component.html',
  styleUrls: ['./career-manager.component.scss'],
})
export class CareerManagerComponent implements OnInit {
  userid: any;
  myReporteeTabVisible = true;
  feedbackGenerationCMShow = true;
  hasRoleBased: any;
  initialAvatar;
  matTabsClickedIndex = 0;
  name: any;
  map = new Map<String, boolean>();
  year: any;
  isCareerManager = false;
  hasHrRole = false;
  @ViewChild(CmGoalSheetComponent) cmGoalSheetComponent: CmGoalSheetComponent;

  constructor(
    private goalService: GoalService,
    private userService: UserService,
    private initialAvatarService: InitialAvatarService,
    public userDetailService: UserDetailsService,
    public subGoal: SubgoalService,
    public usergoalService: UserGoalService,
    public dialogue: MatDialog
  ) {
    this.userService.myReporteeTabVisibility.subscribe((res) => {
      this.myReporteeTabVisible = res;
    });
  }

  ngOnInit(): void {
    this.matTabsClickedIndex = 0;
    this.hasHrRole = this.userDetailService.hasRole([
      'ROLE_HUMAN_RESOURCE',
      'ROLE_SUPER_ADMIN',
    ]);
    this.isCareerManager = this.userDetailService.hasRole([
      'ROLE_CAREER_MANAGER',
    ]);
    this.hasRoleBased = this.userDetailService.hasRole(['ROLE_USER']);
    this.goalService.resetGoalService();
    this.name = this.userService.processName(
      this.userDetailService.getUserDetails()
    );
    this.initialAvatar = this.initialAvatarService.getInitialImage(
      this.userDetailService.getUserDetails().firstName,
      this.userDetailService.getUserDetails().lastName
    );
    this.year = new Date().getFullYear();
    this.userid = this.userDetailService.getUserDetails().id;
    this.getGoal();
  }
  getGoal() {
    this.usergoalService
      .getGoalByUserAndYear(this.userid, this.year)
      .subscribe((res) => {
        for (let temp of res.body) {
          this.subGoal.getSubgoalByUserGoal(temp.id).subscribe((r) => {
            if (r.body.length == 0) {
              this.map.set(temp.goalType, false);
            } else {
              this.map.set(temp.goalType, true);
            }
          });
        }
      });
  }
  openAssignGoalDialogue() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '90%';
    dialogConfig.height = '50%';
    dialogConfig.data = this.map;
    const dialogRef = this.dialogue
      .open(AssignGoalComponent, dialogConfig)
      .afterClosed()
      .subscribe((result) => {
        if (result.event == 'Closed') {
          this.cmGoalSheetComponent.ngOnInit();
          this.getGoal();
        }
      });
  }

  onTabChanged($event) {
    this.usergoalService.setCurrentYear(new Date().getFullYear());
    this.matTabsClickedIndex = $event.index;
    if (this.matTabsClickedIndex == 1) {
      this.userService.myReporteeTabVisibility.next(true);
    }
  }
  goalSheetSelf(index) {
    if (index == 0) {
      this.goalService.resetGoalService();
      for (let goalType in GoalType) {
        if (isNaN(Number(goalType))) {
          this.goalService.setGoalServiceSelf(
            goalType,
            this.userDetailService.getUserDetails().id,
            new Date().getFullYear()
          );
        }
      }
    }
  }
}
