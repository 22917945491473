<div class="container">
  <div class="row mt-2">
    <div class="col mt-1">
      <mat-tab-group>
        <mat-tab label="Pending Feedback">
          <app-feedback-tab-content
            [feedbacksList]="this.feedbackService.feedbackPendingList"
            [disableEdit]="disableEdit"
          ></app-feedback-tab-content>
        </mat-tab>
        <mat-tab label="Submitted Feedback">
          <app-feedback-tab-content
            [feedbacksList]="this.feedbackService.feedbackSubmittedList"
            [disableEdit]="!disableEdit"
          ></app-feedback-tab-content>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
