import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public static LOGGED_IN_USER_INFO: string = 'loggedInUser';
  public static LOGGED_IN_USER_TYPE: string = 'loggedInUserType';
  public static JWT_TOKEN: string = 'jwtToken';
  public static REFRESH_TOKEN:string = 'refreshToken'
  public static ACCESS_TOKEN:string = 'accessToken'
  public static IS_LOGGED_OUT:string = 'false';
  public static USER_INFO:string='user_info';
  public static LOGIN_TYPE:string='login_type';

  constructor() { }

  getItem(item) {
    return localStorage.getItem(item);
  }

  setItem(key, value) {
    localStorage.setItem(key, value);
  }

  removeItem(item) {
    localStorage.removeItem(item);
  }
}
