import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { th } from 'date-fns/locale';
import { Toast, ToastRef, ToastrService } from 'ngx-toastr';
import { FeedbackRole } from 'src/app/services/feedback/feedback-enum';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-add-new-feedback',
  templateUrl: './add-new-feedback.component.html',
  styleUrls: ['./add-new-feedback.component.scss'],
})
export class AddNewFeedbackComponent implements OnInit {
  name: string = '';
  email: string = '';
  userNameIDMapping = {};
  userIdNameMapping = {};
  temp = {};
  userList;
  searchValue = '';
  userID: any;
  FeedbackRole = [];
  feedbackFrom;
  feedbackRole;
  weightage;
  formGroup: FormGroup;
  displaySelectedArea;
  checkSum = 0;
  emailError: string;
  constructor(
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    public dialogRef: MatDialogRef<AddNewFeedbackComponent>,
    public toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.feedbackFrom = this.data[1];
    this.feedbackRole = this.data[2];
    this.weightage = this.data[3];
    this.checkSum = this.data[4] - this.data[3];
    this.formGroup = this.fb.group({
      weightage: ['  ', [Validators.required]],
      feedbackFrom: ['  ', [Validators.required]],
      feedbackRole: ['  ', [Validators.required]],
    });
    this.formGroup.get('weightage').setValue(this.data[3]);
    this.formGroup.get('feedbackFrom').setValue(this.data[1]);
    this.formGroup.get('feedbackRole').setValue(this.data[2]);

    this.userService.getAllUserForDropDown().subscribe((usrData) => {
      usrData.body.forEach((element) => {
        if (element.userID != this.data[0]) {
          this.userNameIDMapping[
            element.firstName +
              ' ' +
              element.lastName +
              '(' +
              element.employeeId+
              ')'
          ] = element.userID;
          this.userIdNameMapping[element.userID] =
            element.firstName +
            ' ' +
            element.lastName +
            '(' +
            element.employeeId +
            ')';
          this.temp[element.firstName + ' ' + element.lastName] =
            element.userID.toUpperCase();
        }
        this.feedbackFrom = this.userIdNameMapping[this.feedbackFrom];
      });
      this.formGroup
        .get('feedbackFrom')
        .setValue(this.userIdNameMapping[this.data[1]]);
      this.userList = Object.keys(this.userNameIDMapping);
      this.displaySelectedArea = this.userList;
    });
    for (let role of Object.values(FeedbackRole)) {
      if (isNaN(Number(role))) {
        this.FeedbackRole.push(role);
      }
    }
  }
  onWeightagetyped(event) {
    let temp = 0;
    if (this.checkSum + parseInt(event) > 100) {
      this.setFormControlError(
        'weightage',
        'InvalidWeightage',
        'Please Enter Valid Percentage'
      );
    }
  }
  setFormControlError(
    controlName: string,
    controlError: string,
    errorMsg: string
  ) {
    this.formGroup.get(controlName).markAsTouched({ onlySelf: true });
    this.formGroup.get(controlName).markAsDirty({ onlySelf: true });
    this.formGroup.get(controlName).setErrors({ [controlError]: true });
    this.emailError = errorMsg ? errorMsg : 'Enter valid percentage';
  }
  checkError = (controlName: string, errorName: string) => {
    return this.formGroup.controls[controlName].hasError(errorName);
  };
  search(query: string, i) {
    let result = this.select(query);
    if (result.length != 0) {
      this.displaySelectedArea = result;
    }
    this.displaySelectedArea = result;
  }
  select(query: string): string[] {
    let result: string[] = [];
    for (let a of this.userList) {
      if (a.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        result.push(a);
      }
    }
    return result;
  }
  focusOutEvent(i) {}

  submitForm() {
    this.formGroup;
    if (this.formGroup.valid) {
      let result = {
        feedbackFrom: this.formGroup.controls.feedbackFrom.value,
        feedbackRole: this.formGroup.controls.feedbackRole.value,
        weightage: this.formGroup.controls.weightage.value,
      };
      this.dialogRef.close(result);
    } else {
      this.toaster.error('Fill required field', 'Failed');
    }
    // Handle form submission and data processing here
  }

  getUserID(username, i) {
    this.userID = this.userNameIDMapping[username] || this.temp[username];
    this.feedbackFrom = this.userID;
  }
  selectedRole(role, i) {
    this.feedbackRole = role;
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
