import { Injectable } from '@angular/core';
import { RestAPIService } from '../restAPI/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class StatusResignationService {

  constructor(
    private restApi: RestAPIService,

    ) {}

  getStatusResignation(){
    return this.restApi.get("/api/exit-details/status/employee");
  }
}
