import { User } from '../models/user';
import { TimeZoneStandard } from "@microsoft/microsoft-graph-types";


export class ExitDetailsDTO {
    public exitReason: String
    public resignationDate: Date
    public noticePeriodServedInDays: Number
    public lastWorkingDay: TimeZoneStandard
    public ccEmails: string;
    public userId: User
        constructor() {
 
        this.exitReason="";
        this.noticePeriodServedInDays=0;
        this.ccEmails="";
        this.resignationDate= new Date();
    }
}
