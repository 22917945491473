import { Injectable } from '@angular/core';
import { RestAPIService } from '../restAPI/rest-api.service';
import { environment } from 'src/environments/environment';
import { AlertsService } from '../alerts.service';
@Injectable({
  providedIn: 'root'
})
export class BulkEmployeeImportService {
  public response:any;
  constructor(private restApi: RestAPIService) { 
    this.response = null;
  }

  uploadBulkEmployees(formData){
   return this.restApi.post('/api/user/upload-bulk', formData);
  }
}
