import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ResignationemailComponent } from '../../resignationemail/resignationemail.component';
import { StatusResignationService } from 'src/app/services/status-resignation/status-resignation.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { Status } from 'src/app/services/user/user-enum';
@Component({
  selector: 'app-reliving-process',
  templateUrl: './reliving-process.component.html',
  styleUrls: ['./reliving-process.component.scss'],
})
export class RelivingProcessComponent implements OnInit {
  disableAgreement: boolean = true;
  constructor(
    private dialog: MatDialog,
    private statusResignationService: StatusResignationService,
    private userDetailService: UserDetailsService
  ) { }
  userId: string;
  status: string[] = [];
  taskColor: string;
  statusColor: string;
  ngOnInit(): void {
    this.initiateComponent()
  }
  initiateComponent() {
    this.userId = this.userDetailService.getUserDetails().id;
    this.statusResignationService.getStatusResignation().subscribe((res) => {
      this.status = res.body;
      for (let s of this.status) {
        if (s === Status.APPLIED) {
          this.taskColor = 'applied_task';
          this.statusColor = 'applied_status';
        } else if (s === Status.ACCEPTED) {
          this.taskColor = 'accepted_task';
          this.statusColor = 'accepted_status';
        } else if (s === Status.REJECTED) {
          this.taskColor = 'rejected_task';
          this.statusColor = 'rejected_status';
        } else if (s === Status.KNOWLEDGE_TRANSFER_STARTED) {
          this.taskColor = 'KT_task';
          this.statusColor = 'KT_status';
        }
      }
    });
  }
  relivingEnable(event) {
    this.disableAgreement = !event.checked;
  }
  open() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '100%';
    dialogConfig.height = '95%';
    const dialogRef = this.dialog.open(ResignationemailComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.initiateComponent();
    });
  }
}
