<div class="container-fluid" id="content">
  <div class="row">
    <div class="col-md-12">
      <!-- Asset List starts -->
      <mat-tab-group
        class="mt-2 mr-5"
        (selectedTabChange)="onTabChanged($event)"
      >
        <mat-tab label="Assets">
          <div class="mt-4">
            <app-asset-card> </app-asset-card>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
