<div class="container main-div">
    <div class="row">
        <div class="col-lg-6 col-md-4 col-sm-12">
            <div class="child-div">
                <img src="\assets\calendar-icon\blue-calendar.svg" alt="calendar">
                <Label class="lb-lbl">Leave Balance</Label>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-12">
            <div class="child-div">
                <mat-form-field  appearance="none">
                    Year
                    <mat-select [(value)]="selectedYear" (selectionChange)="onYearChange()">
                        <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-12">
            <div class="child-div user">
                <div class="user-detail-div" *ngIf="!isCM">
                    <div class="center" *ngIf="userDetail.userImageThumbnail === ''">
                        <img class="user-img" src="assets/icons/default-avatar.jpg" alt="" />
                    </div>
                    <div class="center" *ngIf="userDetail.userImageThumbnail !== ''">
                        <img class="user-img" [src]="userDetail?.userImageThumbnail" alt="">
                    </div>
                    <div class="user-name-id-div">
                        <div>{{ userDetail?.firstName }}</div>
                        <div>{{ userDetail?.employeeId }}</div>
                    </div>
                </div>
                <mat-form-field appearance="none" *ngIf="isCM">
                    <mat-select #select (selectionChange)="selectEmployee($event)" [(value)]="userDetail">
                        <mat-select-trigger>
                            <div class="user-detail-div">
                                <div *ngIf="userDetail.userImageThumbnail === ''">
                                    <img class="user-img" src="assets/icons/default-avatar.jpg" alt="" />
                                </div>
                                <div *ngIf="userDetail.userImageThumbnail !== ''">
                                    <img class="user-img" [src]="userDetail?.userImageThumbnail" alt="">
                                </div>
                                <div class="user-name-id-div">
                                    <div>{{ userDetail?.firstName }} {{ userDetail?.lastName }}</div>
                                    <div>{{ userDetail?.employeeId }}</div>
                                </div>
                            </div>
                        </mat-select-trigger>
                        <div class="lms-search-main ">
                            <img class="lms-search-img" src="assets/icons/dashboard-icons/search-icon.svg" />
                            <input class="lms-search-input" type="text" placeholder="Search"
                                (keyup)="searchEmployee($event.target.value)" autocomplete="off">
                        </div>
                        <mat-option *ngFor="let user of filterEmployee" [value]="user">
                            <div class="user-detail-div">
                                <div class="center" *ngIf="user.userImageThumbnail === ''">
                                    <img class="user-img" src="assets/icons/default-avatar.jpg" alt="" />
                                </div>
                                <div class="center" *ngIf="user.userImageThumbnail !== ''">
                                    <img class="user-img" [src]="user?.userImageThumbnail" alt="">
                                </div>
                                <div class="user-name-id-div">
                                    <div class="user-name-div">{{user?.firstName}} {{user?.lastName}}</div>
                                    <div>{{user?.employeeId}} ({{user?.baseLocation}})</div>
                                </div>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="row hr"></div>
    <div class="row">
        <div *ngFor="let leaveType of leaveTypes" class="leave col-lg-4 col-md-6 col-sm-6 col-xs-12">
            <div class="leave-type">
                <div class="leave-heading">
                    <div class="heading-name">
                        <img [src]="leaveType.iconSrc" alt="calendar">
                        <label class="heading-lbl blue-clr">{{ leaveType.name | underScoreToSpace | titlecase }}</label>
                    </div>
                    <div class="opening-bal">
                        Opening<span *ngIf="leaveType.accruedBalance !== undefined">/Accrued</span> Balance :
                        <label
                            [ngClass]="{'blue-clr':leaveType.openingBalance!==0, 'black-clr':leaveType.openingBalance===0}">
                            <span *ngIf="leaveType.accruedBalance !== undefined"> {{ leaveType.openingBalance }}</span>
                            <span *ngIf="leaveType.accruedBalance === undefined"> {{ leaveType.openingBalance}}</span>
                            <span *ngIf="leaveType.accruedBalance !== undefined">/{{leaveType.accruedBalance}}</span>
                        </label>
                    </div>
                </div>
                <div class="leave-detail" [ngClass]="{'grey-clr': leaveType.availableBalance === 0,
                                'light-blue-clr': leaveType.availableBalance !== 0 &&  leaveType.name !== 'FLOATER_LEAVE', 
                                'orange-clr':  leaveType.name === 'FLOATER_LEAVE'}">
                    <div class="center avail-bal">Available Balance</div>
                    <div class="center"
                        [ngClass]="{'blue-clr':leaveType.availableBalance!==0, 'black-clr':leaveType.availableBalance===0}">
                        {{ leaveType.availableBalance | number:'2.0'}}</div>
                    <div class="center"><span *ngIf="leaveType.length !== 0">
                            <button class="view-detail" (click)="leaveHistoryDialog(leaveType)">View Details</button>
                        </span></div>
                    <div class="center availed">Availed: {{ leaveType.availed }}</div>
                    <div class="center lapse-date"
                        *ngIf="( leaveType.name === 'FLOATER_LEAVE' && leaveType.availed===0 && leaveType.availableBalance !== 0)">
                        Lapse on {{ getCurrentLapseDate() }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>