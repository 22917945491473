import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bulk-excel-summary',
  templateUrl: './bulk-excel-summary.component.html',
  styleUrls: ['./bulk-excel-summary.component.scss'],
})
export class BulkExcelSummaryComponent implements OnInit {
  public summaryContent: any;

  constructor(
    public dialogRef: MatDialogRef<BulkExcelSummaryComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
