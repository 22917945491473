import { Injectable } from '@angular/core';
import { RestAPIService } from '../rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class PreviosemploymentUrlService {
  private readonly prevempBaseApi = '/api/previous-employments';
  private readonly user = '/user';
  private readonly companyname = '/companyname';
  private readonly userId_param = 'userId'
  private readonly company_param = 'companyName'
  constructor(private restApi:RestAPIService) { }
  getBaseUrl(){
    return this.prevempBaseApi;
  }
  getUserUrl(userId) {
    return this.prevempBaseApi + this.user
      + '?' + this.userId_param + '=' + userId
  }
  getByFieldUrl(field){
    return this.prevempBaseApi+ this.getByField(field)
  }
  private getByField(field){
    return '/'+field;
  }
  getCompanyUrl(userId,comapanyName) {
    return this.prevempBaseApi + this.companyname
      + '?' + this.company_param + '=' + comapanyName+'&'+ this.userId_param + '=' + userId
  }
}
