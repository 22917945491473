import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { LeaveTypeDTO } from 'src/app/dtos/LeaveTypeDTO ';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { LeaveBalanceService } from 'src/app/services/leaveBalances/leave-balance.service';
import { EventTabClosed, LeaveType, TransactionType } from 'src/app/services/user/user-enum';
import { LeaveBalanceHistoryComponent } from '../../leave-management/leave-balance/leave-balance-history/leave-balance-history.component';
import { Transaction } from 'src/app/models/transaction';

@Component({
  selector: 'app-view-leavebalance-dialog',
  templateUrl: './view-leavebalance-dialog.component.html',
  styleUrls: ['./view-leavebalance-dialog.component.scss']
})

export class ViewLeavebalanceDialogComponent implements OnInit {
  selectedYear: number;
  years: number[] = [];
  userDetail: UserDTO;
  currentDate: Date = new Date();
  leaveTypes: LeaveTypeDTO[] = [];
  carryForwardLeave = 0

  constructor(
    private dialogRef: MatDialogRef<ViewLeavebalanceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private leaveBalanceService: LeaveBalanceService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.initiateComponent()
  }

  onYearChange(year) {
    this.selectedYear = year;
    this.getLeaveBalance(this.userDetail.id, this.selectedYear);
  }

  initiateComponent() {
    this.userDetail = this.data.userDetail;
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year >= this.selectedYear - 10; year--) {
      this.years.push(year);
    }
    this.createLeaveTypeArray();
    this.getLeaveBalance(this.userDetail.id, new Date().getFullYear());
  }

  createLeaveTypeArray() {
    this.carryForwardLeave = 0
    this.leaveTypes = Object.values(LeaveType)
      .filter(type => type !== LeaveType.ACCRUED_LEAVE)
      .map(type => {
        const leaveType: any = {
          name: type,
          totalBalance: 0,
          openingBalance: 0,
          availableBalance: 0,
          availed: 0,
          history: [],
          iconSrc: `/assets/calendar-icon/${type.toLowerCase().replace(/ /g, '-').replace(/_/g, '-')}-calendar.jpg`
        };
        if (type === LeaveType.PLANNED_LEAVE) {
          leaveType.accruedBalance = 0;
        }
        return leaveType;
      });
  }

  getLeaveBalance(userId, year) {
    this.leaveBalanceService.getLeaveBalanceByIdAndYear(userId, year).subscribe(res => {
      let LeaveHistory = this.segrigateLeaveHistory(res.body.transactionHistory)
      this.carryForwardLeave = res.body.carryForwardLeaves;
      this.setBalanceAndHistory(res.body.balanceLeave, LeaveHistory);
    }, err => {
      this.createLeaveTypeArray();
    })
  }

  segrigateLeaveHistory(leavesHistory) {
    return leavesHistory.reduce((result, transaction) => {
      const leaveType = transaction.leaveType;
      if (!result[leaveType]) {
        result[leaveType] = [];
      }
      result[leaveType].push(transaction);
      return result;
    }, {});
  }

  setBalanceAndHistory(leaveBalanceData, leaveHistory) {
    for (const key in leaveBalanceData) {
      const leaveTypeIndex = this.leaveTypes.findIndex(leave => leave.name === key);
      if (leaveTypeIndex !== -1) {
        this.leaveTypes[leaveTypeIndex].availableBalance = leaveBalanceData[key];
        this.leaveTypes[leaveTypeIndex].history = leaveHistory[key];
        this.leaveTypes[leaveTypeIndex].totalBalance = this.getTotalBalance(leaveHistory[key])
        this.leaveTypes[leaveTypeIndex].openingBalance = this.getOpeningBalance(leaveHistory[key]);
        if (key === LeaveType.PLANNED_LEAVE) {
          this.leaveTypes[leaveTypeIndex].openingBalance += this.carryForwardLeave;
          this.leaveTypes[leaveTypeIndex].totalBalance += this.carryForwardLeave;
        }
        this.leaveTypes[leaveTypeIndex].availed = this.calculateAvailedBalance(this.leaveTypes[leaveTypeIndex].totalBalance, this.leaveTypes[leaveTypeIndex].availableBalance)
      }
      else {
        if (key === LeaveType.ACCRUED_LEAVE) {
          const plannedLeaveIndex = this.leaveTypes.findIndex(leave => leave.name === LeaveType.PLANNED_LEAVE);
          if (plannedLeaveIndex !== -1) {
            this.leaveTypes[plannedLeaveIndex].accruedBalance = leaveBalanceData[key];
          }
        }
      }
    }
  }

  getTotalBalance(leaveHistory?: Transaction[]): number {
    const filteredTransactions: Transaction[] = (leaveHistory ?? []).filter((transaction: Transaction) => {
      return (transaction.transactionPersonType === TransactionType.SYSTEM || transaction.transactionPersonType === TransactionType.HR)
        && (transaction.transactionType === TransactionType.CREDIT || transaction.transactionType === TransactionType.HR_CREDIT);
    });

    const totalBalance = filteredTransactions.reduce((acc: number, transaction: Transaction) => {
      return acc + transaction.transactionCount;
    }, 0);

    return totalBalance;
  }

  getOpeningBalance(leaveHistory?: Transaction[]): number {
    const filteredTransactions: Transaction[] = (leaveHistory ?? []).filter((transaction: Transaction) => {
      return transaction.transactionPersonType === TransactionType.SYSTEM && transaction.transactionType === TransactionType.CREDIT;
    });

    const openingBalance = filteredTransactions.reduce((acc: number, transaction: Transaction) => {
      return acc + transaction.transactionCount;
    }, 0);

    return openingBalance;
  }

  calculateAvailedBalance(totalBalance, AvailableBal) {
    return Math.abs(totalBalance - AvailableBal);
  }

  leaveHistoryDialog(leaveDetail) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "100%";
    dialogConfig.height = "50%"
    dialogConfig.data = leaveDetail;
    const dialogRef = this.dialog.open(LeaveBalanceHistoryComponent, dialogConfig);
  }

  getCurrentLapseDate(): string {
    return this.currentDate.getMonth() <= 5 ? 'June-30' : 'December-31';
  }

  closeDialog() {
    this.dialogRef.close({ event: EventTabClosed.Closed });
  }

}
