import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { RealTimeFeedbackRole } from 'src/app/services/feedback/feedback-enum';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JsonFormComponent } from '../../json-form/json-form.component';
import { UserService } from 'src/app/services/user/user.service';
import { FormsSchemaService } from 'src/app/services/forms/forms-schema.service';
import { RefreshService } from 'src/app/services/refreshService/refresh.service';
import { FeedbackType, Status } from 'src/app/services/user/user-enum';
@Component({
  selector: 'app-add-real-time-feedback',
  templateUrl: './add-real-time-feedback.component.html',
  styleUrls: ['./add-real-time-feedback.component.scss'],
})
export class AddRealTimeFeedbackComponent implements OnInit {
  feedbackRole = [];
  form: FormGroup;
  submitBtnText = 'Next';
  submitBtnDisabled = false;
  feedbackTitle = '';
  role = '';
  years: Number[] = [];
  selectedYear: Number = new Date().getFullYear();
  constructor(
    private dialogRef: MatDialogRef<AddRealTimeFeedbackComponent>,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) private data,
    private feedbackService: FeedbackService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private user: UserService,
    private formSchema: FormsSchemaService,
    private dialog: MatDialog,
    private refreshService: RefreshService
  ) {
  }
  ngOnInit(): void {
    this.initializeForm();
    this.generateYearList();
    this.processEnumValuesToArray();
  }
  initializeForm() {
    this.form = this.fb.group({
      feedbackTitle: [
        '',
        Validators.required,
        Validators.pattern(/^[a-zA-Z\s]*$/),
        Validators.maxLength(32),
      ],
      selectedYear: ['', Validators.required],
      selectedRole: ['', Validators.required],
    });
  }
  generateYearList() {
    const currentYear = new Date().getFullYear();
    this.years.push(currentYear);
    return this.years;
  }
  submitForm() {
    if (
      this.form.controls.feedbackTitle.status == Status.PENDING &&
      this.form.controls.selectedRole.status == Status.VALID &&
      this.form.controls.selectedYear.status == Status.VALID
    ) {
      // 
      this.feedbackTitle = this.form.get('feedbackTitle').value;
      this.role = this.form.get('selectedRole').value;
      this.selectedYear = this.form.get('selectedYear').value;
      if (this.feedbackTitle != '' && this.role != '') {
        this.submitBtnText = 'Initiating..';
        this.submitBtnDisabled = true;
        const jsonObject = {
          feedbackFor: this.data,
          feedbackFrom: [
            {
              feedbackFrom: this.userDetailService.getUserDetails().id,
              feedbackRole: this.role,
            },
          ],
          feedbackType: 'ORPHAN',
          title: this.feedbackTitle,
          year: this.selectedYear,
        };
        this.feedbackService.addRealTimeFeedBack(jsonObject).subscribe(
          (res) => {
            
            this.refreshService.triggerRefresh('pages');
            this.openJSonForm(res.body.feedbackFrom[0].id);
            
          },
          (e) => {
            this.submitBtnText = 'Next';
            this.submitBtnDisabled = false;
            this.toastr.error(
              e.status == 400
                ? 'Feedbacks alredy closed for this user!'
                : ' Enter Valid Details'
            );
          }
        );
      } else {
        this.submitBtnText = 'Next';
        this.submitBtnDisabled = false;
        this.form.markAllAsTouched();
        this.toastr.error('Please Enter Valid Details');
      }
    } else {
      this.form.markAllAsTouched();
      this.submitBtnText = 'Next';
      this.submitBtnDisabled = false;
      this.toastr.error('Please Enter Valid Details');
    }
  }
  async openJSonForm(feedbackId) {
    await this.feedbackService.getFeedBackByIdAsync(feedbackId);
    const resOfFeedBackAfterParse = await this.feedbackService
      .resOfFeedBackAfterParse;
    const resOfFeedBackGroupAfterParse = await this.feedbackService
      .resOfFeedBackGroupAfterParse;
    const timeFrame = resOfFeedBackGroupAfterParse.year;
    await this.user.getUserByIdAsync(resOfFeedBackGroupAfterParse.feedbackFor);
    const assinedUserAfterParse = await this.user.assinedUserAfterParse;
    const assinedManagerUseAfterParse = await this.user
      .assinedManagerUseAfterParse;
    await this.formSchema.getFormSchemaByRoleAndJobTitleAsync(
      resOfFeedBackAfterParse.feedbackRole,
      assinedUserAfterParse.jobTitle
    );
    const formSchemaAfterParse = await this.formSchema.formSchemaAfterParse;
    let temp;
    let ifSave = false;
    if (resOfFeedBackAfterParse.status == Status.READ) {
      temp = resOfFeedBackAfterParse.submission;
      ifSave = true;
    } else {
      temp = formSchemaAfterParse.formTemplate;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '95vh';
    dialogConfig.maxWidth = '85%';
    dialogConfig.data = [
      temp,
      feedbackId,
      assinedUserAfterParse.firstName + ' ' + assinedUserAfterParse.lastName,
      ifSave,
      assinedManagerUseAfterParse.firstName +
      ' ' +
      assinedManagerUseAfterParse.lastName,
      timeFrame,
      false,
      'ORPHAN_FEEDBACK',
    ];
    dialogConfig.panelClass = 'feedbackform-overlay';
    const dialogRef = this.dialog
      .open(JsonFormComponent, dialogConfig)
      .afterClosed()
      .subscribe((result) => { this.closeDialogue(); });
  }
  closeDialogue() {
    this.dialogRef.close();
  }
  processEnumValuesToArray() {
    for (const role of Object.values(RealTimeFeedbackRole)) {
      if (isNaN(Number(role))) {
        this.feedbackRole.push(role);
      }
    }
  }
}
