import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PositionDetails } from 'src/app/models/positionDetails';
import { PreviousEmployment } from 'src/app/models/previousEmployment';
import { PreviosemploymentUrlService } from '../restAPI/previousEmploymentURLs/previosemployment-url.service';
import { RestAPIService } from '../restAPI/rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class PreviousEmploymentService {
  
overall = new Subject<boolean>();
  constructor(private restApiService: RestAPIService,
    private prevurl : PreviosemploymentUrlService
  ) { }
  create(payload) {
    return this.restApiService.post(
      this.prevurl.getBaseUrl(),
      payload
    )
  }
  update(payload){
    return this.restApiService.put(
      this.prevurl.getBaseUrl(),
      payload
    )
  }
  getprevempByUserIdSubscribe(userId)  {
    return this.restApiService.get(
      this.prevurl.getUserUrl(userId)
    )
  }
  
  private _listners=new Subject<any>();
  getexperience():Observable<any>{
    return this._listners.asObservable();
  }
  setexperience(filterBy : string)
  {
  this._listners.next(filterBy);
  }

  setPrevempResponse(response):PreviousEmployment[]{
    let skillList:PreviousEmployment[] = []
    for(let data of response){
        let skill:PreviousEmployment = new PreviousEmployment()
        skillList.push(
          skill.bind(data)          
        )
    }
    return skillList
  }
  setpostionDetails(response):PositionDetails[]{
    
    let skillList:PositionDetails[] = []
    for(let data of response){
        let skill:PositionDetails = new PositionDetails()
        ;
        skill.jobTitle=data.jobTitle;
        skill.capability=data.capability
        skill.positionStartDate=data.startDate
        skill.positionEndDate=data.endDate
        skill.bind(data)     
        skill.capability=data.capability
        if(data.endDate==null)
        {
          skill.positionEndDate=null;
        }
        
         
        skillList.push(
          skill
        )
    }
    
    return skillList
  }
  delete(id: any) {
    this.restApiService.delete(
      this.prevurl.getByFieldUrl(id)
    ).subscribe(res=>{
    })
  }
  getcompanybyuserId(user,comapanyName){
    return this.restApiService.get(
      this.prevurl.getCompanyUrl(user,comapanyName)
    )
  }
}
