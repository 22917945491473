<div>
  <div class="row">
    <button
      class="custom-button-2"
      (click)="onTabClick('APPLY')"
      [ngClass]="{ 'active-tab': activeTab === 'APPLY' }"
    >
      Apply
    </button>
    <button
      class="custom-button-2"
      (click)="onTabClick('PENDING')"
      [ngClass]="{ 'active-tab': activeTab === 'PENDING' }"
    >
      Pending
    </button>
    <button
      class="custom-button-2"
      (click)="onTabClick('HISTORY')"
      [ngClass]="{ 'active-tab': activeTab === 'HISTORY' }"
    >
      History
    </button>
  </div>

  <div *ngIf="activeTab == 'APPLY'">
    <app-apply-newleave></app-apply-newleave>
  </div>

  <div *ngIf="activeTab == 'PENDING'">
    <app-leave-details></app-leave-details>
  </div>
  <div *ngIf="activeTab == 'HISTORY'">
    <app-leave-status></app-leave-status>
  </div>
</div>
