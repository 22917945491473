import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user/user.service';
import { PreviousEmploymentService } from 'src/app/services/previousEmployment/previous-employment.service';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Departments, JobTitles, GeoLocation, EventTabClosed } from 'src/app/services/user/user-enum';
import { PositionDetails } from 'src/app/models/positionDetails';
@Component({
  selector: 'app-position-details',
  templateUrl: './position-details.component.html',
  styleUrls: ['./position-details.component.scss'],
})
export class PositionDetailsComponent implements OnInit {
  preEmployment: FormArray;
  editSpotlightDetails: FormGroup;
  today = new Date();
  minDate = new Date(2022, 0, 1); //BA working start date
  removedPreviousemployment = [];
  jobTitles: JobTitles[] = [];
  userDto: User
  departments: Departments[] = []
  geoLocations: GeoLocation[] = []
  baseLocations: string[][] = []
  userid: string;
  isEndDatePresent: Boolean[] = [];
  constructor(
    private userService: UserService,
    private toaster: ToastrService,
    private fb: FormBuilder,
    private empservice: PreviousEmploymentService,
    private dialogRef: MatDialogRef<PositionDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: User
  ) {

  }
  ngOnInit(): void {
    this.setUserId();
    this.processEnumValuesToArray();
    this.getData();
    this.editSpotlightDetails.valueChanges.subscribe(() => {
      this.checkForDateRangeOverlap();
    });
  }
  setUserId() {
    this.editSpotlightDetails = this.fb.group({
      previousEmployment: this.fb.array([]),
    });
    this.userDto = this.data;
    this.userid = this.data.id;
  }
  getData() {
    this.userService.getpositionHistroy(this.userid).subscribe(res => {
      let index=0;
      for (let i of res.body.positionHistory) {
        i.jobTitle = this.getEnumValueByEnumKey(JobTitles, i.jobTitle);
        i.capability = this.getEnumValueByEnumKey(Departments, i.capability);
        i.geoLocation = this.getEnumValueByEnumKey(GeoLocation, i.geoLocation);
        this.onGeoLocationChange(i.geoLocation,index);
        index++;
      }
      if (res.body.positionHistory.length < 1) {
        this.addPreEmployment();
      }
      this.setPrevempFormArray(res.body.positionHistory)
    }, (e) => {
      this.toaster.error("Error fetching the details")
    })
  }
  createpreEmp() {
    let formGroup = this.fb.group(new PositionDetails());
    formGroup.controls.capability.setValidators([Validators.required]);
    formGroup.controls.baseLocation.setValidators([Validators.required,Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9+#. -]*$')]);
    formGroup.controls.geoLocation.setValidators([Validators.required]);
    formGroup.controls.jobTitle.setValidators([Validators.required]);
    formGroup.controls.positionStartDate.setValidators([Validators.required]);
    return formGroup;
  }
  getMinEndDate(i) {
    var endDate = this.editSpotlightDetails.get('previousEmployment')['controls'][i].get('positionStartDate').value
    return endDate
  }
  processEnumValuesToArray() {
    for (let jobTitle of Object.values(JobTitles)) {
      if (isNaN(Number(jobTitle))) this.jobTitles.push(jobTitle);
    }
    for (let department of Object.values(Departments)) {
      if (isNaN(Number(department)))
        this.departments.push(department)
    }
    for (let geoLocation of Object.values(GeoLocation)) {
      if (isNaN(Number(geoLocation)))
        this.geoLocations.push(geoLocation)
    }
  }
  setPrevempFormArray(response) {
    let data: number;
    this.preEmployment = this.editSpotlightDetails.get(
      'previousEmployment'
    ) as FormArray;
    let empList: PositionDetails[] =
      this.empservice.setpostionDetails(response);
    for (let s of empList) {
      if (s.positionEndDate == null) {
        this.isEndDatePresent.push(false);
      } else {
        this.isEndDatePresent.push(true);
      }
      let formGroup: FormGroup = this.fb.group(s);
      formGroup.controls.capability.setValidators([Validators.required]);
      formGroup.controls.jobTitle.setValidators([Validators.required]);
      formGroup.controls.positionStartDate.setValidators([Validators.required]);
      formGroup.controls.baseLocation.setValidators([Validators.required,Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9+#. -]*$')]);
      formGroup.controls.geoLocation.setValidators([Validators.required]);
      this.preEmployment.push(formGroup);
    }
  }
  PreviousEmp(): FormArray {
    return this.editSpotlightDetails.get('previousEmployment') as FormArray;
  }
  addPreEmployment() {
    this.preEmployment = this.editSpotlightDetails.get(
      'previousEmployment'
    ) as FormArray;
    this.preEmployment.insert(0, this.createpreEmp());
    this.baseLocations.unshift([]); // add empty at index 0 
  }
  removePreEmployment(id, i) {
    this.removedPreviousemployment.push(id);
    this.PreviousEmp().removeAt(i);
    this.baseLocations.splice(i, 1); // remove 1 object from i location
  }
  closeDialog(event: Event) {
    this.dialogRef.close({ event: EventTabClosed.Closed });
  }
  onSubmit() {
    let data = [];
    if (this.editSpotlightDetails.valid) {
      for (let prevemp of this.editSpotlightDetails.value.previousEmployment) {
        var obj = {};
        obj['startDate'] = prevemp.positionStartDate;
        if (prevemp.positionEndDate == null) {
          obj['endDate'] = '';
        }
        else
          obj['endDate'] = prevemp.positionEndDate;
        obj['jobTitle'] = this.getEnumKeyByEnumValue(JobTitles, prevemp.jobTitle)
        obj['capability'] = this.getEnumKeyByEnumValue(Departments, prevemp.capability)
        obj['geoLocation'] = this.getEnumKeyByEnumValue(GeoLocation, prevemp.geoLocation)
        obj['baseLocation'] = prevemp.baseLocation;
        data.push(obj)
      }
      this.userDto['positionHistory'] = data;
      this.userService.setpositionHistroy(this.userid, this.userDto).subscribe(res => {
        this.toaster.success("Successfully Saved")
        this.dialogRef.close({ event: 'success' })
      }, (e) => {
        this.toaster.error("Error Saving the details")
      })
    } else {
      this.toaster.error("Invalid Details")
    }
  }
  getEnumKeyByEnumValue(myEnum, enumValue) {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }
  getEnumValueByEnumKey(myEnum, enumKey: string) {
    return myEnum[enumKey];
  }
  checkForDateRangeOverlap(): void {
    const previousEmployment = this.editSpotlightDetails.get('previousEmployment') as FormArray;
    if (!previousEmployment) {
      return;
    }
    // Clear errors for all form controls first
    previousEmployment.controls.forEach((control) => {
      if (control.get('positionStartDate').hasError('dateRangeOverlap')) {
        control.get('positionStartDate').setErrors(null);
      }
      if (control.get('positionStartDate').hasError('dateRangeOverlap')) {
      control.get('positionEndDate').setErrors(null);
    }
    });
    const dateRanges = previousEmployment.value.map((item, index) => {
      return {
        startDate: item.positionStartDate,
        endDate: item.positionEndDate,
        index,
      };
    });
    for (let i = 0; i < dateRanges.length - 1; i++) {
      for (let j = i + 1; j < dateRanges.length; j++) {
        if (
          dateRanges[i].startDate &&
          dateRanges[j].startDate &&
          dateRanges[i].startDate <= dateRanges[j].endDate &&
          dateRanges[i].endDate >= dateRanges[j].startDate
        ) {
          previousEmployment.controls[dateRanges[i].index]
            .get('positionStartDate')
            .setErrors({ dateRangeOverlap: true });
        }
        if (
          dateRanges[i].endDate &&
          dateRanges[j].endDate &&
          dateRanges[i].endDate <= dateRanges[j].endDate &&
          dateRanges[i].startDate >= dateRanges[j].startDate
        ) {
          previousEmployment.controls[dateRanges[i].index]
            .get('positionEndDate')
            .setErrors({ dateRangeOverlap: true });
        }
      }
    }
  }

  onGeoLocationChange(geoLocation: string,index) {
    this.userService.fetchBaseLocations(geoLocation.toLocaleUpperCase()).subscribe(res=>
      {
        this.baseLocations[index] = res.body;
      },
      (error) => {
        console.error('Error fetching base locations:', error);
      }
    );
  }
}