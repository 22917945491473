import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FeedbackService } from 'src/app/services/feedback.service';
@Component({
  selector: 'app-feedback-summery',
  templateUrl: './feedback-summery.component.html',
  styleUrls: ['./feedback-summery.component.scss'],
})
export class FeedbackSummeryComponent implements OnInit {
  summary: string = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private feedbackService: FeedbackService,
    private tosterService: ToastrService,
    private dialogRef: MatDialogRef<FeedbackSummeryComponent>
  ) { }
  ngOnInit(): void {
    this.existingSummery();
  }
  existingSummery() {
    this.feedbackService.getFeedbackById(this.data[0]).subscribe((res) => {
      this.summary = res.body.orphanSummary;
    });
  }
  closeDialog() {
    this.dialogRef.close();
  }
  submitSummary() {
    if (this.summary && this.summary != '') {
      this.feedbackService
        .feedbackSummerySubmit(this.data[0], this.summary)
        .subscribe(
          (res) => {
            this.tosterService.success('Feedback summary submitted!');
            this.dialogRef.close(this.data);
          },
          (e) => {
            this.tosterService.error(
              'Your feedback submission appears to be pending or unsuccessful. Kindly resubmit or reach out to our support team for assistance.',
              e
            );
          }
        );
    } else {
      this.tosterService.warning('Feedback summery is required!');
    }
  }
}
