import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { UserService } from 'src/app/services/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { JsonFormComponent } from '../../json-form/json-form.component';
import { FormsSchemaService } from 'src/app/services/forms/forms-schema.service';
import { DeleteFeedbackComponent } from './delete-feedback/delete-feedback.component';
import { FeedbackType, Status } from 'src/app/services/user/user-enum';
import { trigger, transition, style, animate } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-public-profile',
  templateUrl: './real-time-feedback.component.html',
  styleUrls: ['./real-time-feedback.component.scss'],
  animations: [
    trigger('slideUp', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('600ms ease-out', style({ transform: 'translateY(0)', opacity: 1 }))
      ]),
    ])
  ]
})
export class RealTimeFeedbackComponent implements OnInit {
  @Input() dataFromParent: string;
  userId: string;
  years: number[] = [];
  BAstartYear: number = 2022;
  feedbackList = [];
  feedbackCount: number;
  feedback = [];
  loggedInProfile: Boolean;
  loggedInUserId: string;
  dataSubscription;
  userid: string;
  sortOrder: 'asc' | 'desc' = 'asc';
  userNameIDMapping: Object = {};
  userIdNameMapping: Object = {};
  temp: Object = {};
  userList: Object[];
  uList: Object[]
  isSpinner: boolean = true;
  constructor(
    private dialog: MatDialog,
    private userDetailService: UserDetailsService,
    private userService: UserService,
    private feedbackService: FeedbackService,
    private user: UserService,
    private formSchema: FormsSchemaService,
    private route: ActivatedRoute,
    private tosterService: ToastrService,
  ) {

  }

  ngOnInit(): void {
    this.refreshOnSearchOfEmployeeTab();
  }
  refreshOnSearchOfEmployeeTab() {
    this.dataSubscription = this.feedbackService.data$.subscribe((data) => {
      this.userid = data;
      this.feedbackList = [];
      this.feedback = [];
      this.initiateComponent()
      this.getFeedbackForOtherUsersProfile();
    });
  }
  initiateComponent() {
    this.years = [];
    this.loggedInUserId = this.userDetailService.getUserDetails().id;
    this.getuserList();
    for (let i = this.BAstartYear; i <= new Date().getFullYear(); i++) {
      this.years.push(i);
    }
    this.feedbackList = [];
    if (this.userid == null) {
      this.userid = this.route.snapshot.paramMap.get('id');
    }
    if (this.userid == null) {
      this.userid = this.loggedInUserId;
      this.loggedInProfile = true;
    } else {
      this.loggedInProfile = false;
    }
    if (this.userid == this.loggedInUserId) {
      this.loggedInProfile = true;
    }
    this.userId = this.loggedInUserId;
    if (this.loggedInProfile) {
      this.getAllFeedbacks();
    } else {
      this.getFeedbackForOtherUsersProfile();
    }
  }
  getuserList() {
    this.userService.getAllUserForDropDown().subscribe((usrData) => {
      usrData.body.forEach((element) => {
        this.userNameIDMapping[element.firstName + ' ' + element.lastName] =
          element.userID;
        this.userIdNameMapping[element.userID] =
          element.firstName +
          ' ' +
          element.lastName +
          '(' +
          element.employeeId +
          ')';
        this.temp[element.firstName + ' ' + element.lastName] = element.userID;
      });
      this.userList = Object.keys(this.userNameIDMapping);
      this.uList = Object.keys(this.temp);
    });
  }
  getAllFeedbacks() {
    this.isSpinner = true;
    this.feedbackService
      .getAllRealTimeFeedbackByUser(this.userId)
      .subscribe((res) => {
        this.feedbackList = [];
        this.feedbackCount = res.body.length;
        for (let i = res.body.length - 1; i >= 0; i--) {
          const feedbackDate =
            res.body[i].feedbackFrom[0].submittedAt === null
              ? res.body[i].feedbackFrom[0].createdAt
              : res.body[i].feedbackFrom[0].submittedAt;
          const createdAt = res.body[i].feedbackFrom[0].createdAt;
          const isEditable = this.isEditable(feedbackDate);
          const feedbackItem = {
            name: res.body[i].feedbackFor,
            score: res.body[i].feedbackFrom[0].score,
            title: res.body[i].feedbackFrom[0].orphanSummary
              ? res.body[i].feedbackFrom[0].orphanSummary
              : '-',
            role: res.body[i].feedbackFrom[0].feedbackRole,
            date: res.body[i].feedbackFrom[0].createdAt,
            isContinue:
              res.body[i].feedbackFrom[0].feedbackSubmissionStatus === Status.READ,
            isCreated:
              res.body[i].feedbackFrom[0].feedbackSubmissionStatus ===
              Status.CREATED,
            isSubmitted:
              res.body[i].feedbackFrom[0].feedbackSubmissionStatus ===
              Status.SUBMITTED,
            id: res.body[i].feedbackFrom[0].id,
            isEditable,
            year: res.body[i].year,
            createdAt,
            status:
              res.body[i].feedbackFrom[0].feedbackSubmissionStatus === Status.READ ||
                res.body[i].feedbackFrom[0].feedbackSubmissionStatus === Status.CREATED
                ? Status.DRAFT
                : Status.SUBMITTED,
          };
          const isDuplicate = this.feedbackList.some(
            (item) => item.id === feedbackItem.id
          );
          if (!isDuplicate) {
            this.feedbackList.push(feedbackItem);
          }
        }
        this.isSpinner = false;
      });
  }
  isEditable(updatedAt): boolean {
    const currentTime = new Date();
    const updatedAtDateTime = new Date(updatedAt);
    updatedAtDateTime.setDate(updatedAtDateTime.getDate() + 3);
    updatedAtDateTime.setHours(0, 0, 0, 0);
    return currentTime < updatedAtDateTime;
  }
  getFeedbackForOtherUsersProfile() {
    this.isSpinner = true;
    this.feedback.length = 0;
    this.feedbackService
      .getFeedbackByUserIdandYear(this.userid)
      .subscribe((res) => {
        this.feedback = [];
        for (let i = res.body.length - 1; i >= 0; i--) {
          const feedbackDate =
            res.body[i].feedbackFrom[0].submittedAt === null
              ? res.body[i].feedbackFrom[0].createdAt
              : res.body[i].feedbackFrom[0].submittedAt;
          const isEditable = this.isEditable(feedbackDate);
          const feedbackItem = {
            name: res.body[i].feedbackFor,
            score: res.body[i].feedbackFrom[0].score,
            title: res.body[i].feedbackFrom[0].orphanSummary
              ? res.body[i].feedbackFrom[0].orphanSummary
              : '-',
            role: res.body[i].feedbackFrom[0].feedbackRole,
            date: res.body[i].feedbackFrom[0].createdAt,
            isContinue:
              res.body[i].feedbackFrom[0].feedbackSubmissionStatus === Status.READ,
            isCreated:
              res.body[i].feedbackFrom[0].feedbackSubmissionStatus ===
              Status.CREATED,
            isSubmitted:
              res.body[i].feedbackFrom[0].feedbackSubmissionStatus ===
              Status.SUBMITTED,
            id: res.body[i].feedbackFrom[0].id,
            isEditable,
            year: res.body[i].year,
            status:
              res.body[i].feedbackFrom[0].feedbackSubmissionStatus === Status.READ ||
                res.body[i].feedbackFrom[0].feedbackSubmissionStatus === Status.CREATED
                ? Status.DRAFT
                : Status.SUBMITTED,
          };
          this.feedback.push(feedbackItem);
        }
        this.isSpinner = false;
      });
  }
  deleteFeedback(feedback) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '485px';
    dialogConfig.height = 'auto';
    dialogConfig.data = 'Are you sure you want to delete this real-time feedback?';
    const dialogRef = this.dialog.open(DeleteFeedbackComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'Yes') {
        this.feedbackService.delete(feedback.id).subscribe(
          () => {
            this.feedbackService.filter('');
            this.tosterService.success('Feedback deleted!');
            if (this.loggedInProfile) this.getAllFeedbacks();
            else this.getFeedbackForOtherUsersProfile();
          },
          (e) => {
            this.tosterService.warning('You cannot delete this feedback!');
          }
        );
      }

    });
  }
  editFeedback(feedback) {
    if (feedback.isSubmitted) {
      this.feedbackService
        .editRealTimeFeedback(feedback.id)
        .subscribe((res) => {
          this.OpenJSonForm(feedback.id);
        });
    } else {
      this.OpenJSonForm(feedback.id);
    }
  }
  async OpenJSonForm(feedbackId) {
    await this.feedbackService.getFeedBackByIdAsync(feedbackId);
    const resOfFeedBackAfterParse = await this.feedbackService
      .resOfFeedBackAfterParse;
    const resOfFeedBackGroupAfterParse = await this.feedbackService
      .resOfFeedBackGroupAfterParse;
    const timeFrame = resOfFeedBackGroupAfterParse.year;
    await this.user.getUserByIdAsync(resOfFeedBackGroupAfterParse.feedbackFor);
    const assinedUserAfterParse = await this.user.assinedUserAfterParse;
    const assinedManagerUseAfterParse = await this.user
      .assinedManagerUseAfterParse;
    await this.formSchema.getFormSchemaByRoleAndJobTitleAsync(
      resOfFeedBackAfterParse.feedbackRole,
      assinedUserAfterParse.jobTitle
    );
    const formSchemaAfterParse = await this.formSchema.formSchemaAfterParse;
    let temp;
    let ifSave = false;
    if (resOfFeedBackAfterParse.status == Status.READ) {
      temp = resOfFeedBackAfterParse.submission;
      ifSave = true;
    } else {
      temp = formSchemaAfterParse.formTemplate;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '95vh';
    dialogConfig.maxWidth = '85%';
    dialogConfig.data = [
      temp,
      feedbackId,
      assinedUserAfterParse.firstName + ' ' + assinedUserAfterParse.lastName,
      ifSave,
      assinedManagerUseAfterParse.firstName +
      ' ' +
      assinedManagerUseAfterParse.lastName,
      timeFrame,
      false,
      'ORPHAN_FEEDBACK',
      true,
    ];
    dialogConfig.panelClass = 'feedbackform-overlay';
    const dialogRef = this.dialog
      .open(JsonFormComponent, dialogConfig)
      .afterClosed()
      .subscribe((result) => {
        this.getAllFeedbacks();
        this.getFeedbackForOtherUsersProfile();
      });
  }
  sortByAlphabetically(columnName) {
    this.feedbackList.sort((a, b) => {
      const itemA = a[columnName].toLowerCase();
      const itemB = b[columnName].toLowerCase();
      if (this.sortOrder === 'asc') {
        if (itemA < itemB) {
          return -1;
        } else if (itemA > itemB) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (itemA > itemB) {
          return -1;
        } else if (itemA < itemB) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    this.feedback.sort((a, b) => {
      const itemA = a[columnName].toLowerCase();
      const itemB = b[columnName].toLowerCase();
      if (this.sortOrder === 'asc') {
        if (itemA < itemB) {
          return -1;
        } else if (itemA > itemB) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (itemA > itemB) {
          return -1;
        } else if (itemA < itemB) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
  }
  sortNameByAlphabetically(columnName) {
    this.feedbackList.sort((a, b) => {
      const itemA = this.userIdNameMapping[a[columnName]].toLowerCase();
      const itemB = this.userIdNameMapping[b[columnName]].toLowerCase();
      if (this.sortOrder === 'asc') {
        if (itemA < itemB) {
          return -1;
        } else if (itemA > itemB) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (itemA > itemB) {
          return -1;
        } else if (itemA < itemB) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    this.feedback.sort((a, b) => {
      const itemA = this.userIdNameMapping[a[columnName]].toLowerCase();
      const itemB = this.userIdNameMapping[b[columnName]].toLowerCase();
      if (this.sortOrder === 'asc') {
        if (itemA < itemB) {
          return -1;
        } else if (itemA > itemB) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (itemA > itemB) {
          return -1;
        } else if (itemA < itemB) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
  }
}
