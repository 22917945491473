<div class="main_header_div mb-2">
    <div class="child">
        <h1 class="header_name "><img class="header-icon mr-3" src="assets/icons/emergContact.png"> Emergency Contacts
        </h1>
    </div>
    <div class="pr-3 pt-3">
        <img class="close-icon" (click)="closeDialog($event)" src="assets/icons/close.png">
    </div>
</div>
<hr>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 form_main_div">
            <form [formGroup]="emergencyContactForm" (ngSubmit)="onSubmit()">
                <div class="content-scroll">
                    <fieldset formGroupName="emergencyContactOne">
                        <label>Emergency Contact One</label>
                        <div class="row mt-3">
                            <div class="col-md-4">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Name</mat-label>
                                    <input matInput formControlName="name" autocomplete="off">
                                    <mat-error
                                        *ngIf="checkError('name','emergencyContactOne','required')">Required</mat-error>
                                    <mat-error *ngIf="checkError('name','emergencyContactOne','pattern')">Invalid
                                        Name</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Contact Number</mat-label>
                                    <input matInput formControlName="phoneNumber" autocomplete="off">
                                    <mat-error
                                        *ngIf="checkError('phoneNumber','emergencyContactOne', 'required')">Required</mat-error>
                                    <mat-error
                                        *ngIf="checkError('phoneNumber','emergencyContactOne', 'pattern')">Invalid
                                        Contact Number</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Relationship</mat-label>
                                    <mat-select formControlName="relationship">
                                        <mat-option *ngFor="let relation of this.relation" [value]="relation">
                                            {{relation}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="checkError('relationship','emergencyContactOne', 'required')">Required</mat-error>
                                    <mat-error
                                        *ngIf="checkError('relationship','emergencyContactOne', 'pattern')">Invalid</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Address Line</mat-label>
                                    <input matInput formControlName="location" autocomplete="off">
                                    <mat-error
                                        *ngIf="checkError('location','emergencyContactOne', 'required')">Required</mat-error>
                                        <mat-error
                                        *ngIf="checkError('location','emergencyContactOne', 'pattern')">Invalid pattern</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset formGroupName="emergencyContactTwo">
                        <label>Emergency Contact Two</label>
                        <div class="row mt-3">
                            <div class="col-md-4">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Name</mat-label>
                                    <input matInput formControlName="name" autocomplete="off">
                                    <mat-error
                                        *ngIf="checkError('name','emergencyContactTwo', 'required')">Required</mat-error>
                                    <mat-error *ngIf="checkError('name','emergencyContactTwo', 'pattern')">Invalid
                                        Name</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Contact Number</mat-label>
                                    <input matInput formControlName="phoneNumber" autocomplete="off">
                                    <mat-error
                                        *ngIf="checkError('phoneNumber','emergencyContactTwo', 'required')">Required</mat-error>
                                    <mat-error
                                        *ngIf="checkError('phoneNumber','emergencyContactTwo', 'pattern')">Invalid
                                        Contact Number</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Relationship</mat-label>
                                    <mat-select formControlName="relationship">
                                        <mat-option *ngFor="let relation of this.relation" [value]="relation">
                                            {{relation}}
                                        </mat-option>
                                    </mat-select>                                    <mat-error
                                        *ngIf="checkError('relationship','emergencyContactTwo', 'required')">Required</mat-error>
                                    <mat-error
                                        *ngIf="checkError('relationship','emergencyContactTwo', 'pattern')">Invalid</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Address Line</mat-label>
                                    <input matInput formControlName="location" autocomplete="off">
                                    <mat-error
                                        *ngIf="checkError('location','emergencyContactTwo', 'required')">Required</mat-error>
                                    <mat-error
                                        *ngIf="checkError('location','emergencyContactTwo', 'pattern')">Invalid pattern</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="submit_button_main">
                            <button class="save_Emp_btn" type="submit"> <span>Save</span> </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>