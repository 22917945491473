<div class="else-template"></div>
<div *ngIf="isMyrepoteeVisible">
  <ng-container *ngIf="this.nodata == true; else elseTemplate">
    <div class="nothing-data">
      <img class="no-reportee-img" src="assets/images/no-data-project.png" />
    </div>
  </ng-container>
  <ng-template #elseTemplate>
    <div>
      <div class="row-main">
        <img src="assets/icons/search-black.png">
        <input
          class="search_input"
          type="search"
          placeholder="Search"
          [(ngModel)]="searchText"
          value=""
        />
      </div>
      <div class="contianer-2">
        <div class="card-container">
          <table>
            <thead>
              <tr>
                <th class="th-emp-name">Employee</th>
                <th class="th-emp-title">Title</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="emp-row"
                *ngFor="let directreportee of userdto | filter : searchText"
                (click)="onViewReportee(directreportee.userId)" [@slideUp]
              >
                <td>
                  <label class="name-user">
                    {{ directreportee.firstName | titlecase }}
                    {{ directreportee.lastName | titlecase }}
                    ({{ directreportee.employeeId | uppercase }})
                  </label>
                </td>
                <td>
                  <label class="title-user">
                    {{
                      directreportee.jobTitle | underScoreToSpace | titlecase
                    }}
                  </label>
                </td>
                <td>
                  <label class="isclosed" *ngIf="directreportee.closed">
                    Closed
                  </label>
                  <label class="link-download" *ngIf="!directreportee.closed">
                    Open
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<div *ngIf="!isMyrepoteeVisible">
  <app-profile-detail [myReporteeId]="abc"></app-profile-detail>
</div>
