import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormsSchemaService } from 'src/app/services/forms/forms-schema.service';
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  @Input() feedbackForm;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formService: FormsSchemaService
  ) {}

  ngOnInit(): void {}
  matDialogClose() {
    this.dialogRef.close('no');
  }

  deleteForm() {
    this.dialogRef.close('yes');
  }
}
