import {GoalStatus, GoalType,Metric} from 'src/app/services/goalSheet/goal-sheet-enum'
import { User } from './user';


interface IGoal {
    id?: string;
    currentValue?:number
    endDate?:string
    goalSheetId?:string
    goalStatus?:GoalStatus
    goalType?:GoalType
    initialValue?:string
    metric?:string
    startDate?:string
    targetValue?:string
    timeFrame?:string
    title?:string
    createdDate?:string
    user?:User
    lastUpdated?:string
    description?:string
}

export class Goal {
    id: string;
    currentValue:number
    endDate:string
    goalSheetId:string
    goalStatus:GoalStatus
    goalType:GoalType
    initialValue:string
    metric:string
    startDate:string
    targetValue:string
    timeFrame:string
    title:string
    createdDate:string
    user:User
    lastUpdated:string
    description:string
    constructor(goal: IGoal) {
        if (goal && goal.id) {
            this.id = goal.id;
        }
        this.currentValue = goal && goal.currentValue;
        this.endDate = goal && goal.endDate || null;
        this.goalSheetId = goal && goal.goalSheetId || null;
        this.goalStatus = goal && goal.goalStatus || null;
        this.goalType = goal && goal.goalType || null;
        this.initialValue = goal && goal.initialValue;
        this.metric = goal && goal.metric || null;
        this.startDate = goal && goal.startDate || null;
        this.targetValue =  goal && goal.targetValue || null;
        this.timeFrame =  goal && goal.timeFrame || null;
        this.title =  goal && goal.title || null;
        this.createdDate = goal && goal.createdDate || null;
        this.user = goal && goal.user || null;
        this.lastUpdated = goal && goal.lastUpdated || null;
        this.description = goal && goal.description || null;
    }
}