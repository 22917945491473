<!-- Header -->

<div *ngIf="isHeader; then thenBlock; else elseBlock"></div>
<ng-template #thenBlock>
  <div
    class="modal-header btn-primary"
    style="height: 50px; background-color: #00bfd5"
  >
    <h3 class="modal-title" style="margin-bottom: 10px; font-size: medium">
      Edit a Form
    </h3>
    <mat-icon class="close-icon" (click)="onClose()">close</mat-icon>
  </div>
</ng-template>
<ng-template #elseBlock>
  <div
    class="modal-header btn-primary"
    style="height: 50px; background-color: #00bfd5"
  >
    <h3 class="modal-title" style="margin-bottom: 10px; font-size: medium">
      Create a Form
    </h3>
    <mat-icon class="close-icon" (click)="onClose()">close</mat-icon>
  </div>
</ng-template>

<div *ngIf="model[inc] && !report" class="padding20 white-bg">
  <div class="row">
    <div class="col-12">
      <div class="btn-cont">
        <div *ngIf="addSection">
          <button
            type="button"
            class="btn btn-success mt-4 mr-3 ml-3 float-left"
            (click)="onEditPrevious()"
          >
            {{ inc + 1 }} <i class="pl-2 fas fa-arrow-left"></i>
          </button>
          <button
            type="button"
            class="btn btn-success mt-4 mr-3 float-right"
            (click)="onEditNext()"
          >
            <i class="pl-2 fas fa-arrow-right"></i>
          </button>
        </div>

        <button
          type="button"
          class="btn btn-primary mt-4 mr-4 float-right"
          style="background-color: #00bfd5"
          (click)="onAddSection()"
        >
          Add Section <i class="pl-2 fas fa-plus"></i>
        </button>

        <div class="table-fixed">
          <table class="table-borderless">
            <tr>
              <th>
                <div class="dropdown-fields feedback_roles">
                  <mat-select
                    name="demo1"
                    id="#ip-feedback-role"
                    (selectionChange)="onSelectedFeedbackRole($event)"
                    [(ngModel)]="feedbackRole"
                  >
                    <mat-option value="SUPERVISOR">Supervisor</mat-option>
                    <mat-option value="CAREER_MANAGER"
                      >Career Manager</mat-option
                    >
                    <mat-option value="CLIENT">Client</mat-option>
                    <mat-option value="PEER">Peer</mat-option>
                    <mat-option value="SELF_EVALUATION"
                      >Self Evaluation</mat-option
                    >
                    <mat-option value="DIRECT_REPORTEE"
                      >Direct Reportee</mat-option
                    >
                  </mat-select>
                </div>
              </th>
              <th class="text-center">&nbsp; to &nbsp; &nbsp;</th>
              <th>
                <div class="dropdown-fields job_title">
                  <mat-select
                    name="demo"
                    id="#ip-job-titles"
                    (selectionChange)="onSelectedJobTitle($event)"
                    [(ngModel)]="jobTitle"
                  >
                    <mat-option value="ANALYST">Analyst</mat-option>
                    <mat-option value="CONSULTANT">Consultant</mat-option>
                    <mat-option value="SR_CONSULTANT">Sr Consultant</mat-option>
                    <mat-option value="MANAGER">Manager</mat-option>
                    <mat-option value="SR_MANAGER">Sr Manager</mat-option>
                    <mat-option value="DIRECTOR">Director</mat-option>
                    <mat-option value="SR_DIRECTOR">Sr Director</mat-option>
                    <mat-option value="MANAGING_DIRECTOR"
                      >Managing Director</mat-option
                    >
                  </mat-select>
                </div>
              </th>

              <th></th>
            </tr>
          </table>
        </div>
        <div class="text-center">
          <mat-form-field
            class="ml-5 mt-3"
            appearance="outline"
            style="width: 480px !important"
          >
            <mat-label>Feedback form type</mat-label>
            <input
              matInput
              [(ngModel)]="formTypeTextDisplay"
              placeholder="Enter form type"
              autocomplete="off"
              id="formType"
              name="formType"
              minlength="4"
              #name="ngModel"
              Required
              [readonly]="!editable"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="spacer30"></div>
  <div class="row">
    <div class="col-2">
      <table>
        <tr>
          <td>
            <div
              class="fields"
              style="margin-left: 30px"
              *ngFor="let item of fieldModels; let i = index"
            >
              <div
                class="field pointer shadow-bg"
                [dndDraggable]="item"
                [dndEffectAllowed]="'copy'"
                (dndEnd)="onDragEnd($event)"
              >
                <table>
                  <tr>
                    <td>
                      <i
                        [ngClass]="item.icon"
                        class="fa pull-left"
                        style="font-size: x-large"
                      ></i>
                    </td>
                    <td>
                      <h4 style="margin-top: 16px; margin-left: 6px">
                        {{ item.label }}
                      </h4>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="col-10">
      <div
        class="form-group"
        style="margin-bottom: 0px !important; padding: 30px 60px 5px 60px"
      >
        <h2>
          <input
            id="id1"
            class="form-control"
            type="text"
            [(ngModel)]="model[inc].name"
            #myInput
            placeholder="Enter Section Category name"
            (keydown)="OnInput(myInput.value)"
            Required
          />
        </h2>
      </div>

      <section
        dndDropzone
        (dndDragover)="onDragover($event)"
        (dndDrop)="onDrop($event, model[inc].attributes)"
        class="dndDropArea"
        [ngClass]="{
          empty:
            model[inc].name == 'Additional Questions' ||
            model[inc].attributes.length == 0
        }"
      >
        <div
          class="field"
          *ngFor="let item of model[inc].attributes; let i = index"
          (dndStart)="onDragStart($event)"
          (dndCanceled)="onDragCanceled($event)"
          (dndMoved)="onDragged(item, model[inc].attributes, 'move')"
          [dndEffectAllowed]="'all'"
          [dndDraggable]="item"
        >
          <div class="row form-group">
            <div class="col-1">
              <i class="fa fa-ellipsis-v dndHandle" dndHandle></i>
            </div>
            <div class="col-7">
              <div *ngIf="item.type == 'text'">
                <label>{{ item.label }}</label>
                <label *ngIf="item.Required" class="text-danger">*</label>
                <input
                  type="text"
                  class="form-control"
                  id="{{ item.name }}"
                  placeholder="{{ item.placeholder }}"
                />
              </div>
              <div *ngIf="item.type == 'number'">
                <label>{{ item.label }}</label>
                <label *ngIf="item.Required" class="text-danger">*</label>
                <input
                  type="number"
                  class="form-control"
                  id="{{ item.name }}"
                  min="{{ item.min }}"
                  max="{{ item.max }}"
                  placeholder="{{ item.placeholder }}"
                />
              </div>
              <div *ngIf="item.type == 'textarea'">
                <label>{{ item.label }}</label>
                <label *ngIf="item.Required" class="text-danger">*</label>
                <textarea
                  class="form-control"
                  id="{{ item.name }}"
                  placeholder="{{ item.placeholder }}"
                >
                </textarea>
              </div>
              <div *ngIf="item.type == 'paragraph'">
                <p class="form-text">{{ item.placeholder }}</p>
              </div>
              <div *ngIf="item.type == 'autocomplete'">
                <label>{{ item.label }}</label>
                <label *ngIf="item.Required" class="text-danger">*</label>
                <select class="form-control" id="{{ item.name }}">
                  <option *ngFor="let v of item.values" [value]="v.value">
                    {{ v.label }}
                  </option>
                </select>
              </div>
              <div *ngIf="item.type == 'checkbox'">
                <label>{{ item.label }}</label>
                <label *ngIf="item.Required" class="text-danger">*</label>
                <div *ngFor="let v of item.values" class="inline-form-group">
                  <label class="cust-check-bx">
                    <input
                      type="checkbox"
                      [value]="v.value"
                      name="{{ item.name }}"
                      (click)="toggleValue(v)"
                    />
                    {{ v.label }}
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div *ngIf="item.type == 'radio'">
                <label>{{ item.label }}</label>
                <label *ngIf="item.Required" class="text-danger">*</label>
                <div *ngFor="let v of item.values" class="inline-form-group">
                  <label class="cust-check-bx">
                    <input
                      type="radio"
                      [value]="v.value"
                      name="{{ item.name }}"
                    />
                    {{ v.label }}
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div *ngIf="item.type == 'button'" class="btn-cont">
                <input
                  type="{{ item.subtype }}"
                  (click)="submit()"
                  value="{{ item.label }}"
                  class="btn btn-primary"
                  id="{{ item.name }}"
                />
              </div>
            </div>
            <div class="col-4">
              <i
                *ngIf="item.toggle"
                (click)="item.toggle = false"
                class="fa fa-chevron-up pull-right"
              ></i>
              <i
                *ngIf="!item.toggle"
                (click)="item.toggle = true"
                class="fa fa-chevron-down pull-right"
              ></i>
              <i (click)="removeField(i)" class="fa fa-trash pull-right"></i>
            </div>
          </div>
          <div *ngIf="item.toggle" class="toggle-Wrapper">
            <div class="form-group">
              <label>Required</label>
              <i
                *ngIf="item.Required"
                (click)="item.Required = false"
                class="fa fa-toggle-on red"
              ></i>
              <i
                *ngIf="!item.Required"
                (click)="item.Required = true"
                class="fa fa-toggle-off"
              ></i>
              <span class="pull-right ucfirst">{{ item.type }}</span>
            </div>
            <div class="form-group">
              <label>Label</label>
              <input
                class="form-control"
                type="text"
                [(ngModel)]="item.label"
              />
            </div>
            <div class="form-group">
              <label>Name</label>
              <input class="form-control" type="text" [(ngModel)]="item.name" />
            </div>
            <div class="form-group">
              <label>Placeholder</label>
              <input
                class="form-control"
                type="text"
                [(ngModel)]="item.placeholder"
              />
            </div>
            <div class="form-group">
              <label>Regex</label>
              <input
                class="form-control"
                type="text"
                [(ngModel)]="item.regex"
              />
            </div>
            <div *ngIf="item.regex" class="form-group">
              <label>Error text</label>
              <input
                class="form-control"
                type="text"
                [(ngModel)]="item.errorText"
              />
            </div>

            <!-- number -->
            <div *ngIf="item.type == 'number'" class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Min</label>
                  <input
                    class="form-control"
                    type="text"
                    [(ngModel)]="item.min"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Max</label>
                  <input
                    class="form-control"
                    type="text"
                    [(ngModel)]="item.max"
                  />
                </div>
              </div>
            </div>

            <div
              *ngIf="
                item.type == 'radio' ||
                item.type == 'checkbox' ||
                item.type == 'autocomplete'
              "
            >
              <div class="row">
                <div class="col-5">
                  <div class="form-group">
                    <label>Label</label>
                  </div>
                </div>
                <div class="col-5">
                  <div class="form-group">
                    <label>Value</label>
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <label>Action</label>
                  </div>
                </div>
              </div>
              <div
                *ngFor="let i of item.values; let valueIndex = index"
                class="row"
              >
                <div class="col-5">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      [(ngModel)]="i.label"
                    />
                  </div>
                </div>
                <div class="col-5">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      [(ngModel)]="i.value"
                    />
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <label (click)="item.values.splice(valueIndex, 1)"
                      >remove</label
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-5">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      [(ngModel)]="value.label"
                    />
                  </div>
                </div>
                <div class="col-5">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      [(ngModel)]="value.value"
                    />
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <label (click)="addValue(item.values)">Add</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--optional placeholder element for dropzone-->
        <!--will be removed from DOM on init-->
        <div class="dndPlaceholder" dndPlaceholderRef></div>
      </section>
    </div>
  </div>
</div>

<div *ngIf="report" class="padding20 white-bg">
  <div class="row">
    <div class="col-12 btn-cont">
      <button
        (click)="report = false"
        style="margin-top: 10px; margin-left: 5px"
        class="btn btn-primary"
      >
        << Back
      </button>
    </div>
    <div class="col-12 spacer30"></div>
    <!-- if records found -->
    <div *ngIf="reports?.length > 0" class="col-12">
      <div *ngFor="let records of reports" class="report-block">
        <div *ngFor="let record of records.attributes">
          <div
            *ngIf="record.type != 'button' && record.type != 'paragraph'"
            class="row"
          >
            <div class="col-4">{{ record.label }}</div>
            <div class="col-8">{{ record.value }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- if no records found -->
    <div *ngIf="reports?.length == 0" class="col-12 btn-cont">
      No response found
    </div>
  </div>
</div>

<div class="container marginT60 marginB30" style="max-width: 500px">
  <div class="row" style="padding: 30px">
    <div *ngIf="showComponent">
      <app-dynamic-form [parentData]="dataNew"></app-dynamic-form>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary"
    style="float: right; margin-right: 10px; margin-bottom: 20px"
    (click)="onSave()"
  >
    Save
  </button>
</div>
