import { Injectable } from '@angular/core';
import { RestAPIService } from '../restAPI/rest-api.service';
import { ProbationFeebackUrlsService } from '../restAPI/probationFeedbackURLs/probation-feeback-urls.service';

@Injectable({
  providedIn: 'root'
})
export class NewProbationFeedbackGroupServiceService {

  constructor(private restApiService: RestAPIService,
    private feedbackgroupurl: ProbationFeebackUrlsService
  ) { }

  create(payload) {
    return this.restApiService.post(
      this.feedbackgroupurl.probationFeebackBaseUrl(),
      payload
    )
  }

  getfeedbackbyuser(id) {
    return this.restApiService.get(
      this.feedbackgroupurl.getProbationFeebackBaseUrl(id)
    )
  }
  
  update(payload, id) {
    return this.restApiService.put(
      this.feedbackgroupurl.updateProbationFeeback(id),
      payload
    )
  }

  delete(id) {
    return this.restApiService.delete(
      this.feedbackgroupurl.deleteProbationFeeback(id)
    )
  }

  getRefresh() {

  }
}
