<div class="main_header_div col-md-12">
  <div class="w-head">
    <div class="svg-container floater-icon-bg">
      <img src="assets/icons/dashboard-icons/yellow-calendar.svg"/>
    </div>
    Floater Leave
  </div>
  <img  class="close-icon-svg"
  (click)="closeDialog()" src="assets/icons/dashboard-icons/close-pink-icon.svg"/>

</div>

<div class="row">
  <div class="col-md-7 desc-sub-div ml-2 mt-3">
    Applying For: {{ this.data.holidayDescription }}
  </div>
  <div class="col-md-4 desc-sub-div mt-3">
    Leave Balance: {{ restrictedHolidays }}
  </div>
  <div class="col-md-7 desc-sub-div ml-2 mb-3">
    To:
    <p class="blue">{{ careerManagerEmailId }}</p>
  </div>
  <div class="col-md-4 desc-sub-div mb-3">
    Date: {{ this.data.holidayDate | date }}
  </div>
</div>

<div class="submit_button_main" *ngIf="this.restrictedHolidays > 0">
  <button class="submit_btn" type="submit" (click)="onSubmit()">Submit</button>
</div>

<div class="submit_button_main" *ngIf="this.restrictedHolidays <= 0">
  <div class="red">Not enough leave balance.</div>
</div>
