<div class="main_header_div">
    <div class="child">
        <h1 class="header_name "><img class="header-icon mr-3" src="assets\icons\travel.png"> Travel history with Blue Altair</h1>
    </div>
    <div class="pr-3 pt-3">
        <img class="close-icon" (click)="closeDialog($event)" src="assets/icons/close.png">
    </div>
</div>
<hr />
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 form_main_div">
      <div class="add_button_fix">
        <button class="add_button" (click)="addbluealtairtravel()">+Add</button>
        <button class="plus_button mt-4" (click)="addbluealtairtravel()">
          <span>+Add</span>
        </button>
      </div>
      <form [formGroup]="editBusinesstravel2">
        <div class="bodyScroll">
          <div formArrayName="bluealtairarray" *ngFor="
              let bluetravel of this.BlueAltairTravel().controls;
              let i = index
            ">
            <div class="row">
              <div class="col-md-3" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>From Country<span class="contentRed"></span></mat-label>
                  <mat-select Required formControlName="fromCounry">
                    <mat-option *ngFor="let option of countries" [value]="option">{{ option }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="
                      editBusinesstravel2
                        .get('bluealtairarray')
                        ['controls'][i].get('fromCounry')
                        .hasError('required')
                    ">
                    Required
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>To Country<span class="contentRed"></span></mat-label>
                  <mat-select Required formControlName="toCountry">
                    <mat-option *ngFor="let option of countries" [value]="option">{{ option }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="
                      editBusinesstravel2
                        .get('bluealtairarray')
                        ['controls'][i].get('toCountry')
                        .hasError('required')
                    ">
                    Required
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>Travel Date <span class="contentRed"></span></mat-label>
                  <input matInput [min]="minDate" [max]="today" [matDatepicker]="picker4" placeholder="dd/mm/yyyy"
                    formControlName="travelDate" />
                  <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                  <mat-datepicker #picker4></mat-datepicker>
                  <mat-error *ngIf="
                      editBusinesstravel2
                        .get('bluealtairarray')
                        ['controls'][i].get('travelDate')
                        .hasError('required') && !editBusinesstravel2
                        .get('bluealtairarray')
                        ['controls'][i].get('travelDate')
                        .hasError('matDatepickerParse')
                    ">
                    Required
                  </mat-error>
                  <mat-error *ngIf="
                      editBusinesstravel2
                        .get('bluealtairarray')
                        ['controls'][i].get('travelDate')
                        .hasError('matDatepickerParse')
                    ">
                    Invalid Date
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-3" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>Reason For Travel<span class="contentRed"></span></mat-label>
                  <input matInput formControlName="reasonOfTravel" autocomplete="off" />
                  <mat-error *ngIf="
                      editBusinesstravel2
                        .get('bluealtairarray')
                        ['controls'][i].get('reasonOfTravel')
                        .hasError('required')
                    ">
                    Required
                  </mat-error>
                  <mat-error *ngIf="
                      editBusinesstravel2
                        .get('bluealtairarray')
                        ['controls'][i].get('reasonOfTravel')
                        .hasError('pattern')
                    ">
                    Invalid pattern
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row margin-bottom">
              <div class="col-md-6 additional-info-input" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>Additonal Info</mat-label>
                  <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"
                    cdkAutosizeMaxRows="15" placeholder="Additional Info" formControlName="additionalInfo"
                    maxlength="250"></textarea>
                  <mat-hint>
                    <label *ngIf="
                        !editBusinesstravel2
                          .get('bluealtairarray')
                          ['controls'][i].get('additionalInfo').value
                      ">
                      Remaining characters: 250 / 250
                    </label>
                    <label *ngIf="
                        editBusinesstravel2
                          .get('bluealtairarray')
                          ['controls'][i].get('additionalInfo').value
                      ">
                      Remaining characters:
                      {{
                      250 -
                      editBusinesstravel2
                      .get("bluealtairarray")
                      ["controls"][i].get("additionalInfo").value.length
                      }}
                      / 250
                    </label>
                  </mat-hint>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <button class="remove_passport_label" (click)="removebluealtair(bluetravel.value.id, i)">
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="submit_button_main">
              <button class="save_Emp_btn" type="submit" (click)="onSubmit()">
                <span>Save</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
