<div class="table-fixed">
    <table class="table-data">
        <thead>
            <tr class="table-head">
                <th class="reg-category">Category</th>
                <th class="reg-date">Date</th>
                <th class="reg-day">No. of Days</th>
                <th class="reg-status">Status</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngIf="regularizedDates.length == 0">
                <tr>
                    <td colspan="4" class="no-data-message">No data available</td>
                </tr>
            </ng-container>
            <ng-container *ngIf="regularizedDates.length > 0">
                <ng-container class="table-body" *ngFor="let item of regularizedDates | orderBy : 'attendanceDateTime'">
                    <tr>
                        <td class="reg-category">
                            <label>Regularization</label>
                        </td>
                        <td class="reg-date">
                            <label>{{ item.attendanceDateTime | date }}</label>
                        </td>
                        <td class="reg-day">
                            <label>1</label>
                        </td>
                        <td class="reg-status">
                            <label>Approved</label>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
        </tbody>
    </table>
</div>

