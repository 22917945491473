import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { MessageService } from 'primeng/api';
import { FormsSchemaService } from 'src/app/services/forms/forms-schema.service';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import swal from 'sweetalert2';
import { field, value } from '../form-template-dialog/global.model';

@Component({
  selector: 'app-form-template-dialog',
  templateUrl: './form-template-dialog.component.html',
  styleUrls: ['./form-template-dialog.component.scss'],
})
export class FormTemplateDialogComponent implements OnInit {
  value: value = {
    label: '',
    value: '',
  };

  formTypes: string[] = [
    'Manager To Supervisor',
    'Supervisor To Manager',
    'Manager To Peer',
    'Peer To Manager',
    'Supervisor To Peer',
    'Peer To Supervisor',
    'Client Feedback',
  ];
  success = false;
  public dataNew;
  public show;
  public showComponent: boolean = false;
  public serverName;

  public addSection: boolean = false;
  public inc: number = 0;
  public j: number = 0;
  public counter: number = 0;
  public temp: any[];
  public isHeader: boolean = false;
  public isDescription: boolean = true;
  public showSubmitButton: Boolean;
  @Output() public childEvent = new EventEmitter();
  fromPage: any;
  display: string;
  public isFilledFlag = [];
  onEditPrevious() {
    if (this.inc > 0) {
      this.inc--;
    }
    if (this.inc == 0) {
      this.isDescription = true;
    }
  }
  onEditNext() {
    if (this.inc < this.model.length - 1) {
      this.inc++;
      this.isDescription = false;
    }
  }
  onClose() {
    this.dialogRef.close();
    this.editDialogRef.close();
  }
  onSave() {
    let sourceData = JSON.parse(JSON.stringify(this.model));
    this.isFormFilled(sourceData);
    if (this.isFilledFlag.includes(false)) {
      this.notificationService.warn('Please answer all the questions');
    } else {
      this.dialogRef.close([sourceData, true]);
      this.editDialogRef.close(sourceData);
    }
  }

  isFormFilled(sourceData) {
    this.isFilledFlag = [];
    if (sourceData != undefined) {
      for (let index = 0; index < sourceData.length; index++) {
        for (let item of sourceData[index].attributes) {
          if (item.type == 'text') {
            item.value != undefined
              ? (this.isFilledFlag[index] = true)
              : (this.isFilledFlag[index] = false);
            break;
          } else if (item.type == 'textarea') {
            item.value != undefined
              ? (this.isFilledFlag[index] = true)
              : (this.isFilledFlag[index] = false);
            break;
          } else if (item.type == 'autocomplete') {
            item.value != undefined
              ? (this.isFilledFlag[index] = true)
              : (this.isFilledFlag[index] = false);
            break;
          } else if (item.type == 'checkbox') {
            this.isFilledFlag[index] = false;
            for (let v of item.values) {
              if (v.selected == true) {
                this.isFilledFlag[index] = true;
                break;
              }
            }
          } else if (item.type == 'radio') {
            item.value != undefined
              ? (this.isFilledFlag[index] = true)
              : (this.isFilledFlag[index] = false);
            break;
          }
        }
      }
    }
  }

  OnInput(value) {
    this.serverName = value;
  }
  getNewFormSection() {
    return {
      name: 'App name...',
      theme: {
        bgColor: 'ffffff',
        textColor: '555555',
        bannerImage: '',
      },
      attributes: [],
    };
  }
  onAddSection() {
    this.addSection = true;
    this.isDescription = false;
    this.model.push(this.getNewFormSection());
    this.temp = JSON.parse(JSON.stringify(this.model));
    this.inc++;
  }
  get staticInc() {
    return this.inc;
  }
  fieldModels: Array<field> = [
    {
      type: 'text',
      icon: 'fa-font',
      label: 'Text',
      description: 'Enter your name',
      placeholder: 'Enter your name',
      className: 'form-control',
      subtype: 'text',
      regex: '',
      handle: true,
    },
    {
      type: 'textarea',
      icon: 'fa-text-width',
      label: 'Textarea',
    },
    {
      type: 'checkbox',
      required: true,
      label: 'Checkbox',
      icon: 'fa-list',
      description: 'Checkbox',
      inline: true,
      values: [
        {
          label: 'Option 1',
          value: 'option-1',
        },
        {
          label: 'Option 2',
          value: 'option-2',
        },
      ],
    },
    {
      type: 'radio',
      icon: 'fa-list-ul',
      label: 'Radio',
      description: 'Radio boxes',
      values: [
        {
          label: 'Option 1',
          value: 'option-1',
        },
        {
          label: 'Option 2',
          value: 'option-2',
        },
      ],
    },
    {
      type: 'autocomplete',
      icon: 'fa-bars',
      label: 'Select',
      description: 'Select',
      placeholder: 'Select',
      className: 'form-control',
      values: [
        {
          label: 'Option 1',
          value: 'option-1',
        },
        {
          label: 'Option 2',
          value: 'option-2',
        },
        {
          label: 'Option 3',
          value: 'option-3',
        },
      ],
    },
    {
      type: 'button',
      icon: 'fa-paper-plane',
      subtype: 'submit',
      label: 'Submit',
    },
  ];
  model: any = [
    {
      name: 'App name...',
      description: 'App Description...',
      theme: {
        bgColor: 'ffffff',
        textColor: '555555',
        bannerImage: '',
      },
      attributes: [],
    },
  ];
  disableEdit: Boolean;
  report = false;
  reports: any = [];
  constructor(
    config: NgbCarouselConfig,
    public formSchemaService: FormsSchemaService,
    private route: ActivatedRoute,
    public initialAvatarService: InitialAvatarService,
    public dialogRef: MatDialogRef<FormTemplateDialogComponent>,
    public editDialogRef: MatDialogRef<FormTemplateDialogComponent>,
    public notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    config.interval = 10000;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = false;
  }
  ngOnInit() {
    if (this.editDialogRef.componentInstance.data != null)
      if (this.editDialogRef.componentInstance.data.length != 0) {
        this.isDescription = true;
        this.model = JSON.parse(this.editDialogRef.componentInstance.data[0]);
        this.disableEdit = JSON.parse(
          this.editDialogRef.componentInstance.data[1]
        );

        this.addSection = true;
        this.isHeader = true;
        this.dataNew = this.model;
        this.isFormFilled(this.model);
        if (this.isFilledFlag.includes(true) || this.disableEdit == true) {
          this.showSubmitButton = false;
        } else {
          this.showSubmitButton = true;
        }
      }
  }
  ngAfterInit() {}
  onDragStart(event: DragEvent) {}
  onDragEnd(event: DragEvent) {}
  onDraggableCopied(event: DragEvent) {}
  onDraggableLinked(event: DragEvent) {}
  onDragged(item: any, list: any[], effect: DropEffect) {
    if (effect === 'move') {
      const index = list.indexOf(item);
      list.splice(index, 1);
    }
  }
  onDragCanceled(event: DragEvent) {}
  onDragover(event: DragEvent) {}
  onDrop(event: DndDropEvent, list?: any[]) {
    if (list && (event.dropEffect === 'copy' || event.dropEffect === 'move')) {
      if (event.dropEffect === 'copy')
        event.data.name = event.data.type + '-' + new Date().getTime();
      let index = event.index;
      if (typeof index === 'undefined') {
        index = list.length;
      }
      list.splice(index, 0, event.data);
    }
  }
  addValue(values) {
    values.push(this.value);
    this.value = { label: '', value: '' };
  }
  removeField(i) {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to remove this field?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00B96F',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove!',
    }).then((result) => {
      if (result.value) {
        this.model[this.inc].attributes.splice(i, 1);
      }
    });
  }
  updateForm() {
    let input = new FormData();
    input.append('id', this.model[this.inc]._id);
    input.append('name', this.model[this.inc].name);
    input.append('description', this.model[this.inc].description);
    input.append('bannerImage', this.model[this.inc].theme.bannerImage);
    input.append('bgColor', this.model[this.inc].theme.bgColor);
    input.append('textColor', this.model[this.inc].theme.textColor);
    input.append('attributes', JSON.stringify(this.model[this.inc].attributes));
  }
  initReport() {
    this.report = true;
    let input = {
      id: this.model[this.inc]._id,
    };
  }
  toggleValue(item) {
    item.selected = !item.selected;
  }
  submit() {
    let valid = true;
    let validationArray = JSON.parse(
      JSON.stringify(this.model[this.inc].attributes)
    );
    if (!valid) {
      return false;
    }
    let input = new FormData();
    input.append('formId', this.model[this.inc]._id);
    input.append('attributes', JSON.stringify(this.model[this.inc].attributes));
  }
}
