<div class="main_header_div mt-3">
  <div class="child">
    <label class="header_name">Revoke</label>
  </div>
  <div class="child_close_btn">
    <img class="close-icon-svg" (click)="closeDialog()" src="assets/icons/dashboard-icons/close-pink-icon.svg" />

  </div>
</div>
<hr />
<div class="container-fluid">
  <form [formGroup]="newAssetDetails">
    <div class="row">
      <div class="col-md-5">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Comment <span class="contentRed"></span></mat-label>
          <input matInput formControlName="comment" autocomplete="off" />
          <mat-error *ngIf="checkError('comment', 'required')"
            >Required</mat-error
          >
          <mat-error
            *ngIf="
              newAssetDetails.get('comment').hasError('noOnlySpecialCharacters')
            "
            >Invalid characters</mat-error
          >
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="submit_button_main">
          <button
            class="add_Emp_btn"
            *ngIf="!this.revokeInProgress"
            (click)="revokeAsset()"
          >
            <span class="add_em_name">Revoke</span>
          </button>
          <button class="add_Emp_btn" *ngIf="this.revokeInProgress" disabled>
            <span class="add_em_name">Revoking...</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
