<!-- Part 2 Comments-->

<div class="container-fluid maint-div">
  <div class="row image-header">
    <img class="img-logo" src="assets/images/BA-logo.png" />
  </div>
  <div class="row form-details-div">
    <div class="col">
      <div class="row title">
        <span class="font-lighter">Review For</span>
      </div>
      <div class="row review-user-row">
        <div class="col-2 m-0 review-user-logo-div">
          <img
            class="review-user-logo d-inline"
            [src]="this.initialAvatarService.getInitialImage('Tejas', 'Kuthe')"
          />
        </div>
        <div class="col-10 review-user-name-div d-inline">
          <span class="review-user-name font-lighter">Tejas Kuthe</span>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="row title">
        <span class="font-lighter">Career Manager</span>
      </div>
      <div class="row career-manager-row">
        <div class="col career-manager-name-div">
          <span class="career-manager-name font-lighter">Aniket Bhat</span>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="row title">
        <span class="font-lighter">Time Frame</span>
      </div>
      <div class="row time-frame-row">
        <div class="col time-frame-div">
          <span class="time-frame font-lighter">Jan 2021 - Dec 2021</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row category-div rounded-corners">
    <span class="category font-heavy">{{
      this.formSchemaService.currentFormCategory
    }}</span>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="nav-arrow-div">
        <button
          *ngIf="inc != 0"
          type="button"
          class="btn btn-success icon-position"
          (click)="onEditPrevious()"
        >
          <i class="fas fa-arrow-left"></i>
        </button>
      </div>

      <div class="question-col-div" *ngIf="model[inc] && !report">
        <app-dynamic-form [parentData]="model[inc]" [disableEdit]="disableEdit">
        </app-dynamic-form>
      </div>

      <div class="nav-arrow-div">
        <button
          *ngIf="inc != this.model.length - 1"
          type="button"
          class="btn btn-success icon-right icon-position"
          (click)="onEditNext()"
        >
          <i class="fas fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="row formFooter">
    <div
      id="cancel"
      class="col btn-footer btn-footer-cancel"
      (click)="onClose()"
    >
      <span id="cancelBtn" class="btn-label font-heavy btn-cancel">
        Cancel
      </span>
    </div>

    <div class="col btn-footer btn-footer-save" *ngIf="showSubmitButton">
      <span class="btn-label font-heavy btn-save"> Save </span>
    </div>

    <div
      class="col btn-footer btn-footer-submit"
      *ngIf="showSubmitButton"
      (click)="onSave()"
    >
      <span class="btn-label btn-submit"> Submit </span>
    </div>
  </div>
</div>

<!-- TRIALS -->
