<table class="table">
  <thead>
    <th scope="col" width="12%">Core Competency</th>
    <th scope="col" width="30%">Question</th>
    <th scope="col" width="23%">Answer</th>
    <th scope="col" width="35%">Comment</th>
  </thead>
  <tbody>
    <ng-container *ngFor="let page of parentData; let pi = index">
      <tr *ngFor="let item of page.attributes; let i = index">
        <td
          *ngIf="i == 0"
          [attr.rowspan]="page.attributes.length"
          [attr.class]="pi % 2 == 0 ? 'even' : 'odd'"
        >
          {{ page.name }}
        </td>
        <td>
          {{ item.label }}
        </td>
        <td>
          {{ item.value }}
        </td>
        <td>
          <div class="comment" [class.show]="item.show">
            {{ item.description }}
          </div>
          <button
            *ngIf="item.description"
            (click)="copyDescriptionToClipboard(item.description)"
            class="copy-btn"
          >
            <mat-icon
              ><span class="material-icons" style="font-size: 20px">
                content_copy
              </span>
            </mat-icon>
          </button>
          <button
            class="show-button"
            *ngIf="item.description && item.description.length > 100"
            (click)="item.show = !item.show"
          >
            {{ item.show ? "Show less" : "Show More" }}
          </button>
        </td>
      </tr>
      <tr *ngFor="let item of page.question; let i = index">
        <td
          *ngIf="i == 0"
          [attr.rowspan]="page.question.length"
          [attr.class]="pi % 2 == 0 ? 'even' : 'odd'"
        >
          {{ page.name }}
        </td>
        <td>
          {{ item.que }}
        </td>
        <td></td>
        <td>
          <div class="comment" [class.show]="item.show">
            {{ item.ans }}
          </div>
          <button
            *ngIf="item.ans"
            (click)="copyDescriptionToClipboard(item.ans)"
            class="copy-btn"
          >
            <mat-icon
              ><span class="material-icons" style="font-size: 20px">
                content_copy
              </span>
            </mat-icon>
          </button>

          <button
            class="show-button"
            *ngIf="item.ans && item.ans.length > 100"
            (click)="item.show = !item.show"
          >
            {{ item.show ? "Show less" : "Show More" }}
          </button>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
