import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-anniversary-event',
  templateUrl: './anniversary-event.component.html',
  styleUrls: ['./anniversary-event.component.scss'],
})
export class AnniversaryEventComponent implements OnInit {
  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  years: number[] = [];
  selectedMonth: string = this.months[new Date().getMonth()];
  selectedYear: number = new Date().getFullYear();
  empDetail = [];
  Birthdaylist = [];
  Anniversarylist = [];
  userThumbnails = [];
  overall = 0;
  todaydate = new Date();
  
  constructor(
    private userService: UserService
  ) {}

  ngOnInit(): void {
  this.initiateAnniversaryEvent();
  }
 initiateAnniversaryEvent()
 {
  this.userService.getAllUsers().subscribe((res) => {
    this.userService.setUsersDto(res.body);
    this.empDetail = this.userService.usersDto;
    this.empDetail.forEach((element) => {
      if (element.joiningDate !== null) {
        let day2 = element.joiningDate;
        let date2 = new Date(day2);
        let date3 = new Date();

        const isTodayAnniversary =
          date2.getDate() == date3.getDate() &&
          date2.getMonth() == date3.getMonth();

        if (
          date2.getDate() >= date3.getDate() &&
          date2.getMonth() == date3.getMonth()
        ) {
          this.Anniversarylist.push({
            firstName: element.firstName,
            lastName: element.lastName,
            userImageThumbnail: element.userImageThumbnail,
            message: this.processDate(element.joiningDate),
            joiningDate: element.joiningDate,
            isImage: element.userImageThumbnail !== null,
            isToday: isTodayAnniversary,
          });
        }
      }
    });

    this.Anniversarylist.sort((a, b) => {
      const dateA = new Date(a.joiningDate);
      const dateB = new Date(b.joiningDate);
      return dateA.getDate() - dateB.getDate();
    });
  });

  if (this.userService.allUserData.length == 0) {
    this.userService.getAllUser();
  }
 }
  processDate(i) {
    let work_anniversary;
    if (i) {
      let joiningDate = new Date(i);
      let todaydate: Date = new Date();
      let diff = todaydate.getTime() - joiningDate.getTime();
      let days = diff / (1000 * 60 * 60 * 24);
      this.overall += days;
      let year = this.getYearFromDays(days);
      let months = this.getMonthFromDays(days);
      if (
        joiningDate.getMonth() === todaydate.getMonth() &&
        joiningDate.getDate() > todaydate.getDate()
      ) {
        joiningDate.setFullYear(todaydate.getFullYear());

        // Create a function to format the date as dd-MM-yyyy
        function formatDate(date) {
          const day = date.getDate().toString().padStart(2, '0');
          const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so we add 1
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
        }

        // Format the date and assign it to work_anniversary
        work_anniversary = formatDate(joiningDate);
      } else if (year > 0) {
        let ordinalIndicator = this.getOrdinalIndicator(year);
        work_anniversary = `Today ${year}${ordinalIndicator} Anniversary Celebration`;
      } else {
        work_anniversary = '< 1 year';
      }
    }
    return work_anniversary;
  }

  getOrdinalIndicator(year: number): string {
    switch (year % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  getMonthFromDays(days) {
    return Math.floor((days % 365) / 30);
  }

  getYearFromDays(days) {
    return Math.floor(days / 365);
  }
}
