export enum FeedbackSubmitStatus {
    PENDING='PENDING', 
    SUBMITTED='SUBMITTED'
}
export enum FeedbackReadStatus {
    UNREAD='UNREAD',
    READ='READ'
}
export enum Page{
    Project = "Project",
    CareerMangerTeam = "Career Manager Team"
}
export enum FeedbackRole{
        SUPERVISOR,
        PEER,
        CLIENT,
        CAREER_MANAGER,
        DIRECT_REPORTEE,
        SELF_EVALUATION
}
export enum RealTimeFeedbackRole{
        SUPERVISOR,
        PEER,
        CAREER_MANAGER,
        DIRECT_REPORTEE,
        
}
export enum ProbationFeedbackRole{
        SUPERVISOR,
        PEER,
        CAREER_MANAGER,
        SELF_EVALUATION,
        REVERSE_PROBATION_FEEDBACK,
        CLIENT
}
export enum FeedbackTemplateCategory {
    PROJECT,
    PERFORMANCE
}
