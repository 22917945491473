import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResignationUrlsService {
  private readonly rejectResignationBaseUrl = '/api/exit-details/acceptOrReject';
  private readonly exitDetailsBaseUrl = '/api/exit-details';

  constructor() { }

  rejectResignation(isAccepted, id) {
    return this.rejectResignationBaseUrl + '/' + isAccepted + '/' + id;
  }
  getExitDetailsBaseUrl(id) {
    return this.exitDetailsBaseUrl + '/' + id;
  }

  postKtUserBaseUrl(id) {
    return this.exitDetailsBaseUrl + '/' + id + '/knowledge-transfers';
  }

  deleteKtUserBaseUrl(id,ktId) {
    return this.exitDetailsBaseUrl +'/'+id+ '/knowledge-transfer/'+ktId; 
  }
}
