import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { LoginService } from 'src/app/services/login.service';
import { AttendanceService } from 'src/app/services/attendance-extra/attendance.service';
import { Attendance } from 'src/app/models/attendance';
import { AttendanceStatus } from '../../services/attendance-extra/attendance-enum';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user/user.service';
import { AuthGuardService } from 'src/app/services/auth-guard.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { NotificationsComponent } from '../notifications/notifications.component';
import { RefreshService } from 'src/app/services/refreshService/refresh.service';
import { SearchEmployeeComponent } from './search-employee/search-employee.component';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
})

export class PagesComponent implements OnInit, AfterViewInit {
  mode = new FormControl('side' as MatDrawerMode);
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  mark_Attendance;
  attendance_Marked;
  disableState: boolean = false;
  data: Attendance = new Attendance();
  attendanceStatus: boolean = false;
  opened: boolean = false;
  userData: string;
  searchText = '';
  filteredUsers: Observable<User[]>;
  allUsers: User[];
  selectedUser: any;
  userControl: FormControl = new FormControl();
  counter: number = 0;
  isDialogOpen: boolean = false;
  @Input() disableClose;
  @ViewChild('searchbar') searchbar: ElementRef;
  toggleSearch: boolean = false;
  searchbarOpen = false;
  userId: any;
  isExpanded: boolean = false;

  constructor(
    private observer: BreakpointObserver,
    public loginService: LoginService,
    private authguardService: AuthGuardService,
    private userService: UserService,
    public userDetatilsService: UserDetailsService,
    private attendanceService: AttendanceService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private refreshService: RefreshService,
  ) {
    this.refreshService.refresh$.subscribe((componentName) => {
      if (componentName === 'pages') {
        this.updateNotificationCounter();
      }
    });
  }

  ngOnInit(): void {
    this.counter = 0;
    this.updateNotificationCounter();
    this.checkAttendanceStatus();
    this.userId = this.userDetatilsService.getUserDetails().id;
    this.updateImg();
  }

  onSidebarToggled(expanded: boolean) {
    this.isExpanded = expanded;
  }

  updateImg() {
    this.userService.getUserByID(this.userId).subscribe(
      (res) => {
        if (res.body.userImageThumbnail !== '') {
          this.userData =
            'data:image/png;base64,' + res.body.userImageThumbnail;
        } else {
          this.userData = 'empty';
        }
      },
      (e) => {
      }
    );
    this.userDetatilsService.image$.subscribe(imageUrl => {
      this.userData = imageUrl ? imageUrl : this.userData;
    });
  }

  checkAttendanceStatus() {
    this.attendanceService.getAttendanceForLoggerInUser().subscribe((res) => {
      if (res.body == false) {
        this.mark_Attendance = true;
        this.attendance_Marked = false;
      } else {
        this.mark_Attendance = false;
        this.attendance_Marked = true;
        this.disableState = false;
      }
    });
  }

  searchClose() {
    this.searchText = '';
    this.toggleSearch = false;
  }

  markAttendance() {
    this.checkAttendanceStatus();
    if (!this.disableState) {
      this.data.createPayload(
        new Date(),
        AttendanceStatus.PRESENT,
        this.userDetatilsService.getUserDetails().id
      );
      this.attendanceService.SendAttendance(this.data).subscribe((res) => {
        this.disableState = true;
        this.mark_Attendance = false;
        this.attendance_Marked = true;
        this.notificationService.sucess(
          'Your Attendance is marked Successfully'
        );
      });
    }
    this.disableState = true;
  }

  ngAfterViewInit(): void {
    this.observer.observe(['(max-width: 590px)']).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = 'over';
        this.sidenav.close();
      } else {
        this.sidenav.mode = 'side';
        this.sidenav.open();
      }
    });
  }

  logOut() {
    this.authguardService.signOut();
  }

  updateNotificationCounter() {
    this.notificationService.getCounterOfNotification().subscribe((res) => {
      this.counter = res.body.length;
    });
  }

  openNotifications() {
    this.isDialogOpen = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '34%';
    dialogConfig.height = '68%';
    dialogConfig.panelClass = 'custom-not-dialog';
    dialogConfig.position = {
      top: '58px',
      right: '0',
    };
    const dialogRef = this.dialog.open(NotificationsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.isDialogOpen = false;
      this.updateNotificationCounter();
    });
  }

  openEmployeeSearch() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = 'auto';
    dialogConfig.height = 'auto';
    dialogConfig.panelClass = 'custom-not-dialog';
    dialogConfig.position = {
      top: '1%',
      right: '11%',
    };
    const dialogRef = this.dialog.open(SearchEmployeeComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => { });
  }
}
