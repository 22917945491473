<div class="form-outerdiv">
  <div class="main-header-div">
    <div class="col-md-3 caption">
      <img class="upload_img" src="assets/images/BA-logo.png" alt="" />
    </div>
    <div class="col-md-2 caption">
      <label>Review For</label>
      <label class="name">{{ this.dataActual[2] }}</label>
    </div>
    <div class="col-md-2 caption">
      <label>Career Manager</label>
      <label class="name">{{ this.dataActual[4] }}</label>
    </div>
    <div class="col-md-2 caption">
      <label>Time Frame</label>
      <label class="name">{{ this.dataActual[5] }}</label>
    </div>
    <div class="col-md-2 caption">
      <label>Score</label>
      <label class="name"
        >{{ this.totalScore / this.questionCount | number : "1.2-2" }}/5</label
      >
    </div>
    <button class="close_label" (click)="closeDialog()"><img class="close-icon-svg"  
      src="assets/icons/dashboard-icons/close-pink-icon.svg" /></button>
  </div>
  <div class="emp-display">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <table class="mobile-emp-details">
          <tr>
            <td class="w-1">Review For</td>
            <td class="w-2">
              <label class="m-lbl ml-2">{{ this.dataActual[2] }}</label>
            </td>
          </tr>
          <tr>
            <td class="w-1">Career Manager</td>
            <td class="w-2">
              <label class="m-lbl">{{ this.dataActual[4] }}</label>
            </td>
          </tr>
          <tr>
            <td class="w-1">Time Frame</td>
            <td class="w-2">
              <label class="m-lbl">{{ this.dataActual[5] }}</label>
            </td>
          </tr>
          <tr></tr>
        </table>
      </div>
    </mat-expansion-panel>
  </div>

  <div class="dynamic_form_view_alignment">
    <table class="table">
      <thead>
        <th scope="col" width="25%">Question</th>
        <th scope="col" width="45%">Answer</th>
        <th scope="col" width="30%">Comment</th>
      </thead>
      <tbody>
        <ng-container>
          <tr *ngFor="let question of probationAnswersData; let i = index">
            <td class="question-td">
              {{ question.label }}
            </td>
            <td>
              <div class="comment">
                {{ question.answervalue }}
              </div>
            </td>
            <td>
              {{ question.description }}
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
