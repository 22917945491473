import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user/user.service';
import { ViewChild, ElementRef } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { TaskComponent } from '../tasks/task/task.component';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { ExitChecklistDTO } from 'src/app/dtos/ExitChecklistDTO';
import { ExitChecklist } from 'src/app/services/exit-checklist/exit-checklist.service';

@Component({
  selector: 'app-exit-checklist',
  templateUrl: './exit-checklist.component.html',
  styleUrls: ['./exit-checklist.component.scss'],
})
export class ExitChecklistComponent implements OnInit {
  @ViewChild('divedit') divedit: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;
  userStageFormGroup: FormGroup;
  hrStageFormGroup: FormGroup;
  financeStageFormGroup: FormGroup;
  cmStageFormGroup: FormGroup;
  adminStageFormGroup: FormGroup;
  operationStageFormGroup: FormGroup;

  userStageQueFormArray: FormArray;
  hrStageQueFormArray: FormArray;
  financeStageQueFormArray: FormArray;
  cmStageQueFormArray: FormArray;
  adminStageQueFormArray: FormArray;
  operationStageQueFormArray: FormArray;

  updatedUserExitChecklistDto;

  userList;
  userNameIDMapping = {};
  userIdNameMapping = [];
  updateChecklist;
  options;
  stages: any = {};
  hrFormGroup: FormGroup;
  adminFormGroup: FormGroup;
  operationFormGroup: FormGroup;
  financeFormGroup: FormGroup;
  exitDTO = new ExitChecklistDTO();
  cmFormGroup: FormGroup;
  filteredOptions;
  contentEditable: boolean;
  userID;
  userName;
  currentStepIndex = 0;
  mattabCurrentStepIndex: number = 0;

  exitChecklistStages: any = {
    stages: {
      USER_STAGE: {},
      HR_STAGE: {},
      OPERATIONS_STAGE: {},
      CARRER_MANAGER_STAGE: {},
      FINANCE_STAGE: {},
      ADMIN_STAGE: {},
    },
  };

  displayColumns: string[] = [
    'Sr. no.',
    'Action',
    'Must be Completed by',
    'Action Completion Date',
    'Comments',
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public trnxData: any,
    public dialogRef: MatDialogRef<TaskComponent>,
    private userService: UserService,
    public userDetailService: UserDetailsService,
    public exitService: ExitChecklist,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    //getting all user name
    this.userService.getAllUsers().subscribe((usrData) => {
      usrData.body.forEach((element) => {
        this.userNameIDMapping[element.firstName + ' ' + element.lastName] =
          element.id;
        this.userIdNameMapping[element.id] =
          element.firstName + ' ' + element.lastName;
      });
      this.userList = Object.keys(this.userNameIDMapping);
    });
    this.getExitDetails();
    this.userStageQueFormArray = this.fb.array([]);
    this.hrStageQueFormArray = this.fb.array([]);
    this.financeStageQueFormArray = this.fb.array([]);
    this.cmStageQueFormArray = this.fb.array([]);
    this.adminStageQueFormArray = this.fb.array([]);
    this.operationStageQueFormArray = this.fb.array([]);

    //based on the type change the current index of mat stepper
    if (this.trnxData.typeOfTask == 'UPDATE_CHECKLIST') {
      this.updateChecklist = true;
      switch (this.trnxData.subTask) {
        case 'USER_STAGE':
          this.currentStepIndex = 0;
          break;
        case 'HR_STAGE':
          this.currentStepIndex = 1;
          break;
        case 'OPERATIONS_STAGE':
          this.currentStepIndex = 2;
          this.mattabCurrentStepIndex = 1;
          break;
        case 'ADMIN_STAGE':
          this.currentStepIndex = 2;
          break;
        case 'FINANCE_STAGE':
          this.currentStepIndex = 3;
          break;
        case 'CARRER_MANAGER_STAGE':
          this.currentStepIndex = 4;
          break;
        default:
          this.currentStepIndex = 0;
      }
    }

    this.exitService
      .createChecklist(
        this.exitChecklistStages,
        this.trnxData.taskTransactionId
      )
      .subscribe((res) => {
        this.updatedUserExitChecklistDto = {
          id: res.body.id,
          stages: res.body.stages,
        };
        this.stages = res.body.stages;
        switch (this.currentStepIndex) {
          case 0:
            this.setUserStage();
            break;
          case 1:
            this.setHrStage();
            break;
          case 2:
            this.setAdminStage();
            this.setOperationStage();
            break;
          case 3:
            this.setFinanceStage();
            break;
          case 4:
            this.setCareerManagerStage();
            break;
          default:
            this.setUserStage();
        }
      });
    this.initForm();
    this.getNames();
    this.contentEditable = false;
  }

  async getExitDetails() {
    try {
      const exitDetail = await this.exitService
        .getExitDetails(this.trnxData.taskTransactionId)
        .toPromise();
      this.userID = exitDetail.body.userId;
      this.userName = await this.userService
        .getUserNameByID(this.userID)
        .toPromise();
    } catch (error) {}
  }

  setStage(event) {
    this.userStageQueFormArray = this.fb.array([]);
    this.hrStageQueFormArray = this.fb.array([]);
    this.financeStageQueFormArray = this.fb.array([]);
    this.cmStageQueFormArray = this.fb.array([]);
    this.adminStageQueFormArray = this.fb.array([]);
    this.operationStageQueFormArray = this.fb.array([]);

    this.currentStepIndex = event.selectedIndex;
    switch (this.currentStepIndex) {
      case 0:
        this.setUserStage();
        break;
      case 1:
        this.setHrStage();
        break;
      case 2:
        this.setAdminStage();
        this.setOperationStage();
        break;
      case 3:
        this.setFinanceStage();
        break;
      case 4:
        this.setCareerManagerStage();
        break;
      default:
        this.setUserStage();
    }
  }
  setUserStage() {
    this.stages.USER_STAGE.fields.forEach((rowData) => {
      const rowFormGroup = this.createRowFormGroupForUserStage(rowData);
      this.setFormData(rowData, rowFormGroup);
      this.userStageQueFormArray.push(rowFormGroup);
    });

    this.userStageFormGroup = this.fb.group({
      rows: this.userStageQueFormArray,
    });
  }
  setHrStage() {
    this.hrFormGroup
      .get('hr')
      .setValue(this.userIdNameMapping[this.stages.HR_STAGE.owner]);
    this.stages.HR_STAGE.fields.forEach((rowData) => {
      const rowFormGroup = this.createRowFormGroupForUserStage(rowData);
      this.setFormData(rowData, rowFormGroup);
      this.hrStageQueFormArray.push(rowFormGroup);
    });
    this.hrStageFormGroup = this.fb.group({
      rows: this.hrStageQueFormArray,
    });
  }

  setAdminStage() {
    this.adminFormGroup
      .get('admin')
      .setValue(this.userIdNameMapping[this.stages.ADMIN_STAGE.owner]);
    this.stages.ADMIN_STAGE.fields.forEach((rowData) => {
      const rowFormGroup = this.createRowFormGroupForUserStage(rowData);
      this.setFormData(rowData, rowFormGroup);
      this.adminStageQueFormArray.push(rowFormGroup);
    });
    this.adminStageFormGroup = this.fb.group({
      rows: this.adminStageQueFormArray,
    });
  }

  setOperationStage() {
    this.operationFormGroup
      .get('operation_owner')
      .setValue(this.userIdNameMapping[this.stages.OPERATIONS_STAGE.owner]);
    this.stages.OPERATIONS_STAGE.fields.forEach((rowData) => {
      const rowFormGroup = this.createRowFormGroupForUserStage(rowData);
      this.setFormData(rowData, rowFormGroup);
      this.operationStageQueFormArray.push(rowFormGroup);
    });
    this.operationStageFormGroup = this.fb.group({
      rows: this.operationStageQueFormArray,
    });
  }

  setFinanceStage() {
    this.financeFormGroup
      .get('finance_owner')
      .setValue(this.userIdNameMapping[this.stages.FINANCE_STAGE.owner]);

    this.stages.FINANCE_STAGE.fields.forEach((rowData) => {
      const rowFormGroup = this.createRowFormGroupForUserStage(rowData);
      this.setFormData(rowData, rowFormGroup);
      this.financeStageQueFormArray.push(rowFormGroup);
    });
    this.financeStageFormGroup = this.fb.group({
      rows: this.financeStageQueFormArray,
    });
  }

  setCareerManagerStage() {
    this.cmFormGroup
      .get('cm')
      .setValue(this.userIdNameMapping[this.stages.CARRER_MANAGER_STAGE.owner]);

    this.stages.CARRER_MANAGER_STAGE.fields.forEach((rowData) => {
      const rowFormGroup = this.createRowFormGroupForUserStage(rowData);
      this.setFormData(rowData, rowFormGroup);
      this.cmStageQueFormArray.push(rowFormGroup);
    });
    this.cmStageFormGroup = this.fb.group({
      rows: this.cmStageQueFormArray,
    });
  }

  setFormData(rowData, rowFormGroup) {
    rowFormGroup.get('expectedDate').setValue(rowData.expectedDate);
    rowFormGroup.get('completionDate').setValue(rowData.completionDate);
    rowFormGroup.get('comment').setValue(rowData.comment);
  }

  createRowFormGroupForUserStage(data: any): FormGroup {
    return this.fb.group({
      id: [data.id],
      title: [data.title],
      expectedDate: [data.mustComplete],
      completionDate: [data.actionCompletionDate],
      comment: [data.comments],
      resource: [data.resource] || null,
    });
  }

  assignUser() {
    let currentExitDTO = {
      stages: {
        USER_STAGE: {
          owner: this.userID,
        },
      },
    };
    this.exitService
      .updateChecklist(this.trnxData.taskTransactionId, currentExitDTO)
      .subscribe((res) => {
        this.closeDialog();
      });
  }

  submitUserStage() {
    this.updatedUserExitChecklistDto.stages.USER_STAGE = {
      owner: this.userID,
      fields: this.userStageQueFormArray.value,
    };
    this.exitService
      .updateExitChecklistByUser(
        this.trnxData.taskTransactionId,
        this.updatedUserExitChecklistDto
      )
      .subscribe((res) => {
        this.closeDialog();
      });
  }

  getUserID(username) {
    return this.userNameIDMapping[username];
  }

  assignHr() {
    if (this.hrFormGroup.status === 'VALID') {
      let currentExitDTO = {
        stages: {
          HR_STAGE: {
            owner: this.getUserID(this.hrFormGroup.get('hr').value),
          },
        },
      };
      this.exitService
        .updateChecklist(this.trnxData.taskTransactionId, currentExitDTO)
        .subscribe((res) => {});
    }
  }
  submitHrStage() {
    this.updatedUserExitChecklistDto.stages.HR_STAGE = {
      fields: this.hrStageQueFormArray.value,
    };
    this.exitService
      .updateExitChecklistByUser(
        this.trnxData.taskTransactionId,
        this.updatedUserExitChecklistDto
      )
      .subscribe((res) => {
        this.closeDialog();
      });
  }
  assignAdmin() {
    if (this.adminFormGroup.get(['admin']).status) {
      let currentExitDTO = {
        stages: {
          ADMIN_STAGE: {
            owner: this.getUserID(this.adminFormGroup.get('admin').value),
          },
        },
      };
      this.exitService
        .updateChecklist(this.trnxData.taskTransactionId, currentExitDTO)
        .subscribe((res) => {});
    }
  }
  assignOperationOwner() {
    if (this.operationFormGroup.get(['operation_owner']).status) {
      let currentExitDTO = {
        stages: {
          OPERATIONS_STAGE: {
            owner: this.getUserID(
              this.operationFormGroup.get('operation_owner').value
            ),
          },
        },
      };
      this.exitService
        .updateChecklist(this.trnxData.taskTransactionId, currentExitDTO)
        .subscribe((res) => {});
    }
  }
  assignFinanceOwner() {
    if (this.financeFormGroup.status === 'VALID') {
      let currentExitDTO = {
        stages: {
          FINANCE_STAGE: {
            owner: this.getUserID(
              this.financeFormGroup.get('finance_owner').value
            ),
          },
        },
      };
      this.exitService
        .updateChecklist(this.trnxData.taskTransactionId, currentExitDTO)
        .subscribe((res) => {});
    }
  }

  submitFinanceStage() {
    this.updatedUserExitChecklistDto.stages.FINANCE_STAGE = {
      fields: this.financeStageQueFormArray.value,
    };
    this.exitService
      .updateExitChecklistByUser(
        this.trnxData.taskTransactionId,
        this.updatedUserExitChecklistDto
      )
      .subscribe((res) => {
        this.closeDialog();
      });
  }
  assignCarreerManager() {
    if (this.cmFormGroup.status === 'VALID') {
      let currentExitDTO = {
        stages: {
          CARRER_MANAGER_STAGE: {
            owner: this.getUserID(this.cmFormGroup.get('cm').value),
          },
        },
      };
      this.exitService
        .updateChecklist(this.trnxData.taskTransactionId, currentExitDTO)
        .subscribe((res) => {});
    }
  }
  submitCarreerManagerStage() {
    this.updatedUserExitChecklistDto.stages.CARRER_MANAGER_STAGE = {
      fields: this.cmStageQueFormArray.value,
    };
    this.exitService
      .updateExitChecklistByUser(
        this.trnxData.taskTransactionId,
        this.updatedUserExitChecklistDto
      )
      .subscribe((res) => {
        this.closeDialog();
      });
  }
  changeEditable() {
    document.getElementById('divedit').contentEditable = 'true';
  }
  closeDialog() {
    this.dialogRef.close();
  }
  initForm() {
    this.hrFormGroup = this.fb.group({
      hr: ['', Validators.required],
    });
    this.adminFormGroup = this.fb.group({
      admin: ['', Validators.required],
    });
    this.operationFormGroup = this.fb.group({
      operation_owner: ['', Validators.required],
    });
    this.financeFormGroup = this.fb.group({
      finance_owner: ['', Validators.required],
    });
    this.cmFormGroup = this.fb.group({
      cm: ['', Validators.required],
    });
    this.hrFormGroup.get('hr').valueChanges.subscribe((response) => {
      this.filterData(response);
    });
    this.adminFormGroup.get('admin').valueChanges.subscribe((response) => {
      this.filterData(response);
    });
    this.operationFormGroup
      .get('operation_owner')
      .valueChanges.subscribe((response) => {
        this.filterData(response);
      });
    this.financeFormGroup
      .get('finance_owner')
      .valueChanges.subscribe((response) => {
        this.filterData(response);
      });
    this.cmFormGroup.get('cm').valueChanges.subscribe((response) => {
      this.filterData(response);
    });
  }
  filterData(enteredData) {
    this.filteredOptions = this.options.filter((item) => {
      return item.toLowerCase().indexOf(enteredData.toLowerCase()) > -1;
    });
  }
  getNames() {
    this.userService.getData().subscribe((response) => {
      this.options = response;
      this.filteredOptions = response;
    });
  }
}
