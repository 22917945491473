<div class="row mt-3 holiday">
  <div class="col-md-7 col-sm-12">
    <mat-card class="calendar-card">
      <full-calendar
        #fullCalendar
        [options]="calendarOptions"
        class="full-calendar"
      ></full-calendar>
    </mat-card>
    <div class="color-legend">
      <div class="colour-main-div">
        <div class="general colour-div"></div>
        <div class="colour-div-label">
          <label class="legend-text">Mandatory Holiday</label>
        </div>
      </div>
      <div class="colour-main-div">
        <div class="Restricted colour-div"></div>
        <div class="colour-div-label">
          <label class="legend-text">Floater Holiday</label>
        </div>
      </div>
      <div class="colour-main-div">
        <div class="Client colour-div"></div>
        <div class="colour-div-label">
          <label class="legend-text">Client Holiday</label>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-5 col-sm-12">
    <div class="cont-2">
      <div class="holiday-align">
        <div class="icon-holiday">
          <img src="assets/calendar-icon/holidays.svg"/>
          <div class="holiday-heading">Holidays</div>
        </div>
        <div class="holidays">{{ selectedMonth }}-{{ selectedYear }}</div>
      </div>
      <div class="cont-3">
        <div *ngIf="restrictedHolidays.length == 0" class="heading heading-1">
          No Holidays Available
        </div>
        <div *ngFor="let holiday of restrictedHolidays | orderBy : 'date'">
          <div class="holiday-section">
            <div class="heading heading-1">
              <div>{{ holiday.date }}</div>
              <div>{{ holiday.desc }}</div>
            </div>
            <div class="blue">
              <button *ngIf="!holiday.status" class="applyLeaveBtn-dark">
                Applied
              </button>
              <button
                (click)="
                  applyRestrictedHoliday(holiday.desc, holiday.originalDate)
                "
                *ngIf="
                  holiday.calendarLeaveType == 'FLOATER_HOLIDAY' &&
                  holiday.status
                "
                class="blue-border-button"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
