<div class="loading_div" *ngIf="loadingflag > 0">
  <mat-spinner strokeWidth="7" [diameter]="170"></mat-spinner>
  <label class="loading_label">Loading...</label>
</div>

<div [hidden]="loadingflag > 0">
    <form [formGroup]="newLeaveDetails">
      <div class="container-1">
        <div class="heading">
          <h2 class="head-1">Applying For Leave</h2>
        </div>
        <div class="container-2">
         <div class="row">
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-8">
                <div class="row ">
                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Leave Type<span class="contentRed"></span></mat-label>
                        <mat-select
                          formControlName="typeOfLeave"
                          (selectionChange)="onLeaveTypeChange()"
                        >
                          <mat-option
                            *ngFor="let leaveType of this.leavetypes"
                            [value]="leaveType"
                            [class.selected]="selectedEmailOptions.includes(email)"
                          >
                            {{ leaveType | underScoreToSpace }}
                          </mat-option>
                        </mat-select>
          
                        <mat-error
                          *ngIf="newLeaveDetails.get('typeOfLeave').errors?.required"
                          >Required</mat-error
                        >
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                       
                    </div>
                    
                  </div>
                  <div class="row ">
                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Start Date <span class="contentRed"></span></mat-label>
                        <input
                          [min]="this.minDateofYear"
                          [max]="this.maxDateOfYear"
                          [disabled]="isDisableInput"
                          matInput
                          [matDatepicker]="picker"
                          formControlName="startDate"
                          placeholder="dd/mm/yyyy"
                          (dateChange)="onStartDateChange($event.value)"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error
                          *ngIf="newLeaveDetails.get('startDate').errors?.required"
                          >Required</mat-error
                        >
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Session<span class="contentRed"></span></mat-label>
                        <mat-select
                          [disabled]="isDisableInput"
                          formControlName="startSession"
                          *ngIf="!isExistingLeave"
                          [ngModel]="'First Half'"
                          (selectionChange)="onSessionChange()"
                        >
                          <mat-option
                            *ngFor="let firstnsecondhalf of this.firstsecondhalf"
                            [value]="firstnsecondhalf"
                          >
                            {{ firstnsecondhalf | underScoreToSpace }}
                          </mat-option>
                        </mat-select>
                        <mat-select
                          [disabled]="isDisableInput"
                          formControlName="startSession"
                          *ngIf="isExistingLeave"
                          (selectionChange)="onSessionChange()"
                        >
                          <mat-option
                            *ngFor="let firstnsecondhalf of this.firstsecondhalf"
                            [value]="firstnsecondhalf"
                          >
                            {{ firstnsecondhalf | underScoreToSpace }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row ">
                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>End Date <span class="contentRed"></span></mat-label>
                        <input
                          matInput
                          [min]="newLeaveDetails.controls.startDate.value"
                          [max]="this.maxDateOfYear"
                          [matDatepicker]="picker2"
                          formControlName="endDate"
                          placeholder="dd/mm/yyyy "
                          [disabled]="
                            !newLeaveDetails.controls.startDate.value || isDisableInput
                          "
                          (dateChange)="onSessionChange()"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="picker2"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                        <mat-error
                          *ngIf="newLeaveDetails.controls.endDate.hasError('pattern')"
                          >{{ errorMessage }}</mat-error
                        >
                        <mat-error *ngIf="newLeaveDetails.get('endDate').errors?.required"
                          >Required</mat-error
                        >
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Session</mat-label>
                        <mat-select
                          [disabled]="isDisableInput"
                          formControlName="endSession"
                          *ngIf="!isExistingLeave"
                          [ngModel]="'Second Half'"
                          (selectionChange)="onSessionChange()"
                        >
                          <mat-option
                            *ngFor="let firstnsecondhalf of this.firstsecondhalf"
                            [value]="firstnsecondhalf"
                          >
                            {{ firstnsecondhalf | underScoreToSpace }}
                          </mat-option>
                        </mat-select>
                        <mat-select
                          [disabled]="isDisableInput"
                          formControlName="endSession"
                          *ngIf="isExistingLeave"
                          (selectionChange)="onSessionChange()"
                        >
                          <mat-option
                            *ngFor="let firstnsecondhalf of this.firstsecondhalf"
                            [value]="firstnsecondhalf"
                          >
                            {{ firstnsecondhalf | underScoreToSpace }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  </div>
                  <div class="col-md-4">
                    <div class="leave-data">
                    <label class="leave_balance">Leave Balance: {{leaveBalance | number:'1.2-2'}} {{dayBString}}
                    </label>
                    <label *ngIf="showAccuredBalance" class="leave_balance">Accrued Balance: {{accuredBalance}} {{dayCString}}</label>
                    <label class="leave_balance">Applying for: {{typedLADays}} {{dayAString}}</label>
                  
                    </div>
                  </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                  <div class="row mt-2">
                      <div class="col-md-12">
                          <div class="container-3">
                          <label class="resignation_reason_label">Reason For Leave</label>
                          <div>
                              <textarea matInput type="text" formControlName="reasonForLeave"
                                  class="resignation_reason_input" maxlength="100" placeholder="Enter reason (max 100 characters)">
                              </textarea>
                          </div>
                          <div class=" certificate">
                              <img class="img_size" src="assets/icons/Certificate.png">
                              <label *ngIf="!isAttachmentSaved" for="my_file" class="certificate_label">Attach
                                  File</label>
          
          
                              <input *ngIf="!isAttachmentSaved" type="file" id="my_file" style="display: none;"
                                  multiple accept=".png, .jpg, .pdf" (change)="uploadAttachment($event)" #fileInput />
                                  <div *ngIf="imageError" class="error-message">{{ imageError }}</div>
          
          
                              <ng-container *ngIf="isAttachmentSaved ; else elseBlock">
                                  <label class="certificate_label" (click)="open(content5)">
                                      {{this.attachmentname}}</label>
                                  <button class="remove_label" (click)="removeAttachment()">Remove</button>
                              </ng-container>
                              <ng-template #content5 let-modal>
                                  <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')">
                                      <span aria-hidden="true">&times;</span>
                                  </button>
                                  <div class="modal-body"
                                      *ngIf="this.attachmentType === 'application/pdf'; else imageBlock">
                                      <ng-container>
                                          <pdf-viewer [src]="attachmentBase64" [rotation]="0" [fit-to-page]="false"
                                              [original-size]="false" [show-all]="true"></pdf-viewer>
                                      </ng-container>
                                  </div>
                                  <ng-template #imageBlock>
                                      <img [src]="attachmentBase64" alt="Error" width="100%" height="auto" />
                                  </ng-template>
                              </ng-template>
                              <ng-template #elseBlock>
                              </ng-template>
                          </div>
                          </div>
                      </div>
                  </div>
                  </div>
                  </div>
            </div>
            <div class="col-md-4">
                <mat-card class="main-mat-card-2">
                    <div class="text-primary">

                            <div class="cm-details">
                            <div style="margin-left: 10px;">
                              
                                <img class="noemployee_img" [(src)]="this.carrerManagerImage" alt=""> 
                                </div>
                               <div class="cm-detail"> 
                                <div > {{ carrerManagerName}} </div>
                                <div>{{carrerManagerId}}</div>
                            </div>
                               
                            </div>
        
                            <div class="col-md-4"> 
                            <div class="inputField ">

                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Add CC </mat-label>

                                    <mat-select formControlName="ccMentions" multiple
                                        (selectionChange)="onAddCCSelectionChange($event)">
                                        <input class="myInput" #myInput matInput focused="'true'"
                                            (keyup)="searchAllEmails($event.target.value)" autocomplete="off"
                                            placeholder="Search Email">
                                        <mat-option *ngFor="let user of this.filteredAllEmails" [value]="user">
                                          <ng-container *ngIf="userIdNameMapping[user]">
                                            {{userIdNameMapping[user].firstName + ' ' + userIdNameMapping[user].lastName }}
                                        </ng-container>
                                        </mat-option></mat-select>
                                </mat-form-field>
                            </div>
                           
                        </div>
                        <div class="col-md-12">
                          <div class="cc-container">
                          <div *ngFor="let email of selectedEmailOptions" class="cm-details-1">
                            <div class="details">
                              <img class="noemployee_img-1" [src]="userIdNameMapping[email]?.userImageThumbnail ? 'data:image/png;base64,' + userIdNameMapping[email]?.userImageThumbnail : 'assets/icons/default-avatar.jpg'" alt="" />
                            </div>
                               <div class="cm-detail"> 
                                <div > {{ userIdNameMapping[email].firstName + ' ' + userIdNameMapping[email].lastName}} </div>
                                <div > {{ userIdNameMapping[email].employeeId}} </div>
                            </div>
                               
                            </div>
                            </div>
                        </div>
                    </div>
                </mat-card>
                <div class="btn-apply">
                <button class="blue-button" type="submit" [disabled]=submitButtonDisabled
                    (click)="onSubmit()">
                    <span>{{ applyLeaveLabel }}</span>
                </button>
                </div>
            </div>
         </div>
        </div>
      </div>
    </form>
  </div>