export interface KnowledgeTransferFieldsValue {
    additionalProp1: {
        disabled: boolean;
        value: string;
    };
    additionalProp2: {
        disabled: boolean;
        value: string;
    };
    additionalProp3: {
        disabled: boolean;
        value: string;
    };
}
export interface KnowledgeTransferField {
    [key: number]: {
        knowledgeTransferFieldsValue: KnowledgeTransferFieldsValue;
        title: string;
    };
}
export interface KnowledgeTransfersDTO {
    additionalComments: string;
    endDate: string;
    id: string;
    knowledgeTransferFields: KnowledgeTransferField;
    knowledgeTransferedTo: string;
    projectId: string;
    startDate: string;
    taskType: string;
    title: string;
}
export class knowledgeTransferDTO {
    ccEmails: string;
    exitReason: string;
    id: string;
    knowledgeTransfersDTO: KnowledgeTransfersDTO[];
    lastWorkingDay: string;
    noticePeriodId: string;
    noticePeriodServedInDays: number;
    resignationDate: string;
    status: string;
    taskId: string;
    userId: string;
}