import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RefreshService } from 'src/app/services/refreshService/refresh.service';
import { select, Store } from '@ngrx/store';
import * as fromTask from '../../../store/reducers/task.reducer';
import * as taskActions from '../../../store/actions/task.action';

@Component({
  selector: 'app-taskdialog',
  templateUrl: './taskdialog.component.html',
  styleUrls: ['./taskdialog.component.scss'],
})
export class TaskdialogComponent implements OnInit {
  selectedModuleTaskList: any[] = [];
  totalTaskList: any[] = [];
  moduleName: any;
  pendingTasks$ = this.store.pipe(select(fromTask.selectPendingTasks));
  wfoTasks$ = this.store.pipe(select(fromTask.selectWFOTasks));
  lmsTasks$ = this.store.pipe(select(fromTask.selectLMSTasks));
  performanceTasks$ = this.store.pipe(select(fromTask.selectPerformanceTasks));
  profileTasks$ = this.store.pipe(select(fromTask.selectProfileTasks));
  wfoTasksCount$ = this.store.pipe(select(fromTask.wfoTasksCount));
  lmsTasksCount$ = this.store.pipe(select(fromTask.lmsTasksCount));
  performanceTasksCount$ = this.store.pipe(
    select(fromTask.performanceTasksCount)
  );
  profileTasksCount$ = this.store.pipe(select(fromTask.profileTasksCount));

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TaskdialogComponent>,
    private refreshService: RefreshService,
    private store: Store<any>
  ) {
    this.refreshService.refresh$.subscribe((componentName) => {
      if (componentName === 'taskdialog') {
        this.ngOnInit();
      }
    });
  }

  ngOnInit(): void {
    this.moduleName = this.data.moduleName;
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Closed' });
    this.ngOnInit();
  }
}
