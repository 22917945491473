import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TabNavigationService {

  constructor(private router: ActivatedRoute,) {

  }
  private activeTabSubject = new BehaviorSubject<string>('leave-calendar');
  activeTab$ = this.activeTabSubject.asObservable();
  public startDateForLeave = new BehaviorSubject<Date>(null);



  setActiveTab(tab: string) {
    this.activeTabSubject.next(tab);
  }

  setStartDate(date: Date){
    this.startDateForLeave.next(date);
  }

  deleteStartDateQueryParam() {
    const queryParams = { ...this.router.snapshot.queryParams };    
    if ('startDate' in queryParams) {
      delete queryParams['startDate'];
    }
  }
}
