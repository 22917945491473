import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ValidatorFn,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CareerManagerDTO } from 'src/app/dtos/CareerManagerDTO';
import { FeedbackDTO } from '../../../dtos/FeedbackDTO';
import { ProjectDTO } from 'src/app/dtos/ProjectDTO';
import { CareerManagerTeam } from 'src/app/models/careerManagerTeam';
import { CareerManagerTeamService } from 'src/app/services/careerManager.service';
import { Page } from 'src/app/services/feedback/feedback-enum';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { FormsSchemaService } from 'src/app/services/forms/forms-schema.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { FeedbackTypeEnum } from 'src/app/services/forms/forms-enum';
@Component({
  selector: 'app-feedback-generation-dialog',
  templateUrl: './feedback-generation-dialog.component.html',
  styleUrls: ['./feedback-generation-dialog.component.scss'],
})
export class FeedbackGenerationDialogComponent implements OnInit {
  careermanagerData;
  careerManagerPeerList = [];
  feedbackTypeEnum = [];
  checkError: boolean;
  multipleFeedbackGenerationArray: FormGroup;
  feedbackGeneration: FormArray;

  constructor(
    public editDialogRef: MatDialogRef<FeedbackGenerationDialogComponent>,
    public fb: FormBuilder,
    public formService: FormsSchemaService,
    public careerManagerService: CareerManagerTeamService,
    public userService: UserService,
    public userDetailService: UserDetailsService,
    public feedbackService: FeedbackService
  ) {
    this.multipleFeedbackGenerationArray = this.fb.group({
      feedbacks: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    //create a new form array entry for feedback generation
    this.addFeedbackGeneration();
    //get all form
    this.formService.getAllForms();
    //Get all company data
    this.userService.getAllUser();
    //Get all direct reportees of logged in user
    this.userService.getAllDirectReportees(
      this.userDetailService.getUserDetails().id
    );
    for (let feedbackType in FeedbackTypeEnum) {
      if (isNaN(Number(feedbackType))) {
        this.feedbackTypeEnum.push(feedbackType);
      }
    }
  }

  feedbacks(): FormArray {
    return this.multipleFeedbackGenerationArray.get('feedbacks') as FormArray;
  }

  addFeedbackGeneration() {
    this.feedbackGeneration = this.multipleFeedbackGenerationArray.get(
      'feedbacks'
    ) as FormArray;
    this.feedbackGeneration.push(this.createFeedbackGeneration());
  }

  createFeedbackGeneration() {
    return this.fb.group({
      formTemplate: ['', [Validators.required]],
      feedbackType: ['', [Validators.required]],
      directReportee: ['', [Validators.required]],
      employees: ['', [Validators.required]],
    });
  }

  removeFeedback(i: number) {
    this.feedbacks().removeAt(i);
  }

  processName(user) {
    let name;
    if (user && user.firstName) name = user.firstName;

    if (user && user.lastName) {
      name ? (name += ' ' + user.lastName) : (name = user.lastName);
    }
    return name;
  }

  toggle(expanded) {
    expanded = !expanded;
  }

  displayFn(object) {
    let result = '';
    if (object instanceof Object) {
      return object.firstName + ' ' + object.lastName;
    } else {
      return object;
    }
  }

  onSubmit() {
    if (this.multipleFeedbackGenerationArray.valid) {
      for (let feedback of this.multipleFeedbackGenerationArray.value
        .feedbacks) {
        let feedBackDTO = new FeedbackDTO();
        if (feedback) {
          feedBackDTO.careerManagerId =
            this.userDetailService.getUserDetails().id;
          feedBackDTO.createdById = this.userDetailService.getUserDetails().id;
          feedBackDTO.updatedById = feedBackDTO.createdById;
          feedBackDTO.userReviewFromId = feedback.employees.id;
          feedBackDTO.userReviewToId = feedback.directReportee.id;
          feedBackDTO.formTemplate = JSON.stringify(feedback.formTemplate);
          feedBackDTO.formTemplateType = feedback.formTemplate.formType;
          feedBackDTO.feedbackType = feedback.feedbackType;
          this.feedbackService
            .addFeedbackSubscribe(feedBackDTO)
            .subscribe((res) => {
              this.feedbackService
                .getFeedbackByCareerManagerIdSubscribe(
                  this.userDetailService.getUserDetails().id
                )
                .subscribe((res) => {
                  this.feedbackService.setFeedback(res.body);
                });
            });
        }
      }
      this.editDialogRef.close();
    } else {
      this.checkError = true;
    }
  }

  onClose() {
    this.editDialogRef.close();
  }
}
