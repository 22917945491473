import { Component, Inject, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { LeaveApplicationService } from 'src/app/services/leave-application/leave-application.service';
import { LeaveApplicationDTO } from 'src/app/dtos/LeaveApplicationDTO';

@Component({
  selector: 'app-withdraw-leave-application',
  templateUrl: './withdraw-leave-application.component.html',
  styleUrls: ['./withdraw-leave-application.component.scss'],
})
export class WithdrawLeaveApplicationComponent implements OnInit {
  rejectionDetails: FormGroup;
  userId: String;
  leaveApplications: LeaveApplicationDTO = new LeaveApplicationDTO();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<WithdrawLeaveApplicationComponent>,
    private leaveApplicationService: LeaveApplicationService,
    private userDetailsService: UserDetailsService
  ) {
   
  }

  ngOnInit(): void {
    this.initiateForm()
  }
initiateForm()
{
  this.rejectionDetails = new FormGroup({
    reasonForRejection: new FormControl(),
  });
  this.userId = this.userDetailsService.getUserDetails().id;

}
  revokeLeave() {
    const reason = this.rejectionDetails.get('reasonForRejection').value;
    this.leaveApplicationService
      .revokeLeaveApplicationbyID(this.data.leaveDetail.id)
      .subscribe(
        (res) => {
          this.closeDialog('success');
        },
        () => {
          this.closeDialog('error');
        }
      );
  }

  closeDialog(event: string) {
    this.dialogRef.close({ event });
  }
}
