<div class="container-fluid" id="content">
  <div class="row">
    <div
      class="col-md-12 add-emo-container"
      *ngIf="
        (userRoles.includes('ROLE_SUPER_ADMIN') &&
          this.matTabsClickedIndex == 0) ||
        (!userRoles.includes('ROLE_SUPER_ADMIN') &&
          this.matTabsClickedIndex == 0 &&
            userRoles.includes('ROLE_PROJECT_ADMIN'))
      "
    ></div>
    <div
      class="col-md-12 add-emo-container"
      *ngIf="
        (userRoles.includes('ROLE_SUPER_ADMIN') &&
          this.matTabsClickedIndex == 1) ||
        (!userRoles.includes('ROLE_SUPER_ADMIN') &&
          userRoles.includes('ROLE_ASSET_ADMIN') &&
            ((this.matTabsClickedIndex == 0 &&
              !userRoles.includes('ROLE_PROJECT_ADMIN')) ||
              (this.matTabsClickedIndex == 1 &&
                userRoles.includes('ROLE_PROJECT_ADMIN'))))
      "
    >
      <button class="add_Emp_btn" (click)="addAssetDialog()">
        <span class="add_em_name">Add New Asset</span>
      </button>
    </div>
    <div
      class="col-md-12 add-emo-container"
      *ngIf="
        (userRoles.includes('ROLE_SUPER_ADMIN') &&
          this.matTabsClickedIndex == 2) ||
        (!userRoles.includes('ROLE_SUPER_ADMIN') &&
          userRoles.includes('ROLE_CLIENT_ADMIN') &&
            ((this.matTabsClickedIndex == 0 &&
              !userRoles.includes('ROLE_PROJECT_ADMIN') &&
              !userRoles.includes('ROLE_ASSET_ADMIN')) ||
              (this.matTabsClickedIndex == 1 &&
                !userRoles.includes('ROLE_PROJECT_ADMIN') &&
                userRoles.includes('ROLE_ASSET_ADMIN')) ||
              (this.matTabsClickedIndex == 1 &&
                userRoles.includes('ROLE_PROJECT_ADMIN') &&
                !userRoles.includes('ROLE_ASSET_ADMIN')) ||
              (this.matTabsClickedIndex == 2 &&
                userRoles.includes('ROLE_PROJECT_ADMIN') &&
                userRoles.includes('ROLE_ASSET_ADMIN'))))
      "
    ></div>
    <div class="col-md-12">
      <mat-tab-group class="tab-group" [selectedIndex]="getTabIndex()">
        <mat-tab
          label="Projects"
          (click)="setActiveTab('projects')"
          class="mat-tab-label-active"
          *ngIf="
            userRoles.includes('ROLE_PROJECT_ADMIN') ||
            userRoles.includes('ROLE_SUPER_ADMIN')
          "
        >
          <ng-template mat-tab-label>
            <mat-icon class="projects">assignment</mat-icon>
            Projects
          </ng-template>
          <div class>
            <app-project-card [projects]="projectList"> </app-project-card>
          </div>
        </mat-tab>
        <mat-tab
          label="Assets"
          (click)="setActiveTab('assets')"
          class="mat-tab-label-active"
          *ngIf="
            userRoles.includes('ROLE_ASSET_ADMIN') ||
            userRoles.includes('ROLE_SUPER_ADMIN')
          "
        >
          <ng-template mat-tab-label class="tab-2">
            <mat-icon class="asset"> devices</mat-icon>

            Assets
          </ng-template>
          <div class="mat-background">
            <app-asset-card></app-asset-card>
          </div>
        </mat-tab>
        <mat-tab
          label="Clients"
          (click)="setActiveTab('clients')"
          class="mat-tab-label-active"
          *ngIf="
            userRoles.includes('ROLE_CLIENT_ADMIN') ||
            userRoles.includes('ROLE_SUPER_ADMIN')
          "
        >
          <ng-template mat-tab-label class="tab-3">
            <mat-icon class="client">person</mat-icon>
            Clients
          </ng-template>
          <div class="mat-background">
            <app-client-details></app-client-details>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
