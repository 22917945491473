<div class="main_header_div">
  <div class=head>{{leaveType}}</div>
    <img class="close-icon-svg" (click)="closeDialog()" src="assets/icons/dashboard-icons/close-pink-icon.svg" />
 
</div>
<mat-panel-description class="description"> {{warning}} </mat-panel-description>
<div class="submit_button_main">
  <button class="ok-btn" (click)="okDialog()">
    <span>Ok</span>
  </button>
</div>
