<div class="row contentRow">
  <div class="col contentCol">
    <div class="row p-2" *ngFor="let feedback of feedbacksList">
      <div class="container-fluid cardContent">
        <div class="row">
          <div class="col-1 col-sm-1 justify-content-center">
            <img
              class="img-style"
              [src]="
                this.initialAvatarService.getInitialImage(
                  feedback.userReviewTo.firstName,
                  feedback.userReviewTo.lastName
                )
              "
            />
          </div>
          <div class="col-3">
            <div class="alignContent"></div>
            <p class="h3">{{ processName(feedback.userReviewTo) }}</p>
            <p class="h5">
              <small class="text-muted">{{
                processDesignation(feedback.userReviewTo)
              }}</small>
            </p>
          </div>

          <div
            class="col-6 col-sm-6 text alignContent justify-content-md-center"
          >
            <p>
              Status :
              <span [ngClass]="statusColor"
                >{{ feedback.feedbackSubmitStatus }}
              </span>
            </p>
            <p>Form Type : {{ feedback.formTemplateType }}</p>
            <p>Generated : 2 Days ago</p>
          </div>
          <div class="col-2 alignContent text-center">
            <button mat-flat-button (click)="openFormTemplateDialog(feedback)">
              <mat-icon
                class="alignExpand"
                aria-hidden="false"
                aria-label="Example home icon"
                >launch</mat-icon
              >
            </button>
            <p *ngIf="!disableEdit" class="alignExpandText">Submit Feedback</p>
            <p *ngIf="disableEdit" class="alignExpandText">View Feedback</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
