import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { VisaService } from 'src/app/services/visa/visa.service';
import { Visa } from 'src/app/models/visa';
import { ToastrService } from 'ngx-toastr';
import { Countries } from 'src/app/services/country/country-enum';
import { EventTabClosed } from 'src/app/services/user/user-enum';
@Component({
  selector: 'app-visa-details',
  templateUrl: './visa-details.component.html',
  styleUrls: ['./visa-details.component.scss'],
})
export class VisaDetailsComponent implements OnInit {
  editBusinesstravel: FormGroup;
  visas: FormArray;
  userDto = new UserDTO();
  removedVisa: String[] = [];
  countries = [];
  noOnlySpecialCharactersValidator(): ValidatorFn {
    const onlySpecialCharsRegex = /^[!#$%^&*(),.<>?":@?\{}|<_ /]+$/;
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value as string;
      if (onlySpecialCharsRegex.test(value)) {
        return { noOnlySpecialCharacters: true };
      }
      return null;
    };
  }
  constructor(
    private fb: FormBuilder,
    private visaservice: VisaService,
    private toaster: ToastrService,
    private dialogRef: MatDialogRef<VisaDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
  }
  ngOnInit(): void {
    this.getExistingVisaDetails();
  }
  getExistingVisaDetails() {
    this.editBusinesstravel = this.fb.group({
      visa: this.fb.array([], [Validators.required]),
    });
    this.countries = Object.values(Countries);
    this.visaservice.getvisaByUserIdSubscribe(this.data).subscribe((res) => {
      if (res.body[0] == null) {
        this.addVisa();
      }
      this.setVisaFormArray(res.body);
    });
  }
  closeDialog(event: Event) {
    this.dialogRef.close({ event: EventTabClosed.Closed });
  }
  createvisa() {
    const formGroup = this.fb.group(new Visa());
    formGroup.controls.country.setValidators([Validators.required]);
    formGroup.controls.visaType.setValidators([
      Validators.required,
      Validators.pattern('^(?![0-9]+$)[A-Za-z0-9\\s-]{3,20}$')]);
      formGroup.controls.validFrom.setValidators([
      Validators.required,
      this.noOnlySpecialCharactersValidator(),
    ]);
    formGroup.controls.validTo.setValidators([Validators.required]);
    formGroup.controls.active;
    formGroup.controls.additionalInformation.setValidators([this.noOnlySpecialCharactersValidator()]);
    return formGroup;
  }
  processDate(visa): string {
    const validTo = visa.controls.validTo.value;
    return visa.controls.validTo.valid && new Date() >= validTo ? 'Inactive' : 'Active';
  }
  setVisaFormArray(response) {
    this.visas = this.editBusinesstravel.get('visa') as FormArray;
    const visaList: Visa[] = this.visaservice.setVisaResponse(response);
    for (const s of visaList) {
      s.country = Countries[s.country];
      const formGroup: FormGroup = this.fb.group(s);
      formGroup.controls.country.setValidators([Validators.required]);
      formGroup.controls.visaType.setValidators([
        Validators.required,
        Validators.pattern('^(?![0-9]+$)[A-Za-z0-9\\s-]{3,20}$')]);
      formGroup.controls.validFrom.setValidators([
        Validators.required,
        this.noOnlySpecialCharactersValidator(),
      ]);
      formGroup.controls.validTo.setValidators([Validators.required]);
      formGroup.controls.active.setValue(this.processDate(formGroup));
      formGroup.controls.additionalInformation;
      this.visas.push(formGroup);
    }
  }
  addEvent(type: string, visa: FormGroup) {
    visa.controls.active.setValue(this.processDate(visa));
  }
  Visa(): FormArray {
    return this.editBusinesstravel.get('visa') as FormArray;
  }
  addVisa() {
    this.visas = this.editBusinesstravel.get('visa') as FormArray;
    this.visas.insert(0, this.createvisa());
  }
  removevisa(id, i: number) {
    this.removedVisa.push(id);
    this.Visa().removeAt(i);
  }
  getEnumKeyByEnumValue(myEnum, enumValue) {
    const keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }
  onSubmit() {
    let isPopUp = false;
    if (this.editBusinesstravel.valid) {
      for (const visas of this.editBusinesstravel.value.visa) {
        visas.country = this.getEnumKeyByEnumValue(Countries, visas.country);
        if (
          visas &&
          !visas.id &&
          visas.country != '' &&
          visas.visaType != '' &&
          visas.validFrom != undefined &&
          visas.validTo != undefined
        ) {
          visas.userId = this.data;
          this.visaservice.create(visas).subscribe((res) => {
            if (!isPopUp) {
              this.toaster.success('Successfully Saved');
              isPopUp = true;
            }
            this.dialogRef.close({ event: EventTabClosed.Closed });
          });
        } else if (
          visas &&
          visas.id &&
          visas.country != '' &&
          visas.visaType != '' &&
          visas.validFrom != undefined &&
          visas.validTo != undefined
        ) {
          visas.userId = this.data;
          this.visaservice.update(visas).subscribe((res) => {
            if (!isPopUp) {
              this.toaster.success('Successfully Saved');
              isPopUp = true;
            }
            this.dialogRef.close({ event: EventTabClosed.Closed });
          });
        }
      }
    }
    for (const i in this.removedVisa) {
      this.visaservice.delete(this.removedVisa[i]);
    }
  }
}
