import { UserDTO } from '../dtos/UserDTO';
interface IPositionDetails
{
id?:string;
positionStartDate?:Date;
positionEndDate?:Date;
capabilityId?:string;
titleId?:string;
geoLocationId?:string;
baseLocation?:string;
userId?:string;

}
export class PositionDetails{
    id:string;
    positionStartDate:Date;
    positionEndDate:Date;
    capability:string;
    jobTitle:string;
    geoLocation:string;
    baseLocation:string;
    userId:string;
    
    constructor() {
        this.id = undefined
        this.userId = undefined
        this.capability = ""
        this.positionEndDate = undefined
        this.positionStartDate = undefined
        this.jobTitle="",
        this.geoLocation=""
        this.baseLocation=""

    }
    bind(httpResponse: any) {
        this.id = httpResponse && httpResponse.id || undefined
        this.positionStartDate = httpResponse && new Date(httpResponse.startDate) || undefined
        this.positionEndDate = httpResponse && new Date(httpResponse.endDate) || undefined
        this.jobTitle = httpResponse && httpResponse.jobTitle || undefined
        this.capability= httpResponse && httpResponse.jobTitle || undefined
        this.userId = httpResponse && httpResponse.userId || undefined
        this.geoLocation = httpResponse && httpResponse.geoLocation || undefined
        this.baseLocation = httpResponse && httpResponse.baseLocation || undefined
        return this;
    }
}