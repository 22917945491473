<mat-card class="main-card">
  <div class="row">
    <div class="col-md-12">
      <div class="main-header">
        <div class="svg-heading-div">
          <img class="submodule-svg" src="assets/icons/dashboard-icons/reportee-leave-icon.svg" />
          <div class="head">Team members on leave today</div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <!--  reportees on leave with empty data -->
      <div class="tableFixHead-empty" *ngIf="!monthReporteeLeaveData || monthReporteeLeaveData.length == 0">
        <div class="headingOne">
          <div class="no-data-image">
            <img src="assets/icons/dashboard-icons/white-reportee-leave-icon.svg" />
          </div>
          <div class="no-data-text">No team members on leave today</div>
        </div>
      </div>

      <!--  reportees on leave with data -->
      <div class="tableFixHead" *ngIf="monthReporteeLeaveData && monthReporteeLeaveData.length != 0">
        <div *ngFor="let reportee of monthReporteeLeaveData | orderBy : 'date'">
          <div class="single-reportee-detail-container">
            <div class="img-container-div">
              <img class="noemployee_img" src="assets/icons/default-avatar.jpg" alt="" />
              <div class="name-container">
                {{ reportee.name | titlecase }}
                <div>{{ reportee.id }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</mat-card>