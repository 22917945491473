<div>
  <div class="row">
    <button
      class="custom-button-1"
      (click)="onTabClick('APPLY')"
      [ngClass]="{ 'active-tab': activeTab === 'APPLY' }"
    >
      Apply
    </button>
    <button
      class="custom-button-1"
      (click)="onTabClick('PENDING')"
      [ngClass]="{ 'active-tab': activeTab === 'PENDING' }"
    >
      Pending
    </button>
    <button
      class="custom-button-1"
      (click)="onTabClick('HISTORY')"
      [ngClass]="{ 'active-tab': activeTab === 'HISTORY' }"
    >
      History
    </button>
  </div>
  <div *ngIf="activeTab == 'APPLY'">
    <app-attendance-details></app-attendance-details>
  </div>
  <div *ngIf="activeTab == 'PENDING'">
    <app-pending-regulariztion-details></app-pending-regulariztion-details>
  </div>

  <div *ngIf="activeTab == 'HISTORY'">
   <app-approved-regularizations></app-approved-regularizations>
  </div>
</div>
