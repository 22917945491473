import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PolicyConfigurationURLsService {
  private readonly createPolicyURL = '/api/create-master-leave';
  private readonly getAllPolicyURL = '/api/get-all-master-leave';
  private readonly deletePolicyURL = '/api/delete-master_leave';

  constructor() { }

  createPolicyUrl(){
    return this.createPolicyURL;
  }

  getAllPolicyUrl(){
    return this.getAllPolicyURL;
  }

  deletePolicyUrl(id){
    return this.deletePolicyURL + '/' + id;
  }
}
