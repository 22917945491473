import { Component, OnInit, Inject } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
  AbstractControl,
  FormControl,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssetDTO } from 'src/app/dtos/AssetDTO';
import { AssetManagementService } from 'src/app/services/asset-management/asset-management.service';
import {
  AssetLocations,
  AssetCategories,
  AssetCondition,
  AssetStatus,
} from 'src/app/services/asset-management/asset-enum';
import { UserService } from 'src/app/services/user/user.service';
import { AssetMainComponent } from '../../asset-main/asset-main.component';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { Router } from '@angular/router';
import { AssetCardComponent } from '../../asset-card/asset-card/asset-card.component';

@Component({
  selector: 'app-add-asset',
  templateUrl: './add-asset.component.html',
  styleUrls: ['./add-asset.component.scss'],
})
export class AddAssetComponent implements OnInit {
  newAssetDetails: FormGroup;
  assetDto = new AssetDTO();
  allLocationList = [];
  allAssetCategoriesList = [];
  allAssetConditionsList = [];
  allAssetStatusesList = [];
  statusNotAvailable: boolean;
  statusIssued: boolean;
  statusAvailable: boolean;
  assignAssetchecked: boolean = false;
  allEmployeeIdsWithNames = {};
  empIds: any;
  allAssetIds = [];
  emailError: any;
  inEditMode = false;
  minAssignDate: Date;
  relevantDoc: boolean;
  relevantDocError: any;
  binaryDocString: any;
  base64RelevantDocString: any;
  relevantDocName: any;
  assetImage: Array<boolean> = new Array(5).fill(false);
  assetImageError: Array<string> = new Array(5).fill('');
  binaryAssetImageString: Array<string> = new Array(5).fill('');
  base64AssetImageString: Array<string> = new Array(5).fill('');
  updatedResult;
  showUpdating: boolean;
  showCreatingAsset: boolean;

  ngOnInit(): void {
    this.setFormData();
    this.setNewMinAssignDate();
    this.processEnumValuesToArray();
    this.assetService.getAllAssetIDs().subscribe((data) => {
      this.allAssetIds = data.body;
    });
  }
  noOnlySpecialCharactersValidator(): ValidatorFn {
    const onlySpecialCharsRegex = /^[!#$%^&*(),.<>?":@?\{}|<_ /]+$/;
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value as string;

      if (onlySpecialCharsRegex.test(value)) {
        return { noOnlySpecialCharacters: true };
      }

      return null;
    };
  }

  dateFieldValidator(): ValidatorFn {
    const dateFieldRegex = /^[0-9/]+$/;

    return (control: FormControl): { [key: string]: any } | null => {
      const value = control.value as string;

      if (!value) {
        // If the field is empty, it should show the "Required" message.
        return { required: true };
      }

      if (dateFieldRegex.test(value)) {
        // Validation passed, return null (no errors)
        return null;
      }

      // Validation failed, return the error object
      return { invalidDateField: true };
    };
  }

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private assetService: AssetManagementService,
    private userService: UserService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<AssetMainComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.newAssetDetails = this.fb.group({
      assetID: [
        '',
        [Validators.required, this.noOnlySpecialCharactersValidator()],
      ],
      assetName: [
        '',
        [Validators.required, this.noOnlySpecialCharactersValidator()],
      ],
      assetDescription: [
        '',
        [Validators.required, this.noOnlySpecialCharactersValidator()],
      ],
      assetLocation: [
        '',
        [Validators.required, this.noOnlySpecialCharactersValidator()],
      ],
      assetCategory: [
        '',
        [Validators.required, this.noOnlySpecialCharactersValidator()],
      ],
      purchasedOn: ['', [Validators.required]],
      assetCondition: ['', [Validators.required]],
      assetStatus: [''],
      unavailabilityReason: [''],
      employeeId: [''],
      employeeName: [''],
      assetAssignedDate: [''],
      assetPrimaryImage: [''],
      assetSecondaryImage1: [''],
      assetSecondaryImage2: [''],
      assetSecondaryImage3: [''],
      assetSecondaryImage4: [''],
    });
  }

  setNewMinAssignDate() {
    this.minAssignDate = new Date(
      this.newAssetDetails.get('purchasedOn').value
    );
  }

  processEnumValuesToArray() {
    for (let assetLocation of Object.values(AssetLocations)) {
      if (isNaN(Number(assetLocation)))
        this.allLocationList.push(assetLocation);
    }
    for (let assetCategory of Object.values(AssetCategories)) {
      if (isNaN(Number(assetCategory)))
        this.allAssetCategoriesList.push(assetCategory);
    }

    for (let assetCondition of Object.values(AssetCondition)) {
      if (isNaN(Number(assetCondition)))
        this.allAssetConditionsList.push(assetCondition);
    }

    for (let assetStatus of Object.values(AssetStatus)) {
      if (isNaN(Number(assetStatus)))
        this.allAssetStatusesList.push(assetStatus);
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Closed' });
  }

  setFormData() {
    if (this.data) {
      this.inEditMode = true;
      this.newAssetDetails
        .get('assetID')
        .setValue(this.data.assetID ? this.data.assetID : '');

      this.newAssetDetails
        .get('assetName')
        .setValue(this.data.assetName ? this.data.assetName : '');

      this.newAssetDetails
        .get('assetDescription')
        .setValue(this.data.assetDescription ? this.data.assetDescription : '');

      var assetLocation = this.getEnumValueByEnumKey(
        AssetLocations,
        this.data.assetLocation
      );
      this.newAssetDetails
        .get('assetLocation')
        .setValue(this.data.assetLocation ? assetLocation : '');

      var assetCategory = this.getEnumValueByEnumKey(
        AssetCategories,
        this.data.assetCategory
      );
      this.newAssetDetails
        .get('assetCategory')
        .setValue(this.data.assetCategory ? assetCategory : '');

      this.newAssetDetails
        .get('purchasedOn')
        .setValue(this.data.purchasedOn ? new Date(this.data.purchasedOn) : '');

      var assetCondition = this.getEnumValueByEnumKey(
        AssetCondition,
        this.data.assetCondition
      );
      this.newAssetDetails
        .get('assetCondition')
        .setValue(this.data.assetCondition ? assetCondition : '');

      var assetStatus = this.getEnumValueByEnumKey(
        AssetStatus,
        this.data.assetStatus
      );
      this.newAssetDetails
        .get('assetStatus')
        .setValue(this.data.assetStatus ? assetStatus : '');

      this.newAssetDetails
        .get('unavailabilityReason')
        .setValue(
          this.data.unavailabilityReason ? this.data.unavailabilityReason : ''
        );

      this.newAssetDetails
        .get('employeeId')
        .setValue(this.data.employeeId ? this.data.employeeId : '');

      this.newAssetDetails
        .get('employeeName')
        .setValue(this.data.employeeName ? this.data.employeeName : '');

      this.newAssetDetails
        .get('assetAssignedDate')
        .setValue(
          this.data.assetAssignedDate
            ? new Date(this.data.assetAssignedDate)
            : ''
        );

      if (this.data.assetPrimaryThumbnailViewScreen != '') {
        this.binaryAssetImageString[0] =
          'data:image/png;base64,' + this.data.assetPrimaryThumbnailViewScreen;
        this.assetImage[0] = true;
      }
      if (this.data.assetSecondaryThumbnail1 != '') {
        this.binaryAssetImageString[1] =
          'data:image/png;base64,' + this.data.assetSecondaryThumbnail1;
        this.assetImage[1] = true;
      }
      if (this.data.assetSecondaryThumbnail2 != '') {
        this.binaryAssetImageString[2] =
          'data:image/png;base64,' + this.data.assetSecondaryThumbnail2;
        this.assetImage[2] = true;
      }
      if (this.data.assetSecondaryThumbnail3 != '') {
        this.binaryAssetImageString[3] =
          'data:image/png;base64,' + this.data.assetSecondaryThumbnail3;
        this.assetImage[3] = true;
      }
      if (this.data.assetSecondaryThumbnail4 != '') {
        this.binaryAssetImageString[4] =
          'data:image/png;base64,' + this.data.assetSecondaryThumbnail4;
        this.assetImage[4] = true;
      }
    }
  }

  getEnumValueByEnumKey(myEnum, enumKey: string) {
    return myEnum[enumKey];
  }

  checkError = (controlName: string, errorName: string) => {
    return this.newAssetDetails.controls[controlName].hasError(errorName);
  };

  onEmployeeIdTextTyped(event) {
    this.userService.validateEmployeeId(event).subscribe(
      (res) => {},
      (error) => {
        if (error.error.errorKey == 'employeeIdExist') {
          this.setFormControlError(
            'employeeId',
            'InvalidEmployeeId',
            'Employee Id Already Exist'
          );
        } else {
          this.setFormControlError(
            'employeeId',
            'InvalidEmployeeId',
            'required'
          );
        }
      }
    );
  }

  nullifyFields() {
    this.newAssetDetails.get('employeeId').setValue('');
    this.newAssetDetails.get('employeeName').setValue('');
    this.newAssetDetails.get('assetAssignedDate').setValue('');
    this.newAssetDetails.get('unavailabilityReason').setValue('');
  }

  changeStatus(value) {
    this.assignAssetchecked = false;
    this.newAssetDetails.controls['employeeId'].clearValidators();
    this.newAssetDetails.controls['employeeId'].updateValueAndValidity();
    this.newAssetDetails.controls['assetAssignedDate'].clearValidators();
    this.newAssetDetails.controls['assetAssignedDate'].updateValueAndValidity();
    this.nullifyFields();

    if (value == 'Not Available') {
      this.statusNotAvailable = true;
      this.statusIssued = false;
      this.statusAvailable = false;
    }

    if (value == 'Issued') {
      this.statusIssued = true;
      this.statusNotAvailable = false;
      this.statusAvailable = false;
    }

    if (value == 'Available') {
      this.statusAvailable = true;
      this.statusNotAvailable = false;
      this.statusIssued = false;
    }
  }

  changeAssignAssetChecked() {
    this.assignAssetchecked = !this.assignAssetchecked;
    if (this.assignAssetchecked == false) {
      this.newAssetDetails.controls['employeeId'].clearValidators();
      this.newAssetDetails.controls['employeeId'].updateValueAndValidity();
      this.newAssetDetails.controls['assetAssignedDate'].clearValidators();
      this.newAssetDetails.controls[
        'assetAssignedDate'
      ].updateValueAndValidity();
      this.nullifyFields();
    } else {
      this.newAssetDetails.controls['employeeId'].setValidators(
        Validators.required
      );
      this.newAssetDetails.controls['employeeId'].updateValueAndValidity();
      this.newAssetDetails.controls['assetAssignedDate'].setValidators(
        Validators.required
      );
      this.newAssetDetails.controls[
        'assetAssignedDate'
      ].updateValueAndValidity();
    }
  }

  populateEmployeeName(id) {
    let employeeName = this.allEmployeeIdsWithNames[id];
    this.newAssetDetails.get('employeeName').setValue(employeeName);
  }

  checkIfAssetAlreadyExists() {
    if (this.allAssetIds.includes(this.newAssetDetails.get('assetID').value)) {
      return true;
    } else {
      return false;
    }
  }

  setFormControlError(
    controlName: string,
    controlError: string,
    errorMsg: string
  ) {
    this.newAssetDetails.get(controlName).markAsTouched({ onlySelf: true });
    this.newAssetDetails.get(controlName).markAsDirty({ onlySelf: true });
    this.newAssetDetails.get(controlName).setErrors({ [controlError]: true });
    this.emailError = errorMsg;
  }

  onAssetIdTextTyped(event) {
    if (this.allAssetIds.includes(this.newAssetDetails.get('assetID').value)) {
      this.setFormControlError(
        'assetID',
        'InvalidAssetId',
        'Asset Id Already Exist'
      );
    }
    if (this.newAssetDetails.get('assetID').value.length == 0) {
      this.setFormControlError('assetID', 'InvalidAssetId', 'required');
    }
  }

  onSubmit() {
    if (this.newAssetDetails.valid) {
      if (this.inEditMode) {
        this.assetDto.id = this.data.id;
      }

      if (this.inEditMode) {
        this.showUpdating = true;
        this.newAssetDetails.patchValue({
          assetPrimaryImage: this.binaryAssetImageString[0].split(',')[1],
          assetSecondaryImage1: this.binaryAssetImageString[1].split(',')[1],
          assetSecondaryImage2: this.binaryAssetImageString[2].split(',')[1],
          assetSecondaryImage3: this.binaryAssetImageString[3].split(',')[1],
          assetSecondaryImage4: this.binaryAssetImageString[4].split(',')[1],
        });

        if (this.assetImage[0]) {
          if (
            this.newAssetDetails.get('assetPrimaryImage').value ==
            this.data.assetPrimaryThumbnailViewScreen
          ) {
            this.assetDto.assetPrimaryImage = 'Unchanged';
          } else {
            this.assetDto.assetPrimaryImage =
              this.newAssetDetails.get('assetPrimaryImage').value;
          }
        } else {
          this.assetDto.assetPrimaryImage = '';
        }

        if (this.assetImage[1]) {
          if (
            this.newAssetDetails.get('assetSecondaryImage1').value ==
            this.data.assetSecondaryThumbnail1
          ) {
            this.assetDto.assetSecondaryImage1 = 'Unchanged';
          } else {
            this.assetDto.assetSecondaryImage1 = this.newAssetDetails.get(
              'assetSecondaryImage1'
            ).value;
          }
        } else {
          this.assetDto.assetSecondaryImage1 = '';
        }

        if (this.assetImage[2]) {
          if (
            this.newAssetDetails.get('assetSecondaryImage2').value ==
            this.data.assetSecondaryThumbnail2
          ) {
            this.assetDto.assetSecondaryImage2 = 'Unchanged';
          } else {
            this.assetDto.assetSecondaryImage2 = this.newAssetDetails.get(
              'assetSecondaryImage2'
            ).value;
          }
        } else {
          this.assetDto.assetSecondaryImage2 = '';
        }

        if (this.assetImage[3]) {
          if (
            this.newAssetDetails.get('assetSecondaryImage3').value ==
            this.data.assetSecondaryThumbnail3
          ) {
            this.assetDto.assetSecondaryImage3 = 'Unchanged';
          } else {
            this.assetDto.assetSecondaryImage3 = this.newAssetDetails.get(
              'assetSecondaryImage3'
            ).value;
          }
        } else {
          this.assetDto.assetSecondaryImage3 = '';
        }

        if (this.assetImage[4]) {
          if (
            this.newAssetDetails.get('assetSecondaryImage4').value ==
            this.data.assetSecondaryThumbnail4
          ) {
            this.assetDto.assetSecondaryImage4 = 'Unchanged';
          } else {
            this.assetDto.assetSecondaryImage4 = this.newAssetDetails.get(
              'assetSecondaryImage4'
            ).value;
          }
        } else {
          this.assetDto.assetSecondaryImage4 = '';
        }
      } else {
        this.showCreatingAsset = true;
      }

      this.assetDto.assetID = this.newAssetDetails.get('assetID').value;
      this.assetDto.assetName = this.newAssetDetails.get('assetName').value;
      this.assetDto.assetDescription =
        this.newAssetDetails.get('assetDescription').value;
      this.assetDto.assetLocation = this.getEnumKeyByEnumValue(
        AssetLocations,
        this.newAssetDetails.get('assetLocation').value
      );
      this.assetDto.assetCategory = this.getEnumKeyByEnumValue(
        AssetCategories,
        this.newAssetDetails.get('assetCategory').value
      );
      this.assetDto.purchasedOn = this.newAssetDetails.get('purchasedOn').value;
      this.assetDto.assetCondition = this.getEnumKeyByEnumValue(
        AssetCondition,
        this.newAssetDetails.get('assetCondition').value
      );

      if (!this.inEditMode) {
        if (this.assetImage[0]) {
          this.assetDto.assetPrimaryImage =
            this.newAssetDetails.get('assetPrimaryImage').value;
        }
        if (this.assetImage[1]) {
          this.assetDto.assetSecondaryImage1 = this.newAssetDetails.get(
            'assetSecondaryImage1'
          ).value;
        }
        if (this.assetImage[2]) {
          this.assetDto.assetSecondaryImage2 = this.newAssetDetails.get(
            'assetSecondaryImage2'
          ).value;
        }
        if (this.assetImage[3]) {
          this.assetDto.assetSecondaryImage3 = this.newAssetDetails.get(
            'assetSecondaryImage3'
          ).value;
        }
        if (this.assetImage[4]) {
          this.assetDto.assetSecondaryImage4 = this.newAssetDetails.get(
            'assetSecondaryImage4'
          ).value;
        }
      }

      if (this.assignAssetchecked) {
        this.newAssetDetails.get('assetStatus').setValue('Issued');
        this.assetDto.assetStatus = this.getEnumKeyByEnumValue(
          AssetStatus,
          this.newAssetDetails.get('assetStatus').value
        );
      } else {
        this.assetDto.assetStatus = this.getEnumKeyByEnumValue(
          AssetStatus,
          this.newAssetDetails.get('assetStatus').value
        );
      }
      this.assetDto.unavailabilityReason = this.newAssetDetails.get(
        'unavailabilityReason'
      ).value;
      this.assetDto.employeeId = this.newAssetDetails.get('employeeId').value;
      this.assetDto.assetAssignedDate =
        this.newAssetDetails.get('assetAssignedDate').value;

      if (this.inEditMode) {
        this.assetService.updateAsset(this.assetDto).subscribe((res) => {
          this.updatedResult = res.body;
          this.showUpdating = false;
          this.notificationService.sucess('Asset Updated');
          this.dialogRef.close({ event: 'Updated', data: this.updatedResult });
        });
      } else {
        this.assetService.addAsset(this.assetDto).subscribe(
          (res) => {
            this.showCreatingAsset = false;
            this.notificationService.sucess('New Asset Created');
            this.dialogRef.close({ event: 'Created' });
          },
          (error) => {
            if (error.status == 403) {
              this.closeDialog();
              this.router.navigate(['/unauthorized']);
            }
          }
        );
      }
    } else {
      this.notificationService.invalidFormData();
    }
  }

  getEnumKeyByEnumValue(myEnum, enumValue) {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }

  readRelevantDoc(event: Event): void {
    this.relevantDoc = false;
    this.relevantDocError = '';
    if (event && event[0]) {
      const file = event[0];
      const reader = new FileReader();
      const max_size = 1000000;
      const max_height = 15200;
      const max_width = 25600;

      if (event[0].size > max_size) {
        this.relevantDocError =
          'Maximum size allowed is ' + max_size / 1000 + 'MB';
      }
      reader.onload = (e) => {
        this.relevantDoc = true;
        this.binaryDocString = e.target.result as string;
        this.base64RelevantDocString = btoa(this.binaryDocString);
        this.relevantDocName = event[0].name;
      };
      reader.readAsDataURL(file);
    }
  }

  removeRelevantDoc() {
    this.relevantDoc = false;
  }

  readImage(event: Event, imageNumber: any) {
    this.assetImage[imageNumber] = false;
    this.assetImageError[imageNumber] = '';
    if (event && event[0]) {
      const file = event[0];
      const reader = new FileReader();
      const max_size = 250000;
      const max_height = 15200;
      const max_width = 25600;

      if (event[0].size > max_size) {
        this.assetImageError[imageNumber] =
          'Maximum allowed size is ' + max_size / 1000 + 'KB';
        return;
      }
      reader.onload = (e) => {
        this.assetImage[imageNumber] = true;
        this.binaryAssetImageString[imageNumber] = e.target.result as string;
        this.base64AssetImageString[imageNumber] = btoa(
          this.binaryAssetImageString[imageNumber]
        );
        this.newAssetDetails.patchValue({
          assetPrimaryImage: this.binaryAssetImageString[0].split(',')[1],
          assetSecondaryImage1: this.binaryAssetImageString[1].split(',')[1],
          assetSecondaryImage2: this.binaryAssetImageString[2].split(',')[1],
          assetSecondaryImage3: this.binaryAssetImageString[3].split(',')[1],
          assetSecondaryImage4: this.binaryAssetImageString[4].split(',')[1],
        });
      };
      reader.readAsDataURL(file);
    }
  }

  removeAssetImage(imageNumber: any) {
    this.assetImage[imageNumber] = false;
  }

  generateAssetImageString(imgString) {
    return 'data:image/jpeg;charset=utf-8;base64, ' + imgString;
  }

  refreshAssetListing() {
    this.ngOnInit();
  }
}
