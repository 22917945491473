interface IClient {
  id?: string;
  clientId?: string;
  clientLocation?: string;
  clientName?: string;
  clientDescription?: string;
  createdDate?: Date;
  imageBase?: string;
  clientImageThumbnail?:string
}

export class Client {
  id: string;
  clientId: string;
  clientLocation: string;
  clientName: string;
  clientDescription: string;
  createdDate: Date;
  imageBase: string;
  clientImageThumbnail:string
  constructor(client: IClient) {
    if (client && client.id) {
      this.id = client.id;
    }
    this.clientLocation = (client && client.clientLocation) || null;
    this.clientId = (client && client.clientId) || null;
    this.clientName = (client && client.clientName) || null;
    this.clientDescription = (client && client.clientDescription) || null;
    this.createdDate = (client && client.createdDate) || new Date();
    this.imageBase = (client && client.imageBase) || null;
    this.clientImageThumbnail = (client && client.clientImageThumbnail) || null;
  }
}
