import { FormSchemaDTO } from "./FormSchemaDTO";
import { UserDTO } from "./UserDTO";

export class ProjectDTO {
    public id: string;
    public projectName: string;
    public projectDescription: string;
    public projectCategory:string;
    public projectType:string;
    public startDate: Date;
    public endDate: Date;
    public managerId: string;
    public formSchemas: FormSchemaDTO[];
    public clientId: string;
   
    public projectCode:string;
    public department:string;
    public userProjects:string[];
    public skill:string[];
    constructor() {
        this.id = null;
        this.projectName = "";
        this.projectDescription = "";
        this.startDate = new Date();
        this.endDate = new Date();
         this.managerId = "";
        this.formSchemas = [];
        this.clientId = "";
        this.projectCode = "";
        this.department = "";
        this.userProjects=[];
        this.skill=[];
    }
}