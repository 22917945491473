<div>
  <div class="row">
    <div class="col-md-12 pl-0">
      <mat-card class="mat_card">
        <div class="cal-div">
          <div class="search_main">
            <mat-icon class="search-icon">search</mat-icon>
            <input
              class="search_input"
              type="search"
              [(ngModel)]="searchText"
              placeholder="Search"
            />
          </div>
          <div>
            <input
              class="date"
              (dateChange)="updateDOB($event)"
              [formControl]="date1"
              [matDatepicker]="picker"
              readonly
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
          <button class="cs-download" (click)="file()">
            <img src="assets/icons/download.png" class="download-img-export" />
          </button>
        </div>
      </mat-card>
    </div>
  </div>
  <div class="row mt-3">
    <ng-container *ngIf="this.isVisible == false; else elseTemplate">
    </ng-container>
  </div>
  <ng-template #elseTemplate>
    <div class="col-md-12 demo">
      <div class="table-fixed">
        <table class="table-borderless">
          <thead>
            <tr *ngIf="!userDto.length == 0">
              <th>
                <img
                  src="assets/icons/sort.png"
                  class="hover-cursor"
                  [class.active]="order === 'id'"
                  (click)="reverseOrder()"
                />
                <span class="ml-2 emp-title">Employee</span>
              </th>
              <th class="emp-title">Title</th>
              <th class="emp-title">Capability</th>
              <th class="emp-title">Career Manager</th>
              <th class="emp-title">Official Email</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="userDto.length == 0">
              <div class="no-leave">No employee is on leave</div>
            </tr>
            <tr
              *ngFor="
                let data of userDto
                  | filter : searchText
                  | orderBy : order : reverse : caseInsensitive
                  | paginate
                    : { id: 'unmarked_id', itemsPerPage: 8, currentPage: p }
              "
            >
              <td>
                <div class="row">
                  <div *ngIf="data.userImageThumbnail === null">
                    <img
                      class="noemployee_img"
                      src="assets/icons/default-avatar.jpg"
                      alt=""
                    />
                  </div>
                  <div *ngIf="data.userImageThumbnail !== null">
                    <img
                      class="employee_img"
                      [(src)]="data.userImageThumbnail"
                      alt=""
                    />
                  </div>
                  <div class="col firstnameblock">
                    <label class="firstAndLastname">{{
                      data.firstName + " " + data.lastName
                    }}</label>
                    <br />
                    <label class="idemp">ID {{ data.employeeId }}</label>
                  </div>
                </div>
              </td>
              <td>
                <label class="user_name"> {{ jobTitles[data.jobTitle] }}</label>
              </td>
              <td>
                <label class="user_role">{{
                  departments[data.department]
                }}</label>
              </td>
              <td>
                <label class="user_id">{{
                  this.careerManagerIdName[data.careerManagerId]
                }}</label>
              </td>
              <td>
                <label class="user_email">{{ data.email }}</label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination-controls
      id="unmarked_id"
      class="pagination"
      (pageChange)="p = $event"
    >
    </pagination-controls>
  </ng-template>
</div>
