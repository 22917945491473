<div class="row">
    <div class="col-md-12 pl-0">
        <mat-card class="mat_card">
            <div class="search_main ">
                <mat-icon class="search-icon  mt-1 mr-4">search</mat-icon>
                <!-- <input class="search_input" type="search" placeholder="Search..."
                    (ngModelChange)="onSearchTextTyped($event)" [ngModel]="searchText" value=""> -->
                <input class="search_input" type="search" placeholder="Search" [(ngModel)]="searchText" value="">

            </div>
            <div class="export-import-container">
                <div class="mt-1 items">
                    <!-- <img src="assets/download.png" class="download-img"> -->
                    <img (click)="redirecttoBulkEmployeeComponent()" src="assets/download_arrow.png" title="Bulk Employee Import"
                        class="export-img">
                </div>
                <div class="mt-1 items">
                    <!-- <img src="assets/download.png" class="download-img"> -->
                    <img (click)="openExportDetailsDilogue()" src="assets/download.png" title="Employee List Export"
                        class="export-img">
                </div>
            </div>
        </mat-card>
    </div>
</div>
<div class="row mt-3">
  <ng-container
    *ngIf="this.userService.displayEmployeeCards == false; else elseTemplate"
  >
    <div class="nothing-data">
      <div class="nodata">
        <img
          *ngIf="this.userService.getEmployeesCount() === 0"
          src="assets/images/no-data-mobile.png"
        />
        <div
          class="main_div mt-5"
          *ngIf="this.userService.getEmployeesCount() !== 0"
        >
          <mat-spinner strokeWidth="7" [diameter]="170"></mat-spinner>
          <label class="loading_label mt-4 mb-5">Loading...</label>
        </div>
      </div>

      <div class="nothing-mobile">
        <img src="assets/images/no-data-mobile.png" />
      </div>
      <div class="btn">
        <button
          *ngIf="this.userService.getEmployeesCount() === 0"
          (click)="redirecttoBulkEmployeeComponent()"
          class="importbulk"
        >
          Import Bulk Employees
        </button>
      </div>
    </div>
  </ng-container>
  <ng-template #elseTemplate>
    <div *ngIf="this.mobileview == false" class="col-md-12">
      <div class="table-fixed">
        <table class="table-borderless">
          <thead>
            <tr>
              <th>
                <img
                  src="assets/icons/sort.png"
                  class="hover-cursor"
                  (click)="reverseOrder()"
                />
                <span class="ml-2 emp-title">Employee</span>
              </th>
              <th class="emp-title">Title</th>
              <th class="emp-title">Capability</th>
              <th class="emp-title">Career Manager</th>
              <th class="emp-title">Official Email</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let empDetail of this.userService.usersDto
                  | filter : searchText
                  | paginate
                    : { id: 'employee_id', itemsPerPage: 8, currentPage: p }
              "
            >
              <td class="NameandId">
                <div class="row">
                  <div *ngIf="empDetail.userImageThumbnail === null">
                    <img
                      class="noemployee_img"
                      src="assets/icons/default-avatar.jpg"
                      alt=""
                    />
                  </div>
                  <div *ngIf="empDetail.userImageThumbnail !== null">
                    <img
                      class="employee_img"
                      [(src)]="empDetail.userImageThumbnail"
                      alt=""
                    />
                  </div>
                  <div class="col firstnameblock">
                    <label class="firstAndLastname"
                      >{{
                        empDetail.firstName + " " + empDetail.lastName
                          | underScoreToSpace
                          | titlecase
                      }}
                    </label>
                    <br />
                    <label class="idemp">ID {{ empDetail.employeeId }}</label>
                  </div>
                </div>
              </td>
              <td>
                <label class="user_name">
                  {{ jobTitles[empDetail.jobTitle] }}</label
                >
              </td>
              <td>
                <label class="user_role">{{
                  departments[empDetail.department]
                }}</label>
              </td>
              <td>
                <label class="user_id">{{
                  this.userService.careerManagerIdName[
                    empDetail.careerManagerId
                  ]
                }}</label>
              </td>
              <td>
                <label class="user_email">{{ empDetail.email }}</label>
              </td>

              <img
                src="assets/icons/3dots.png"
                [matMenuTriggerFor]="beforeMenu"
                class="threedots"
              />

              <button
                class="Connect"
                [matMenuTriggerFor]="beforeMenu"
                *ngIf="this.hasRoleBased"
              >
                Connect
              </button>
              <mat-menu
                class="matmenu"
                #beforeMenu="matMenu"
                xPosition="before"
              >
                <button
                  mat-menu-item
                  class="transform-none"
                  (click)="editEmployeeDialog(empDetail)"
                >
                  <span class="bold">Edit</span>
                </button>
                <button
                  mat-menu-item
                  class="transform-none"
                  *ngIf="empDetail.activated"
                  (click)="deactivateEmployee(empDetail)"
                >
                  <span class="bold">Deactivate</span>
                </button>
                <button
                  mat-menu-item
                  class="transform-none"
                  *ngIf="!empDetail.activated"
                  (click)="activateEmployee(empDetail)"
                >
                  <span class="bold">Activate</span>
                </button>
                <button
                  mat-menu-item
                  class="transform-none"
                  (click)="viewEmployee(empDetail)"
                >
                  <span class="bold">View</span>
                </button>
              </mat-menu>
            </tr>
          </tbody>
        </table>
      </div>

      <pagination-controls
        id="employee_id"
        class="pagination"
        (pageChange)="p = $event"
      ></pagination-controls>
    </div>

    <div *ngIf="this.mobileview == true" class="col-md-12">
      <div class="table-fixed">
        <table class="table-borderless">
          <thead>
            <tr>
              <th>
                <img
                  src="assets/icons/sort.png"
                  class="hover-cursor"
                  (click)="reverseOrder()"
                />
                <span class="ml-2 emp-title">Employee</span>
              </th>
              <th class="emp-title">Title</th>
              <th class="emp-title">Capability</th>
              <th class="emp-title">Career Manager</th>
              <th class="emp-title">Official Email</th>
              <th></th>
            </tr>
          </thead>
          <tbody
            class="table-scroll"
            infiniteScroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50"
            [infiniteScrollContainer]="'.table-scroll'"
            [fromRoot]="true"
            (scrolled)="onScroll()"
          >
            <tr
              *ngFor="
                let empDetail of this.usermobiledata | filter : searchText
              "
            >
              <td class="NameandId">
                <div class="row">
                  <div *ngIf="empDetail.userImageThumbnail === null">
                    <img class="employee_img" src="assets/man.png" alt="" />
                  </div>
                  <div *ngIf="empDetail.userImageThumbnail !== null">
                    <img
                      class="employee_img"
                      [(src)]="empDetail.userImageThumbnail"
                      alt=""
                    />
                  </div>
                  <div class="col firstnameblock">
                    <label class="firstAndLastname">{{
                      empDetail.firstName + " " + empDetail.lastName
                        | underScoreToSpace
                        | titlecase
                    }}</label>
                    <br />
                    <label class="idemp">ID {{ empDetail.employeeId }}</label>
                  </div>
                </div>
              </td>
              <td>
                <label class="user_name">
                  {{
                    empDetail.jobTitle | underScoreToSpace | titlecase
                  }}</label
                >
              </td>
              <td>
                <label class="user_role">{{
                  empDetail.department | underScoreToSpace | titlecase
                }}</label>
              </td>
              <td>
                <label class="user_id">{{
                  this.careerManagerIdName[empDetail.careerManagerId]
                }}</label>
              </td>
              <td>
                <label class="user_email">{{ empDetail.email }}</label>
              </td>
              <img
                src="assets/icons/3dots.png"
                [matMenuTriggerFor]="beforeMenu"
                class="threedots"
              />
              <button class="Connect" [matMenuTriggerFor]="beforeMenu">
                Connect
              </button>
              <mat-menu
                class="matmenu"
                #beforeMenu="matMenu"
                xPosition="before"
              >
                <button mat-menu-item class="first">
                  <span class="connect">Connect</span>
                </button>
                <button mat-menu-item>
                  <span class="bold" (click)="editEmployeeDialog(empDetail)"
                    >Edit</span
                  >
                </button>
                <button mat-menu-item>
                  <span class="bold" (click)="deleteEmployee(empDetail)"
                    >Delete</span
                  >
                </button>
              </mat-menu>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-template>
</div>