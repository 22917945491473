interface IEDUCATION {
    courseName:  string;
    universityName:string;
    yearofCompletion : Date;
    courseType:string;
    userId:String
    id:string
}

export class Education{
    public courseName: String;
    public courseType: string;
    public universityName: String;
    public  yearofCompletion: Date
    public  yearOfBeginning: Date

    public userId :string
    public id:string

    constructor(){
        this.courseName = ''
        this.courseType=''
        this.universityName=''
        this.id = undefined
        this.userId = undefined
        this.yearofCompletion=undefined
        this.yearOfBeginning=undefined
      
       
    }

    bind(httpResponse:any){
        this.id = httpResponse && httpResponse.id || undefined
        this.courseName = httpResponse && httpResponse.courseName || undefined
        this.courseType = httpResponse && httpResponse.courseType || undefined
        this.universityName= httpResponse && httpResponse.universityName || undefined
        this.yearofCompletion = httpResponse && httpResponse.yearofCompletion || undefined
        this.yearOfBeginning = httpResponse && httpResponse.yearOfBeginning || undefined

       
        this.userId = httpResponse && httpResponse.userId || undefined 
        return this;     
    }
}