import { Component, OnInit, ViewChild } from '@angular/core';
import { LeaveApplicationService } from 'src/app/services/leave-application/leave-application.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WithdrawLeaveApplicationComponent } from '../leave-details/withdraw-leave-application/withdraw-leave-application.component';
import { ApplyNewleaveComponent } from '../apply-newleave/apply-newleave.component';
import { Status } from 'src/app/services/user/user-enum';

@Component({
  selector: 'app-leave-status',
  templateUrl: './leave-status.component.html',
  styleUrls: ['./leave-status.component.scss'],
})
export class LeaveStatusComponent implements OnInit {
  p: number;
  isSpinner: boolean = true;
  LeaveDetailsList = [];
  totalLeaveDaysList: string[] = [];
  searchText: string;
  userid: string;
  years: number[] = [];
  selectedYear = new Date().getFullYear();

  constructor(
    private leaveApplicationService: LeaveApplicationService,
    public userDetailService: UserDetailsService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.initiateForm();
    this.getAllLeaves();
  }
  initiateForm()
  {
    const currentYear = new Date().getFullYear();
    for (let i = currentYear + 20; i > currentYear - 20; i--) {
      this.years.push(i);
    }
    this.p = 1;
    this.userid = this.userDetailService.getUserDetails().id;
  }
  getAllLeaves() {
    this.isSpinner = true;
    this.LeaveDetailsList = [];
    this.leaveApplicationService.getAllLeaveApplication().subscribe(
      (res) => {
        for (let r of res.body) {
        if(r.approvalStatus==Status.APPROVED || r.approvalStatus==Status.REJECTED || r.approvalStatus==Status.REVOKED_BY_USER ){
          let currentLeave: any = r;
          let days = 'calculating';
          this.leaveApplicationService
            .getLeaveTotalCount(r)
            .subscribe((res) => {
              days = res.body;
              const currentLeave={
                typeofLeave:r.typeOfLeave,
                approvalStatus:r.approvalStatus,
                days:res.body,
                startDate:r.startDate,
                endDate:r.endDate,
                transactionDateTime:r.transactionDateTime,
                reasonForLeave:r.reasonForLeave

              }
                this.LeaveDetailsList.push(currentLeave);
              
            });
        }
      }
        this.isSpinner = false;
      },
      (e) => {
        this.isSpinner = false;
      }
    );
  }

  revokeBox(leaveDetail) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    dialogConfig.data = { leaveDetail };
    const dialogRef = this.dialog.open(
      WithdrawLeaveApplicationComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(
      (result) => {
        this.getAllLeaves();
      },
      () => {}
    );
  }

  editLeave(detail) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '100%';
    dialogConfig.height = '90%';
    dialogConfig.panelClass = 'apply-leave';
    dialogConfig.data = { existingLeave: detail };
    const dialogRef = this.dialog.open(ApplyNewleaveComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.getAllLeaves();
    });
  }

  applyNewLeave() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '100%';
    dialogConfig.height = '90%';
    dialogConfig.panelClass = 'apply-leave';
    const dialogRef = this.dialog.open(ApplyNewleaveComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.getAllLeaves();
    });
  }


  isDateYetToPass(date): boolean {
    const currentDate = new Date();
    const approvalDate = new Date(date); // Assuming the approval date is stored in the 'approvalDate' property

    return approvalDate > currentDate;
  }
}
