import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LeaveBalanceComponent } from '../leave-balance.component';
import { AbsolutePipe } from 'src/app/services/pipes/absolute';
import { LeaveApplicationService } from 'src/app/services/leave-application/leave-application.service';
import { Status, TransactionType } from 'src/app/services/user/user-enum';

@Component({
  selector: 'app-leave-balance-history',
  templateUrl: './leave-balance-history.component.html',
  styleUrls: ['./leave-balance-history.component.scss'],
  providers: [AbsolutePipe],
})
export class LeaveBalanceHistoryComponent implements OnInit {
  transactions: any[] = [];
  filteredTransactions =[];
  processedTransactions=[];
 firstCreditTransactionIndex = -1;
  constructor(
    @Inject(MAT_DIALOG_DATA) public leaveData: any,
    private leaveService: LeaveApplicationService,
    private dialogRef: MatDialogRef<LeaveBalanceComponent>,
  ) { }

  ngOnInit(): void {
    this.transactions = this.processTransactions(this.leaveData.history, this.leaveData.openingBalance);
  }

  processTransactions(transactionHistory: any[], openingBalance): any[] {
    const uniqueLeaveApplicationIds = new Set();
    const closingBalanceTransaction: any = {
      transaction: 'Closing Balance',
      transactionTime: null,
      from: null,
      to: null,
      transactionCount: 0,
    };

    transactionHistory
    this.filteredTransactions = transactionHistory.filter(transaction => {
    
      if (transaction.leaveApplicationId !== null && transaction.leaveApplicationId !== "") {
        if (uniqueLeaveApplicationIds.has(transaction.leaveApplicationId)) {
          return false;
        } else {
          uniqueLeaveApplicationIds.add(transaction.leaveApplicationId);
          return true;
        }
      }
      return true;
    });
   

    // Apply any additional processing to filtered transactions
    this.processedTransactions = this.filteredTransactions.filter(transaction => {
      if (transaction.transactionPersonType === TransactionType.SYSTEM || transaction.transactionPersonType === 'HR') {
        
        if (transaction.transactionType === TransactionType.CREDIT) {
          transaction.transaction = transaction.transactionPersonType=='HR'? transaction.transactionType: 'Opening Balance';
          transaction.transactionCount = transaction.transactionCount;
          let transactionTime = new Date(transaction.transactionTime);
          transaction.from = new Date(transactionTime.getFullYear(), 0, 1)
          transaction.to = new Date(transactionTime.getFullYear(), 11, 31)
        }
        else if (transaction.transactionType === TransactionType.ENCASH || transaction.transactionType === TransactionType.CARRY_FORWARD_DEBIT) {
          // Check if closing balance transaction already exists
          if (closingBalanceTransaction.from === null) {
            closingBalanceTransaction.from = transaction.transactionTime;
          }
          closingBalanceTransaction.to = transaction.transactionTime;
          closingBalanceTransaction.transactionTime = transaction.transactionTime;
          closingBalanceTransaction.transactionCount = closingBalanceTransaction.transactionCount + Math.abs(transaction.transactionCount);
        }
      }
      else if ((transaction.leaveApplicationId !== null && transaction.leaveApplicationId !== "")) {
        this.leaveService.getLeaveDetailsByLeaveApplicationId(transaction.leaveApplicationId).subscribe(res => {
          transaction.from = res.body.startDate
          transaction.to = res.body.endDate
          if (res.body.approvalStatus === Status.PENDING) {
            transaction.transaction = TransactionType.AVAILED;
            transaction.reason = res.body.reasonForLeave
          } if (res.body.approvalStatus === Status.APPROVED) {
            transaction.transaction = "Approved"
            transaction.reason = res.body.reasonForLeave
          }
          if (res.body.approvalStatus === Status.REVOKED_BY_USER) {
            transaction.transaction = "Withdrawn"
            transaction.reason = res.body.reasonForCancellation
          }
          if (res.body.approvalStatus === Status.REJECTED) {
            transaction.transaction = "Rejected"
            transaction.reason = res.body.reasonForCancellation
          }
        })
      }

      if(transaction.transactionPersonType===TransactionType.HR && transaction.transactionType===TransactionType.CREDIT){
        transaction.transaction=TransactionType.CREDIT;
      }
      if(transaction.transactionPersonType===TransactionType.HR && transaction.transactionType===TransactionType.DEBIT){
        transaction.transaction=TransactionType.DEBIT;
      }

      if(transaction.transactionPersonType===TransactionType.SYSTEM && transaction.transactionType===TransactionType.CREDIT){
        transaction.transaction=TransactionType.OPENING_BALANCE;
      }
      return transaction;
    });

    // Push closing balance transaction to processed transactions if it exists
    if (closingBalanceTransaction.from !== null && closingBalanceTransaction.to !== null) {
      this.processedTransactions.push(closingBalanceTransaction);
    }

    const filteredProcessedTransactions = this.processedTransactions.filter(transaction =>
      !(transaction.transactionType === TransactionType.ENCASH || transaction.transactionType === 'CARRY_FORWARD_DEBIT' )
    );

    return filteredProcessedTransactions;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
  
}
