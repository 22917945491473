import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BulkImportHolidaysComponent } from './bulk-import-holidays/bulk-import-holidays.component';
import { AddholidaysComponent } from './addholidays/addholidays.component';
import { BluealtairHolidaysComponent } from './bluealtair-holidays/bluealtair-holidays.component';
import { ClientHolidaysComponent } from './client-holidays/client-holidays.component';
@Component({
  selector: 'app-configure',
  templateUrl: './configure.component.html',
  styleUrls: ['./configure.component.scss'],
})
export class ConfigureComponent implements OnInit {
  @ViewChild('baHoliday') baHoliday: BluealtairHolidaysComponent;
  @ViewChild('clientHoliday') clientHoliday: ClientHolidaysComponent;

  constructor() {}

  ngOnInit(): void {}

}
