export class ClientDTO {
  public id: String;
  public clientId: string;
  public clientName: String;
  public clientDescription: String;
  public createdDate: Date;
  public clientLocation: String;
  public imageBase: String;
  public clientImageThumbnail: String

  constructor() {
    this.clientId = '';
    this.clientName = '';
    this.clientDescription = '';
    this.createdDate = new Date();
    this.clientLocation = '';
    this.imageBase = '';
    this.clientImageThumbnail = '';
  }
}
