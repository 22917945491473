import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderByApprovalStatus'
})

export class OrderByApprovalStatusPipe implements PipeTransform {
  transform(items: any[]): any[] {
    if (!items) return [];
    
   
    const pendingItems = items.filter(item => item.approvalStatus === 'PENDING');
    const nonPendingItems = items.filter(item => item.approvalStatus !== 'PENDING');
    return [...pendingItems, ...nonPendingItems];
  }

}
