<mat-drawer-container class="main_container">
  <mat-drawer mode="side" opened>
    <ul class="list-unstyled">
      <li
        class="list-item"
        (click)="toggleOption('optionPerformance')"
        [ngClass]="{ active: selectedOption === 'optionPerformance' }"
      >
        Performance
      </li>
      <li
        class="list-item"
        (click)="toggleOption('optionLeaveConfiguration')"
        [ngClass]="{
          active:
            selectedOption === 'optionLeaveConfiguration' ||
            activeSubOption !== ''
        }"
      >
        {{ activeSubOption ? "Holiday Configuration" : "Leave Configuration" }}
      </li>
    </ul>
  </mat-drawer>
  <mat-drawer-content>
    <div *ngIf="selectedOption === 'optionPerformance'">
      <app-under-construction></app-under-construction>
    </div>
    <div *ngIf="selectedOption === 'optionLeaveConfiguration'">
      <div class="row">
        <div class="col-md-12">
          <div class="table-fixed">
            <table class="table-borderless">
              <tbody>
                <tr
                  class="bg"
                  (click)="
                    toggleOption('optionLeaveConfigurationHoliday');
                    activeSubOption = 'optionLeaveConfigurationHoliday'
                  "
                >
                  <td>
                    <div class="left">
                      <img src="assets/icons/calendar.png"/>
                      <div class="label-desc-container">
                        <label class="user_name">Holiday Configuration</label>
                        <p>New Holiday, Client Holiday</p>
                      </div>
                    </div>
                  </td>
                </tr>

                <tr
                  class="bg"
                  (click)="
                    toggleOption('optionLeaveConfigurationPolicy');
                    activeSubOption = 'optionLeaveConfigurationPolicy'
                  "
                >
                  <td>
                    <div class="left">
                      <mat-icon
                        style="color: #666666"
                        class="material-icons-outlined"
                        >policy</mat-icon
                      >
                      <div class="label-desc-container">
                        <label class="user_name"
                          >Leave Policy Configuration
                        </label>
                        <p>Leave Configuration</p>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selectedOption === 'optionLeaveConfigurationHoliday'">
      <div class="col-md-6 inner-div">
        <div (click)="toggleOption('optionLeaveConfiguration')">
          <figure class="mt-3">
            <mat-icon>
              <img class="upload_img" src="assets/icons/back_arrow.png"
            /></mat-icon>
            <label class="upload_text">Leave Policy Configuration</label>
          </figure>
        </div>
      </div>
      <app-configure></app-configure>
    </div>
    <div *ngIf="selectedOption === 'optionLeaveConfigurationPolicy'">
      <div class="col-md-6 inner-div">
        <div (click)="toggleOption('optionLeaveConfiguration')">
          <figure class="mt-3">
            <mat-icon>
              <img class="upload_img" src="assets/icons/back_arrow.png"
            /></mat-icon>
            <label class="upload_text">Leave Policy Configuration</label>
          </figure>
        </div>
      </div>
      <app-leave-policy-configuration></app-leave-policy-configuration>
    </div>
  </mat-drawer-content>
</mat-drawer-container>