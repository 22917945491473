<!-- header starts here -->
<div>
  <mat-toolbar class="mat_toolbar" [class.hidden]="toggleSearch">
    <button mat-icon-button *ngIf="sidenav.mode === 'over'" (click)="sidenav.toggle()">
      <mat-icon class="hamburger" *ngIf="!sidenav.opened"> menu </mat-icon>
      <mat-icon class="hamburger" *ngIf="sidenav.opened"> close </mat-icon>
    </button>
    <!-- <div class="main_logo_div">
      <img class="Company_logo" src="assets/icons/ba-icon.png" alt="" />
      <span class="Company_name">Blue Pulse</span>
    </div> -->
    <span class="example-spacer"></span>
    <div class="search-svg-icon" (click)="openEmployeeSearch()">
     <img src="assets/icons/dashboard-icons/white-search-icon.svg">
    </div>

    <div class="notification-div" [style.visibility]="isDialogOpen ? 'hidden' : 'visible'">
      <div class="counter-circle" *ngIf="counter != 0">{{ counter }}</div>
      <img (click)="openNotifications()" class="svg-icon" src="assets/icons/dashboard-icons/notification-icon.svg">
    </div>

    <div class="ml-4">
      <button mat-icon-button *ngIf="this.userData && this.userData !=='empty'">
        <img id="profile_avatar" [(src)]="this.userData" [matMenuTriggerFor]="beforeMenu" alt="" />
      </button>
      <button mat-icon-button *ngIf="!this.userData || this.userData == 'empty'">
        <img id="profile_avatar" src="assets/icons/default-avatar.jpg" [matMenuTriggerFor]="beforeMenu" alt="" />
      </button>
      <mat-menu class="matmenu" #beforeMenu="matMenu">
        <button mat-menu-item (click)="logOut()">
          <mat-icon>
            <img class="logout_logo" src="assets/icons/Logout.png" /></mat-icon>
          <label class="logout">Logout</label>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>

  <mat-toolbar class="search-block mat-elevation-z4" [class.active]="toggleSearch">
    <mat-toolbar-row style="padding: 0 5px">
      <button class="search-icon" mat-icon-button disabled>
        <mat-icon>search</mat-icon>
      </button>
      <input class="search-control" type="text" placeholder="Search" [(ngModel)]="searchText" #searchbar />
      <button mat-button mat-icon-button (click)="searchClose()">
        <mat-icon class="close_icon">close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <!-- header ends here -->
  <!-- Sidebar Starts here -->

  <mat-sidenav-container>
    <mat-sidenav #sidenav id="sidenav" [disableClose]="true" [style.width]="isExpanded ? '210px' : '90px'">
      <app-sidebar-menu (sidebarToggled)="onSidebarToggled($event)"></app-sidebar-menu>
    </mat-sidenav>
    <mat-sidenav-content [style.paddingLeft]="isExpanded ? '210px' : '90px'">
      <div class="main-content">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<!-- Sidebar Ends here -->