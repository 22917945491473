import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { RestAPIService } from '../restAPI/rest-api.service';
import { ToastrService } from 'ngx-toastr';
import { RegularizationURLService } from '../restAPI/regularization/regularization-url.service';
@Injectable({
  providedIn: 'root'
})
export class RegularizationService {

  constructor(private restApi: RestAPIService,
    private regularizationUrl:RegularizationURLService,private toast :ToastrService) { }

    
    applyForRealization(payload) {
      
      return this.restApi.post(
        this.regularizationUrl.getBaseUrl(),
        payload
      )
    }

    withdrawnRegularizationRequest(payload,id){
      return this.restApi.put(
        this.regularizationUrl.withdrawRegularization(id),
        payload
      )
    }
    
    private _listners=new Subject<any>();

    listen():Observable<any>{
      return this._listners.asObservable();
    }

    filter(filterBy : string)
    {
        this._listners.next(filterBy);
    }
}
