import { Injectable } from '@angular/core';
import { PassportDetails } from 'src/app/models/passportdetails';
import { PassportdetailsUrlService } from '../restAPI/passportDetailsURLs/passportdetails-url.service';
import { RestAPIService } from '../restAPI/rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class PassportDetailsService {
  constructor(private restApiService: RestAPIService,
    private passporturl : PassportdetailsUrlService
  ) { }
  create(payload) {
     return this.restApiService.post(
      this.passporturl.getBaseUrl(),
      payload
    )
  }
  
  update(payload){
    
    return this.restApiService.put(
      this.passporturl.getBaseUrl(),
      payload
    )
   
  }
  getpassByUserIdSubscribe(userId) {
    return this.restApiService.get(
      this.passporturl.getUserUrl(userId)
    )
  }
  setPassportResponse(response):PassportDetails[]{
      let passportList:PassportDetails[] = []
      for(let data of response){
        let passports:PassportDetails = new PassportDetails()
        passportList.push(
          passports.bind(data)          
        )
    }
    return passportList
  }
  delete(id: any) {
    this.restApiService.delete(
      this.passporturl.getByFieldUrl(id)
    ).subscribe(res=>{
      
    })
  }
}
