import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DeleteEmployeeComponent } from 'src/app/components/employee/delete-employee/delete-employee.component';
import { NewFeedbackGroupService } from 'src/app/services/new-feedback-group.service';
import { UserService } from 'src/app/services/user/user.service';
import { UserDTO } from 'src/app/dtos/UserDTO';
@Component({
  selector: 'app-close-feedback',
  templateUrl: './close-feedback.component.html',
  styleUrls: ['./close-feedback.component.scss'],
})
export class CloseFeedbackComponent implements OnInit {
  payload;
  user: UserDTO;
  cmid: UserDTO;
  cmemail: UserDTO;
  value: any;
  emails: string[] = [];
  filteredEmails: Observable<string[]>;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  DLemail: string = 'pr-india@bluealtair.com';
  separatorKeysCodes: number[] = [ENTER, COMMA];

  emailCtrl = new FormControl();

  allEmails: string[] = [];

  @ViewChild('EmailInput') EmailInput: ElementRef<HTMLInputElement>;
  @ViewChild('emailAuto') matAutocomplete: MatAutocomplete;
  useremail: String;
  constructor(
    public dialogRef: MatDialogRef<DeleteEmployeeComponent>,
    private feedbackgroup: NewFeedbackGroupService,
    public toastr: ToastrService,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.filteredEmails = this.emailCtrl.valueChanges.pipe(
      startWith(null),
      map((email: string | null) =>
        email ? this._filter(email) : this.allEmails.slice()
      )
    );
  }
  ngOnInit(): void {
    this.getemail();
    this.getAllEmpEmail();
    this.value;
  }
  remove(ccemail: string): void {
    const index = this.emails.indexOf(ccemail);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    this.emails.push(event.option.viewValue);
    this.EmailInput.nativeElement.value = '';
    this.emailCtrl.setValue(null);
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allEmails.filter(
      (ccemail) => ccemail.toLowerCase().indexOf(filterValue) === 0
    );
  }

  closefeedback() {
    var objForPayload = {};
    var data = [];
    var final = [];
    objForPayload['feedbackSummary'] = this.data.note;

    data.push(this.cmemail);
    data.push(this.DLemail);
    final = [...data, ...this.emails];
    objForPayload['ccEmails'] = final;

    if (!this.data.isProbation) {
      this.feedbackgroup
        .closeFeedBackSaveNote(
          this.data.directReporteeId,
          this.data.selected,
          this.data.saveAsDraft,
          objForPayload
        )
        .subscribe(
          (res) => {
            if (res.body == 0) {
              this.toastr.success('Success', 'Success');
              this.feedbackgroup.setRefresh('refresh');
              this.dialogRef.close();
            } else if (res.body == 1) {
              this.toastr.error(
                'All Feedback Group weightage Sum should be 100%',
                'failed'
              );
              this.dialogRef.close();
            } else if (res.body == 2) {
              this.toastr.error('All feedback must be submitted', 'failed');
              this.dialogRef.close();
            } else if (res.body == 3) {
              this.toastr.error(
                'All feedback weightage sum should be 100%',
                'failed'
              );
              this.dialogRef.close();
            }
          },
          (error) => {
            this.toastr.error(
              'No feedback generated for user for the year',
              'failed'
            );
          }
        );
    } else {
      // for probation
      this.feedbackgroup
        .closeProbationFeedBackSaveNote(
          this.data.directReporteeId,
          this.data.selected,
          this.data.saveAsDraft,
          objForPayload
        )
        .subscribe(
          (res) => {
            if (res.body == 0) {
              this.toastr.success('Success', 'Success');
              this.feedbackgroup.setRefresh('refresh');
              this.dialogRef.close();
            } else if (res.body == 1) {
              this.toastr.error(
                'All Feedback Group weightage Sum should be 100%',
                'failed'
              );
              this.dialogRef.close();
            } else if (res.body == 2) {
              this.toastr.error('All feedback must be submitted', 'failed');
              this.dialogRef.close();
            } else if (res.body == 3) {
              this.toastr.error(
                'All feedback weightage sum should be 100%',
                'failed'
              );
              this.dialogRef.close();
            } else if (res.body == 5) {
              this.toastr.error(
                'Career Manager and Supervisor feedback is mandatory',
                'failed'
              );
              this.dialogRef.close();
            }
          },
          (error) => {
            this.toastr.error(
              'No feedback generated for user for the year',
              'failed'
            );
          }
        );
    }

    this.matDialogClose();
  }
  getemail() {
    this.userService
      .getUserByID(this.data.directReporteeId)
      .subscribe((res) => {
        this.user = res.body;
        this.cmid = res.body.careerManagerId;
        this.userService.getUserByID(this.cmid).subscribe((res) => {
          this.cmemail = res.body.email;
        });
      });
  }
  matDialogClose() {
    this.dialogRef.close();
  }
  getAllEmpEmail() {
    this.userService.getAllUsersMailId().subscribe((res) => {
      this.allEmails = res.body;
      (val: any[]) => {
        this.allEmails = val.map((user) => user.email);
        this.emailCtrl.setValue(null); //use this to apply changes instantly
      };
    });
  }
}
