import { AddressTypeDTO  } from "./AddressTypeDTO";
import { User } from '../models/user';
export class AddressDetailsDTO {
    public addressLineOne: String
    public addressLineTwo: String
    public country: String
    public city: String
    public state: String
    public pincode: String
    public addressType:string
    public userId: string
    public addressCategory:string
    public id:string
    constructor() {
        this.addressLineOne = ""
        this.addressLineTwo = ""
        this.country = ""
        this.city = ""
        this.state = ""
        this.pincode = ""
        this.addressCategory=undefined
    }
}