export class PassportDetailsDTO {
    public id :string
    public country:string
    public passportNumber: String;
    public expiryDate : Date;
    public userId :string
    public passportb:string
    constructor() {
        this.passportNumber="";
     }
}