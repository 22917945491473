import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class AddressDetailsUrlService {
  public readonly addressdetailBaseApi = '/api/address-details';
  private readonly user = '/user';
  private readonly userId_param = 'userId'
  private readonly address_param = 'addresssCategory'
  constructor() { }
  getBaseUrl(){
    return this.addressdetailBaseApi;
  }
getUserUrll(userId)
{
  return this.addressdetailBaseApi + this.user + '?' + this.userId_param + '=' + userId
}  
  getUserUrl(category,userId) {
    return this.addressdetailBaseApi + this.user
      + '?' + this.address_param + '=' + category+'&'+ this.userId_param + '=' + userId
  }
}
