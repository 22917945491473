<div class="table-main-body">

    

    <table class="content-table">
        <thead>
            <tr class="table-head">
                <th class="head-1">LeaveType/Holiday</th>
                <th class="head-2">No. of Days</th>
                <th class="head-3">Status</th>
            </tr>
        </thead>
        <tbody *ngIf="isSpinner">
            <div class="spinner-div">
                <mat-spinner strokeWidth="7" [diameter]="150"></mat-spinner>
                <label class="loading_label">Loading...</label>
            </div>
        </tbody>
        <tbody *ngIf="!isSpinner">
            <div *ngIf="LeaveDetailsList.length == 0">
                <tr>
                    <td colspan="5" class="no-data-message">No data available</td>
                </tr>
            </div>
            <ng-container *ngIf="LeaveDetailsList.length > 0">
                <ng-container *ngFor="
              let leave of this.LeaveDetailsList | orderBy : 'startDate' : 'desc' 
            ">
                    <tr>
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <td class="cont-1">
                                            {{
                                            leave.typeofLeave !== "FLOATER_LEAVE"

                                            ?
                                            (leave.typeofLeave | underScoreToSpace | titlecase)
                                            : "Floater"
                                            }}
                                        </td>
                                        <td class="cont-2">{{ leave.days }}</td>
                                        <td class="cont-1">{{leave.approvalStatus==="REVOKED_BY_USER"? 'Withdrawn ':
                                            leave.approvalStatus| underScoreToSpace | titlecase}}</td>

                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr />
                                <div class="expansion">
                                    <div>Applied on : {{ leave.transactionDateTime | date }}</div>
                                    <div *ngIf="leave.reasonForLeave" class="reason-mat">Reason : {{ leave.reasonForLeave }}</div>
                                </div>
                                <div>
                                    Duration : {{ leave.startDate | date }} to
                                    {{ leave.endDate | date }}
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </tr>
                </ng-container>
            </ng-container>


        </tbody>
    </table>

</div>