import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { EventTabClosed } from 'src/app/services/user/user-enum';
import { UserService } from 'src/app/services/user/user.service';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  userDto = new UserDTO();
  aboutDetails: FormGroup;
  userid: string;
  constructor(
    private toaster: ToastrService,
    private userService: UserService,
    private fb: FormBuilder,
    private userDetailService: UserDetailsService,
    private dialogref: MatDialogRef<AboutComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
  }
  ngOnInit(): void {
    this.setFormData();
  }
  closeDialog(event: Event) {
    this.dialogref.close({ event: EventTabClosed.Closed });
  }
  setFormData() {
    this.aboutDetails = this.fb.group({
      about: [''],
    });
    this.userDto.id = this.data[1];
    this.userDto.about = this.data[0];
    this.aboutDetails = this.fb.group({
      // RE for accpeting only specified characters and words.
      about: ['', [Validators.pattern(/^[a-zA-Z0-9 !(),."-:\n]+$/)]],
    });
    this.aboutDetails
      .get('about')
      .setValue(this.userDto.about ? this.userDto.about : '');
  }
  onSubmit() {
    if (
      this.aboutDetails.valid
    ) {
      this.userDto.about = this.aboutDetails.get('about').value;
      this.userService
        .setUserAbout(this.userDto.id, this.userDto.about)
        .subscribe(() => {
          this.userDetailService.setUserAbout(this.userDto.about);
          this.toaster.success('Successfully Saved');
          this.dialogref.close({ event: EventTabClosed.Closed });
        });
    }
  }
}
