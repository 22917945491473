import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-deactivate-employee',
  templateUrl: './deactivate-employee.component.html',
  styleUrls: ['./deactivate-employee.component.scss'],
})
export class DeactivateEmployeeComponent implements OnInit {
  constructor(
    private userService: UserService,
    public dialogRef: MatDialogRef<DeactivateEmployeeComponent>,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: User
  ) { }

  ngOnInit(): void { }

  matDialogClose() {
    this.dialogRef.close();
  }

  deactivateEmployee() {
    this.userService.deactivateUser(this.data[0].id).subscribe((res) => {
      if (res.body.success) {
        this.toastr.success("Success", "Successfully Deactivated");
        this.matDialogClose();
      }
      else {
        this.toastr.warning("Failed", res.body.message)

      }
    });
  }

  activateEmployee() {

    if (this.data[0].careerManagerId != null) {
      this.userService.activateUser(this.data[0].id).subscribe((res) => {
        if (res.body.success) {
          this.toastr.success("Success", "Successfully Acitvated")
          this.matDialogClose();
        }
        else {
          this.toastr.warning("Failed", res.body.message)

        }
      });
    }
    else {
      this.toastr.error("Career manager is Required", "Failed");
    }

  }

  bulkActivateEmployee() {

    this.userService.bulkActivation(this.data[0]).subscribe((res) => {

      if (res.body.SUCCESSFULLY_ACTIVATED.length > 0) {
        this.toastr.success("Success", "Activated Successfully");
        this.matDialogClose();
      }
      if (res.body.CM_NOT_AVAILABLE.length > 0) {
        this.toastr.error("Career manager is Required", "Failed")
        this.matDialogClose();
      }
    });
  }

  bulkDeleteEmployee() {

    this.userService.deleteSelectedUsers(this.data[0]).subscribe((res) => {

      if (res.body.SUCCESSFULLY_DELETED.length > 0) {

        this.toastr.success("Success", "Successfully Deactivated");
        this.matDialogClose();
      }
    });
  }
  deleteEmployee() {

    this.userService.deleteNewUser(this.data[0].id).subscribe((res) => {
      if (res.body.success) {
        this.toastr.success("Success", "Successfully Deleted");
        this.matDialogClose();
      }
      else {
        this.toastr.warning("Failed", res.body.message)

      }
    });
  }
  deleteAlumini() {
    this.userService.deleteDeactivatedUser(this.data[0].id).subscribe((res) => {
      if (res.body.success) {
        this.toastr.success("Success", "Successfully Deleted");
        this.matDialogClose();
      }
      else {
        this.toastr.warning("Failed", res.body.message)

      }
    });
  }

}
