<div class="main-container">
  <div class="header mt-3">
    <div class="warnig-with-icon">
      <img src="\assets\icons\warning.png" />
      <label class="warning">Warning</label>
    </div>
    <div class="close-icon">
      <img src="\assets\icons\close.png" (click)="matDialogClose()" />
    </div>
  </div>
  <div class="line"></div>

  <div class="body-text">
    <label *ngIf="data[1] == 'Deactivate' || (data[1] == 'BulkDelete'&& data[0].length == 1)">
      Are you sure you want to deactivate this employee?
    </label>
    <label *ngIf="data[1] == 'Activate' || (data[1] == 'BulkActivate' && data[0].length == 1)">
      Are you sure you want to activate this employee?
    </label>
    <label *ngIf="data[1] == 'BulkActivate' && data[0].length > 1">
      Are you sure you want to  activate these employees?
    </label>
    <label *ngIf="data[1] == 'BulkDelete'&& data[0].length > 1">
      Are you sure you want to  delete these employees?
    </label>
    <label *ngIf="data[1] == 'Delete'">
      Are you sure you want to  delete this employee?
    </label>
    <label *ngIf="data[1] == 'AluminiDelete'">
      Are you sure you want to  delete this Alumni employee?
    </label>
  </div>
  <div class="footer">
    <button class="no" (click)="matDialogClose()">No</button>
    <button
      *ngIf="data[1] == 'Deactivate'"
      class="yes"
      (click)="deactivateEmployee()"
    >
      Yes
    </button>
    <button
      *ngIf="data[1] == 'Activate'"
      class="yes"
      (click)="activateEmployee()"
    >
      Yes
    </button>
    <button
      *ngIf="data[1] == 'BulkActivate'"
      class="yes"
      (click)="bulkActivateEmployee()"
    >
      Yes
    </button>
    <button
      *ngIf="data[1] == 'BulkDelete'"
      class="yes"
      (click)="bulkDeleteEmployee()"
    >
      Yes
    </button>
    <button *ngIf="data[1] == 'Delete'" class="yes" (click)="deleteEmployee()">
      Yes
    </button>
    <button *ngIf="data[1] == 'AluminiDelete'" class="yes" (click)="deleteAlumini()">
      Yes
    </button>
  </div>
</div>
