<div class="container-fluid">
  <div class="main_header_div">
    <div class="child">
      <h3 class="header_name">Bulk Configuration</h3>
    </div>
    <div class="child_close_btn">
      <button class="closeButton" (click)="closeDialog()">Close</button>
    </div>
  </div>
</div>
<div class="form_main_div">
  <form [formGroup]="leaveConfigureFormGroup">
    <div class="row">
      <div class="col-md-3 first_box">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Leave Type</mat-label>
          <mat-select formControlName="leaveType">
            <mat-option *ngFor="let type of leaveTypes" [value]="type">
              {{ type | underScoreToSpace | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Credit or Debit</mat-label>
          <mat-select formControlName="creditOrDebit">
            <mat-option value="credit">Credit</mat-option>
            <mat-option value="debit">Debit</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6"></div>
    </div>
    <div class="row">
      <div class="col-md-3 first_box">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Number of Days</mat-label>
          <input
            matInput
            type="number"
            formControlName="numberOfDays"
            autocomplete="off"
          />
          <mat-error
            *ngIf="
              leaveConfigureFormGroup.get('numberOfDays').hasError('multipleOf')
            "
          >
            Days must be a multiple of 0.25
          </mat-error>
          <mat-error
            *ngIf="leaveConfigureFormGroup.get('numberOfDays').errors?.required"
          >
            Required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3"></div>
      <div class="col-md-6"></div>
    </div>
    <div style="display: flex; justify-content: flex-end">
      <button
        mat-raised-button
        type="button"
        class="submit_button_main"
        (click)="submit()"
      >
        Bulk Configure
      </button>
    </div>
  </form>
</div>