import { Injectable } from '@angular/core';
import { LeaveApprovalUrlsService } from '../restAPI/leaveApprovalURLs/leave-approval-urls.service';
import { RestAPIService } from '../restAPI/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class LeaveApprovalService {

  constructor(
    private leaveApprovalUrl:LeaveApprovalUrlsService,
    private restApiService: RestAPIService,
    
  ) { }

  update(payload){
    return this.restApiService.put(
      this.leaveApprovalUrl.leaveApprovalUrl(),
      payload
    )
  }

  getOnLeaveReportees(month, year){
    return this.restApiService.get(
      this.leaveApprovalUrl.onLeaveReportees(month, year)
    )
  }
  getReporteeAttendance(year, userId){
    return this.restApiService.get(
      this.leaveApprovalUrl.getReporteeAttendance(year, userId)
    )
  }
  
  updateThroughTask(payload){
    return this.restApiService.put(
      this.leaveApprovalUrl.leaveApprovalBaseApi(),
      payload
    )
  }

  leaveConfigurationByCm(payload,cmId,remark){
    return this.restApiService.put(
      this.leaveApprovalUrl.leaveConfigurationByCm(cmId,remark),payload
    )
  }
}
