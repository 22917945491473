import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { retry } from 'rxjs/operators';
import { RestAPIService } from '../restAPI/rest-api.service';
import { SubgoalURLService } from '../restAPI/subgoalURL/subgoal-url.service';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class SubgoalService {

  constructor(private restApi: RestAPIService,
    private subgoalUrl:SubgoalURLService,private toast :ToastrService) { }

    getSubgoalById(id){
      return this.restApi.get(this.subgoalUrl.getByFieldUrl(id));
    }
    getSubgoalByUserGoal(usergoalid){
      return this.restApi.get(this.subgoalUrl.getUserGoalIdUrl(usergoalid));
    }
    
    create(payload) {
      
      return this.restApi.post(
        this.subgoalUrl.getBaseUrl(),
        payload
      )
    }
    update(payload){
      return this.restApi.put(
        this.subgoalUrl.getBaseUrl(),
        payload
      )
    }
    delete(id: any) {
     return this.restApi.delete(
        this.subgoalUrl.getByFieldUrl(id)
      ).subscribe(res=>{
        this.toast.success('Successfully Deleted');
        
      })
    }
    private _listners=new Subject<any>();
    listen():Observable<any>{
      return this._listners.asObservable();
    }
    filter(filterBy : string)
    {
    this._listners.next(filterBy);
    }
}
