export class FeedbackNotesDTO {
        public cmNote: string;
        public hrNote: string;
        public id: string;
        public userId: string;
        public year: string;
        constructor() {
            this.cmNote="";
            this.hrNote="";
        }   
    }
