<div class="main_header_div">
  <div class="child">
    <h1 class="header_name "><img class="header-icon mr-3" src="assets\icons\history.png">Employment History</h1>
  </div>
  <div class="pr-3 pt-3">
    <img class="close-icon" (click)="closeDialog($event)" src="assets/icons/close.png">
  </div>
</div>
<hr />

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 form_main_div">
      <div class="add_button_fix">
        <button class="add_button" type="button" (click)="addPreEmployment()">+Add</button>
        <button class="plus_button mt-4" type="button" (click)="addPreEmployment()">
          <span>+Add</span>
        </button>
      </div>
      <form [formGroup]="editSpotlightDetails">
        <div class="bodyScroll">
          <div formArrayName="previousEmployment" *ngFor="let work of this.PreviousEmp().controls; let i = index">
            <div class="row">
              <div class="col-md-2" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>Start Date <span class="contentRed"></span></mat-label>

                  <input matInput [max]="today" [matDatepicker]="picker2" placeholder="dd/mm/yyyy"
                    formControlName="startDate" />
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                  <mat-error *ngIf="
                      editSpotlightDetails
                        .get(['previousEmployment', i, 'startDate'])
                        .hasError('required') && !editSpotlightDetails
                        .get(['previousEmployment', i, 'startDate'])
                        .hasError('matDatepickerParse')
                    ">
                    Required
                  </mat-error>
                  <mat-error *ngIf="
                  editSpotlightDetails
                    .get(['previousEmployment', i, 'startDate'])
                    .hasError('matDatepickerParse')
                ">
                    Invalid Date
                  </mat-error>
                  <mat-error *ngIf="
                      editSpotlightDetails
                        .get(['previousEmployment', i, 'startDate'])
                        .hasError('dateOverlap')
                    ">
                    Overlaping dates
                  </mat-error>

                </mat-form-field>
              </div>

              <div class="col-md-2" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>End Date <span class="contentRed"></span></mat-label>
                  <input [min]="getMinEndDate(i)" [max]="today" matInput [matDatepicker]="picker3"
                    placeholder="dd/mm/yyyy" formControlName="endDate" [disabled]="
                      !editSpotlightDetails.controls.previousEmployment
                        .controls[i].controls.startDate.value
                    " />
                  <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                  <mat-datepicker #picker3></mat-datepicker>
                  <mat-error *ngIf="
                      editSpotlightDetails
                        .get(['previousEmployment', i, 'endDate'])
                        .hasError('required') && !editSpotlightDetails
                        .get(['previousEmployment', i, 'endDate'])
                        .hasError('matDatepickerParse')
                    ">
                    Required
                  </mat-error>
                  <mat-error *ngIf="
                      editSpotlightDetails
                        .get(['previousEmployment', i, 'endDate'])
                        .hasError('matDatepickerParse')
                    ">
                    Invalid Date
                  </mat-error>
                  <mat-error *ngIf="
                      editSpotlightDetails
                        .get(['previousEmployment', i, 'endDate'])
                        .hasError('dateOverlap')
                    ">
                    Overlaping dates
                  </mat-error>

                </mat-form-field>
                <mat-error class="dateOverlapError"></mat-error>
              </div>
              <div class="col-md-3" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>Job Title</mat-label>
                  <input matInput autocomplete="off" formControlName="jobTitle" />
                  <mat-error *ngIf="
                      editSpotlightDetails
                        .get(['previousEmployment', i, 'jobTitle'])
                        .hasError('noOnlySpecialCharacters')
                    ">Invalid characters</mat-error>
                  <mat-error *ngIf="
                      editSpotlightDetails
                        .get(['previousEmployment', i, 'jobTitle'])
                        .hasError('required')
                    ">
                    Required
                  </mat-error>
                  <mat-error *ngIf="
                      editSpotlightDetails
                        .get(['previousEmployment', i, 'jobTitle'])
                        .hasError('pattern')
                    ">
                    Invalid pattern
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>Company</mat-label>
                  <input matInput autocomplete="off" formControlName="comapanyName" />
                  <mat-error *ngIf="
                      editSpotlightDetails
                        .get(['previousEmployment', i, 'comapanyName'])
                        .hasError('noOnlySpecialCharacters')
                    ">Invalid characters</mat-error>
                  <mat-error *ngIf="
                      editSpotlightDetails
                        .get(['previousEmployment', i, 'comapanyName'])
                        .hasError('required')
                    ">
                    Required
                  </mat-error>
                  <mat-error *ngIf="
                      editSpotlightDetails
                        .get(['previousEmployment', i, 'comapanyName'])
                        .hasError('pattern')
                    ">
                    Invalid pattern
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <button class="remove_label" type="button" (click)="removePreEmployment(work.value.id, i)">
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="submit_button_main">
              <button class="save_Emp_btn" type="button" (click)="onSubmit()">
                <span>Save</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>