import { Injectable } from '@angular/core';
import { RestAPIService } from '../rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class SharePointUrlService {
  private readonly sharePointBaseUrl = '/api/testing_sharepoint_cron_job';
  constructor(private restApi:RestAPIService) { }
  getBaseUrl(){
  return this.sharePointBaseUrl;
  }

  
  }
















