export class CmReportDTO{
    public id : String;
    public firstName : String;
    public lastName : String;
    public employeeId : String;
    public directReportees : any;
    public openCount  : number;
    public closeCount : number;
    public notInitiated : number

    constructor(){
        this.id = "";
        this.firstName="";
        this.lastName="";
        this.employeeId="";
        this.directReportees=[];
        this.openCount=0
        this.closeCount=0
        this.notInitiated=0
    }
}