import { Component, OnInit } from '@angular/core';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { AbsentService } from 'src/app/services/absent/absent.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WithdrawRegularizationComponent } from './withdraw-regularization/withdraw-regularization.component';
import { AttendanceStatus } from 'src/app/services/attendance-extra/attendance-enum';

@Component({
  selector: 'app-pending-regulariztion-details',
  templateUrl: './pending-regulariztion-details.component.html',
  styleUrls: ['./pending-regulariztion-details.component.scss']
})
export class PendingRegulariztionDetailsComponent implements OnInit {
  val: any[] = [];
  userDetail: UserDTO;
  constructor(
    private userdetails: UserDetailsService,
    private absentService: AbsentService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getUserDetails();
    this.getStatus(AttendanceStatus.UNMARKED_ATTENDANCE, this.userDetail.id)
  }
getUserDetails()
{
  this.userDetail = this.userdetails.getUserDetails();
}
  revokeBox(leaveDetail) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    dialogConfig.data = { leaveDetail };
    const dialogRef = this.dialog.open(
      WithdrawRegularizationComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result.event === 'success') {
           this.getStatus(AttendanceStatus.UNMARKED_ATTENDANCE, this.userDetail.id)
        }
      },
      () => {}
    );
  }
  getStatus( attendanceStatus,userId) {
    this.val = [];
    const user = this.userdetails.getUserDetails();
    this.absentService.getstatusbyuser(AttendanceStatus.UNMARKED_ATTENDANCE,userId).subscribe(res => {
      for (let i = 0; i < res.body.length; i++) {
        const newItem = res.body[i];
        if(newItem.appliedForRegularization==true) {
        this.val.push(newItem);
      }
    }
    }
    
    )
    
    return this.val;
  }

}
