<div class="table-fixed">
  <table class="table-data">
      <thead>
          <tr class="table-head">
              <th class="reg-category">Category</th>
              <th class="reg-date">Date</th>
              <th class="reg-day">No. of Days</th>
              <th class="reg-status">Status</th>
              <th class="reg-status"></th>
          </tr>
      </thead>
      <tbody>
          <ng-container *ngIf="val.length == 0">
              <tr>
                  <td colspan="5" class="no-data-message">No data available</td>
              </tr>
          </ng-container>
          <ng-container *ngIf="val.length > 0">
              <ng-container *ngFor="let item of val | orderBy : 'attendanceDateTime'">
                  <tr *ngIf="item.appliedForRegularization" class="table-body">
                      <td class="reg-category">
                          <label>Regularization</label>
                      </td>
                      <td class="reg-date">
                          <label>{{ item.attendanceDateTime | date }}</label>
                      </td>
                      <td class="reg-date">
                          <label>1</label>
                      </td>
                      <td class="reg-date">
                          <label>Pending</label>
                      </td>
                      <td>
                          <button class="withdraw-btn" (click)="revokeBox(item)">
                              Withdraw
                          </button>
                      </td>
                  </tr>
              </ng-container>
          </ng-container>
      </tbody>
  </table>
</div>

