import { environment } from "./environments/environment";

export const OAuthSettings = {
    appId: 'f7744189-93ef-43af-9601-6e08d312f79b',
    redirectUri: environment.BASE_API_URL_FRONTEND,
    postLogoutRedirectUri: environment.BASE_API_URL_FRONTEND,
    scopes: [
      "user.read"
      // "mailboxsettings.read",
      // "calendars.readwrite"
    ],
    authority: 'https://login.microsoftonline.com/153dcb9d-7906-432c-8b87-10671d48ff0d',
  };
  