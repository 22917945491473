<div class="main_header_div">
        <h3 mat-dialog-title class="mat-title"><img class="header-icon mr-3" src="assets/icons/rtf.png">Summary of real-time feedback</h3>
        <div class="child_close_btn">
           <img class="btn-submit" (click)="closeDialog()" src="assets/icons/close.png">
        </div>
</div>
<hr />
<div>
  <form #feedbackForm="ngForm" (ngSubmit)="submitSummary()">
    <div class="m-15 additional-info-input">
      <mat-form-field class="example-full-width" style="width: 100%" appearance="outline">
        <mat-label>Feedback Summary</mat-label>
        <textarea matInput name="summary" required [(ngModel)]="summary" maxlength="250"
          placeholder="Write a short summary for this real-time feedback"></textarea>
        <mat-hint>
          <label>
            Remaining characters: {{ 250 - summary.length }} / 250
          </label>
        </mat-hint>
        <mat-error *ngIf="summary === '' || !feedbackForm.valid">
          Required
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <button class="submit-btn" [disabled]="!feedbackForm.valid" type="submit">
        Submit
      </button>
    </div>
  </form>
</div>
