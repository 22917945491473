import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FeedbackDTO } from '../../../dtos/FeedbackDTO';
import {
  FeedbackSubmitStatus,
  FeedbackReadStatus,
} from 'src/app/services/feedback/feedback-enum';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { EditAppComponent } from '../../edit-app/edit-app.component';
import { FormTemplateDialogComponent } from '../form-template-dialog/form-template-dialog/form-template-dialog.component';
@Component({
  selector: 'app-feedback-tab-content',
  templateUrl: './feedback-tab-content.component.html',
  styleUrls: ['./feedback-tab-content.component.scss'],
})
export class FeedbackTabContentComponent implements OnInit, OnChanges {
  @Input() public feedbacksList;
  @Input() public disableEdit: Boolean;
  public showFeedbackList;
  public userToName;
  public isFilledFlag = [];
  public name;
  feedbackDTO = new FeedbackDTO();
  public isSubmitted: Boolean = false;
  public statusColor;

  constructor(
    public userDetailsService: UserDetailsService,
    public feedbackService: FeedbackService,
    public userService: UserService,
    public initialAvatarService: InitialAvatarService,
    public userDetailService: UserDetailsService,
    private notificationService: NotificationService,
    public dialog: MatDialog
  ) {}

  ngOnChanges(): void {
    if (this.feedbacksList != undefined) {
      this.showFeedbackList = this.feedbacksList;
    }

    this.statusColor = {
      submitStatus: this.disableEdit,
      pendingStatus: !this.disableEdit,
    };
  }
  onClickSubmittedTab() {
    this.feedbackService
      .getFeedbackByUserIdAndStatus(
        this.userDetailsService.getUserDetails().id,
        FeedbackReadStatus.READ,
        FeedbackSubmitStatus.SUBMITTED
      )
      .subscribe((res) => {
        this.showFeedbackList = res.body;
      });
  }
  ngOnInit(): void {}

  openFormTemplateDialog(property): void {
    if (property) {
      const dialogRef = this.dialog.open(FormTemplateDialogComponent, {
        height: '97%',
        data: {
          property:
            property.feedbackSubmitStatus == FeedbackSubmitStatus.PENDING
              ? JSON.parse(property.formTemplate).formTemplate
              : property.formSubmissionAnswer,
          disableEdit: this.disableEdit,
        },
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result != undefined) {
          property.formSubmissionAnswer = JSON.stringify(result);
          property.readStatus = FeedbackReadStatus.READ;
          property.feedbackSubmitStatus = FeedbackSubmitStatus.SUBMITTED;

          //send Feedback object which is "property" in this case
          this.feedbackDTO.prepareUpdateDTOPayload(property);

          this.feedbackService
            .updateFeedbackSubscribe(this.feedbackDTO)
            .subscribe((res) => {
              this.feedbackService
                .getFeedbackByUserIdAndStatus(
                  this.userDetailService.getUserDetails().id,
                  FeedbackReadStatus.UNREAD,
                  FeedbackSubmitStatus.PENDING
                )
                .subscribe((res) => {
                  this.feedbackService.setFeedbackPendingList(res.body);
                  this.notificationService.sucess(
                    'Feedback Submitted Successfully'
                  );
                });

              this.feedbackService
                .getFeedbackByUserIdAndStatus(
                  this.userDetailService.getUserDetails().id,
                  FeedbackReadStatus.READ,
                  FeedbackSubmitStatus.SUBMITTED
                )
                .subscribe((res) => {
                  this.feedbackService.setFeedbackSubmittedList(res.body);
                });
            });
        }
      });
    }
  }

  processName(user) {
    let name;
    if (user && user.firstName) name = user.firstName;

    if (user && user.lastName) {
      name ? (name += ' ' + user.lastName) : (name = user.lastName);
    }

    return name;
  }

  processDesignation(user) {
    let designation;
    if (user && user.firstName) return (designation = user.jobTitle);
  }

  openGenerateDialog(formTemplate) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
    dialogConfig.height = '90%';
    dialogConfig.panelClass = ['panelClassFeedbackGenerationForm'];
    dialogConfig.backdropClass = 'backdropClass';
    dialogConfig.data = formTemplate;
    const dialogRef = this.dialog.open(EditAppComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
