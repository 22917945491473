<div class="container">
  <div class="main_header_div">
    <div class="head">
      <img src="assets/icons/dashboard-icons/wfo-icon.svg" />
      Working from Office
    </div>
    <img class="close-icon-svg" (click)="closeDialog()" src="assets/icons/dashboard-icons/close-pink-icon.svg" />
  </div>
</div>

<div class="container select-main">
  <tbody>
    <tr>
      <td>
        <button *ngIf="loggedInUserId === userDetails.id" class="blue-button" (click)="applyWFO()">Apply WFO</button>
        <button *ngIf="loggedInUserId != userDetails.id" class="grey-disabled-button">Apply WFO</button>
      </td>

      <td>
        <div class="month-container-main">
          <mat-form-field class="month-container" appearance="outline">
            <mat-label>Month</mat-label>
            <mat-select [(ngModel)]="selectedMonth" (selectionChange)="selectMonth($event.value)">
              <mat-option *ngFor="let month of months" [value]="month">
                {{ monthNames[month - 1] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </td>

      <td>
        <div class="month-container-main">
          <mat-form-field class="month-container" appearance="outline">
            <mat-label>Year</mat-label>
            <mat-select [(ngModel)]="selectedYear" (selectionChange)="selectYear($event.value)">
              <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </td>

      <td>
        <div class="reportee-select-main-div">
          <div class="reportee-select-child-div ">
            <mat-form-field appearance="none">
              <div class="reportee-detail-div">
                <div>
                  <img class="reportee-img" src="assets/icons/default-avatar.jpg" alt=""
                    *ngIf="!userDetails.userImageThumbnail || userDetails.userImageThumbnail === ''">
                  <img class="reportee-img" [src]="getSafeImageUrl(userDetails.userImageThumbnail)" alt=""
                    *ngIf="userDetails.userImageThumbnail && userDetails.userImageThumbnail !== ''">
                </div>
                <div class="user-name-id-div">
                  <div>{{ userDetails?.firstName }}  {{ userDetails?.lastName}}</div>
                  <div>
                    {{ userDetails?.employeeId }}
                    <ng-container *ngIf="userDetails?.baseLocation">
                      ({{ userDetails?.baseLocation }})
                    </ng-container>
                  </div>
                </div>
              </div>
              <mat-select #select (selectionChange)="selectEmployee($event)" [(value)]="userDetails">

                <div class="search-main ">
                  <img class="search-img" src="assets/icons/dashboard-icons/search-icon.svg" />
                  <input class="search-input" type="text" placeholder="Search"
                    (keyup)="searchEmployee($event.target.value)" autocomplete="off">
                </div>

                <mat-option *ngFor="let user of filterEmployee" [value]="user">
                  <div class="reportee-detail-div">
                    <div>
                      <img class="reportee-img" src="assets/icons/default-avatar.jpg" alt=""
                        *ngIf="!user.userImageThumbnail || user.userImageThumbnail === ''">
                      <img class="reportee-img" [src]="getSafeImageUrl(user.userImageThumbnail)" alt=""
                        *ngIf="user.userImageThumbnail && user.userImageThumbnail !== ''">
                    </div>
                    <div class="user-name-id-div">
                      <div class="user-name-div">{{user?.firstName}} {{user?.lastName}}</div>
                      <div>
                        {{user?.employeeId}}
                        <ng-container *ngIf="user?.baseLocation">
                          ({{ user?.baseLocation }})
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </td>

      <td >
        <div *ngIf="wfoDetailsList && wfoDetailsList.length != 0" class="download-main">
          <img class="download-icon" (click)="downloadWFODetail()" src="assets/icons/dashboard-icons/download.svg" />
        </div>
        <div *ngIf="!wfoDetailsList || (wfoDetailsList && wfoDetailsList.length == 0)" class="disable-download-main">
          <img class="disable-download-icon" src="assets/icons/dashboard-icons/disable-download.svg" />
        </div>
      </td>

      <td>
        <div>
          WFO Days : <span class="blue-color">{{ totalWfoDays }}</span>
        </div>
      </td>
    </tr>
  </tbody>
</div>

<div class="container wfo-detail-main">
  <div class="no-wfo-main" *ngIf="!wfoDetailsList || (wfoDetailsList && wfoDetailsList.length == 0)">
    <img class="no-wfo-img" src="\assets\images\no-goal.png" alt="" />
    <div class="no-wfo-assign" *ngIf="!this.useridcheck">
      No Work from Office Record found.
    </div>
  </div>

  <div class="custom-table-container">
    <table class="custom-table" *ngIf="wfoDetailsList && wfoDetailsList.length != 0">
      <thead>
        <tr>
          <th>Date</th>
          <th>Location</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="scrollable-tbody">
        <tr *ngFor="let wfoDetail of wfoDetailsList | orderBy : 'startingDate' : true">
          <td>{{ wfoDetail.startingDate | date : "dd-MM-yyyy" }}</td>
          <td class="blue-color">
            {{ wfoDetail.officeLocation | titlecase | underScoreToSpace }}
          </td>
          <td class="blue-color">
            {{
            wfoDetail.workingFromOfficeStatus === "APPROVED"
            ? "Approved"
            : "Pending"
            }}
          </td>
          <td>
            <button *ngIf="wfoDetail.workingFromOfficeStatus === 'APPLIED'" (click)="openIsWithdrawDetail(wfoDetail)"
              class="withdrawBtn">
              Withdraw
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>