import { TypeOfLeave, ApprovalStatus, PostApprovalLeaveCancellationStatus } from "../services/leaveManagment/leave-enum";

export class LeaveApprovalDTO {
    id: string;
    leaveApplId:any;
    userId: string;
    careerManagerId: string;
    projectManagerId: string;
    typeOfLeave: string;
    startDate: Date;
    endDate: Date;
    startSession: number;
    endSession: number;
    transactionDateTime: number;
    approvalStatus: string;
    approvedBy: string;
    contactNumber: string;
    reasonForLeave: string;
    ccMentions: string[];
    postApprovalLeaveCancellationStatus: string;
    reasonForRejection: string;
    compOffLeaveDetails: any[];
    isAdvancedLeaveApplied: boolean;

    constructor() {
        this.leaveApplId="";
        this.approvalStatus=undefined;
        this.reasonForRejection="";

    }
}