interface IAddressDetails{
    id:string
    address:string
    city:string
    state:string
    pincode:string
    userId:string
}
export class AddressDetails{
public address:string
public city:string
public state:string
public pincode:string
public userId:string
public id:string
public country:string
constructor()
{
this.id = undefined
this.address = ''
this.city = ''
this.state = ''
this.pincode = ''
this.userId = undefined
this.country= ''
}
bind(httpResponse:any){
    this.id = httpResponse && httpResponse.id || undefined
    this.address = httpResponse && httpResponse.address || undefined
    this.city = httpResponse && httpResponse.city || undefined
    this.state= httpResponse && httpResponse.state || undefined
    this.pincode = httpResponse && httpResponse.pincode || undefined  
    this.userId = httpResponse && httpResponse.userId || undefined
    this.country = httpResponse && httpResponse.country || undefined
    return this;     
}
}
