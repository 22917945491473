import { Injectable } from '@angular/core';
import { EmployeeReview } from 'src/app/models/employeeReview';
import { EmployeeReviewUrlsService } from '../restAPI/employeeReviewURLs/employee-review-urls.service';
import { RestAPIService } from '../restAPI/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeReviewService {
  public employeeReviewList: EmployeeReview[];

  constructor(private restApiService: RestAPIService,
    private employeeReviewUrl: EmployeeReviewUrlsService) {

  }

  getEmployeeReviewByCareerManagerIdSubscibe(careerManagerId) {
    
    return this.restApiService.get(
      this.employeeReviewUrl.getByParamUrl(
        { "careerManagerId": careerManagerId }
      )
    )
  }

  getEmployeeReviewByCareerManagerId(careerManagerId) {

    this.restApiService.get(
      this.employeeReviewUrl.getByParamUrl(
        { "careerManagerId": careerManagerId }
      )
    ).subscribe(res => {
      this.setEmployeeReview(res.body)
     
    })
  }

  setEmployeeReview(employeeReviews) {
    this.employeeReviewList = [];
    for (let employeeReview of employeeReviews) {
      this.employeeReviewList.push(new EmployeeReview(employeeReview))
    }
  }

  updateEmployeeReview(payload) {
    this.restApiService.put(
      this.employeeReviewUrl.getBaseUrl(),
      payload
    ).subscribe()
  }

  updateEmployeeReviewSubscribe(payload) {
    return this.restApiService.put(
      this.employeeReviewUrl.getBaseUrl(),
      payload
    )
  }
  getAllEmployee() {
    this.restApiService.get(
      this.employeeReviewUrl.getBaseUrl()
    ).subscribe(res => { this.setEmployeeReview(res.body) })
    
  }

  searchByEmployeeName(searchText) {
    this.restApiService.get(
      this.employeeReviewUrl.getSearchUrl(searchText)
    ).subscribe(res => {
      this.setEmployeeReview(res.body)
      this.employeeReviewList
    }
    )
  }

}
