import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class EmergencycontactUrlService {
  public readonly emergencydetailBaseApi = '/api/emergency-contacts' ;
  private readonly user = '/user';
  private readonly userId_param = 'userId'
  private readonly emer_category = 'emerCategory'
  constructor() { }
  getBaseUrl(){
    return this.emergencydetailBaseApi;
  }
getUserUrll(userId)
{
  return this.emergencydetailBaseApi + this.user + '?' + this.userId_param + '=' + userId
}  
  getUserUrl(category,userId) {
    return this.emergencydetailBaseApi + this.user
      + '?' + this.emer_category + '=' + category+'&'+ this.userId_param + '=' + userId
  }
}
