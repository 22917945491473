<div class="main_header_div">
  <div class="child">

    <h1 class="header_name"><img class="header-icon mr-3" src="assets\icons\about.png">About</h1>
  </div>
  <div class="pr-3 pt-3">
    <img class="close-icon" (click)="closeDialog($event)" src="assets/icons/close.png">
  </div>
</div>
<hr />

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 form_main_div">
      <form [formGroup]="aboutDetails">
        <div class="row">
          <div class="col-md-12 about-input">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>About</mat-label>
              <textarea [spellcheck]="true" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" maxlength="500"
                cdkAutosizeMinRows="7" cdkAutosizeMaxRows="15" formControlName="about"
                style="font-family: nunito"></textarea>
              <mat-hint>
                <label>
                  Remaining characters:
                  {{ 500 - aboutDetails.get("about").value.length }} / 500
                </label>
              </mat-hint>
              <mat-error *ngIf="aboutDetails.get('about').hasError('pattern')">
                Only specified special characters are allowed. e.g. (! () , . "
                - :)
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="submit_button_main">
              <button class="save_Emp_btn" type="submit" (click)="onSubmit()">
                <span>Save</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>