<div class="container-fluid">
  <div class="main_header_div">
    <div class="head">
      <img src="assets/icons/holidays-icons/holiday_configuration.svg" />
      Holiday Configuration
    </div>
    <img class="close-icon-svg" (click)="closeDialog()" src="assets/icons/dashboard-icons/close-pink-icon.svg" />
  </div>
  <div class="row mt-3">
    <div class="col-md-12">
      <form [formGroup]="newLeaveDetails">
        <div class="row">
          <div class="col-md-2 mat-form-field-capabilities">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Year <span class="contentRed"></span></mat-label>
              <mat-select formControlName="year" [disabled]="rowAdded ">
                <mat-option *ngFor="let year of years" [value]="year">
                  {{ year }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="isInvalidControl('year')"> year is required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-3 mat-form-field-capabilities">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Holiday Type <span class="contentRed"></span></mat-label>
              <mat-select formControlName="holidaytype"  [disabled]="rowAdded">
                <mat-option *ngFor="let holiday of holidayType" [value]="holiday">{{ holiday |underScoreToSpace | titlecase   }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3 mat-form-field-capabilities">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Geo Location <span class="contentRed"></span></mat-label>
              <mat-select formControlName="geoLocation"  (selectionChange)="onGeoLocationChange($event.value)" [disabled]="rowAdded">
                <mat-option *ngFor="let geoLocation of this.geoLocations" [value]="geoLocation">
                  {{ geoLocation | titlecase   }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="isInvalidControl('geoLocation')">Geo Location is required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <button (click)=addRow() class="add-holiday">Add row</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 table-1">
      <table class="table">
        <thead>
          <tr>
            <th>
            Base Loction
            </th>
            <th>
              Employment type
            </th>
            <th>
              Date
              </th>
              <th>
                Holiday Name
              </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of tableData; let i = index">
            <td>
              <div class="mat-form-feild-type">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>
                    <span class="common-heading"> Base Location</span>
                  </mat-label>
                  <mat-select [(ngModel)]="row.baseLocation"  multiple>
                    <mat-option *ngFor="let location of locations" name="location" [value]="location">
                      {{ location }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </td>
            <td>
            <div class="mat-form-feild-type">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>
                <span class="common-heading"> Employment Type</span>
              </mat-label>
              <mat-select [(ngModel)]="row.employmentType" multiple>
                <mat-option *ngFor="let employmentType of this.employmentTypes" name="employmentType"  [value]="employmentType">
                  {{ employmentType }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </td>
            <td>
              <div class="mat-form-feild-type">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>
                  <span class="common-heading"> Holiday Date</span>
                </mat-label>
                <input matInput [(ngModel)]="row.date" [matDatepicker]="picker" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              </div>
            </td>
            <td>
              <div class="mat-form-feild-type">
              <mat-form-field appearance="outline"  class="example-full-width">
                <mat-label>
                  <span class="common-heading"> Holiday Name</span>
                </mat-label>
                <input matInput type="text" [(ngModel)]="row.holidayName" required>
              </mat-form-field>
              </div>
              </td>
            <td><button class="remove-btn" (click)="removeRow(i)">Remove</button></td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="submit_button_main">
        <button class="save_btn" type="button" (click)="saveData()">
          <span>Save</span>
        </button>
      </div>
    </div>
  </div>
</div>