import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { EmergencyContact } from 'src/app/models/emergencycontact';
import { EmergencycontactService } from 'src/app/services/emergency-contact/emergencycontact.service';
import { ContactType, EventTabClosed, Relation } from 'src/app/services/user/user-enum';
@Component({
  selector: 'app-emergency-contact',
  templateUrl: './emergency-contact.component.html',
  styleUrls: ['./emergency-contact.component.scss']
})
export class EmergencyContactComponent implements OnInit {
  emergencyContactForm: FormGroup;
  userDto = new UserDTO();
  emergencyContactOne: EmergencyContact = new EmergencyContact();
  emergencyContactTwo: EmergencyContact = new EmergencyContact();
  relation: (string | Relation)[] = [];
  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private emergencycontactservice: EmergencycontactService,
    private dialogRef: MatDialogRef<EmergencyContactComponent>,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
  }
  ngOnInit(): void {
    this.initiateFormAGroup();
    this.processEnumValuesToArray();
    this.loadEmergencyContact(ContactType.Emergencycontactone, 'emergencyContactOne');
    this.loadEmergencyContact(ContactType.Emergencycontacttwo, 'emergencyContactTwo');
  }
  initiateFormAGroup() {
    this.emergencyContactForm = this.fb.group({
      emergencyContactOne: this.fb.group({
        name: ['', [
          Validators.required,
          Validators.pattern('[a-zA-Z ]*')
        ]],
        relationship: ['',
          [Validators.required,
          Validators.pattern('[a-zA-Z -]*')
          ]],
        location: ['', [Validators.required,Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9+#. ,()-]*$')]],
        phoneNumber: ['', [
          Validators.required,
          Validators.pattern(/^[\d\s+-]{7,15}$/),
          Validators.maxLength(15)
        ]]
      }),
      emergencyContactTwo: this.fb.group({
        name: ['',
          [Validators.required,
          Validators.pattern('[a-zA-Z ]*')]
        ],
        relationship: ['',
          [Validators.required,
          Validators.pattern('[a-zA-Z -]*')]
        ],
        location: ['', [Validators.required,Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9+#. ,()-]*$')]],
        phoneNumber: ['', [
          Validators.required,
          Validators.pattern(/^[\d\s+-]{7,15}$/),
          Validators.maxLength(25)
        ]]
      })
    });
  }
  processEnumValuesToArray() {
    for (const relation of Object.values(Relation)) {
      if (isNaN(Number(relation))) { this.relation.push(relation); }
    }
  }
  loadEmergencyContact(contactType: string, contactKey: string): void {
    this.emergencycontactservice.getemerbyuserId(contactType, this.data).subscribe((res) => {
      this[contactKey] = res.body;
      this.emergencyContactForm.get(contactKey).patchValue(this[contactKey]);
    });
  }
  closeDialog(event: Event): void {
    this.dialogRef.close({ event: EventTabClosed.Closed });
  }
  checkError(formControlName: string, parent, errorName: string): boolean {
    const error = this.emergencyContactForm.get(parent).get(formControlName);
    return error ? error.hasError(errorName) : false;
  }
  onSubmit(): void {
    
    if (this.emergencyContactForm.valid) {
      const formData = this.emergencyContactForm.value;
      this.saveEmergencyContact(formData.emergencyContactOne, ContactType.Emergencycontactone, 'emergencyContactOne');
      this.saveEmergencyContact(formData.emergencyContactTwo, ContactType.Emergencycontacttwo, 'emergencyContactTwo');
    } else {
      this.toaster.error('Invalid Details');
    }
  }
  saveEmergencyContact(emergencyContact: EmergencyContact, contactType: string, contactKey: string): void {
    emergencyContact.id = this[contactKey].id;
    emergencyContact.emergencyContactType = contactType;
    emergencyContact.userId = this.data;
    const request = emergencyContact.id ? this.emergencycontactservice.putdocument(emergencyContact)
      : this.emergencycontactservice.postdocument(emergencyContact);
    request.subscribe(() => {
      this.toaster.success('Successfully Saved');
      this.dialogRef.close({ event: EventTabClosed.Closed });
    }, () => {
      this.toaster.error('Error');
    });
  }
}
