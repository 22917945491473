export enum GoalStatus{
	OnTrack = "OnTrack",
    NotStarted = "NotStarted",
    AtRisk = "AtRisk",
    NeedsAttention = "NeedsAttention",
    Closed = "Closed",
}

export enum GoalStatusLabel{
	OnTrack = "On Track",
    NotStarted = "Not Started",
    AtRisk = "At Risk",
    NeedsAttention = "Needs Attention",
    Closed = "Closed",
}

export enum GoalType{
	BuildingTheBusinessIP = "BuildingTheBusinessIP",
    ClientRelations = "ClientRelations",
    Communication = "Communication",
    CoreValues = "CoreValues",
    TeamWorkAndCollaboration = "TeamWorkAndCollaboration",
    TechnicalCapability = "TechnicalCapability",
    TimeManagement = "TimeManagement",
    Compliance = "Compliance",
}

export enum GoalTypeLabel{
    BuildingTheBusinessIp="Building The Business / IP ",
    ClientRelations="Client Relations",
    Communication="Communication ",
    CoreValues="Core Values",
    TeamWorkAndCollaboration="Team Work And Collaboration",
    TechnicalCapability="Technical Capability",
    TimeManagement="Time Management",
    Compliance="Compliance",
    OverallProgress="Overall Progress"
}

export enum Metric{
	Percent = "Percent"
}