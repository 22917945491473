import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configure-menu',
  templateUrl: './configure-menu.component.html',
  styleUrls: ['./configure-menu.component.scss']
})
export class ConfigureMenuComponent implements OnInit {

  selectedOption: string;
  activeSubOption: string = ''; // Add this variable
  constructor() { }

  ngOnInit(): void {
    this.selectedOption = 'optionPerformance';
  }

  onTabChanged($event) {
  }

  toggleOption(option: string) {
    this.selectedOption = option;
    this.activeSubOption = ''; // Clear active sub-option
  }

}
