<div class="container">
  <div class="row">
    <div class="col-md-3 search-bar  mt-3">
      <img class="col-md-2 icon" src="\assets\svg\search_bar.svg" alt="search" />
      <input type="search" class="search-input col-md-10" placeholder="Search Holiday Name" [(ngModel)]="searchTerm"
        (input)="filterHolidays()" />
    </div>
    <div class="btn col-md-2 mt-3">
      <div class="centered-content">
        <button (click)="addNewHoliday()" class="btn-common">
          <img class="img-class-bulk" src="assets/icons/AddNewEmployee.png" />
          <span class="font-size"> Holiday Configuration </span>
        </button>
      </div>
    </div>
    <div class="col-md-2 btn mt-3">
      <div class="centered-content">
        <img class="img-class-bulk" src="assets/icons/AddNewEmployee.png" />
        <button class="btn-common">Bulk Import</button>
      </div>
    </div>
    <div class="col-md-2 mat-form-field-common mt-3">
      <mat-form-field appearance="outline" class="example-full-width">
        <mat-label>Holiday Type <span class="contentRed"></span></mat-label>
        <mat-select formControlName="holidayType" (selectionChange)="onHolidayTypeChange($event)">
          <mat-option *ngFor="let type of this.holidayType" [value]="type">
            {{ type  | underScoreToSpace | titlecase  }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <pagination-controls class="pagination-controls mt-3" (pageChange)="page = $event" previousLabel="<"
      nextLabel=">"></pagination-controls>
  </div>
  <form [formGroup]="newholidayDetails">
  <div class="row mt-2">
    <div class="col-md-2  mat-form-field-common mt-3 ">
      <mat-form-field appearance="outline" class="example-full-width">
        <mat-label>Geo Location <span class="contentRed"></span></mat-label>
        <mat-select formControlName="geoLocation" (selectionChange)="onGeoLocationTypeChange($event)">
          <mat-option *ngFor="let geoLocation of this.geoLocations" [value]="geoLocation">
            {{ geoLocation | titlecase}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 mat-form-field-common mt-3">
      <mat-form-field appearance="outline" mat-form-field-common class="example-full-width">
        <mat-label>Base Location <span class="contentRed"></span></mat-label>
        <mat-select formControlName="baseLocation" (selectionChange)="onBaseLocationTypeChange($event)">
          <mat-option *ngFor="let location of locations" [value]="location">
            {{ location }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 mat-form-field-common mt-3">
      <mat-form-field appearance="outline" mat-form-field-common class="example-full-width">
        <mat-label>Employment Type <span class="contentRed"></span></mat-label>
        <mat-select formControlName="employmentType" (selectionChange)="onEmploymentTypeChange($event)">
          <mat-option *ngFor="let employmentType of this.employmentTypes" [value]="employmentType">
            {{ employmentType | titlecase }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 mat-form-field-common mt-3">
      <mat-form-field appearance="outline" mat-form-field-common class="example-full-width">
        <mat-label>Year <span class="contentRed"></span></mat-label>
        <mat-select formControlName="year" (selectionChange)="onYearChange($event)">
          <mat-option *ngFor="let year of years" [value]="year">
            {{ year }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  </form>
  <div class="mt-3 row">
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th  colspan="4">
              <div class="table-header-cell">
                <span class="name">Date</span>
              </div>
            </th>
            <th  colspan="4">
              <div class="table-header-cell">
                <span class="name">Day</span>
              </div>
            </th>
            <th  colspan="4">
              <div class="table-header-cell">
                <span class="name">Holiday Name</span>
              </div>
            </th>
            <th  colspan="5">
              <div class="table-header-cell">

                <span class="name">Holiday Type</span>
              </div>
            </th>
            <th  colspan="5">
              <span class="name">Employment Type
                </span>
              </th>
            <th  colspan="3" >
              <span class="name">Geolocation</span>
            </th>
            <th  colspan="4">
              <span class="name">
              Base Location
              </span>
            </th>
            <th colspan="2">
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="
            let holiday of this.allHolidays | orderBy : 'date' : 'desc'  | paginate : { itemsPerPage: 5, currentPage: page }
          ">
            <td  colspan="4">
              <label class="data">
                {{ holiday.date| date : "dd-MM-yyyy" }}
                </label>
              </td>
              <td  colspan="4">
                <label class="data">
                   {{holiday.dayName}}
                  </label>
                </td>
            <td  colspan="4">
              <label class="data">
              {{ holiday.leaveDescription | underScoreToSpace | titlecase }}
            </label>
          </td>
            <td  colspan="5">
              <label class="data">{{ holiday.calendarLeaveType | underScoreToSpace | titlecase }}
                </label>
              </td>
            <td  colspan="5">
              <label class="data">{{this.loggedInUser.employmentType | underScoreToSpace | titlecase}}
            </label> 
            </td>
            <td  colspan="3">
              <label class="data">
              {{this.loggedInUser.geoLocation | underScoreToSpace | titlecase}}
              </label> 
            </td>
            <td  colspan="4">
              <label class="data">
              {{this.loggedInUser.baseLocation | underScoreToSpace | titlecase}}
              </label>
            </td>
            <td  colspan="2">
              <label class="data">
                <img src="assets/icons/3dots.png" />
              </label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>