<div class="container">
  <div class="row">
    <div class="col-md-3 search-bar  set-margin">
      <img src="\assets\svg\search_bar.svg" alt="search" />
      <input type="search" class="search-input" placeholder="Search " [(ngModel)]="searchTerm"
        (input)="applySearch()" />
    </div>

    <div class="col-md-2 mat-form-field-capabilities  set-margin">
      <mat-form-field appearance="outline" class="example-full-width">
        <mat-label>
          <img src="\assets\svg\filter-icon.svg" />
          <span class="common-heading"> Capabilities</span>
        </mat-label>
        <mat-select [(ngModel)]="capabilitySearch" (selectionChange)="applySearchForJobTitleAndDepartement()" multiple>
          <mat-option *ngFor="let capability of this.departments" [value]="capability" [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
          matTooltip=" {{ capability }}">
            {{ capability }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-2 mat-form-field-title  set-margin">
      <mat-form-field appearance="outline" class="example-full-width">
        <mat-label>
          <img src="\assets\svg\filter-icon.svg" />
          <span class="common-heading"> Title</span>
        </mat-label>
        <mat-select [(ngModel)]="jobTitleSearch" (selectionChange)="applySearchForJobTitleAndDepartement()" multiple>
          <mat-option *ngFor="let jobTitle of this.jobTitles" [value]="jobTitle">
            {{ jobTitle }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-2 mat-form-field-title  set-margin" *ngIf="hasSuperAdminRights">
      <mat-form-field appearance="outline" class="example-full-width">
        <mat-label>
          <img src="\assets\svg\filter-icon.svg" />
          <span class="common-heading"> Geo Location</span>
        </mat-label>
        <mat-select [(ngModel)] = "geoLocationSearch" (selectionChange)="applySearchForJobTitleAndDepartement()" multiple>
          <mat-option *ngFor="let geoLocation of this.geoLocations" [value]="geoLocation">
            {{ geoLocation}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-.5 download-employee-bulk  set-margin">
      <img src="assets/svg/download.svg" />
    </div>
    <pagination-controls class="pagination-controls mt-3" (pageChange)="page = $event" previousLabel="<"
      nextLabel=">" [maxSize]="4"></pagination-controls>
  </div>
  <div class="mt-3 row">
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th colspan="2" (click)="sortTable('name')">
              <div class="table-header-cell">
                <img src="assets/svg/sort.svg" alt="Double Arrow" class="icon name-sort-icon" />
                <span class="name">Name</span>
              </div>
            </th>
            <th colspan="1" (click)="sortTable('employeeId')">
              <div class="table-header-cell">
                <img src="assets/svg/sort.svg" alt="Double Arrow" class="icon" />
                <span class="name">ID</span>
              </div>
            </th>
            <th (click)="sortTable('jobTitle')">
              <div class="table-header-cell">
                <img src="assets/svg/sort.svg" alt="Double Arrow" class="icon" />
                <span class="name">Title</span>
              </div>
            </th>
            <th colspan="2" (click)="sortTable('department')">
              <div class="table-header-cell">
                <img src="assets/svg/sort.svg" alt="Double Arrow" class="icon" />
                <span class="name">Capability</span>
              </div>
            </th>

            <th colspan="2">Email ID</th>
            <th></th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="
                let activeUser of this.filteredUsers
                  | paginate : { itemsPerPage: 10, currentPage: page }
              ">
            <td colspan="2">
              <div class="name-container">
                <div *ngIf="activeUser.userImageThumbnail === null || activeUser.userImageThumbnail === ''">
                  <img class="noemployee_img" src="assets/icons/default-avatar.jpg" alt="" />
                </div>
                <div *ngIf="activeUser.userImageThumbnail !== ''">
                  <img class="employee_img" [(src)]="activeUser.userImageThumbnail" alt="img" />
                </div>
                {{ activeUser.name }}
              </div>
            </td>
            <td colspan="1"><label class="data">{{ activeUser.employeeId }}</label></td>
            <td><label class="data">{{  this.getEnumValueByKey(this.jobTitle, activeUser.jobTitle)}}</label></td>
            <td colspan="2"><label class="data">{{ this.getEnumValueByKey(this.department,activeUser.department)  }}</label></td>
            <td colspan="2"><label class="data">{{ activeUser.email }}</label></td>
            <td class="threedots-main">
              <label class="data">
                <img src="assets/svg/3dots.svg" [matMenuTriggerFor]="employeeMenu" class="threedots" />

                <mat-menu class="matmenu" #employeeMenu="matMenu" xPosition="before">
                  <button mat-menu-item class="transform-none" (click)="viewEmployeeBalance(activeUser)">
                    <span class="bold">View</span>
                  </button>
                  <button mat-menu-item class="transform-none" (click)="configureEmployeeLeave(activeUser)">
                    <span class="bold">Configure</span>
                  </button>
                </mat-menu>
              </label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>