import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { Departments, ExcelSheetName, JobTitles } from 'src/app/services/user/user-enum';
import { UserService } from 'src/app/services/user/user.service';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { FormControl, FormGroup } from '@angular/forms';
import { TooltipPosition } from '@angular/material/tooltip';
import { TypeOfLeave } from 'src/app/services/leaveManagment/leave-enum';
import { AbsentService } from 'src/app/services/absent/absent.service';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-unmarked-employee',
  templateUrl: './unmarked-employee.component.html',
  styleUrls: ['./unmarked-employee.component.scss']
})
export class UnmarkedEmployeeComponent implements OnInit {
  activeUser: UserDTO[] = []
  leaveTypes = [];
  departments = [];
  jobTitles = [];
  department = Departments;
  jobTitle = JobTitles;
  page: number = 1;
  searchTerm: string = '';
  isSpinner = true;
  filteredUsers: UserDTO[] = [];
  searchForm: FormGroup;
  capabilitySearch: Array<string> = [];
  jobTitleSearch: Array<string> = [];
  leaveType: Array<string> = [];
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  currentSortName: { column: string, direction: 'asc' | 'desc' } = { column: 'name', direction: 'asc' };
  currentSortEmployeeId: { column: string, direction: 'asc' | 'desc' } = { column: 'employeeId', direction: 'asc' };
  currentSortJobTitle: { column: string, direction: 'asc' | 'desc' } = { column: 'jobTitle', direction: 'asc' };
  currentSortDepartment: { column: string, direction: 'asc' | 'desc' } = { column: 'department', direction: 'asc' };
  fromDate: Date;
  toDate: Date;
  currentSort: { column: string, direction: 'asc' | 'desc' } = { column: 'name', direction: 'asc' };
  constructor(private userService: UserService, private dialog: MatDialog, private router: Router, private absentService: AbsentService, private cdr: ChangeDetectorRef
  ) {

  }
  ngOnInit(): void {
    this.processEnumValuesToArray()
    this.getUnmarkedEmployee()
  }
  getUnmarkedEmployee() {
    this.isSpinner = true;
    let currentYear = new Date().getFullYear();
    this.absentService.getUnmakedEmployee(currentYear).subscribe(res => {
      for (let i = 0; i < res.body.length; i++) {
        res.body[i].user.createdDateTime = res.body[i].createdDateTime;
        this.filteredUsers.push(res.body[i].user);
      }
      this.isSpinner = false;
      this.filteredUsers.forEach(element => {
        element.name = element.firstName + " " + element.lastName;
        if (element.userImageThumbnail !== '') {
          element.userImageThumbnail = 'data:image/png;base64,' + element.userImageThumbnail;
        }
        this.activeUser = this.filteredUsers;
        this.userService.getEmployeeById(element.careerManagerId).subscribe(res => {
          element.carrierManagerName = res.body.firstName + ' ' + res.body.lastName;
          // Trigger change detection manually after modifications
          this.cdr.detectChanges();
        });
      });
    }, error => {
      this.isSpinner = false;
    });
  }

  ifOverflow(e) {
    return e.scrollWidth > e.clientWidth;
  }
  applySearch() {
    this.capabilitySearch = [];
    this.jobTitleSearch = [];
    this.fromDate = null;
    this.toDate = null;
    let filteredUsers = this.activeUser.filter(user => {
      const nameMatch = user.firstName.toLowerCase().includes(this.searchTerm.toLowerCase()) || user.lastName.toLowerCase().includes(this.searchTerm.toLowerCase());
      const carrierManagerMatch =
        (user.carrierManagerName?.toLowerCase() ?? '').includes(this.searchTerm.toLowerCase());

      return nameMatch || carrierManagerMatch;
    });

    filteredUsers.sort((a, b) => {
      const aNameMatch = a.firstName.toLowerCase().includes(this.searchTerm.toLowerCase()) || a.lastName.toLowerCase().includes(this.searchTerm.toLowerCase());
      const bNameMatch = b.firstName.toLowerCase().includes(this.searchTerm.toLowerCase()) || b.lastName.toLowerCase().includes(this.searchTerm.toLowerCase());

      if (aNameMatch && !bNameMatch) {
        return -1;
      } else if (!aNameMatch && bNameMatch) {
        return 1;
      } else {
        return 0;
      }
    });
    this.filteredUsers = filteredUsers;
  }
  applySearchForJobTitleAndDepartement() {
    let filteredUsers = this.activeUser.filter(user => {

      const departmentKeys = this.capabilitySearch.map(capability => this.getDepartmentKeyByValue(capability));
      const jobTitleKeys = this.jobTitleSearch.map(jobTitle => this.getJobTitleKeyByValue(jobTitle));

      const departmentMatch = departmentKeys.length === 0 || departmentKeys.includes(user.department);
      const jobTitleMatch = jobTitleKeys.length === 0 || jobTitleKeys.includes(user.jobTitle);
      return departmentMatch && jobTitleMatch;
    });
    this.filteredUsers = filteredUsers;
  }
  processEnumValuesToArray() {
    for (const jobTitle of Object.values(JobTitles)) {
      if (isNaN(Number(jobTitle))) {
        this.jobTitles.push(jobTitle);
      }
    }

    for (const department of Object.values(Departments)) {
      if (isNaN(Number(department))) {
        this.departments.push(department);
      }
    }
    for (const leaveType of Object.values(TypeOfLeave)) {
      if (isNaN(Number(leaveType))) {
        this.leaveTypes.push(leaveType);
      }
    }
  }
  getDepartmentKeyByValue(value: string): string | undefined {
    return Object.keys(Departments).find(key => Departments[key] === value);
  }
  getLeaveTypeKeyByValue(value: string): string | undefined {
    return Object.keys(TypeOfLeave).find(key => TypeOfLeave[key] === value);
  }
  getJobTitleKeyByValue(value: string): string | undefined {
    return Object.keys(JobTitles).find(key => JobTitles[key] === value);
  }

  sortTable(column: string): void {
    switch (column) {
      case 'name':
      case 'employeeId':
      case 'jobTitle':
      case 'department':
      case 'carrierManagerName':
      case 'email':
        this.sortByColumn(column);
        break;
    }
  }

  private sortByColumn(column: string): void {
    const currentSort = this.getCurrentSortForColumn(column);

    if (currentSort.column === column) {
      currentSort.direction = currentSort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      currentSort.column = column;
      currentSort.direction = 'asc';
    }

    this.updateCurrentSortForColumn(column, currentSort);

    this.filteredUsers.sort((a, b) => {
      const aValue = a[column] ? a[column].toLowerCase() : null;
      const bValue = b[column] ? b[column].toLowerCase() : null;

      if (aValue === null && bValue === null) {
        return 0;
      } else if (aValue === null) {
        return 1;
      } else if (bValue === null) {
        return -1;
      } else {
        return currentSort.direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
    });
  }

  private getCurrentSortForColumn(column: string): { column: string, direction: 'asc' | 'desc' } {
    switch (column) {
      case 'name':
        return this.currentSortName;
      case 'employeeId':
        return this.currentSortEmployeeId;
      case 'jobTitle':
        return this.currentSortJobTitle;
      case 'department':
        return this.currentSortDepartment;
      default:
        return { column: '', direction: 'asc' };
    }
  }

  private updateCurrentSortForColumn(column: string, currentSort: { column: string, direction: 'asc' | 'desc' }): void {
    switch (column) {
      case 'name':
        this.currentSortName = currentSort;
        break;
      case 'employeeId':
        this.currentSortEmployeeId = currentSort;
        break;
      case 'jobTitle':
        this.currentSortJobTitle = currentSort;
        break;
      case 'department':
        this.currentSortDepartment = currentSort;
        break;
    }
  }
  exportToExcel(): void {
    const fileName = ExcelSheetName.AbsentEmployee
    const header = ['Name', 'Employee ID', 'Gender', 'DOB', 'Marital Status', 'Mobile number', 'Alternate number', 'Job Title', 'Capability', 'Career Manager', 'Email', 'Geo location', 'Base Location'];

    const data = this.filteredUsers.map(user => ({
      'Name': user.firstName + " " + user.lastName,
      'Employee ID': user.employeeId,
      'Gender': user.gender,
      'DOB': formatDate(user.dateOfBirth, 'yyyy-MM-dd', 'en-US'),
      'Marital Status': user.relationshipStatus,
      'Mobile number': user.mobileNumber,
      'Alternate number': user.alternateMobileNumber,
      'Job Title': JobTitles[user.jobTitle],
      'Capability': Departments[user.department],
      'Geo location': user.geoLocation,
      'Base Location': user.baseLocation,
      'Career Manager': user.carrierManagerName,
      'Email': user.email
    }));

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, { header });
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  }
  getEnumValueByKey(type, key: string): any {
    return type[key];

  }
  applyFilter(): void {
    const fromDate = new Date(this.fromDate);
    const toDate = new Date(this.toDate);
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(0, 0, 0, 0);
    if (!isNaN(fromDate.getTime()) && !isNaN(toDate.getTime())) {
      this.filteredUsers = this.activeUser.filter(user => {
        const userDate = new Date(user.createdDateTime);
        userDate.setHours(0, 0, 0, 0);
        return userDate >= fromDate && userDate <= toDate;
      });
    } else {
      console.error('Invalid date input');
    }
  }
  getMinEndDate() {
    return this.fromDate; 
  }

}
