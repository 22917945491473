import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { NotificationUrlService } from '../restAPI/notificationURLs/notification-url.service';
import { RestAPIService } from '../restAPI/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private messageService:MessageService,
    private restApiService: RestAPIService,
    private notificationUrl: NotificationUrlService
    ) { }

  sucess(message){
    this.messageService.add(
      {
        severity: 'success',
        summary: 'Success',
        detail: message
      }
    )
    
    
  }

  info(message){
    this.messageService.add(
      {
        severity: 'info',
        summary: 'Info',
        detail: message
      }
    )
    
    
  }

  warn(message){
    this.messageService.add(
      {
        severity: 'warn',
        summary: 'Warning',
        detail: message
      }
    )
    
    
  }

  error(message){
    this.messageService.add(
      {
        severity: 'error',
        summary: 'Error',
        detail: message
      }
    )
    
    
  }

  invalidFormData(){
    this.warn("Invalid Form Data Entry")
  }

  createNotificationForRTF(notificationDto){
    return this.restApiService.post(
      this.notificationUrl.createNotification(),
      notificationDto
    )
  }

  getAllNotifications(){
    return this.restApiService.get(
      this.notificationUrl.getAllNotification()
    )
  }

  clearAllNotifications(){
    return this.restApiService.get(
      this.notificationUrl.clearAllNotification()
    )
  }

  clearSingleNotification(id){
    return this.restApiService.get(
      this.notificationUrl.clearSingleNotification(id)
    )
  }

  getCounterOfNotification(){
    return this.restApiService.get(
      this.notificationUrl.getCounterOfNotification()
    )
  }
}
