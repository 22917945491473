import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FeedbacknotesUrlService {

  private readonly feedbackNotesDetailsBaseApi = '/api/feedbackNotes';
  private readonly probationFeedbackNotesDetailsBaseApi = '/api/probation/feedbackNotes';
  private readonly user = '/user';
  private readonly update = '/update';
  private readonly userId_param = 'userId';
  private readonly getFeedbackSummery = '/api/feedbacks/getFeedbackSummary'
  private readonly getProbationFeedbackSummery = '/api/probation/feedbacks/getFeedbackSummary'
  private readonly getAllYearsFeedbackSummary1 = '/api/feedbacks/getAllYearsFeedbackSummary'


  constructor() { }

  getBaseUrl() {
    return this.feedbackNotesDetailsBaseApi;
  }
  getProbationBaseUrl() {
    return this.probationFeedbackNotesDetailsBaseApi;
  }
  updatenote() {
    return this.feedbackNotesDetailsBaseApi + this.update;

  }
  updateProbationNote() {
    return this.probationFeedbackNotesDetailsBaseApi + this.update;

  }
  getnotesbyuserandyear(userId, year) {
    return this.feedbackNotesDetailsBaseApi + '/' + userId + '/' + year
  }
  getProbationnotesbyuserandyear(userId, year) {
    return this.probationFeedbackNotesDetailsBaseApi + '/' + userId + '/' + year
  }

  getFeedbackSummary(userId, year) {

    return this.getFeedbackSummery + '/' + userId + '/' + year
  }

  // for probation
  getProbationFeedbackSummary(userId, year) {

    return this.getProbationFeedbackSummery + '/' + userId
  }


  getAllYearsFeedbackSummary(userId) {

    return this.getAllYearsFeedbackSummary1 + '/' + userId
  }
  getByFieldUrl(field) {
    return this.feedbackNotesDetailsBaseApi + this.getByField(field);
  }
  private getByField(field) {
    return '/' + field;
  }


}

