import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AddHolidaysURLsService {
  private readonly updateLeave  = '/api/single-import-leaves';
  private readonly editLeave = '/api/update-leaves-by-ID';
  private readonly addHolidays = '/api/holiday';

  constructor() { }
  getBaseUrl(){
    return this.updateLeave;
  }

  updateLeavesByYear(year){
    return this.updateLeave;
  }

  addHoliday(){
 return this.addHolidays;
  }
  getHoliday(year,geo,employment , baseLocation){
    return this.addHolidays + '/' + year + '/' + geo + '/' + employment + '/' + baseLocation;
  }

  editLeavesById(year,Id){
    return this.editLeave + '/' + year + '/' + Id ;
  }
}
