export enum TypeOfLeave {
    PLANNED_LEAVE = "Planned Leave",
    ACCRUED_LEAVE = "Accured leave",
    UNPLANNED_LEAVE = "Unplanned Leave",
    FLOATER_LEAVE = "Floater Leave",
    COMP_OFF = "Comp Off",
    MATERNITY_LEAVE = "Maternity Leave",
    PATERNITY_LEAVE = "Paternity Leave",
    SPECIAL_LEAVE = "Special Leave",
    CONTINGENCY_LEAVE = "Contingency Leave",
    LOSS_OF_PAY = "Loss Of Pay",
}

export enum ApprovalStatus {
    PENDING = "PENDING",
    APPROVED = "Approved",
    REVOKED_BY_USER = "Revoked By User",
    REVOKE_REQUEST_BY_USER = "Revoke Request By User",
    REVOKE_REQUEST_REJECTED = "Revoke Request Rejected",
    REJECTED = "REJECTED",
}

export enum PostApprovalLeaveCancellationStatus {
    PENDING = "PENDING",
    APPROVED = "Approved",
    REJECTED = "Rejected",
}

export enum FirstnSecondHalf {
    FIRST_HALF = "First Half",
    SECOND_HALF = "Second Half",
}