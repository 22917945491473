
<div class="container-fluid">
    <div class="main_header_div">
    <div class="head">
      <img src="assets/icons/holidays-icons/holiday_configuration.svg" />
      Add Client Holiday
    </div>
    <img class="close-icon-svg" (click)="closeDialog()" src="assets/icons/dashboard-icons/close-pink-icon.svg" />
  </div>
  <div class="row mt-3">
    <div class="col-md-12 table-1">
      <table class="table">
        <thead>
          <tr>
            <th>
          Client Name
            </th>
            <th>
        Project Name
            </th>
            <th>
          Date
              </th>
              <th>
              Holiday Name
                    </th>
          </tr>
        </thead>
        <tbody>
            <tr *ngFor="let client of clientDetails; let i = index">
              
              <td>{{client.clientName}}</td>
                <td>{{client.projectName}}</td>
              <td>
               {{client.date}}
              </td>
              <td> 
                {{client.holidayName}}
            </td>
            </tr>
          </tbody>
    </table>
  </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-12 table-1">
      <table class="table">
        <thead>
          <tr>
            <th>
          Employee Name
            </th>
            <th>
         Employee Id
            </th>
            <th>
           Project Name
              </th>
          </tr>
        </thead>
        <tbody>
              <tr *ngFor="let member of projectDetails">
                <td>{{ member.talentName }}</td>
                <td>{{ member.userId }}</td>
                <td>{{ member.projectName }}</td>
              </tr>
          </tbody>
          
    </table>
    </div>
  </div>
  <div>
    <button class="blue-button">Back</button>
    <button class="blue-button" (click)="configureData()">Configure</button>
  </div>
  </div>

