import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { User } from 'src/app/models/user';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { PreviousEmploymentService } from 'src/app/services/previousEmployment/previous-employment.service';
import { PreviousEmployment } from 'src/app/models/previousEmployment';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EventTabClosed } from 'src/app/services/user/user-enum';
@Component({
  selector: 'app-employment-history',
  templateUrl: './employment-history.component.html',
  styleUrls: ['./employment-history.component.scss'],
})
export class EmploymentHistoryComponent implements OnInit {
  @ViewChild('fileInput') fileInput: File;
  preEmployment: FormArray;
  editSpotlightDetails: FormGroup;
  today = new Date();
  removedPreviousemployment: String[] = [];
  noOnlySpecialCharactersValidator(): ValidatorFn {
    const onlySpecialCharsRegex = /^[!#$%^&*(),.<>?":@?\{}|<_ /]+$/;
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value as string;
      if (onlySpecialCharsRegex.test(value)) {
        return { noOnlySpecialCharacters: true };
      }
      return null;
    };
  }
  constructor(
    private toaster: ToastrService,
    private fb: FormBuilder,
    private empservice: PreviousEmploymentService,
    private dialogRef: MatDialogRef<EmploymentHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) private data: User
  ) {
  }
  ngOnInit(): void {
    this.initiateComponent();
  }
  initiateComponent() {
    this.editSpotlightDetails = this.fb.group(
      {
        previousEmployment: this.fb.array([]),
      },
      {
        validator: this.dateOverlapValidator,
      }
    );
    this.empservice.getprevempByUserIdSubscribe(this.data).subscribe((res) => {
      if (res.body[0] == null) {
        this.addPreEmployment();
      }
      this.setPrevempFormArray(res.body);
    });
  }
  getMinEndDate(i) {
    var endDate = this.editSpotlightDetails
      .get('previousEmployment')
    ['controls'][i].get('startDate').value;
    return endDate;
  }
  createpreEmp() {
    let formGroup = this.fb.group(new PreviousEmployment());
    formGroup.controls.comapanyName.setValidators([
      Validators.required,
      this.noOnlySpecialCharactersValidator(),
      Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9+#. -]*$')
    ]);
    formGroup.controls.jobTitle.setValidators([
      Validators.required,
      this.noOnlySpecialCharactersValidator(),
      Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9+#. -]*$')
    ]);
    formGroup.controls.startDate.setValidators([Validators.required]);
    formGroup.controls.endDate.setValidators([Validators.required]);
    return formGroup;
  }
   processDate(previousEmployment): string {
    let experience = '';
    const startDate = previousEmployment.controls.startDate.value;
    const endDate = previousEmployment.controls.endDate.value;
    if (startDate && endDate) {
        const diffInMs = endDate.getTime() - startDate.getTime();
        const days = diffInMs / (1000 * 60 * 60 * 24);
        const years = Math.floor(days / 365);
        const months = Math.floor((days % 365) / 30);
        experience = years > 0 ? `${years} Year` : '';
        experience += months > 0 ? (experience ? ' ' : '') + `${months} Month` : '';
        if (months < 1 && years < 1) {
            experience = '< 1 Month';
        }
    }
    return experience;
}
  getMonthFromDays(days) {
    return Math.floor((days % 365) / 30);
  }
  getYearFromDays(days) {
    return Math.floor(days / 365);
  }
  checkError = (controlName: string, errorName: string) => {
    return this.editSpotlightDetails.controls[controlName].hasError(errorName);
  };
  
  setPrevempFormArray(response) {
    let data: number;
    this.preEmployment = this.editSpotlightDetails.get(
      'previousEmployment'
    ) as FormArray;
    let empList: PreviousEmployment[] =
      this.empservice.setPrevempResponse(response);
    for (let s of empList) {
      let formGroup: FormGroup = this.fb.group(s);
      formGroup.controls.comapanyName.setValidators([
        Validators.required,
        this.noOnlySpecialCharactersValidator(),
        Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9+#. -]*$')
      ]);
      formGroup.controls.jobTitle.setValidators([
        Validators.required,
        this.noOnlySpecialCharactersValidator(),
        Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9+#. -]*$')
      ]);
      formGroup.controls.startDate.setValidators([Validators.required]);
      formGroup.controls.endDate.setValidators([Validators.required]);
      formGroup.controls.experiencemonth.setValue(this.processDate(formGroup));
      this.preEmployment.insert(0,formGroup);
    }
  }
  addEvent(type: string, previousEmployment: FormGroup) {
    previousEmployment.controls.experiencemonth.setValue(
      this.processDate(previousEmployment)
    );
  }
  PreviousEmp(): FormArray {
    return this.editSpotlightDetails.get('previousEmployment') as FormArray;
  }
  addPreEmployment() {
    this.preEmployment = this.editSpotlightDetails.get(
      'previousEmployment'
    ) as FormArray;
    this.preEmployment.insert(0, this.createpreEmp());
  }
  removePreEmployment(id, i) {
    this.removedPreviousemployment.push(id);
    this.PreviousEmp().removeAt(i);
  }
  //Previous Employeement Details Form ends
  closeDialog(event: Event) {
    this.dialogRef.close({ event: EventTabClosed.Closed });
  }
  dateOverlapValidator: ValidatorFn = (
    control: AbstractControl
  ): { [key: string]: any } | null => {
    const employmentArray = control.get('previousEmployment') as FormArray;
    const employmentRecords = employmentArray.controls.map(
      (group: FormGroup) => group.value
    );
    let hasOverlap = false; // Flag to track if any overlap is found
    for (let i = 0; i < employmentRecords.length; i++) {
      for (let j = i + 1; j < employmentRecords.length; j++) {
        const record1 = employmentRecords[i];
        const record2 = employmentRecords[j];
        const startDate1 = new Date(record1.startDate);
        const endDate1 = new Date(record1.endDate);
        const startDate2 = new Date(record2.startDate);
        const endDate2 = new Date(record2.endDate);
        if (startDate1 <= endDate2 && endDate1 >= startDate2) {
          // There is an overlap between record1 and record2 for either Start Dates or End Dates
          if (startDate1 <= endDate2 && endDate1 >= startDate2) {
            // Overlap for Start Dates
            employmentArray.controls[i]
              .get('startDate')
              .setErrors({ dateOverlap: true });
            employmentArray.controls[j]
              .get('startDate')
              .setErrors({ dateOverlap: true });
            hasOverlap = true;
          }
          if (startDate1 <= endDate2 && endDate1 >= startDate2) {
            // Overlap for End Dates
            employmentArray.controls[i]
              .get('endDate')
              .setErrors({ dateOverlap: true });
            employmentArray.controls[j]
              .get('endDate')
              .setErrors({ dateOverlap: true });
            hasOverlap = true;
          }
        } else {
          if (
            employmentArray.controls[i].get('startDate').hasError('dateOverlap')
          ) {
            employmentArray.controls[i].get('startDate').setErrors(null);
          }
          if (
            employmentArray.controls[j].get('startDate').hasError('dateOverlap')
          ) {
            employmentArray.controls[j].get('startDate').setErrors(null);
          }
          if (
            employmentArray.controls[i].get('endDate').hasError('dateOverlap')
          ) {
            employmentArray.controls[i].get('endDate').setErrors(null);
          }
          if (
            employmentArray.controls[j].get('endDate').hasError('dateOverlap')
          ) {
            employmentArray.controls[j].get('endDate').setErrors(null);
          }
        }
      }
    }
    if (hasOverlap) {
      return { dateOverlap: true };
    }
    return null; // No overlaps found
  };
  onSubmit() {
    let isPopUp = false;
    if (this.editSpotlightDetails.valid) {
      for (let prevemp of this.editSpotlightDetails.value.previousEmployment) {
        if (
          prevemp &&
          !prevemp.id &&
          prevemp.comapanyName != '' &&
          prevemp.jobTitle != '' &&
          prevemp.startDate != undefined &&
          prevemp.endDate != undefined
        ) {
          prevemp.userId = this.data;
          this.empservice.create(prevemp).subscribe((res) => {
            this.empservice.setexperience('refresh_experience');
            this.dialogRef.close({ event: EventTabClosed.Closed });
            if (!isPopUp) {
              this.toaster.success('Successfully Saved');
              isPopUp = true;
            }
          });
        } else if (
          prevemp &&
          prevemp.id &&
          prevemp.comapanyName != '' &&
          prevemp.jobTitle != '' &&
          prevemp.startDate != undefined &&
          prevemp.endDate != undefined
        ) {
          this.empservice.update(prevemp).subscribe((res) => {
            this.empservice.setexperience('refresh_experience');
            this.dialogRef.close({ event: EventTabClosed.Closed });
            if (!isPopUp) {
              this.toaster.success('Successfully Saved');
              isPopUp = true;
            }
          });
        } else {
        }
      }
    }
    if (this.removedPreviousemployment.length > 0) {
      for (let i in this.removedPreviousemployment) {
        this.empservice.delete(this.removedPreviousemployment[i]);
        this.dialogRef.close({ event: EventTabClosed.Closed });
      }
    }
  }
}
