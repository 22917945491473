import { RegularizeDates } from "../models/RegularizeDates";

export class ApplyRegularizationDTO {
    public id: String;
    public userId: String;
    public reasonForRegularization: String;
    public createdDateTime: Date;
    public regularizeDates: RegularizeDates[];
    public appliedForRegularization:boolean;


    constructor() {
        this.userId = "";
        this.reasonForRegularization = "";
        this.appliedForRegularization=false;
    }
}