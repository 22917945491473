export class AssetDTO {
    public assetID: String;
    public assetName: String;
    public assetDescription: String;
    public assetLocation: String;
    public assetCategory: String;
    public purchasedOn: Date;
    public assetCondition: String;
    public assetStatus: String;
    public unavailabilityReason: String;
    public employeeId: String;
    public assetAssignedDate: Date;
    public id: string;
    public assetHistory: Set<any>;
    public assetPrimaryImage: String;
    public assetSecondaryImage1: String;
    public assetSecondaryImage2: String;
    public assetSecondaryImage3: String;
    public assetSecondaryImage4: String;

    constructor() {
        this.assetID = "";
        this.assetName = "";
        this.assetDescription = "";
        this.assetLocation = "";
        this.assetCategory = "";
        this.purchasedOn = null;
        this.assetCondition = "";
        this.assetStatus = "";
        this.unavailabilityReason = "";
        this.employeeId = "";
        this.assetAssignedDate = null;
        this.id = "";
        this.assetHistory = null;
        this.assetPrimaryImage = "";
        this.assetSecondaryImage1 = "";
        this.assetSecondaryImage2 = "";
        this.assetSecondaryImage3 = "";
        this.assetSecondaryImage4 = "";
    }
}