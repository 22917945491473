<div class="container-fluid mt-4">
  <div class="row">
    <div class="col-md-12 close-icon">
      <mat-icon (click)="matDialogClose()">close</mat-icon>
    </div>
  </div>
  <img class="main-card-destop" src="\assets\icons\Image close.png" />

  <div class="row main_container">
    <div class="col-md-12">
      <h1 class="confirmation_text">Are you sure ?</h1>
      <h3 class="confirmation_text-2">
        Do you really want to delete these records? This process cannot be
        undone.
      </h3>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="main_button_div">
        <button mat-button class="cancel-btn ml-3" (click)="matDialogClose()">
          Cancel
        </button>
        <button mat-button class="delete-button" (click)="deleteForm()">
          Delete
        </button>
      </div>
    </div>
  </div>
</div>
