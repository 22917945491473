import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationUrlService {
  private readonly notificationBaseUrl = '/api/notifications';
 

  constructor() { }

  createNotification() {
    return this.notificationBaseUrl + '/create-notification';
  }
  getAllNotification() {
    return this.notificationBaseUrl + '/mark-seen';
  }

  getCounterOfNotification() {
    return this.notificationBaseUrl + '/created';
  }

  clearAllNotification() {
    return this.notificationBaseUrl + '/clear-all';
  }

  clearSingleNotification(id) {
    return this.notificationBaseUrl + '/clear/' + id;
  }


}
