import { Injectable } from '@angular/core';
import { Talent } from 'src/app/models/talent';
import { RestAPIService } from '../restAPI/rest-api.service';
import { TalentUrlService } from '../restAPI/talentURLs/talent-url.service';
import { UserService } from '../user/user.service';
@Injectable({
  providedIn: 'root'
})
export class TalentService {
  constructor(private restApiService: RestAPIService,
    private userService: UserService,
    private talenturl: TalentUrlService) { }
  create(payload) {
    return this.restApiService.post(
      this.talenturl.getBaseUrl(),
      payload
    )
  }
  update(payload) {
    return this.restApiService.put(
      this.talenturl.getBaseUrl(),
      payload
    )
  }
  gettalentbyuserIdandprojectid(project,user){
    return this.restApiService.get(
      this.talenturl.gettalentUrl(project,user)
    )
  }
  getTalentByProjectIdSubscribe(projectId) {
    return this.restApiService.get(
      this.talenturl.getProjectUrl(projectId)
    )
  }
  getTalentByUserIdSubscribe(userId) {
    return this.restApiService.get(
      this.talenturl.getUserIdUrl(userId)
    )
  }
  validateEmployeeId(employeeId,projectId) {
    return this.restApiService.get(this.talenturl.getValidateEmployeeIdUrl(employeeId,projectId));
  }
  setTalentResponse(response): Talent[] {
    let talentList: Talent[] = []
    for (let data of response) {
      let talent: Talent = new Talent()
      talentList.push(
        talent.bind(data)
      )
    }
    return talentList
  }
  delete(id: any) {
    this.restApiService.delete(
      this.talenturl.getByFieldUrl(id)
    ).subscribe(res => {
      
    })
  }
  getProjectManagerEmails(){
    return this.restApiService.get(
      this.talenturl.getProjectManagerEmailsUrl()
    )
  }
}
