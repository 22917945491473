<div class="col-md-12">
    <mat-card class="matcardprofiledetail ">
        <mat-card-content>
            <div class="row ">
                <div class=" coloums ">
                    <img src="assets/icons/default-avatar.jpg" class="img-container">
                </div>
                <div class=" col-md-2">
                    <h5 class="reportee-category"> {{this.userdto3.firstName + ' ' + this.userdto3.lastName}}</h5>
                    <p class="project-name"> ID {{this.userdto3.employeeId}} </p>
                </div>
                <div class="col">
                    <label class="asset-common">Reportee </label>
                    <label class="count blue"> {{this.directReportees}}</label>
                </div>
                <div class="col">
                    <label class="asset-common">Open </label>
                    <label class="count green"> {{this.openCount}}</label>
                </div>
                <div class="col">
                    <label class="asset-common">Closed </label>
                    <label class="count red"> {{this.closeCount}}</label>
                </div>
                <div class="col">
                    <label class="asset-common ">Not Initiated </label>
                    <label class="count yellow"> {{this.notInitiatedCount}}</label>
                </div>
                <div class="left-margin">
                    <div class="asset-common ">
                        Year
                    </div>

                    <div>
                        <mat-select class="year" [(value)]="selected" (selectionChange)="loadForm($event)">
                            <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                        </mat-select>
                    </div>

                </div>


            </div>
        </mat-card-content>
    </mat-card>
</div>
<div class="col-md-12">
    <div class=" content"> <mat-tab-group class="tab-group">
            <mat-tab class="mat-tab-label-active">
                <ng-template mat-tab-label>
                    <img src="assets/icons/reportee.png">
                    Reportees
                </ng-template>

                <ng-container *ngIf="this.nodata == true; else elseTemplate">
                    <div class="nothing-data">
                        <img class="no-reportee-img" src="assets/no-data-project.png">
                    </div>
                </ng-container>
                <ng-template #elseTemplate>
                    <div>
                        <div class="card-container ">
                            <div *ngFor="let directreportee of this.userdto2 | filter:searchText  | paginate: { id:'directreportee_id', itemsPerPage: itemsPerPage, currentPage: p } "
                                class="col-xl-3 col-lg-4 col-md-4 col-sm-6"
                                (click)="onViewReportee(directreportee.userId)">
                                <div class="matcardprojectdetail">

                                    <div class="" style="display: flex;">
                                        <div>
                                            <img src="assets/icons/default-avatar.jpg" class="img-container">
                                        </div>
                                        <div class="name-div">
                                            <div class="emp-name">
                                                <label>
                                                    {{directreportee.firstName }}
                                                    {{directreportee.lastName}}
                                                </label>
                                            </div>
                                            <div class="emp-id">
                                                <label class="">

                                                    ID {{directreportee.employeeId}}
                                                </label>
                                            </div>
                                        </div>

                                    </div>

                                    <div class=" coloums-div color">
                                        <div class="col-3 project-name">
                                            Score
                                        </div>

                                        <div class="col-3 count blue ">
                                            {{directreportee.totalScore | number:'1.2-2'}}/5
                                        </div>
                                    </div>
                                    <div class=" coloums-div ">
                                        <div class="col project-name">
                                            Feedback Groups
                                        </div>

                                        <div class="col-3 count blue ">
                                            {{directreportee.feedbackGroupCount
                                            }}

                                        </div>

                                    </div>
                                    <div class=" coloums-div ">
                                        <div class="col project-name">
                                            Submitted Feedback
                                        </div>

                                        <div class="col-3 count blue ">
                                            {{directreportee.submittedFeedbackCount
                                            }}/{{directreportee.feedbackForms}}

                                        </div>

                                    </div>

                                    <div class="coloums-div color">
                                        <div class="col-3  project-name">
                                            Status
                                        </div>

                                        <div class="col-3 mr-3">
                                            <div *ngIf="directreportee.closed!=true" class="count green">Open</div>
                                            <div *ngIf="directreportee.closed==true" class="count red">Closed</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </ng-template>
            </mat-tab>

        </mat-tab-group>
    </div>
    <pagination-controls id="directreportee_id" class="pagination" (pageChange)="p = $event"></pagination-controls>

</div>
