<div class="main_header_div">
  <div class="child">
    <h1 class="header_name "><img class="header-icon mr-3" src="assets\icons\position.png">Position History</h1>
  </div>
  <div class="pr-3 pt-3">
    <img class="close-icon" (click)="closeDialog($event)" src="assets/icons/close.png">
  </div>
</div>
<hr />

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 form_main_div">
      <div class="add_button_fix">
        <button class="add_button" type="button"(click)="addPreEmployment()">+Add</button>
        <button class="plus_button mt-4" type="button"(click)="addPreEmployment()">
          <span>+Add</span>
        </button>
      </div>
      <form [formGroup]="editSpotlightDetails">
        <div class="bodyScroll">
          <div formArrayName="previousEmployment" *ngFor="let work of this.PreviousEmp().controls; let i = index">
            <div class="row">
              <div class="col-md-2" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>Start Date <span class="contentRed"></span></mat-label>
                  <input matInput [min]="minDate" [max]="today" [matDatepicker]="picker2" placeholder="dd/mm/yyyy"
                    formControlName="positionStartDate" />
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                  <mat-error *ngIf="
                      PreviousEmp()
                        .at(i)
                        .get('positionStartDate')
                        .hasError('required') && !PreviousEmp()
                        .at(i)
                        .get('positionStartDate')
                        .hasError('matDatepickerParse')
                    ">
                    Required</mat-error>
                  <mat-error *ngIf="
                      PreviousEmp()
                        .at(i)
                        .get('positionStartDate')
                        .hasError('dateRangeOverlap')
                    ">
                    Overlap detected
                  </mat-error>
                  <mat-error *ngIf="
                      PreviousEmp()
                        .at(i)
                        .get('positionStartDate')
                        .hasError('matDatepickerParse')
                    ">
                    Invalid Date
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-2" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>End Date <span class="contentRed"></span></mat-label>
                  <input matInput [min]="getMinEndDate(i)" [matDatepicker]="picker3" placeholder="dd/mm/yyyy"
                    formControlName="positionEndDate">
                  <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                  <mat-datepicker #picker3></mat-datepicker>
                  <mat-error *ngIf="PreviousEmp().at(i).get('positionEndDate').hasError('dateRangeOverlap')">
                    Overlap detected
                  </mat-error>
                  <mat-error *ngIf="
                      PreviousEmp()
                        .at(i)
                        .get('positionEndDate')
                        .hasError('matDatepickerParse')
                    ">
                    Invalid Date
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-2 " [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>Job Title <span class="contentRed"></span></mat-label>
                  <mat-select formControlName="jobTitle">
                    <mat-option *ngFor="let jobTitle of this.jobTitles" [value]="jobTitle">
                      {{jobTitle}}
                    </mat-option>
                  </mat-select>
                  <mat-error>Required</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-2 " [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>Capability<span class="contentRed"></span></mat-label>
                  <mat-select formControlName="capability">
                    <mat-option *ngFor="let capability of this.departments" [value]="capability">
                      {{capability}}
                    </mat-option>
                  </mat-select>
                  <mat-error>Required</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-2" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>Geo Location<span class="contentRed"></span></mat-label>
                  <mat-select formControlName="geoLocation" (selectionChange)="onGeoLocationChange($event.value, i)">
                    <mat-option *ngFor="let geoLocation of this.geoLocations" [value]="geoLocation">
                      {{geoLocation}}
                    </mat-option>
                  </mat-select>
                  <mat-error>Required</mat-error>
                </mat-form-field>
              </div>
              
              <div class="col-md-2" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>Base Location<span class="contentRed"></span></mat-label>
                  <mat-select formControlName="baseLocation">
                    <mat-option *ngFor="let baseLocation of baseLocations[i]" [value]="baseLocation">
                      {{baseLocation}}
                    </mat-option>
                  </mat-select>
                  <mat-error>Required</mat-error>
                </mat-form-field>
              </div>
              
              <div class="col-md-2 mb-2">
                <button class="remove_label" type="button" (click)="removePreEmployment(work.value.id,i)">Remove</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="submit_button_main">
              <button class="save_Emp_btn" type="button" (click)="onSubmit()"> <span>Save</span>
              </button>
            </div>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>