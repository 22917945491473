export class FormSchemaDTO {
    public  id : String;
    public formTemplate : String;
    public projectId : String;
    public formType : String;
    public jobTitle : String;
    public category : String;
    public formName : String;
    public feedbackRole : String;


    constructor(){
        this.id = "";
        this.formTemplate = "";
        this.projectId = "";
        this.formType = "";
        this.jobTitle = "";
        this.category = "";
        this.formName = "";
        this.feedbackRole = "";
    }
}
