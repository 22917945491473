<div class="container-fluid mt-4">
  <div class="row">
    <div class="col-md-4" *ngFor="let feedbackForm of this.formService.forms">
      <div class="card cardContent">
        <div class="row">
          <div class="col-md-12">
            <button
              class="editBtn mt-1"
              mat-icon-button
              (click)="openEditDialog(feedbackForm)"
            >
              <mat-icon class="iconEdit">edit</mat-icon>
            </button>
            <button
              class="copyBtn mt-1 mr-1"
              mat-icon-button
              (click)="cloneNewFormDialog(feedbackForm)"
            >
              <mat-icon>file_copy</mat-icon>
            </button>
            <button
              class="deleteBtn mt-1 mr-2"
              mat-icon-button
              (click)="deleteFeedbackForm(feedbackForm)"
            >
              <mat-icon class="iconDelete">delete_outline</mat-icon>
            </button>
          </div>
        </div>

        <button mat-flat-button (click)="openFormTemplateDialog(feedbackForm)">
          <div class="card-content">
            <div class="card-body">
              <img
                class="img"
                src="assets/icons/goal_logo.png"
                alt="Card image cap"
              />
              <p class="h7 text-center card-title">
                {{ feedbackForm.formType.replaceAll("_", " ") }}
              </p>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
