<div class="container">
    <div class="main_header_div">
        <div class="head">
            <img src="assets/icons/dashboard-icons/calendar.svg" />
            Leave Bulk Configuration
        </div>
        <img class="close-icon-svg" (click)="closeDialog()" src="assets/icons/dashboard-icons/close-pink-icon.svg" />
    </div>
</div>

<div class="container">
    <form [formGroup]="bulkConfigureFormGroup">
        <div class="row first-row">
            <div class="col-md-4">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Geo Location</mat-label>
                    <mat-select formControlName="geoLocation">
                        <mat-option *ngFor="let geoLocation of geoLocations" [value]="geoLocation">
                            {{ geoLocation | underScoreToSpace | titlecase }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="
                  bulkConfigureFormGroup.get('geoLocation').errors?.required
                ">
                        Required
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Employment Type</mat-label>
                    <mat-select formControlName="employmentType">
                        <mat-option *ngFor="let employmentType of employmentTypes" [value]="employmentType">
                            {{ employmentType | underScoreToSpace | titlecase }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="
                  bulkConfigureFormGroup.get('employmentType').errors?.required
                ">
                        Required
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Year</mat-label>
                    <mat-select formControlName="year">
                        <mat-option *ngFor="let year of years" [value]="year">
                            {{ year }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="
                  bulkConfigureFormGroup.get('year').errors?.required
                ">
                        Required
                    </mat-error>
                </mat-form-field>
            </div>

        </div>

        <div class="row first-row">
            <div class="col-md-4">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Leave Type</mat-label>
                    <mat-select formControlName="leaveType">
                        <mat-option *ngFor="let type of leaveTypes" [value]="type">
                            {{ type | underScoreToSpace | titlecase }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="
                  bulkConfigureFormGroup.get('leaveType').errors?.required
                ">
                        Required
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Action</mat-label>
                    <mat-select formControlName="creditOrDebit">
                        <mat-option value="credit">Credit</mat-option>
                        <mat-option value="debit">Debit</mat-option>
                    </mat-select>
                    <mat-error *ngIf="
                  bulkConfigureFormGroup.get('creditOrDebit').errors?.required
                ">
                        Required
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Number of Days</mat-label>
                    <input matInput type="number" formControlName="numberOfDays" min="0" autocomplete="off" />

                    <mat-error *ngIf="
                    bulkConfigureFormGroup.get('numberOfDays').hasError('multipleOf')
                  ">
                        Days must be a multiple of 0.25
                    </mat-error>
                    <mat-error *ngIf="bulkConfigureFormGroup.get('numberOfDays').errors?.required">
                        Required
                    </mat-error>
                    <mat-error *ngIf="bulkConfigureFormGroup.get('numberOfDays').hasError('min')">
                        Days must be a Positive Integer
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <hr />
        <div class="row third-row">
            <button class="blue-button" (click)="submit()"> Configure</button>
        </div>

    </form>

</div>