import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LeaveApprovalUrlsService {
  private readonly leaveapprovalBaseUrl = '/api/leave_acceptance';
  private readonly leaveConfigurationUrl = '/api/addLeaveBalanceByCm'
  private readonly onLeaveReporteesBaseUrl = '/api/leave_application/getOnLeaveReportee'  
  private readonly getReporteeAttendanceBaseUrl = '/api/attendances/calendar-attendance/cmReportee'  

  constructor() { }


  leaveApprovalBaseApi(){
    return this.leaveapprovalBaseUrl;
  }
  leaveApprovalUrl() {
    return this.leaveapprovalBaseUrl;
  }
  leaveConfigurationByCm(CmOrHrId,remark){
    return this.leaveConfigurationUrl + '/' + CmOrHrId + '/' + remark;
  }

  onLeaveReportees( month, year){
    return this.onLeaveReporteesBaseUrl+'/'+month+"/"+year; 
  }

  getReporteeAttendance( year, reporteeId){
    return this.getReporteeAttendanceBaseUrl+'/'+year+"/"+reporteeId; 
  }
  
}
