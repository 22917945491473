import { Component, Input, OnInit, Inject } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { range } from 'rxjs';
import { EvaluationConfigurationDTO } from 'src/app/dtos/EvaluationConfigurationDTO';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { Activity } from 'src/app/services/activity/activity-enum';
import { EvaluationConfigurationService } from 'src/app/services/evaluationConfiguration/evaluation-configuration.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { MainContentComponent } from '../employee/main-content/main-content.component';

@Component({
  selector: 'app-evaluation-configuration',
  templateUrl: './evaluation-configuration.component.html',
  styleUrls: ['./evaluation-configuration.component.scss'],
})
export class EvaluationConfigurationComponent implements OnInit {
  evaluationConfigForm: FormGroup;
  ActivityType: Activity;
  evalConfDto: EvaluationConfigurationDTO = new EvaluationConfigurationDTO();

  constructor(
    public fb: FormBuilder,
    public evalConfigService: EvaluationConfigurationService,
    private notificationService: NotificationService,
    private spinnerService: SpinnerService,
    public dialogRef: MatDialogRef<MainContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.evaluationConfigForm = this.fb.group(
      {
        careerManagerWeightage: ['', [Validators.required]],
        clientWeightage: ['', [Validators.required]],
        managerWeightage: ['', [Validators.required]],
        peerWeightage: ['', [Validators.required]],
        supervisorWeightage: ['', [Validators.required]],
      },
      {
        validator: ValidPercentDistribution,
      }
    );
  }

  ngOnInit(): void {
    //check userDto id has any entry in evaluation configuration if yes then set form builder
  }

  get careerManagerWeightage() {
    return this.evaluationConfigForm.get('careerManagerWeightage');
  }
  get clientWeightage() {
    return this.evaluationConfigForm.get('clientWeightage');
  }
  get managerWeightage() {
    return this.evaluationConfigForm.get('managerWeightage');
  }
  get peerWeightage() {
    return this.evaluationConfigForm.get('peerWeightage');
  }
  get supervisorWeightage() {
    return this.evaluationConfigForm.get('supervisorWeightage');
  }

  onSubmit() {
    if (this.evaluationConfigForm.valid) {
      this.evalConfigService
        .postEvaluationConfigurationSubscribe(
          this.prepareDto(this.evaluationConfigForm.value)
        )
        .subscribe((res) => {
          this.spinnerService.requestEnded();
          this.notificationService.sucess('Configuration assigned');
        });
      this.dialogRef.close();
    } else {
      this.notificationService.invalidFormData();
    }
  }

  prepareDto(payload) {
    this.evalConfDto.careerManagerWeightage = payload.careerManagerWeightage;
    this.evalConfDto.clientWeightage = payload.clientWeightage;
    this.evalConfDto.managerWeightage = payload.managerWeightage;
    this.evalConfDto.peerWeightage = payload.peerWeightage;
    this.evalConfDto.supervisorWeightage = payload.supervisorWeightage;

    this.evalConfDto.userWeightageId = this.data.id;
    this.evalConfDto.userWeightageLogin = this.data.email;

    return this.evalConfDto;
  }

  processName(user) {
    let name;
    if (user && user.firstName) name = user.firstName;

    if (user && user.lastName) {
      name ? (name += ' ' + user.lastName) : (name = user.lastName);
    }

    return name;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}

//Pertage distribution code
const ValidPercentDistribution: ValidatorFn = (fg: FormGroup) => {
  let careerManagerWeightage = fg.get('careerManagerWeightage');
  let clientWeightage = fg.get('clientWeightage');
  let managerWeightage = fg.get('managerWeightage');
  let peerWeightage = fg.get('peerWeightage');
  let supervisorWeightage = fg.get('supervisorWeightage');

  let percent = 100;

  if (
    careerManagerWeightage.value > 100 ||
    percent < careerManagerWeightage.value
  ) {
    careerManagerWeightage.markAsTouched({ onlySelf: true });
    careerManagerWeightage.markAsDirty({ onlySelf: true });
    careerManagerWeightage.setErrors({ incorrect: true });
    careerManagerWeightage.setErrors({ invalidDistribution: true });

    return null;
  } else {
    percent -= careerManagerWeightage.value;
  }

  if (clientWeightage.value > 100 || percent < clientWeightage.value) {
    clientWeightage.markAsTouched({ onlySelf: true });
    clientWeightage.markAsDirty({ onlySelf: true });
    clientWeightage.setErrors({ incorrect: true });
    clientWeightage.setErrors({ invalidDistribution: true });
    return null;
  } else {
    percent -= clientWeightage.value;
  }

  if (managerWeightage.value > 100 || percent < managerWeightage.value) {
    managerWeightage.markAsTouched({ onlySelf: true });
    managerWeightage.markAsDirty({ onlySelf: true });
    managerWeightage.setErrors({ incorrect: true });
    managerWeightage.setErrors({ invalidDistribution: true });
    return null;
  } else {
    percent -= managerWeightage.value;
  }

  if (supervisorWeightage.value > 100 || percent < supervisorWeightage.value) {
    supervisorWeightage.markAsTouched({ onlySelf: true });
    supervisorWeightage.markAsDirty({ onlySelf: true });
    supervisorWeightage.setErrors({ incorrect: true });
    supervisorWeightage.setErrors({ invalidDistribution: true });
    return null;
  } else {
    percent -= supervisorWeightage.value;
  }

  if (peerWeightage.value > 100 || percent < peerWeightage.value) {
    peerWeightage.markAsTouched({ onlySelf: true });
    peerWeightage.markAsDirty({ onlySelf: true });
    peerWeightage.setErrors({ incorrect: true });
    peerWeightage.setErrors({ invalidDistribution: true });
    return null;
  } else {
    percent -= peerWeightage.value;
  }

  return null;
};
