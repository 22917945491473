import {
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  CalendarOptions,
  EventInput,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { AttendanceHistoryService } from 'src/app/services/attendaceHistory/attendance-history.service';
import * as moment from 'moment';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { RegularizeComponent } from '../attendance-calendar/attendance-details/regularize/regularize.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AbsentService } from 'src/app/services/absent/absent.service';
import { ApplyNewleaveComponent } from './apply-newleave/apply-newleave.component';
import { LeaveApplicationDTO } from 'src/app/dtos/LeaveApplicationDTO';
import { ApprovalStatus } from 'src/app/services/leaveManagment/leave-enum';
import { AttendanceDTO } from 'src/app/dtos/AttendanceDTO';
import { AttendanceStatus } from 'src/app/services/attendance-extra/attendance-enum';
import { LeaveType } from 'src/app/services/user/user-enum';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-leave-attendance',
  templateUrl: './leave-attendance.component.html',
  styleUrls: ['./leave-attendance.component.scss'],
})
export class LeaveAttendanceComponent implements OnInit {
  @ViewChild('fullCalendar') fullCalendar!: FullCalendarComponent;
  months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  years: number[] = [];
  selectedMonth: string = this.months[new Date().getMonth()];
  selectedYear: number = new Date().getFullYear();
  regularizationTab: boolean = true;
  attendanceHistoryTab: boolean = false;
  userId: String;
  HistoryData: EventInput[] = [];
  activeTab: string = 'APPLY';
  val: AttendanceDTO[] = [];
  selectedItems: AttendanceDTO[] = [];
  historyTabData: { date: Date; status: string }[] = [];

  item: AttendanceDTO[] = [];
  constructor(
    private attendaceHistory: AttendanceHistoryService,
    private userdetails: UserDetailsService,
    private absentService: AbsentService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): any {
    this.getUserDetails();
    this.getStatus(this.userId, AttendanceStatus.UNMARKED_ATTENDANCE);
  }
  getUserDetails() {
    this.userId = this.userdetails.getUserDetails().id;
    const currentYear = new Date().getFullYear();
    for (let i = currentYear + 20; i > currentYear - 20; i--) {
      this.years.push(i);
    }
    const user = this.userdetails.getUserDetails();
  }
  apiCallForStatus(): Observable<EventInput[]> {
    return this.attendaceHistory.getAttendaceHistory(this.selectedYear, this.userId)
      .pipe(
        map(res => {
          const historyData: EventInput[] = [];

          for (const attendanceRecord of res.body) {
            const status = attendanceRecord.attendanceStatus;

            if (this.isValidStatus(status)) {
              const formattedDate = moment(attendanceRecord.attendanceDateTime).format('YYYY-MM-DD');
              const bgColor = this.getStatusColor(status);

              const currentEvent: EventInput = {
                title: '',
                start: formattedDate,
                end: formattedDate,
                backgroundColor: bgColor,
              };

              historyData.push(currentEvent);
            }
          }

          this.calendarOptions.events = historyData;
          return historyData;
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  private isValidStatus(status: AttendanceStatus): boolean {
    return [
      AttendanceStatus.PRESENT,
      AttendanceStatus.ABSENT,
      AttendanceStatus.REGULARIZED_PRESENT,
      AttendanceStatus.LEAVE,
      AttendanceStatus.UNMARKED_ATTENDANCE,
      AttendanceStatus.HOLIDAY,
    ].includes(status);
  }

  private getStatusColor(status: AttendanceStatus): string {
    switch (status) {
      case AttendanceStatus.PRESENT:
        return '#28A745';
      case AttendanceStatus.REGULARIZED_PRESENT:
        return '#FFA900';
      case AttendanceStatus.ABSENT:
      case AttendanceStatus.UNMARKED_ATTENDANCE:
        return '#EB0000';
      case AttendanceStatus.LEAVE:
        return '#0045B9';
      default:
        return '#FAA8A9';
    }
  }


  getHistoryTabData(): void {
    this.attendaceHistory.getAttendaceHistory(this.selectedYear, this.userId)
      .subscribe(
        (res) => {
          const attendanceHistory = res.body;
          this.historyTabData = this.filterHistoryData(attendanceHistory);
        },
        (error) => {
          console.error('Error fetching attendance history:', error);
          // Handle the error if needed
        }
      );
  }

  private filterHistoryData(attendanceHistory: any[]): any[] {
    const filteredData = attendanceHistory
      .filter(record => this.isValidHistoryRecord(record))
      .filter(record => this.isRecordForSelectedMonth(record));

    return filteredData.map(record => this.transformRecordToHistoryData(record));
  }

  private isValidHistoryRecord(record: any): boolean {
    const status = record.attendanceStatus;
    return (
      status === AttendanceStatus.REGULARIZED_PRESENT ||
      (status === AttendanceStatus.UNMARKED_ATTENDANCE && record.appliedForRegularization)
    );
  }

  private isRecordForSelectedMonth(record: any): boolean {
    const dateObj = new Date(record.attendanceDateTime);
    const monthName = dateObj.toLocaleString('default', { month: 'long' });
    return monthName.toLowerCase() === this.selectedMonth.toLowerCase();
  }

  private transformRecordToHistoryData(record: any): any {
    const formattedDate = moment(record.attendanceDateTime).format('ddd, D MMM,');
    const status = (record.attendanceStatus === AttendanceStatus.REGULARIZED_PRESENT) ? 'Approved' : 'Pending';

    return {
      date: formattedDate,
      status: status,
    };
  }

  openDialog(item: any[] = []) {
    const dialogRef = this.dialog.open(RegularizeComponent, {
      height: '50%',
      width: '40%',
      data: {
        item: item,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // Refresh the list of unmarked attendance here
      if (result) {
        const user = this.userdetails.getUserDetails();
        this.getStatus(AttendanceStatus.UNMARKED_ATTENDANCE, this.userId);
      }
      this.item = [];
    });
  }


  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin],
    initialDate: new Date(),
    weekends: true,
    firstDay: 1,
    datesSet: (event) => {
      const dateObj = new Date(event.end);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth();
      this.selectedMonth = month == 0 ? 'December' : this.months[month - 1];
      this.selectedYear = month == 0 ? year - 1 : year;
      this.apiCallForStatus();
      this.getHistoryTabData();
    },
    headerToolbar: {
      start: 'prevYear prev',
      center: 'title',
      end: 'today next nextYear',
    },
  };

  public onMonthYearChange() {
    if (this.fullCalendar) {
      const date = new Date(
        this.selectedYear,
        this.months.indexOf(this.selectedMonth),
        1
      );
      this.fullCalendar.getApi().gotoDate(date);
    }
  }

  onTabClick(tabName) {
    this.activeTab = tabName;
  }

  getStatus(userId, attendanceStatus) {
    this.val = [];
    const user = this.userdetails.getUserDetails();
    this.absentService.getstatusbyuser(AttendanceStatus.UNMARKED_ATTENDANCE, this.userId).subscribe(res => {
      for (let i = 0; i < res.body.length; i++) {
        this.val.push(res.body[i]);

      }
    }, (e) => {
    }
    )

    return this.val;
  }


  applyLeave(item: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '100%';
    dialogConfig.height = '90%';
    dialogConfig.panelClass = 'apply-leave';

    var leaveApplicationDTO = new LeaveApplicationDTO();
    leaveApplicationDTO.userId = this.userdetails.getUserDetails().id;
    leaveApplicationDTO.careerManagerId = this.userdetails.getUserDetails().careerManagerId;
    leaveApplicationDTO.typeOfLeave = LeaveType.PLANNED_LEAVE
    leaveApplicationDTO.startDate = item.attendanceDateTime;
    leaveApplicationDTO.endDate = item.attendanceDateTime;
    leaveApplicationDTO.startSession = 1;
    leaveApplicationDTO.endSession = 2;
    leaveApplicationDTO.approvalStatus = ApprovalStatus.PENDING;
    dialogConfig.data = {
      existingLeave: leaveApplicationDTO,
      isDisableDate: true,
    };
    const dialogRef = this.dialog.open(ApplyNewleaveComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => { });
  }
}
