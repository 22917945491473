import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EmployeeOnLeaveDTO } from 'src/app/dtos/EmployeeOnLeaveDTO';
import { Departments, ExcelSheetName, JobTitles } from 'src/app/services/user/user-enum';
import { UserService } from 'src/app/services/user/user.service';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { FormControl, FormGroup } from '@angular/forms';
import { TooltipPosition } from '@angular/material/tooltip';
import { TypeOfLeave } from 'src/app/services/leaveManagment/leave-enum';
import { AbsentService } from 'src/app/services/absent/absent.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-employee-on-leave',
  templateUrl: './employee-on-leave.component.html',
  styleUrls: ['./employee-on-leave.component.scss']
})
export class EmployeeOnLeaveComponent implements OnInit {
  activeUser: EmployeeOnLeaveDTO[]
  leaveTypes = [];
  departments = [];
  jobTitles = [];
  department = Departments;
  jobTitle = JobTitles;
  isSpinner:boolean=true;
  page: number = 1;
  searchTerm: string = '';
  filteredUsers: EmployeeOnLeaveDTO[];
  searchForm: FormGroup;
  capabilitySearch: Array<string> = [];
  jobTitleSearch: Array<string> = [];
  leaveType: Array<string> = [];
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  currentSortName: { column: string, direction: 'asc' | 'desc' } = { column: 'name', direction: 'asc' };
  currentSortEmployeeId: { column: string, direction: 'asc' | 'desc' } = { column: 'employeeId', direction: 'asc' };
  currentSortJobTitle: { column: string, direction: 'asc' | 'desc' } = { column: 'jobTitle', direction: 'asc' };
  currentSortDepartment: { column: string, direction: 'asc' | 'desc' } = { column: 'department', direction: 'asc' };
  startDate: string;
  endDate: string;
  fromDate: Date;
  toDate: Date;
  // Add this property to your component class
  currentSort: { column: string, direction: 'asc' | 'desc' } = { column: 'name', direction: 'asc' };
  constructor(private userService: UserService, private dialog: MatDialog, private router: Router, private absentService: AbsentService, private toastrService: ToastrService
  ) {

  }
  ngOnInit(): void {
    this.processEnumValuesToArray()
    this.getUsers()
  }
  getUsers() {
    this.isSpinner=true;
    this.absentService.getStatusByDate().subscribe(res => {
      this.filteredUsers = res.body;
      this.activeUser = res.body;
      this.filteredUsers.forEach(element => {
        this.isSpinner=false;
        if (element.userImageThumbnail !== '') {
          element.userImageThumbnail =
            'data:image/png;base64,' + element.userImageThumbnail;
        }
        this.userService.getEmployeeById(element.careerManagerId).subscribe(res => {
          element.carrierManagerName = res.body.firstName + " " + res.body.lastName;
        })
      });
    },error=>{
      this.isSpinner=true;
    });
  }
  applySearch() {
    this.capabilitySearch = [];
    this.jobTitleSearch = [];
    this.fromDate = null;
    this.toDate = null;
    this.leaveType = [];
    let filteredUsers = this.activeUser.filter(user => {
      const nameMatch = user.firstName.toLowerCase().includes(this.searchTerm.toLowerCase()) || user.lastName.toLowerCase().includes(this.searchTerm.toLowerCase());
      const carrierManagerMatch =
        (user.carrierManagerName?.toLowerCase() ?? '').includes(this.searchTerm.toLowerCase());
      return nameMatch || carrierManagerMatch;
    });
    filteredUsers.sort((a, b) => {
      const aNameMatch = a.firstName.toLowerCase().includes(this.searchTerm.toLowerCase()) || a.lastName.toLowerCase().includes(this.searchTerm.toLowerCase());
      const bNameMatch = b.firstName.toLowerCase().includes(this.searchTerm.toLowerCase()) || b.lastName.toLowerCase().includes(this.searchTerm.toLowerCase());
      if (aNameMatch && !bNameMatch) {
        return -1;
      } else if (!aNameMatch && bNameMatch) {
        return 1;
      } else {
        return 0;
      }
    });
    this.filteredUsers = filteredUsers;
  }
  applySearchForJobTitleAndDepartementAndLeaveType() {
    let filteredUsers = this.activeUser.filter(user => {

      const departmentKeys = this.capabilitySearch.map(capability => this.getDepartmentKeyByValue(capability));
      const jobTitleKeys = this.jobTitleSearch.map(jobTitle => this.getJobTitleKeyByValue(jobTitle));
      const leaveTypeKeys = this.leaveType.map(leaveType => this.getLeaveTypeKeyByValue(leaveType));

      const departmentMatch = departmentKeys.length === 0 || departmentKeys.includes(user.department);
      const jobTitleMatch = jobTitleKeys.length === 0 || jobTitleKeys.includes(user.jobTitle);
      const leaveTypeMatch = leaveTypeKeys.length === 0 || leaveTypeKeys.includes(user.leaveType);

      return departmentMatch && jobTitleMatch && leaveTypeMatch;
    });
    this.filteredUsers = filteredUsers;
  }
  processEnumValuesToArray() {
    for (const jobTitle of Object.values(JobTitles)) {
      if (isNaN(Number(jobTitle))) {
        this.jobTitles.push(jobTitle);
      }
    }

    for (const department of Object.values(Departments)) {
      if (isNaN(Number(department))) {
        this.departments.push(department);
      }
    }
    for (const leaveType of Object.values(TypeOfLeave)) {
      if (isNaN(Number(leaveType))) {
        this.leaveTypes.push(leaveType);
      }
    }
  }
  getDepartmentKeyByValue(value: string): string | undefined {
    return Object.keys(Departments).find(key => Departments[key] === value);
  }
  getLeaveTypeKeyByValue(value: string): string | undefined {
    return Object.keys(TypeOfLeave).find(key => TypeOfLeave[key] === value);
  }
  getJobTitleKeyByValue(value: string): string | undefined {
    return Object.keys(JobTitles).find(key => JobTitles[key] === value);
  }

  sortTable(column: string): void {
    switch (column) {
      case 'name':
      case 'employeeId':
      case 'jobTitle':
      case 'department':
      case 'carrierManagerName':
      case 'email':
        this.sortByColumn(column);
        break;
    }
  }

  private sortByColumn(column: string): void {
    const currentSort = this.getCurrentSortForColumn(column);

    if (currentSort.column === column) {
      currentSort.direction = currentSort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      currentSort.column = column;
      currentSort.direction = 'asc';
    }

    this.updateCurrentSortForColumn(column, currentSort);

    this.filteredUsers.sort((a, b) => {
      const aValue = a[column] ? a[column].toLowerCase() : null;
      const bValue = b[column] ? b[column].toLowerCase() : null;

      if (aValue === null && bValue === null) {
        return 0;
      } else if (aValue === null) {
        return 1;
      } else if (bValue === null) {
        return -1;
      } else {
        return currentSort.direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
    });
  }

  private getCurrentSortForColumn(column: string): { column: string, direction: 'asc' | 'desc' } {
    switch (column) {
      case 'name':
        return this.currentSortName;
      case 'employeeId':
        return this.currentSortEmployeeId;
      case 'jobTitle':
        return this.currentSortJobTitle;
      case 'department':
        return this.currentSortDepartment;
      default:
        return { column: '', direction: 'asc' };
    }
  }

  private updateCurrentSortForColumn(column: string, currentSort: { column: string, direction: 'asc' | 'desc' }): void {
    switch (column) {
      case 'name':
        this.currentSortName = currentSort;
        break;
      case 'employeeId':
        this.currentSortEmployeeId = currentSort;
        break;
      case 'jobTitle':
        this.currentSortJobTitle = currentSort;
        break;
      case 'department':
        this.currentSortDepartment = currentSort;
        break;
    }
  }
  exportToExcel(): void {
    const fileName = ExcelSheetName.OnLeaveEmployee;
    const header = ['Name', 'Employee ID', 'Gender', 'DOB', 'Marital Status', 'Mobile number', 'Alternate number', 'Job Title', 'Capability', 'Career Manager', 'Email', 'Geo location', 'Base Location'];

    const data = this.filteredUsers.map(user => ({
      'Name': user.firstName + " " + user.lastName,
      'Employee ID': user.employeeId,
      'Job Title': JobTitles[user.jobTitle],
      'Capability': Departments[user.department],
      'Career Manager': user.carrierManagerName,
      'Email': user.email,
      'Leave Type': user.leaveType,
      'Start Date': user.startDate,
      'End Date': user.endDate
    }));

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, { header });
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  }
  getEnumValueByKey(type, key: string): any {
    return type[key];
  }
  ifOverflow(e){
    return e.scrollWidth > e.clientWidth;
  }
  applyFilters(startDate, endDate): void {
    const filteredUsers = this.filteredUsers.filter(user => {
      const userStartDate = new Date(user.startDate);
      const userEndDate = new Date(user.endDate);

      const startDateTime = startDate ? new Date(startDate) : null;
      const endDateTime = endDate ? new Date(endDate) : null;

      const startDateCondition = !startDateTime || userStartDate >= startDateTime;
      const endDateCondition = !endDateTime || userEndDate <= endDateTime;
      return startDateCondition && endDateCondition;
    });
    this.filteredUsers = filteredUsers;
  }
  applyFilter(): void {
    const fromDate = new Date(this.fromDate);
    const toDate = new Date(this.toDate);
    if (fromDate instanceof Date && !isNaN(fromDate.getTime()) &&
      toDate instanceof Date && !isNaN(toDate.getTime())) {
      this.filteredUsers = this.activeUser.filter(user => {
        const userStartDate = new Date(user.startDate);
        const userEndDate = new Date(user.endDate);
        userStartDate.setHours(0, 0, 0, 0);
        userEndDate.setHours(0, 0, 0, 0);
        return (userStartDate.getTime() >= fromDate.getTime() &&
          userEndDate.getTime() <= toDate.getTime());
      });
    } else {
      console.error('Invalid date input');
    }
  }
  getMinEndDate() {
    return this.fromDate;
  }
}
