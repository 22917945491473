<mat-card class="main-card">
  <div class="row">
    <div class="col-md-12">
      <div class="main-header">
        <div class="svg-heading-div">
          <img class="submodule-svg" src="assets/icons/dashboard-icons/daily-attendance.svg" />
          <div class="head">Daily Attendance</div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="tableFixHead">
        <div>
          Please ensure you mark your attendance to prevent any issues with
          regularizations
        </div>

        <div class="row mt-5">
          <div class="col-8">
            <div class="attendance-date-main">
              <span class="attendance-heading">Mark the Attendance</span>
              <br />
              <span class="attendance-date">{{ currentDate }}</span>
            </div>
          </div>
          <div class="col-4">
            <div class="attendance-main" *ngIf="!isLoading">
              <img *ngIf="mark_Attendance" src="/assets/icons/mark-attendance-new-pulse.png"
                class="attendance-img unmarked" alt="" (click)="markAttendance()" [disabled]="disableState" />

              <img *ngIf="attendance_Marked" src="/assets/icons/marked-attendance-new-pulse.png"
                class="attendance-img marked" alt="" />
            </div>
            <div class="attendance-main" *ngIf="isLoading">
              <mat-spinner strokeWidth="2" class="attendance-loader" [diameter]="35"></mat-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>