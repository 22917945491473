import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Certificate } from 'src/app/models/certificate';
import { CertificationService } from 'src/app/services/certification/certification.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserCertificateDTO } from 'src/app/dtos/UserCertificateDTO';
import { EventTabClosed } from 'src/app/services/user/user-enum';
@Component({
  selector: 'app-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.scss'],
})
export class CertificationsComponent implements OnInit {
  editSpotlightDetails: FormGroup;
  certificatenames: string;
  certificateType: string[] = [];
  certificatename: string[] = [];
  removecertificates: number[] = [];
  multiCertificates: string[] = [];
  UploadCertificate: FormArray;
  cardBase64: string;
  binary: string[] = [];
  flag: boolean[] = [];
  certCount: number = 0;
  today: Date = new Date();
  userId: string;
  constructor(
    private toaster: ToastrService,
    private userDetailService: UserDetailsService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private certificateservice: CertificationService,
    private dialogRef: MatDialogRef<CertificationsComponent>,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
  }
  ngOnInit(): void {
    this.initiateComponent()
  }
  initiateComponent() {
    this.editSpotlightDetails = this.fb.group({
      uploadCertificates: this.fb.array([]),
    });
    this.userId = this.data
      ? this.data
      : this.userDetailService.getUserDetails().id;
    this.certificateservice
      .getCertByUserIdSubscribe(this.userId)
      .subscribe((res) => {
        if (res.body[0] == null) {
          this.addCertificate();
        }
        this.setCertificateFormArray(res.body);
      });
  }
  getMinEndDate(i) {
    var minEndDate = this.editSpotlightDetails
      .get('uploadCertificates')
    ['controls'][i].get('dateOfIssue').value;
    return minEndDate;
  }
  createcertificate() {
    return this.fb.group({
      documentName: ['',Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9+#. -]*$')], // Add pattern validation
      dateOfIssue: [''],
      validUpto: [''],
      docBase: [''],
    });
  }
  removecertificate(id: any, i) {
    this.removecertificates.push(id);
    this.Certificate.removeAt(i);
    this.certificateType.splice(i, 1);
    this.multiCertificates.splice(i, 1);
    this.certificatename.splice(i, 1);
    this.certCount = this.certCount - 1;
  }
  setCertificateFormArray(response) {
    this.UploadCertificate = this.editSpotlightDetails.get(
      'uploadCertificates'
    ) as FormArray;
    let certList: Certificate[] =
      this.certificateservice.setCertResponse(response);
    for (let s of certList) {
      if (s.docBase && s.docBase != 'undefined') {
        var temp = atob(s.docBase.toString());
        var cerType = this.getDocumentType(temp);
        this.multiCertificates.unshift(temp);
        this.certificateType.unshift(cerType);
        this.binary.unshift(s.docBase.toString());
      } else {
        this.multiCertificates.unshift('undefined');
        this.certificateType.unshift('undefined');
        this.binary.unshift('undefined');
        s.docBase='undefined'; 
      }
      this.certCount = this.certCount + 1;
      this.flag.unshift(true);
      const fg= this.fb.group(s);
      fg.controls.documentName.setValidators(Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9+#. -]*$'));
      this.UploadCertificate.insert(0, fg);
    }
  }
  get Certificate(): FormArray {
    return this.editSpotlightDetails.get('uploadCertificates') as FormArray;
  }
  addCertificate() {
    this.UploadCertificate = this.editSpotlightDetails.get(
      'uploadCertificates'
    ) as FormArray;
    this.flag.unshift(true);
    this.certCount = this.certCount + 1;
    this.UploadCertificate.insert(0, this.createcertificate());
    this.binary.unshift('undefined');
    this.multiCertificates.unshift('');
    this.certificateType.unshift('');
  }
  closeDialog(event: Event) {
    this.dialogRef.close({ event: EventTabClosed.Closed });
  }
  getDocumentType(temp): string {
    const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];
    for (let type of allowed_types) {
      if (temp.indexOf(type) != -1) {
        return type;
      }
    }
  }
  onSubmit() {
    let isPopUp = false;
    if (this.editSpotlightDetails.valid) {
      let i = 0;
      for (let cert of this.editSpotlightDetails.value.uploadCertificates) {
        let userdocument = new UserCertificateDTO();
        userdocument.docBase = this.binary[i].toString();
        userdocument.documentName = cert.documentName;
        userdocument.userId = this.userId;
        if (
          cert &&
          !cert.id &&
          cert.dateOfIssue != undefined &&
          cert.documentName != ''
        ) {
          cert.userId = this.userId;
          cert.docBase = this.binary[i].toString();
          this.certificateservice.create(cert).subscribe((res) => {
            this.dialogRef.close({ event: EventTabClosed.Closed });
            if (!isPopUp) {
              this.toaster.success('Successfully Saved');
              isPopUp = true;
            }
          });
        } else if (
          cert &&
          cert.id &&
          cert.dateOfIssue != undefined &&
          cert.documentName != '' &&
          cert.docBase != ''
        ) {
          if (cert.docBase == 'undefined') {
            cert.docBase = this.binary[i].toString();
          }
          this.certificateservice.update(cert).subscribe((res) => {
            this.dialogRef.close({ event: EventTabClosed.Closed });
            if (!isPopUp) {
              this.toaster.success('Successfully Saved');
              isPopUp = true;
            }
          });
        }
        i++;
      }
      for (let i in this.removecertificates) {
        this.certificateservice.delete(this.removecertificates[i]);
        this.dialogRef.close({ event: EventTabClosed.Closed });
        if (!isPopUp) {
          this.toaster.success('Successfully Saved');
          isPopUp = true;
        }
      }
    }else{
      this.toaster.error("Invalid Details")
    }
  }
  uploadCertificate(i, fileInput) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];
      this.certificatename.push(fileInput.target.files[0].name);
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const imgBase64Path: String = e.target.result;
        let imageContent = imgBase64Path.split(',')[1];
        let imageMetaData = imgBase64Path.split(',')[0];
        let imageContentType = imageMetaData.substring(
          imageMetaData.lastIndexOf(':') + 1,
          imageMetaData.lastIndexOf(';')
        );
        var temp = imageMetaData + ',' + imageContent;
        this.cardBase64 = imageMetaData + ',' + imageContent;
        if (this.multiCertificates[i] == null) {
          this.multiCertificates.unshift(temp);
          this.certificateType.unshift(imageContentType);
        } else {
          this.multiCertificates[i] = temp;
          this.certificateType[i] = imageContentType;
        }
        this.binary[i] = btoa(this.cardBase64);
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }
  open(content, i) {
    this.multiCertificates[i];
    if (this.flag[i] == false) {
      this.multiCertificates[i] = atob(this.multiCertificates[i]);
      this.flag[i] = true;
    }
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => { },
        (reason) => { }
      );
  }
  remove(i){
    this.certificateType[i]="";
    this.multiCertificates[i]="";
    this.binary[i]="";
    this.certificatename[i]="";
    this.certCount = this.certCount - 1;
    this.editSpotlightDetails.value.uploadCertificates[i].docBase='undefined';
  }
}
