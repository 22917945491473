import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { RefreshService } from 'src/app/services/refreshService/refresh.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  notificationList: any[] = [];
  isNotificationVisible = false;
  isLoading: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<NotificationsComponent>,
    private notificationService: NotificationService,
    private refreshService: RefreshService
  ) {}

  ngAfterViewInit() {
    this.dialogRef.backdropClick().subscribe(() => {
      // Handle backdrop click (click outside the dialog)
      this.dialogRef.close();
      this.isNotificationVisible = false;
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Closed' });
  }

  ngOnInit(): void {
    this.notificationService.getAllNotifications().subscribe((res) => {
      this.notificationList = res.body.reverse();
      this.isLoading = false;
    });
    setTimeout(() => {
      // After the delay, set isNotificationVisible to true
      this.isNotificationVisible = true;
    }, 160);
  }

  clearAllNotifications() {
    this.notificationService.clearAllNotifications().subscribe((res) => {
      this.ngOnInit();
    });
  }

  clearSingleNotification(id) {
    this.notificationService.clearSingleNotification(id).subscribe((res) => {
      this.ngOnInit();
    });
  }
}
