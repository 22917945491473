import { GoalType } from "../services/goalSheet/goal-sheet-enum"

export class UserGoalDTO{
   
    public goalType:GoalType
    public progressValue:number
    public createddate:Date
    public year:String
    public userId:String

    constructor(){
       
        this.goalType=null
        this.progressValue=0
        this.createddate=null
        this.year=null
        this.userId=null
    }
}